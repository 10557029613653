<template>
<div class='h-mt'>
    <form class='b-add-company__form--edit'
          @keyup.enter='onSubmit'>
        <div
            v-if='isAddressSwitch && !isHubsFlow'
            class='h-mv-20'>
            <FwFormInput
                style='width: 280px'
                class='b-add-company__form--edit__input'
                :fieldHasError='fieldHasError(ADMIN)'
                labelType='label-medium'
                :title='isTechProfile ? `${$t(`TECH.SAME.COMPANY.ADDRESS`)}*` : `${$t(`ADVISOR.SAME.BRANCH.ADDRESS`)}*`'>
                <FwLabelCard
                    class='h-flex-1-0 b-label-card'>
                    <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                        <div class='b-add-edit__form__option h-flex h-flex-center'>
                            <span class='b-add-worker__is_admin_active'>
                                {{ isAddressTheSame ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                            </span>
                        </div>
                        <FwSwitcher
                            :isActive='isAddressTheSame'
                            :isRequestSending='isActiveAdminRequestSending'
                            @toggleSwitch='isAddressTheSame = !isAddressTheSame'>
                        </FwSwitcher>
                    </div>
                </FwLabelCard>
            </FwFormInput>
        </div>
        <div v-if='!isAddressTheSame' class='b-add-company__form--edit__row'>
            <div class='b-add-company__form--edit__input'>
                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError()'
                    :title='`${$t(`LABEL.ADDRESS`)}*`'
                    labelType='label-medium'>
                    <SelectAddress
                        :startAddress='address'
                        @changePostCode='value => post_code = value'
                        @input='input'>
                    </SelectAddress>
                </FwFormInput>
            </div>
        </div>
        <div class='b-add-company__form--edit__row'>
            <FwFormInput
                class='b-base-form-margin b-add-company__form--edit__input'
                labelType='label-medium'
                :title='`${$t(`UPDATE.ADDRESS.WHEN.TITLE`)}*`'>
                <div class='b-working-hours__date-picker h-mt-15'>
                    <FwDatepicker
                        v-model='dateFrom'
                        name='datepicker'
                        :placeholder='$t(`LABEL.CHOOSE_DAY`)'
                        :propsRange='false'
                        :propsLang='`${$i18n.locale}`'
                        :selectMode='false'
                        pickerStyle='static'
                        propsType='date'
                        propsFormat='DD/MM/YYYY'
                        openOnTop
                        :disabledDate='disabledDateByObject'
                        propsInputClass='b-add-reserved-slot-form__datepicker'>
                        <template #icon>
                            <FwIcon
                                class='h-flex'
                                icon='calendar'
                                size='16'
                                color='#203f6a'>
                            </FwIcon>
                        </template>
                    </FwDatepicker>
                </div>
            </FwFormInput>
        </div>
    </form>
    <div class='b-form-hint__wrapper'>
        <div class='b-form-hint'>
            <p class='h-mt-5'>
                * {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
            </p>
        </div>
    </div>

    <div class='h-flex h-flex-center h-flex-dir-column'>
        <FwButton
            v-if='!workerUpdateData'
            class='h-mt-20'
            :disabled='isRequestSending || !dateFrom || (!address && !isAddressTheSame)'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <FwButton
            v-else-if='!addressUpdateData && isAddress'
            class='h-mt-20'
            :disabled='isRequestSending || !dateFrom'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <div v-else class='h-mt-40 h-mb-0 h-fw-500 h-font-16 h-opacity-0_5'>
            {{ $t('UPDATE.ADDRESS.ALREADY.UPDATED') }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins, Emit, Prop } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import DateMixin from '@/mixins/dateMixin';
import { CompanyData } from '@/types/Companies';
import { SelectAddress } from '@/components/common/SelectAddress';
import { addPostCodeToAddress } from '@/helpers/address';
import { ParsedAddressData } from '@/types/GoogleMap';

const WorkerPageStore = namespace('WorkerPageStore');
@Component({
    components: {
        SelectAddress,
    },
})
export default class EditAddressForm extends Mixins(ValidationMixin, DateMixin) {
    @WorkerPageStore.State('workerUpdateData') workerUpdateData!: any;
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;
    @Prop({ type: Object, default: null }) readonly objectData!: any;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAddress!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAddressSwitch!: boolean;
    @Prop({ type: String, default: '' }) readonly updateType!: string;
    @Prop({ type: String, default: '' }) readonly userType!: string;
    phoneCode: string = '+33';
    dateFrom: string = '';
    address: string | null = '';
    post_code: string | null = '';
    isAddressTheSame: boolean = false;
    currentDate: Date = this.getDayDateAtNight();
    get disabledDateByObject() {
        return {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate() + 1) : new Date(),
        };
    }
    get isTechProfile() {
        return this.userType === `Technician`;
    }
    get isHubsFlow() {
        return this.user().account_organization_type === `with_hubs` &&
            this.user().account_organization_type !== `with_all` &&
            this.user().account_organization_type !== `with_branches`;
    }
    input(parsedAddressData: ParsedAddressData) {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
    }

    created() {
        if (this.isAddressSwitch && this.objectData) {
            if (this.isTechProfile) {
                this.isAddressTheSame = this.objectData.is_company_address_used;
            } else {
                this.isAddressTheSame = this.objectData.is_branch_address_used;
            }
        }
        this.address = this.isTechProfile ? this.objectData.technician_profile.departure_point : this.objectData.address;
    }

    @Emit('onSubmit')
    onSubmit() {
        if (this.isAddressTheSame) {
            this.address = this.isTechProfile ? this.objectData.company_address : this.objectData.branch.address;
        }
        return {
            address: addPostCodeToAddress(this.address!, this.post_code!),
            dateFrom: this.dateFrom,
        };
    }
}
</script>

<style lang='sass'>
.b-add-company__form--edit
    display: flex
    flex-wrap: wrap
    margin: 0 -15px

    .b-label-card
        background-color: #fff
        height: 60px !important

    .b-working-hours__date-picker
        width: 280px
        border-radius: 10px

        @include media('<=phone')
            width: 100%

    &__row
        display: flex
        width: 100%
        margin-bottom: 20px

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-company__form--edit__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7
</style>
