export default {
    // hardcoded translations for statistics back end keys
    'total': 'Total',
    'branch': 'By this branch',
    'outside': 'By users outside of this branch',
    'client_or_prospect': 'External : client or prospect',
    'internal': 'Internal',

    'hub_branch': 'By this hub',
    'hub_outside': 'By users outside of this hub',

    'for_branch': 'For this branch',
    'for_outside': 'For users outside of this branch',

    'for_hub_branch': 'For this hub',
    'for_hub_outside': 'For users outside of this hub',
};
