import { AttributeExtendedType } from '@/types/Attribute';

export type ValidatorType =
    `input_required` |
    `length` |
    `min_length` |
    `max_length` |
    `min_value` |
    `max_value` |
    `pattern` |
    `number_range` |
    `max_options` |
    `phone_regexp` |
    `file_mime` |
    `file_size`

export type AttributeSchemaInputType = `string` | `checkbox` | `select`

const VALIDATOR_TYPES: { [key: string]: ValidatorType } = {
    INPUT_REQUIRED: `input_required`,
    LENGTH: `length`,
    MIN_LENGTH: `min_length`,
    MAX_LENGTH: `max_length`,
    MIN_VALUE: `min_value`,
    MAX_VALUE: `max_value`,
    NUMBER_RANGE: `number_range`,
    MAX_OPTIONS: `max_options`,
    PHONE_REGEXP: `phone_regexp`,
    FILE_MIME: `file_mime`,
    MAX_FILE_SIZE: `file_size`,
    PATTERN: `pattern`,
};

const validatorsToTemplateErrorMapping = {
    input_required: `required`,
    length: `length`,
    min_length: `minLength`,
    max_length: `maxLength`,
    min_value: `greaterThan`,
    max_value: `lessThan`,
    number_range: `between`,
    max_options: `size`,
    phone_regexp: `size`,
    file_mime: `size`,
    file_size: `size`,
    pattern: `regex`,
};

const ATTRIBUTE_TYPES: { [key: string]: AttributeSchemaInputType } = {
    INPUT: `string`,
    CHECKBOX: `checkbox`,
    SINGLE_SELECT: `select`,
};

const ATTRIBUTE_TYPE_MAP = {
    [ATTRIBUTE_TYPES.CHECKBOX]: `CheckboxAttribute`,
    [ATTRIBUTE_TYPES.SINGLE_SELECT]: `SelectAttribute`,
    [ATTRIBUTE_TYPES.INPUT]: `InputAttribute`,
};

function isValid(value: any, validator: ValidatorType, validatorValue: any): boolean {
    function isEmptyValue(v: null | string) {
        // eslint-disable-next-line
            return v == null || v === '';
    }

    function validate(v: null | string, checkIsValueValid: (v: null | string) => boolean, isValueRequired = false) {
        if (isValueRequired) {
            return !isEmptyValue(v) && checkIsValueValid(v);
        }
        return isEmptyValue(v) ? true : checkIsValueValid(v);
    }

    function stripComma(str :string) {
        return String(str).replace(/,/g, ``);
    }

    switch (validator) {
        case VALIDATOR_TYPES.DATA_REQUIRED:
        case VALIDATOR_TYPES.INPUT_REQUIRED:
            return validate(value, () => !isEmptyValue(value), true);

        case VALIDATOR_TYPES.LENGTH:
            return validate(value, val => {
                const strValue = String(value);
                return !strValue.length || (strValue.length >= validatorValue &&
                strValue.length <= validatorValue);
            });

        case VALIDATOR_TYPES.MIN_LENGTH:
            return validate(value, () => {
                const strValue = String(value);
                const minLength = Number.parseInt(validatorValue, 10);
                return !value || strValue.length >= minLength;
            });

        case VALIDATOR_TYPES.MAX_LENGTH:
            return validate(value, () => {
                const strValue = String(value);
                const maxLength = Number.parseInt(validatorValue, 10);

                return !value || strValue.length <= maxLength;
            });

        case VALIDATOR_TYPES.MIN_VALUE:
            return validate(value, () => {
                const numbValue = Number.parseInt(stripComma(value), 10);
                const min = Number.parseInt(validatorValue, 10);

                return (!value && value !== 0) || numbValue >= min;
            });

        case VALIDATOR_TYPES.MAX_VALUE:
            return validate(value, () => {
                const numbValue = Number.parseInt(stripComma(value), 10);
                const max = Number.parseInt(validatorValue, 10);

                return (!value && value !== 0) || numbValue <= max;
            });

        case VALIDATOR_TYPES.NUMBER_RANGE:
            return validate(value, () => {
                const numbValue = Number.parseInt(value, 10);
                return !value || (numbValue >= validatorValue[0] && numbValue <= validatorValue[1]);
            });

        case VALIDATOR_TYPES.MAX_OPTIONS:
            return validate(value, (val: string | null) => {
                const selectedOptionsAmount = Array.isArray(val) && val.length;

                if (selectedOptionsAmount || selectedOptionsAmount === 0) {
                    return selectedOptionsAmount <= +validatorValue;
                }
                return false;
            });
        case VALIDATOR_TYPES.PHONE_REGEXP:
        case VALIDATOR_TYPES.PATTERN:
            return validate(value, (val: string | null) => {
                if (validatorValue && val) {
                    return RegExp(validatorValue, `g`).test(val);
                }
                return false;
            });
        case VALIDATOR_TYPES.FILE_MIME:
            return validate(value, (val: any) => {
                if (Array.isArray(validatorValue) && val.type) {
                    return validatorValue.includes(val.type);
                }
                return false;
            });
        case VALIDATOR_TYPES.MAX_FILE_SIZE:
            return validate(value, (val: any) => {
                if (validatorValue && val.size) {
                    return val.size <= validatorValue;
                }
                return false;
            });

        default:
            return true;
    }
}

function checkIsValid(scheme: AttributeExtendedType): boolean {
    // @ts-ignore
    const validators = Object.keys(scheme.validator).filter(validator => scheme.validator[validator] !== ``);
    if (validators && validators.length) {
        // @ts-ignore
        return !validators.some((validator: ValidatorType) => !isValid(scheme.value || ``, validator, scheme.validator[validator]));
    }
    return true;
}

export {
    ATTRIBUTE_TYPES,
    ATTRIBUTE_TYPE_MAP,
    VALIDATOR_TYPES,
    validatorsToTemplateErrorMapping,
    isValid,
    checkIsValid,
};
