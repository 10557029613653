<template>
<FwFormInput
    class='h-flex-1-0 h-mh-20 qa-choose-language'
    labelType='label-medium'
    :title='$t("LABEL.PLACEHOLDER.CHOOSE_LANG")'>
    <div class='fw-select-base b-settings-reminder__list__select'>
        <FwSelect
            v-model='value'
            class='fw-select-with-icon--big'
            type='white'
            :withCircles='true'
            :clearable='false'
            :canNotDelete='true'
            :searchable='false'
            label='text'
            :options='options'
            @input='changeLocal'>
        </FwSelect>
    </div>
</FwFormInput>
</template>

<script lang='ts'>
import SimpleVueValidation from 'simple-vue-validator';
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import { Component, Vue } from 'vue-property-decorator';
import { LangOption } from '@/types/Lang';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { User } from '@/types/User';
import { getErrorsTemplate } from '@/helpers/translate';

const GlobalStore = namespace('GlobalStore');

@Component
export default class ChooseSiteLang extends Vue {
    @GlobalStore.Mutation('mutationLoginUser') mutationLoginUser!: (user: User) => void;
    value: LangOption = {
        value: 'fr',
        text: 'Fr',
    };
    options: Array<LangOption> = [
        {
            value: 'fr',
            text: 'Français',
        },
        {
            value: 'en',
            text: 'English',
        },
    ];

    async changeLocal(lang: LangOption) {
        try {
            if (this.user() && this.user().id) {
                const { data } = await AuthWebApi.editUserAccount({ locale: lang.value }, this.user().id);
                this.mutationLoginUser(data.manager);
                this.$i18n.locale = data.manager.locale;
            } else {
                this.$i18n.locale = lang.value;
            }
            dayjs.locale(this.$i18n.locale === 'fr' ? fr : this.$i18n.locale);
            SimpleVueValidation.extendTemplates(getErrorsTemplate((this.$i18n.locale as 'fr' | 'en')));
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    created() {
        const storageLocal = this.user().locale || this.$i18n.locale;
        if (storageLocal) {
            const optionLocal = this.options.find(item => item.value === storageLocal);
            if (optionLocal) {
                this.value = optionLocal;
            } else {
                this.value = this.options[0];
            }
        } else {
            this.value = this.options[0];
        }
    }
}
</script>

<style lang='sass'>
.h-width-300
    width: 300px
</style>
