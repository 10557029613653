<template>
<div class='b-geografic-popup h-flex h-flex-dir-column h-flex-justify-star'>
    <div class='b-geografic-popup--main'>
        <div class='b-back-button h-inline-flex h-pointer'
             @click='goBack'>
            <FwIcon
                class='h-flex-center h-pointer'
                icon='arrow-left'
                size='14'
                color='rgba(33,63,107,.3)'>
            </FwIcon>
            <p class='h-bold h-mv-0 h-ml-10 h-font-14'>
                {{ $t('BUTTON.BACK') }}
            </p>
        </div>
        <div class='b-geografic-popup--main__row'>
            <div class='b-geografic-popup--main__buttons'>
                <div class='h-width-300'>
                    <FwInput
                        class='h-flex-1 h-ml-15'
                        :value='searchText'
                        :placeholder='$t(`WORKER.FORM.GEOGRAFIC.SEARCH.PLACEHOLDER`)'
                        iconRight='loupe'
                        iconSize='15'
                        inputStyle='white-bigger-search'
                        type='search'
                        @input='$emit("searchInputMethod", searchText)'
                        @update:value='value => searchText = value'>
                    </FwInput>
                </div>
                <div class='h-ml-20'>
                    <FwCheckbox
                        v-model='isAllDepartment'
                        :value='isAllDepartment'
                        :styles='{
                            justifyContent: "flex-end"
                        }'
                        :iconStyles='{
                            paddingRight: `20px`,
                            height: `60px`,
                        }'
                        type='white-with-text-transparent'
                        :itemWidth='`290px`'
                        :text='$t(`WORKER.FORM.GEOGRAFIC.SELECT.ENTIRE.DISTRICT`)'
                        name='checkbox'
                        :itemCheckedRight='false'
                        isSquare
                        @change='updateDepartment'>
                    </FwCheckbox>
                </div>
            </div>
            <FwButton
                class='h-ph-20'
                borderRadiusType='small-border'
                fontSize='14px'
                @click.native='addCitiesMethod'>
                {{ $t('BUTTON.ADD') }}
            </FwButton>
        </div>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else
         style='overflow: auto;'
         class='h-flex h-flex-wrap h-flex-justify-start'>
        <div v-for='department in countryDepartments'
             :key='department.id'
             class='h-m-10 h-flex h-flex-wrap b-geografic-popup__checkbox__wrapper'>
            <FwCheckbox
                :id='department.id'
                :key='department.id'
                v-model='checkboxCities'
                class='icon-white__background b-geografic-popup__checkbox'
                :value='department.id'
                :disabled='isAllDepartment'
                type='white-with-text'
                :itemWidth='`290px`'
                :itemCheckedRight='false'
                isSquare
                :styles='{
                    borderRadius: 0
                }'
                :iconStyles='{
                    paddingRight: `20px`,
                    borderRight: `1px solid #BDC6D3`,
                    height: `60px`,
                }'
                isBorderRight
                :text='`${department.post_code} - ${department.name}`'
                name='checkbox'
                @change='setDepartment(department)'>
            </FwCheckbox>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { clone, unionWith } from 'ramda';

import {
    POSITION_NAME,
} from '@/helpers/roles';
import { GeograficTypes, GeograficCitiesType } from '@/types/Appointment';

import ValidationMixin from '@/mixins/validation';
import PermissionsMixin from '@/mixins/permissions';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    validators: {
        [POSITION_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class AddOrEditGeograficCitiesForm extends Mixins(ValidationMixin, PermissionsMixin) {
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @AppointmentStore.State('geograficCitiesData') geograficCitiesData!: Array<GeograficCitiesType>;
    @AppointmentStore.State('isCountry') isCountry!: boolean;
    @Prop({ type: Object, default: null }) readonly beforeDepartmentsCloneData!: Array<GeograficTypes>;
    @Prop({ type: Object, default: null }) readonly countryDepartments!: Array<GeograficTypes>;
    @Prop({ type: Object, default: [] }) readonly updatedDepartment!: GeograficTypes;
    @Prop({ type: Object, default: null }) readonly currentCity!: GeograficTypes;
    @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isCountryDepartments!: boolean;
    isRequestSending: boolean = false;
    isAllDepartment: boolean = false;
    searchText: string = '';
    checkboxCities: Array<string> = [];
    beforeDepartmentCloneData : Array<GeograficTypes> = [];

    get citiesWithoutCurrentDepartment() {
        return this.geograficCitiesData.filter(item => item.parent_id !== this.currentCity.id);
    }

    get dataFromCountryDepartments() {
        return this.countryDepartments.filter(item => this.checkboxCities.includes(item.id));
    }

    addCitiesMethod() {
        if (this.isAllDepartment) {
            let departmentObj = [];
            if (this.geograficCitiesData.some(item => this.currentCity.id === item.id)) {
                departmentObj = this.citiesWithoutCurrentDepartment;
            } else {
                departmentObj = [...this.geograficCitiesData, this.currentCity];
            }
            this.setGeograficCitiesData(unionWith((x, y) => x.id === y.id, departmentObj, this.geograficCitiesData));
        } else {
            const arr = unionWith((x, y) => x.id === y.id, this.geograficCitiesData, this.dataFromCountryDepartments);

            this.setGeograficCitiesData(arr);
        }
        if (this.geograficCitiesData.some(item => item.post_code === this.geoCountryIsoCode)) {
            const countryObj = this.geograficCitiesData.filter(item => item.post_code !== this.geoCountryIsoCode);
            this.setGeograficCitiesData(countryObj);
        }

        this.$emit('closePopup');
    }
    goBack() {
        let localGeograficCitiesData: any [];
        if (!this.isAllDepartment) {
            localGeograficCitiesData = this.geograficCitiesData.filter(department => department.id !== this.currentCity.id);
            this.setGeograficCitiesData(unionWith((x, y) => x.id === y.id, localGeograficCitiesData, this.dataFromCountryDepartments));
        } else {
            localGeograficCitiesData = unionWith((x, y) => x.id === y.id, this.geograficCitiesData, [this.currentCity]);
            this.setGeograficCitiesData(localGeograficCitiesData);
        }

        this.$emit('goBack');
    }
    updateDepartment() {
        if (this.isAllDepartment) {
            this.beforeDepartmentCloneData = clone(this.dataFromCountryDepartments);
            this.checkboxCities = [];
            this.setGeograficCitiesData(this.citiesWithoutCurrentDepartment);
        } else {
            const arr = this.beforeDepartmentCloneData.filter(item => item.id !== this.currentCity.id);
            this.checkboxCities = arr.map(item => item.id);
            this.setGeograficCitiesData(unionWith((x, y) => x.id === y.id, this.geograficCitiesData, arr));
        }
    }

    setDepartment(department: GeograficTypes) {
        const arrChosenDepartment = this.countryDepartments.filter(item => this.checkboxCities.includes(item.id));
        let arr = [];
        if (!this.checkboxCities.includes(department.id)) {
            arr = this.geograficCitiesData.filter(item => item.id !== department.id);
        } else {
            arr = this.geograficCitiesData;
        }

        this.setGeograficCitiesData(unionWith((x, y) => x.id === y.id, arr, arrChosenDepartment));
    }

    @Watch('currentCity')
    checkIsWholeDepartment() {
        this.isAllDepartment = this.checkboxCities.some(item => item === this.currentCity.id) ||
            this.geograficCitiesData.map(department => department.id).includes(this.currentCity.id);
    }

    created() {
        this.checkboxCities = this.geograficCitiesData.map(item => item.id);
    }
}
</script>

<style lang='sass'>
.b-geografic-popup
    height: calc(85vh - 150px)

    @include media('<=phone')
        &__checkbox
            width: 100%

            &__wrapper
                width: 100%

    &--main
        z-index: 1
        background-color: rgb(248, 249, 250)
        padding-bottom: 20px

        &__buttons
            display: flex
            @include media('<=phone')
                display: block

    .b-back-button
        top: 50px

    input.fw-input.fw-input--white-bigger-search::placeholder
        width: 100%
</style>
