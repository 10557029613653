<template>
<div class='b-calendar-body__footer__wrapper'
     :class='{ "b-calendar-body__footer__wrapper--with-aside": isAsideInfoShow }'>
    <div>
        <div class='b-calendar-body__footer'>
            <div class='b-calendar-body__column b-calendar-body__column--filter'>
                <!-- Free days -->
                Disponible(s)
            </div>
            <div v-for='index in daysInMonth'
                 :key='index'
                 class='b-calendar-body__cell b-calendar-body__cell--count'>
                <div class='b-calendar-body__cell__square'>
                    <div class='b-calendar-body__cell__square__sub' :style='getStyles'>
                        {{ daysData.free[index - 1] }}
                    </div>
                </div>
            </div>
        </div>
        <div class='b-calendar-body__footer'>
            <div class='b-calendar-body__column b-calendar-body__column--filter'>
                <!-- On-call days -->
                Astreinte(s)
            </div>
            <div v-for='index in daysInMonth'
                 :key='index'
                 class='b-calendar-body__cell b-calendar-body__cell--count'>
                <div class='b-calendar-body__cell__square'>
                    <div class='b-calendar-body__cell__square__sub' :style='getStyles'>
                        {{ daysData.onCall[index - 1] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkerAvatar } from '@/components/simple/WorkerAvatar';
import { CalendarCell } from '@/components/calendar/CalendarCell';
import { COUNT_TYPE, getColorType } from '@/components/calendar/helpers';

@Component({
    components: {
        WorkerAvatar,
        CalendarCell,
    },
})
export default class CalendarFooter extends Vue {
    @Prop({ type: Number, required: true }) readonly daysInMonth!: number;
    @Prop({ type: Boolean, default: false }) readonly isAsideInfoShow!: boolean;
    @Prop({ type: Object, required: true }) readonly daysData!: { free: Array<number>, onCall: Array<number> };
    @Prop({ type: Boolean, required: true }) readonly isColorblind!: boolean;

    get getStyles(): { [key: string]: string } {
        return { backgroundColor: (getColorType(this.isColorblind) as any)[COUNT_TYPE] };
    }
}
</script>
