<template>
<div>
    <template v-if='havePermissions'>
        <FwSpinLoader
            v-if='isLoading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader h-width-100p h-height-100p'
            :isActive='isLoading'
            className='h-p-20'>
        </FwSpinLoader>
        <template v-else-if='appointmentData'>
            <AddOrEditAppointmentByForm
                :asFullPage='true'
                :appointmentData='appointmentData'
                @cancel='closePopup'>
            </AddOrEditAppointmentByForm>
        </template>
    </template>
    <div v-else>
        <h1 class='h-font-20 h-pt-20 h-text-center h-max-width-80p'>
            {{ $t('MATMUT.HAVE_NOT_PERMISSIONS') }}
        </h1>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { AddOrEditAppointmentByForm } from '@/views/consult/add-appointment/AddOrEditAppointmentByForm';
import { AppointmentConsultSearchType } from '@/types/Appointment';
import { GoBackButton } from '@/components/simple/GoBackButton';

@Component({
    components: {
        AddOrEditAppointmentByForm,
        GoBackButton,
    },
})
export default class AddOrEditAppointmentByFormAsPage extends Vue {
    isLoading: boolean = false;
    appointmentData: AppointmentConsultSearchType | null = null;

    get havePermissions(): boolean {
        if (!this.$route.meta) return false;
        return this.currentUserRole && this.currentUserRole[this.$route.meta.rightBlockType] && this.currentUserRole[this.$route.meta.rightBlockType][this.$route.meta.right];
    }

    closePopup() {
        this.$router.push({ path: '/consult/choose' });
    }

    async created() {
        if (this.havePermissions) {
            try {
                this.isLoading = true;
                const data = await AppointmentWebApi.getAppointment(this.$route.params.id);
                this.appointmentData = data.appointment;
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>
