<template>
<div class='b-route-wrapper'>
    <portal-target name='popupWrapper'></portal-target>
    <FixedBackground></FixedBackground>
    <div class='b-main-panel-wrapper'>
        <AppHeader v-if='showAppHeader'></AppHeader>
        <div class='h-pos-rel container h-width-100p'>
            <GoBackButton
                v-if='showBackButton'
                class='b-route-wrapper__back_arrow'>
            </GoBackButton>
        </div>
        <router-view v-if='!accountDataLoading'></router-view>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';
import { FixedBackground } from '@/components/global/FixedBackground';
import { AppHeader } from '@/components/global/AppHeader';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { ProgressBarWrapper } from '@/components/global/ProgressBarWrapper';

const CommonStore = namespace('CommonStore');

@Component({
    components: {
        GoBackButton,
        FixedBackground,
        AppHeader,
        ProgressBarWrapper,
    },
})
export default class RouterWrapper extends Vue {
    @CommonStore.Action('fetchAccountTitles') fetchAccountTitles!: () => Promise<void>;
    accountDataLoading: boolean = false;

    get showAppHeader(): boolean {
        return this.$route.meta && this.$route.meta.showAppHeader;
    }

    get showBackButton(): boolean {
        return this.$route.meta && this.$route.meta.backButton;
    }

    get currentUserAuth(): boolean {
        return this.isUserAuth();
    }

    @Watch(`currentUserAuth`)
    isUserAuthHandler(auth: boolean) {
        if (auth) {
            this.fetchAccountTitles();
        }
    }

    async created() {
        if (this.currentUserAuth) {
            this.accountDataLoading = true;
            try {
                await this.fetchAccountTitles();
            } catch (e) {
                console.log(e);
            } finally {
                this.accountDataLoading = false;
            }
        }
    }
}
</script>

<style lang='sass'>
.b-route-wrapper
    width: 100%
    min-height: 100vh
    position: relative
    display: flex

    &__back_arrow
        left: 100px
        top: 47px

        @include media('<desktop')
            left: 20px
            top: 20px
            display: flex !important

.b-main-panel-wrapper
    width: 100%
    display: flex
    flex-direction: column

@include media('<desktop')
    .b-route-wrapper
        &__back_arrow
            margin-left: 10px
            margin-top: 20px
            position: static
</style>
