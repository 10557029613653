export default {
    'ERRORS.ONLY.ONE.SLOT': 'Sorry! You cannot create more than one unavailability/reserved slot in the same day.',
    'ERRORS.PERMISSION': 'Sorry you do not have the permission to access',
    'ERRORS.LOGIN.PASSWORD': 'Name or password is incorrect. Please, try again.',
    'ERRORS.404': 'Record not found',
    'ERRORS.401': 'Unauthorized',
    'ERRORS.403': 'You do not have access rights',
    'ERRORS.500': 'Internal server error',
    'ERRORS.SERVER.ISSUE': 'Sorry, server error! Please, try it later',

    'ERRORS.CALENDAR.NO.DATA': 'Calendar booking data is not exists',
};
