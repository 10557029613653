<template>
<div>
    <form class='b-add-branch__form'
          @keydown.stop.prevent.enter='onSubmit'>
        <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
            {{ $t('LABEL.CLIENT.INFORMATION') }}
        </h3>
        <div class='h-flex h-width-100p'>
            <FwFormInput
                class='h-flex-1-0 h-mh-25'
                :fieldHasError='fieldHasError(CLIENT_FULL_NAME)'
                labelType='label-medium'
                :title='`${$t("LABEL.NAME")}`'>
                <FwInput
                    :value='client_full_name'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CLIENT_FULL_NAME)'
                    @update:value='value => client_full_name = value'
                    @input='clearServerErrorsBase(CLIENT_FULL_NAME)'
                    @blur='addCurrentInputToValidateArray(CLIENT_FULL_NAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mh-25'
                style='flex: 0 0 260px'
                :fieldHasError='fieldHasError(CLIENT_PHONE)'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE`)}`'>
                <FwInput
                    :value='client_phone'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CLIENT_PHONE)'
                    @update:value='value => client_phone = value'
                    @input='clearServerErrorsBase(CLIENT_PHONE)'
                    @blur='addCurrentInputToValidateArray(CLIENT_PHONE)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-25'
                :fieldHasError='fieldHasError(CLIENT_EMAIL)'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}`'>
                <FwInput
                    :value='client_email'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CLIENT_EMAIL)'
                    @update:value='value => client_email = value'
                    @input='clearServerErrorsBase(CLIENT_EMAIL)'
                    @blur='addCurrentInputToValidateArray(CLIENT_EMAIL)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-width-100p h-mt-15'>
            <FwFormInput
                class='h-flex-1-0 h-mh-25'
                :fieldHasError='fieldHasError(CLIENT_ID)'
                labelType='label-medium'
                :title='`${$t(`LABEL.ID`)}`'>
                <FwInput
                    :value='client_id'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CLIENT_ID)'
                    @update:value='value => client_id = value'
                    @input='clearServerErrorsBase(CLIENT_ID)'
                    @blur='addCurrentInputToValidateArray(CLIENT_ID)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mh-25'
                style='flex: 1 0 27%'
                :fieldHasError='fieldHasError(CLIENT_ADDRESS)'
                labelType='label-medium'
                :title='`${$t(`LABEL.ADDRESS`)}`'>
                <FwInput
                    :value='client_address'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CLIENT_ADDRESS)'
                    @update:value='value => client_address = value'
                    @input='clearServerErrorsBase(CLIENT_ADDRESS)'
                    @blur='addCurrentInputToValidateArray(CLIENT_ADDRESS)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-width-100p h-mt-15'>
            <FwFormInput
                class='h-flex-1-0 h-mh-25'
                :fieldHasError='fieldHasError(COMPANY_NAME)'
                labelType='label-medium'
                :title='`${$t(`LABEL.COMPANY.NAME`)}`'>
                <FwInput
                    :value='company_name'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(COMPANY_NAME)'
                    @update:value='value => company_name = value'
                    @input='clearServerErrorsBase(COMPANY_NAME)'
                    @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mh-25'
                style='flex: 1 0 27%'
                :fieldHasError='fieldHasError(COMPANY_ADDRESS)'
                labelType='label-medium'
                :title='`${$t(`LABEL.COMPANY.ADDRESS`)}`'>
                <FwInput
                    :value='company_address'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(COMPANY_ADDRESS)'
                    @update:value='value => company_address = value'
                    @input='clearServerErrorsBase(COMPANY_ADDRESS)'
                    @blur='addCurrentInputToValidateArray(COMPANY_ADDRESS)'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';

import {
    CLIENT_FULL_NAME,
    CLIENT_PHONE,
    CLIENT_ID,
    CLIENT_ADDRESS,
    ADDRESS,
    CLIENT_EMAIL,
    COMPANY_NAME,
    BRANCH_PHONE,
    COMPANY_ADDRESS,
} from '@/helpers/events';
import { AppointmentSearchType } from '@/types/Appointment';
import ValidationMixin from '@/mixins/validation';

@Component({
    validators: {
        [CLIENT_FULL_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [CLIENT_PHONE](value: string) {
            return this.requiredValidator({ value });
        },
        [CLIENT_ID](value: string) {
            return this.requiredValidator({ value });
        },
        [CLIENT_ADDRESS](value: string) {
            return this.requiredValidator({ value });
        },
        [COMPANY_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [ADDRESS](value: string) {
            return this.requiredValidator({ value });
        },
        [BRANCH_PHONE](value: string) {
            return this.requiredValidator({ value });
        },
        [COMPANY_ADDRESS](value: string) {
            return this.requiredValidator({ value });
        },
    },
})

export default class PartnerAppointmentClientForm extends Mixins(ValidationMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly eventTime!: string;
    disabledForm: boolean = true;

    CLIENT_FULL_NAME: string = '';
    CLIENT_PHONE: string = '';
    CLIENT_EMAIL: string = '';
    CLIENT_ID: string = '';
    CLIENT_ADDRESS: string = '';
    COMPANY_NAME: string = '';
    ADDRESS: string = '';
    AFF_BRANCH_NAME: string = '';
    AFF_BRANCH_CODE: string = '';
    COMPANY_ADDRESS: string = '';
    client_full_name: string = '';
    client_phone: string = '';
    client_email: string = '';
    client_id: string = '';
    client_address: string = '';
    company_name: string = '';
    address: string = '';
    affiliate_branch_name: string = '';
    affiliate_branch_code: string = '';
    company_address: string = '';
    isActiveAdminRequestSending: boolean = false;
    isActiveAdmin: boolean = false;
    serverErrors: serverErrors = {
        company_name: null,
        client_name: null,
        client_surname: null,
        client_address: null,
        contract_number: null,
        location: null,
        advisor_name: null,
        advisor_phone: null,
        branches_name: null,
    };
    updateFormByServerValues() {
        if (this.appointmentData) {
            if (this.appointmentData.client_full_name) {
                this.client_full_name = this.appointmentData.client_full_name;
            }
            if (this.appointmentData.client_phone) {
                this.client_phone = this.appointmentData.client_phone;
            }
            if (this.appointmentData.client_email) {
                this.client_email = this.appointmentData.client_email;
            }
            if (this.appointmentData.client_external_id) {
                this.client_id = this.appointmentData.client_external_id;
            }
            if (this.appointmentData.client_address) {
                this.client_address = this.appointmentData.client_address;
            }
        }
    }

    created() {
        this.updateFormByServerValues();
    }

    @Emit('onSubmit')
    onSubmit() {}
}
</script>

<style lang='sass'>
.b-add-branch__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px

    &__row
        display: flex
        width: 100%
        margin-bottom: 0

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-branch__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7
</style>
