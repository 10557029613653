<template>
<div class='b-blue-background
        b-add-popup
        b-blue-background__opacity
        b-cancel-popup__select
        b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='450px'
        :title='$t(`PLAN.APPOINTMENT.CREATE.SKILL`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        :submitText='$t(`BUTTON.ADD`)'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <div style='min-width: 300px' class='h-m-20'>
                <FwFormInput
                    :fieldHasError='fieldHasError(SKILL_NAME)'
                    title=''>
                    <FwInput
                        :value='skill_name'
                        :placeholder='$t(`PLAN.APPOINTMENT.PLACEHOLDER.GIVE.TITLE`)'
                        type='text'
                        :buttonRight='$t(`BUTTON.ADD`)'
                        :buttonDisabled='!isFormValid'
                        :error='getFieldErrorText(SKILL_NAME)'
                        inputStyle='white-bigger-shadow'
                        @addTextFromInput='addTextFromInput'
                        @update:value='value => skill_name = value'
                        @input='clearServerErrorsBase(SKILL_NAME)'
                        @blur='addCurrentInputToValidateArray(SKILL_NAME)'>
                    </FwInput>
                </FwFormInput>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import {
    SKILL_NAME,
} from '@/helpers/events';

@Component({
    validators: {
        [SKILL_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class AddNewSkillPopup extends Mixins(ValidationMixin) {
    [SKILL_NAME]: string = '';
    SKILL_NAME: string = SKILL_NAME;
    serverErrors: serverErrors = {
        [SKILL_NAME]: null,
    };
    addTextFromInput(val: string) {
        this.$emit(`addTextFromInput`, this[SKILL_NAME]);
        this.$emit(`close`);
    }
};
</script>

<style lang='sass'>
.b-blue-background
    display: flex
    justify-content: center
    align-items: center
    .fw-popup__title
        font-size: 22px
        @include media('<=phone')
            font-size: 18px

    .fw-popup__close_popup
        @include media('<=phone')
            right: 22px
            top: 22px
    input::placeholder
        width: 100px
        text-overflow: ellipsis
.b-add-popup
    .fw-input__inner--button
        background: #ffffff
        border: none
        width: 80px
        height: 55px
        right: 5px
</style>
