<template>
<component
    :is='component'
    :to='to'
    class='b-role-card'>
    <span v-if='title'>
        {{ title }}
    </span>
    <slot name='content'></slot>
    <slot name='default'></slot>
</component>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class RoleCard extends Vue {
    @Prop({ type: Object, default: null }) readonly to!: any;
    @Prop({ type: String, required: true }) readonly title!: any;

    get component(): string {
        if (this.to) {
            return `router-link`;
        }
        return `span`;
    }
}
</script>

<style lang='sass'>
.b-role-card
    background-color: #ffffff
    padding: 0 20px
    min-height: 60px
    box-shadow: $big-box-shadow
    font-size: 16px
    font-weight: 500
    color: #213F6B
    border-radius: 10px
    justify-content: space-between
    display: flex
    align-items: center
</style>
