<template>
<div>
    <portal to='branch-statistic-header'>
        <StatisticsHeader
            :title='$t("SETTINGS.ROLE.RIGHTS.APPOINTMENT_CATEGORY")'
            :startDate='[from, to]'
            @asRange='dates => $emit("asRange", dates)'>
        </StatisticsHeader>
    </portal>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else-if='showStatistic'>
        <HorizontalStatistic
            :ordering='ordering'
            :data='data'
            :count='total'>
        </HorizontalStatistic>
        <KpiSelectCategory
            v-if='showSelectCategory'
            :category_names='categoryNamesFiltered'
            @search='category => $emit("search", category)'
            @clear='$emit("clear")'>
        </KpiSelectCategory>
        <StatisticTable
            :data='statisticTableData'>
        </StatisticTable>
    </template>
    <div v-else>
        {{ $t('LABEL.HAVE.NOT.DATA.FOR.PERIOD') }}
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { HorizontalStatistic } from '@/components/common/HorizontalStatistic';
import { StatisticTable } from '@/components/common/StatisticTable';
import { KpiSelectCategory } from '@/components/common/KpiSelectCategory';
import { StatisticsHeader } from '@/components/common/StatisticsHeader';
import { BranchKpiCategoryNamesType } from '@/types/Branch';
import BranchStatisticsMixin from '../BranchStatisticsMixin';

@Component({
    components: {
        HorizontalStatistic,
        StatisticTable,
        StatisticsHeader,
        KpiSelectCategory,
    },
})
export default class AppointmentBookedTypes extends Mixins(BranchStatisticsMixin) {}
</script>

