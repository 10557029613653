<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        :max-width='popupWidth'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        :width='width'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <div v-if='popupImg'>
                    <FwImage
                        :height='`160px`'
                        :src='requireImage(popupImg)'>
                    </FwImage>
                </div>
                <h2 class='h-font-22 h-mb-0'>
                    {{ $t(headerText) }}
                </h2>
                <p v-if='subMessage'
                   class='h-mt-10 h-mb-20 h-text-center'>
                    {{ subMessage }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-40'
                    :class='qaClassName'
                    borderRadiusType='small-border'
                    @click='$emit("close") && goToPage()'>
                    {{ $t('FINISH.POPUP.SUCCESS.CLOSE') }}
                </FwButton>
            </div>
            <slot name='subButtons'></slot>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class FinalePopup extends Vue {
    @Prop({ type: String, default: 'POPUP.SUCCESS.HEADER' }) readonly headerText!: str;
    @Prop({ type: String, default: null }) readonly subMessage!: string;
    @Prop({ type: String, default: '800px' }) readonly popupWidth!: string;
    @Prop({ type: String, default: null }) readonly width!: string;
    @Prop({ type: String, default: null }) readonly popupImg!: string;
    @Prop({ type: String, default: null }) readonly qaClassName!: string;

    @Emit('goToPage')
    goToPage() {
        return true;
    }
}
</script>
