<template>
<div>
    <portal to='branch-statistic-header'>
        <StatisticsHeader
            :title='$t("BRANCH.STATISTICS.DISTRIBUTION.APPOINTMENT")'
            :startDate='[from, to]'
            @asRange='dates => $emit("asRange", dates)'>
        </StatisticsHeader>
    </portal>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <template v-if='isDataExists'>
            <h1 v-if='countText'
                class='b-horizontal-statistics__title'>
                {{ $t(countText) }}
                <span v-if='total'>
                    : {{ total }}
                </span>
            </h1>
            <VerticalStatistic
                v-if='showStatistic'
                class='h-mv-30'
                :title='`${$t("BRANCH.STATISTICS.BEFORE")} ${$t(centerPoint)}`'
                color='#27DBAD'
                :data='data'
                :timeLine='beforeTime'>
            </VerticalStatistic>
            <VerticalStatistic
                v-if='showStatistic'
                class='h-mt-60'
                :title='`${$t("BRANCH.STATISTICS.AFTER")} ${$t(centerPoint)}`'
                color='#FFC500'
                :data='data'
                :timeLine='afterTime'>
            </VerticalStatistic>
        </template>
        <div v-else>
            {{ $t('LABEL.HAVE.NOT.DATA.FOR.PERIOD') }}
        </div>
    </template>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { EventDataType } from '@/types/Events';
import { VerticalStatistic } from '@/components/common/VerticalStatistic';
import { StatisticsHeader } from '@/components/common/StatisticsHeader';
import { mainCalendarTime } from '@/mocks/tableData';
import BranchStatisticsMixin from '../BranchStatisticsMixin';

@Component({
    components: {
        VerticalStatistic,
        StatisticsHeader,
    },
})
export default class AppointmentBookedByTimeSlot extends Mixins(BranchStatisticsMixin) {
    starts: { [key: string]: string } = {};
    centerPoint: string = 'CALENDAR.TIME.01.PM';
    lastPoint: string = 'CALENDAR.TIME.06.PM';

    get countText(): string {
        if (!this.$route.meta || !this.$route.params.type) {
            return '';
        }
        return this.$route.params.type === 'for' ? this.$route.meta.titleFor : this.$route.meta.titleBy;
    }

    get beforeTime(): Array<EventDataType> {
        return mainCalendarTime.slice(0, mainCalendarTime.findIndex(time => time.time === this.centerPoint));
    }

    get afterTime(): Array<EventDataType> {
        return mainCalendarTime.slice(mainCalendarTime.findIndex(item => item.time === this.centerPoint), mainCalendarTime.findIndex(item => item.time === this.lastPoint));
    }

    get isDataExists(): boolean {
        return Boolean(Object.keys(this.data).length);
    }
}
</script>
