<template>
<div class='b-onboarding__wrapper'>
    <div>
        <FwImage
            :height='`112px`'
            :src='requireImage("calendar-sync.svg")'>
        </FwImage>
    </div>
    <div class='b-onboarding__title'>
        {{ $t(`ONBOARDING.CALENDAR.SYNC.${workerAdvisorString}.TITLE`) }}
    </div>
    <div class='b-onboarding__aside'>
        {{ $t(`ONBOARDING.CALENDAR.SYNC.${workerAdvisorString}.SUBTITLE`) }}
    </div>

    <ol class='b-onboarding__ol h-p-0'>
        <div class='h-flex h-flex-center h-flex-dir-column'>
            <FwButton size='large-xl'
                      color='choose'
                      borderRadiusType='tiny-border'
                      class='h-m-10'
                      :fontSize='`16px`'
                      @click.native='inviteAllPopup = true'>
                {{ $t(`ONBOARDING.CALENDAR.SYNC.${workerAdvisorString}.BUTTON.INVITE`) }}
                <FwIcon icon='arrow-right'
                        size='12'
                        color='#27dbbd'>
                </FwIcon>
            </FwButton>
            <FwButton size='large-xl'
                      color='choose'
                      borderRadiusType='tiny-border'
                      class='h-m-10'
                      :fontSize='`16px`'
                      :to='`${mainRouteGlobal}/invitations/choose-${workerAdvisor}s`'>
                {{ $t(`ONBOARDING.CALENDAR.SYNC.BUTTON.${workerAdvisorString}.CONFIRM`) }}
                <FwIcon icon='arrow-right'
                        size='12'
                        color='#27dbbd'>
                </FwIcon>
            </FwButton>
        </div>
    </ol>
    <InvitationPreviewPopup
        v-if='inviteAllPopup'
        :headerText='$t(`INVITATION PREVIEW`)'
        @close='inviteAllPopup = false'
        @invitationStatusSent='invitationStatusSent'>
    </InvitationPreviewPopup>
    <InProgressPopup
        v-if='showInprogressPopup'
        :title='$t(`ONBOARDING.POPUP.PROGRESS.TITLE`)'
        :subMessage='$t(`ONBOARDING.POPUP.PROGRESS.TEXT`)'>
    </InProgressPopup>
    <FinalePopup
        v-if='showFinalePopup'
        :popupWidth='`400px`'
        :popupImg='`success.svg`'
        :headerText='$t(`ONBOARDING.INVITATION.FINALE.SET.TITLE`)'
        :subMessage='$t(`ONBOARDING.INVITATION.FINALE.SET.TEXT`)'
        @goToPage='goToNextPage'>
    </FinalePopup>
    <FwButton class='h-m-20'
              color='transparent'
              :fontColor='`rgba(33, 63, 107, 0.3)`'
              :to='"/onboarding/success"'>
        {{ $t('BUTTON.LATER') }}
    </FwButton>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InvitationPreviewPopup } from '@/components/popups/InvitationPreviewPopup';
import { InProgressPopup } from '@/components/popups/InProgressPopup';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { WorkersWebApi } from '@/api/workers/WorkersApi';

@Component({
    components: {
        InvitationPreviewPopup,
        InProgressPopup,
        FinalePopup,
    },
})
export default class OnboardingCalendarSyncPage extends Vue {
    @Prop({ type: String, default: '' }) readonly workerAdvisorString!: string;
    inviteAllPopup: boolean = false;
    showInprogressPopup: boolean = false;
    showFinalePopup: boolean = false;
    get workerAdvisor() {
        return this.mainRouteGlobal === `/partners-onboarding` ? 'technician' : 'advisor';
    }
    goToSpecific() {
        console.log('specific');
    }
    goToNextPage() {
        this.$router.push(`/onboarding/success`);
    }
    async invitationStatusSent() {
        this.showInprogressPopup = true;
        try {
            this.inviteAllPopup = false;
            await WorkersWebApi.confirmAdvisorsAllInvitationData(true);
            setTimeout(() => {
                this.showInprogressPopup = false;
                this.showFinalePopup = true;
            }, 1000);
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }
}
</script>

<style lang='sass'>
    .b-onboarding
        &__wrapper
            padding: 110px 0
            display: flex
            flex-direction: column
            align-items: center

        &__title
            margin-top: 20px
            font-size: 30px
            line-height: 37px
            letter-spacing: 0.37px
            font-weight: 600

        &__aside
            margin-top: 9px
            font-size: 16px

        &__ol
            max-width: 600px
            margin-top: 10px
            font-size: 22px
            line-height: 30px
            font-weight: 600
            counter-reset: list

            li
                margin-top: 20px
                list-style-type: none

                &:before
                    list-style-type: none
                    counter-increment: list
                    content: counter(list) ' - '
</style>
