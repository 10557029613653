<template>
<div class='h-form-comment h-flex h-flex-dir-column' @click.stop.prevent>
    <GoBackButton
        class='h-mb-30 h-width-100'
        isRelativePosition
        :method='backToCommentsList'>
    </GoBackButton>
    <form class='b-form-tab h-flex h-width-100p'
          @keydown.stop.prevent.enter.once='onSubmitComment'>
        <FwFormInput
            class='h-mh-15'
            style='width: 100%'
            :fieldHasError='fieldHasError("static.notes")'
            labelType='label-medium'
            :title='$t("CONSULT.APPOINTMENT.COMMENT")'>
            <FwInput
                class='fw-input__white-bigger'
                componentType='textarea'
                :value='notes'
                type='text'
                name='text'
                :placeholder='$t("CONSULT.APPOINTMENT.COMMENT")'
                :error='getFieldErrorText("notes")'
                inputStyle='textarea'
                @update:value='value => notes = value'
                @input='clearServerErrorsBase("notes")'
                @blur='addCurrentInputToValidateArray("notes")'>
            </FwInput>
        </FwFormInput>
        <div class='h-flex h-flex-dir-column h-align-items-center h-width-100p'>
            <FwButton
                class='h-mt-20'
                :disabled='!notes || !isFormValid'
                borderRadiusType='tiny-border'
                @click.native.stop.prevent='onSubmitComment'>
                {{ $t('BUTTON.SAVE') }}
            </FwButton>
            <div
                v-if='comment'
                class='b-form-tab__delete h-mt-20'
                @click.stop.prevent='onSubmitDelete'>
                {{ $t('LABEL.DELETE') }}
            </div>
        </div>
    </form>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
    NOTES,
} from '@/helpers/events';
import { CommentData } from '@/types/Comment';
import ValidationMixin from '@/mixins/validation';
import AccountMixin from '@/mixins/account';
import { FormAttributes } from '@/components/forms/attributes';
import { GoBackButton } from '@/components/simple/GoBackButton';

const CommonStore = namespace('CommonStore');

@Component({
    components: { FormAttributes, GoBackButton },
    validators: {
        [NOTES](value: string) {
            return this.baseInputValidator({ value, maxLength: 500 });
        },
    },
})

export default class InternalAppointmentCommentForm extends Mixins(ValidationMixin, AccountMixin) {
    @Prop({ type: Object, default: null }) readonly comment!: CommentData;
    disabledForm: boolean = true;

    NOTES: string = '';
    notes: string = '';
    serverErrors: serverErrors = {
        notes: null,
    };
    schemaClass: string = 'h-flex-1-0 h-mh-25';

    backToCommentsList() {
        this.$emit('backToCommentsList');
    }

    created() {
        if (this.comment && this.comment.content) {
            this.notes = this.comment.content;
        }
    }

    onSubmitComment() {
        this.$emit('submitComment', this.notes);
    }

    onSubmitDelete() {
        this.$emit('deleteComment', this.comment.id);
    }
}
</script>

<style lang='sass'>
    .b-form-tab
        display: flex
        flex-wrap: wrap
        margin: 0 -15px

        &__row
            display: flex
            width: 100%
            margin-bottom: 0

            @include media('<=phone')
                flex-direction: column
                padding: 0 15px
                margin-bottom: 0 !important

                .b-form-tab__input
                    margin: 8px 15px

        &__input
            flex: 1 0
            margin: 0 15px
            input
                &:disabled
                    color: darkgray

        &__delete
            text-transform: uppercase
            margin-top: 20px
            font-size: 12px
            color: #FF004D
            font-weight: bold
            cursor: pointer
            text-align: center

            &:hover
                opacity: 0.7
    .h-form-comment
        textarea
            min-height: 150px
            overflow: auto
</style>
