export default {
    'REMINDERS.MAX': 'Reminders (max 2 reminders)',
    'REMINDERS.BOOKING': 'Booking',
    'REMINDERS.UPDATE': 'Update',
    'REMINDERS.CANCELLATION': 'Cancellation',
    'REMINDERS.REMINDER': 'Reminder',

    'REMINDERS.2.HOURS': '2 hours before',
    'REMINDERS.1.DAY': '1 day before',
    'REMINDERS.2.DAYS': '2 days before',
    'REMINDERS.1.WEEK': '1 week before',

    'REMINDERS.FINAL.CUSTOMER': 'Final customer',
};
