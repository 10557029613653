<template>
<div class='b-add-hub-team__wrapper container'>
    <form @keydown.stop.prevent.enter='onSubmit'>
        <div class='h-flex h-flex-space-between h-mb-30'>
            <FwFormInput
                class='h-flex-1-0'
                :fieldHasError='fieldHasError(TEAM_NAME)'
                labelType='label-medium'
                :title='`${$t(`ONBOARDING.OPTION.TEAMS.FORM.TITLE`)}*`'>
                <FwInput
                    class='qa-hub-name-input'
                    :value='team_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`ONBOARDING.OPTION.TEAMS.FORM.TITLE`)'
                    inputStyle='white-bigger-shadow'
                    :error='getFieldErrorText(TEAM_NAME)'
                    @update:value='value => team_name = value'
                    @input='clearServerErrorsBase(TEAM_NAME)'
                    @blur='addCurrentInputToValidateArray(TEAM_NAME)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-mb-30 b-required-field'>
            * {{ $t(`LABEL.REQUIRED.FIELD`) }}
        </div>
    </form>
    <div class='h-flex h-flex-center h-flex-dir-column'>
        <FwButton
            class='qa-hub-name-submit'
            size='little'
            :disabled='disabledFormButton || isRequestSending'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <div v-if='currentRoute && canDelete'
             class='b-add-branch__form__delete qa-hub-page-delete-button'
             :disabled='isRequestSending'
             @click='$emit("delete")'>
            {{ $t('HUB.FORM.DELETE.POPUP.BUTTON') }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins, Emit, Prop } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import { TEAM_NAME } from '@/helpers/hub';
import { SitesApi } from '@/api/sites/SitesApi';
import { BranchSitesDataType } from '@/types/Site';
import PermissionsMixin from '@/mixins/permissions';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    validators: {
        [TEAM_NAME](value: string) {
            return this.requiredValidator({ value });
        },
    },
})
export default class AddOrEditHubForm extends Mixins(ValidationMixin, PermissionsMixin) {
    @WorkerPageStore.State('hubStateData') hubStateData!: BranchSitesDataType;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    team_name: string | null = '';
    startTeamName: string | null = '';
    serverErrors: serverErrors = {
        team_name: null,
    };

    get disabledFormButton(): boolean {
        if (this.currentRoute) {
            return this.localFormIsInvalid || this.team_name === this.startTeamName;
        }
        return this.localFormIsInvalid;
    }

    get localFormIsInvalid(): boolean {
        return !this.team_name;
    }

    get currentRoute() {
        return this.$route.params.id;
    }

    async created() {
        if (this.currentRoute) {
            const existHub = { hub: this.hubStateData };
            const { hub } = this.hubStateData ? existHub : await SitesApi.getHub(this.currentRoute);
            this.team_name = hub.name;
            this.startTeamName = hub.name;
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return {
            name: this.team_name,
        };
    }
}
</script>
