import { LoginPage } from '@/views/auth/LoginPage';
import { LoginSsoPage } from '@/views/auth/LoginSsoPage';
import { LoginCompanyPage } from '@/views/auth/LoginCompanyPage';
// import { LoginMicrosoftPage } from '@/views/auth/LoginMicrosoftPage';
// import { AuthPage } from '@/views/auth/AuthPage';
import { ResetPasswordEmailPage } from '@/views/auth/ResetPasswordEmailPage';
// import { ResetPasswordPage } from '@/views/auth/ResetPasswordPage';
import { LogOutPage } from '@/views/auth/LogOutPage';

export default [
    {
        path: '/login-company',
        alias: '/',
        name: 'LoginCompanyPage',
        component: LoginCompanyPage,
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    {
        path: '/sso',
        alias: '/',
        name: 'LoginSsoPage',
        component: LoginSsoPage,
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    {
        path: '/logout',
        name: 'LogOutPage',
        component: LogOutPage,
        meta: {
            hideHeader: true,
        },
    },
    // {
    //     path: '/login-with-microsoft',
    //     alias: '/',
    //     name: 'LoginMicrosoftPage',
    //     component: LoginMicrosoftPage,
    //     meta: {
    //         notAuthRequired: true,
    //         hideHeader: true,
    //     },
    // },
    // {
    //     path: '/auth',
    //     alias: '/',
    //     name: 'AuthPage',
    //     component: AuthPage,
    //     meta: {
    //         notAuthRequired: true,
    //         hideHeader: true,
    //     },
    // },
    {
        path: '/reset-password-email',
        name: 'ResetPasswordEmailPage',
        component: ResetPasswordEmailPage,
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    // {
    //     path: '/reset-password',
    //     name: 'ResetPasswordPage',
    //     component: ResetPasswordPage,
    //     meta: {
    //         notAuthRequired: true,
    //         hideHeader: true,
    //     },
    // },
];
