import GlobalStore from './GlobalStore';
import CommonStore from './CommonStore';
import CalendarPageStore from './CalendarPageStore';
import CalendarStore from './CalendarStore';
import WorkerPageStore from './WorkerPageStore';
import AppointmentStore from './AppointmentStore';
import TeamsStore from './TeamsStore';
import BreadCrumbsStore from './BreadCrumbsStore';
import UserStore from './UserStore';
import OnboardingStore from './OnboardingStore';
import SettingsStore from './SettingsStore';
import StatisticsStore from './StatisticsStore';
import ConsultStore from './ConsultStore';

export default {
    GlobalStore,
    CommonStore,
    CalendarStore,
    CalendarPageStore,
    SettingsStore,
    WorkerPageStore,
    AppointmentStore,
    TeamsStore,
    BreadCrumbsStore,
    UserStore,
    OnboardingStore,
    StatisticsStore,
    ConsultStore,
};
