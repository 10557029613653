export default {
    'RECAP.TITLE': 'Recap',
    'RECAP.SUBTITLE': 'Please check all the details below before confirming.',
    'RECAP.KIND.LABEL': 'Appointments will be booked with people from:',
    'RECAP.KIND.INDIVIDUAL.COMPANY': 'My company',
    'RECAP.KIND.INDIVIDUAL.PARTNER': 'Partner companies',

    'RECAP.KIND.ORGANIZATION.LABEL': 'Advisors are attached to:',
    'RECAP.KIND.ORGANIZATION.HUB': 'A hub',
    'RECAP.KIND.ORGANIZATION.BRANCH': 'A branch',
    'RECAP.KIND.ORGANIZATION.HUB.BRANCH': 'Hubs and branches',
    'RECAP.KIND.ORGANIZATION.APPT.TYPES': 'Appointment types',
    'RECAP.KIND.ORGANIZATION.HUBS': 'Hubs',
    'RECAP.KIND.ORGANIZATION.BRANCHES': 'Branches',
    'RECAP.KIND.ORGANIZATION.ADVISORS': 'Advisors',

    'RECAP.YOU.HAVE.ADDED': 'You have added',
};
