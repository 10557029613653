export default {
    'PLAN.KIND.APPOINTMENT': 'What kind of appointment',
    'PLAN.CALENDAR.APPOINTMENT.HEADER': 'Plan an appointment',
    'PLAN.CHOOSE.APPOINTMENT': 'Choose an appointment',
    'PLAN.HEADER': 'Intervention code',
    'PLAN.IWS.CODE': 'IWS code',
    'PLAN.COHERIS.CODE': 'Coheris code',
    'PLAN.SOCIETY.NAME': 'Company selection',
    'PLAN.SOCIETY.LABEL': 'Company name',
    'PLAN.SOCIETY.PLACEHOLDER': 'Search a company',
    'PLAN.SOCIETY.NO.ELEMENT.FOUND': 'No elements found. Consider changing the search query',
    'APPOINTMENT.SUCCESS.POPUP.TXT': 'Appointment booked successfully',
    'APPOINTMENT.SUCCESS.TEXT': 'Appointment booked',
    'APPOINTMENT.UPDATED.SUCCESS.TEXT': 'Appointment updated',
    'POPUP.SUCCESS.HEADER': 'Success !',

    'PLAN.APPOINTMENT.ADD.HEADER': 'Add an appointment type',
    'PLAN.APPOINTMENT.EDIT.HEADER': 'Edit an appointment type',
    'PLAN.APPOINTMENT.DELETE.HEADER': 'Delete an appointment type',
    'PLAN.APPOINTMENT.NO.EVENTS': 'You don\'t have any skill added yet',
    'PLAN.APPOINTMENT.CREATE.SKILL': 'Create a skill',

    'PLAN.APPOINTMENT.BUSINESS.PARTNER.TITLE': 'Business partner companies',
    'PLAN.APPOINTMENT.BUSINESS.PARTNER.ADD': 'Add a business partner company',
    'PLAN.APPOINTMENT.BUSINESS.PARTNER.EDIT': 'Edit a business partner company',

    'PLAN.APPOINTMENT.PLACEHOLDER.GIVE.TITLE': 'Give a name to the skill',

    'PLAN.APPOINTMENT.ADDRESS.ALL': 'All',
    'PLAN.APPOINTMENT.ADDRESS.ON.SITE': 'On site',
    'PLAN.APPOINTMENT.ADDRESS.BY.PHONE': 'By phone',
    'PLAN.APPOINTMENT.ADDRESS.BY.VIDEO': 'By Online Video',
    'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.FACE': 'In Branch - Face to Face - Public',
    'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.VIDEO': 'In branch - Videoconference',
    'PLAN.APPOINTMENT.IN.BRANCH.PRIVATE': 'In Branch - Face to Face - Private',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PUBLIC': 'In Branch - By Phone - Public',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PUBLIC': 'In Branch - By Video - Public',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PRIVATE': 'In Branch - By Phone - Private',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PRIVATE': 'In Branch - By Video - Private',

    'PLAN.APPOINTMENT.WORKERS.TITLE': 'Advisors are attached to',
    'PLAN.APPOINTMENT.WORKERS.HUB': 'A hub',
    'PLAN.APPOINTMENT.WORKERS.BRANCH': 'A branch',
    'PLAN.APPOINTMENT.WORKERS.BOTH': 'Both',

    'PLAN.APPOINTMENT.BRANCH.ROOMS.TITLE': 'Add room to this branch',
    'PLAN.APPOINTMENT.BRANCH.ROOMS.ADD.TITLE': 'Add a room',
    'PLAN.APPOINTMENT.BRANCH.ROOMS.NAME': 'Name',
    'PLAN.APPOINTMENT.BRANCH.ROOMS.ASIDE': 'How many room do you want to add?',
    'PLAN.APPOINTMENT.BRANCH.VIDEO.ROOM': 'Video room',

    'PLAN.APPOINTMENT.BRANCH.LIST.NAME': 'Branch\'s name',

    'PLAN.BRANCH.TITLE.ADD': 'Add branch',
    'PLAN.BRANCH.TITLE.EDIT': 'Edit branch’s details',
    'PLAN.BRANCH.DELETE.TXT': 'DELETE THIS BRANCH',
    'PLAN.BRANCH.CHOOSE.ROOM': 'Choose a branch to add a room',
    'PLAN.BRANCH.PLACEHOLDER.ROOM': 'Search a room',
};
