<template>
<div class='b-add-worker__wrapper container h-pb-50'>
    <AppointmentAddPartnerWorker
        v-if='mainRouteGlobal === `/partners-onboarding`'>
    </AppointmentAddPartnerWorker>
    <AppointmentAddHubBranchAdvisor
        v-else>
    </AppointmentAddHubBranchAdvisor>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AppointmentAddPartnerWorker } from '@/components/forms/add/AppointmentAddPartnerWorker';
import { AppointmentAddHubBranchAdvisor } from '@/components/forms/add/AppointmentAddHubBranchAdvisor';

@Component({
    components: {
        AppointmentAddPartnerWorker,
        AppointmentAddHubBranchAdvisor,
    },
})
export default class AppointmentAddWorker extends Vue {
}
</script>

