<template>
<div class='b-consult-branches b-page-container b-page-container--big'>
    <GoBackButton
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <div v-if='companyStateData'
         class='h-flex h-flex-center h-flex-space-between h-mb-10'>
        <h1>
            {{ companyStateData.title }}
        </h1>
        <div class='h-flex'>
            <FwButton
                v-if='currentUserRole && currentUserRole.calendar_entity_rights.read'
                :to='`/consult/calendar/company/${$route.params.id}`'
                class='h-white-space-nowrap'
                size='little'
                fontSize='12px'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.SEE_CALENDAR') }}
            </FwButton>
        </div>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else class='b-consult-card__wrapper'>
        <div class='b-consult-card'>
            <div class='b-consult-card__title'>
                <div>
                    {{ $t('HEADER.INFORMATION') }}
                </div>
                <FwIcon
                    v-if='canEdit'
                    class='h-pointer'
                    icon='edit-settings'
                    size='25'
                    color='#BEC7D4'
                    @click.native='isCompanyEditPopup = true'>
                </FwIcon>
            </div>
            <div class='b-consult-card__inner b-consult-card__scroll-inner'>
                <ConsultCard
                    v-for='information in prepareData(companyStateData)'
                    :key='information.title'
                    :information='information'>
                </ConsultCard>
                <div class='h-mt-40 h-flex h-flex-space-between'>
                    <div class='b-consult-card__address h-fw-700'>
                        {{ $t('COMPANIES.FORM.INPUT.ADDRESS.LABEL') }}
                    </div>
                    <FwIcon
                        v-if='canEdit'
                        class='h-pointer'
                        icon='edit-settings'
                        size='25'
                        color='#BEC7D4'
                        @click.native='goToCompanyAddress'>
                    </FwIcon>
                </div>
                <div class='b-consult-card__sub-aside'>
                    {{ companyStateData.address }}
                </div>
                <WorkingHoursUpdateInfo
                    v-if='addressUpdateData'
                    isAddress>
                </WorkingHoursUpdateInfo>
            </div>
        </div>
        <div v-if='currentUserRole.technician_rights.list'
             class='b-consult-card'>
            <div class='b-consult-card__title h-flex h-flex-space-between h-align-items-center'>
                <span class='h-m-0'>
                    {{ $tc('LABEL.TECHNICIANS', companyStateData.technicians.length) }}
                </span>
                <div class='b-consult-button-size'>
                    <FwButton
                        v-if='currentUserRole.technician_rights.create'
                        class='h-flex h-flex-center h-text-center'
                        size='small'
                        borderRadiusType='small-border'
                        fontSize='12px'
                        @click.native='addWorkerMethod'>
                        {{ $t('BUTTON.ADD') }}
                    </FwButton>
                </div>
            </div>
            <div class='h-mt-20 h-pb-30'>
                <div v-if='companyStateData.technicians.length !== 0'
                     class='b-consult-card__scroll-inner'>
                    <component
                        :is='currentUserRole.technician_rights.read ? "router-link" : "div"'
                        v-for='worker in companyStateData.technicians'
                        :key='worker.id'
                        :to='currentUserRole.technician_rights.read ? { path: `/consult/technician/${worker.id}` } : false'
                        class='b-consult-card__worker-wrapper'
                        :class='{ "h-pointer": currentUserRole.technician_rights.read }'>
                        <WorkerAvatar
                            :worker='worker'
                            nameClass='b-consult-card__worker-name'
                            size='small'
                            align='right'>
                        </WorkerAvatar>
                        <div class='b-consult-card__worker-arrow'>
                            <FwIcon
                                class='h-mh-5'
                                icon='arrow-right'
                                size='20'
                                color='#27dbbd'>
                            </FwIcon>
                        </div>
                    </component>
                </div>
                <div v-else class='h-width-100p'>
                    <h2 class='h-text-center'>
                        {{ $t('WORKER.NO.WORKER') }}
                    </h2>
                </div>
            </div>
        </div>
    </div>
    <AddOrEditAddressByForm
        v-if='isAddressUpdatePopup'
        :objectData='companyStateData'
        updateAction='ValidateCompanyAddress'
        updateType='Company'
        isAddress
        @close='isAddressUpdatePopup = false'>
    </AddOrEditAddressByForm>
    <AddOrEditCompanyByForm
        v-if='isCompanyEditPopup'
        @closePopup='closeCompanyPopup'>
    </AddOrEditCompanyByForm>
    <AddEditWorkerPopup
        v-if='isAddEditWorkerPopup'
        isWorkerAdd
        @closePopup='closeWorkerAddPopup'>
    </AddEditWorkerPopup>
    <InformationPopup
        v-if='isPreventPopupShow'
        :headerText='$t(`UPDATE.ADDRESS.EDIT.TITLE`)'
        :bodyText='$t(`UPDATE.ADDRESS.ALREADY.UPDATED`)'
        :buttonText='$t(`FINISH.POPUP.SUCCESS.CLOSE`)'
        @close='isPreventPopupShow = false'>
    </InformationPopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { workerDataType } from '@/types/User';
import { CompanyInformationDataType, CompanyData } from '@/types/Companies';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { workersData } from '@/mocks/userData';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { AddEditWorkerPopup } from '@/components/popups/AddEditWorkerPopup';
import { AddOrEditCompanyByForm } from '@/views/consult/add-companies/AddOrEditCompanyByForm';
import { WorkingHoursUpdateInfo } from '@/components/simple/WorkingHoursUpdateInfo/index';
import { AddOrEditAddressByForm } from '@/views/consult/update-address/AddOrEditAddressByForm';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { InformationPopup } from '@/components/popups/InformationPopup';
import PermissionsMixin from '@/mixins/permissions';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        ConsultCard,
        WorkerAvatar,
        InformationPopup,
        AddEditWorkerPopup,
        AddOrEditCompanyByForm,
        WorkingHoursUpdateInfo,
        AddOrEditAddressByForm,
        GoBackButton,
    },
})
export default class ConsultCompanyPage extends Mixins(PermissionsMixin) {
    @WorkerPageStore.Action('storeCompanyData') storeCompanyData!: Function;
    @WorkerPageStore.Mutation('setCompanyData') setCompanyData!: Function;
    @WorkerPageStore.Mutation('ADDRESS_UPDATE_DATA') setAddressUpdateData!: Function;
    @WorkerPageStore.State('companyStateData') companyStateData!: CompanyData;
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;
    workersData: Array<workerDataType> = workersData;
    isAddEditWorkerPopup: boolean = false;
    isAddressUpdatePopup: boolean = false;
    isPreventPopupShow: boolean = false;
    isCompanyEditPopup: boolean = false;
    loading: boolean = true;

    async getCompanyData() {
        try {
            const existCompany = { company: this.companyStateData };
            const data = this.companyStateData ? existCompany : await AppointmentWebApi.getSocietyItemData(this.$route.params.id);
            this.loading = false;
            this.setCompanyData(data.company);
            this.setAddressUpdateData(data.company.update_request);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    goToCompanyAddress() {
        if (this.addressUpdateData) {
            this.isPreventPopupShow = true;
        } else {
            this.isAddressUpdatePopup = true;
        }
    }

    addWorkerMethod() {
        this.isAddEditWorkerPopup = true;
    }

    closeWorkerAddPopup() {
        this.isAddEditWorkerPopup = false;
        this.getCompanyData();
    }

    closeCompanyPopup() {
        this.isCompanyEditPopup = false;
    }

    prepareData(company: any): Array<CompanyInformationDataType> {
        const companyDataArray: Array<CompanyInformationDataType> = [];
        if (company.siret) {
            companyDataArray.push({
                title: 'SIRET',
                aside: company.siret,
            });
        }
        if (company.phone) {
            companyDataArray.push({
                title: this.$i18n.t('COMPANIES.FORM.INPUT.PHONE.LABEL'),
                aside: company.phone,
            });
        }
        if (company.contact_email) {
            companyDataArray.push({
                title: this.$i18n.t('LABEL.EMAIL'),
                aside: company.contact_email,
            });
        }
        return companyDataArray;
    }
    created() {
        this.setCompanyData(null);
        this.getCompanyData();
    }
}
</script>

<style lang='sass'>
.b-consult-card__wrapper
    .b-consult-card__inner
        padding: 0 30px 40px

        @include media('<=phone')
            padding: 0 20px 20px

.b-consult-card
    &__wrapper
        margin: 0 -15px
        display: flex
        justify-content: space-between

    &__worker-wrapper
        display: flex
        padding: 10px 40px
        align-items: center
        justify-content: space-between

        &:hover
            cursor: pointer
            background-color: $light-gray

            .b-consult-card__worker-arrow
                transform: translate(5px, 0)

    &__worker-name
        flex: 1
        padding: 0
        font-size: 18px
        font-weight: 500
        color: $dark-blue

    &__scroll-inner
        max-height: 400px
        overflow: auto

    &__worker-arrow
        transform: translate(0, 0)
        transition: transform .4s ease-in-out

.b-page-title
    padding: 20px 0 10px
    display: flex
    align-items: center
    justify-content: space-between

@include media('<=phone')
    .b-page-title
        flex-wrap: wrap
        padding: 20px 15px

        & > div:not(:first-of-type)
            margin-left: 15px
</style>
