import { OnboardingMainPage } from '@/views/onboarding/OnboardingMainPage';
import { OnboardingChooseForWhoPage } from '@/views/onboarding/OnboardingChooseForWhoPage';
import { OnboardingSuccessPage } from '@/views/onboarding/OnboardingSuccessPage';
import { RouteType } from '@/types/router';
import { ONBOARDING } from '@/helpers/rights';

const OnboardingRoutes: Array<RouteType> = [
    {
        path: '/onboarding',
        name: OnboardingMainPage.name,
        component: OnboardingMainPage,
        meta: {
            showAppHeader: true,
            pageType: ONBOARDING,
            isFirstPage: true,
        },
    },
    {
        path: '/onboarding/choose',
        name: OnboardingChooseForWhoPage.name,
        component: OnboardingChooseForWhoPage,
        meta: {
            showAppHeader: true,
            backButton: true,
            pageType: ONBOARDING,
            isFirstPage: true,
        },
    },
    {
        path: '/onboarding/success',
        name: OnboardingSuccessPage.name,
        component: OnboardingSuccessPage,
        meta: {
            showAppHeader: true,
            pageType: ONBOARDING,
        },
    },
];

export default OnboardingRoutes;
