<template>
<div class='b-onboarding__wrapper'>
    <div>
        <FwImage
            :src='requireImage("onboarding.svg")'
            :height='`101px`'>
        </FwImage>
    </div>
    <div class='b-onboarding__title'>
        {{ $t('ONBOARDING.TITLE') }}
    </div>
    <div class='b-onboarding__aside'>
        {{ $t('ONBOARDING.ASIDE') }}
    </div>

    <ol class='b-onboarding__ol'>
        <li>
            {{ $t('ONBOARDING.OPTION.1') }}
        </li>
        <li>
            {{ $t('ONBOARDING.OPTION.2') }}
        </li>
    </ol>

    <FwButton
        class='h-mt-10'
        borderRadiusType='small-border'
        size='little'
        to='/onboarding/choose'>
        {{ $t('ONBOARDING.BUTTON') }}
    </FwButton>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class OnboardingMainPage extends Vue {
}
</script>

<style lang='sass'>
.b-onboarding
    &__wrapper
        padding: 110px 0
        display: flex
        flex-direction: column
        align-items: center

        @include media('<=phone')
            padding: 40px 0 80px !important

    &__title
        margin-top: 20px
        font-size: 30px
        line-height: 37px
        letter-spacing: 0.37px
        font-weight: 600

    &__aside
        margin-top: 9px
        font-size: 16px
        padding: 0 20px

        text-align: center

    &__ol
        margin-top: 10px
        font-size: 22px
        line-height: 30px
        font-weight: 600
        counter-reset: list

        li
            margin-top: 20px
            list-style-type: none

            &:before
                list-style-type: none
                counter-increment: list
                content: counter(list) ' - '
</style>
