export default {
    'RESET.PASSWORD.HEADER': 'Reset your password',
    'RESET.PASSWORD.HEADER.CHANGE': 'Change my password',
    'RESET.PASSWORD.CURRENT': 'Current password',
    'RESET.PASSWORD.NEW': 'New password',
    'RESET.PASSWORD.NEW.CONFIRM': 'Confirm your new password',
    'RESET.PASSWORD.CHARACTERS': '6 characters minimum',
    'RESET.PASSWORD.EYE': 'Click on the eye to show the password',
    'RESET.PASSWORD': 'Confirm password',
};
