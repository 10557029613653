<template>
<div class='b-onboarding__wrapper'>
    <div>
        <FwImage
            :height='`155px`'
            :src='requireImage("onboarding-success.svg")'>
        </FwImage>
    </div>
    <div class='b-onboarding__title'>
        {{ $t('ONBOARDING.FINALE.PAGE.TITLE') }}
    </div>
    <div class='b-onboarding__aside h-width-250 h-text-center'>
        {{ $t('ONBOARDING.FINALE.PAGE.TEXT') }}
    </div>
    <FwButton
        class='h-mt-20'
        to='/appointment/matmut/fetch-details'
        borderRadiusType='small-border'>
        {{ $t('ONBOARDING.FINALE.PAGE.BUTTON') }}
    </FwButton>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class OnboardingSuccessPage extends Vue {
}
</script>

<style lang='sass'>
.b-onboarding
    &__wrapper
        padding: 110px 0
        display: flex
        flex-direction: column
        align-items: center

    &__title
        margin-top: 20px
        font-size: 30px
        line-height: 37px
        letter-spacing: 0.37px
        font-weight: 600

    &__aside
        margin-top: 9px
        font-size: 16px

    &__ol
        max-width: 600px
        margin-top: 10px
        font-size: 22px
        line-height: 30px
        font-weight: 600
        counter-reset: list

        li
            margin-top: 20px
            list-style-type: none

            &:before
                list-style-type: none
                counter-increment: list
                content: counter(list) ' - '
</style>
