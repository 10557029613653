<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwSpinLoader
        v-if='isLoading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='isLoading'
        className='h-p-20'>
    </FwSpinLoader>
    <FwPopup
        v-else
        class='b-geo-zone-reassignment-popup qa-advisor-geo-zone-popup'
        :padding='isMobile() ? "30px 20px" : "50px"'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='780px'
        :title='$tc("ADVISOR.GEO_ZONE_REASSIGNMENT.PLURAL", 1)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='closePopup'>
        <template #body>
            <div class='b-geo-zone-reassignment-popup__inner'>
                <div class='b-geo-zone-reassignment-popup__header'>
                    <div class='b-geo-zone-reassignment-popup__avatar-wrapper'>
                        <WorkerAvatar
                            :worker='worker'
                            :avatarSize='35'
                            :hideInfo='true'>
                        </WorkerAvatar>
                        <div class='b-geo-zone-reassignment-popup__avatar__title'>
                            <span v-if='worker.first_name'>
                                {{ worker.first_name | capitalizeAll }}
                            </span>
                            <span v-if='worker.last_name'>
                                {{ worker.last_name | capitalizeAll }}
                            </span>
                        </div>
                    </div>
                    <div class='fw-select-base fw-select-white-modern b-geo-zone-reassignment-popup__select'>
                        <FwSelect
                            v-model='userTo'
                            class='qa-advisor-geo-zone-select-adv'
                            :propsPlaceholder='$t(`ADVISOR.GEO_ZONE_SELECT_ADVISOR`)'
                            type='sort-simple'
                            :options='users'
                            :searchable='true'
                            noElementsFoundText=''
                            :loading='isUserDataLoading'
                            :show-no-results='false'
                            :clear-on-select='false'
                            :hide-selected='true'
                            openDirection='bottom'
                            label='name'
                            showRightIcon>
                            <template #noOptions>
                                {{ $t('LABEL.NO.RESULT') }}
                            </template>
                            <template #noResult>
                                {{ $t('LABEL.NO.RESULT') }}
                            </template>
                        </FwSelect>
                    </div>
                </div>
                <FwTransferList
                    class='b-geo-zone-reassignment-popup__transfer-list  qa-advisor-geo-zone__transfer-list'
                    :options='options'
                    :model='model'
                    :allOptionsPostfix='[$tc("ADVISOR.GEO_ZONE_ELEMENTS", 1), $tc("ADVISOR.GEO_ZONE_ELEMENTS", 2)]'
                    :allModelsPostfix='[$tc("ADVISOR.GEO_ZONE_ELEMENTS", 1), $tc("ADVISOR.GEO_ZONE_ELEMENTS", 2)]'
                    @updateOptions='updateOptions'
                    @updateModel='updateModel'>
                </FwTransferList>

                <div class='b-geo-zone-reassignment-popup__time'>
                    <div class='b-geo-zone-reassignment-popup__time__title'>
                        {{ $t('ADVISOR.GEO_ZONE.WHEN_REASSIGNMENT_HAPPEN') }}
                    </div>
                    <div class='b-geo-zone-reassignment-popup__time__wrapper'>
                        <div class='b-geo-zone-reassignment-popup__time__inner'>
                            <FwDatepicker
                                v-model='dateFrom'
                                class='b-geo-zone-reassignment-popup__date'
                                name='datepicker'
                                :placeholder='$t(`ADVISOR.GEO_ZONE.START_DATE`)'
                                :propsRange='false'
                                :propsLang='`${$i18n.locale}`'
                                :selectMode='false'
                                pickerStyle='form'
                                hideIcon
                                propsType='date'
                                propsFormat='DD/MM/YYYY'
                                :openOnTop='true'
                                :disabledDate='disabledDateByObject'
                                propsInputClass='b-add-reserved-slot-form__datepicker'>
                            </FwDatepicker>
                        </div>
                        <div class='b-geo-zone-reassignment-popup__time__inner b-geo-zone-reassignment-popup__date--right'>
                            <FwDatepicker
                                v-model='dateTo'
                                class='b-geo-zone-reassignment-popup__date'
                                name='datepicker'
                                :placeholder='$t(`ADVISOR.GEO_ZONE.END_DATE`)'
                                :propsRange='false'
                                :propsLang='`${$i18n.locale}`'
                                :selectMode='false'
                                pickerStyle='form'
                                hideIcon
                                propsType='date'
                                propsFormat='DD/MM/YYYY'
                                :openOnTop='true'
                                :disabledDate='disabledDateByObject'
                                propsInputClass='b-add-reserved-slot-form__datepicker'>
                            </FwDatepicker>
                        </div>
                    </div>
                </div>

                <div class='h-flex-center h-pt-20'>
                    <FwButton
                        class='h-ph-20'
                        borderRadiusType='small-border'
                        fontSize='14px'
                        :disabled='addGeoDisabled'
                        size='base'
                        @click.native='addGeoZone'>
                        {{ $t('BUTTON.ADD') }}
                    </FwButton>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import { Component, Prop, Mixins, Watch, Emit } from 'vue-property-decorator';
import { workerDataType } from '@/types/User';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { AdvisorsWebApi } from '@/api/workers/AdvisorsApi';
import { GeoReassignmentsAdvisorType, GeoSiteOption } from '@/types/Consult';
import DateMixin from '@/mixins/dateMixin';
import { capitalizeAll } from '@/helpers/string';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class GeoZoneReassignmentPopup extends Mixins(DateMixin) {
    @Prop({ type: Object, required: true }) readonly worker!: workerDataType;

    isLoading: boolean = false;
    isUserDataLoading: boolean = false;
    dateFrom: null | Date = null;
    dateTo: null | Date = null;
    options: Array<GeoSiteOption> = [];
    model: Array<GeoSiteOption> = [];
    userTo: GeoReassignmentsAdvisorType | null = null;
    users: Array<GeoReassignmentsAdvisorType> = [];
    currentDate: Date = this.getDayDateAtNight();

    get addGeoDisabled(): boolean {
        return !(this.dateTo && this.dateFrom && this.userTo && this.model.length);
    }

    get disabledDateByObject(): { disablePrevDays: Date | number } {
        return {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate() + 1) : new Date(),
        };
    }

    updateOptions(models: Array<GeoSiteOption>): void {
        this.model = this.model.filter(model => !models.map(x => x.id).includes(model.id));
        this.options = this.options.concat(models);
    }

    updateModel(options: Array<GeoSiteOption>): void {
        this.options = this.options.filter(option => !options.map(x => x.id).includes(option.id));
        this.model = this.model.concat(options);
    }

    async doRequest(): Promise<void> {
        this.isLoading = true;
        try {
            const data = await AdvisorsWebApi.geoReassignments(this.$route.params.id);
            this.users = data.advisors.map(item => ({ ...item, ...{ name: capitalizeAll(`${item.first_name} ${item.last_name}`) }}));
            this.options = data.geo_sites.map(item => ({ ...item, ...{ title: `${item.post_code} - ${item.name}` }}));
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoading = false;
        }
    }

    async addGeoZone(): Promise<void> {
        if (this.addGeoDisabled) return;
        try {
            await AdvisorsWebApi.doGeoReassignments({
                user_from_id: this.$route.params.id,
                site_ids: this.model.map(item => item.id),
                due_from: dayjs(this.dateFrom).format(`YYYY-MM-DD`),
                due_to: dayjs(this.dateTo).format(`YYYY-MM-DD`),
                user_id: (this.userTo as GeoReassignmentsAdvisorType).id,
            });
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
            this.$emit('createGeo');
            this.closePopup();
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    created() {
        this.doRequest();
    }

    @Watch('dateFrom')
    startDayHandler(dateFrom: Date) {
        if (this.dateTo && dateFrom > this.dateTo) {
            this.dateTo = dateFrom;
        }
        this.calendarPanelKey += 1;
    }

    @Watch('dateTo')
    endDayHandler(dateTo: Date) {
        if (this.dateFrom && dateTo < this.dateFrom) {
            this.dateFrom = dateTo;
        }
    }

    @Emit('close')
    closePopup() {}
}
</script>

<style lang="sass">
.b-geo-zone-reassignment-popup
    .b-transfer-list__column, .b-geo-zone-reassignment-popup__select, .b-geo-zone-reassignment-popup__date
        min-width: 290px
        max-width: 290px

    @include media('<=phone')
        .b-transfer-list__wrapper
            flex-direction: column

        .b-transfer-list__column
            width: 100%
            max-width: none

        .b-transfer-list__arrow-wrapper
            flex-direction: row-reverse

        .b-transfer-list__arrow
            margin: 0 25px

        .b-transfer-list__arrow:first-of-type
            svg
                transform: rotate(90deg)

        .b-transfer-list__arrow:last-of-type
            svg
                transform: rotate(90deg)

    &__inner
        display: block

    &__transfer-list
        margin: 10px 0 20px

    &__header
        display: flex
        justify-content: space-between
        align-items: center
        padding-top: 30px

        @include media('<=phone')
            flex-direction: column
            align-items: flex-start

            .b-geo-zone-reassignment-popup__select
                width: 100%
                margin-top: 10px
                max-width: none

    &__avatar-wrapper
        display: flex
        align-items: center
        padding-left: 10px

    &__avatar

        &__title
            font-size: 16px
            color: #213F6B
            padding-left: 11px
            font-weight: bold

    .fw-select-sort-simple .multiselect__tags
        width: 100%
        background-color: #fff !important
        border-radius: 10px !important
        border: 1px solid rgba(33, 63, 107, 0.1) !important

        input
            opacity: 0

        .multiselect__single
            padding-top: 0 !important

    &__time
        display: block

        &__wrapper
            display: flex
            padding-top: 10px

            @include media('<=phone')
                flex-direction: column
                align-items: flex-start

        &__inner
            flex: 1 0

            @include media('<=phone')
                width: 100%

                &:not(:last-of-type)
                    margin-bottom: 10px

                .mx-datepicker
                    width: 100%
                    max-width: none

        &__title
            font-weight: 500
            font-size: 16px

    &__date
        &--right
            display: flex
            justify-content: flex-end

        &.mx-datepicker--form
            background-color: #fff !important
            border: 1px solid rgba(33, 63, 107, 0.1) !important
</style>
