<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        :max-width='isDesktop() ? "420px" : "calc(100% - 20px)"'
        width='90%'
        popupClass='b-popup-style--no-shadow'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#fff'
        popupRadius='20px'>
        <template #body>
            <div class='h-flex h-flex-space-between h-flex-dir-column'>
                <FwImage
                    class='h-flex-center'
                    :src='requireImage("log-out.svg")'
                    :height='`101px`'>
                </FwImage>
                <div class='h-text-center h-mb-20'>
                    <div class='b-base-title h-mt-10'>
                        {{ $t('AUTH.LOG_OUT.TITLE') }}
                    </div>
                    <p v-if='userData.active' class='h-mt-10 h-mb-20 h-text-center'>
                        {{ $t('AUTH.LOG_OUT.ASIDE') }}
                    </p>
                    <p v-else class='h-mt-10 h-mb-20 h-text-center'>
                        {{ $t('AUTH.LOG_OUT.NOT.ACTIVE') }}
                        <span class='h-fw-500'>
                            {{ userData.email }}
                        </span>
                    </p>
                </div>
            </div>

            <div class='h-flex-center h-flex-dir-column'>
                <FwButton
                    class='h-mb-20 qa-do-logout-button'
                    borderRadiusType='medium-border'
                    @click='logOut'>
                    {{ $t('AUTH.LOG_OUT.YES') }}
                </FwButton>

                <div class='b-base-alert-link h-pointer'
                     @click='$router.go(-1)'>
                    {{ $t('AUTH.LOG_OUT.NO') }}
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { User } from '@/types/User';

const GlobalStore = namespace('GlobalStore');

@Component
export default class LogOutPage extends Vue {
    @GlobalStore.State('userData') userData!: User;
    logOut() {
        this.$store.commit('GlobalStore/mutationUnLoginUser');
        this.$router.push({ path: '/' });
        // @ts-ignore
        window.location.reload(true);
    }
}
</script>

<style lang='sass'>
    .push-btn
        margin: 0 10px
        width: 156px!important
        border-radius: 15px!important
</style>
