<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-add-or-edit-company'>
    <FwPopup
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='1000px'
        width='95%'
        :style='{height: `90vh`, position: `relative`, overflow: `auto`}'
        :allowBackgroundScrolling='false'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        :padding='isMobile() ? "30px 20px" : "40px 50px 25px"'
        @close='$emit(`closePopup`)'>
        <template #body>
            <AddOrEditCompanyForm
                class='h-mt-30'
                :isRequestSending='isRequestSending'
                :errorFromServer='errorFromServer'
                @onSubmit='sendDataToServer'
                @delete='showConfirm'>
            </AddOrEditCompanyForm>
        </template>
    </FwPopup>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`CONSULT.DELETE.COMPANY.TITLE`)'
        :subMessage='$t(`CONSULT.DELETE.COMPANY.SUBTITLE`)'
        :isRequestSending='isRequestSending'
        @deleteItem='deleteCompany'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import { Component } from 'vue-property-decorator';
import { AddOrEditCompanyForm } from '@/components/forms/add/AddOrEditCompanyForm';
import { LocationPopup } from '@/components/popups/LocationPopup';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { FinalePopup } from '@/components/popups/FinalePopup';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        AddOrEditCompanyForm,
        LocationPopup,
        DeletePopup,
        FinalePopup,
    },
})
export default class AddOrEditCompanyByForm extends Vue {
    @WorkerPageStore.Mutation('setCompanyData') setCompanyData!: Function;
    errorFromServer: string | null | TranslateResult = null;
    finalePopupShow: boolean = false;
    isRequestSending: boolean = false;
    confirmPopup: boolean = false;
    get companyData() {
        return this.$store.state.CalendarPageStore.company;
    }
    showConfirm() {
        this.confirmPopup = true;
    }
    async sendDataToServer(data: any) {
        this.isRequestSending = true;
        try {
            let company;
            if (this.$route.params.id) {
                company = await AppointmentWebApi.changeCompanyData(data, this.$route.params.id);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
            } else {
                company = await AppointmentWebApi.createCompany(data);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
            }
            this.isRequestSending = false;
            this.$store.commit('PLAN_COMPANY', company.data.company);
            if (this.$route.meta && this.$route.meta.isMainComponent) {
                this.$router.push({ path: `/consult/company/${company.data.company.id}` });
            } else {
                this.$emit(`closePopup`);
            }
            this.setCompanyData(company.data.company);
        } catch ({ response }) {
            this.errorFromServer = response.data.errors[0];
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
    async deleteCompany() {
        this.isRequestSending = true;
        try {
            await AppointmentWebApi.deleteCompany(this.$route.params.id);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.$router.push({ path: '/consult/choose/companies' });
            this.setCompanyData(null);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
    goToCompaniesPage() {
        this.$router.push({ path: '/consult/companies' });
    }
}
</script>

<style lang='sass'>
.b-add-company
    &__wrapper
        width: 1000px

        @include media('<=phone')
            width: 100%

.b-add-or-edit-company__line
    display: flex
    justify-content: space-between
    margin-bottom: 40px

    @include media('<=phone')
        flex-direction: column
        margin-bottom: 0

        .fw-form-input__wrapper
            margin-bottom: 10px
</style>
