import axios from 'axios';
import { updateUrlGetParams } from '@/helpers/url';
import { HubKpiResponseType, HubParamsType } from '@/types/Hub';
import WebApi from '../WebApi';

class HubApi extends WebApi {
    // async getHubKpi(branch_id: string): Promise<BaseBranchKpiResponseType> {
    //     const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/components/kpis`, { branch_id }));
    //     return data;
    // }

    async getHubKpiData(params: HubParamsType): Promise<HubKpiResponseType> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/kpis_hub_aggregators`, params));
        return data;
    }

    async getBranchKpiAppointmentAdvisorByCategory(
        category_id: string, branch_id: string, from: string, to: string
    ): Promise<{ [key: string]: number }> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/kpis_aggregators/appointment_advisors_by_category`, { category_id, branch_id, from, to }));
        return data.advisors_counts;
    }
}

const hubApi = new HubApi();

export {
    hubApi as HubApi,
};
