import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { AccountType } from '@/types/Account';

@Component
export default class OrganizationMixin extends Vue {
    @State(state => state.GlobalStore.account) account!: AccountType;

    get isOrganizationWithBranches(): boolean {
        return Boolean(this.account.organization_type === 'with_branches');
    }

    get isOrganizationWithHubs(): boolean {
        return Boolean(this.account.organization_type === 'with_hubs');
    }

    get isOrganizationWithAll(): boolean {
        return Boolean(this.account.organization_type === 'with_all');
    }

    get currentType() {
        return this.account.organization_type || this.user().account_kind;
    }
};
