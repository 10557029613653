export default {
    'LOGIN.HEADER': 'Welcome! Please log in to access to the platform',
    'LOGIN.TXT': 'Log in',
    'LOGIN.EMAIL.LABEL': 'Your email',
    'LOGIN.PASSWORD.LABEL': 'Your password',
    'LOGIN.PASSWORD.PLACEHOLDER': 'It\'ll stay between us',
    'LOGIN.FORGET.PASSWORD': 'Forget password?',
    'LOGIN.WITH.AZURE': 'Log in with Azure AD',
    'LOGIN.WITH.SSO': 'Connect with SSO',
    'LOGIN.WITH.MICROSOFT': 'Sign in with Microsoft',

    'LOGIN.COMPANY.LABEL': 'Your company account',
    'LOGIN.COMPANY.PLACEHOLDER': 'Name of your company account',
    'LOGIN.PLACEHOLDER.EMAIL': 'address@email.com',
    'AUTH.SIGN_UP.TITLE': 'Sign up and start',

    'AUTH.SIGN_UP.FORM.EMAIL.LABEL': 'Your email',
    'AUTH.SIGN_UP.FORM.EMAIL.PLACEHOLDER': 'address@email.com',
    'AUTH.SIGN_UP.FORM.EMAIL_CODE.LABEL': 'The code you received by mail',
    'AUTH.SIGN_UP.FORM.EMAIL_CODE.PLACEHOLDER': '5 numbers',
    'AUTH.SIGN_UP.FORM.BUTTON.NEXT': 'Next',
    'AUTH.LOGIN.TITLE': 'Log in',
    'AUTH.LOGIN.FORM.FORGET.PASSWORD': 'Forget password?',
    'AUTH.LOGIN.FORM.EMAIL.LABEL': 'Your email',
    'AUTH.LOGIN.FORM.EMAIL.PLACEHOLDER': 'address@email.com',
    'AUTH.LOGIN.FORM.PASSWORD.LABEL': 'Your password',
    'AUTH.LOGIN.FORM.PASSWORD.PLACEHOLDER': 'Type your password',
    'AUTH.LOGIN.FORM.BUTTON.NEXT': 'Log in',
    'AUTH.LOGIN.LINK_TEXT': 'I want to log in',
    'AUTH.CONFIRM_ACCOUNT.TITLE': 'Confirm my account',
    'AUTH.CONFIRM_ACCOUNT.LINK_TEXT': 'I want to confirm my account',
};
