import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
    namespaced: true,
})
class BreadCrumbsStore extends VuexModule {
    flowKey: string = 'base';
    flowName: string = '';
    localStorageKey: number = 1;
    flows: { [key: string]: Array<string> } = {
        'base': [
            'BREADCRUMBS.APPOINTMENTS.TYPES',
            'BREADCRUMBS.ORGANISATION',
            'BREADCRUMBS.ADVISORS',
            'BREADCRUMBS.RECAP',
        ],
        'partners': [
            'BREADCRUMBS.APPOINTMENTS.TYPES',
            'BREADCRUMBS.BUSINESS.PARTNER.TITLE',
            'BREADCRUMBS.TECH',
            'BREADCRUMBS.RECAP',
        ],
        'hubs': [
            'BREADCRUMBS.APPOINTMENTS.TYPES',
            'BREADCRUMBS.HUBS',
            'BREADCRUMBS.ADVISORS',
            'BREADCRUMBS.RECAP',
        ],
        'branches': [
            'BREADCRUMBS.APPOINTMENTS.TYPES',
            'BREADCRUMBS.BRANCHES',
            'BREADCRUMBS.ADVISORS',
            'BREADCRUMBS.RECAP',
        ],
        'hubs-and-branches': [
            'BREADCRUMBS.APPOINTMENTS.TYPES',
            'BREADCRUMBS.HUBS.BRANCHES',
            'BREADCRUMBS.ADVISORS',
            'BREADCRUMBS.RECAP',
        ],
        'appointment-matmut': [
            `BREADCRUMBS.CUSTOM.DETAILS`,
            'LABEL.APPOINTMENT.TYPE',
            'BREADCRUMBS.LOCATION',
            'BREADCRUMBS.ADDRESS',
            'BREADCRUMBS.RECAP',
        ],
    };

    @Mutation
    setFlowKey(key: string) {
        window.localStorage.setItem('SASS_FLOW_KEY', key);
        this.flowKey = key;
        this.localStorageKey += 1;
    }

    @Mutation
    setFlowName(key: string) {
        window.localStorage.setItem('SASS_FLOW_NAME', key);
        this.flowName = key;
    }

    @Mutation
    clearFlowKey() {
        window.localStorage.removeItem('SASS_FLOW_KEY');
        this.flowKey = 'base';
        this.localStorageKey += 1;
    }

    get currentFlow() {
        return this.flows[this.currentFlowKey];
    }

    get currentFlowKey(): string {
        if (this.localStorageKey && window.localStorage.getItem('SASS_FLOW_KEY')) {
            return window.localStorage.getItem('SASS_FLOW_KEY')!;
        }
        return this.flowKey;
    }
}

export default BreadCrumbsStore;
