<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-urls-popup'>
    <FwPopup
        class='b-geo-zone-reassignments-popup qa-advisor-geo-zones-popup'
        :class='{ "b-geo-zone-reassignments-popup--empty": !isLoading && advisorUrls.length }'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='1220px'
        :title='$tc("ADVISOR.SELFCARE.LINKS.PLURAL", 1)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        :padding='isMobile() ? "30px 20px" : "50px"'
        :disableClosingOnClickOutside='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @click.native.stop
        @close='$emit("close")'>
        <template #body>
            <div class='b-geo-zone-reassignments-popup__inner'>
                <div class='b-geo-zone-reassignments-popup__buttons h-mt-25'>
                    <div></div>
                    <FwButton
                        v-if='showSelfcareLinkAction'
                        borderRadiusType='small-border qa-advisor-geo-zone-add'
                        size='little'
                        @click.native='isSelfcareBookingPopupShow = true'>
                        {{ $t('BUTTON.ADD') }}
                    </FwButton>
                </div>

                <FwSpinLoader
                    v-if='isLoading'
                    :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                    class='h-flex-center loader'
                    :isActive='isLoading'
                    className='h-p-20'>
                </FwSpinLoader>
                <div class='b-geo-zone-reassignments-popup__table__wrapper'>
                    <FwHorizontalScroll
                        v-if='!isLoading && advisorUrls.length'
                        :isPageLoaded='true'
                        slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force h-mt-30'
                        iconClass='h-opacity-1'
                        newClass='fw-arrow-big-circle-fixed'
                        :arrowBgWidth='null'
                        colorIcon='#fff'
                        btnLocation='between'
                        hideBtn>
                        <template #content>
                            <CalendarTable
                                :titles='titles'
                                :data='advisorUrls'
                                :bigIndexes='[]'
                                class='b-calendar__table__wrapper'>
                                <div v-for='(item, i) in advisorUrls'
                                     :key='i'
                                     class='b-calendar__table'>
                                    <div class='b-calendar__table__outer'>
                                        <div v-for='(key, j) in mapping'
                                             :key='j'
                                             class='b-calendar__table__item'>
                                            <template v-if='item[key] && item[key].length'>
                                                <span class='h-overflow-text'>
                                                    {{ item[key] }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class='b-calendar__table__buttons'>
                                        <div class='b-calendar__table__item__delete h-ml-10 h-pointer h-width-60'
                                             @click='copyLink(item)'>
                                            <FwIcon
                                                class='h-flex-center h-pointer'
                                                icon='copy-document'
                                                size='18'
                                                color='rgba(33,63,107,.3)'>
                                            </FwIcon>
                                        </div>
                                        <div class='b-calendar__table__item__delete h-ml-10 h-pointer h-width-60'
                                             @click='showDeleteGeoZone(item)'>
                                            <FwIcon
                                                class='h-flex-center h-pointer'
                                                icon='bucket-edit'
                                                size='18'
                                                color='rgba(33,63,107,.3)'>
                                            </FwIcon>
                                        </div>
                                    </div>
                                </div>
                            </CalendarTable>
                        </template>
                    </FwHorizontalScroll>
                    <div v-else-if='!isLoading'
                         class='b-geo-zone-reassignments-popup__empty-text'>
                        {{ $t('ADVISOR.SELFCARE.NO_LINKS') }}
                    </div>
                    <FwPagination
                        v-if='totalPage > perPage'
                        :totalPage='totalPage'
                        :currentPage='currentPage'
                        :perPage='perPage'
                        :value='perPage'
                        @getData='getData'>
                    </FwPagination>
                </div>
            </div>
        </template>
    </FwPopup>
    <CalizySelfcateAddUrlPopup
        v-if='isSelfcareBookingPopupShow'
        @updateUrlsList='updateValue'
        @close='isSelfcareBookingPopupShow = false'>
    </CalizySelfcateAddUrlPopup>
    <DeletePopup
        v-if='isDeletePopupShow'
        :headerText='$t("ADVISOR.SELFCARE.DELETE.LINK.TITLE")'
        :subMessage='$t("ADVISOR.SELFCARE.DELETE.LINK.SUBTITLE")'
        :isRequestSending='isDeleteLoading'
        @deleteItem='deleteUrl'
        @close='isDeletePopupShow = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import {
    AppointmentAdvisorUrlsBuilderType,
    AppointmentCategoryType,
} from '@/types/Appointment';
import { BaseMetaType } from '@/types/Response';

import { CalendarTable } from '@/components/common/CalendarTable';
import { CalizySelfcateAddUrlPopup } from '@/components/popups/CalizySelfcateAddUrlPopup';
import { DeletePopup } from '@/components/popups/DeletePopup';

@Component({
    components: {
        CalendarTable,
        CalizySelfcateAddUrlPopup,
        DeletePopup,
    },
})
export default class CalizySelfcareLinksPopup extends Vue {
    @Prop({ type: Number, required: true }) readonly updateListKey!: number;

    advisorUrls: Array<AppointmentAdvisorUrlsBuilderType> = [];
    urlItem: AppointmentAdvisorUrlsBuilderType | null = null;
    appointmentCategories: Array<AppointmentCategoryType> = [];
    urlBuilderData: null = null;

    isAddingLoading: boolean = false;
    isDeleteLoading: boolean = false;
    isDeletePopupShow: boolean = false;
    isSelfcareBookingPopupShow: boolean = false;
    isLoading: boolean = false;
    currentPage: number = 1;
    totalPage: number = 0;
    perPage: number = 5;
    meta: BaseMetaType | null = null;

    get titles(): Array<string> {
        return [
            'LABEL.CATEGORY',
            'LABEL.TYPE',
            'LABEL.METHOD',
            'LABEL.LINK',
        ];
    }

    get mapping(): Array<string> {
        return [
            `appointment_category_kind`,
            `appointment_category_title`,
            `location_name`,
            `tiny_url`,
        ];
    }

    get showSelfcareLinkAction(): boolean {
        return this.currentUserRole && this.currentUserRole.advisor_rights && this.currentUserRole.advisor_rights.update;
    }

    getData(currentPage: number, perPage: number = this.perPage): void {
        this.currentPage = currentPage;
        this.perPage = perPage;
        this.getAdvisorsUrls();
    }

    updateMeta(meta: BaseMetaType): void {
        this.meta = meta;
        this.totalPage = meta.total_count;
        this.currentPage = meta.current_page;
    }

    showDeleteGeoZone(item: AppointmentAdvisorUrlsBuilderType): void {
        this.isDeletePopupShow = true;
        this.urlItem = item;
    }

    async copyLink(item: AppointmentAdvisorUrlsBuilderType) {
        await navigator.clipboard.writeText(item.tiny_url);
        this.sentNotif(`SELFCARE.YOUR.BOOKING.LINK.COPY.TO.CLIPBOARD`, true);
    }

    async getAdvisorsUrls(): Promise<void> {
        this.isLoading = true;
        try {
            const data = await AppointmentWebApi.getAdvisorUrlsBuilder({
                advisor_id: this.$router.currentRoute.params.id,
                current_page: this.currentPage,
                per_page: this.perPage,
            });

            this.advisorUrls = data.advisor_urls;
            this.updateMeta(data.meta);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoading = false;
        }
    }

    async deleteUrl(): Promise<void> {
        this.isDeleteLoading = true;
        try {
            if (this.urlItem) {
                await AppointmentWebApi.deleteAdvisorUrlBuilder(this.urlItem.id);
                this.currentPage = this.advisorUrls.length === 1 && this.currentPage !== 1 ? this.currentPage - 1 : this.currentPage;
            }
            this.getAdvisorsUrls();
            this.urlItem = null;
            this.isDeletePopupShow = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isDeleteLoading = false;
        }
    }

    @Watch('updateListKey')
    updateListKeyHandler() {
        this.getAdvisorsUrls();
    }

    updateValue() {
        this.getAdvisorsUrls();
    }

    created() {
        this.getAdvisorsUrls();
    }
}
</script>

<style lang="sass">
.b-urls-popup
    .b-calendar__table__header__item, .b-calendar__table__item
        flex: 2 0 220px
        max-width: 220px
    .b-calendar__table__header__item:last-child, .b-calendar__table__item:last-child
        white-space: nowrap
        flex: 2 0 255px
        max-width: 255px
        span
            overflow: hidden
            text-overflow: ellipsis
    .b-calendar__table__buttons:before
        content: none
.b-geo-zone-reassignments-popup
    width: 1020px
    justify-content: flex-start !important
    min-height: 720px

    .b-calendar__table__inner
        max-width: 100%

    &--empty
        min-height: none

    &__inner
        display: block

    &__buttons
        display: flex
        justify-content: space-between

        .fw-button
            height: 50px !important

    .fw-arrow-big-circle-fixed.fw-arrow-big-circle-fixed--right
        display: none !important

    .fw-pagination
        background: transparent !important
        bottom: 46px

    &__empty-text
        font-size: 16px
        text-align: center
        font-weight: 500
        margin-top: 20px

    &__delete-all
        font-size: 14px
        font-weight: 500
        color: $cancel-red
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        position: absolute
        bottom: 0
        height: 60px
        width: calc(100% - 100px)

        &__inner
            display: flex
            align-items: center
            cursor: pointer
            transition: opacity 0.2s

            &:hover
                opacity: 0.8

    .fw-input::placeholder
        width: 100%

    &__tabs
        display: flex
        font-size: 14px
        color: #213F6B
        margin-left: 40px
        align-items: center
        justify-content: center
        width: 100%
        margin: 10px 0 20px

        &__item
            position: relative
            font-weight: 500
            opacity: 0.5

            &.active
                opacity: 1
                &:after
                    width: 80%
                    position: absolute
                    left: 0
                    content: ''
                    right: 0
                    bottom: -7px
                    border-top: 2px solid #213F6B
                    height: 0
                    margin: auto

            &:not(.active)
                cursor: pointer

            &:not(:last-of-type)
                margin-right: 60px

    .multiselect__placeholder
        color: #909FB5 !important
</style>
