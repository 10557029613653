<template>
<div class='h-flex'>
    <SettingsNav
        :title='$t("SETTINGS.MENU.HEADER")'
        :routes='settingsChildrenRoutes'>
    </SettingsNav>
    <div class='b-settings-router-view-wrapper'>
        <router-view></router-view>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { SettingsNav } from '@/components/common/SettingsNav';
import getSettingsChildrenRoutes from '@/routes/settings/children-routes';
import { RouteType } from '@/types/router';
import AccountMixin from '@/mixins/account';
import PermissionsMixin from '@/mixins/permissions';

@Component({
    components: {
        SettingsNav,
    },
})
export default class SettingsWrapper extends Mixins(AccountMixin, PermissionsMixin) {
    get isUrlBuilderAvailable() {
        if (!this.accountData) {
            return false;
        }

        return (
            this.accountData &&
            this.accountData.account.geo_scope === 'national' &&
            this.accountData.account.url_builder_support
        );
    }

    get settingsChildrenRoutes(): Array<RouteType> {
        return getSettingsChildrenRoutes(this.isMatmutAccount, this.isUrlBuilderAvailable);
    }
}
</script>

<style lang='sass'>
.b-settings-router-view-wrapper
    padding: 40px 95px 80px
    width: 100%
    max-height: calc(100vh - 90px)
    overflow: auto

.b-settings__title
    font-size: 25px
    font-weight: bold
    display: flex
    justify-content: space-between
    width: 100%
    align-items: center
</style>
