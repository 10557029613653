import { User, UserManagerProfileRole } from '@/types/User';
import { RouteMetaType, RouteType } from '@/types/router';
import { RequiredRightType, RoleFullPayloadType, RoleRightType } from '@/types/Roles';

const POSITION_NAME = 'position_name';
const ROLE_NAME = 'role_name';
const ROLE_DESCRIPTION = 'role_description';
const USER_EMAIL = 'email';
const USER_PHONE = 'phone';
const USER_NAME = 'first_name';
const LAST_NAME = 'last_name';
const CODE_SITE = 'code_site';
const OLD_PASSWORD_NAME = 'old_password';
const USER_PASSWORD = 'user_password';
const CONFIRM_PASSWORD = 'user_confirm_password';
const NAME = 'name';
const USER_ROLE = 'role';
const USER_ROLE_ID = 'role_id';
const CONTACT_EMAIL = 'contact_email';
const CONTACT_PHONE = 'contact_phone';
const LOGO = 'logo';
const ADDRESS = 'address';

const getUserRole = (user: User): RoleFullPayloadType | null => {
    if (user.manager_profile) {
        return user.manager_profile.role;
    }
    return user.technician_profile ? user.technician_profile.role || null : null;
};

const hasPermissionsToVisit = (userRole: RoleFullPayloadType, meta?: RouteMetaType) => {
    if (!meta || !meta.rightBlock) {
        return true;
    }
    // @ts-ignore-next-line
    const roleBlock: RoleRightType = userRole && userRole[meta.rightBlock];
    if (!roleBlock) {
        return false;
    }
    if (meta.rights) {
        return meta.rights.some((right: RequiredRightType) => right in roleBlock && roleBlock[right]);
    }
    // @ts-ignore-next-line
    return roleBlock[meta.right];
};

export {
    POSITION_NAME,
    ROLE_NAME,
    ROLE_DESCRIPTION,
    USER_EMAIL,
    USER_PHONE,
    USER_NAME,
    LAST_NAME,
    CODE_SITE,
    OLD_PASSWORD_NAME,
    USER_PASSWORD,
    CONFIRM_PASSWORD,
    NAME,
    USER_ROLE,
    USER_ROLE_ID,
    CONTACT_EMAIL,
    CONTACT_PHONE,
    LOGO,
    ADDRESS,
    getUserRole,
    hasPermissionsToVisit,
};
