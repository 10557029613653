<template>
<div class='b-cancel-reason h-flex h-flex-center h-flex-dir-column h-mt-20'>
    <FwFormInput
        labelType='label-medium'
        :title='$t(`CANCEL.ORIGIN.LABEL`)'>
        <div style='background: #fff; width: 400px; border-radius: 10px;' class='h-p-0'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='valuePerson'
                    class='qa-consult-recap__appointment-cancel-origin'
                    :propsPlaceholder='$t(`CANCEL.PERSON.PLACEHOLDER`)'
                    type='white'
                    label='title'
                    :options='optionsReasonOrigin'
                    @input='choosedValue'>
                    <template v-slot:option='{ props }'>
                        <slot :props='props'
                              name='option'>
                            <div>
                                {{ props.option.title }}
                            </div>
                        </slot>
                    </template>
                </FwSelect>
            </div>
        </div>
    </FwFormInput>
    <FwFormInput
        v-if='valuePerson'
        class='h-mt-20'
        labelType='label-medium'
        :title='$t(`CANCEL.REASON.LABEL`)'>
        <div style='background: #fff; width: 400px; border-radius: 10px;' class='h-p-0'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='valueReason'
                    class='qa-consult-recap__appointment-cancel-reason'
                    :propsPlaceholder='$t(`CANCEL.REASON.PLACEHOLDER`)'
                    type='white'
                    label='title'
                    :options='optionsResult'>
                    <template v-slot:option='{ props }'>
                        <slot :props='props'
                              name='option'>
                            <div>
                                {{ props.option.title }}
                            </div>
                        </slot>
                    </template>
                </FwSelect>
            </div>
        </div>
    </FwFormInput>
    <div v-if='valuePerson && valueReason'
         class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-30 qa-consult-recap__appointment-cancel-submit'
            borderRadiusType='tiny-border'
            :disabled='isRequestSending'
            @click='onSubmit'>
            {{ $t('CANCEL.BUTTON.APPOINTMENT') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import i18n from '@/locale';
import AccountMixin from '@/mixins/account';
import DynamicFormMixin from '@/mixins/dynamicFormMixin';

interface ReasonValueType {
    title: string,
    value: string,
}

interface ReasonCompanyKindType {
    internalData: Array<ReasonValueType>,
    externalData: Array<ReasonValueType>,
}

interface ReasonPartnerKindType {
    internalData: Array<ReasonValueType>,
    clientData: Array<ReasonValueType>,
    partnerData: Array<ReasonValueType>,
}

@Component
export default class CancelReasonSelect extends Mixins(AccountMixin, DynamicFormMixin) {
    @Prop({ type: Array, default: null }) readonly optionsReasonOrigin!: null | Array<string>;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    valuePerson: ReasonValueType | null = null;
    valueReason: ReasonValueType | null = null;
    optionsResult: Array<ReasonValueType> | null = null;
    companyReasonValues: ReasonCompanyKindType = {
        internalData: [
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.NOT.AVAILABLE'),
                value: 'not_available',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.OTHER'),
                value: 'other',
            },
        ],
        externalData: [
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.NOT.AVAILABLE'),
                value: 'not_available',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.NO.ANYMORE'),
                value: 'no_need',
            },
        ],
    };

    partnersReasonValues: ReasonPartnerKindType = {
        internalData: [
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.BOOK.MISTAKE'),
                value: 'booking_mistake',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.NECESSARY.ANYMORE'),
                value: 'no_need',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.STOCK'),
                value: 'stock_issue',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.TECH.ISSUE'),
                value: 'technical_issue',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.OTHER'),
                value: 'other',
            },
        ],
        clientData: [
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.NOT.AVAILABLE'),
                value: 'not_available',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.NO.ANYMORE'),
                value: 'no_need',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.OTHER'),
                value: 'other',
            },
        ],
        partnerData: [
            {
                title: i18n.tc('CANCEL.REASON.EXTERNAL.COMPANY.EMERGENCY'),
                value: 'company_emergency',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.NOT.AVAILABLE'),
                value: 'not_available',
            },
            {
                title: i18n.tc('CANCEL.REASON.EXTERNAL.DELAY'),
                value: 'delay',
            },
            {
                title: i18n.tc('CANCEL.REASON.EXTERNAL.MATERIAL.ISSUE'),
                value: 'material_issue',
            },
            {
                title: i18n.tc('CANCEL.REASON.INTERNAL.OTHER'),
                value: 'other',
            },
        ],
    };

    get userAccountKindPartner() {
        return this.user().account_kind === 'partner';
    }
    /* eslint-disable */
    choosedValue() {
        this.valueReason = null;
        const reasons = this.accountData.cancellation_reasons;
        const origins = this.accountData.cancellation_origins;
        if (this.valuePerson && reasons && reasons.length && origins && origins.length) {
            const filteredArray = reasons.filter(obj => obj.cancellation_origin_keys.includes(this.valuePerson!.value));
            this.optionsResult = filteredArray.map(item => {
                return {
                    title: item[this.currentLangKey],
                    value: item.searchable_name,
                };
            });
            return;
        }
        if (this.userAccountKindPartner) {
            switch (this.valuePerson!.value) {
                case `internal`:
                    this.optionsResult = this.partnersReasonValues.internalData;
                    break;
                case `client`:
                    this.optionsResult = this.partnersReasonValues.clientData;
                    break;
                case `partner_company`:
                    this.optionsResult = this.partnersReasonValues.partnerData;
                    break;
                default:
                    this.optionsResult = null;
            }
        } else {
            switch (this.valuePerson && this.valuePerson!.value) {
                case `internal`:
                    this.optionsResult = this.companyReasonValues.internalData;
                    break;
                case `client_or_prospect`:
                    this.optionsResult = this.companyReasonValues.externalData;
                    break;
                default:
                    this.optionsResult = null;
            }
        }
    }
    /* eslint-enable */

    @Emit('onSubmit')
    onSubmit() {
        return {
            origin: this.valuePerson!.value,
            reason: this.valueReason!.value,
        };
    }
}
</script>

<style lang="sass">
.b-cancel-reason
    .multiselect__input::placeholder
        color: transparent
</style>
