<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        class='b-choose-book-type-popup'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='1200px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mb-20'>
                    {{ dayText }}
                </h2>
                <RouterLink
                    class='h-flex b-choose-book-type-popup__advisor'
                    :to='{ name: "ConsultAdvisorPage", params: { id: bookData.user_id }}'
                    target='_blank'>
                    <WorkerAvatar
                        v-if='bookData'
                        :worker='bookData'
                        class='h-mr-10'
                        size='small'
                        align='right'>
                    </WorkerAvatar>
                    <span
                        class='b-calendar-body__name'>
                        {{ bookData.name | capitalizeAll }}
                    </span>
                </RouterLink>
                <div class='b-choose-book-type-popup__items'>
                    <template v-if='details && details.length'>
                        <div v-for='item in details'
                             :key='item.id'
                             :class='getClass(item)'
                             class='b-choose-book-type-popup__item'>
                            <div class='b-choose-book-type-popup__item__time'>
                                {{ getSlot(item) }}
                            </div>
                            <div class='b-choose-book-type-popup__item__title'>
                                {{ getTitle(item) }}
                            </div>
                        </div>
                    </template>
                    <div v-else
                         class='h-text-center'>
                        {{ $t('POPUPS.HOW_TO_USE.NO_DATA_FOR_THIS_DAY') }}
                    </div>
                </div>
                <div class='b-choose-book-type-popup__labels'>
                    <div v-for='(label, index) in labels'
                         :key='index'
                         :style='{ backgroundColor: label.bgColor, color: label.color }'
                         class='b-choose-book-type-popup__label'>
                        {{ label.value }} {{ label.text }}
                    </div>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkerAvatar } from '@/components/simple/WorkerAvatar';
import { CalendarCell } from '@/components/calendar/CalendarCell';
import { CalendarMonthDayDetailsType, CellDataClickType } from '@/types/Calendar';
import { hexToRgbA } from '@/helpers/colors';
import {
    APPOINTMENT_TYPE_SC,
    APPOINTMENT_TYPE_SASS,
    RESERVED_SLOT_TYPE,
    UNAVAILABILITY_TYPE,
    getColorType,
} from '@/components/calendar/helpers';

const BG_OPACITY = 0.2;

@Component({
    components: {
        CalendarCell,
        WorkerAvatar,
    },
})
export default class ChooseBookType extends Vue {
    @Prop({ type: Object, required: true }) readonly bookData!: CellDataClickType;
    @Prop({ type: Object, required: true }) readonly details!: Array<CalendarMonthDayDetailsType>;
    @Prop({ type: Boolean, required: true }) readonly isColorblind!: boolean;

    get labels(): Array<any> {
        const APPOINTMENT_TYPE_SASS_COUNT = this.details.filter(item => item.origin === `appointment_saas`).length;
        const APPOINTMENT_TYPE_SC_COUNT = this.details.filter(item => item.origin === `appointment_sc`).length;
        const RESERVED_SLOT_TYPE_SASS_COUNT = this.details.filter(item => item.origin === `reserved_slot`).length;
        const UNAVAILABILITY_TYPE_SASS_COUNT = this.details.filter(item => item.origin === `unavailability`).length;
        return [
            {
                value: APPOINTMENT_TYPE_SASS_COUNT,
                type: APPOINTMENT_TYPE_SASS,
                bgColor: hexToRgbA(getColorType(this.isColorblind)[APPOINTMENT_TYPE_SASS], BG_OPACITY),
                color: getColorType(this.isColorblind)[APPOINTMENT_TYPE_SASS],
                text: this.$tc(`LABEL.CALIZY_SAAS_APPT`, APPOINTMENT_TYPE_SASS_COUNT),
            },
            {
                value: APPOINTMENT_TYPE_SC_COUNT,
                type: APPOINTMENT_TYPE_SC,
                bgColor: hexToRgbA(getColorType(this.isColorblind)[APPOINTMENT_TYPE_SC], BG_OPACITY),
                color: getColorType(this.isColorblind)[APPOINTMENT_TYPE_SC],
                text: this.$tc(`LABEL.CALIZY_SELFCARE_APPT`, APPOINTMENT_TYPE_SC_COUNT),
            },
            {
                value: RESERVED_SLOT_TYPE_SASS_COUNT,
                type: RESERVED_SLOT_TYPE,
                bgColor: hexToRgbA(getColorType(this.isColorblind)[RESERVED_SLOT_TYPE], BG_OPACITY),
                color: getColorType(this.isColorblind)[RESERVED_SLOT_TYPE],
                text: this.$tc(`LABEL.RESERVED_SLOT`, RESERVED_SLOT_TYPE_SASS_COUNT),
            },
            {
                value: UNAVAILABILITY_TYPE_SASS_COUNT,
                type: UNAVAILABILITY_TYPE,
                bgColor: hexToRgbA(getColorType(this.isColorblind)[UNAVAILABILITY_TYPE], BG_OPACITY),
                color: getColorType(this.isColorblind)[UNAVAILABILITY_TYPE],
                text: this.$tc(`LABEL.UNAVAILABILITY`, UNAVAILABILITY_TYPE_SASS_COUNT),
            },
        ];
    }

    get dayText(): string {
        return dayjs(this.bookData.currentDate).format('DD/MM/YYYY');
    }

    getSlot(bookData: CalendarMonthDayDetailsType): str {
        return this.isUnavailabilitySlot(bookData) && bookData.full_day ? this.$t('CALENDAR.FULL.DAY') : bookData.slot;
    }

    getTitle(bookData: CalendarMonthDayDetailsType): str {
        return this.isReservedSlot(bookData) ? this.$tc(`LABEL.RESERVED_SLOT`) : bookData.title;
    }

    isUnavailabilitySlot(bookData: CalendarMonthDayDetailsType): boolean {
        return bookData.origin === `unavailability`;
    }

    isReservedSlot(bookData: CalendarMonthDayDetailsType): boolean {
        return bookData.origin === `reserved_slot`;
    }

    getClass(item: CalendarMonthDayDetailsType): string | null {
        const classMapping = {
            appointment_saas: `b-choose-book-type-popup__item--saas`,
            appointment_sc: `b-choose-book-type-popup__item--selfcare`,
            unavailability: `b-choose-book-type-popup__item--unavailability`,
            reserved_slot: `b-choose-book-type-popup__item--reserved-slot`,
        };
        // @ts-ignore
        return classMapping[item.origin] || null;
    }
}
</script>

<style lang='sass'>
.b-choose-book-type-popup
    width: 600px !important
    color: #213F6B

    &__items
        border-bottom: 1px solid #B7C0CE
        width: 100%
        padding-bottom: 25px
        padding-top: 25px

    &__item
        height: 50px
        width: 100%
        border-radius: 5px
        overflow: hidden
        position: relative
        padding: 0 15px
        border-left: 5px solid transparent
        color: #213F6B
        font-size: 12px
        display: flex
        flex-direction: column
        justify-content: center
        margin-bottom: 10px
        border-top: 1px solid transparent
        border-bottom: 1px solid transparent
        border-right: 1px solid transparent

        &__title
            font-weight: bold

        &--saas
            background-color: #FFF2E7
            border-color: #FF8112

        &--selfcare
            background-color: #E9F7FE
            border-color: #37B4FB

        &--unavailability
            background-color: #E8E9E9
            border-color: #7D7D7D

        &--reserved-slot
            background-color: #f2fdf2
            border-color: #3c9823

    &__advisor
        &:hover
            text-decoration: underline

    &__labels
        margin: 15px -10px
        text-align: center
        display: flex

    &__label
        width: 118px
        height: 60px
        display: inline-flex
        align-items: center
        justify-content: center
        font-weight: 500
        border-radius: 5px
        margin: 7px
        font-size: 12px
</style>
