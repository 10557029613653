import {
    WorkerTimeType,
} from '@/types/Workers';

const workingHoursData: WorkerTimeType = {
    monday: {
        day: 'CALENDAR.WEEK.MONDAY',
        monday: true,
        state: true,
        dayPart: {
            day: {
                timeTo: '18:00',
                timeFrom: '08:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00',
                timeFrom: '12:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    tuesday: {
        day: 'CALENDAR.WEEK.TUESDAY',
        tuesday: true,
        state: true,
        dayPart: {
            day: {
                timeTo: '18:00',
                timeFrom: '08:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00',
                timeFrom: '12:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    wednesday: {
        day: 'CALENDAR.WEEK.WEDNESDAY',
        wednesday: true,
        state: true,
        dayPart: {
            day: {
                timeTo: '18:00',
                timeFrom: '08:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00',
                timeFrom: '12:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    thursday: {
        day: 'CALENDAR.WEEK.THURSDAY',
        thursday: true,
        state: true,
        dayPart: {
            day: {
                timeTo: '23:30',
                timeFrom: '08:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: false,
            },
        },
    },
    friday: {
        day: 'CALENDAR.WEEK.FRIDAY',
        friday: true,
        state: true,
        dayPart: {
            day: {
                timeTo: '23:30',
                timeFrom: '08:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00',
                timeFrom: '12:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    saturday: {
        day: 'CALENDAR.WEEK.SATURDAY',
        saturday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.DAY',
                state: false,
            },
            lunch: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: false,
            },
        },
    },
    sunday: {
        day: 'CALENDAR.WEEK.SUNDAY',
        sunday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.DAY',
                state: false,
            },
            lunch: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: false,
            },
        },
    },
};
export {
    workingHoursData,
};
