import { UserListType } from '@/types/User';

// it is user data for user "All"
const userAll: UserListType = {
    id: null,
    name: 'LABEL.ALL',
    last_name: 'LABEL.ALL.ADVISORS',
    color: '#27DBBD - #DF2E17', // "#27DBBD - #DF2E17"
};

const userAllDisabled: UserListType = {
    id: null,
    disabled: true,
    name: 'LABEL.ALL',
    last_name: 'LABEL.ALL.ADVISORS',
    color: '#828282 - #8С8С8С',
};

export {
    userAll,
    userAllDisabled,
};
