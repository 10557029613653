const BOOKING_FIRST_NAME = 'first_name';
const BOOKING_LAST_NAME = 'last_name';
const BOOKING_COMPANY = 'company';
const BOOKING_PHONE = 'phone_number';
const BOOKING_EMAIL = 'email';
const BOOKING_ADDRESS = 'address';
const BOOKING_POST_CODE = 'local_post_code';
const BOOKING_NOTES = 'notes';

const BOOKING_PROSPECT_CUSTOMER_ID = 'customer_id';
const BOOKING_CLIENT_EMAIL = 'client_email';
const BOOKING_CLIENT_PHONE = 'client_phone';

export {
    BOOKING_FIRST_NAME,
    BOOKING_LAST_NAME,
    BOOKING_COMPANY,
    BOOKING_PHONE,
    BOOKING_EMAIL,
    BOOKING_ADDRESS,
    BOOKING_POST_CODE,
    BOOKING_NOTES,

    BOOKING_PROSPECT_CUSTOMER_ID,
    BOOKING_CLIENT_EMAIL,
    BOOKING_CLIENT_PHONE,
};
