<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-add-edit-worker-popup'>
    <FwPopup
        v-show='!confirmPopup'
        class='h-pos-rel'
        style='height: 90%;overflow-y: auto; justify-content: flex-start'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='1200px'
        width='auto'
        :padding='isMobile() ? "30px 20px" : "35px"'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        disableClosingOnClickOutside
        @close='closeWorkerPopup'>
        <template #body>
            <ConsultPartnerAddTechnician
                v-show='!isGeograficPopup'
                :type='type'
                :parent_id='parent_id'
                :isWorkerAdd='isWorkerAdd'
                @showDeletePopup='showDeletePopup'
                @closeWorkerPopup='closeWorkerPopup'
                @openGeograficPopup='openGeograficPopup'>
            </ConsultPartnerAddTechnician>
            <AddEditGeograficPopup
                v-if='isGeograficPopup'
                @closePopup='closePopup'
                @close='isGeograficPopup = false'>
            </AddEditGeograficPopup>
        </template>
    </FwPopup>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.WORKER.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.WORKER.TEXT`)'
        :isRequestSending='isRequestSending'
        @deleteItem='deleteTechnician'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ConsultPartnerAddTechnician } from '@/components/forms/add/ConsultPartnerAddTechnician';
import { AddEditGeograficPopup } from '@/components/popups/AddEditGeograficPopup';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { FinalePopup } from '@/components/popups/FinalePopup';

@Component({
    components: {
        ConsultPartnerAddTechnician,
        AddEditGeograficPopup,
        DeletePopup,
        FinalePopup,
    },
})
export default class AddEditWorkerPopup extends Vue {
    @Prop({ type: Boolean, default: false }) readonly isWorkerAdd!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAddEditConsultAdvisors!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isBranchHubHide!: boolean;
    @Prop({ type: String, default: null }) readonly type!: string;
    @Prop({ type: String, default: null }) readonly parent_id!: string;

    isGeograficPopup: boolean = false;
    isRequestSending: boolean = false;
    confirmPopup: boolean = false;
    finalePopupShow: boolean = false;
    openGeograficPopup() {
        this.isGeograficPopup = true;
    }
    closePopup() {
        this.isGeograficPopup = false;
    }
    closeWorkerPopup() {
        // @ts-ignore-next-line
        return this.$emit(`closePopup`);
    }
    showDeletePopup() {
        this.confirmPopup = true;
    }
    async deleteTechnician() {
        this.isRequestSending = true;
        try {
            await WorkersWebApi.deleteWorker({
                id: this.$route.params.id,
            });
            this.confirmPopup = false;
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.$router.push({ path: '/consult/choose/technicians' });
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
}
</script>
<style lang='sass'>
@include media('<=desktop')
    .fw-popup
        width: 100%!important

.b-add-edit-worker-popup
    @include media('<=phone')
        max-width: 100vw
</style>
