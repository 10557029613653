<template>
<div class='b-add-branch__wrapper container'>
    <div class='b-base-title__wrapper'>
        <h2 class='b-appointment-left-bar__title'>
            {{ currentRoute ? $t('PLAN.BRANCH.TITLE.EDIT') : $t('ONBOARDING.CHOOSE.BRANCH.TITLE') }}
        </h2>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>

    <form v-else
          class='h-pb-50'
          @keydown.stop.prevent.enter='onSubmit'>
        <div class='h-flex h-flex-space-between h-mb-30'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("name")'
                labelType='label-medium'
                :title='`${$t(`LABEL.NAME`)}*`'>
                <FwInput
                    v-focus='true'
                    :value='name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.NAME`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("name")'
                    @update:value='value => name = value'
                    @input='clearServerErrorsBase("name")'
                    @blur='addCurrentInputToValidateArray("name")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError(BRANCH_ID)'
                labelType='label-medium'
                :title='`${$t(`LABEL.BRANCH.ID`)}`'>
                <FwInput
                    :value='external_id'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.ID`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText(BRANCH_ID)'
                    @update:value='value => external_id = value'
                    @input='clearServerErrorsBase(BRANCH_ID)'
                    @blur='addCurrentInputToValidateArray(BRANCH_ID)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-flex-space-between h-mb-30'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("address")'
                :title='`${$t(`LABEL.ADDRESS`)}*`'
                labelType='label-medium'>
                <SelectAddress
                    :startAddress='address'
                    :isDisabled='currentRoute'
                    @changePostCode='value => post_code = value'
                    @input='input'>
                </SelectAddress>
            </FwFormInput>
        </div>

        <div class='h-flex h-flex-space-between h-mb-30'>
            <FwFormInput
                class='h-flex-1-0 h-ph-10'
                :fieldHasError='fieldHasError("phone")'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE.NUMBER`)}`'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    :placeholder='$t(`LABEL.PHONE.NUMBER`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText("phone")'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => phone = value'
                    @input='clearServerErrorsBase("phone")'
                    @blur='addCurrentInputToValidateArray("phone")'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-0-0-50p h-ph-10'
                :fieldHasError='fieldHasError("email")'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EMAIL`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText("email")'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase("email")'
                    @blur='addCurrentInputToValidateArray("email")'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-flex-center h-flex-space-between'>
            <h2>
                {{ $t('ADD.ROOM.QUESTION') }}
            </h2>
            <FwLabelCard
                :disabled='rooms.length'
                class='b-add-edit__form--label-card'>
                <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                    <div class='h-flex h-flex-center'>
                        <span class='b-add-worker__is_admin_active'>
                            {{ isActive ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                        </span>
                    </div>
                    <FwSwitcher
                        class='h-mh-10'
                        :isActive='isActive'
                        :disabled='rooms.length'
                        @toggleSwitch='isActive = !isActive'>
                    </FwSwitcher>
                </div>
            </FwLabelCard>
        </div>
        <div v-if='rooms.length'
             class='h-mt-20 h-pb-20 h-flex h-align-items-center h-flex-space-between'>
            <div>
                This branch have {{ rooms.length }} rooms. You can mange it:
            </div>

            <FwButton
                size='large-xl'
                color='choose'
                borderRadiusType='tiny-border'
                style='width: 220px'
                class='h-ml-10'
                :fontSize='`16px`'
                @click.native='goToManageRooms'>
                <span style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>
                    {{ $t('MANAGE.ROOMS.TITLE') }}
                </span>
                <FwIcon
                    icon='arrow-right'
                    size='12'
                    color='#37e6c9'>
                </FwIcon>
            </FwButton>
        </div>
        <div class='h-mb-50 h-mt-20 b-required-field'>
            * {{ $t(`LABEL.REQUIRED.FIELD`) }}
        </div>
    </form>
    <portal to='appointment-bar-buttons'>
        <FwButton
            size='little'
            :disabled='isDisabled'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ currentRoute ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
        </FwButton>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { CreateBranchPayloadType } from '@/types/Appointment';
import { BranchSitesDataType, SitesBranchDataType } from '@/types/Site';
import { SelectAddress } from '@/components/common/SelectAddress';
import { NAME, ADDRESS, EMAIL, BRANCH_ID } from '@/helpers/branch';
import { ParsedAddressData } from '@/types/GoogleMap';
import { CalendarTable } from '@/components/common/CalendarTable';
import { addPostCodeToAddress } from '@/helpers/address';

const OnboardingStore = namespace('OnboardingStore');
const BreadCrumbsStore = namespace('BreadCrumbsStore');

@Component({
    validators: {
        [NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [ADDRESS](value: string) {
            return this.requiredValidator({ value });
        },
        [EMAIL](value: string) {
            return this.emailValidator({ value, notRequired: true });
        },
    },
    components: {
        SelectAddress,
        CalendarTable,
    },
})
export default class AppointmentBranchAdd extends Mixins(ValidationMixin) {
    @OnboardingStore.Mutation('mutationBranchData') mutationBranchData!: Function;
    @BreadCrumbsStore.Getter('currentFlowKey') currentFlowKey!: string;

    [BRANCH_ID]: string = '';
    BRANCH_ID: string = BRANCH_ID;
    name: string = '';
    address: string = '';
    phone: string = '';
    email: string = '';
    post_code: string = '';
    isActive: boolean = false;
    sendRequest: boolean = false;
    loading: boolean = false;
    parsedAddressData: ParsedAddressData | null = null;
    serverErrors: serverErrors = {
        name: null,
        address: null,
        phone_number: null,
        email: null,
        [BRANCH_ID]: null,
    };
    branch: null | BranchSitesDataType = null;
    rooms: Array<SitesBranchDataType> = [];
    mapping: Array<string> = [
        `name`,
        `email`,
        `facility`,
    ];
    bigIndexes: Array<number> = [1];

    get isDisabled(): boolean {
        return this.disabledFormButton || this.sendRequest;
    }

    get currentRoute() {
        return this.$route.params.id;
    }

    get disabledFormButton() {
        return !(this.name && this.address);
    }
    get userKindBoth() {
        return this.user().account_organization_type === 'with_all';
    }

    goToManageRooms() {
        this.mutationBranchData(this.branch);
        if (this.isRecapRoute) {
            this.$router.push({ path: `${this.mainRouteGlobal}/recap/branches/add-rooms/${this.branch!.id}` });
        } else {
            this.$router.push({ path: `${this.mainRouteGlobal}/branches/add-rooms/${this.branch!.id}` });
        }
    }

    input(parsedAddressData: ParsedAddressData) {
        this.parsedAddressData = parsedAddressData;
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
    }

    updateFormByServerValues(branch: BranchSitesDataType) {
        if (branch) {
            if (branch.name) {
                this.name = branch.name;
            }
            if (branch.address) {
                this.address = branch.address;
            }
            if (branch.phone) {
                this.phone = branch.phone;
            }
            if (branch.email) {
                this.email = branch.email;
            }
            if (branch.post_code) {
                this.post_code = branch.post_code;
            }
            if (branch.branch_rooms.length) {
                this.rooms = branch.branch_rooms;
                this.isActive = true;
            }
            if (branch.external_id) {
                // @ts-ignore-next-line
                this[BRANCH_ID] = this.branch.external_id;
            }
        }
    }

    async onSubmit() {
        if (this.isDisabled) return;
        if (this.parsedAddressData) {
            this.address = this.parsedAddressData.calizyAddress;
        }
        this.sendRequest = true;
        const payload: CreateBranchPayloadType = {
            name: this.name,
            address: addPostCodeToAddress(this.address, this.post_code),
            email: this.email,
            phone: this.phone,
            post_code: this.post_code,
            [BRANCH_ID]: this[BRANCH_ID],
            kind: 'branch',
        };
        try {
            const { site } = this.currentRoute ?
                await OnboardingApi.updateOnboardingBranch(payload, this.currentRoute) :
                await OnboardingApi.createOnboardingBranch(payload);
            this.mutationBranchData(site);
            // eslint-disable-next-line
            if (this.isActive && !this.isRecapRoute) {
                this.$router.push({ path: `${this.mainRouteGlobal}${this.userKindBoth ? `/both/branches/add-rooms/` : `/branches/add-rooms/`}${site.id}` });
            } else if (this.isRecapRoute) {
                this.$router.push({ path: `${this.mainRouteGlobal}/recap/branches` });
            } else {
                this.$router.push({ path: `${this.mainRouteGlobal}${this.userKindBoth ? `/both/branches` : `/branches`}` });
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.sendRequest = false;
        }
    }

    async created() {
        this.loading = true;
        if (this.currentRoute) {
            const site = await OnboardingApi.getOnboardingSite(this.currentRoute);
            this.branch = site;
            this.updateFormByServerValues(site);
        }
        this.loading = false;
    }
}
</script>

<style lang='sass'>
.b-add-branch
    &__wrapper
        width: 600px
        margin-bottom: 250px

        @include media('<=phone')
            width: 100%

        .b-required-field
            font-weight: 500
            font-size: 14px
            margin-bottom: 70px

        .fw-select-address
            .multiselect__content-wrapper
                position: absolute !important
                top: 60px
                height: 300px
                .multiselect__content
                    .multiselect__element
                        background-color: #fff !important

        .fw-input
            &::placeholder
                font-weight: 400 !important
            font-weight: 400 !important

        .b-calendar__table__inner
            max-width: none

    &__rooms
        margin-bottom: 170px
        &__title
            font-size: 25px
            font-weight: 500
            margin-bottom: 0

        &__form
            display: flex
            justify-content: space-between
            align-items: center

        &__aside
            font-size: 16px
            font-weight: 500

        &__select
            width: 100px
        &__bottom
            margin-bottom: 150px
</style>
