<template>
<div class='b-geografic-popup h-flex h-flex-dir-column h-flex-justify-start'>
    <div class='b-geografic-popup--main'>
        <div class='b-geografic-popup--main__row'>
            <div class='b-geografic-popup--main__row__inner'>
                <div class='h-width-300'>
                    <FwInput
                        class='h-flex-1 h-ml-15'
                        :value='searchText'
                        :placeholder='$t(`LABEL.GEOGRAPHIC.ZONE.DISTRICTS.SEARCH`)'
                        iconRight='loupe'
                        iconSize='15'
                        inputStyle='white-bigger-search'
                        type='search'
                        @input='$emit("searchLocalInputMethod", searchText)'
                        @update:value='value => searchText = value'>
                    </FwInput>
                </div>
                <div class='h-ml-20 qa-advisor-gz-country'>
                    <FwCheckbox
                        v-model='isAllCountry'
                        :value='isAllCountry'
                        :styles='{
                            justifyContent: "flex-end"
                        }'
                        :iconStyles='{
                            paddingRight: `25px`,
                            height: `60px`,
                        }'
                        type='white-with-text-transparent'
                        :itemWidth='`290px`'
                        :text='$t(`WORKER.FORM.GEOGRAFIC.TITLE`)'
                        name='checkbox'
                        :itemCheckedRight='false'
                        isSquare
                        @change='updateDepartments'>
                    </FwCheckbox>
                </div>
            </div>
            <FwButton
                class='h-ph-20 qa-advisor-gz-add'
                :disabled='!isAllCountry && !checkboxDistricts.length && !beforeDepartmentsCloneData.length'
                borderRadiusType='small-border'
                fontSize='14px'
                @click.native='addCitiesMethod'>
                {{ $t('BUTTON.ADD') }}
            </FwButton>
        </div>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else
         style='overflow: auto;'
         class='b-geografic-popup--main__cities-wrapper'>
        <div class='h-flex h-flex-wrap'>
            <FwButton
                v-for='department in countryDepartmentsSortedValues'
                :key='department.id'
                size='large-xl'
                color='choose'
                borderRadiusType='tiny-border'
                style='width: 290px; padding-right: 20px!important'
                class='h-m-10'
                paddingHorizontal
                :fontSize='`16px`'
                :disabled='isAllCountry'
                :isCountry='wholeCountryValue'
                paddingOnlyRight>
                <FwCheckbox
                    :id='department.id'
                    :key='department.id'
                    v-model='checkboxDistricts'
                    :value='department.id'
                    type='white-with-text-transparent'
                    :styles='{
                        borderRadius: 0
                    }'
                    itemWidth='60px'
                    name='checkbox'
                    noIndent
                    isBorderRight
                    @change='setDepartments(department)'>
                </FwCheckbox>
                <div
                    class='h-width-100p h-height-100p h-flex h-align-items-center h-flex-space-between'
                    @click='onSubmit(department)'>
                    <span class='h-pl-20' style='max-width: 190px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; text-align: left'>
                        {{ department.post_code }} - {{ department.name }}
                    </span>
                    <FwIcon
                        icon='arrow-right'
                        size='12'
                        color='#213F6B'>
                    </FwIcon>
                </div>
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { unionWith, clone } from 'ramda';

import { GeograficTypes, GeograficCitiesType } from '@/types/Appointment';

import {
    POSITION_NAME,
} from '@/helpers/roles';
import { sortGeograficPlaces } from '@/helpers/geografic';

import ValidationMixin from '@/mixins/validation';
import PermissionsMixin from '@/mixins/permissions';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    validators: {
        [POSITION_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class AddOrEditGeograficForm extends Mixins(ValidationMixin, PermissionsMixin) {
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @AppointmentStore.State('geograficCitiesData') geograficCitiesData!: Array<GeograficCitiesType>;

    @Prop({ type: Object, default: () => ([]) }) readonly countryDepartments!: Array<GeograficTypes>;
    @Prop({ type: Object, default: null }) readonly wholeCountry!: Array<GeograficTypes>;
    @Prop({ type: Object, default: null }) readonly beforeDepartmentsCloneData!: Array<GeograficTypes>;
    @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isCountryDepartments!: boolean;
    isRequestSending: boolean = false;
    searchText: string = '';
    isAllCountry: boolean = false;
    departmentData: GeograficTypes | null = null;
    checkboxDistricts: Array<string> = [];
    departmentsCloneData: Array<GeograficCitiesType> = [];

    @Emit('onSubmit')
    onSubmit(data: GeograficTypes) {}

    get countryDepartmentsSortedValues(): Array<GeograficTypes> {
        return sortGeograficPlaces(this.countryDepartmentsValues);
    }
    get countryDepartmentsValues(): Array<GeograficTypes> {
        return this.countryDepartments.filter(item => item.post_code !== this.geoCountryIsoCode);
    }
    get wholeCountryValue(): GeograficTypes | undefined {
        return this.countryDepartments.find(item => item.post_code === this.geoCountryIsoCode);
    }
    get departmentsIds() {
        return this.geograficCitiesData.map(item => item.id);
    }
    setDepartments(department: GeograficTypes) {
        const arrChosenDepartments = this.countryDepartments.filter(item => this.checkboxDistricts.includes(item.id));
        let arr = [];
        if (!this.checkboxDistricts.includes(department.id)) {
            arr = this.geograficCitiesData.filter(item => item.id !== department.id);
        } else {
            arr = this.geograficCitiesData.filter(item => item.parent_id !== department.id);
        }

        this.setGeograficCitiesData(unionWith((x, y) => x.id === y.id, arr, arrChosenDepartments));
    }

    addCitiesMethod() {
        const arr = this.countryDepartments.filter(item => this.checkboxDistricts.includes(item.id));
        let countryObj = [];
        if (this.isAllCountry) {
            countryObj.push(this.wholeCountryValue);
        } else {
            countryObj = unionWith((x, y) => x.id === y.id, arr, this.geograficCitiesData);
        }

        this.setGeograficCitiesData(countryObj);
        this.$emit('closePopup');
    }

    updateDepartments() {
        if (this.isAllCountry) {
            this.departmentsCloneData = clone(this.geograficCitiesData);
            this.setGeograficCitiesData([]);
        } else {
            this.setGeograficCitiesData(this.departmentsCloneData);
        }
    }

    created() {
        if (this.geograficCitiesData.find(item => item.post_code === this.geoCountryIsoCode)) {
            this.isAllCountry = true;
        } else {
            this.checkboxDistricts = this.departmentsIds;
        }
    }

    @Watch('geograficCitiesData')
    updateCheckboxDistricts() {
        this.checkboxDistricts = this.departmentsIds;
    }
}
</script>

<style lang="sass">
.b-geografic-popup

    &--main
        &__cities-wrapper
            display: flex
            flex-wrap: wrap
            justify-content: flex-start
            padding-bottom: 20px

            @include media('<=phone')
                .fw-button--choose
                    width: 100% !important

        &__row
            display: flex
            justify-content: space-between
            align-items: center

            &__inner
                display: flex

            @include media('<=phone')
                flex-wrap: wrap
                flex-direction: column

                .h-width-300
                    width: 100%

                &__inner
                    flex-direction: column
                    width: 100%

    .fw-button--choose.ghost
        background: #fff !important
        opacity: 0.5
        border: #C8C8C8 !important
        color: #213F6B !important
        cursor: initial

    input.fw-input.fw-input--white-bigger-search::placeholder
        width: 100%
</style>
