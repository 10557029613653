const BOOK_CALENDAR_ON_SITE: string = 'BOOK_CALENDAR_ON_SITE';
const BOOK_CALENDAR_ON_PHONE: string = 'BOOK_CALENDAR_ON_PHONE';
const BOOK_CALENDAR_BY_ONLINE_VIDEO: string = 'BOOK_CALENDAR_BY_ONLINE_VIDEO';
const BOOK_CALENDAR_ON_SITE_IN_BRANCH: string = 'BOOK_CALENDAR_ON_SITE_IN_BRANCH';
const BOOK_CALENDAR_IN_BRANCH_FACE_TO_FACE: string = 'BOOK_CALENDAR_IN_BRANCH_FACE_TO_FACE';
const BOOK_CALENDAR_IN_BRANCH_VIDEOCONFERENCE: string = 'BOOK_CALENDAR_IN_BRANCH_VIDEOCONFERENCE';
const BOOK_CALENDAR_IN_BRANCH_PRIVATE_VIDEO: string = 'BOOK_CALENDAR_IN_BRANCH_PRIVATE_VIDEO';
const BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PUBLIC: string = 'BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PUBLIC';
const BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PRIVATE: string = 'BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PRIVATE';
const BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PUBLIC: string = 'BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PUBLIC';
const BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PRIVATE: string = 'BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PRIVATE';
const BOOK_CALENDAR_IN_SERVICE_POINT: string = 'BOOK_CALENDAR_IN_SERVICE_POINT';

const CONSULT_CALENDAR_COMPANY: string = 'CONSULT_CALENDAR_COMPANY';
const CONSULT_CALENDAR_BRANCH: string = 'CONSULT_CALENDAR_BRANCH';
const CONSULT_CALENDAR_HUB: string = 'CONSULT_CALENDAR_HUB';
const CONSULT_CALENDAR_ROOM: string = 'CONSULT_CALENDAR_ROOM';
const CONSULT_CALENDAR_SERVICE_POINT: string = 'CONSULT_CALENDAR_SERVICE_POINT';
const CONSULT_CALENDAR_USER: string = 'CONSULT_CALENDAR_USER';

const BOOK_CALENDAR_TYPES: Array<string> = [
    BOOK_CALENDAR_ON_PHONE,
    BOOK_CALENDAR_ON_SITE,
    BOOK_CALENDAR_BY_ONLINE_VIDEO,
    BOOK_CALENDAR_ON_SITE_IN_BRANCH,
    BOOK_CALENDAR_IN_BRANCH_FACE_TO_FACE,
    BOOK_CALENDAR_IN_BRANCH_VIDEOCONFERENCE,
    BOOK_CALENDAR_IN_BRANCH_PRIVATE_VIDEO,
    BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PUBLIC,
    BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PRIVATE,
    BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PUBLIC,
    BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PRIVATE,
    BOOK_CALENDAR_IN_SERVICE_POINT,
];

const CONSULT_CALENDAR_TYPES: Array<string> = [
    CONSULT_CALENDAR_COMPANY,
    CONSULT_CALENDAR_USER,
    CONSULT_CALENDAR_BRANCH,
    CONSULT_CALENDAR_HUB,
    CONSULT_CALENDAR_ROOM,
    CONSULT_CALENDAR_SERVICE_POINT,
];

const BOOK_CALENDAR_MAPPING: { [key: string]: string } = {
    'on-phone': BOOK_CALENDAR_ON_PHONE,
    'on-site': BOOK_CALENDAR_ON_SITE,
    'by-online-video': BOOK_CALENDAR_BY_ONLINE_VIDEO,
    'in-branch-on-site': BOOK_CALENDAR_ON_SITE_IN_BRANCH,
    'in-branch-face-to-face': BOOK_CALENDAR_IN_BRANCH_FACE_TO_FACE,
    'in-branch-videoconference': BOOK_CALENDAR_IN_BRANCH_VIDEOCONFERENCE,
    'in-branch-face-to-face-private': BOOK_CALENDAR_IN_BRANCH_PRIVATE_VIDEO,
    'in-branch-by-phone-public': BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PUBLIC,
    'in-branch-by-phone-private': BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PRIVATE,
    'in-branch-by-video-public': BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PUBLIC,
    'in-branch-by-video-private': BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PRIVATE,
    'in-service-point': BOOK_CALENDAR_IN_SERVICE_POINT,
};

const CONSULT_CALENDAR_MAPPING: { [key: string]: string } = {
    'company': CONSULT_CALENDAR_COMPANY,
    'branch': CONSULT_CALENDAR_BRANCH,
    'user': CONSULT_CALENDAR_USER,
    'hub': CONSULT_CALENDAR_HUB,
    'room': CONSULT_CALENDAR_ROOM,
    'service-point': CONSULT_CALENDAR_SERVICE_POINT,
};

const CONSULT_MONTH_VIEW_CALENDARS = ['hub', 'branch', 'service-point'];

export {
    BOOK_CALENDAR_ON_SITE,
    BOOK_CALENDAR_ON_PHONE,
    BOOK_CALENDAR_ON_SITE_IN_BRANCH,
    BOOK_CALENDAR_BY_ONLINE_VIDEO,
    BOOK_CALENDAR_IN_BRANCH_FACE_TO_FACE,
    BOOK_CALENDAR_IN_BRANCH_VIDEOCONFERENCE,
    BOOK_CALENDAR_IN_BRANCH_PRIVATE_VIDEO,
    BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PUBLIC,
    BOOK_CALENDAR_IN_BRANCH_BY_PHONE_PRIVATE,
    BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PUBLIC,
    BOOK_CALENDAR_IN_BRANCH_BY_VIDEO_PRIVATE,
    BOOK_CALENDAR_IN_SERVICE_POINT,

    CONSULT_CALENDAR_COMPANY,
    CONSULT_CALENDAR_USER,
    CONSULT_CALENDAR_BRANCH,
    CONSULT_CALENDAR_HUB,
    CONSULT_CALENDAR_ROOM,
    CONSULT_CALENDAR_SERVICE_POINT,

    BOOK_CALENDAR_TYPES,
    CONSULT_CALENDAR_TYPES,

    BOOK_CALENDAR_MAPPING,
    CONSULT_CALENDAR_MAPPING,

    CONSULT_MONTH_VIEW_CALENDARS,
};
