<template>
<div class='h-pt-10 h-flex-center h-flex-dir-column'>
    <FwDatepicker
        v-model='localDate'
        name='datepicker'
        class='h-mt-10'
        :propsRange='false'
        :selectMode='false'
        :propsLang='`${$i18n.locale}`'
        pickerStyle='static'
        :hideIcon='true'
        :showWeeksNumbers='true'
        :reservedSlots='reservedSlotsData'
        :hideInput='true'
        reservedSlotsColor='green'
        :disabledDate='disabledDate'
        @change-calendar-month='changeCalendarMonthHandler'
        @selectDate='updateDatesHandler'>
    </FwDatepicker>
</div>
</template>

<script lang='ts'>
import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';
import { ReservedSlotsType } from '@/types/ReservedSlots';

@Component
export default class CalendarDatePicker extends Vue {
    @Prop({ type: Date, required: true }) readonly dateCurrent!: null | Date;
    @Prop({ type: Date, required: true }) readonly dateUntil!: null | Date;
    @Prop({ type: Boolean, default: false }) readonly isBookCalendarType!: boolean;
    @Prop({ type: Array, default: () => [] }) readonly reservedSlotsData!: Array<ReservedSlotsType>;
    localDate: null | Date = null;

    get disabledDate() {
        return this.isBookCalendarType ? {
            disablePrevDays: new Date() || null,
            disableNextDays: this.dateUntil ? new Date(this.dateUntil).setDate(new Date(this.dateUntil).getDate() + 2) : null,
        } : null;
    }

    @Watch('dateCurrent')
    updateLocalDate() {
        this.localDate = this.dateCurrent;
    }

    mounted() {
        this.updateLocalDate();
    }

    @Emit('updateDates')
    updateDatesHandler(date: Date) {
        return date;
    }

    @Emit('changeCalendarMonth')
    changeCalendarMonthHandler(data: any) {}
}
</script>
