import axios, { AxiosRequestConfig } from 'axios';
import store from '@/store/index';
import { EventBus } from '@/utils/EventBus';
import i18n from '@/locale';

export default class WebApi {
    urlPrefix: string = process.env.VUE_APP_API_URL || '';
    ssoPrefix: string = process.env.VUE_APP_SSO_PREFIX || '';

    constructor() {
        axios.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
        axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
        // @ts-ignore-next-line
        axios.interceptors.request.use((config: AxiosRequestConfig) => {
            if (
                config.url &&
                config.method &&
                ['post', 'delete', 'put', 'patch', 'get'].includes(config.method) &&
                (config.url.startsWith('/') || config.url.startsWith(this.urlPrefix)) &&
                config.headers &&
                config.headers[config.method]
            ) {
                // @ts-ignore
                config.headers[config.method]['Accept-Language'] = 'en-US,en;q=0.5; fr-CH, fr;q=0.9';
                // @ts-ignore
                config.headers[config.method]['X-App-Origin'] = 'Saas';
                // @ts-ignore
                if (!config.headers[config.method]['Content-Type']) {
                    // @ts-ignore
                    config.headers[config.method]['Content-Type'] = `application/json`;
                }
                if (['delete', 'get'].includes(config.method) && !config.data) {
                    config.data = {};
                }
            }
            return config;
        });

        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error && error.response && error.response.status === 401) {
                if (window.location.pathname !== '/') {
                    store.commit('GlobalStore/mutationUnLoginUser');
                    window.location.href = '/';
                }
                EventBus.$emit(`sent-notification`, i18n.t('ERRORS.401'));
            } else if (error && error.response && error.response.status === 403) {
                EventBus.$emit(`sent-notification`, i18n.t('ERRORS.403'));
            }
            return Promise.reject(error);
        });
    }
}
