import axios from 'axios';
import { BranchTitles } from '@/types/Branch';
import {
    CategoryLocationSkillNamesResponseType,
    CategorySkillNamesResponseType,
    HubsResponseType,
    CreateAppointmentCategoryPayloadType,
    AppointmentCategoriesResponseType,
    CreateBranchPayloadType,
    AppointmentCategoryResponseType,
    BranchRoomResponseType,
    HubCreateType,
    AdvisorType,
    GeograficTypes,
    pagePaginationData,
    CategoryLocationAppointmentSkillNamesResponseType,
} from '@/types/Appointment';
import { BranchSitesDataType } from '@/types/Site';
import { updateUrlGetParams } from '@/helpers/url';
import WebApi from '../WebApi';

class OnboardingApi extends WebApi {
    async getBranchesTitles(): Promise<Array<BranchTitles>> {
        const { data } = await axios.get(
            `${this.urlPrefix}/front_helpers/branch_titles`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return data.branch_titles;
    };
    async getHubNames() {
        return axios.get(
            `${this.urlPrefix}/front_helpers/hub_names`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };
    async fetchSkillNames(): Promise<CategorySkillNamesResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/skill_names`);
        return data;
    }

    async categoryLocationSkillNames(): Promise<CategoryLocationSkillNamesResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/category_location_skill_names`);
        return data;
    }

    async getAppointmentCategoriesData() {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/appointment_category_kinds`);

        return data;
    };

    async categoryLocationSkillNamesForAppointment(appointment_category_id: string, user_id?: string): Promise<CategoryLocationAppointmentSkillNamesResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/front_helpers/location_skills_for_appointment_category`, { appointment_category_id, ...user_id && { user_id }});
        const { data } = await axios.get(url);
        return data;
    }

    async fetchHubs(): Promise<HubsResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/hubs`);
        return data;
    }

    async getAppointmentCategories(): Promise<AppointmentCategoriesResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/appointment_categories`);
        return data;
    }

    async createOnboardingAppointmentCategory(payload: CreateAppointmentCategoryPayloadType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/account_onboarding/appointment_categories`,
            payload
        );
        return data;
    }

    async editOnboardingAppointmentCategory(payload: CreateAppointmentCategoryPayloadType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/account_onboarding/appointment_categories/${id}`,
            payload
        );
        return data;
    }

    async getOnboardingAppointmentCategories(payload: pagePaginationData): Promise<AppointmentCategoriesResponseType> {
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/appointment_categories${urlParams}`);
        return data;
    }

    async getOnboardingAppointmentCategory(id: string): Promise<AppointmentCategoryResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/appointment_categories/${id}`);
        return data;
    }

    async deleteOnboardingAppointmentCategories(id: string) {
        const { data } = await axios.delete(`${this.urlPrefix}/account_onboarding/appointment_categories/${id}`);
        return data;
    }

    async createOnboardingBranch(site: CreateBranchPayloadType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/account_onboarding/sites`,
            { site }
        );
        return data;
    }

    async getOnboardingBranchesRoomsList(kind: string, payload: pagePaginationData) {
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/sites?kind=${kind}${urlParams}`);
        return data;
    }

    async getOnboardingAllBranchesRoomsList(kind: string) {
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/sites?kind=${kind}`);
        return data;
    }

    async getOnboardingSite(id: string): Promise<BranchSitesDataType> {
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/sites/${id}`);
        return data.site;
    }

    async getOnboardingBranchRooms(kind: string, id: string) {
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/sites?kind=${kind}&branch_id=${id}`);
        return data;
    }

    async createOnboardingRoom(site: BranchRoomResponseType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/account_onboarding/sites`,
            { site }
        );
        return data;
    }
    async updateOnboardingRoom(site: BranchRoomResponseType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/account_onboarding/sites/${id}`,
            { site }
        );
        return data;
    }

    async deleteOnboardingRoom(id: string) {
        const { data } = await axios.delete(`${this.urlPrefix}/account_onboarding/sites/${id}`);
        return data;
    }
    async deleteOnboardingBranch(id: string) {
        const { data } = await axios.delete(`${this.urlPrefix}/account_onboarding/sites/${id}`);
        return data;
    }
    async updateOnboardingBranch(site: CreateBranchPayloadType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/account_onboarding/sites/${id}`,
            { site }
        );
        return data;
    }

    async getOnboardingHubs(payload: pagePaginationData) {
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/hubs${urlParams}`);
        return data;
    }

    async createOnboardingHub(hub: HubCreateType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/account_onboarding/hubs`,
            { hub }
        );
        return data;
    }
    async deleteOnboardingHub(id: string) {
        const { data } = await axios.delete(`${this.urlPrefix}/account_onboarding/hubs/${id}`);
        return data;
    }
    async updateOnboardingHub(hub: HubCreateType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/account_onboarding/hubs/${id}`,
            { hub }
        );
        return data;
    }

    async getOnboardingHub(id: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/account_onboarding/hubs/${id}`,
        );
        return data;
    }

    async getFrontHelpers(name: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/front_helpers/${name}`,
        );
        return data;
    }

    async getGeograficCities(name: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/front_helpers/${name}`,
        );
        return data;
    }

    async getGeograficDataBySearch(name: string, payload: pagePaginationData, searchValue: string, id: string) {
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/${name}?department_id=${id}&post_code=${searchValue}${urlParams}`);
        return data;
    }

    async getGeograficDepartments(payloadData: GeograficTypes, params: pagePaginationData) {
        const urlParams = `&pagination[per_page]=${params.per_page}&pagination[page]=${params.current_page}`;
        const { data } = await axios.get(
            `${this.urlPrefix}/front_helpers/cities?department_id=${payloadData.id}&post_code=${payloadData.post_code}${urlParams}`,
        );
        return data;
    }

    async getSearchGeograficDepartments(payloadData: GeograficTypes) {
        const { data } = await axios.get(
            `${this.urlPrefix}/front_helpers/cities?department_id=${payloadData.id}&post_code=${payloadData.post_code}`,
        );
        return data;
    }

    async createOnboardingAdvisor(advisor: AdvisorType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/account_onboarding/advisors`,
            { advisor }
        );
        return data;
    }

    async updateOnboardingAdvisor(advisor: AdvisorType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/account_onboarding/advisors/${id}`,
            { advisor }
        );
        return data;
    }

    async getOnboardingAdvisor(id: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/account_onboarding/advisors/${id}`,
        );
        return data;
    }

    async getOnboardingAdvisors(payload: pagePaginationData) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/advisors${urlParams}`);
        return data;
    }

    async deleteOnboardingAdvisor(id: string) {
        const { data } = await axios.delete(`${this.urlPrefix}/account_onboarding/advisors/${id}`);
        return data;
    }
}

const onboardingApi = new OnboardingApi();

export {
    onboardingApi as OnboardingApi,
};
