<template>
<div class='b-horizontal-statistics__wrapper'>
    <h1 v-if='countText'
        class='b-horizontal-statistics__title'>
        {{ $t(countText) }}
        <span v-if='count'>
            : {{ count }}
        </span>
    </h1>

    <div v-for='(key, index) in ordering'
         :key='index'
         class='b-horizontal-statistics__item'>
        <div class='b-horizontal-statistics__text'>
            {{ doTranslateKey(key) }}
        </div>
        <div class='b-horizontal-statistics__value'>
            <div class='b-horizontal-statistics__value__inner'
                 :style='{
                     width: animateStart? `${data[key] / maxValue * 100}%` : "0px",
                     backgroundColor: colors[index],
                     opacity: animateStart ? "1" : "0"
                 }'>
                {{ data[key] }}
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { HUB_TYPE } from '@/routes/planAppointment/consts';

@Component
export default class HorizontalStatistic extends Vue {
    @Prop({ type: Number, default: 0 }) readonly count!: number;
    @Prop({ type: Object, required: true }) readonly data!: { [key: string]: number };
    @Prop({ type: Array, required: true }) readonly ordering!: Array<string>;

    animateStart: boolean = false;

    colors: Array<string> = [
        `#27DBAD`,
        `#FFC500`,
        `#A11CD8`,
        `#00DFEC`,
        `#F71055`,
        `#ACDA64`,
        `#F3982B`,
        `#2B9105`,
        `#223F6B`,
    ]

    get countText(): string {
        if (!this.$route.meta || !this.$route.params.type) {
            return '';
        }
        return this.$route.params.type === 'for' ? this.$route.meta.titleFor : this.$route.meta.titleBy;
    }

    get maxValue(): number {
        return Math.max(...Object.values(this.data));
    }

    doTranslateKey(key: string): string | TranslateResult {
        const trimmedKey = key.trim();
        let translateKey = trimmedKey;
        if (this.$route.meta && this.$route.meta.type === HUB_TYPE && (trimmedKey === 'branch' || trimmedKey === 'outside')) {
            if (this.$route.meta!.requestType === `recipients`) {
                translateKey = `for_hub_${trimmedKey}`;
            } else {
                translateKey = `hub_${trimmedKey}`;
            }
        } else if ((trimmedKey === 'branch' || trimmedKey === 'outside') && this.$route.meta!.requestType === `recipients`) {
            translateKey = `for_${trimmedKey}`;
        }
        return this.$i18n.t(translateKey);
    }

    mounted() {
        setTimeout(() => {
            this.animateStart = true;
        }, 100);
    }
}
</script>

<style lang='sass'>
.b-horizontal-statistics
    &__wrapper
        padding: 14px 30px
        background-color: #fff
        border-radius: 20px

    &__item
        display: flex
        align-items: center
        height: 39px

    &__text
        font-size: 14px
        width: 300px
        color: #213F6B
        font-weight: bold
        padding-right: 10px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__value
        flex: 1

        &__inner
            height: 25px
            border-radius: 14.5px
            font-size: 14px
            color: #fff
            padding: 0 16px
            font-weight: 500
            display: flex
            line-height: 25px
            align-self: center
            transition: width 0.6s cubic-bezier(.09,.8,.95,.97), opacity 0.6s ease-in-out

    &__title
        font-size: 18px
        line-height: 24px
</style>
