export default {
    'COMPANIES.FORM.TITLE': 'Add a company',

    'COMPANIES.SEARCH.PLACEHOLDER': 'Search a company',
    'COMPANIES.CHOOSE.PLACEHOLDER': 'Choose company',
    'COMPANIES.ADD.BUTTON': 'Add a company',
    'COMPANY.TITLE.TAKEN': 'Title has already been taken',

    'COMPANIES.FORM.INPUT.NAME.LABEL': 'Company name',
    'COMPANIES.FORM.INPUT.ADDRESS.LABEL': 'Address',
    'COMPANIES.FORM.INPUT.ADDRESS_2.LABEL': 'Address 2',
    'COMPANIES.FORM.INPUT.START_POINT.LABEL': 'Starting point',
    'COMPANIES.FORM.INPUT.START_POINT.ERROR': 'Invalid starting point',
    'COMPANIES.FORM.INPUT.PHONE.LABEL': 'Phone',
    'COMPANIES.FORM.INPUT.CITY.LABEL': 'City',
    'COMPANIES.FORM.INPUT.PLACEHOLDER.NUMBERS': 'numbers',

    'COMPANY.POPUP.LOCATION.TITLE': 'Do you confirm the address?',
    'COMPANY.POPUP.LOCATION.BUTTON.CONFIRM': 'Yes, I confirm this address',
    'COMPANY.POPUP.LOCATION.BUTTON.CANCEL': 'No change the address',
    'COMPANY.POPUP.LOCATION.FAILED.TITLE': 'Geolocation failed!',
    'COMPANY.POPUP.LOCATION.FAILED.TEXT': 'Please try to input another address',
    'COMPANY.POPUP.LOCATION.FAILED.BUTTON': 'Change address',
    'COMPANY.YOU.CANNOT.EDIT.ADDRESS': 'You cannot edit Address1, Zip code, City fields after confirmation',

    'COMPANIES.FORM.SUBMIT.TEXT': 'Add',
    'COMPANIES.POPUP.SUCCESS.MESSAGE': 'The company has been added',
    'COMPANIES.POPUP.SUCCESS.CHANGED.MESSAGE': 'The company has been changed',
    'COMPANIES.POPUP.CHECKBOX.TXT': 'I confirm that the address above is correct. Ticking this checkbox will launch the calculations of the first availabilities',
};
