<template>
<div class='b-change-workers-hours__wrapper'>
    <div v-show='hoursPopup'
         class='h-flex h-flex-center h-flex-dir-column'>
        <div class='h-flex h-flex-center b-working-hours'>
            <div v-for='(day, indexWeek) in workingTimeValue'
                 class='b-change-workers-hours__inner'>
                <h3 class='h-text-center'>
                    {{ $t(day.day) }}
                </h3>
                <div class='b-switch-time-day h-pt-0 h-pb-10 h-ph-10 h-mh-5'>
                    <div v-for='(half, indexDay) in day.dayPart'>
                        <div class='b-switch-day-time__half h-flex h-flex-dir-column h-flex-space-between'>
                            <div class='b-switch-day h-flex h-flex-center h-flex-space-between h-width-100p'>
                                <p class='b-switch-font h-bold h-mr-15'>
                                    {{ $t(half.label) }}
                                </p>
                                <FwSwitcher
                                    class='h-width-40'
                                    :isActive='half.state'
                                    @toggleSwitch='toggleSwitch(day, half, indexDay)'>
                                </FwSwitcher>
                            </div>
                            <div class='b-switch-time h-width-100p'>
                                <div class='b-switch-time__input h-flex h-flex-center h-flex-space-between h-width-100p'>
                                    <label class='b-switch-font__label h-bold'>
                                        {{ $t('WORKER.TIME.SLOT.FROM') }}
                                    </label>
                                    <div style='width: 85px; position: relative;'
                                         class='b-calendar-select__week fw-select-base fw-select-base__time h-ml-10'>
                                        <div style='width: 100%;'>
                                            <div style='position: relative;'
                                                 :class='{ "b-calendar-select__week--active": !half.state || workerUpdateData }'
                                                 class='b-calendar-select__week fw-select-base fw-select-base__time'>
                                                <FwSelect
                                                    v-model='half.timeFrom'
                                                    :class='`${indexWeek}-${indexDay}-0`'
                                                    :canNotDelete='true'
                                                    :disabled='!half.state || workerUpdateData'
                                                    :propsPlaceholder='checkTimeProps(half.timeFrom)'
                                                    :searchable='false'
                                                    :options='checkDayStatus(indexDay, null, day)'
                                                    @input='checkMorningTime(day)'
                                                    @focus='scrollToActive(indexWeek, indexDay, 0, checkDayStatus(indexDay, null, day), half.timeFrom)'>
                                                </FwSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='b-switch-time__input h-flex h-flex-center h-flex-space-between h-width-100p h-mt-10'>
                                    <label class='b-switch-font__label h-bold'>
                                        {{ $t('WORKER.TIME.SLOT.TO') }}
                                    </label>
                                    <div style='width: 85px; position: relative;'
                                         :class='{"b-calendar-select__week--active": !half.state || workerUpdateData}'
                                         class='b-calendar-select__week fw-select-base fw-select-base__time h-ml-10'>
                                        <div style='position: relative'>
                                            <FwSelect
                                                v-model='half.timeTo'
                                                :class='`${indexWeek}-${indexDay}-1`'
                                                :canNotDelete='true'
                                                :disabled='!half.state || workerUpdateData'
                                                :propsPlaceholder='checkTimeProps(half.timeTo)'
                                                :searchable='false'
                                                :options='checkDayStatus(indexDay, half, day)'
                                                @input='checkAfternoonTime(day)'
                                                @focus='scrollToActive(indexWeek, indexDay, 1, checkDayStatus(indexDay, half, day), half.timeTo)'>
                                            </FwSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if='workerStoreData && workerStoreData.confirmed_at && !workerUpdateData && !servicePointsScheduleId'
             class='h-flex h-flex-dir-column h-flex-center h-mt-40 h-mb-0 h-fw-500 h-font-16'>
            {{ $t('UPDATE.TITLE.POPUP') }}
            <div class='b-working-hours__date-picker h-mt-15'>
                <FwDatepicker
                    v-model='dateFrom'
                    name='datepicker'
                    :placeholder='$t(`LABEL.CHOOSE_DAY`)'
                    :propsRange='false'
                    :propsLang='`${$i18n.locale}`'
                    :selectMode='false'
                    pickerStyle='static'
                    propsType='date'
                    propsFormat='DD/MM/YYYY'
                    openOnTop
                    :disabledDate='disabledDateByObject'
                    propsInputClass='b-add-reserved-slot-form__datepicker'>
                    <template #icon>
                        <FwIcon
                            class='h-flex'
                            icon='calendar'
                            size='16'
                            color='#203f6a'>
                        </FwIcon>
                    </template>
                </FwDatepicker>
            </div>
            <FwButton
                class='h-mt-30'
                :style='{
                    padding: `0 22px`,
                    height: `40px`,
                    minWidth: `auto`
                }'
                borderRadiusType='tiny-border'
                :disabled='isRequestSending || !dateFrom'
                @click='updateWorkerSchedule'>
                {{ $t('BUTTON.SAVE') }}
            </FwButton>
        </div>
        <div v-if='workerUpdateData && workerStoreData.confirmed_at' class='h-mt-40 h-mb-0 h-fw-500 h-font-16 h-opacity-0_5'>
            {{ $t('UPDATE.TITLE.ALREADY.UPDATED') }}
        </div>
        <FwButton
            v-if='workerStoreData && !workerStoreData.confirmed_at'
            class='h-mt-30 qa-wh-submit'
            :style='{
                padding: `0 22px`,
                height: `40px`,
                minWidth: `auto`
            }'
            borderRadiusType='tiny-border'
            :disabled='isRequestSending'
            @click='confirmWorkerSchedule'>
            {{ $t('WORKER.POPUP.CONFIRM.BUTTON') }}
        </FwButton>
    </div>
    <ConfirmPopup v-show='savePopup' @close='$emit("closePopup")' @sendDataToServer='onSubmit'></ConfirmPopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import {
    WorkerTimeType,
    WorkerScheduleTimeType,
} from '@/types/Workers';
import DateMixin from '@/mixins/dateMixin';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { ConfirmPopup } from '@/components/popups/ConfirmPopup';
import { dateToString, WORKING_HOURS } from '@/helpers/dates';
import { SitesApi } from '@/api/sites/SitesApi';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: { ConfirmPopup },
})
export default class ChangeWorkersWorkingHours extends Mixins(DateMixin) {
    @WorkerPageStore.Action('storeWorkerScheduleData') storeWorkerScheduleData!: Function;
    @WorkerPageStore.Mutation('WORKER_UPDATE_DATA') setWorkerUpdateData!: Function;

    @Prop({ type: Object, default: null }) readonly workingTimeValue!: WorkerTimeType;
    @Prop({ type: Boolean, default: false }) readonly savePopup!: boolean;
    @Prop({ type: Boolean, default: false }) readonly hoursPopup!: boolean;
    @Prop({ type: String, default: 'ValidateWorkingHours' }) readonly actionUpdate!: string;
    @Prop({ type: String, default: null }) readonly servicePointsScheduleId!: string | null;

    isRequestSending: boolean = false;
    timeDay: Array<string> = WORKING_HOURS;
    timeLunch: Array<string> = WORKING_HOURS;
    dateFrom: string = '';
    currentDate: Date = this.getDayDateAtNight();

    get switcherState() {
        const obj: { [key: string]: any; } = this.workingTimeValue;
        return Object.keys(obj).every(k => (
            obj[k].dayPart.lunch.state === false &&
            obj[k].dayPart.day.state === false &&
            obj[k].state === false
        ));
    }

    get workerStoreData(): any {
        return this.$store.state.WorkerPageStore.workerScheduleData;
    }

    get workerUpdateData(): any {
        return this.$store.state.WorkerPageStore.workerUpdateData;
    }

    get workingHoursRequest() {
        const obj = {
            monday_start: this.workingTimeValue.monday.dayPart.day.timeFrom,
            monday_end: this.workingTimeValue.monday.dayPart.day.timeTo,
            monday_break_start: this.workingTimeValue.monday.dayPart.lunch.timeFrom,
            monday_break_end: this.workingTimeValue.monday.dayPart.lunch.timeTo,
            tuesday_start: this.workingTimeValue.tuesday.dayPart.day.timeFrom,
            tuesday_end: this.workingTimeValue.tuesday.dayPart.day.timeTo,
            tuesday_break_start: this.workingTimeValue.tuesday.dayPart.lunch.timeFrom,
            tuesday_break_end: this.workingTimeValue.tuesday.dayPart.lunch.timeTo,
            wednesday_start: this.workingTimeValue.wednesday.dayPart.day.timeFrom,
            wednesday_end: this.workingTimeValue.wednesday.dayPart.day.timeTo,
            wednesday_break_start: this.workingTimeValue.wednesday.dayPart.lunch.timeFrom,
            wednesday_break_end: this.workingTimeValue.wednesday.dayPart.lunch.timeTo,
            thursday_start: this.workingTimeValue.thursday.dayPart.day.timeFrom,
            thursday_end: this.workingTimeValue.thursday.dayPart.day.timeTo,
            thursday_break_start: this.workingTimeValue.thursday.dayPart.lunch.timeFrom,
            thursday_break_end: this.workingTimeValue.thursday.dayPart.lunch.timeTo,
            friday_start: this.workingTimeValue.friday.dayPart.day.timeFrom,
            friday_end: this.workingTimeValue.friday.dayPart.day.timeTo,
            friday_break_start: this.workingTimeValue.friday.dayPart.lunch.timeFrom,
            friday_break_end: this.workingTimeValue.friday.dayPart.lunch.timeTo,
            saturday_start: this.workingTimeValue.saturday.dayPart.day.timeFrom,
            saturday_end: this.workingTimeValue.saturday.dayPart.day.timeTo,
            saturday_break_start: this.workingTimeValue.saturday.dayPart.lunch.timeFrom,
            saturday_break_end: this.workingTimeValue.saturday.dayPart.lunch.timeTo,
            sunday_start: this.workingTimeValue.sunday.dayPart.day.timeFrom,
            sunday_end: this.workingTimeValue.sunday.dayPart.day.timeTo,
            sunday_break_start: this.workingTimeValue.sunday.dayPart.lunch.timeFrom,
            sunday_break_end: this.workingTimeValue.sunday.dayPart.lunch.timeTo,
        };
        Object.keys(obj).forEach(i => {
            // @ts-ignore-next-line
            if (obj[i]) {
                // @ts-ignore-next-line
                obj[i] = `${obj[i]}:00`;
            }
        });
        return obj;
    }

    get disabledDateByObject() {
        return {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate() + 1) : new Date(),
        };
    }

    scrollToActive(indexWeek: string, indexDay: string, id: number, currentOptionsList: any, time: string) {
        this.$nextTick(() => {
            const activeSelector = document.querySelector(`.${indexWeek}-${indexDay}-${id}`);
            if (activeSelector) {
                const wrapper = activeSelector.querySelector('.multiselect__content-wrapper');
                if (wrapper) {
                    const options = wrapper.querySelectorAll('.multiselect__element');
                    const activeIndex = currentOptionsList.indexOf(time);
                    if (wrapper && options[activeIndex]) {
                        const activeOption = options[activeIndex] as HTMLElement;
                        wrapper.scrollTop = activeOption.offsetTop;
                    }
                }
            }
        });
    }

    addMinutesMethod(time: string, minsToAdd: string) {
        function dateString(day: any) { return (day < 10 ? '0' : '') + day; }
        let result = null;
        if (time) {
            const piece = time.split(':');
            const minutes = (piece[0] as any) * 60 + +piece[1] + +minsToAdd;
            const dayHours = dateString(minutes % (24 * 60) / 60 | 0);
            const dayMinutes = dateString(minutes % 60);
            result = `${dayHours}:${dayMinutes}`;
        }
        return result;
    }

    async updateWorkerSchedule() {
        this.isRequestSending = true;
        const payload = {
            updatable_action: this.actionUpdate,
            dt_execute_at: dateToString(new Date(this.dateFrom), true),
            updatable_id: this.workerStoreData.id,
            updatable_type: 'WorkingSchedule',
            new_data: this.workingHoursRequest,
        };
        try {
            const response = await WorkersWebApi.updateTechnicianSchedulesData(payload);
            this.setWorkerUpdateData(response.data.update_request);
            this.sentNotif('Update is scheduled', true);
            this.$emit('closePopup');
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    checkDayStatus(value: string | null, halfDay: any, dayData: any) {
        let statusResult = null;
        if (value === `day`) {
            const arrMorning = [];
            let lunchFrom = null;
            let lunchTo = null;
            if (dayData) {
                lunchFrom = dayData.dayPart.lunch.timeFrom;
                lunchTo = dayData.dayPart.lunch.timeTo;
            }
            if (halfDay) {
                if (!lunchTo || !lunchFrom) {
                    for (let i = 0; i < this.timeDay.length; i++) {
                        if (this.timeDay[i] > halfDay.timeFrom) {
                            arrMorning.push(this.timeDay[i]);
                        } else if (!halfDay.timeFrom) {
                            halfDay.timeTo = halfDay.timeFrom;
                            arrMorning.push(this.timeDay[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < this.timeDay.length; i++) {
                        if (halfDay.timeFrom && this.timeDay[i] >= lunchTo) {
                            arrMorning.push(this.timeDay[i]);
                        } else if (!lunchTo || !lunchFrom) {
                            arrMorning.push(this.timeDay[i]);
                        }
                    }
                }
                statusResult = arrMorning;
            } else {
                const arrFirstHalfMorning = [];
                for (let i = 0; i < this.timeDay.length; i++) {
                    if (this.timeDay[i] <= lunchFrom) {
                        arrFirstHalfMorning.push(this.timeDay[i]);
                    } else if (!lunchTo || !lunchFrom) {
                        arrFirstHalfMorning.push(this.timeDay[i]);
                    }
                }
                statusResult = arrFirstHalfMorning;
            }
        } else {
            const arrMorning = [];
            if (halfDay) {
                for (let i = 0; i < this.timeLunch.length; i++) {
                    if (this.timeLunch[i] > halfDay.timeFrom) {
                        arrMorning.push(this.timeLunch[i]);
                    } else if (!halfDay.timeFrom) {
                        arrMorning.push(this.timeDay[i]);
                    }
                }
                statusResult = arrMorning;
            } else {
                statusResult = this.timeLunch;
            }
        }
        return statusResult;
    }

    checkMorningTime(day: any): void {
        if (day.dayPart.day.timeFrom > day.dayPart.lunch.timeFrom) {
            day.dayPart.day.timeFrom = day.dayPart.lunch.timeFrom;
        } else if ((day.dayPart.day.timeFrom > day.dayPart.day.timeTo)) {
            day.dayPart.day.timeTo = this.addMinutesMethod(day.dayPart.day.timeFrom, '15');
        } else if ((day.dayPart.lunch.timeFrom > day.dayPart.lunch.timeTo)) {
            day.dayPart.lunch.timeTo = this.addMinutesMethod(day.dayPart.lunch.timeFrom, '15');
        } else if ((day.dayPart.lunch.timeFrom) && !(day.dayPart.lunch.timeFrom)) {
            day.dayPart.lunch.timeTo = this.addMinutesMethod(day.dayPart.lunch.timeFrom, '15');
        }
    }
    checkAfternoonTime(day: any): void {
        if (day.dayPart.day.timeTo < day.dayPart.lunch.timeTo) {
            day.dayPart.day.timeTo = day.dayPart.lunch.timeTo;
        } else if ((!day.dayPart.lunch.timeFrom && day.dayPart.lunch.timeTo)) {
            day.dayPart.lunch.timeFrom = this.addMinutesMethod(day.dayPart.lunch.timeTo, '-15');
        }
    }

    async confirmWorkerSchedule() {
        this.isRequestSending = true;
        const currentDate = new Date();
        if (this.servicePointsScheduleId) {
            try {
                const data = await SitesApi.updateServicePointSchedulesData(this.workingHoursRequest, currentDate, this.servicePointsScheduleId);
                this.storeWorkerScheduleData(data.working_schedule);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CONFIRMED', true);
                this.$emit('closeWorkingHoursPopup');
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        } else {
            const scheduleData = this.workerStoreData.id;
            try {
                const response = await WorkersWebApi.confirmTechnicianSchedulesData(this.workingHoursRequest, currentDate, scheduleData);
                this.isRequestSending = false;
                this.storeWorkerScheduleData(response.data.working_schedule);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CONFIRMED', true);
                this.$emit('closeWorkingHoursPopup');
            } catch ({ response }) {
                this.isRequestSending = false;
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        }
    }
    checkTimeProps(value: string): string {
        return !value ? 'HH:mm' : value;
    }
    toggleSwitch(day: any, value: any, index: string): void {
        if (!this.workerStoreData.update_request) {
            this.$emit('toggleSwitch', value);
            if (index === `lunch`) {
                day.dayPart.day.state = true;
                if (!value.state) {
                    day.dayPart.lunch.timeFrom = null;
                    day.dayPart.lunch.timeTo = null;
                } else if (!day.dayPart.day.state) {
                    day.dayPart.day.timeFrom = '08:00';
                    day.dayPart.day.timeTo = '18:00';
                } else {
                    day.dayPart.lunch.timeFrom = '12:00';
                    day.dayPart.lunch.timeTo = '14:00';
                }
            } else if (index === `day`) {
                day.dayPart.lunch.state = false;
                if (!value.state) {
                    day.dayPart.day.timeFrom = null;
                    day.dayPart.day.timeTo = null;
                    day.dayPart.lunch.timeFrom = null;
                    day.dayPart.lunch.timeTo = null;
                } else {
                    day.dayPart.day.timeFrom = '08:00';
                    day.dayPart.day.timeTo = '18:00';
                }
            }
        }
    }
    closePopup(): void {
        this.showFinalePopup = false;
        this.$emit('showSavePopup', false);
    }

    @Emit('onSubmit')
    onSubmit(): WorkerScheduleTimeType {
        return {
            monday_start: this.workingTimeValue.monday.dayPart.day.timeFrom,
            monday_end: this.workingTimeValue.monday.dayPart.day.timeTo,
            monday_break_start: this.workingTimeValue.monday.dayPart.lunch.timeFrom,
            monday_break_end: this.workingTimeValue.monday.dayPart.lunch.timeTo,
            tuesday_start: this.workingTimeValue.tuesday.dayPart.day.timeFrom,
            tuesday_end: this.workingTimeValue.tuesday.dayPart.day.timeTo,
            tuesday_break_start: this.workingTimeValue.tuesday.dayPart.lunch.timeFrom,
            tuesday_break_end: this.workingTimeValue.tuesday.dayPart.lunch.timeTo,
            wednesday_start: this.workingTimeValue.wednesday.dayPart.day.timeFrom,
            wednesday_end: this.workingTimeValue.wednesday.dayPart.day.timeTo,
            wednesday_break_start: this.workingTimeValue.wednesday.dayPart.lunch.timeFrom,
            wednesday_break_end: this.workingTimeValue.wednesday.dayPart.lunch.timeTo,
            thursday_start: this.workingTimeValue.thursday.dayPart.day.timeFrom,
            thursday_end: this.workingTimeValue.thursday.dayPart.day.timeTo,
            thursday_break_start: this.workingTimeValue.thursday.dayPart.lunch.timeFrom,
            thursday_break_end: this.workingTimeValue.thursday.dayPart.lunch.timeTo,
            friday_start: this.workingTimeValue.friday.dayPart.day.timeFrom,
            friday_end: this.workingTimeValue.friday.dayPart.day.timeTo,
            friday_break_start: this.workingTimeValue.friday.dayPart.lunch.timeFrom,
            friday_break_end: this.workingTimeValue.friday.dayPart.lunch.timeTo,
            saturday_start: this.workingTimeValue.saturday.dayPart.day.timeFrom,
            saturday_end: this.workingTimeValue.saturday.dayPart.day.timeTo,
            saturday_break_start: this.workingTimeValue.saturday.dayPart.lunch.timeFrom,
            saturday_break_end: this.workingTimeValue.saturday.dayPart.lunch.timeTo,
            sunday_start: this.workingTimeValue.sunday.dayPart.day.timeFrom,
            sunday_end: this.workingTimeValue.sunday.dayPart.day.timeTo,
            sunday_break_start: this.workingTimeValue.sunday.dayPart.lunch.timeFrom,
            sunday_break_end: this.workingTimeValue.sunday.dayPart.lunch.timeTo,
        };
    }
    @Watch('switcherState')
    showSavePopup() {
        this.$emit('showSavePopup', !this.switcherState);
    }
}
</script>

<style lang='sass'>
.b-change-workers-hours
    &__inner
        @include media('<=phone')
            flex: 1 0
            max-width: 50%

    &__wrapper
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column
        margin-top: 20px

        @include media('<=phone')
            height: 600px
            max-height: 70vh
            overflow: auto
            justify-content: flex-start

.b-working-hours
    &__date-picker
        width: 320px
        height: 60px
        background: #fff
        display: flex
        align-items: center

        .mx-datepicker--static .mx-calendar, .mx-datepicker--static .mx-datepicker-popup
            background: #fff !important
            bottom: 65px !important
            padding: 10px

        .mx-input::placeholder
            overflow: inherit
            color: $dark-blue

    .fw-select-base .multiselect
        height: 30px !important
        width: 81px !important
        border-radius: 8px !important
        background-color: #FFFFFF
        box-shadow: $big-box-shadow !important

        &::after
            bottom: 10px
            height: 10px

        .multiselect__tags
            background: #fff
            font-size: 10px !important
            font-weight: 500!important
            line-height: 12px !important
            max-height: 30px !important
            min-height: 30px !important

            .multiselect__single
                min-height: 30px

        .fw-select__caret svg
            width: 8px !important

        .multiselect__element
            padding: 0 5px
            font-size: 10px

        .multiselect__option
            display: flex
            justify-content: flex-start
            align-items: center
            color: #213F6B
            font-weight: 500 !important
            line-height: 12px !important
            padding: 5px 5px !important
            background: #fff !important

        .multiselect__option--highlight
            color: #fff!important
            border-radius: 5px !important
            background: $aqua-marine !important

    .b-switch-time-day
        border-radius: 10px
        background-color: #F0F2F5

        .mx-datepicker--form
            background: #fff
            box-shadow: $big-box-shadow

        .mx-datepicker
            height: 30px

            input::placeholder
                line-height: normal
                text-align: left
                display: flex
                justify-content: center
                align-items: center
                font-size: 10px
                font-weight: 500

        .mx-datepicker-popup
            background: #fff
            margin-top: 35px

        .mx-input-wrapper
            input
                text-align: left
                font-size: 11px
                font-weight: 500

        .b-switch-font
            font-size: 10px

            &__label
                font-size: 10px
                opacity: 0.3
                color: rgb(33, 63, 107)

        .mx-input-append
            margin-right: 12px !important

.b-calendar-select__week--active
    .multiselect__tags
        opacity: 0.3 !important
        color: #213F6B !important

.b-working-hours .multiselect__option--selected
    color: #37e6c9 !important

.b-switch-day-time__half:nth-child(1)
    margin-top: 10px

@include media('<tablet')
    .b-working-hours
        flex-flow: row wrap
</style>
