<template>
<div>
    <div class='b-button-back' @click='backToEditPopup'>
        <FwIcon icon='arrow-left'
                size='14'
                color='#27dbbd'>
        </FwIcon>
    </div>
    <form class='b-reset-pass__form h-flex h-flex-dir-column h-mt-30'
          @keydown.stop.prevent.enter='onSubmit'>
        <FwFormInput
            class='h-mb-15'
            :fieldHasError='fieldHasError(OLD_PASSWORD_NAME)'
            labelType='label-thin'
            :title='$t(`RESET.PASSWORD.CURRENT`)'>
            <FwInput
                :value='old_password'
                type='password'
                name='password'
                autocomplete='current-password'
                :placeholder='$t(`RESET.PASSWORD.EYE`)'
                inputStyle='white-bigger-shadow'
                :error='getFieldErrorText(OLD_PASSWORD_NAME)'
                @update:value='value => old_password = value'
                @input='clearServerErrorsBase(OLD_PASSWORD_NAME)'
                @blur='addCurrentInputToValidateArray(OLD_PASSWORD_NAME)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='h-mb-15'
            :fieldHasError='fieldHasError(USER_PASSWORD)'
            labelType='label-thin'
            :title='$t(`RESET.PASSWORD.NEW`)'>
            <FwInput
                :value='user_password'
                type='password'
                name='password'
                autocomplete='current-password'
                :placeholder='$t(`RESET.PASSWORD.CHARACTERS`)'
                inputStyle='white-bigger-shadow'
                :error='getFieldErrorText(USER_PASSWORD)'
                @update:value='value => user_password = value'
                @input='clearServerErrorsBase(USER_PASSWORD)'
                @blur='addCurrentInputToValidateArray(USER_PASSWORD)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='h-mb-15'
            :fieldHasError='fieldHasError(CONFIRM_PASSWORD)'
            labelType='label-thin'
            :title='$t(`RESET.PASSWORD.NEW.CONFIRM`)'>
            <FwInput
                :value='user_confirm_password'
                type='password'
                name='password'
                autocomplete='current-password'
                :placeholder='$t(`RESET.PASSWORD.CHARACTERS`)'
                inputStyle='white-bigger-shadow'
                :error='getFieldErrorText(CONFIRM_PASSWORD)'
                @update:value='value => user_confirm_password = value'
                @input='clearServerErrorsBase(CONFIRM_PASSWORD)'
                @blur='addCurrentInputToValidateArray(CONFIRM_PASSWORD)'>
            </FwInput>
        </FwFormInput>
    </form>
    <div class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-20'
            :disabled='!isFormValid || !passwordMatch'
            borderRadiusType='tiny-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Validator } from 'simple-vue-validator';
import ValidationMixin from '@/mixins/validation';
import {
    OLD_PASSWORD_NAME,
    USER_PASSWORD,
    CONFIRM_PASSWORD,
} from '@/helpers/roles';

type ValidationTouchRecordsItemType = {
    field: string,
    value: boolean,
}

const GlobalStore = namespace('GlobalStore');

@Component({
    validators: {
        [OLD_PASSWORD_NAME](value: string) {
            return this.baseInputValidator({ value, minLength: 6 });
        },
        [USER_PASSWORD](value: string) {
            return this.baseInputValidator({ value, minLength: 6 });
        },
        // eslint-disable-next-line
        [`${USER_PASSWORD}, ${CONFIRM_PASSWORD}`]: function(password: string, passwordNew: string) {
            if (this.validation.touchedRecords.findIndex(
                (item: ValidationTouchRecordsItemType) => item.field === CONFIRM_PASSWORD) !== -1
            ) {
                return Validator.value(passwordNew).match(password);
            }
        },
        [CONFIRM_PASSWORD](value: string) {
            return this.baseInputValidator({ value, minLength: 6 });
        },
    },
})
export default class EditCompanyForm extends Mixins(ValidationMixin) {
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;
    @GlobalStore.Mutation('showEditUserPopup') showEditUserPopup!: Function;
    @GlobalStore.Mutation('showResetPasswordPopup') showResetPasswordPopup!: Function;
    avatarImage: string = 'https://www.stickees.com/files/avatars/male-avatars/1698-david-sticker.png';

    [OLD_PASSWORD_NAME]: string = '';
    [USER_PASSWORD]: string = '';
    [CONFIRM_PASSWORD]: string = '';
    OLD_PASSWORD_NAME: string = OLD_PASSWORD_NAME;
    USER_PASSWORD: string = USER_PASSWORD;
    CONFIRM_PASSWORD: string = CONFIRM_PASSWORD;

    serverErrors: serverErrors = {
        [OLD_PASSWORD_NAME]: null,
        [USER_PASSWORD]: null,
        [CONFIRM_PASSWORD]: null,
    };
    get passwordMatch() {
        return this.user_confirm_password === this.user_password;
    }
    avatarUpload(img: string) {
        this.avatarImage = img;
    }
    backToEditPopup() {
        this.showEditUserPopup(true);
        this.showResetPasswordPopup(false);
    }

    mounted() {
        if (this.formData) {
            const keys = Object.keys(this.formData);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                // @ts-ignore-next-line
                this[key] = this.formData[key];
                this.addCurrentInputToValidateArray(key);
            }
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return {
            [OLD_PASSWORD_NAME]: this[OLD_PASSWORD_NAME],
            [USER_PASSWORD]: this[USER_PASSWORD],
        };
    }
}
</script>

<style lang='sass'>
    .b-reset-pass__form
        display: flex
        flex-wrap: wrap
    .b-button-back
        cursor: pointer
        position: absolute
        top: 40px
        left: 40px
</style>
