export default {
    'SETTINGS.CALENDAR.TITLE': 'Visualisation du calendrier de planification des rendez-vous',
    'SETTINGS.CALENDAR.SLOT.TITLE': 'Ici vous pouvez gérer la vue globale de votre calendrier de planification des rendez-vous',
    'SETTINGS.CALENDAR.SLOT.EXISTING': 'Créneaux horaires existants',
    'SETTINGS.CALENDAR.FROM': 'de',
    'SETTINGS.CALENDAR.TO': 'à',
    'SETTINGS.CALENDAR.DAY.TITLE': 'Jour',
    'SETTINGS.CALENDAR.DAYS.TITLE': 'Jours',
    'SETTINGS.CALENDAR.DAYS.DEFAULT': 'Jours par défaut',
    'SETTINGS.CALENDAR.ADD.WEEKENDS': 'Ajouter des jours du weekend',
    'SETTINGS.CALENDAR.POPUP.CALENDAR.TXT': 'La vue de votre calendrier a bien été modifiée',
    'SETTINGS.CALENDAR.DAY.LIST.EMPTY': 'Au moins un jour doit être sélectionné',
};
