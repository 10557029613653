<template>
<div>
    <div class='h-text-center h-pt-30'>
        <h1>
            {{ $t('SELECT.POSTCODE.APPOINTMENT.TITLE') }}
        </h1>
    </div>
    <div class='b-society-selection h-flex-center'>
        <form class='h-mt-15'
              @keyup.enter='goNext(code)'>
            <div class='fw-select-base fw-select-white-modern b-classic-width-input'>
                <FwFormInput
                    labelType='label-medium'
                    :title='$t("SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER")'>
                    <FwInput
                        v-focus='true'
                        class='qa-select-appointment-post-code'
                        :value='code'
                        type='number'
                        name='text'
                        inputStyle='white-bigger'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        placeholder='ex: 75001'
                        @update:value='value => code = value'
                        @blur='addCurrentInputToValidateArray("code")'>
                    </FwInput>
                </FwFormInput>
            </div>
        </form>
    </div>

    <portal to='appointment-bar-buttons'>
        <FwButton
            class='qa-appointment-set-post-code'
            size='little'
            :disabled='!isFormValid'
            borderRadiusType='small-border'
            @click.native='goNext(code)'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';
import { namespace } from 'vuex-class';

import { AppointmentCategoryType } from '@/types/Appointment';
import { ClientType } from '@/types/User';

import ValidationMixin from '@/mixins/validation';
import DateMixin from '@/mixins/dateMixin';
import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';
import PermissionsMixin from '@/mixins/permissions';

import { parseAddress } from '@/helpers/address';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const CalendarStore = namespace('CalendarStore');
const AppointmentStore = namespace('AppointmentStore');

@Component({
    validators: {
        code(value: string) {
            return Validator.value(value).required().integer();
        },
    },
})
export default class AppointmentPostcodePage extends Mixins(PermissionsMixin, ValidationMixin, DateMixin, NoDataMixin, AppointmentMixin) {
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (categoryName: AppointmentCategoryType) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('client') client!: ClientType | null;
    @CalendarStore.State('post_code') post_code!: string;

    loading: boolean = false;
    code: string = '';

    serverErrors: serverErrors = {
        code: null,
    };

    async goNext(code?: string) {
        const localCode = code || this.code;
        if (!localCode) return;
        this.setPostCode(localCode);
        if (this.isOnVideoFlow) {
            this.$router.push({ path: `/appointment/calendar/by-online-video` });
        } else {
            this.$router.push({ path: '/appointment/calendar/on-phone' });
        }
    }

    created() {
        if (this.post_code) {
            this.code = this.post_code;
        }

        if (this.client && this.client.address) {
            const address = parseAddress(this.client.address);

            if (!(this.isOnPhoneFlow || this.isOnVideoFlow || this.isOnSiteFlow)) {
                this.goNext(address.zip_code);
            } else {
                this.code = '';
            }
        }

        if (this.geoScopeNational) {
            this.goNext(this.geoCountryIsoCode as string);
        }

        if (!this.appointmentCategory) {
            this.$_NoDataMixin_bookFlowNoData();
        }
    }
}
</script>

<style lang='sass'>
.b-society-selection
    .multiselect__option--highlight
        background: transparent!important

    .multiselect__option--highlight span
        color: rgb(47, 89, 151)!important

    .fw-select-base.fw-select-white-modern, .fw-select-white
        box-shadow: none !important

.b-society-selection
    & > .h-mt-15
        @include media('<=phone')
            width: 100%
</style>
