<template>
<div>
    <div id='g_id_onload'
         :data-client_id='clientId'
         data-callback='handleCredentialResponse'>
    </div>
    <div class='g_id_signin'></div>
</div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AuthWebApi } from '@/api/auth/AuthApi';
import RedirectMixin from '@/mixins/redirect';
import AuthMixin from '@/mixins/auth';

@Component
export default class GoogleSignIn extends Mixins(RedirectMixin, AuthMixin) {
    @Prop({ type: String, default: null }) readonly account!: string;

    private clientId: string = process.env.VUE_APP_DEPLOY_TYPE === 'production' ? '536993715129-d04k88a39pgpuei34vcp9jonecqe0bvr' : '536993715129-7pkj0n5blbmi64t7k8pbu4ck5b3d5nh7';

    mounted() {
        const gsiScript = document.createElement('script');
        gsiScript.src = 'https://accounts.google.com/gsi/client';
        gsiScript.async = true;
        gsiScript.defer = true;
        document.body.appendChild(gsiScript);
    }
    beforeMount() {
        (window as any).handleCredentialResponse = this.onSignIn;
    }

    async onSignIn(response: any) {
        try {
            const id_token = response.credential;
            const { data } = await AuthWebApi.googleAuth(id_token, this.account);
            this.setUser(data.user);
            this.goToPlanAppointment(data.user);
        } catch (e) {
            this.sentNotif('Error');
        }
    }
}
</script>
