import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';

export default {
    state: {
        appointment: '',
        location: {},
        calendar_settings_data: null,
        date: {},
        company: '',
        calendar_settings_account: null,
        calendar_reserved_slots: [],
        events: [],
        appointment_exists_period: {
            start: new Date(),
            end: new Date(),
        },
        appointment_reserved_slots_exists: [],
    },
    mutations: {
        PLAN_APPOINTMENT(state:any, data:any) {
            state.appointment = data;
        },
        PLAN_LOCATION(state:any, data:any) {
            state.location = data;
        },
        PLAN_DATE(state:any, data:any) {
            state.date = data;
        },
        PLAN_COMPANY(state:any, data:any) {
            state.company = data;
        },
        CALENDAR_SETTINGS_DATA(state:any, data:any) {
            state.calendar_settings_data = data;
        },
        CALENDAR_SETTINGS_ACCOUNT(state:any, data:any) {
            state.calendar_settings_account = data;
        },
        CALENDAR_RESERVED_SLOTS(state:any, data:any) {
            state.calendar_reserved_slots.push(...data);
        },
        CALENDAR_RESERVED_SLOTS_EXISTS(state:any, data:any) {
            state.appointment_reserved_slots_exists.push(data);
        },
        CALENDAR_COMMIT_EVENTS(state: any, newEvents: any) {
            state.events = newEvents;
        },
        CALENDAR_COMMIT_EVENTS_CONCAT(state: any, newEvents: any) {
            const eventForConcat = newEvents.filter(
                (item: UnavailabilityEventInstancesType) => !(state.events.find(
                    (stateItem: UnavailabilityEventInstancesType) => stateItem.id === item.id)
                )
            );
            state.events.push(...eventForConcat);
        },
        CALENDAR_APPOINTMENT_EXISTS_PERIOD(state: any, appointment_exists_period: { start: Date, end: Date }) {
            state.appointment_exists_period = {
                start: new Date(Math.min(+state.appointment_exists_period.start, +appointment_exists_period.start)),
                end: new Date(Math.max(+state.appointment_exists_period.end, +appointment_exists_period.end)),
            };
        },
        CALENDAR_APPOINTMENT_EXISTS_PERIOD_CLEAR(state: any) {
            state.appointment_exists_period = {
                start: new Date(),
                end: new Date(),
            };
        },
        CALENDAR_DATE_CLEAR(state: any) {
            state.appointment_exists_period = {
                start: new Date(),
                end: new Date(),
            };
            state.events = [];
            state.calendar_reserved_slots = [];
            state.appointment_reserved_slots_exists = [];
        },
    },
    actions: {
        storePlanAppointment({ commit, state }: any, payload: object) {
            commit('PLAN_APPOINTMENT', payload);
        },
        storePlanLocation({ commit, state }: any, payload: object) {
            commit('PLAN_LOCATION', payload);
        },
        storePlanDate({ commit, state }: any, payload: object) {
            commit('PLAN_DATE', payload);
        },
        storeCalendarSettingsData({ commit, state }: any, payload: object) {
            commit('CALENDAR_SETTINGS_DATA', payload);
        },
        storeCalendarViewSettingsAccount({ commit, state }: any, payload: object) {
            commit('CALENDAR_SETTINGS_ACCOUNT', payload);
        },
        storeCalendarReservedSlots({ commit, state }: any, payload: object) {
            commit('CALENDAR_RESERVED_SLOTS', payload);
        },
    },
};

