export default {
    'HUB.PLACEHOLDER.SEARCH': 'Search a hub',
    'HUB.ADVISORS.NUMBER': 'Number of advisors',
    'HUB.POPUP.TITLE.ADD': 'Add a hub',
    'HUB.POPUP.TITLE.EDIT': 'Edit a hub',

    'HUB.FORM.LABEL': 'Admin of hub',
    'HUB.FORM.DELETE.POPUP.TITLE': 'Delete the hub',
    'HUB.FORM.DELETE.POPUP.BUTTON': 'Delete this hub',
    'HUB.FORM.DELETE.POPUP.TEXT': 'Do you really want to delete this hub?',
    'HUB.FORM.CREATE.POPUP.TEXT': 'The hub has been added',
    'HUB.FORM.UPDATE.POPUP.TEXT': 'The hub was updated',
};
