<template>
<div class='b-appointment-info'>
    <div>
        <img :src='requireImage("logo.svg")'>
    </div>
    <WorkerAvatar
        v-if='worker'
        class='h-mt-30'
        :worker='worker'
        size='small'
        align='right'>
    </WorkerAvatar>
    <ConsultCard
        v-for='information in informationData'
        :key='information.title'
        :information='information'>
    </ConsultCard>
</div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { informationDataType } from '@/types/Consult';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { WorkerType } from '@/types/Workers';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { separateColor, hexToRgbA } from '@/helpers/colors';
import { capitalizeAll } from '@/helpers/string';

@Component({
    components: {
        ConsultCard,
        WorkerAvatar,
    },
})
export default class WorkersCalendarInfo extends Vue {
    informationData: Array<informationDataType> = [];
    worker: WorkerType | null = this.workerStoreData;

    get workerStoreData(): any {
        return this.$store.state.WorkerPageStore.workerData;
    }
    get userColor() {
        const colorArray:Array<string> = separateColor(this.worker!.color!);
        return `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
    }
    prepareData(workerData: any): Array<informationDataType> {
        const workerDataArray: Array<informationDataType> = [];
        if (workerData.company_title) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.COMPANY'),
                aside: capitalizeAll(workerData.company_title),
            });
        }
        if (workerData.id) {
            workerDataArray.push({
                title: 'ID',
                aside: workerData.id,
            });
        }
        if (workerData.email) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.EMAIL'),
                aside: workerData.email,
            });
        }
        if (workerData.phone || workerData.technician_profile.phone) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.PHONE'),
                aside: workerData.phone || workerData.technician_profile.phone,
            });
        }
        if (workerData.company_name) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.COMPANY'),
                aside: capitalizeAll(workerData.company_name),
            });
        }
        return workerDataArray;
    }
    getWorker() {
        WorkersWebApi.getTechnicianData({
            id: this.$router.currentRoute.params.id,
        }).then(response => {
            if (response.status === 200) {
                this.loading = false;
                this.$store.dispatch('storeWorkerData', response.data.user);
                this.worker = response.data.user;
                this.informationData = this.prepareData(response.data.user);
                console.log('ok');
            }
        }).catch(({ response }) => {
            if (response.status === 401) {
                console.log('some error');
            } else {
                this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
            }
        });
    }
    mounted() {
        if (this.workerStoreData) {
            this.worker = this.workerStoreData;
            this.informationData = this.prepareData(this.workerStoreData);
        } else {
            this.getWorker();
        }
    }
}
</script>
