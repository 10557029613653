<template>
<div class='b-blue-background
            b-blue-background__opacity
            b-cancel-popup__select
            b-blue-background--fixed
            b-unavailabilities__wrapper'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='600px'
        width='540px'
        :title='$t("CONSULT.INFO.AVAILABILITIES")'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        submitText='BUTTON.ADD'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :padding='isMobile() ? "30px 20px" : "40px 50px 25px"'
        :disableClosingOnClickOutside='true'
        :hideSubPopup='!showSubPopup'
        @close='$emit("close")'>
        <template #body>
            <div style='width: 360px'
                 class='h-margin-0-auto'>
                <FwDatepicker
                    v-if='!loading'
                    v-model='localDate'
                    class='h-mv-20'
                    :propsRange='false'
                    :selectMode='false'
                    pickerStyle='static'
                    :bigStyle='true'
                    :hideIcon='true'
                    :showWeeksNumbers='true'
                    :reservedSlots='availabilitySlots'
                    :hideInput='true'
                    :propsLang='`${$i18n.locale}`'
                    :disabledDate='disabledDateByObject'
                    @change-calendar-month='changeCalendarMonth'
                    @selectDate='selectDate'>
                </FwDatepicker>
            </div>
        </template>

        <template #subPopup>
            <template v-if='localDate && showSubPopup && !forceTempHidePopup'>
                <ChooseReservedSlotForm
                    v-if='showChooseReservedSlot && !currentAvailabilitySlot'
                    :date='localDate'
                    :currentSlots='currentSlots'
                    :currentInstancesData='currentInstancesData'
                    :isReservedSotsPopup='isReservedSotsPopup'
                    @closePopup='closePopup'
                    @chooseSlot='setSlot'
                    @addNewSlot='setShowAddReservedSlot'>
                </ChooseReservedSlotForm>
                <AddReservedSlotForm
                    v-else
                    :date='localDate'
                    :reservedSlot='currentSlot || currentAvailabilitySlot'
                    :instanceData='currentInstanceData'
                    :isRequestSendingStatus='isRequestSending'
                    :isAvailabilitySlots='isAvailability'
                    @closePopup='closePopup'
                    @addSlot='createEvent'
                    @removeSlot='openDeletePopup'>
                </AddReservedSlotForm>
            </template>
        </template>
    </FwPopup>

    <DeleteReservedSlotPopup
        v-if='showReservedSlotPopup'
        :instanceData='instanceData'
        type='availabilities'
        :isMultipleEvents='events.length > 1'
        :isRequestSending='isRequestSending'
        @click.native.stop
        @removeSlot='removeEvent'
        @close='closeReservedSlotPopup'>
    </DeleteReservedSlotPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AddReservedSlotForm } from '@/components/forms/add/AddReservedSlotForm';
import { DeleteReservedSlotPopup } from '@/components/popups/DeleteReservedSlotPopup';
import { ChooseReservedSlotForm } from '@/components/forms/add/ChooseReservedSlotForm';
import ReservedSlotsMixin from '@/mixins/ReservedSlotsMixin';

@Component({
    components: {
        AddReservedSlotForm,
        ChooseReservedSlotForm,
        DeleteReservedSlotPopup,
    },
})
export default class AvailabilitiesPopup extends Mixins(ReservedSlotsMixin) {
    currentDate = new Date();
    isReservedSotsPopup: boolean = false;
    isUnavailability: boolean = false;
    isAvailability: boolean = true;

    get disabledDateByObject() {
        const currentDay = new Date();
        return this.reservedSlots && {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate()) : currentDay,
            disableNextDays: new Date(currentDay.setDate(currentDay.getDate() + 1)).setMonth(currentDay.getMonth() + 3),
            excludedSlots: this.reservedSlots ? this.reservedSlots.map(item => item.date) : [],
        };
    }
}
</script>

<style lang='sass'>
.b-unavailabilities
    &__wrapper
        .mx-datepicker
            box-shadow: none !important

        .fw-popup__sub_popup
            width: 380px !important
            max-height: calc(100vh - 70px)
            transform: translate(80%, 0) !important

            @include media('<=phone')
                transform: none !important

            @media (max-height: 700px)
                .b-add-reserved-slot-form__date
                    display: none !important

                .b-add-reserved-slot-form__title
                    position: relative
                    top: -10px

                .b-add-reserved-slot-form__close_popup
                    top: 20px

            @media (max-height: 900px)
                padding: 30px 30px 50px !important
</style>
