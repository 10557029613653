<template>
<div class='b-reserved-slots-list__wrapper'>
    <div v-for='(slot, key) in RESERVED_SLOTS_MAPPING'
         :key='key'
         class='b-reserved-slots-list__item__wrp'
         @click='updateActiveId(key)'>
        <div class='b-reserved-slots-list__item'
             :class='{ "b-reserved-slots-list__item--active" : activeId === key }'>
            <div class='b-reserved-slots-list__item__inner'>
                <div class='b-reserved-slots-list__item__icon__wrapper'>
                    <div class='b-reserved-slots-list__item__icon'>
                        <FwIcon
                            class='h-flex'
                            :icon='slot.icon'
                            size='13'
                            color='#fff'>
                        </FwIcon>
                    </div>
                    <div class='b-reserved-slots-list__item__text qa-advisor-reserved-slots-icon-text'>
                        {{ $t(slot.text) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';
import { pick } from 'ramda';
import { RESERVED_SLOTS_MAPPING, ALL_ID } from '@/helpers/appointment';
import { CategorySkillNameType } from '@/types/Appointment';

@Component
export default class ReservedSlotsList extends Vue {
    @Prop({ type: String, default: null }) readonly startActiveId!: string | null;
    @Prop({ type: Array, required: true }) readonly skills!: Array<CategorySkillNameType>;
    skillIds: Array<string> = this.skills.map(skill => skill.searchable_name);
    skillIdsAll = this.skillIds.length > 1 ? [ALL_ID, ...this.skillIds] : this.skillIds;
    activeId: string = this.startActiveId || this.skillIdsAll[0];

    RESERVED_SLOTS_MAPPING = pick(this.skillIdsAll, RESERVED_SLOTS_MAPPING)

    updateActiveId(id: string) {
        if (this.activeId !== id) {
            this.activeId = id;
            this.updateHandler();
        }
    }

    @Emit('update')
    updateHandler(): string {
        return this.activeId;
    }
}
</script>

<style lang='sass'>
.b-reserved-slots-list
    &__wrapper
        display: flex
        margin: 0 -4px
        flex-wrap: wrap

    &__item
        display: flex
        flex-wrap: wrap
        border: 1px solid rgba(33, 63, 107, 0.08)
        background-color: #fff
        border-radius: 7px
        align-items: center
        justify-content: center
        text-overflow: ellipsis
        word-wrap: break-word
        padding: 4px
        width: 100%
        position: relative

        &__wrp
            padding: 2px
            width: 25%
            max-width: 25%

        &:hover
            border-color: $main-green
            cursor: pointer

        &--active
            background-color: lighten($main-green, 45)
            border-color: $main-green
            color: $main-green

            .b-reserved-slots-list__item__icon
                background-color: $main-green

        &:after
            content: ''
            padding-top: 100%
            display: block

        &__inner
            position: absolute
            width: 100%
            height: 100%
            top: 0
            left: 0
            display: flex
            align-items: center
            justify-content: center
            flex-wrap: wrap

        &__text
            font-size: 10px
            line-height: 1.1em
            max-height: 4.4em
            overflow: hidden
            text-align: center
            font-weight: 500
            min-width: 100%
            margin-top: 7px
            padding: 0 3px

        &__icon
            padding: 5px
            border-radius: 3px
            background-color: $dark-blue
            display: flex
            align-items: center
            justify-content: center

            &__wrapper
                width: 100%
                display: flex
                align-items: center
                justify-content: center
                flex-wrap: wrap
</style>
