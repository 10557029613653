<template>
<div class='b-kpi-select-category'>
    <h1 class='b-consult-branch-stat__title b-consult-branch-stat__title--small'>
        {{ $t('SELECT.TYPE.APPOINTMENT.TITLE') }}
    </h1>
    <div class='fw-select-base'>
        <FwSelect
            v-model='category_name'
            class='fw-select-with-icon--big'
            :propsPlaceholder='$t(`LABEL.APPOINTMENT.TYPE`)'
            type='white'
            :searchable='false'
            label='title'
            openDirection='bottom'
            :multiple='false'
            :clearable='false'
            :withCircles='true'
            :canNotDelete='false'
            :options='category_names'>
        </FwSelect>
    </div>
    <div class='h-text-center h-pt-15'>
        <div class='h-flex-center'>
            <FwButton
                size='little'
                borderRadiusType='small-border'
                :disabled='!category_name'
                @click.prevent='handleSearch'>
                {{ $t('BUTTON.SEARCH') }}
            </FwButton>
        </div>
        <div class='b-kpi-select-category__clear h-mt-15'
             @click='clearHandler'>
            {{ $t('BUTTON.CLEAR') }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { BranchKpiCategoryNamesType } from '@/types/Branch';

@Component
export default class KpiSelectCategory extends Vue {
    @Prop({ type: Array, required: true }) readonly category_names!: Array<BranchKpiCategoryNamesType>;

    category_name: null | BranchKpiCategoryNamesType = null;

    @Emit('search')
    handleSearch(): BranchKpiCategoryNamesType {
        return (this.category_name as BranchKpiCategoryNamesType);
    }

    @Emit('clear')
    clearHandler() {
        this.category_name = null;
    }

    @Watch('category_names')
    categoryNamesHandler() {
        this.category_name = null;
    }
}
</script>

<style lang='sass'>
.b-kpi-select-category
    .fw-select-white
        box-shadow: none !important

    &__clear
        text-transform: uppercase
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center
</style>
