import { user } from '@/helpers/auth';
import { User } from '@/types/User';
import store from '@/store';

const currentUser: User = user(store);

const RIGHT_CREATE = `create`;
const RIGHT_DESTROY = `destroy`;
const RIGHT_LIST = `list`;
const RIGHT_READ = `read`;
const RIGHT_UPDATE = `update`;

// Account route rights constant
const PARTNER = `partner`;
const INDIVIDUAL = `individual`;
const ONBOARDING = `onboarding_account_type`;
const CONSULT = currentUser.account_status;
const FORBIDDEN = `forbidden`;
const ACCOUNT_KIND = `individual`;

export {
    RIGHT_CREATE,
    RIGHT_DESTROY,
    RIGHT_LIST,
    RIGHT_READ,
    RIGHT_UPDATE,
    ONBOARDING,
    CONSULT,
    ACCOUNT_KIND,
    FORBIDDEN,
    PARTNER,
    INDIVIDUAL,
};
