<template>
<div class='b-page-container b-page-container--big'>
    <div class='h-text-center h-pt-30'>
        <h1>
            {{ $t('RECAP.TITLE') }}
        </h1>
        <p class='h-fw-500 h-font-14'>
            {{ $t('RECAP.SUBTITLE') }}
        </p>
    </div>
    <FwSpinLoader
        v-if='loading || !recapData'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else>
        <div class='b-recap-cards__wrapper'>
            <FwSimpleCard
                class='h-m-10'
                borderRadius='10px'
                :width='`430px`'
                :height='`50px`'>
                <div class='h-flex h-flex-space-between h-align-items-center h-width-100p'>
                    <div class='h-flex h-ml-10'>
                        <div style='font-size: 10px'>
                            {{ $t('RECAP.KIND.LABEL') }}
                            <span class='h-fw-500 h-font-12'>
                                {{ recapData.account.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </FwSimpleCard>
            <FwSimpleCard
                v-if='advisorsTitle'
                class='h-m-10'
                borderRadius='10px'
                :width='`430px`'
                :height='`50px`'>
                <div class='h-flex h-flex-space-between h-align-items-center h-width-100p'>
                    <div class='h-flex h-ml-10'>
                        <div style='font-size: 10px'>
                            {{ $t('RECAP.KIND.ORGANIZATION.LABEL') }}
                            <span class='h-fw-500 h-font-12'>
                                {{ $t(advisorsTitle) }}
                            </span>
                        </div>
                    </div>
                </div>
            </FwSimpleCard>
        </div>
        <div class='b-recap-cards__wrapper'>
            <router-link
                v-for='card in consultItems'
                :key='card.route'
                class='h-m-10'
                :to='card.route'>
                <FwSimpleCard
                    :width='`430px`'
                    :height='`130px`'>
                    <div class='h-flex h-flex-space-between h-align-items-center h-width-100p'>
                        <div class='h-flex h-flex-center'>
                            <div class='h-flex-center' style='min-width: 110px'>
                                <FwImage
                                    :src='requireImage(card.image)'
                                    :height='`57px`'
                                    :widht='`100px`'>
                                </FwImage>
                            </div>
                            <div class='h-flex h-flex-dir-column h-ml-10'>
                                <div class='h-font-18 h-bold'>
                                    {{ $t(card.title) }}
                                </div>
                                <div class='h-font-12 h-mt-10'>
                                    {{ $t(card.subtitle) }} {{ recapData[card.count] }}
                                </div>
                            </div>
                        </div>
                        <FwIcon
                            class='h-mh-5'
                            icon='arrow-right'
                            size='16'
                            color='#27dbbd'>
                        </FwIcon>
                    </div>
                </FwSimpleCard>
            </router-link>
        </div>
    </div>
    <portal to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                size='little'
                :disabled='sendRequest'
                borderRadiusType='small-border'
                @click='approvePublishAccount'>
                {{ $t('Confirm') }}
            </FwButton>
        </div>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RecapDataType } from '@/types/Recap';
import { AccountRecapType } from '@/types/Account';
import { ProgressBarWrapper } from '@/components/global/ProgressBarWrapper';
import { AccountApi } from '@/api/account/AccountApi';

const BreadCrumbsStore = namespace('BreadCrumbsStore');

@Component({
    components: {
        ProgressBarWrapper,
    },
})
export default class RecapChoosePage extends Vue {
    @BreadCrumbsStore.State('flowName') flowName!: string;
    value: string = '';
    advisorsTitle: string | null = null;
    loading: boolean = false;
    sendRequest: boolean = false;
    recapData: AccountRecapType | null = null;
    consultChooseData: RecapDataType =
        {
            partners: [
                {
                    image: 'partners-company.svg',
                    title: 'PLAN.APPOINTMENT.BUSINESS.PARTNER.TITLE',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'companies_count',
                    route: 'recap/partners',
                },
                {
                    image: 'workers.svg',
                    title: 'SETTINGS.ROLE.WORKERS.TITLE',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'technicians_count',
                    route: 'recap/technicians',
                },
                {
                    image: 'calendar.svg',
                    title: 'RECAP.KIND.ORGANIZATION.APPT.TYPES',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'appointment_categories_count',
                    route: 'recap/appointment/categories',
                },
            ],
            company_hubs: [
                {
                    image: 'consult-hubs.svg',
                    title: 'RECAP.KIND.ORGANIZATION.HUBS',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'hubs_count',
                    route: 'recap/hubs',
                },
                {
                    image: 'workers.svg',
                    title: 'RECAP.KIND.ORGANIZATION.ADVISORS',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'advisors_count',
                    route: 'recap/advisors',
                },
                {
                    image: 'calendar.svg',
                    title: 'RECAP.KIND.ORGANIZATION.APPT.TYPES',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'appointment_categories_count',
                    route: 'recap/appointment/categories',
                },
            ],
            company_branches: [
                {
                    image: 'consult-branches.svg',
                    title: 'RECAP.KIND.ORGANIZATION.BRANCHES',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'branches_count',
                    route: 'recap/branches',
                },
                {
                    image: 'workers.svg',
                    title: 'RECAP.KIND.ORGANIZATION.ADVISORS',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'advisors_count',
                    route: 'recap/advisors',
                },
                {
                    image: 'calendar.svg',
                    title: 'RECAP.KIND.ORGANIZATION.APPT.TYPES',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'appointment_categories_count',
                    route: 'recap/appointment/categories',
                },
            ],
            company_both: [
                {
                    image: 'consult-branches.svg',
                    title: 'RECAP.KIND.ORGANIZATION.BRANCHES',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'branches_count',
                    route: 'recap/branches',
                },
                {
                    image: 'consult-hubs.svg',
                    title: 'RECAP.KIND.ORGANIZATION.HUBS',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'hubs_count',
                    route: 'recap/hubs',
                },
                {
                    image: 'workers.svg',
                    title: 'RECAP.KIND.ORGANIZATION.ADVISORS',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'advisors_count',
                    route: 'recap/advisors',
                },
                {
                    image: 'calendar.svg',
                    title: 'RECAP.KIND.ORGANIZATION.APPT.TYPES',
                    subtitle: 'RECAP.YOU.HAVE.ADDED',
                    count: 'appointment_categories_count',
                    route: 'recap/appointment/categories',
                },
            ],
        };
    get consultItems() {
        let result;
        if (this.user().account_kind === 'partner') {
            result = this.consultChooseData.partners;
            this.advisorsTitle = null;
        } else if (this.user().account_organization_type === 'with_hubs') {
            result = this.consultChooseData.company_hubs;
            this.advisorsTitle = 'RECAP.KIND.ORGANIZATION.HUBS';
        } else if (this.user().account_organization_type === 'with_branches') {
            result = this.consultChooseData.company_branches;
            this.advisorsTitle = 'RECAP.KIND.ORGANIZATION.BRANCHES';
        } else {
            result = this.consultChooseData.company_both;
            this.advisorsTitle = 'RECAP.KIND.ORGANIZATION.HUB.BRANCH';
        }
        return result;
    }
    goToInterventionPage() {
        this.$router.push({ path: '/appointment/partners-location' });
    }
    async approvePublishAccount() {
        this.sendRequest = true;
        try {
            await AccountApi.publishAccount(this.user().account_id);
            if (this.$route.meta && this.$route.meta.next) {
                this.$router.push({ path: this.$route.meta.next });
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.sendRequest = false;
        }
    }
    async created() {
        this.loading = true;
        const { data } = await AccountApi.getRecapDate(this.user().account_id);
        this.recapData = data;
        this.loading = false;
    }
}
</script>

<style lang='sass'>
    .b-society-selection
        .multiselect__option--highlight
            background: transparent!important
        .multiselect__option--highlight span
            color: rgb(47, 89, 151)!important
    .b-recap-cards__wrapper
        margin-top: 20px
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: center
        .fw-simple-card-wrapper
            padding: 0 25px!important
</style>
