<template>
<div>
    <portal to='branch-statistic-header'>
        <StatisticsHeader
            :title='title'
            :startDate='[from, to]'
            @asRange='dates => $emit("asRange", dates)'>
        </StatisticsHeader>
    </portal>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <template v-if='showStatistic'>
            <HorizontalStatistic
                :ordering='ordering'
                :count='total'
                :data='data'>
            </HorizontalStatistic>
            <KpiSelectCategory
                v-if='showSelectCategory'
                :category_names='categoryNamesFiltered'
                @search='category => $emit("search", category)'
                @clear='$emit("clear")'>
            </KpiSelectCategory>
            <StatisticTable
                :data='statisticTableData'>
            </StatisticTable>
        </template>
        <div v-else>
            {{ $t('LABEL.HAVE.NOT.DATA.FOR.PERIOD') }}
        </div>
    </template>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { HorizontalStatistic } from '@/components/common/HorizontalStatistic';
import { StatisticTable } from '@/components/common/StatisticTable';
import { StatisticsHeader } from '@/components/common/StatisticsHeader';
import { KpiSelectCategory } from '@/components/common/KpiSelectCategory';
import { BOOKED_GROUP } from '@/routes/planAppointment/consts';
import BranchStatisticsMixin from '../BranchStatisticsMixin';

@Component({
    components: {
        HorizontalStatistic,
        StatisticTable,
        StatisticsHeader,
        KpiSelectCategory,
    },
})
export default class AppointmentBookedRecipients extends Mixins(BranchStatisticsMixin) {
    get title(): TranslateResult {
        // eslint-disable-next-line max-len
        return this.$route.meta && this.$route.meta.linkGroupName === BOOKED_GROUP ? this.$i18n.t('BRANCH.STATISTICS.APPOINTMENT.RECIPIENTS') : this.$i18n.t('BRANCH.STATISTICS.APPOINTMENT.CANCEL.RECIPIENTS');
    }
}
</script>
