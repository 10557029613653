import { CalendarMonthViewUserDataType } from '@/types/Calendar';

const APPOINTMENT_TYPE = 'APPOINTMENT_TYPE';
const RESERVED_SLOT_TYPE = 'RESERVED_SLOT_TYPE';
const APPOINTMENT_TYPE_SC = 'APPOINTMENT_TYPE_SC';
const APPOINTMENT_TYPE_SASS = 'APPOINTMENT_TYPE_SASS';
const UNAVAILABILITY_TYPE = 'UNAVAILABILITY_TYPE';
const MULTIPLY_TYPE = 'MULTIPLY_TYPE';
const EMPTY_TYPE = 'EMPTY_TYPE';
const COUNT_TYPE = 'COUNT_TYPE';

const SQUARE_TYPES_BY_NUMBER_INDEX = {
    1: MULTIPLY_TYPE, // will update it in future
    2: APPOINTMENT_TYPE,
    3: UNAVAILABILITY_TYPE,
    4: RESERVED_SLOT_TYPE,
};

const SQUARE_TYPES_FOR_GENERATE = [
    APPOINTMENT_TYPE,
    UNAVAILABILITY_TYPE,
    MULTIPLY_TYPE,
    EMPTY_TYPE,
];

const SQUARE_MAPPING = {
    COUNT_TYPE: 'b-calendar-body__cell--count',
    APPOINTMENT_TYPE: 'b-calendar-body__cell--appointment',
    RESERVED_SLOT_TYPE: 'b-calendar-body__cell--reserved',
    UNAVAILABILITY_TYPE: 'b-calendar-body__cell--unavailability',
    MULTIPLY_TYPE: 'b-calendar-body__cell--multiple',
    EMPTY_TYPE: 'b-calendar-body__cell--empty',
};

const COLOR_TYPES = {
    APPOINTMENT_TYPE_SASS: `#F56104`,
    APPOINTMENT_TYPE: `#F56104`,
    RESERVED_SLOT_TYPE: `#27DBBD`,
    APPOINTMENT_TYPE_SC: `#3FB3FB`,
    UNAVAILABILITY_TYPE: `#C9C9C9`,
    MULTIPLY_TYPE: `#E9ECF0`,
    COUNT_TYPE: `#DCF2F5`,
    EMPTY_TYPE: `#fff`,
};

const COLOR_BLIND_TYPES = {
    APPOINTMENT_TYPE_SASS: `#F66102`,
    RESERVED_SLOT_TYPE: `#DC267F`,
    APPOINTMENT_TYPE: `#FFB000`,
    APPOINTMENT_TYPE_SC: `#648FFF`,
    UNAVAILABILITY_TYPE: `#FFB000`,
    MULTIPLY_TYPE: `#E9ECF0`,
    COUNT_TYPE: `#DCF2F5`,
    EMPTY_TYPE: `#fff`,
};

function getColorType(isColorblind = false) {
    return isColorblind ? COLOR_BLIND_TYPES : COLOR_TYPES;
}

function getAsideDataMock(users: Array<CalendarMonthViewUserDataType>) {
    const head = [
        {
            id: 'free',
            name: 'Dispos', // free
        },
        {
            id: 'on_call',
            name: 'Astr.', // on-call days
        },
        {
            id: 'free_days',
            name: 'Dispos contrat', // free days contract
        },
        {
            id: 'on_call_contact',
            name: 'Astr. contrat', // on-call contract
        },
        {
            id: 'missions_free',
            name: 'Missions / dispos', // missions / free days
        },
        {
            id: 'missions_on_call',
            name: 'Missions / astr.', // missions / on-call days
        },
        {
            id: 'missions_xl',
            name: 'Missions XL', // missions XL
        },
        {
            id: 'missions_xxl',
            name: 'Missions XXL', // missions XXL
        },
    ];
    return {
        head,
        data: new Array(users.length).fill('').map((item, index) => (
            // eslint-disable-next-line no-shadow
            head.map(item => ({ id: item.id, value: Math.floor(Math.random() * 99) + 1 }))
        )),
    };
}

export {
    getColorType,
    SQUARE_MAPPING,
    COUNT_TYPE,
    getAsideDataMock,
    SQUARE_TYPES_BY_NUMBER_INDEX,
    SQUARE_TYPES_FOR_GENERATE,
    APPOINTMENT_TYPE,
    APPOINTMENT_TYPE_SC,
    APPOINTMENT_TYPE_SASS,
    RESERVED_SLOT_TYPE,
    UNAVAILABILITY_TYPE,
    MULTIPLY_TYPE,
    EMPTY_TYPE,
};
