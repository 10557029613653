import axios from 'axios';
import {
    BookAppointmentOnPhoneType,
    BookAppointmentBranchFaceToFaceType,
    BookAppointmentOnSiteType,
    BookAppointmentOnVideoRoomType,
} from '@/types/Book';
import { AppointmentOnSiteDataType, AvailabilitiesBranchFaceToFacePayloadType } from '@/types/Appointment';
import WebApi from '../WebApi';
import { BookingCalendarResponseDataType } from '@/types/Calendar';

class BookApi extends WebApi {
    async bookDirectAppointment(appointment: any): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.post(
            `${this.urlPrefix}/direct_appointments`,
            { appointment },
        );
        return data.appointment;
    }

    async bookAppointmentTelecom(appointment: BookAppointmentOnPhoneType): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.post(
            `${this.urlPrefix}/telecom_appointments`,
            { appointment },
        );
        return data.appointment;
    }

    async bookAppointmentBranchPublic(appointment: BookAppointmentBranchFaceToFaceType): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.post(
            `${this.urlPrefix}/branch_appointments`,
            { appointment },
        );
        return data.appointment;
    }

    async bookInServicePoint(appointment: BookAppointmentBranchFaceToFaceType): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.post(
            `${this.urlPrefix}/service_point_appointments`,
            { appointment },
        );
        return data.appointment;
    }

    async bookAppointmentOnSite(appointment: BookAppointmentOnSiteType, id: string): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/on_site_appointments/${id}/book`,
            { appointment },
        );
        return data.appointment;
    }

    async bookAppointmentOnSiteInBranch(appointment: BookAppointmentOnSiteType, id: string): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/on_site_in_branch_appointments/${id}/book`,
            { appointment },
        );
        return data.appointment;
    }

    async bookAppointmentBranchPrivate(appointment: BookAppointmentOnVideoRoomType): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.post(
            `${this.urlPrefix}/branch_private_appointments`,
            { appointment },
        );
        return data.appointment;
    }

    async bookAppointmentManually(payload: any): Promise<any> {
        const { data } = await axios.post(
            `${this.urlPrefix}/appointments`,
            {
                'appointment': payload,
            }
        );
        return data.appointment;
    }
}

const bookApi = new BookApi();

export {
    bookApi as BookApi,
};
