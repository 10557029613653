<template>
<div class='b-team-list'>
    <div class='b-team-list__body'>
        <div>
            {{ team.name }}
        </div>
        <FwIcon
            class='h-flex-center h-pointer'
            icon='edit-settings'
            size='20'
            color='rgba(33,63,107,.3)'
            @click.native='editTeam'>
        </FwIcon>
    </div>
    <div class='b-team-list__delete'>
        <FwIcon
            class='h-flex-center h-pointer'
            icon='bucket-edit'
            size='20'
            color='rgba(33,63,107,.3)'
            @click.native='$emit(`isConfirmDeletePopup`, team.id)'>
        </FwIcon>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { HubCreateType } from '@/types/Appointment';

const TeamsStore = namespace('TeamsStore');

@Component
export default class TeamListItem extends Vue {
    @TeamsStore.Mutation('setCurrentTeam') setCurrentTeam!: (team: HubCreateType | null) => void;
    @Prop({ type: String, required: true }) readonly team!: HubCreateType;

    get userKindBoth() {
        return this.user().account_organization_type === 'with_all';
    }

    editTeam() {
        this.setCurrentTeam(this.team);
        if (this.isRecapRoute) {
            this.$router.push({ path: `${this.mainRouteGlobal}/recap/hubs/edit/${this.team.id}` });
        } else {
            this.$router.push({ path: `${this.mainRouteGlobal}${this.userKindBoth ? `/both/hubs/edit/${this.team.id}` : `/hubs/edit/${this.team.id}`}` });
        }
    }
}
</script>

<style lang='sass'>
.b-team-list
    display: flex
    width: 100%
    margin-top: 10px

    &__body
        padding: 18px 20px 18px 20px
        background-color: #fff
        border-radius: 5px
        flex: 1
        display: flex
        justify-content: space-between

    &__delete
        padding: 18px 15px
        background-color: #fff
        border-radius: 5px
        flex: 0 0 60px
        display: flex
        align-items: center
        justify-content: center
        margin-left: 10px
</style>
