const calculateNextPage = ({ page, per_page, total_items }: { page: number, per_page: number, total_items: number }): number => {
    // if we delete last element of page need move to prev page
    if (page !== 1 && (page - 1) * per_page + 1 === total_items) {
        return page - 1;
    }
    return page;
};

export {
    calculateNextPage,
};
