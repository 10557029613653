export default {
    'CHOOSE_MANAGE.COMMON.TITLE': 'What do you want to manage?',

    'CHOOSE_MANAGE.COMMON.WORKER.PLURAL': 'technician | technicians',
    'CHOOSE_MANAGE.COMMON.ADVISOR.PLURAL': ' advisor | advisors',
    'CHOOSE_MANAGE.COMMON.NO.WORKER.PLURAL': 'technician',
    'CHOOSE_MANAGE.COMMON.NO.ADVISOR.PLURAL': ' advisor',

    'CHOOSE_MANAGE.COMMON.WORKERS.FILTER.NAME': 'Last/First name',
    'CHOOSE_MANAGE.COMMON.WORKERS.FILTER.NUMBER': 'Number of workers',
    'CHOOSE_MANAGE.COMMON.WORKERS.FILTER.ID': 'ID',

    'ADD_COMPANIES.COMMON.WORKERS.TITLE': 'Add one or more workers',
    'ADD_COMPANIES.COMMON.COMPANIES.TITLE': 'Add one or more companies',

    'ADD_COMPANIES.COMMON.MANUAL.TITLE': 'Manually',
    'ADD_COMPANIES.COMMON.MANUAL.DESCRIPTION': 'Fill in the information by yourself',

    'ADD_COMPANIES.COMMON.FILE.TITLE': 'Import a file',
    'ADD_COMPANIES.COMMON.FILE.DESCRIPTION': 'Accepted formats: CSV, XLS or XLS',
    'CONSULT.INFO.RESERVED.SLOTS': 'Reserved slots',
    'CONSULT.INFO.UNAVAILABILITIES': 'Unavailabilities',
    'CONSULT.INFO.AVAILABILITIES': 'Available days',
    'CONSULT.INFO.WORKING.HOURS': 'Working hours',
    'CONSULT.INFO.ADD.DISTANCE.TEXT': 'Add distance to the 1st and last appointment',
    'CONSULT.INFO.APPT.STATISTICS': 'Appointment statistics',
    'CONSULT.ADD.FILE.TITLE': 'Import a file',
    'CONSULT.ADD.FILE.QUESTION': 'Is my file ready to be imported?',
    'CONSULT.ADD.FILE.DRAG': 'Drag and drop',
    'CONSULT.ADD.FILE.FRAG_ASIDE_TEXT': 'or click here to import your file from your computer. ' +
        'The supported formats are: CSV, XLS ou XLXS',

    'CONSULT.ADD.FILE.TUTORIAL.TITLE': 'Is my file ready to be imported?',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.1': 'In a new spreadsheet program like <b>Excel</b> or <b>Google Sheet</b>, open a ' +
        '<b>new spreadsheet</b>.',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.2.COMPANIES': 'Create and name <b>10 columns</b> as below. Columns A, B, D, E and F must be ' +
        'filled. Paste all the informations you have on the differents compagnies starting to the line 2.',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.2.WORKERS': 'Create and name <b>7 columns</b> as below. Columns A, B and C must be filled. ' +
        'Paste all the informations you have on the differents compagnies starting to the line 2.',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.3': '<b>Save the file</b> in <b>CSV</b> or <b>Excel format</b>. You can easily' +
        ' access this option from the File menu\n' +
        'of your spreadsheet program, under the name "Export as .CSV" or "Download as .CSV."',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.4': '<b>Import your file</b> by clicking on “import” (CSV, XLS ou XLXS) or ' +
        'by a drag and drop it in the square.\n' +
        'If you try to import another type of file, we will not be able to process the file.',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.5': 'You can now <b>verify your datas</b> and “Import” them in Calizy.',

    'CONSULT.DELETE.BUTTON': 'delete the company',
    'CONSULT.DELETE.WORKER.BUTTON': 'delete the worker',
    'CONSULT.DELETE.WORKER.TITLE': 'Delete worker',
    'CONSULT.DELETE.WORKER.SUBTITLE': 'Do you really want to delete this worker?',

    'CONSULT.ADD.POPUP.ERROR.TITLE': 'Error',
    'CONSULT.ADD.POPUP.ERROR.TEXT': 'Please check our tutorial for files importation and try again.',
    'CONSULT.ADD.POPUP.ERROR.BUTTON_TEXT': 'Read importation tutorial',

    'CONSULT.ADD.FILE.RESULT.BAR.TEXT': 'Everything is okay, you can import your data.',
    'CONSULT.ADD.FILE.RESULT.BAR.BUTTON_TEXT': 'Import',
    'CONSULT.ADD.FILE.RESULT.TITLE': 'Reading your file',
    'CONSULT.ADD.FILE.RESULT.COMPANIES_FOUND_PLURAL': 'no companies founded | 1 company founded | {count} companies founded',

    'CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME': 'Company name',
    'CONSULT.ADD.FILE.RESULT.FIELDS.ADDRESS': 'Address',
    'CONSULT.ADD.FILE.RESULT.FIELDS.ADDRESS_2': 'Address 2',
    'CONSULT.ADD.FILE.RESULT.FIELDS.ZIP_CODE': 'Zip code',
    'CONSULT.ADD.FILE.RESULT.FIELDS.INSEE_CODE': 'INSEE code',
    'CONSULT.ADD.FILE.RESULT.FIELDS.COUNTRY': 'Country',
    'CONSULT.ADD.FILE.RESULT.FIELDS.SIREN': 'SIREN',
    'CONSULT.ADD.FILE.RESULT.FIELDS.SIRET': 'SIRET',
    'CONSULT.ADD.FILE.RESULT.FIELDS.PHONE_NUMBER': 'Phone number',
    'CONSULT.ADD.FILE.RESULT.FIELDS.EMAIL': 'Email',

    'CONSULT.DELETE.COMPANY.TITLE': 'Delete company',
    'CONSULT.DELETE.COMPANY.SUBTITLE': 'Do you really want to delete this company?',
    'CONSULT.DELETE.COMPANY.WORKERS': 'There are still some workers in this company. Please delete the workers before deleting the company.',

    'CACHED.POPUP.INFO.TEXT': 'If any informations have been updated to this appointment, please refresh to display the last version.',
    'CACHED.POPUP.CONTINUE.BUTTON': 'Continue',
    'CACHED.POPUP.REFRESH.BUTTON': 'Refresh',
};
