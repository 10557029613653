export default {
    'WORKER.POPUP.RESERVED.SLOTS.TITLE': 'Créneaux réservés',
    'WORKER.TIME.SLOT.TITLE': 'Horaires de travail',
    'WORKER.TIME.SLOT.MORNING': 'Matin',
    'WORKER.TIME.SLOT.DAY': 'Journée',
    'WORKER.TIME.SLOT.AFTERNOON': 'Après-midi',
    'WORKER.TIME.SLOT.LUNCH': 'Pause déjeuner',
    'WORKER.TIME.SLOT.FROM': 'de',
    'WORKER.TIME.SLOT.TO': 'à',
    'WORKER.TIME.CURRENT.TITLE': 'Actuelles',
    'WORKER.TIME.SCHEDULED.TITLE': 'Programmées',
    'WORKER.TIME.SCHEDULED.UPDATED.AT': 'Mises à jour le',
    'WORKER.TIME.POPUP.TITLE': 'Enregistrer les modifications',
    'WORKER.TIME.POPUP.TXT': 'Souhaitez-vous vraiment quitter cette page sans enregistrer ?',
    'WORKER.TIME.POPUP.BUTTON.CANCEL': 'Ne pas enregistrer',
    'WORKER.TIME.POPUP.BUTTON.SAVE': 'Enregistrer',
    'WORKER.POPUP.ADMIN.TEXT': 'Cette ressource est l’admin de sa société',
    'WORKER.POPUP.COMPANY.ADMIN': 'Cette société possède déjà un admin',
    'WORKER.POPUP.CONFIRM.BUTTON': 'Je confirme ces heures de travail',
    'WORKER.POPUP.NO.PERMISSION.TEXT': 'Vous ne pouvez plus modifier les heures de travail après confirmation',
    'WORKER.POPUP.INVITATION.OOPS': 'Oups',
    'WORKER.POPUP.INVITATION.CHECK': 'Vous devez compléter les étapes suivantes avant d’envoyer une invitation :',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TITLE': 'Confirmer les heures de travail',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TEXT': '(en fermant cette fenêtre de dialogue et en cliquant sur "Heures de travail");',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TITLE': 'Confirmer l\'adresse de l\'entreprise de la ressource',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TEXT': '(en cliquant sur ce lien).',
    'WORKER.POPUP.INVITATION.CHECK.BUTTON': 'Vérifier et confirmer les heures de travail, adresse',
    'WORKER.POPUP.INVITATION.SEND': 'ENVOYER L’INVITATION',
    'WORKER.POPUP.INVITATION.SENT': 'Invitation envoyée',
    'WORKER.POPUP.INVITATION.ACCEPTED': 'Invitation acceptée',
    'WORKER.POPUP.INVITATION.RESEND': 'RENVOYER',
    'WORKER.POPUP.INVITATION.CONFIRMED': 'Compte confirmé',
    'WORKER.POPUP.INVITATION.OUTLOOK.AUTHORIZED': 'Compte Outlook lié',
    'WORKER.POPUP.INVITATION.GOOGLE.AUTHORIZED': 'Compte Google lié',
    'WORKER.POPUP.INVITATION.OUTLOOK.GOOGLE.AUTHORIZED': 'Comptes Google et Outlook liés',
    'WORKER.POPUP.INVITATION.SYNCHRONIZED': 'Calendrier synchronisé',
    'WORKER.POPUP.INVITATION.SENT.MESSAGE': 'Invitation envoyée avec succès !',
    'WORKER.NO.WORKER': 'Pas encore de ressource',
    'WORKER.UNCONFIRMED': 'Utilisateurs non confirmés',
    'WORKER.CONFIRMED': 'Utilisateurs confirmés',
    'WORKER.EMAIL.TAKEN': 'L\'email est déjà utilisé',

    'ADVISOR.FORM.DELETE.POPUP.BUTTON': 'Supprimer ce conseiller',
    'ADVISOR.FORM.UPDATE.POPUP.TEXT': 'Modifications enregistrées',
    'ADVISOR.FORM.CREATE.POPUP.TEXT': 'Ajout enregistré',

    'ADVISOR.SAME.BRANCH.ADDRESS': 'Identique à l\'adresse de l\'agence',

    'TECH.FORM.DELETE.POPUP.BUTTON': 'Supprimer cette ressource',
    'TECH.FORM.UPDATE.POPUP.TEXT': 'Ressources a été mise à jour',
    'TECH.FORM.CREATE.POPUP.TEXT': 'Ressources a bien été ajoutée',

    'TECH.SAME.COMPANY.ADDRESS': 'Identique à l\'adresse de la société',

    'UPDATE.ADDRESS.LABEL': 'Mettre à jour l\'adresse',
    'ACTIONS.LABEL': 'Actions',
    'SELFCARE.BOOKING.LINK.TITLE': 'Lien de RDV selfcare',
    'SELFCARE.BOOKING.LINK.SUBTITLE': 'Veuillez sélectionner un type et un mode de rendez-vous',
    'SELFCARE.YOUR.BOOKING.LINK.TITLE': 'Votre lien de RDV selfcare',
    'SELFCARE.YOUR.BOOKING.LINK.COPY.TO.CLIPBOARD': 'Copié dans le presse-papier',
    'SELFCARE.BOOKING.PLACEHOLDER.TYPE': 'Sélectionnez un type de rendez-vous',
    'SELFCARE.BOOKING.PLACEHOLDER.METHOD': 'Sélectionnez un mode',

    'ADVISOR.GEO_ZONE_SHORT': 'Réassignation de zone géo',
    'ADVISOR.GEO_ZONE_APPT_NOT_REASSIGNED': 'Rendez-vous non réassignés',
    'ADVISOR.GEO_ZONE_REASSIGNMENT.PLURAL': 'Réassignation de zone géographique | Réassignation de zone géographiques',
    'ADVISOR.GEO_ZONE_REASSIGNED_ELEMENTS': 'Éléments réassignés',
    'ADVISOR.GEO_ZONE_ELEMENTS': 'élément | éléments',
    'ADVISOR.GEO_ZONE_REASSIGNED_ZONE': 'Zone réassignée',
    'ADVISOR.GEO_ZONE_RECEIVED_ZONE': 'Zone reçue',
    'ADVISOR.GEO_ZONE_FAILED_REASSIGNMENTS': 'Réassignations de RDV échouées',
    'ADVISOR.GEO_ZONE_DISTRICT': 'Département/ville',
    'ADVISOR.GEO_ZONE_SELECT_ADVISOR': 'Sélectionnez un conseiller',
    'ADVISOR.GEO_ZONE.SEARCH_ADVISOR': 'Rechercher un conseiller',
    'ADVISOR.GEO_ZONE.WHEN_REASSIGNMENT_HAPPEN': 'Quand doit avoir lieu cette réassignation ?',
    'ADVISOR.GEO_ZONE.START_DATE': 'Date début',
    'ADVISOR.GEO_ZONE.END_DATE': 'Date fin',
    'ADVISOR.GEO_ZONE.NO_REASSIGNMENT': 'Aucune réassignation',
    'ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_TITLE': 'Supprimer une réassignation | Supprimer des réassignations',
    'ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_TEXT': 'Êtes-vous certain de vouloir supprimer cette réassignation ? Cette ' +
        'action est irréversible. | Êtes-vous certain de vouloir supprimer ces réassignations ? Cette action est ' +
        'irréversible.',
    'ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_ALL': 'Supprimer toutes les réassignations trouvées',
    'ADVISOR.GEO_ZONE.REASSIGN_APPOINTMENTS': 'Réassigner les rendez-vous correspondant (la réassignation sera effective demain)',

    'ADVISOR.SELFCARE.LINKS.PLURAL': 'Liens Calizy Selfcare | Lien Calizy Selfcare',
    'ADVISOR.YOUR.SELFCARE.LINK': 'Votre lien Calizy Selfcare',
    'ADVISOR.SELFCARE.NO_LINKS': 'Aucun lien',
    'ADVISOR.SELFCARE.DELETE.LINK.TITLE': 'Supprimer un lien',
    'ADVISOR.SELFCARE.DELETE.LINK.SUBTITLE': 'Êtes-vous certain de vouloir supprimer ce lien ? Cette action est irréversible.',
};
