import axios from 'axios';
import { EventsAvailabilitiesResponseType } from '@/types/Events';
import {
    CalendarViewSettingsResponseType,
    PayloadAvailabilitiesDataType,
    CalendarForUserMonthViewResponseType,
    CalendarMonthDayDetailsResponseType,
    CalendarMonthViewResponseType,
    CalendarSyncResponseType,
    CalendarViewSettingsUsertype,
    MonthCalendarSPViewItemType,
    MonthCalendarSPAdvisorItemType, CalendarMonthDayShiftListType,
} from '@/types/Calendar';
import { BaseMetaType } from '@/types/Response';
import WebApi from '../WebApi';
import { updateUrlGetParams } from '@/helpers/url';

interface companyData {
    company_name: string,
    client_surname: string,
    client_name: string,
    contract_number: string,
    location_value: string,
    company: string,
}

class CalendarApi extends WebApi {
    async getAvailabilitiesData(
        { appointment_id, from, to, company_id, dt_week_start, dt_week_end }: PayloadAvailabilitiesDataType
    ): Promise<EventsAvailabilitiesResponseType> {
        const urlParams = `?pagination[per_page]=100`;
        const { data } = await axios.get(
            `${this.urlPrefix}/availabilities${urlParams}`,
            {
                params: {
                    appointment_id,
                    from,
                    to,
                    company_id,
                    dt_week_start,
                    dt_week_end,
                },
            }
        );
        return data;
    }

    async getMonthTimeslotDates(
        { appointment_id, from, to, company_id }: PayloadAvailabilitiesDataType
    ): Promise<Array<string>> {
        const { data } = await axios.get(
            `${this.urlPrefix}/availabilities/timeslot_dates`,
            {
                params: {
                    appointment_id,
                    from,
                    to,
                    company_id,
                },
            }
        );
        return Array.isArray(data) ? data : [];
    }

    async sendData({ company_name, client_surname,
        client_name, contract_number,
        location_value, company }: companyData) {
        return axios.post(
            `${this.urlPrefix}/some_endpoint`,
            {
                'user': {
                    company_name,
                    client_surname,
                    client_name,
                    contract_number,
                    location_value,
                    company,
                },
            },
        );
    }

    async getCalendarViewData(): Promise<CalendarViewSettingsResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/calendar_view_settings`);
        return data;
    }

    async getCalendarInformation(url: string): Promise<{ data: CalendarViewSettingsResponseType, users: Array<CalendarViewSettingsUsertype> }> {
        const { data } = await axios.get(`${this.urlPrefix}/${url}`);
        // info returned in different keys for all calendars =)
        return {
            data: data.site || data.hub || data.user || data.company,
            users: data.site ? data.site.users || [] : [],
        };
    }

    async getServicePointUsers(id: string): Promise<{ data: CalendarViewSettingsResponseType, users: Array<CalendarViewSettingsUsertype> }> {
        const { data } = await axios.get(`${this.urlPrefix}/service_point_advisors?sp_id=${id}`);
        return data;
    }

    async getRemoteCredentials(id: string): Promise<CalendarSyncResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/remote_credentials?user_id=${id}`);
        return data;
    }

    async getMonthView(
        {
            date,
            meta,
            first_name,
            last_name,
            skill_ids = [],
            sort,
            id,
            calendar_type,
        }: {
            date: string,
            meta: BaseMetaType | null,
            first_name?: true,
            last_name?: true,
            skill_ids?: Array<string>,
            id: string,
            calendar_type: `branch` | `hub`,
            sort: string,
        }
    ): Promise<CalendarMonthViewResponseType> {
        const { data } = await axios.get(
            `${this.urlPrefix}/month_views`,
            {
                params: {
                    ...(calendar_type === `hub` && { 'hub_id': id }),
                    ...(calendar_type === `branch` && { 'branch_id': id }),
                    date,
                    'pagination[page]': meta ? meta.next_page : 1,
                    ...(skill_ids.length && { skill_ids }),
                    ...(sort && { sort }),
                    ...(first_name && { first_name }),
                    ...(last_name && { last_name }),
                },
            },
        );
        return data;
    };

    async getMonthServicePointView({ dt, sp_id }: { dt: string, sp_id: string }):Promise<Array<MonthCalendarSPViewItemType>> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/service_point_month_views`, { dt, sp_id }));
        return data.month_views;
    }

    async getMonthServicePointUsersView({ sp_id }: { sp_id: string }):Promise<Array<MonthCalendarSPAdvisorItemType>> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/service_point_advisors`, { sp_id }));
        return data.service_point_advisors;
    }

    async getFakeMonthData(): Promise<CalendarMonthViewResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/dummy_datas/month_view`);
        return data;
    };

    async getMonthViewForUser(
        {
            date,
            user_id,
            id,
            calendar_type,
        }: {
            date: string,
            user_id: string
            id: string,
            calendar_type: `branch` | `hub`,
        }
    ): Promise<CalendarMonthDayDetailsResponseType> {
        const { data } = await axios.get(
            `${this.urlPrefix}/month_views/day_details`,
            {
                params: {
                    ...(calendar_type === `hub` && { 'hub_id': id }),
                    ...(calendar_type === `branch` && { 'branch_id': id }),
                    date,
                    user_id,
                },
            },
        );
        return data;
    };

    async getMonthViewShiftForDate(
        {
            dt,
            advisor_id,
            sp_id,
        }: {
            advisor_id: string
            dt: string,
            sp_id: string,
        }
    ): Promise<Array<CalendarMonthDayShiftListType>> {
        const { data } = await axios.get(
            `${this.urlPrefix}/service_point_month_views/shifts`,
            {
                params: {
                    dt,
                    sp_id,
                    advisor_id,
                },
            },
        );
        return data.shifts;
    };

    async getMonthViewRow({ date, user_id }: { date: string, user_id: string }): Promise<CalendarForUserMonthViewResponseType> {
        const { data } = await axios.get(
            `${this.urlPrefix}/month_views/replace_row`,
            {
                params: {
                    date,
                    user_id,
                },
            },
        );
        return data;
    };
}

const calendarApi = new CalendarApi();

export {
    calendarApi as CalendarApi,
};
