<template>
<div>
    <form class='b-add-user__form'
          @keydown.stop.prevent.enter='onSubmit'>
        <FwAvatarUpload
            class='h-mv-20'
            :backgroundImg='userData.avatar_url'
            @upload='avatarUpload'>
        </FwAvatarUpload>
        <div class='b-add-company__form__row'>
            <FwFormInput
                :fieldHasError='fieldHasError(USER_NAME)'
                labelType='label-medium'
                :title='`${$t("LABEL.FIRST.NAME")}*`'>
                <FwInput
                    :value='first_name'
                    :placeholder='$t(`LABEL.FIRST.NAME`)'
                    type='text'
                    inputStyle='white-bigger-shadow'
                    @update:value='value => first_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                :fieldHasError='fieldHasError(LAST_NAME)'
                labelType='label-medium'
                :title='`${$t("LABEL.LAST.NAME")}*`'>
                <FwInput
                    :value='last_name'
                    :placeholder='$t(`LABEL.LAST.NAME`)'
                    type='text'
                    inputStyle='white-bigger-shadow'
                    @update:value='value => last_name = value'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                :fieldHasError='fieldHasError(USER_PHONE)'
                labelType='label-medium'
                :title='$t(`LABEL.PHONE`)'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    placeholder='ex: 06 20 45 30 79'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => phone = value'>
                    <template #left>
                        <div class='b-phone-with-code fw-select-white fw-select-base__time'>
                            <FwSelect
                                v-model='phoneCode'
                                :disabled='true'
                                noElementsFoundText='no code'
                                :tryToUseSubstring='true'
                                :phoneNumber='userData.manager_profile.phone'
                                type='phone-with-code'
                                :canNotDelete='true'
                                @substrPhone='substrPhone'>
                            </FwSelect>
                        </div>
                    </template>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                :fieldHasError='fieldHasError(USER_EMAIL)'
                labelType='label-medium'
                :title='`${$t("LABEL.EMAIL")}**`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EMAIL`)'
                    inputStyle='white-bigger-shadow'
                    :error='getFieldErrorText(USER_EMAIL)'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase(USER_EMAIL)'
                    @blur='addCurrentInputToValidateArray(USER_EMAIL)'>
                </FwInput>
            </FwFormInput>
        </div>
        <FwFormInput
            style='flex: 0 1 50%;'
            labelType='label-medium'
            :title='$t(`LOGIN.PASSWORD.LABEL`)'>
            <div class='h-flex h-flex-center h-flex-space-between fw-input fw-input--white-bigger'>
                <p>
                    ******
                </p>
                <FwButton
                    :style='{
                        color: `#27dbbd`
                    }'
                    color='transparent'
                    fontSize='12px'
                    @click='showResetPassword'>
                    CHANGER
                </FwButton>
            </div>
        </FwFormInput>
        <div class='h-flex h-flex-dir-column h-ph-15'>
            <div class='b-form-hint__wrapper'>
                <div class='b-form-hint'>
                    * {{ $t('WORKERS.FORM.HINT.ONE_OF_THESE') }}
                </div>
                <div class='b-form-hint'>
                    ** {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
                </div>
            </div>
        </div>
    </form>
    <div class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-20'
            :disabled='!isFormValid || !inputValueCompare || !valid || isRequestSending'
            borderRadiusType='tiny-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import {
    LOGO,
    USER_EMAIL,
    USER_PHONE,
    USER_NAME,
    LAST_NAME,
} from '@/helpers/roles';
import { User } from '@/types/User';

const GlobalStore = namespace('GlobalStore');

@Component({
    validators: {
        [USER_EMAIL](value: string) {
            return this.emailValidator({ value });
        },
    },
})
export default class EditCompanyForm extends Mixins(ValidationMixin) {
    @GlobalStore.Mutation('showEditUserPopup') showEditUserPopup!: Function;
    @GlobalStore.Mutation('showResetPasswordPopup') showResetPasswordPopup!: Function;
    @Prop({ type: Object, default: null }) readonly defaultFormValues!: User;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    userData: User = this.user();
    avatarImage: string | null = this.userData.avatar_url;
    phoneCode: string = '+33';
    [LOGO]: string | null = this.avatarImage;
    [USER_EMAIL]: string = '';
    [USER_PHONE]: string = '';
    [USER_NAME]: string = '';
    [LAST_NAME]: string = '';
    LOGO: string = LOGO;
    USER_EMAIL: string = USER_EMAIL;
    USER_PHONE: string = USER_PHONE;
    USER_NAME: string = USER_NAME;
    LAST_NAME: string = LAST_NAME;

    serverErrors: serverErrors = {
        [USER_EMAIL]: null,
    };
    get valid() {
        let result = null;
        if (this.first_name || this.last_name) {
            result = true;
        }
        return result;
    }
    get phoneNum() {
        return this[USER_PHONE] ? this.phoneCode + this[USER_PHONE] : this[USER_PHONE];
    }
    get inputValueCompare() {
        return this[USER_EMAIL] !== this.defaultFormValues.email ||
            this[USER_NAME] !== this.defaultFormValues.first_name ||
            (this[LAST_NAME] !== this.defaultFormValues.last_name && this[LAST_NAME] !== ``) ||
            this[USER_PHONE] ||
            this[LAST_NAME] !== this.defaultFormValues.last_name ||
            this[LOGO] !== this.avatarImage;
    }
    avatarUpload(img: string) {
        this.avatarImage = img;
    }

    showResetPassword(e: any) {
        e.preventDefault();
        this.showEditUserPopup(false);
        this.showResetPasswordPopup(true);
    }
    substrPhone(mob: string, code: string) {
        this.phone = mob;
    }

    mounted() {
        if (this.defaultFormValues) {
            const keys = Object.keys(this.defaultFormValues);
            this.avatarImage = this.userData.avatar_url;
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                // @ts-ignore-next-line
                this[key] = this.defaultFormValues[key];
                this.addCurrentInputToValidateArray(key);
            }
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return {
            [LOGO]: this.avatarImage,
            [USER_EMAIL]: this[USER_EMAIL],
            [USER_PHONE]: this.phoneNum,
            [USER_NAME]: this[USER_NAME],
            [LAST_NAME]: this[LAST_NAME],
        };
    }
}
</script>

<style lang='sass'>
    .b-add-user__form
        display: flex
        flex-wrap: wrap
        margin: 0 -15px
        .multiselect:after
            bottom: 10px!important
        .multiselect__tags
            padding-left: 20px!important
        .multiselect__placeholder
            color: #bec7d3!important
        .fw-select-white
            box-shadow: none!important
        .b-base-form-small
            width: 210px!important
        .b-base-form-large
            width: 100%!important

        @include media('<=phone')
            flex-direction: column

        .fw-form-input__wrapper
            flex: 1 0 30%
            padding: 0 15px
            margin: 10px 0
</style>
