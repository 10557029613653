<template>
<div class='b-onboarding__wrapper b-page-container'>
    <div class='b-onboarding__title'>
        {{ $t(`ONBOARDING.CALENDAR.SYNC.${workerAdvisorString}.TITLE`) }}
    </div>
    <div class='b-onboarding__aside'>
        {{ $t(`Select ${workerAdvisor} you want to invite to synchronise their calendar`) }}
    </div>
    <div class='b-onboarding__wrapper--invite h-flex h-flex-dir-column h-mt-30'>
        <div class='h-flex h-flex-wrap h-mb-30'>
            <div style='flex: 0 0 50%;'>
                <FwInput
                    class='h-mh-10'
                    :value='searchText'
                    :placeholder='$t(`LABEL.PLACEHOLDER.SEARCH`)'
                    iconRight='loupe'
                    iconSize='15'
                    inputStyle='gray'
                    type='search'
                    @update:value='value => searchText = value'>
                </FwInput>
            </div>
            <div class='h-flex h-flex-justify-end' style='flex: 0 0 50%;'>
                <div class='b-work-company-page fw-select-base h-width-150 h-mh-10'>
                    <FwSelect
                        v-model='sortValue'
                        type='sort-simple'
                        label='name'
                        :canNotDelete='true'
                        :searchable='false'
                        :multiply='false'
                        :options='sortOptions'>
                        <template #title>
                            {{ $t('BUTTON.SORT_BY') }}
                        </template>
                        <template v-slot:option='{ props }'>
                            <slot
                                :props='props'
                                name='option'>
                                <div class='multiselect__content-item'>
                                    <div class='multiselect__item-header h-flex h-flex-space-between h-flex-center h-min-width-200'>
                                        <span class='multiselect__title-first'>
                                            {{ props.option.name }}
                                        </span>
                                    </div>
                                </div>
                            </slot>
                        </template>
                    </FwSelect>
                </div>
            </div>
        </div>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <div v-else class='b-consult-cards__wrapper b-consult-sync-card'>
            <WorkerCard
                v-for='worker in workersListArray'
                :key='worker.id'
                :workerTimeIcon='true'
                :worker='worker'
                class='h-mh-10 h-mb-20'
                :workerId='workerId'
                :workerIdsGetter='workerInvitationIdsGetter'
                @click.native='setWorkerInvitationIds(worker.id)'>
            </WorkerCard>
        </div>
    </div>
    <FwButton
        v-if='workersListArray.length !== 0'
        class='h-mt-30'
        :style='{
            padding: `0 22px`,
            height: `40px`,
            minWidth: `auto`
        }'
        :fontSize='`14px`'
        borderRadiusType='tiny-border'
        @click.native='inviteAllPopup = true'>
        Preview the invitation
    </FwButton>
    <FwButton v-else
              class='h-m-20'
              color='transparent'
              :to='`${mainRouteGlobal}/invitations/choose`'
              :fontColor='`rgba(33, 63, 107, 0.3)`'>
        {{ $t('BUTTON.LATER') }}
    </FwButton>
    <InvitationPreviewPopup
        v-if='inviteAllPopup'
        :headerText='$t(`INVITATION PREVIEW`)'
        :workerCountCheck='workerId'
        @close='inviteAllPopup = false'
        @invitationStatusSent='invitationStatusSent'>
    </InvitationPreviewPopup>
    <InProgressPopup
        v-if='showInprogressPopup'
        :title='$t(`ONBOARDING.POPUP.PROGRESS.TITLE`)'
        :subMessage='$t(`ONBOARDING.POPUP.PROGRESS.TEXT`)'>
    </InProgressPopup>
    <FinalePopup
        v-if='showFinalePopup'
        :popupWidth='`400px`'
        :popupImg='`success.svg`'
        :headerText='$t(`ONBOARDING.INVITATION.FINALE.SET.TITLE`)'
        :subMessage='$t(`ONBOARDING.INVITATION.FINALE.SET.TEXT`)'
        @goToPage='goToNextPage'>
    </FinalePopup>
    <FwPagination
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='getTechniciansList'>
    </FwPagination>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { WorkerCard } from '@/components/simple/WorkerCard';
import { InvitationPreviewPopup } from '@/components/popups/InvitationPreviewPopup';
import { InProgressPopup } from '@/components/popups/InProgressPopup';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { WorkersListDataType, pagePaginationData } from '@/types/Appointment';
import { WorkersWebApi } from '@/api/workers/WorkersApi';

const WorkerPageStore = namespace('WorkerPageStore');

const SORT_BY_NAME = 'name';
const SORT_BY_NUMBER = 'number';

@Component({
    components: {
        WorkerCard,
        InvitationPreviewPopup,
        InProgressPopup,
        FinalePopup,
    },
})
export default class OnboardingWorkersListCalendarSync extends Vue {
    @WorkerPageStore.Mutation('setWorkerInvitationIds') setWorkerInvitationIds!: Function;
    @WorkerPageStore.Getter('workerInvitationIdsGetter') workerInvitationIdsGetter!: Array<string>;
    @Prop({ type: String, default: '' }) readonly workerAdvisorString!: string;
    loading: boolean = false;
    inviteAllPopup: boolean = false;
    showInprogressPopup: boolean = false;
    showFinalePopup: boolean = false;
    searchText: string = '';
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;
    timeout: number | NodeJS.Timeout = 0;
    sortByWorkersCount: boolean = false;
    workerId: number | null = null;
    workersListArray: Array<WorkersListDataType> = [];
    sortValue: null | { name: string, type: string } = null;
    sortOptions: Array<{ name: any | TranslateResult, type: string }> = [];
    get workerAdvisor() {
        return this.mainRouteGlobal === `/partners-onboarding` ? 'technician' : 'advisor';
    }
    goToNextPage() {
        this.$router.push(`/onboarding/success`);
    }
    getTechniciansList(el: number, val: number) {
        if (this.searchText.length > 1) {
            this.getTechniciansBySearch(el, val, this.searchText, this.sortByWorkersCount);
        } else if (this.searchText.length === 0) {
            this.getTechnicians(el, val, this.sortByWorkersCount);
        }
    }
    search() {
        clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.getTechniciansBySearch(this.currentPage, this.perPage, this.searchText, this.sortByWorkersCount);
        }, 1000);
    }
    async invitationStatusSent() {
        this.showInprogressPopup = true;
        try {
            this.inviteAllPopup = false;
            await WorkersWebApi.confirmAdvisorInvitationData(false, this.workerInvitationIdsGetter);
            setTimeout(() => {
                this.showInprogressPopup = false;
                this.showFinalePopup = true;
            }, 2000);
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }
    async getTechnicians(el: number, val: number, sort: boolean) {
        this.loading = true;
        this.currentPage = el;
        const payload: pagePaginationData = {
            current_page: el,
            per_page: val,
            sort,
        };
        if (this.workerAdvisor === 'technician') {
            // @ts-ignore-next-line
            const { data } = await WorkersWebApi.getTechniciansData(payload, 'technician');
            this.totalPage = data.meta.total_count;
            // @ts-ignore-next-line
            this.workersListArray = data.users;
        } else {
            const data = await WorkersWebApi.getAdvisors(payload);
            this.totalPage = data.meta.total_count;
            // @ts-ignore-next-line
            this.workersListArray = data.advisors;
        }
        this.loading = false;
    }
    async getTechniciansBySearch(el: number, val: number, searchText: string, sort: boolean) {
        if (this.searchText.length > 1) {
            this.loading = true;
            this.currentPage = el;
            const payload: pagePaginationData = {
                current_page: el,
                per_page: val,
                sort,
            };
            const payloadTechnician: any = {
                scope: 'technician',
                current_page: el,
                per_page: val,
                sort,
            };
            if (this.workerAdvisor === 'technician') {
                const { data } = await WorkersWebApi.getTechniciansDataBySearch(payloadTechnician, searchText);
                this.workersListArray = data.users;
                this.totalPage = data.meta.total_count;
            } else {
                // @ts-ignore-next-line
                const { data } = await WorkersWebApi.getAdvisorsDataBySearch(payload, searchText);
                this.workersListArray = data.advisors;
                this.totalPage = data.meta.total_count;
            }
            this.loading = false;
        }
    }
    created() {
        this.sortOptions = [
            {
                name: 'AZ',
                type: SORT_BY_NAME,
            },
            {
                name: 'ZA',
                type: SORT_BY_NUMBER,
            },
        ];
        this.sortValue = this.sortOptions[0];
    }
    @Watch('searchText')
    countSearchInput() {
        this.currentPage = 1;
        if (this.searchText.length > 1) {
            this.search();
        } else if (this.searchText.length === 0) {
            this.getTechnicians(this.currentPage, this.perPage, this.sortByWorkersCount);
        }
    }
    @Watch('sortValue')
    sortBy() {
        if (this.sortValue) {
            let key: string = `first_name`; // default
            if (this.sortValue.type === SORT_BY_NAME) {
                key = `first_name`;
                this.sortByWorkersCount = false;
                this.getTechniciansList(this.currentPage, this.perPage);
            } else if (this.sortValue.type === SORT_BY_NUMBER) {
                key = `number`;
                this.sortByWorkersCount = true;
                this.getTechniciansList(this.currentPage, this.perPage);
            }
        }
    }
}
</script>

<style lang='sass'>
.b-onboarding
    &__cards
        display: flex
        flex-flow: row wrap
    &__wrapper
        padding: 110px 0
        display: flex
        flex-direction: column
        align-items: center
        &--invite
            .b-worker-card__wrapper
                max-width: 290px

    &__title
        margin-top: 20px
        font-size: 30px
        line-height: 37px
        letter-spacing: 0.37px
        font-weight: 600

    &__aside
        margin-top: 9px
        font-size: 16px

    &__ol
        max-width: 600px
        margin-top: 10px
        font-size: 22px
        line-height: 30px
        font-weight: 600
        counter-reset: list

        li
            margin-top: 20px
            list-style-type: none

            &:before
                list-style-type: none
                counter-increment: list
                content: counter(list) ' - '
.b-consult-sync-card
    .b-worker-card
        width: 290px
</style>
