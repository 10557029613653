import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import {
    setUserToStorage,
    setAccountToStorage,
    removeUserFromStorage,
    setXHeadersLocale,
    getStorageXHeaders,
    removeHeadersFromStorage,
} from '@/helpers/auth';
import { AccountType } from '@/types/Account';
import { User, UserHeaders } from '@/types/User';

@Module({
    namespaced: true,
})
class GlobalStore extends VuexModule {
    userData: User | object = {};
    account: AccountType | object = {};
    editPopupState: boolean = false;
    resetPasswordPopupState: boolean = false;
    previousScreenName: null | string = null;

    @Mutation
    mutationLoginUser(userData: User) {
        setUserToStorage(userData);
        if (userData.authentication_token) {
            setXHeadersLocale(userData);
        }
        const headersData: UserHeaders | null = getStorageXHeaders();
        if (headersData) {
            axios.defaults.headers.common = {
                'X-User-Token': headersData.auth_token,
                'X-User-Email': headersData.auth_email,
            };
        }
        this.userData = userData;
    }

    @Mutation
    mutationUnLoginUser() {
        removeUserFromStorage();
        removeHeadersFromStorage();
        delete axios.defaults.headers.common['X-Provider-Toke'];
        delete axios.defaults.headers.common['X-Provider-Name'];
        this.userData = {};
        this.account = {};
    }

    @Mutation
    setAccount(account: AccountType) {
        setAccountToStorage(account);
        this.account = account;
    }

    @Mutation
    showResetPasswordPopup(value: boolean) {
        this.resetPasswordPopupState = value;
    }

    @Mutation
    SET_PREVIOUS_SCREEN_NAME(name: string) {
        this.previousScreenName = name;
    }

    @Mutation
    showEditUserPopup(value: boolean) {
        this.editPopupState = value;
    }

    @Action({ commit: 'setUserData' })
    actionUserData(userData: User) {
        return userData;
    }

    @Action({ commit: 'setAccount' })
    actionUpdateAccountData(account: AccountType) {
        return account;
    }

    @Action({ commit: 'mutationLoginUser' })
    actionUpdateUserData(itemsForUpdate: object) {
        return { ...this.userData, ...itemsForUpdate };
    }

    get user() {
        return this.userData;
    }

    get isEditPopup(): boolean {
        return this.editPopupState;
    }

    get isResetPasswordPopup(): boolean {
        return this.resetPasswordPopupState;
    }
}

export default GlobalStore;
