<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-popup-with-sticky-close'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#fff", "#fff", "#fff"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <FinalePopup
        v-if='finalePopupShow'
        qaClassName='qa-service-point-close-finale'
        :subMessage='servicePoint ? $t("SERVICES_POINT.FORM.UPDATE.POPUP.TEXT") : $t("SERVICES_POINT.FORM.CREATE.POPUP.TEXT")'
        @close='closeFinalePopup'>
    </FinalePopup>
    <FwPopup
        v-else-if='!loading && !isConfirmPopupShow'
        :centered='!isMobile()'
        :isFixed='isDesktop()'
        class='h-pos-rel'
        style='height: 90%;overflow-y: auto; overflow-x: hidden; justify-content: flex-start'
        iconColor='#BEC7D4'
        max-width='1000px'
        :width='isMobile() ? "calc(100% - 20px)" : "1000px"'
        :title='servicePoint ? $t(`SERVICES_POINT.TITLE.EDIT`) : $t(`SERVICES_POINT.TITLE.ADD`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :padding='isMobile() ? "30px 20px 60px" : "50px 50px 0"'
        @close='closePopup'>
        <template #body>
            <AddOrEditServicePointForm
                class='h-mt-30'
                :isRequestSending='isRequestSending'
                :servicePoint='servicePoint'
                :errorFromServer='errorFromServer'
                @onSubmit='sendDataToServer'
                @delete='showConfirm'>
            </AddOrEditServicePointForm>
        </template>
    </FwPopup>
    <DeletePopup
        v-if='isConfirmPopupShow'
        :headerText='$t(`SERVICES_POINT.FORM.DELETE.POPUP.TITLE`)'
        :subMessage='$t(`SERVICES_POINT.FORM.DELETE.POPUP.TEXT`)'
        :isRequestSending='isRequestSending'
        confirmQaClass='qa-service-point-page-delete-confirm-button'
        @deleteItem='deleteServicePoint'
        @close='isConfirmPopupShow = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import { omit } from 'ramda';
import { AddOrEditServicePointForm } from '@/components/forms/add/AddOrEditServicePointForm';
import { SitesApi } from '@/api/sites/SitesApi';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { BranchSitesDataType } from '@/types/Site';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { getAddressAsString } from '@/helpers/address';
import { ServicePointItemType, ServicePointFormDataPayloadType } from '@/types/ServicePoint';

@Component({
    components: {
        AddOrEditServicePointForm,
        FinalePopup,
        DeletePopup,
    },
})
export default class AddOrEditServicePoint extends Vue {
    @Prop({ type: Object, required: true }) readonly servicePoint!: ServicePointItemType;

    errorFromServer: string | null | TranslateResult = null;

    isRequestSending: boolean = false;
    finalePopupShow: boolean = false;
    loading: boolean = false;
    isConfirmPopupShow: boolean = false;

    get isEdit(): boolean {
        return !!this.servicePoint;
    }

    get currentId(): string | null {
        return this.$route.params.id || null;
    }

    async sendDataToServer(data: ServicePointFormDataPayloadType): Promise<void> {
        this.isRequestSending = true;
        try {
            if (this.isEdit && this.currentId) {
                const servicePoint = await SitesApi.updateServicePoint(data, this.currentId);
            } else {
                const servicePoint = await SitesApi.createServicePoint(data);
            }
            this.finalePopupShow = true;
        } catch ({ response }) {
            this.sentNotif(response);
            this.isRequestSending = false;
        }
    }

    closeFinalePopup(): void {
        this.updateStateHandler();
        this.closePopup();
    }

    showConfirm(): void {
        this.isConfirmPopupShow = true;
    }

    async deleteServicePoint(): Promise<void> {
        if (!this.currentId) return;
        this.isRequestSending = true;
        try {
            await SitesApi.deleteServicePoint(this.currentId);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.removeHandler();
        } finally {
            this.isRequestSending = false;
        }
    }

    @Emit('closePopup')
    closePopup(): void {}

    @Emit('updateState')
    updateStateHandler(): void {}

    @Emit('remove')
    removeHandler(): void {}
}
</script>
