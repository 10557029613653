<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-location-popup'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='500px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div v-if='companyData && companyData.longitude && companyData.latitude' class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mv-0 h-text-center'>
                    {{ $t('COMPANY.POPUP.LOCATION.TITLE') }}
                </h2>
                <div ref='map' class='h-mt-40' style='width: 500px; height: 280px;'></div>
                <div class='h-flex h-flex-center'>
                    <FwIcon
                        class='h-flex-center h-pointer'
                        icon='location-icon'
                        size='28'
                        color='rgba(33,63,107)'>
                    </FwIcon>
                    <p class='h-font-18 h-fw-500 h-ml-20 h-mv-30'>
                        {{ companyData.address }}
                    </p>
                </div>
            </div>
            <div v-else>
                <h2 class='h-font-22 h-mt-20 h-mb-0 h-text-center'>
                    {{ $t('COMPANY.POPUP.LOCATION.FAILED.TITLE') }}
                </h2>
                <p class='h-font-18 h-ml-20 h-mv-30 h-text-center'>
                    {{ $t('COMPANY.POPUP.LOCATION.FAILED.TEXT') }}
                </p>
            </div>
        </template>
        <template #footer>
            <div v-if='companyData && companyData.longitude && companyData.latitude' class='h-flex h-flex-center h-flex-dir-column'>
                <FwButton
                    class='h-width-300'
                    borderRadiusType='small-border'
                    :disabled='isRequestSending'
                    @click='$emit(`confirmAddress`, companyData)'>
                    {{ $t('COMPANY.POPUP.LOCATION.BUTTON.CONFIRM') }}
                </FwButton>
                <FwButton
                    class='h-mt-20 h-font-18 h-width-300'
                    color='cancel'
                    borderRadiusType='small-border'
                    :disabled='isRequestSending'
                    @click='$emit(`closeLocation`)'>
                    {{ $t('COMPANY.POPUP.LOCATION.BUTTON.CANCEL') }}
                </FwButton>
            </div>
            <FwButton
                v-else
                borderRadiusType='small-border'
                @click='$emit(`closeLocation`)'>
                {{ $t('COMPANY.POPUP.LOCATION.FAILED.BUTTON') }}
            </FwButton>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GoogleApi from '@/mixins/google-api';
import { CompanyData, CompanyUpdateAddressType } from '@/types/Companies';

const WorkerPageStore = namespace('WorkerPageStore');

@Component
export default class LocationPopup extends Mixins(GoogleApi) {
    @WorkerPageStore.State('companyStateData') companyStateData!: CompanyData;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Object, default: null }) readonly companyAddressData!: CompanyUpdateAddressType;

    placeGetParam: string = `sensor=false&language=en&libraries=places`;

    get companyData() {
        return this.companyAddressData ? this.companyAddressData : this.companyStateData;
    }
    @Emit('goToPage')
    goToPage() {
        return true;
    }
    initMap() {
        if (this.companyData.longitude && this.companyData.latitude) {
            const mapOptions = {
                zoom: 16,
                center: { lat: this.companyData.latitude, lng: this.companyData.longitude },
                disableDefaultUI: true,
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            };
            const mapView = new window.google.maps.Map(this.$refs.map, mapOptions);

            const marker = new window.google.maps.Marker({
                position: { lat: this.companyData.latitude, lng: this.companyData.longitude },
                map: mapView,
            });

            window.google.maps.event.addListener(window, 'load', this.initMap);
        }
    }
    loadScript(url: string) {
        return new Promise((resolve, reject) => {
            const existing = document.querySelector('script#someUniqueId');
            if (existing) existing.remove();
            const script: any = document.createElement('script');
            script.onload = () => {
                // @ts-ignore
                resolve();
            };
            script.onerror = (e: string) => {
                reject(e);
            };
            script.id = 'someUniqueId';
            script.async = true;
            script.src = url;
            document.head.appendChild(script);
        });
    }
    async created() {
        try {
            await this.loadScript(`https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&${this.placeGetParam}`);
        } catch ({ response }) {
            this.sentNotif(response);
        }
        this.initMap();
    }
}
</script>
<style lang='sass'>
    .b-location-popup
        .fw-button--cancel
            font-size: 16px
        .gmnoprint a, .gmnoprint span, .gm-style-cc
            display: none
</style>
