<template>
<div class='b-add-appointment__wrapper container'>
    <div class='b-base-title__wrapper'>
        <h2 class='b-appointment-left-bar__title'>
            {{ $t('PLAN.APPOINTMENT.WORKERS.TITLE') }}:
        </h2>
    </div>
    <div class='h-flex h-flex-center h-flex-dir-column'>
        <div class='h-width-220'>
            <FwButton v-for='(item, index) in attachedCategories'
                      :key='index'
                      size='large-xl'
                      color='choose'
                      borderRadiusType='tiny-border'
                      class='h-mt-20'
                      @click.native='item.method'>
                {{ $t(item.text) }}
                <FwIcon icon='arrow-right'
                        size='14'
                        color='#27dbbd'>
                </FwIcon>
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { AccountType } from '@/types/Account';
import {
    ChooseOrganizationType,
} from '@/types/Appointment';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const GlobalStore = namespace('GlobalStore');

type AttachedCategoryType = {
    text: string,
    method: Function
}

@Component
export default class AppointmentWorkersAttached extends Vue {
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @GlobalStore.Action('actionUpdateAccountData') actionUpdateAccountData!: (account: AccountType) => void;
    @GlobalStore.Action('actionUpdateUserData') actionUpdateUserData!: (itemsForUpdate: object) => void;
    attachedCategories: Array<AttachedCategoryType> = [
        {
            text: 'PLAN.APPOINTMENT.WORKERS.HUB',
            method: this.setHub,
        },
        {
            text: 'PLAN.APPOINTMENT.WORKERS.BRANCH',
            method: this.setBranch,
        },
        {
            text: 'PLAN.APPOINTMENT.WORKERS.BOTH',
            method: this.setBoth,
        },
    ];

    async setOrganizationType(type: ChooseOrganizationType) {
        const account: AccountType = await AppointmentWebApi.chooseOrganizationType(type, this.user().account_id);
        this.actionUpdateAccountData(account);
        const itemsForUpdate = { account_organization_type: account.organization_type };
        this.actionUpdateUserData(itemsForUpdate);
    }

    setHub() {
        this.$router.push(`${this.mainRouteGlobal}/hubs/add`);
        this.setFlowKey('hubs');
        this.setOrganizationType('with_hubs');
    }

    setBranch() {
        this.$router.push(`${this.mainRouteGlobal}/branches/add`);
        this.setFlowKey('branches');
        this.setOrganizationType('with_branches');
    }

    setBoth() {
        this.$router.push(`${this.mainRouteGlobal}/both/hubs/add`);
        this.setFlowKey('hubs-and-branches');
        this.setOrganizationType('with_all');
    }

    created() {
        this.setFlowKey('base');
    }
}
</script>
<style lang='sass'>
.b-add-appointment
    &__wrapper
        @include media('<=phone')
            width: 100%
        .b-time-picker
            background: #ffffff
            height: 60px
</style>
