<template>
<div class='b-dynamic-fields-e2e'>
    <h1 class='h-font-22'>
        FormAttributes testing
    </h1>
    <FormAttributes
        class='b-booking-form__outer'
        clearable
        :disabled='false'
        :schemas='prepareAttributeSchema'
        @change='updateSchema'>
    </FormAttributes>

    <div class='h-flex-center h-width-100p'>
        <FwButton
            :disabled='isErrorInForm'
            class='h-mt-20 qa-dynamic-fields-button'
            borderRadiusType='small-border'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { clone } from 'ramda';
import { Component, Mixins } from 'vue-property-decorator';
import { FormAttributes } from '@/components/forms/attributes';
import DynamicFormMixin from '@/mixins/dynamicFormMixin';
import {
    DYNAMIC_FIELDS_SCHEME,
    DYNAMIC_FIELDS_SCHEME_2,
    DYNAMIC_FIELDS_SCHEME_3,
    DYNAMIC_FIELDS_SCHEME_4,
} from '@/mocks/e2e';
import ValidationMixin from '@/mixins/validation';
import { AttributeExtendedType } from '@/types/Attribute';

@Component({
    components: {
        FormAttributes,
    },
})
export default class DynamicFieldsE2e extends Mixins(DynamicFormMixin, ValidationMixin) {
    serverErrors: serverErrors = {};

    get schemesMapping(): { [key: string]: Array<AttributeExtendedType> } {
        return {
            '1': DYNAMIC_FIELDS_SCHEME,
            '2': DYNAMIC_FIELDS_SCHEME_2,
            '3': DYNAMIC_FIELDS_SCHEME_3,
            '4': DYNAMIC_FIELDS_SCHEME_4,
        };
    }

    created() {
        const { id } = this.$route.params;
        // @ts-ignore-next-line
        this.schema = clone(this.schemesMapping[id]);
        this.serverErrors = this.schema.reduce((o, item) => ({ ...o, [item.searchable_name]: null }), {});
    }
}
</script>

<style lang='sass'>
.b-dynamic-fields-e2e
    max-width: 900px
    margin: 0 auto
    padding-top: 40px
</style>

