<template>
<div class='b-add-shift-slot-form'>
    <div v-if='recurrenceLoading'
         class='b-add-shift-slot-form__loading'>
        <FwSpinLoader
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader h-width-100p h-height-100p'
            :isActive='recurrenceLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </div>
    <div class='b-add-shift-slot-form__title'>
        {{ formTitle }}
    </div>
    <div v-if='date'
         class='b-add-shift-slot-form__date qa-advisor-reserved-slots-form__date'>
        {{ dateTitle }}
    </div>
    <div>
        <FwLabelCard
            v-for='instance in currentInstancesData'
            :key='instance.id'
            class='h-mb-10 h-pointer qa-advisor-reserved-slots__date-label'
            height='40px'
            padding='0 15px'
            @click.native.stop='chooseSlot(instance)'>
            <div class='b-add-shift-slot-form__label h-flex-space-between'>
                <div v-if='!reedOnly'
                     class='b-add-shift-slot-form__label__row'>
                    <AdvisorAvatar
                        class='h-mr-7'
                        :advisor='instance'
                        avatarSize='28'
                        hideInfo
                        size='small'
                        align='base'>
                    </AdvisorAvatar>
                    <div class='b-add-shift-slot-form__label__text
                            b-add-shift-slot-form__label__inner
                            h-mr-10'>
                        {{ instance.first_name || '' | capitalize }}
                        <br v-if='instance.first_name && instance.last_name'>
                        {{ instance.last_name || '' | capitalize }}
                    </div>
                </div>
                <div class='b-add-shift-slot-form__label__text
                            b-add-shift-slot-form__label__inner
                            h-mr-10'>
                    {{ timeIntervalText(instance) }}
                </div>
            </div>
        </FwLabelCard>
    </div>

    <div>
        <FwButton
            v-if='showAddButton'
            size='little'
            class='h-width-100p h-mt-20'
            borderRadiusType='tiny-border'
            @click.stop.prevent='addNewSlotHandler'>
            {{ $t('BUTTON.ADD.NEW') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { ReservedSlotsType } from '@/types/ReservedSlots';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';
import DateMixin from '@/mixins/dateMixin';
import { formatDateForTitle } from '@/helpers/dates';
import { AdvisorAvatar } from '@/components/nodes/AdvisorAvatar';

@Component({
    components: {
        AdvisorAvatar,
    },
})
export default class ChooseShiftsSlotForm extends Mixins(DateMixin) {
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Object, default: null }) readonly instanceData!: unknown;
    @Prop({
        validator: prop => Array.isArray(prop) || prop === null,
        required: true,
    }) readonly currentSlots!: Array<unknown> | null;
    @Prop({ type: Boolean, default: false }) readonly isReservedSots!: boolean;
    @Prop({ type: Boolean, default: false }) readonly recurrenceLoading!: boolean;
    @Prop({ type: Boolean, default: false }) readonly reedOnly!: boolean;
    @Prop({ type: Array, required: true }) readonly currentInstancesData!: Array<any>;

    get formTitle(): TranslateResult {
        return this.$i18n.t('SERVICES_POINT.SHIFTS.DELETE.CHOOSE.TITLE');
    }

    get dateTitle(): string {
        return formatDateForTitle(this.date);
    }

    get showAddButton(): boolean {
        if (this.reedOnly) {
            return false;
        }
        if (!this.isReservedSots && this.currentInstancesData.find(item => item.full_day)) {
            return false;
        }
        return +this.date > +this.getDayDateAtNight(new Date());
    }

    timeIntervalText(instance: UnavailabilityEventInstancesType): string | TranslateResult {
        if (instance.full_day) {
            const startDate = new Date(instance.dt_start);
            const endDate = new Date(instance.dt_end);
            if (startDate.getHours() === 0 && startDate.getMinutes() === 0 && endDate.getHours() === 23 && endDate.getMinutes() === 59) {
                return this.$i18n.t('CALENDAR.FULL.DAY');
            }
        }
        return `${this.getFrom(instance)} - ${this.getTo(instance)}`;
    }

    getFrom(instance: UnavailabilityEventInstancesType): string {
        return this.getCurrentDateAsMinutesAMPM(new Date(instance.dt_start), false);
    }

    getTo(instance: UnavailabilityEventInstancesType): string {
        return this.getCurrentDateAsMinutesAMPM(new Date(instance.dt_end), false);
    }

    @Emit('chooseSlot')
    chooseSlot(instance: UnavailabilityEventInstancesType) {
        return instance;
    }

    @Emit('addNewSlot')
    addNewSlotHandler() {}
}
</script>

<style lang='sass' scoped>
.b-add-shift-slot-form
    text-align: center

    .fw-label-card-wrapper
        transition: background 0.2s

        &:hover
            background-color: #e3e4e7

    &__label__inner
        display: flex
        align-items: center
        justify-content: space-between

    &__icon
        padding: 4px
        border-radius: 3px
        background-color: $dark-blue
        display: flex
        align-items: center
        justify-content: center

    .mx-datepicker
        padding: 0 10px

    .multiselect__content-wrapper
        max-height: 200px!important

    &__loading
        width: 100%
        height: 100%
        position: absolute
        left: 0
        top: 0
        display: flex
        align-items: center
        justify-content: center
        z-index: 5

    .fw-label-card-wrapper
        background-color: #F0F2F5

    .multiselect__single, .multiselect__option
        font-weight: 500 !important

    .multiselect__single, .multiselect__option
        font-size: 14px !important

    .fw-select-base .multiselect:after
        display: none !important

    &__title
        font-size: 14px
        line-height: 17px
        color: $main-gray
        text-transform: uppercase
        font-weight: bold

    &__date
        font-size: 25px
        font-weight: bold
        color: $dark-blue
        margin: 15px 0 20px

    &__label
        display: flex
        align-items: center
        justify-content: space-between
        height: 100%

        &__row
            display: flex

        .multiselect__option
            text-transform: uppercase!important

        &--disabled
            position: relative
            opacity: 0.5
            &:after
                content: ''
                background-color: rgba(0,0,0,0.1)
                border-radius: 10px
                width: 100%
                height: 100%
                position: absolute
                z-index: 1
                left: 0
                top: 0

    &__label__text
        font-size: 14px
        color: $dark-blue
        font-weight: 500 !important
        text-align: left

    &__button
        z-index: 4
        position: relative

        &__inner
            margin-top: 20px
            margin-left: 10px
            margin-right: 10px
            flex: 1 1 50%

    &__to
        width: 40px
        margin: 0 10px
        color: $main-gray

    &__wrapper
        position: relative

        &--disable
            &:after
                position: absolute
                content: ''
                left: 0
                width: 100%
                top: 0
                height: 100%
                z-index: 10

            .multiselect__single,
            .b-add-reserved-slot-form__label__text--active,
            .mx-datepicker--form .mx-input,
            .b-add-reserved-slot-form__datepicker.mx-input
                color: #787e85 !important
            .fw-switcher__slider, .fw-check-box__wrapper.fw-is-selected .fw-check-box__container
                background-color: #787e85 !important
            .fw-check-box__container
                color: #fff

    .fw-check-box__container.form-light
        height: 37px
        padding: 5px
        flex: 0 0 37px
        font-weight: 500

    &__datepicker
        font-weight: 500 !important

        &::placeholder
            font-weight: 500 !important
            overflow: auto !important
            width: 100% !important

    &__close_popup
        position: absolute
        right: 40px
        top: 40px
        cursor: pointer
        transition: opacity .3s
        will-change: opacity

        &:hover
            opacity: 0.7

    .multiselect__placeholder
        font-size: 14px !important
        font-weight: 500 !important
        color: #BEC7D3 !important
</style>
