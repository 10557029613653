<template>
<InternalAppointmentCommentForm
    v-if='isAddEditComment'
    :comment='currentComment'
    @backToCommentsList='isAddEditComment=false'
    @submitComment='submitComment'
    @deleteComment='deleteComment'>
</InternalAppointmentCommentForm>
<div v-else class='b-form-tab__comments h-mb-50' @click.stop.prevent>
    <div class='h-flex h-flex-space-between h-width-100p'>
        <h3 class='h-font-16 h-fw-600 h-mt-15 h-mb-10'>
            {{ $t('CONSULT.APPOINTMENT.COMMENTS') }}
        </h3>
        <FwButton
            class='h-ph-20'
            size='small'
            borderRadiusType='small-border'
            fontSize='14px'
            @click.native.stop='addNewComment'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center h-height-100p loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div
            v-for='(comment, index) in comments'
            :key='index'
            ref='commentItem'
            :style='commentStyles(index)'
            class='b-form-tab__comments-item'
            @click.stop='getComment(comment)'>
            {{ comment.content }}
            <div
                v-if='comment && comment.content && showLessMoreButton(comment, index)'
                class='b-form-tab-less-more__button'
                @click.prevent.stop='toggleCommentState(index)'>
                {{ !commentStates[index] ? 'more' : 'less' }}
            </div>
        </div>
        <div
            v-if='!comments.length'
            class='h-text-center h-mt-50'>
            {{ $t('CONSULT.APPOINTMENT.NO.COMMENTS') }}
        </div>
        <FwPagination
            v-if='totalPage > perPage'
            style='position: absolute'
            class='h-mb-20'
            :totalPage='totalPage'
            :currentPage='currentPage'
            :perPage='perPage'
            :value='perPage'
            @getData='getComments'>
        </FwPagination>
    </template>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
    NOTES,
} from '@/helpers/events';
import { AppointmentSearchType } from '@/types/Appointment';
import { CommentPaginationData, CommentData, CommentPayloadData } from '@/types/Comment';

import ValidationMixin from '@/mixins/validation';
import AccountMixin from '@/mixins/account';
import DynamicFormMixin from '@/mixins/dynamicFormMixin';

import { FormAttributes } from '@/components/forms/attributes';
import { InternalAppointmentCommentForm } from '@/components/forms/readable/internal/default/InternalAppointmentCommentForm';

import { CommentsWebApi } from '@/api/comments/CommentsApi';

const CommonStore = namespace('CommonStore');

@Component({
    components: {
        FormAttributes,
        InternalAppointmentCommentForm,
    },
    validators: {
        [NOTES](value: string) {
            return this.requiredValidator({ value });
        },
    },
})

export default class InternalAppointmentCommentsForm extends Mixins(DynamicFormMixin, ValidationMixin, AccountMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly eventTime!: string;

    disabledForm: boolean = true;
    isAddEditComment: boolean = false;
    loading: boolean = false;
    NOTES: string = '';
    notes: string = '';
    serverErrors: serverErrors = {
        notes: null,
    };
    comments: Array<CommentData> = [];
    commentStates: Array<Boolean> = [];
    currentComment: CommentData | null = null;
    schemaClass: string = 'h-flex-1-0 h-mh-25';

    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 5;

    commentStyles(index: number) {
        if (!this.commentStates[index]) {
            return {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            };
        }

        return { wordWrap: 'break-word' };
    }

    toggleCommentState(index: number) {
        if (!this.commentStates[index]) {
            this.$set(this.commentStates, index, true);
        } else if (this.commentStates[index]) {
            this.$set(this.commentStates, index, false);
        }
    }

    showLessMoreButton(comment: CommentData) {
        return comment.content.length * 8 > 1080;
    }

    async getComments(el: number, val: number) {
        try {
            this.loading = true;
            this.currentPage = el;
            const payload: CommentPaginationData = {
                appointment_id: this.appointmentData.id as string,
                current_page: el,
                per_page: val,
            };
            const data = await CommentsWebApi.getComments(payload);
            this.comments = data.comments;
            this.commentStates = Array(this.comments.length).fill(false);
            this.totalPage = data.meta.total_count;
            if (this.currentPage === 1 && this.comments && !this.comments.length) {
                this.isAddEditComment = true;
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    addNewComment() {
        this.currentComment = null;
        this.isAddEditComment = true;
    }

    getComment(comment: CommentData) {
        this.currentComment = comment;
        this.isAddEditComment = true;
    }

    async submitComment(value: string) {
        try {
            if (this.currentComment) {
                const comment = {
                    content: value,
                };

                const data = await CommentsWebApi.updateCommentData(comment as CommentPayloadData, this.currentComment.id);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);

                const index = this.comments.findIndex(item => item.id === data.id);

                if (index !== -1) {
                    this.comments[index] = {
                        ...this.comments[index],
                        ...data,
                    };
                }
                this.isAddEditComment = false;
            } else {
                const comment = {
                    content: value,
                    appointment_id: this.appointmentData.id,
                };

                const data = await CommentsWebApi.sendCommentData(comment as CommentPayloadData);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
                if (this.comments.length === this.perPage) {
                    this.comments.pop();
                }
                this.comments.unshift(data);
                this.currentComment = data;
                this.isAddEditComment = false;
                if (this.currentPage !== 1) {
                    this.getComments(1, this.perPage);
                }
            }
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async deleteComment(id: string) {
        try {
            await CommentsWebApi.deleteComment(id);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.isAddEditComment = false;
            this.comments = this.comments.filter(comment => comment.id !== id);
            this.currentComment = null;
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    created() {
        window.addEventListener('click', this.stopClickPropagation);
        this.getComments(this.currentPage, this.perPage);
    }
}
</script>

<style lang='sass'>
    .b-form-tab
        display: flex
        flex-wrap: wrap
        margin: 0 -15px

        &__comments
            &-item
                position: relative
                cursor: pointer
                color: rgba(33, 63, 107, 0.5)
                font-weight: 500
                font-size: 14px
                margin: 10px
                padding: 25px 15px
                background-color: #EDF0F3
                border-radius: 10px

        &-less-more__button
            padding: 20px
            position: absolute
            bottom: -12px
            right: 0px
            font-size: 12px
            color: $dark-blue
            &:hover
                cursor: pointer
                text-decoration: underline

        &__row
            display: flex
            width: 100%
            margin-bottom: 0

            @include media('<=phone')
                flex-direction: column
                padding: 0 15px
                margin-bottom: 0 !important

                .b-form-tab__input
                    margin: 8px 15px

        &__input
            flex: 1 0
            margin: 0 15px
            input
                &:disabled
                    color: darkgray

        &__delete
            text-transform: uppercase
            margin-top: 20px
            font-size: 12px
            color: #FF004D
            font-weight: bold
            cursor: pointer
            text-align: center

            &:hover
                opacity: 0.7
</style>
