<template>
<FwSimpleCard
    class='b-manager-card qa-manager-card'
    width='100%'
    height='auto'
    padding='20px 20px 18px'
    :isActive='canRead'
    :isDisabled='!canRead'>
    <div class='b-manager-card__profile-image h-flex-center'
         :style='profileStyles'>
        <div>
            <FwIcon
                v-if='!manager.avatar_url'
                class='b-worker__avatar__icon'
                icon='no-avatar'
                size='12'
                color='#fff'>
            </FwIcon>
        </div>
    </div>
    <div class='h-flex h-flex-dir-column h-width-100p h-pl-15'>
        <div class='h-flex h-flex-space-between h-width-100p'>
            <div class='b-manager-card__title'>
                <div v-if='manager.full_name'
                     class='qa-manager-card__full_name'>
                    {{ manager.full_name | capitalizeAll }}
                </div>
            </div>
            <div class='h-flex-dir-column h-flex'>
                <div v-for='role in manager.roles'
                     class='b-manager-card__role'>
                    {{ role }}
                </div>
            </div>
        </div>
        <div class='b-manager-card__role-text__wrapper'>
            <FwIcon
                class='b-manager-card__role-icon'
                :icon='managerRole === "advisor" ? "ok" : "close"'
                size='11px'
                :color='managerRole === "advisor" ? "#27DBBD" : "#EB2767"'>
            </FwIcon>
            <span class='b-manager-card__role-text'>
                {{ $t('LABEL.ADVISOR') }}
            </span>
        </div>
        <div class='b-manager-card__role-text__wrapper'>
            <FwIcon
                class='b-manager-card__role-icon'
                :icon='managerRole === "manager" || isForceManager ? "ok" : "close"'
                size='11px'
                :color='managerRole === "manager" || isForceManager ? "#27DBBD" : "#EB2767"'>
            </FwIcon>
            <span class='b-manager-card__role-text'>
                {{ $t('LABEL.MANAGER') }}
            </span>
        </div>
    </div>
</FwSimpleCard>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SettingsManagerType } from '@/types/Settings';
import { separateColor, hexToRgbA } from '@/helpers/colors';

@Component
export default class ManagerCard extends Vue {
    @Prop({ type: Object, required: true }) readonly manager!: SettingsManagerType;
    @Prop({ type: Boolean, default: true }) readonly isForceManager!: boolean;
    @Prop({ type: Boolean, default: true }) readonly canRead!: boolean;

    get profileStyles() {
        return {
            backgroundImage: this.avatarColor ? `${this.avatarColor}` : 'none',
        };
    }

    get managerRole() {
        return this.manager.kind;
    }

    get avatarColor() {
        let result;
        if (this.manager.color) {
            const colorArray = separateColor(this.manager.color);
            result = this.manager.avatar_url ?
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0.5)},  ${hexToRgbA(colorArray[1], 0.5)}),
        url(${this.manager.avatar_url})` :
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
        } else {
            result = `linear-gradient(225deg, rgba(113, 143, 157, 0), rgba(105, 131, 143, 1))`;
        }
        return result;
    }
}
</script>

<style lang='sass'>
.b-manager-card
    display: flex
    &__title
        font-size: 13px
        color: #213F6B
        font-weight: 500
        margin-bottom: 4px
        min-height: 15px

    &__role
        margin-bottom: 5px
        border-radius: 5px
        background-color: #efefef
        color: #213F6B
        padding: 4px 11px 3px 11px
        font-weight: 500
        font-size: 9px

    &__role-text
        font-size: 11px
        color: #939394
        text-transform: uppercase
        margin-left: 5px
        font-weight: 500

        &__wrapper
            display: flex
            &:not(:last-of-type)
                margin-bottom: 7px

    &__role-icon
        display: contents

    &__profile-image
        width: 40px
        height: 40px
        flex: 0 0 40px
        border-radius: 50%
        background-color: $gray
        background-size: cover
        background-position: center
        transition: box-shadow 0.3s
</style>
