export default {
    'POPUPS.SETTINGS.TITLE': 'Display settings',
    'POPUP.COMING.SOON.TITLE': 'Coming soon',
    'POPUP.COMING.SOON.ASIDE': 'If you want the import to be carried out by our teams, please contact us at',
    'POPUP.COMING.SOON.BUTTON.TEXT': 'Close',

    'POPUPS.SETTINGS.STATISTICS': 'STATISTICS',
    'POPUPS.SETTINGS.ACCESSIBILITY_MODE': 'ACCESSIBILITY MODE',

    'POPUPS.HOW_TO_USE.TITLE': 'How to use ?',
    'POPUPS.HOW_TO_USE.SEE_THE_ACTIVITY': 'See the activity of all users in a given month, from a top level perspective',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_APPOINTMENTS': 'the day only contains appointments',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_SAAS': 'the day only contains Calizy SaaS appointment',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_APPT': 'the day only contains appointment',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_SC': 'the day only contains Calizy Selfcare appointment',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_RESERVED_SLOTS': 'the day only contains reserved slots',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_UNAVAILABILITIES': 'the day only contains unavailabilities',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_DIFFERENT_ELEMENTS': 'The day contains different element',
    'POPUPS.HOW_TO_USE.NOTHING_IS_HAPPENING': 'nothing is happening in the day',
    'POPUPS.HOW_TO_USE.CLICKING_ON_SQUARE': 'Clicking on a square will display a more detailed breakdown of the day',
    'POPUPS.HOW_TO_USE.NO_DATA_FOR_THIS_DAY': 'Noting is happening in the day',

    'POPUPS.BOOK.TITLE': 'Book an appointment',
    'POPUPS.BOOK.APPOINTMENT_TYPE': 'Appointment type',
    'POPUPS.BOOK.MEANS_OF_TRANSPORT': 'Means of transport',
    'POPUPS.BOOK.DESTINATION': 'Destination',
    'POPUPS.BOOK.TEAM': 'Team',
    'POPUPS.BOOK.BOOK': 'Book',
};
