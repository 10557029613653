import { RouteType } from '@/types/router';
import { SettingsWrapper } from '@/views/settings/SettingsWrapper';
import { RIGHT_READ } from '@/helpers/rights';
import getSettingsChildrenRoutes from './children-routes';

const settingsRoutes: Array<RouteType> = [
    {
        path: '/settings',
        component: SettingsWrapper,
        name: SettingsWrapper.name,
        redirect: '/settings/account',
        children: getSettingsChildrenRoutes(true, true),
        meta: {
            rightBlock: 'setting_rights',
            right: RIGHT_READ,
        },
    },
];

export default settingsRoutes;
