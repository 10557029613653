<template>
<FwSimpleCard
    class='b-card-with-list'
    width='100%'
    height='auto'
    padding='20px 20px 13px'
    :isActive='canRead'
    :isDisabled='!canRead'>
    <div v-if='title'
         class='b-card-with-list__title'>
        {{ title }}
    </div>
    <div class='b-card-with-list__item__wrapper'>
        <div v-for='(item, key) in data'
             :key='key'
             class='b-card-with-list__item'>
            <FwIcon
                class='h-flex-center h-pointer'
                :icon='item.active ? "ok" : "close"'
                size='11px'
                :color='item.active ? "#27dbbd" : "#FF004D"'>
            </FwIcon>
            <div class='b-card-with-list__item__text'
                 :class='{ "b-card-with-list__item__text--active": item.active }'>
                {{ item.text }}
            </div>
        </div>
    </div>
</FwSimpleCard>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SettingsCardListItemType } from '@/types/Settings';

@Component
export default class CardWithList extends Vue {
    @Prop({ type: String, default: null }) readonly title!: string | null;
    @Prop({ type: Array, required: true }) readonly data!: Array<SettingsCardListItemType>;
    @Prop({ type: Boolean, default: true }) readonly canRead!: boolean;
}
</script>

<style lang='sass'>
.b-card-with-list
    color: $dark-blue
    font-weight: 700
    display: flex
    flex-direction: column

    &__title
        font-size: 13px
        margin-bottom: 10px
        font-weight: 500
        color: $alt-blue

        width: 100%

    &__item
        display: flex
        align-items: center
        flex: 1 0 50%
        margin-bottom: 7px

        &__text
            font-size: 11px
            margin-left: 5px
            color: #939394
            text-transform: uppercase
            font-weight: 500

        &__wrapper
            display: flex
            flex-wrap: wrap
            width: 100%
</style>
