import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
    namespaced: true,
})
class StatisticsStore extends VuexModule {
    dates: Array<Date> = [];

    @Mutation
    setStatisticDates(statisticDates: Array<Date>) {
        this.dates = statisticDates;
    }
}

export default StatisticsStore;
