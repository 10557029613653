import { ReservedSlotsMappingType } from '@/types/ReservedSlots';

const APPOINTMENT_INFORMATION_NAME: string = 'client_name';
const APPOINTMENT_INFORMATION_DATE: string = 'date';
const APPOINTMENT_INFORMATION_ADVISOR_NAME: string = 'advisor_name';
const APPOINTMENT_INFORMATION_BRANCH_NAME: string = 'branches_name';
const APPOINTMENT_INFORMATION_REFERENCE_NAME: string = 'reference';

const ALL_ID: string = `all`;
const ON_SITE_ID: string = `location_category_on_site`;
const BY_PHONE_ID: string = `location_category_by_phone`;
const IN_BRANCH_BY_ONLINE_VIDEO: string = `location_category_by_online_video`;
const IN_BRANCH_BY_ON_SITE: string = `location_category_on_site_in_branch`;
const IN_SERVICE_POINT: string = `location_category_in_service_point`;
const IN_BRANCH_PUBLIC_ID: string = `location_category_in_branch_public`;

const IN_BRANCH_VIDEO_ID: string = `location_category_in_branch_client_video_private`;
const IN_BRANCH_PRIVATE_VIDEO_ID: string = `location_category_in_branch_private`;
const IN_BRANCH_BY_PHONE_PUBLIC_ID: string = `location_category_in_branch_by_phone_public`;
const IN_BRANCH_BY_PHONE_PRIVATE_ID: string = `location_category_in_branch_by_phone_private`;
const IN_BRANCH_BY_VIDEO_PUBLIC_ID: string = `location_category_in_branch_by_video_public`;
const IN_BRANCH_BY_VIDEO_PRIVATE_ID: string = `location_category_in_branch_by_video_private`;

const LOCATION_SKILLS_SEARCH_NAMES = [
    ON_SITE_ID,
    BY_PHONE_ID,
    IN_BRANCH_BY_ON_SITE,
    IN_BRANCH_BY_ONLINE_VIDEO,
    IN_SERVICE_POINT,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
    IN_BRANCH_BY_VIDEO_PUBLIC_ID,
    IN_BRANCH_BY_VIDEO_PRIVATE_ID,
];

const FLOWS_WITH_ROOM_ID_REQUIRE = [IN_BRANCH_BY_PHONE_PRIVATE_ID, IN_BRANCH_BY_VIDEO_PRIVATE_ID, IN_BRANCH_PRIVATE_VIDEO_ID, IN_BRANCH_VIDEO_ID];

const RESERVED_SLOTS_MAPPING: { [key: string]: ReservedSlotsMappingType } = {
    [ALL_ID]: {
        icon: 'slots-all',
        text: 'PLAN.APPOINTMENT.ADDRESS.ALL',
    },
    [ON_SITE_ID]: {
        icon: 'slots-on-site',
        text: 'PLAN.APPOINTMENT.ADDRESS.ON.SITE',
    },
    [BY_PHONE_ID]: {
        icon: 'slots-on-phone',
        text: 'PLAN.APPOINTMENT.ADDRESS.BY.PHONE',
    },
    [IN_BRANCH_BY_ONLINE_VIDEO]: {
        icon: 'slots-videoconference',
        text: 'PLAN.APPOINTMENT.ADDRESS.BY.VIDEO',
    },
    [IN_BRANCH_PUBLIC_ID]: {
        icon: 'slots-face-to-face',
        text: 'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.FACE',
    },
    [IN_BRANCH_VIDEO_ID]: {
        icon: 'slots-videoconference',
        text: 'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.VIDEO',
    },
    [IN_BRANCH_PRIVATE_VIDEO_ID]: {
        icon: 'slots-branch-private',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.PRIVATE',
    },
    [IN_BRANCH_BY_PHONE_PUBLIC_ID]: {
        icon: 'slots-phone-public',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PUBLIC',
    },
    [IN_BRANCH_BY_VIDEO_PUBLIC_ID]: {
        icon: 'slots-videoconference',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PUBLIC',
    },
    [IN_BRANCH_BY_PHONE_PRIVATE_ID]: {
        icon: 'slots-phone-private',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PRIVATE',
    },
    [IN_BRANCH_BY_VIDEO_PRIVATE_ID]: {
        icon: 'slots-videoconference',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PUBLIC',
    },
};

export {
    APPOINTMENT_INFORMATION_NAME,
    APPOINTMENT_INFORMATION_DATE,
    APPOINTMENT_INFORMATION_ADVISOR_NAME,
    APPOINTMENT_INFORMATION_BRANCH_NAME,
    APPOINTMENT_INFORMATION_REFERENCE_NAME,
    LOCATION_SKILLS_SEARCH_NAMES,
    RESERVED_SLOTS_MAPPING,
    ALL_ID,
    ON_SITE_ID,
    BY_PHONE_ID,
    IN_SERVICE_POINT,
    IN_BRANCH_BY_ON_SITE,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
    IN_BRANCH_BY_VIDEO_PUBLIC_ID,
    IN_BRANCH_BY_VIDEO_PRIVATE_ID,
    FLOWS_WITH_ROOM_ID_REQUIRE,
    IN_BRANCH_BY_ONLINE_VIDEO,
};
