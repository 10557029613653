<template>
<div class='b-consult-card-arrow'>
    <div class='b-consult-card-arrow__title'>
        {{ title }}
    </div>
    <div class='b-consult-card-arrow__icon'>
        <FwIcon
            class='h-mh-5'
            icon='arrow-right'
            size='20'
            color='#27dbbd'>
        </FwIcon>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ConsultCardArrow extends Vue {
    @Prop({ type: String, required: true }) readonly title!: str;
}
</script>

<style lang='sass'>
.b-consult-card-arrow
    background-color: #fff
    border-radius: 20px
    box-shadow: 0 30px 11px -17px rgba(33, 63, 107, 0.05)
    margin: 20px 0 0
    width: 100%
    flex: 1 0
    padding: 20px 25px 20px 30px
    max-height: 74px
    display: flex
    justify-content: space-between
    align-items: center
    cursor: pointer

    @include media('<=phone')
        margin: 10px 0
        padding: 20px 20px 20px 30px

    &:hover
        .b-consult-card-arrow__icon
            transform: translateX(10px)

    &__icon
        transition: transform 0.25s

    &__title
        font-size: 24px
        font-weight: 700

        @include media('<=phone')
            font-size: 20px
</style>
