<template>
<form class='h-flex-center h-flex-wrap h-mh--20'
      @keydown.stop.prevent.enter='onSubmit'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-profile-form__row'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(first_name)'
                labelType='label-medium'
                :title='`${$t("LABEL.FIRST.NAME")}*`'>
                <FwInput
                    :value='first_name'
                    :placeholder='$t(`LABEL.FIRST.NAME`)'
                    type='text'
                    inputStyle='white-bigger'
                    iconRight='edit-settings'
                    iconSize='18'
                    @update:value='value => first_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(last_name)'
                labelType='label-medium'
                :title='`${$t("LABEL.LAST.NAME")}*`'>
                <FwInput
                    :value='last_name'
                    :placeholder='$t(`LABEL.LAST.NAME`)'
                    type='text'
                    inputStyle='white-bigger'
                    iconRight='edit-settings'
                    iconSize='18'
                    @update:value='value => last_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(email)'
                labelType='label-medium'
                :disabled='true'
                :title='`${$t("LABEL.EMAIL")}**`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EMAIL`)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText(email)'
                    :disabled='true'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase(email)'
                    @blur='addCurrentInputToValidateArray(email)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-profile-form__row h-mh--20'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(phone)'
                labelType='label-medium'
                :title='$t(`LABEL.PHONE`)'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    inputStyle='white-bigger'
                    placeholder='ex: 06 20 45 30 79'
                    :error='getFieldErrorText(phone)'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    iconRight='edit-settings'
                    iconSize='18'
                    @update:value='value => phone = value'
                    @input='clearServerErrorsBase(phone)'
                    @blur='addCurrentInputToValidateArray(phone)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                :fieldHasError='fieldHasError(role)'
                labelType='label-medium'
                :disabled='true'
                :title='`${$t("LABEL.ROLE")}`'>
                <div class='fw-select-base b-settings-reminder__list__select'>
                    <FwSelect
                        v-model='role'
                        class='fw-select-with-icon--big'
                        type='white'
                        :disabled='true'
                        :withCircles='true'
                        :clearable='false'
                        :canNotDelete='true'
                        label='name'
                        :options='roles'>
                    </FwSelect>
                </div>
            </FwFormInput>
            <slot></slot>
        </div>
    </template>
</form>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import { User, UserManagerProfileRole } from '@/types/User';
import { isEqual } from '@/helpers/base';
import { ProfileFormDataType } from '@/types/Profile';

@Component({
    validators: {
        email(value: string) {
            return this.emailValidator({ value });
        },
    },
})
export default class EditProfileForm extends Mixins(ValidationMixin) {
    @State(state => state.SettingsStore.roles) roles!: Array<UserManagerProfileRole>;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Boolean, default: false }) readonly formSubmit!: boolean;

    userData: User = this.user();
    email: string = '';
    phone: string = '';
    first_name: string = '';
    last_name: string = '';
    loading: boolean = false;

    serverErrors: serverErrors = {
        email: null,
    };
    role: { id: string, name: string } | null = null

    startFormData: ProfileFormDataType | null = null;

    get isEqual(): boolean {
        return isEqual(this.startFormData, this.currentForm);
    }

    get currentForm(): ProfileFormDataType {
        return {
            email: this.email,
            phone: this.phone || '',
            first_name: this.first_name,
            last_name: this.last_name,
            role_id: this.role ? this.role.id : ``,
        };
    }

    get valid() {
        let result = null;
        if (this.first_name || this.last_name) {
            result = true;
        }
        return result;
    }

    get phoneNumber(): string | null {
        if (this.userData.manager_profile) {
            return this.userData.manager_profile.phone;
        }
        return this.userData.technician_profile ? this.userData.technician_profile.phone : null;
    }

    get roleName(): string {
        return this.currentUserRole ? this.currentUserRole.name : ``;
    }

    updateStartData() {
        this.userData = this.user();
        this.first_name = this.userData.first_name;
        this.last_name = this.userData.last_name;
        this.email = this.userData.email;
        this.phone = this.phoneNumber || '';
        const userRole = this.currentUserRole ? { id: this.currentUserRole.id, name: this.currentUserRole.name } : null;
        this.role = this.roles ? this.roles.find(role => role.name === this.roleName) || userRole : userRole;
        this.startFormData = JSON.parse(JSON.stringify({
            email: this.email,
            phone: this.phone || '',
            first_name: this.first_name,
            last_name: this.last_name,
            role_id: this.role ? this.role.id : '',
        }));
    }

    created() {
        this.updateStartData();
    }

    @Emit('submit')
    onSubmit(): ProfileFormDataType {
        return this.currentForm;
    }

    @Emit('isEqual')
    isEqualHandler(value: boolean): boolean {
        return value;
    }

    @Watch('formSubmit')
    formSubmitHandler(value: boolean): void {
        if (value) {
            this.onSubmit();
        } else {
            this.updateStartData();
        }
    }

    @Watch(`isEqual`)
    watchIsEqualHandler(value: boolean) {
        this.isEqualHandler(value);
    }
}
</script>

<style lang='sass'>
.b-profile-form
    &__row
        width: 100%
        display: flex
        margin-top: 20px

        @include media('<=phone')
            flex-direction: column

            .fw-form-input__wrapper:not(:last-of-type)
                margin-bottom: 12px
</style>
