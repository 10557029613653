const categories = [
    'CATEGORY.LEGAL.ADVISE',
    'CATEGORY.INDIVIDUAL.INSURANCE',
    'CATEGORY.PROFESSIONAL.INSURANCE',
    'CATEGORY.HEALTH.INSURANCE',
    'CATEGORY.ASSET.MANAGEMENT',
];

export {
    categories,
};
