export default {
    'WORKERS.FORM.TITLE': 'Add a technician',
    'WORKERS.FORM.TITLE.EDIT': 'Edit technician’s details',

    'WORKERS.FORM.ADVISOR.TITLE': 'Add a advisor',
    'WORKERS.FORM.ADVISOR.TITLE.EDIT': 'Edit advisor’s details',
    'WORKERS.FORM.TECHNICIAN.TITLE': 'Add a technician',
    'WORKERS.FORM.TECHNICIAN.TITLE.EDIT': 'Edit technician’s details',

    'WORKERS.SEARCH.PLACEHOLDER': 'Last name or First name',
    'WORKERS.ADD.BUTTON': 'Add a technician',

    'WORKERS.USER.NO_NAME': 'No name',

    'WORKERS.FORM.INPUT.FIRST_NAME.LABEL': 'First name',
    'WORKERS.FORM.INPUT.LAST_NAME.LABEL': 'Last name',
    'WORKERS.FORM.INPUT.PHONE.LABEL': 'Phone',
    'WORKERS.FORM.INPUT.COLOR.LABEL': 'Color',
    'WORKERS.FORM.INPUT.LABEL.LABEL': 'Label',
    'WORKERS.FORM.INPUT.COMPANIES.LABEL': 'Companies',
    'WORKERS.FORM.INPUT.COMPANY.LABEL': 'Company',
    'WORKERS.FORM.INPUT.ROLE.LABEL': 'Role',
    'WORKERS.FORM.INPUT.ROLE.CHOOSE': 'Choose a role',
    'WORKERS.FORM.INPUT.PLACEHOLDER.SELECT_COMPANIES.LABEL': 'Select one or more companies',
    'WORKERS.FORM.HINT.ONE_OF_THESE': 'Please complete at least one of these fields',
    'WORKERS.FORM.HINT.REQUIRED': 'Required field',

    'WORKERS.FORM.SUBMIT.TEXT': 'Add',
    'WORKERS.POPUP.SUCCESS.MESSAGE': 'The worker has been added',
    'WORKERS.POPUP.SUCCESS.EDIT.MESSAGE': 'Technician profile has been updated',

    'ADVISOR.FORM.TITLE': 'Add an advisor',
    'ADVISOR.FORM.EDIT.TITLE': 'Edit an advisor',
};
