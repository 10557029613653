<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#fff", "#fff", "#fff"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <FwPopup
        v-else-if='!loading && !confirmPopup'
        :centered='!isMobile()'
        :isFixed='isDesktop()'
        iconColor='#BEC7D4'
        max-width='1000px'
        :width='isMobile() ? "calc(100% - 20px)" : "1000px"'
        :title='$route.params.id ? $t(`HUB.POPUP.TITLE.EDIT`) : $t(`HUB.POPUP.TITLE.ADD`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :padding='isMobile() ? "30px 20px" : "40px 50px 25px"'
        class='h-pos-rel'
        :propsPopupStyles='{ paddingBottom: isMobile() ? "60px" : null }'
        @close='$emit("closePopup")'>
        <template #body>
            <AddOrEditHubForm
                class='h-mt-30'
                :isRequestSending='isRequestSending'
                :errorFromServer='errorFromServer'
                @onSubmit='sendDataToServer'
                @delete='showConfirm'>
            </AddOrEditHubForm>
        </template>
    </FwPopup>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`HUB.FORM.DELETE.POPUP.TITLE`)'
        :subMessage='$t(`HUB.FORM.DELETE.POPUP.TEXT`)'
        :isRequestSending='isRequestSending'
        confirmQaClass='qa-hub-page-delete-confirm-button'
        @deleteItem='deleteHub'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import { Component } from 'vue-property-decorator';
import { AddOrEditHubForm } from '@/components/forms/add/AddOrEditHubForm';
import { SitesApi } from '@/api/sites/SitesApi';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { HubCreateType } from '@/types/Appointment';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        AddOrEditHubForm,
        DeletePopup,
    },
})
export default class AddOrEditHubsByForm extends Vue {
    @WorkerPageStore.Mutation('setHubData') setHubData!: Function;
    errorFromServer: string | null | TranslateResult = null;
    isRequestSending: boolean = false;
    loading: boolean = false;
    confirmPopup: boolean = false;
    showConfirm() {
        this.confirmPopup = true;
    }
    async sendDataToServer(data: HubCreateType) {
        this.isRequestSending = true;
        try {
            let site;
            if (this.$route.params.id) {
                site = await SitesApi.updateHub(
                    data, this.$route.params.id as string,
                );
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
            } else {
                site = await SitesApi.createHub(data);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
            }
            if (this.$route.meta && this.$route.meta.isMainComponent) {
                this.$router.push({ path: `/consult/hub/${site.hub.id}` });
            }
            this.setHubData(site.hub);
            this.$emit('closePopup');
            this.isRequestSending = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
    async deleteHub() {
        this.isRequestSending = true;
        try {
            await SitesApi.deleteHub(this.$route.params.id);
            this.setHubData(null);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.$router.push({ path: '/consult/choose/hubs' });
        } finally {
            this.isRequestSending = false;
        }
    }
}
</script>
