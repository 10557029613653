<template>
<div class='b-page-container b-consult-service-point'>
    <GoBackButton
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='h-flex h-flex-center h-flex-space-between'>
            <div class='h-flex h-align-items-center'>
                <h1 class='h-pr-20 qa-branch-page-title'>
                    {{ $t('SETTINGS.ROLE.RIGHTS.SHIFTS') | capitalizeAll }}
                </h1>
            </div>
        </div>
    </template>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GoBackButton } from '@/components/simple/GoBackButton';
import PermissionsMixin from '@/mixins/permissions';

@Component({
    components: {
        GoBackButton,
    },
})
export default class ConsultServicePointShiftPage extends Mixins(PermissionsMixin) {
    loading: boolean = false;
}
</script>
