<template>
<div class='b-app-wrapper'>
    <RouterWrapper></RouterWrapper>
    <NotificationBar></NotificationBar>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import AuthMixin from '@/mixins/auth';
import { RouterWrapper } from './components/global/RouterWrapper';
import { NotificationBar } from './components/simple/NotificationBar';

@Component({
    components: {
        RouterWrapper,
        NotificationBar,
    },
})
export default class App extends Mixins(AuthMixin) {
    created() {
        dayjs.locale(this.$i18n.locale === 'fr' ? fr : this.$i18n.locale);
    }
}
</script>

<style lang='sass'>
@import '../node_modules/ui-framework/framework/style/base.sass'
@import '~normalize.css/normalize.css'
@import '../style/base.sass'

.b-app-wrapper
    min-height: 100vh
    width: 100%
</style>
