export default {
    'SELECT.APPOINTMENT.TITLE': 'Appointments',
    'SELECT.TYPE.APPOINTMENT.CATEGORY.TITLE': 'Select an appointment category',
    'SELECT.TYPE.APPOINTMENT.TITLE': 'Select an appointment type',
    'SELECT.TYPE.APPOINTMENT.PLACEHOLDER': 'Appointment type',
    'SELECT.TYPE.APPOINTMENT.CATEGORY.PLACEHOLDER': 'Appointment category',

    'SELECT.ROOM.APPOINTMENT.TITLE': 'In which room?',
    'SELECT.ROOM.APPOINTMENT.PLACEHOLDER': 'Select a room',

    'SELECT.LOCATION.APPOINTMENT.TITLE': 'Where will the appointment take place?',
    'SELECT.LOCATION.APPOINTMENT.PLACEHOLDER': 'Location',

    'SELECT.POSTCODE.APPOINTMENT.TITLE': 'What is the customer\'s postcode?',
    'SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER': 'Postcode',

    'SELECT.ADDRESS.APPOINTMENT.CLIENT.TITLE': 'What is the customer\'s address?',
    'SELECT.ADDRESS.APPOINTMENT.LABEL': 'Address',
    'SELECT.ADDRESS.APPOINTMENT.TITLE': 'What is the appointment address?',
    'SELECT.ADDRESS.APPOINTMENT.PLACEHOLDER': 'Please enter the address here',
    'SELECTOR.ADDRESS.NO.ELEMENT.FOUND': 'No elements found',

    'SELECT.ADDRESS.BRANCH.TITLE': 'Select a branch',
    'SELECT.SERVICE_POINT.TITLE': 'Select a service point',
    'SELECT.ADDRESS.BRANCH.CONTACT.DETAILS': 'Contact details',
    'SELECT.ADDRESS.BRANCH.CUSTOMER': 'Customer address',
    'SELECT.BOOKING.PARAMETERS.TITLE': 'Booking Parameters',

    'DELETE.APPOINTMENT.TYPE.TITLE': 'Delete appointment type',
    'DELETE.APPOINTMENT.TYPE.TEXT': 'Do you really want to delete this appointment type?',
    'DELETE.APPOINTMENT.TYPE.SUCCESS.TITLE': 'Appointment type deleted',
    'DELETE.APPOINTMENT.TYPE.SUCCESS.TEXT': 'The appointment type has been deleted',

    'DELETE.APPOINTMENT.WORKER.TITLE': 'Delete technician',
    'DELETE.APPOINTMENT.WORKER.TEXT': 'Do you really want to delete this technician?',
    'DELETE.APPOINTMENT.WORKER.SUCCESS.TITLE': 'Worker deleted',
    'DELETE.APPOINTMENT.WORKER.SUCCESS.TEXT': 'Worker has been deleted',

    'DELETE.APPOINTMENT.BUSINESS.COMPANY.TITLE': 'Delete business partner company',
    'DELETE.APPOINTMENT.BUSINESS.COMPANY.TEXT': 'Do you really want to delete this business partner company?',
    'DELETE.APPOINTMENT.BUSINESS.COMPANY.SUCCESS.TITLE': 'Business partner company deleted',

    'DELETE.APPOINTMENT.ADVISOR.TITLE': 'Delete advisor',
    'DELETE.APPOINTMENT.ADVISOR.TEXT': 'Do you really want to delete this advisor?',
    'DELETE.APPOINTMENT.ADVISOR.SUCCESS.TITLE': 'Advisor deleted',
    'DELETE.APPOINTMENT.ADVISOR.SUCCESS.TEXT': 'Advisor has been deleted',

    'DELETE.APPOINTMENT.HUB.TITLE': 'Delete a hub',
    'DELETE.APPOINTMENT.HUB.TEXT': 'Do you really want to delete this hub?',
    'DELETE.APPOINTMENT.HUB.SUCCESS.TITLE': 'Hub deleted',
    'DELETE.APPOINTMENT.HUB.SUCCESS.TEXT': 'Hub has been deleted',

    'DELETE.APPOINTMENT.BRANCH.TITLE': 'Delete Branch',
    'DELETE.APPOINTMENT.SUB.BRANCH.TEXT': 'Do you really want to delete this branch ?',
    'DELETE.APPOINTMENT.BRANCH.DELETED': 'Branch deleted',

    'ADD.ROOM.TITLE': 'Add a room',
    'EDIT.ROOM.TITLE': 'Edit a room',
    'ADD.ROOM.QUESTION': 'Do you want to add rooms to this branch ?',

    'MANAGE.ROOMS.TITLE': 'Manage rooms',

    'DELETE.APPOINTMENT.ROOM.TITLE': 'Delete room',
    'DELETE.APPOINTMENT.ROOM.TEXT': 'Do you really want to delete this room?',
    'DELETE.APPOINTMENT.ROOM.SUCCESS.TITLE': 'Room deleted',
    'DELETE.APPOINTMENT.ROOM.SUCCESS.TEXT': 'The room has been deleted.',

    'SKILLS.YOU.NEED.INFO': 'The skill you need is not is the list?',
    'SKILLS.YOU.NEED.INFO.CONTACT': ' Please contact us',
    'SKILLS.YOU.NEED.SELECT': 'Select skills',

    'CONSULT.APPOINTMENT.SEARCH.TITLE': 'Search for an appointment',
    'CONSULT.APPOINTMENT.SEARCH.CLIENTS.NAME': 'Client\'s name',
    'CONSULT.APPOINTMENT.SEARCH.CLIENT.FULLNAME': 'Client last name',
    'CONSULT.APPOINTMENT.SEARCH.SITE.CODE': 'Side code',
    'CONSULT.APPOINTMENT.SEARCH.ADVISORS.NAME': 'Advisor\'s last name',
    'CONSULT.APPOINTMENT.SEARCH.ADVISOR.NAME': 'Advisor last name',
    'CONSULT.APPOINTMENT.SEARCH.TECHS.NAME': 'Technician\'s last name',
    'CONSULT.APPOINTMENT.SEARCH.TECH.NAME': 'Technician last name',
    'CONSULT.APPOINTMENT.SEARCH.DATE': 'Date',
    'CONSULT.APPOINTMENT.SEARCH.REFERENCE': 'Reference',
    'CONSULT.APPOINTMENT.SEARCH.POPUP.EDIT': 'Edit appointment',

    'CONSULT.APPOINTMENT.CLIENT.DETAILS': 'Client details',
    'CONSULT.APPOINTMENT.COMMENT': 'Comment',
    'CONSULT.APPOINTMENT.COMMENTS': 'Comments',
    'CONSULT.APPOINTMENT.NO.COMMENTS': 'No comments',

    'CONSULT.BRANCHES.NAME': 'Branches name',
    'CONSULT.BRANCH.NAME': 'Branch name',
    'CONSULT.COMPANIES.NAME': 'Company name',
    'CONSULT.COMPANY.NAME': 'Company name',
    'CONSULT.BRANCHES.REFERENCE': 'Reference',

    'CONSULT.TYPE.NAME.PLACEHOLDER': 'Type the name',
    'CONSULT.TYPE.DATE.PLACEHOLDER': 'Choose a date',
    'CONSULT.TYPE.ADVISOR.PLACEHOLDER': 'Type the name',
    'CONSULT.TYPE.CODE.PLACEHOLDER': 'Type side code',
    'CONSULT.TYPE.BRANCH.PLACEHOLDER': 'Branches name',
    'CONSULT.TYPE.COMPANIES.PLACEHOLDER': 'Company name',
    'CONSULT.TYPE.REFERENCE.PLACEHOLDER': 'Type the reference',

    'CUSTOMER.DETAILS.INPUT.PLACEHOLDER': 'Prospect or Customer ID',
    'CUSTOMER.DETAILS.BUTTON.ID': 'ID',
    'CUSTOMER.DETAILS.BUTTON.TYPE.IN': 'TYPE IN',

    'PAST.APPOINTMENTS.CANT.BE.CANCELED': 'Past appointment can’t be canceled',
    'PAST.APPOINTMENT.DETAILS.TITLE': 'Appointment details',

    'PAST.APPOINTMENT.SET_BOOK.TITLE': 'Please input client email or phone number',
    'PAST.APPOINTMENT.UPDATE.CLIENT.DETAILS': 'I want to update client details',

    'APPOINTMENT.DISABLE.CUSTOMER.NOTIFICATION': 'Disable Customer Notifications',

    'MATMUT.HAVE_NOT_PERMISSIONS': 'You have no rights to access it. Please contact your manager and update profile rights',

    'APPOINTMENT.POPUP.ARCHIVE.TITLE': 'Archive appointment type',
    'APPOINTMENT.POPUP.ARCHIVE.TEXT': 'Do you really want to archive this appointment type?',
    'APPOINTMENT.POPUP.ARCHIVE.YES': 'Yes, archive',
    'APPOINTMENT.POPUP.ARCHIVE.CANCEL': 'Cancel',

    'APPOINTMENT.POPUP.REACTIVE.TITLE': 'Reactivate appointment type',
    'APPOINTMENT.POPUP.REACTIVE.TEXT': 'Do you really want to reactivate this appointment type?',
    'APPOINTMENT.POPUP.REACTIVE.YES': 'Yes, reactivate',
    'APPOINTMENT.POPUP.REACTIVE.CANCEL': 'Cancel',

    'UPDATE.APPOINTMENT.TITLE': 'UPDATE THE APPOINTMENT',
    'UPDATE.APPOINTMENT.TITLE.QUESTION': 'What do you want to update?',
    'UPDATE.APPOINTMENT.DATE.TIME.ADVISOR': 'Date, time, advisor',
    'UPDATE.APPOINTMENT.OTHER.ELEMENT': 'Other element',
    'UPDATE.APPOINTMENT.BEING.UPDATED': 'Appointment being updated',
    'UPDATE.APPOINTMENT.NOW.TITLE.QUESTION': 'Do you want to make any further updates?',
    'UPDATE.APPOINTMENT.NOW.BUTTON': 'Update now',
    'UPDATE.APPOINTMENT.OPTIONS': 'Update the starting time',

    'CONSULT.CLOSURES': 'Closures',
    'CONSULT.ADD_CLOSURES': 'Add a closure',
    'CONSULT.UPDATE_CLOSURES': 'Update a closure',

    'APPOINTMENT.DIRECT.BOOK.HOVER.INFO': 'Appointment directly booked in the advisor calendar.',

    'APPOINTMENT.USE.ANOTHER.ADDRESS': 'Use another address',
    'APPOINTMENT.USE.ANOTHER.POSTCODE': 'Use another postcode',
};
