<template>
<div class='b-page-container b-page-container--big b-consult-workers'>
    <GoBackButton
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <div class='h-flex-dir-column h-mh-10'>
        <div class='b-common-title-wrapper'>
            <h1>
                {{ $t(`LABEL.TECHNICIANS`) }}
                <template v-if='!(loading && !totalPage)'>
                    ({{ totalPage }})
                </template>
            </h1>
            <div class='b-common-title-wrapper__search'>
                <FwInput
                    class='h-flex-1 h-ml-15'
                    :value='searchText'
                    :placeholder='$t(`LABEL.SEARCH.BY.LAST`)'
                    iconRight='loupe'
                    iconSize='15'
                    inputStyle='white-bigger-search'
                    type='search'
                    @update:value='value => searchText = value'>
                </FwInput>
            </div>
        </div>
        <div class='h-flex h-width-100p h-flex-space-between'>
            <div class='b-consult-button-size h-flex h-flex-justify-end h-mr-0'>
                <FwButton
                    v-if='canCreate'
                    class='h-ph-40'
                    size='small'
                    borderRadiusType='small-border'
                    fontSize='12px'
                    @click.native='addWorkerMethod'>
                    {{ $t('BUTTON.ADD') }}
                </FwButton>
            </div>
            <div class='fw-select-base fw-select-white-modern h-width-250' style='box-shadow: none!important;'>
                <FwSelect
                    v-model='sortValue'
                    label='name'
                    :propsPlaceholder='`${$t(`BUTTON.SORT_BY`)}:`'
                    type='sort-simple'
                    :withCircles='true'
                    :options='sortOptions'>
                </FwSelect>
            </div>
        </div>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else class='b-consult-workers__wrapper'>
        <WorkerConsultCard
            v-for='worker in workersCardsData'
            :key='worker.id'
            :class='{ "h-mh-10": !isMobile() }'
            class='h-mb-20'
            :worker='worker'
            :workerId='workerId'
            @click.native='workerId = worker.id'>
        </WorkerConsultCard>
    </div>
    <FwPagination
        v-if='totalPage > perPage'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>
    <AddEditWorkerPopup
        v-if='isAddEditWorkerPopup'
        isWorkerAdd
        @closePopup='closeWorkerAddPopup'>
    </AddEditWorkerPopup>
</div>
</template>

<script lang='ts'>
import throttle from 'lodash.throttle';
import { TranslateResult } from 'vue-i18n';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { updateUrlPageAndQuery } from '@/helpers/url';
import { WorkerType } from '@/types/Workers';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { WorkerConsultCard } from '@/components/simple/WorkerConsultCard/index';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { AddEditWorkerPopup } from '@/components/popups/AddEditWorkerPopup/index';
import OrganizationMixin from '@/mixins/organization';
import PermissionsMixin from '@/mixins/permissions';

@Component({
    components: {
        WorkerConsultCard,
        AddEditWorkerPopup,
        GoBackButton,
    },
})
export default class ConsultWorkersPage extends Mixins(OrganizationMixin, PermissionsMixin) {
    totalPage: number | null = 0;
    perPage: number = 12;
    currentPage: number = 1;
    searchText: string = '';
    text: string = '';
    loading: boolean = true;
    isAddEditWorkerPopup: boolean = false;
    delayOff: boolean = true;
    workerId: number | null = null;
    sortKey: 'first_name' | 'last_name' = `first_name`;
    sortOptions: Array<{ name: str, type: 'first_name' | 'last_name' }> = [
        {
            name: this.$i18n.t('LABEL.FIRST.NAME'),
            type: 'first_name',
        },
        {
            name: this.$i18n.t('LABEL.LAST.NAME'),
            type: 'last_name',
        },
    ];
    sortValue: { name: str, type: 'first_name' | 'last_name' } = this.sortOptions[0];
    sortByLastName: boolean = false;
    workersCardsData: Array<WorkerType> = [];
    throttleSearch = throttle(this.search, 1500, { leading: false, trailing: true });
    unwatch = () => { };
    unwatchSort = () => { };

    updateState(page: number = this.currentPage) {
        this.$router.push(updateUrlPageAndQuery(`/consult/choose/technicians`, page, {
            query: this.searchText,
            sort: this.sortKey !== 'first_name' ? this.sortKey : '',
        }));
    }

    search(): void {
        this.currentPage = 1;
        this.updateState();
    }

    addWorkerMethod() {
        this.isAddEditWorkerPopup = true;
    }

    closeWorkerAddPopup() {
        this.isAddEditWorkerPopup = false;
    }

    async getWorkers(current_page: number, per_page: number, sort: 'first_name' | 'last_name') {
        this.loading = true;
        const payload: any = {
            current_page,
            per_page,
            sort: this.sortByLastName || '',
        };
        // @ts-ignore-next-line
        const { data } = await WorkersWebApi.getTechniciansData(payload, 'technician');
        // @ts-ignore-next-line
        this.workersCardsData = data.users;
        this.totalPage = data.meta.total_count;
        this.loading = false;
    }

    doRequest(): void {
        if (this.searchText.length > 1) {
            this.getWorkersBySearch(this.currentPage, this.perPage, this.searchText);
        } else if (this.searchText.length === 0) {
            this.getWorkers(this.currentPage, this.perPage, this.sortKey);
        }
    }

    async getWorkersBySearch(current_page: number, per_page: number, searchText: string) {
        if (this.searchText.length > 1) {
            this.loading = true;
            const payload: any = {
                scope: 'technician',
                current_page,
                per_page,
                sort: this.sortByLastName || '',
            };
            if (this.delayOff) {
                const { data } = await WorkersWebApi.getTechniciansDataBySearch(payload, searchText);
                this.workersCardsData = data.users;
                this.totalPage = data.meta.total_count;
                this.loading = false;
            }
        }
    }

    takeParamsFromUrl(): void {
        this.unwatch();
        this.unwatchSort();
        this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
        this.searchText = (this.$route.query.query as string) || ``;
        if (this.$route.query.sort) {
            const sotValue = this.sortOptions.find(item => item.type === this.$route.query.sort);
            if (sotValue) {
                this.sortValue = sotValue;
            }
        }
        this.unwatch = this.$watch('searchText', this.throttleSearch);
        this.unwatchSort = this.$watch('sortValue', this.sortBy);
    }

    created(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }

    sortBy(): void {
        if (this.sortValue) {
            this.currentPage = 1;
            this.sortKey = this.sortValue.type;
            this.sortByLastName = this.sortKey === `last_name`;
            this.updateState();
        }
    }

    @Watch(`$route`)
    onRouterChange(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }
}
</script>

<style lang='sass'>
.b-consult-workers.b-page-container--big
    max-width: 830px

    .b-consult-button-size
        height: 44px
        display: flex
        align-items: center
</style>
