import { RouteType } from '@/types/router';
import { SettingsAccountPage } from '@/views/settings/SettingsAccountPage';
import { SettingsManagersPage } from '@/views/settings/SettingsManagersPage';
import { SettingsRolesPage } from '@/views/settings/SettingsRolesPage';
import { SettingsAppointmentTypesPage } from '@/views/settings/SettingsAppointmentTypesPage';
import { SettingsAppointmentRemindersPage } from '@/views/settings/SettingsAppointmentRemindersPage';
import { SettingsCalendarViewPage } from '@/views/settings/SettingsCalendarViewPage';
import { SettingsGenericUrlsPage } from '@/views/settings/SettingsGenericUrlsPage';
import { SettingsRoleAddPage } from '@/views/settings/SettingsRoleAddPage';
import { SettingsCustomisePage } from '@/views/settings/SettingsCustomisePage';
import { RIGHT_CREATE, RIGHT_LIST, RIGHT_UPDATE, RIGHT_READ, CONSULT } from '@/helpers/rights';

function getSettingsChildrenRoutes(isMatmut: boolean = false, isUrlBuilderAvailable: boolean = false): Array<RouteType> {
    const settingsChildrenRoutes: Array<RouteType> = [
        {
            path: '/settings/account',
            name: SettingsAccountPage.name,
            component: SettingsAccountPage,
            meta: {
                showAppHeader: true,
                icon: 'suitcase-icon',
                text: 'SETTINGS.MENU.ACCOUNT',
                showAppHeaderProgress: true,
                rightBlock: 'account_rights',
                right: RIGHT_READ,
                pageType: CONSULT,
                cleanPath: '/settings/account',
            },
        },
        {
            path: '/settings/managers/:page([0-9]+)?',
            name: SettingsManagersPage.name,
            component: SettingsManagersPage,
            meta: {
                showAppHeader: true,
                icon: 'users',
                text: 'SETTINGS.MENU.MANAGERS',
                showAppHeaderProgress: true,
                rightBlock: 'manager_rights',
                right: RIGHT_LIST,
                pageType: CONSULT,
                cleanPath: '/settings/managers',
            },
        },
        {
            path: '/settings/roles/:page([0-9]+)?',
            name: SettingsRolesPage.name,
            component: SettingsRolesPage,
            meta: {
                showAppHeaderProgress: true,
                showAppHeader: true,
                icon: 'memberships',
                text: 'SETTINGS.MENU.ROLES',
                rightBlock: 'right_rights',
                right: RIGHT_LIST,
                pageType: CONSULT,
                cleanPath: '/settings/roles',
            },
        },
        {
            path: '/settings/roles/add',
            name: 'SettingsRoleAddPage',
            component: SettingsRoleAddPage,
            meta: {
                showAppHeader: true,
                showAppHeaderProgress: true,
                rightBlock: 'right_rights',
                right: RIGHT_CREATE,
                pageType: CONSULT,
            },
        },
        {
            path: '/settings/role/:id',
            name: `SettingsRoleAddPageEdit`,
            component: SettingsRoleAddPage,
            meta: {
                showAppHeader: true,
                showAppHeaderProgress: true,
                rightBlock: 'right_rights',
                right: RIGHT_READ,
            },
        },
        {
            path: '/settings/appointment-types/:page([0-9]+)?',
            name: `SettingsAppointmentTypesPage`,
            component: SettingsAppointmentTypesPage,
            meta: {
                showAppHeader: true,
                icon: 'label-icon',
                text: 'SETTINGS.MENU.APPT.TYPES',
                showAppHeaderProgress: true,
                rightBlock: 'appointment_category_rights',
                right: RIGHT_LIST,
                pageType: CONSULT,
                cleanPath: '/settings/appointment-types',
            },
        },
        {
            path: '/settings/appointment-reminders',
            name: SettingsAppointmentRemindersPage.name,
            component: SettingsAppointmentRemindersPage,
            meta: {
                showAppHeader: true,
                showAppHeaderProgress: true,
                icon: 'time-icon',
                text: 'SETTINGS.MENU.APPT.REMINDERS',
                rightBlock: 'appointment_notification_rights',
                right: RIGHT_LIST,
                pageType: CONSULT,
                cleanPath: '/settings/appointment-reminders',
            },
        },
        {
            path: '/settings/calendar-view',
            name: SettingsCalendarViewPage.name,
            component: SettingsCalendarViewPage,
            meta: {
                showAppHeader: true,
                showAppHeaderProgress: true,
                icon: 'calendar',
                text: 'SETTINGS.MENU.CALENDAR.VIEW',
                rightBlock: 'calendar_view_setting_rights',
                right: RIGHT_READ,
                pageType: CONSULT,
                cleanPath: '/settings/calendar-view',
            },
        },
    ];

    if (isUrlBuilderAvailable) {
        settingsChildrenRoutes.push({
            path: '/settings/generic-urls',
            name: SettingsGenericUrlsPage.name,
            component: SettingsGenericUrlsPage,
            meta: {
                showAppHeader: true,
                showAppHeaderProgress: true,
                icon: 'link',
                text: 'SETTINGS.ROLE.RIGHTS.GENERIC.URLS',
                rightBlock: 'calendar_view_setting_rights',
                right: RIGHT_READ,
                pageType: CONSULT,
            },
        });
    }

    if (isMatmut && process.env.VUE_APP_DEPLOY_TYPE === `staging`) {
        settingsChildrenRoutes.push({
            path: '/settings/customise',
            name: SettingsCustomisePage.name,
            component: SettingsCustomisePage,
            meta: {
                showAppHeader: true,
                showAppHeaderProgress: true,
                icon: 'settings-2',
                text: 'SETTINGS.MENU.CUSTOMISATION',
                rightBlock: 'calendar_view_setting_rights',
                right: RIGHT_READ,
                pageType: CONSULT,
                cleanPath: '/settings/customise',
            },
        });
    }
    return settingsChildrenRoutes;
}

export default getSettingsChildrenRoutes;
