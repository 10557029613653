<template>
<div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else>
        <div class='b-settings__title b-settings-role--button b-settings__title--start'>
            <div class='h-flex h-flex-space-between h-width-100p'>
                <div class='h-flex h-align-items-center'>
                    <GoBackButton
                        class='b-settings__back_arrow'>
                    </GoBackButton>
                    <div v-if='role'
                         class='h-flex h-align-items-center h-flex-space-between h-flex-1 h-pl-20'>
                        {{ role.name }}
                    </div>
                </div>
                <FwButton
                    v-if='!disabledByPermission'
                    class='b-settings-role__button'
                    size='small'
                    borderRadiusType='small-border'
                    :disabled='isDisabled'
                    @click.native='saveRole'>
                    {{ $t('BUTTON.SAVE') }}
                </FwButton>
            </div>
        </div>
        <div class='b-settings-role__block h-mb-30'>
            <div class='h-flex h-flex-wrap h-mh--10'>
                <div class='h-ph-10 h-width-50p'>
                    <div class='b-settings-role__title h-pl-20 h-mb-15'>
                        {{ $t('LABEL.NAME') }}
                    </div>
                    <FwInput
                        class='qa-add-role-name-input'
                        :value='name'
                        type='text'
                        name='text'
                        :placeholder='$t(`SETTINGS.ROLE.NAME.TITLE`)'
                        inputStyle='white-bigger-shadow'
                        :error='getFieldErrorText("name")'
                        @update:value='value => name = value'
                        @input='input("name")'
                        @blur='addCurrentInputToValidateArray("name")'>
                    </FwInput>
                </div>
            </div>
        </div>
        <div class='b-settings-role__block'>
            <div class='h-flex h-flex-space-between h-pl-20 h-pr-10 b-settings-role__block__header'>
                <div class='b-settings-role__title-main'>
                    <span v-if='false'>
                        {{ $t('SETTINGS.ROLE.RIGHTS.TITLE') }}
                    </span>
                </div>
                <div class='h-flex h-flex-space-between h-align-items-center h-pr-10 b-settings-role__block__header__inner'>
                    <div v-for='item in titles'
                         :key='item.key'
                         class='h-font-14 h-fw-700 h-width-60 h-flex-center h-width-100 b-settings-role__title--upper'>
                        {{ $t(item.title) }}
                    </div>
                </div>
            </div>
            <div v-for='(group, groupKey) in preparedRightsData'
                 v-show='groupKey !== hideGroup'
                 :key='groupKey'>
                <div class='b-settings-role__group__title'>
                    {{ $t(groupKey) }}
                </div>
                <div v-for='item in filterGroup(group)'
                     :key='item.key'
                     class='b-settings-reminder__list__white-bg h-mv-5'
                     :class='`qa-${item.key}`'>
                    <div class='b-settings-role__row h-flex-space-between'>
                        <div class='b-settings-role__title'>
                            {{ $t(item.data.title) }}
                        </div>
                        <SettingsRolesRow
                            :rights='item.rights'
                            :titles='titles'
                            :rightKey='item.key'
                            :disabled='item.disabledSwitchers'
                            :disabledByPermission='disabledByPermission'
                            @rightUpdate='rights => rightsUpdate(rights, item.key)'>
                        </SettingsRolesRow>
                    </div>
                </div>
            </div>
        </div>

        <div v-if='$route.params.id && canDelete'
             class='h-text-center h-pv-20'>
            <div class='b-add-manager_new__form__delete h-uppercase h-pointer'
                 @click='isDeletePopupShow = true'>
                {{ $t('SETTINGS.ROLE.DELETE.TITLE') }}
            </div>
        </div>
    </div>

    <DeletePopup
        v-if='isDeletePopupShow'
        :headerText='$t(`SETTINGS.ROLE.DELETE.TITLE`)'
        :subMessage='$t(`SETTINGS.ROLE.DELETE.SUB`)'
        :isRequestSending='isDeleteRequestSending'
        @deleteItem='removeRole'
        @close='isDeletePopupShow = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { clone } from 'ramda';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { RoleCard } from '@/components/simple/RoleCard';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import TranslateMixin from '@/mixins/TranslateMixin';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { RoleRightType, RoleFullPayloadType, RoleFullType } from '@/types/Roles';
import { RightKeyType } from '@/types/Rights';
import i18n from '@/locale';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { SettingsRolesRow } from '@/components/common/SettingsRolesRow';
import ValidationMixin from '@/mixins/validation';
import { isEqual } from '@/helpers/base';
import PermissionsMixin from '@/mixins/permissions';

const baseRightsData = () => ({ create: false, destroy: false, list: false, read: false, update: false });

const switchersBase = () => ({ create: false, destroy: false, list: false, read: false, update: false });
const switchersOnlyCreate = () => ({ create: false, destroy: true, list: true, read: true, update: true });
const switchersOnlyShow = () => ({ create: true, destroy: true, list: true, read: false, update: true });
const switchersShowAndEdit = () => ({ create: true, destroy: true, list: true, read: false, update: false });
const switchersOnlyRead = () => ({ create: true, destroy: true, list: true, read: false, update: true });
const switchersCanNotCD = () => ({ create: true, destroy: true, list: false, read: false, update: false });
const switchersShowAndCreate = () => ({ create: false, destroy: true, list: true, read: false, update: true });

const ONBOARDING_GROUP = `SETTINGS.ROLE.GROUP.ONBOARDING`;
const BOOKING_FLOW_GROUP = `SETTINGS.ROLE.GROUP.BOOKING_FLOW`;
const RESOURCES_GROUP = `SETTINGS.ROLE.GROUP.RESOURCES`;
const CONFIGURATION_GROUP = `SETTINGS.ROLE.GROUP.CONFIGURATION`;

type AvailableRightsKeys = {
    [key: string]: {
        title: string,
        rights: RoleRightType,
        group: string,
        forceDisable?: boolean
        disabledSwitchers: RoleRightType
    }
}
type PrepareRightDataType = {
    rights: RoleRightType,
    key: string,
    group: string,
    forceDisable?: boolean,
    data: { title: string, rights: RoleRightType },
    disabledSwitchers: RoleRightType
}

@Component({
    components: {
        GoBackButton,
        RoleCard,
        DeletePopup,
        SettingsRolesRow,
    },
    validators: {
        name(value: string) {
            return this.requiredValidator({ value });
        },
    },
})
export default class SettingsRoleAddPage extends Mixins(TranslateMixin, ValidationMixin, PermissionsMixin) {
    loading: boolean = false;
    isRequestSending: boolean = false;
    isDeleteRequestSending: boolean = false;
    isDeletePopupShow: boolean = false;

    serverErrors = {
        name: null,
    }

    hideGroup: string = ONBOARDING_GROUP;

    titles: Array<{ title: string, key: RightKeyType }> = [
        {
            title: 'SETTINGS.ROLE.CREATE',
            key: `create`,
        },
        {
            title: 'SETTINGS.ROLE.DELETE',
            key: `destroy`,
        },
        {
            title: 'SETTINGS.ROLE.LIST',
            key: `list`,
        },
        {
            title: 'SETTINGS.ROLE.READ',
            key: `read`,
        },
        {
            title: 'SETTINGS.ROLE.EDIT',
            key: `update`,
        },
    ];
    name: string = '';

    groups: Array<string> = [ONBOARDING_GROUP, BOOKING_FLOW_GROUP, RESOURCES_GROUP, CONFIGURATION_GROUP];

    availableRightsKeys: AvailableRightsKeys = {}

    role: RoleFullType | null = null;
    startPreparedRolePayload: null | RoleFullPayloadType = null;

    get disabledByPermission() {
        return this.role ? !this.canEdit : !this.canCreate;
    }

    get preparedRightsData(): { [key: string]: Array<PrepareRightDataType> } {
        const preparedRights: Array<PrepareRightDataType> = Object.keys(this.availableRightsKeys).map(key => ({
            key,
            data: this.availableRightsKeys[key],
            group: this.availableRightsKeys[key].group,
            forceDisable: this.availableRightsKeys[key].forceDisable,
            disabledSwitchers: this.availableRightsKeys[key].disabledSwitchers,
            // @ts-ignore-next-line
            rights: this.role && this.role[key] && !this.availableRightsKeys[key].forceDisable ? this.role[key] : this.availableRightsKeys[key].rights,
        }));
        return Object.fromEntries(this.groups.map(groupName => [groupName, preparedRights.filter(right => right.group === groupName)]));
    }

    get isHubsFlow() {
        return this.user().account_organization_type === `with_hubs`;
    }

    get isBranchesFlow() {
        return this.user().account_organization_type === `with_branches`;
    }

    get isDisabled() {
        if (this.isRequestSending) {
            return true;
        }
        if (!this.$route.params.id) {
            return !this.isFormValid;
        }
        return isEqual(this.preparedRolePayload, this.startPreparedRolePayload) && (!this.role || this.name === this.role.name);
    }

    get preparedRolePayload(): RoleFullPayloadType {
        const groupValues = {};
        const data = Object.values(this.preparedRightsData).flat();
        for (let i = 0; i < data.length; i++) {
            const group = data[i];
            // @ts-ignore-next-line
            groupValues[group.key] = group.rights;
        }
        // @ts-ignore-next-line
        return groupValues;
    }

    get servicePointSupport(): boolean {
        return !!this.accountData?.account.service_point_support;
    }

    async getData() {
        if (!this.skills.length) {
            const { skill_names } = await OnboardingApi.getFrontHelpers('skill_names');
            this.skills = skill_names;
        }
        const { category_location_skill_names } = await OnboardingApi.getFrontHelpers('category_location_skill_names');
        this.categoryLocationSkills = category_location_skill_names;
    }

    filterGroup(group: Array<PrepareRightDataType>) {
        return group.filter(item => !item.forceDisable);
    }

    async fetchRole() {
        this.loading = true;
        try {
            const { data } = await SettingsWebApi.getSettingsRole({ id: this.$route.params.id });
            this.role = data.role;
            this.name = data.role.name;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async removeRole() {
        this.loading = true;
        try {
            await SettingsWebApi.deleteSettingsRole({ id: this.$route.params.id });
            this.isDeletePopupShow = false;
            this.sentNotif(this.$t('SETTINGS.ROLE.DELETE.SUCCESSFULLY'), true);
            this.timeout = window.setTimeout(() => {
                this.$router.push({ path: `/settings/roles` });
            }, 200);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async saveRole() {
        if (this.isDisabled) return;
        this.isRequestSending = true;
        if (this.$route.params.id) {
            try {
                const { data } = await SettingsWebApi.updateSettingsRole(this.preparedRolePayload, this.name, this.$route.params.id);
                this.role = data.role;
                this.sentNotif(this.$t('SETTINGS.ROLE.UPDATE.SUCCESSFULLY'), true);
                this.prepareRoleToView();
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        } else {
            try {
                const { data } = await SettingsWebApi.addSettingsRole(this.preparedRolePayload, this.name);
                this.role = data.role;
                this.sentNotif(this.$t('SETTINGS.ROLE.CREATE.SUCCESSFULLY'), true);
                this.timeout = window.setTimeout(() => {
                    this.$router.push({ path: `/settings/roles` });
                }, 200);
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        }
    }

    rightsUpdate(rights: RoleRightType, key: string) {
        if (this.role) {
            Vue.set(this.role, key, rights);
        } else {
            const currentState = this.availableRightsKeys[key];
            Vue.set(this.availableRightsKeys, key, { ...currentState, ...{ rights }});
        }
    }

    input(name: string) {
        this.addCurrentInputToValidateArray(name);
        this.clearServerErrorsBase(name);
    }

    prepareRoleToView() {
        this.calcAvailableRightsKeys();
        this.startPreparedRolePayload = clone(this.preparedRolePayload);
    }

    calcAvailableRightsKeys() {
        const isPartnerFlow = this.user().account_kind === 'partner';
        const isIndividualFlow = this.user().account_kind === 'individual';
        const isServicePointAndHubs = this.user().account_organization_type === 'with_service_points_and_hubs';

        let calendarEntityRights: string = '';
        if (isPartnerFlow) {
            calendarEntityRights = 'SETTINGS.ROLE.RIGHTS.COMPANY_CALENDAR';
        } else if (this.user().account_organization_type === 'with_all') {
            calendarEntityRights = 'SETTINGS.ROLE.RIGHTS.HUB_BRANCH_CALENDAR';
        } else if (this.user().account_organization_type === 'with_hubs') {
            calendarEntityRights = 'SETTINGS.ROLE.RIGHTS.HUB_CALENDAR';
        } else if (this.user().account_organization_type === 'with_branches') {
            calendarEntityRights = 'SETTINGS.ROLE.RIGHTS.BRANCH_CALENDAR';
        } else {
            calendarEntityRights = 'SETTINGS.ROLE.RIGHTS.CALENDAR';
        }
        this.availableRightsKeys = {
            // BOOKING
            appointment_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.APPOINTMENT'),
                rights: baseRightsData(),
                group: BOOKING_FLOW_GROUP,
                disabledSwitchers: switchersBase(),
            },
            anonymise_calendar_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.ANONYMOUS_CALENDAR'),
                rights: baseRightsData(),
                group: BOOKING_FLOW_GROUP,
                disabledSwitchers: switchersOnlyShow(),
            },
            // ONBOARDING
            onboarding_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.ONBOARDING'),
                rights: baseRightsData(),
                group: ONBOARDING_GROUP,
                disabledSwitchers: switchersOnlyCreate(),
            },
            // RESOURCES
            calendar_entity_rights: {
                title: i18n.tc(calendarEntityRights),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                disabledSwitchers: switchersOnlyRead(),
            },
            service_point_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.SERVICE_POINTS'),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                disabledSwitchers: switchersBase(),
                forceDisable: !this.servicePointSupport,
            },
            shift_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.SHIFTS'),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                disabledSwitchers: switchersBase(),
                forceDisable: !this.servicePointSupport,
            },
            calendar_profile_rights: {
                title: i18n.tc(isPartnerFlow ? 'SETTINGS.ROLE.RIGHTS.TECHNICIAN.CALENDAR' : 'SETTINGS.ROLE.RIGHTS.ADVISOR.CALENDAR'),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                disabledSwitchers: switchersOnlyShow(),
            },
            // - in partner flow disabled this list
            branch_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.BRANCH'),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                forceDisable: isPartnerFlow || this.isHubsFlow || isServicePointAndHubs,
                disabledSwitchers: switchersBase(),
            },
            hub_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.HUB'),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                forceDisable: isPartnerFlow || this.isBranchesFlow,
                disabledSwitchers: switchersBase(),
            },
            advisor_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.ADVISOR'),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                forceDisable: isPartnerFlow,
                disabledSwitchers: switchersBase(),
            },

            // - in individual flow disabled this list
            technician_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.TECHNICIAN'),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                forceDisable: isIndividualFlow,
                disabledSwitchers: switchersBase(),
            },
            company_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.COMPANY'),
                rights: baseRightsData(),
                group: RESOURCES_GROUP,
                forceDisable: isIndividualFlow,
                disabledSwitchers: switchersBase(),
            },

            // CONFIGURATION
            setting_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.SETTINGS'),
                rights: baseRightsData(),
                group: CONFIGURATION_GROUP,
                disabledSwitchers: switchersOnlyShow(),
            },
            account_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.ACCOUNT'),
                rights: baseRightsData(),
                group: CONFIGURATION_GROUP,
                disabledSwitchers: switchersShowAndEdit(),

            },
            manager_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.MANAGER'),
                rights: baseRightsData(),
                group: CONFIGURATION_GROUP,
                disabledSwitchers: switchersBase(),
            },
            right_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.RIGHTS'),
                rights: baseRightsData(),
                group: CONFIGURATION_GROUP,
                disabledSwitchers: switchersBase(),
            },
            appointment_category_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.APPOINTMENT_CATEGORY'),
                rights: baseRightsData(),
                group: CONFIGURATION_GROUP,
                disabledSwitchers: switchersBase(),
            },
            appointment_notification_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.APPOINTMENT_NOTIFICATION'),
                rights: baseRightsData(),
                group: CONFIGURATION_GROUP,
                disabledSwitchers: switchersCanNotCD(),
            },
            calendar_view_setting_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.CALENDAR.VIEW'),
                rights: baseRightsData(),
                group: CONFIGURATION_GROUP,
                disabledSwitchers: switchersShowAndEdit(),
            },
            kpis_rights: {
                title: i18n.tc('SETTINGS.ROLE.RIGHTS.KPI'),
                rights: baseRightsData(),
                group: CONFIGURATION_GROUP,
                disabledSwitchers: switchersShowAndCreate(),
            },
        };
    }

    async created() {
        this.loading = true;
        if (this.$route.params.id) {
            await this.fetchRole();
        }
        this.prepareRoleToView();
        this.loading = false;
    }
};
</script>

<style lang='sass'>
.fw-switcher--active .fw-switcher__slider
    background-color: #27dbbd !important

.b-settings-role
    &__block
        margin-top: 15px
        display: flex
        flex-direction: column

        .multiselect__placeholder
            .h-blue-color
                font-size: 16px
                font-weight: 500
                color: #213F6B
                opacity: 0.5

        .fw-switcher__slider
            background-color: #F0F2F5

        .fw-switcher--disabled
            .fw-switcher__slider
                background-color: #a9a9a9 !important
                cursor: not-allowed

            .fw-switcher__circle
                background-color: #fff

        &__header
            position: sticky
            top: -40px
            z-index: 3

            &__inner
                background-color: #F8F9FA
                padding: 10px
                border-radius: 4px
                box-shadow: $big-box-shadow

    &__title
        font-size: 16px
        color: #213F6B
        font-weight: 700
        &--upper
            text-transform: uppercase

    &__title-main
        font-size: 20px
        color: #213F6B
        font-weight: 500
        &--upper
            text-transform: uppercase

    &__row
        display: flex
        align-items: center
        color: #203f6a
        font-weight: 500
        padding: 20px 20px 19px
        background-color: #fff
        box-shadow: $big-box-shadow
        border-radius: 10px

    &__group__title
        font-size: 20px
        font-weight: 500
        margin-top: 15px
        margin-bottom: 7px
        padding-left: 20px

.b-settings-role--button
    .b-back-button
        position: relative!important
</style>
