<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        class='h-pos-rel'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='540px'
        width='540px'
        :padding='isMobile() ? "30px 20px" : "50px 90px 45px 90px"'
        :title='$t("CONSULT.CLOSURES")'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <ClosuresCalendar
                :id='id'
                :readonly='readonly'
                :type='type'>
            </ClosuresCalendar>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ClosuresCalendar } from '@/components/common/ClosuresCalendar';

@Component({
    components: {
        ClosuresCalendar,
    },
})

export default class AddClosuresPopup extends Vue {
    @Prop({ type: String, required: true }) readonly id!: string;
    @Prop({ type: String, required: true }) readonly type!: string;
    @Prop({ type: Boolean, required: true }) readonly readonly !: boolean
}
</script>
