<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='450px'
        :title='$t(`RESET.PASSWORD.HEADER.CHANGE`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <ResetPasswordForm
                iconsWrapperClass='h-flex-dir-column h-flex'
                :disabledForm='false'
                submitText='BUTTON.SAVE'
                @onSubmit='sendDataToServer'>
            </ResetPasswordForm>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { User } from '@/types/User';
import { ResetPasswordForm } from '@/components/forms/edit/ResetPasswordForm';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { getUserFromStorage } from '@/helpers/auth';

const user: null | User = getUserFromStorage();
@Component({
    components: {
        ResetPasswordForm,
    },
})

export default class EditPopup extends Vue {
    @Prop({ type: String, default: 'Form title' }) readonly popupTitle!: string;

    sendDataToServer(data: any): void {
        if (user) {
            AuthWebApi.resetPassword(
                {
                    old_password: data.old_password,
                    new_password: data.user_password,
                },
                user.id
            ).then(response => {
                if (response.status === 200) {
                    this.$emit('close');
                    console.log('ok');
                }
            }).catch(({ response }) => {
                if (response.status === 401) {
                    console.log('some error');
                } else {
                    this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
                }
            });
        }
    }
}
</script>
