<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        class='h-pos-rel'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='750px'
        width='750px'
        :padding='isMobile() ? "30px 20px" : "35px"'
        :title='title'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <AddOrEditRoomForm
                :loading='isRequestSending'
                :isRequestSending='isRequestSending'
                :roomData='pageRoomData'
                :disabledByPermission='!canRemoveAndEdit'
                :errorFromServer='errorFromServer'
                @onSubmit='sendDataToServer'>
            </AddOrEditRoomForm>
        </template>
        <template v-if='roomData && isConsultBranch && canRemoveAndEdit' #footer>
            <div class='b-company-edit__delete qa-room-form-delete h-mt-20' @click='$emit("showDelete", pageRoomData.id)'>
                {{ $t('LABEL.DELETE') }}
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AddOrEditRoomForm } from '@/components/forms/add/AddOrEditRoomForm';
import { BranchRoomResponseType } from '@/types/Appointment';
import { BranchApi } from '@/api/branch/BranchApi';

@Component({
    components: {
        AddOrEditRoomForm,
    },
})

export default class AddEditRoomPopup extends Vue {
    @Prop({ type: Object, default: null }) readonly roomData!: BranchRoomResponseType;
    @Prop({ type: String, required: true }) readonly branch_id!: string;
    @Prop({ type: Boolean, default: true }) readonly canRemoveAndEdit!: boolean;

    isRequestSending: boolean = false;
    localRoomData: BranchRoomResponseType | null = null;

    async sendDataToServer(data: BranchRoomResponseType) {
        this.isRequestSending = true;
        try {
            if (this.pageRoomData) {
                const { site } = await BranchApi.updateRoom({ ...data, parent_id: this.branch_id }, this.pageRoomData.id);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
                this.$emit('update', site);
            } else {
                const { site } = await BranchApi.createRoom({ ...data, parent_id: this.branch_id });
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
                this.$emit('update', site);
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    get title(): string | TranslateResult {
        if (!this.canRemoveAndEdit) {
            return this.$i18n.t('BRANCH.ROOM.TITLE');
        }
        return this.pageRoomData ? this.$i18n.t('EDIT.ROOM.TITLE') : this.$i18n.t('ADD.ROOM.TITLE');
    }

    get pageRoomData() {
        return this.localRoomData || this.roomData;
    }
    get isConsultBranch() {
        return this.$route.meta && this.$route.meta.isConsultBranch;
    }
}
</script>
<style lang='sass'>
@include media('<=desktop')
    .fw-popup
        width: 100%!important
</style>
