import { render, staticRenderFns } from "./AdvisorAvatar.vue?vue&type=template&id=63fa97ba"
import script from "./AdvisorAvatar.vue?vue&type=script&lang=ts"
export * from "./AdvisorAvatar.vue?vue&type=script&lang=ts"
import style0 from "./AdvisorAvatar.vue?vue&type=style&index=0&id=63fa97ba&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports