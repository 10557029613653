export default {
    'UPDATE.TITLE.POPUP': 'When should be working hours be updated ?',
    'UPDATE.TITLE.POPUP.SCHEDULED.UPDATE': 'Scheduled update',
    'UPDATE.WILL.BE.UPDATE': 'Working hours will be updated on',
    'UPDATE.TITLE.POPUP.CANCEL': 'Cancel update',
    'UPDATE.TITLE.POPUP.CANCEL.QUESTION': 'Do you really want to cancel this update ?',
    'UPDATE.TITLE.POPUP.CANCEL.YES': 'Yes, cancel',
    'UPDATE.TITLE.POPUP.CANCEL.BACK': 'go back',
    'UPDATE.TITLE.ALREADY.UPDATED': 'An update is already scheduled. Please cancel it before scheduling a new one.',

    'UPDATE.ADDRESS.WHEN.TITLE': 'When should the address be updated ?',
    'UPDATE.ADDRESS.DESCRIPTION': 'Address will be updated to',
    'UPDATE.ADDRESS.EDIT.TITLE': 'Edit address',
    'UPDATE.ADDRESS.ON': 'on',
    'UPDATE.ADDRESS.ALREADY.UPDATED': 'An address update is already scheduled. Please cancel it before scheduling a new one.',
};
