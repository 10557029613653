import axios from 'axios';
import {
    CompanyPayloadType,
    CompanyData,
    GetAccountOnboardingCompaniesResponseType,
} from '@/types/Companies';
import { updateUrlGetParams } from '@/helpers/url';
import WebApi from '../WebApi';

class AccountOnboarding extends WebApi {
    async getAccountOnboardingCompanies({ page, per_page }: { page: number, per_page: number }): Promise<GetAccountOnboardingCompaniesResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/account_onboarding/companies`, {
            'pagination[per_page]': per_page,
            'pagination[page]': page,
        });
        const { data } = await axios.get(decodeURI(url));
        return data;
    }

    async getAccountOnboardingCompany(id: string): Promise<{ company: CompanyData }> {
        const { data } = await axios.get(`${this.urlPrefix}/account_onboarding/companies/${id}`);
        return data;
    }

    async postAccountOnboardingCompany(company: CompanyPayloadType) {
        return axios.post(`${this.urlPrefix}/account_onboarding/companies`, { company },);
    }

    async putAccountOnboardingCompany(company: CompanyPayloadType, id: string) {
        return axios.put(`${this.urlPrefix}/account_onboarding/companies/${id}`, { company },);
    }

    async changeCompanyData(company: CompanyPayloadType, id: string) {
        return axios.put(`${this.urlPrefix}/companies/${id}`, { company },);
    }

    async deleteAccountOnboardingCompany(id: string) {
        return axios.delete(`${this.urlPrefix}/account_onboarding/companies/${id}`);
    }
}

const accountOnboarding = new AccountOnboarding();

export {
    accountOnboarding as AccountOnboarding,
};
