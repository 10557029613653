<template>
<div class='b-final-steps__wrapper container'>
    <div class='b-final-steps__working-hours'>
        <div class='b-final-steps__image-calendar'>
            <FwImage
                :height='`128px`'
                :src='requireImage("final-step-calizy.svg")'>
            </FwImage>
        </div>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title h-mb-10'>
                {{ $t('FINAL.STEPS.TITLE') }}
            </h2>
            <p class='h-font-18 h-mt-0'>
                {{ $t('FINAL.STEPS.ASIDE') }}
            </p>
        </div>
    </div>
    <div class='h-flex-center'>
        <div class='b-final-steps__worker-options-wrapper'>
            <h2 v-for='(item, index) in workingHourOptions'
                :key='index'
                class='h-font-22 h-text-center'>
                {{ item.number }} - {{ $t(item.text) }}
            </h2>
        </div>
    </div>
    <div class='h-flex-center h-pt-30'>
        <FwButton
            class='h-mt-10'
            borderRadiusType='small-border'
            size='little'
            :to='`${mainRouteGlobal}/working-hours/choose`'>
            {{ $t('ONBOARDING.BUTTON') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';

type WorkingHourOptionType = {
    number: number,
    text: string,
}

@Component
export default class FinalStepsConfirmInfo extends Vue {
    @Prop({ type: String, default: '' }) readonly workerAdvisorString!: string;
    workingHourOptions: Array<WorkingHourOptionType> = [
        {
            number: 1,
            text: `FINAL.STEPS.${this.workerAdvisorString}.OPTION.1`,
        },
        {
            number: 2,
            text: 'FINAL.STEPS.WORKERS.OPTION.2',
        },
    ];
}
</script>

<style lang='sass'>
.b-final-steps
    &__wrapper
        width: 800px
        margin-bottom: 50px

        @include media('<=phone')
            width: 100%

        .b-base-title__wrapper
            padding-top: 0

    &__working-hours
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column

    &__texts-inner
        display: flex
        flex-direction: column
        padding-left: 90px

    &__text-title
        font-size: 22px
        line-height: 30px
        font-weight: 600
        text-transform: uppercase

    &__image-calendar
        width: 180px
        margin-top: 90px
        margin-bottom: 20px

        img
            width: 100%

    &__button
        margin: 100px

    &__worker-options-wrapper
        width: 520px
        button
            width: 100%

    &__later
        color: rgba(33, 63, 107, 0.3) !important
        font-weight: 500 !important
</style>
