<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-booking-popup__wrapper'>
    <template v-if='isLoading'>
        <FwSpinLoader
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader h-width-100p h-height-100p'
            :isActive='isLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </template>
    <FwPopup
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        width='auto'
        title=''
        :style='{
            maxHeight: `90vh`,
            minWidth: `640px`,
            minHeight: isOnSiteFlow && isMethodStep ? `600px` : `auto`,
            position: isLoading ? `fixed` : `relative`,
            left: isLoading ? `100%` : `auto`,
            padding: `40px 50px`,
            opacity: isLoading ? 0 : 1,
            visibility: isLoading ? "hidden" : "visible",
        }'
        class='b-direct-appointment-popup'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='closeModal'>
        <template #body>
            <div class='h-flex h-flex-center'>
                <h2 class='h-font-22 h-mt-0 h-mb-0'>
                    {{ $t('LABEL.NEW.APPOINTMENT') }}
                </h2>
                <div class='b-info'>
                    <span class='h-ml-10 h-font-20'>
                        ⚠️
                    </span>
                    <div class='b-info-hover h-font-14 h-fw-500'>
                        {{ $t('APPOINTMENT.DIRECT.BOOK.HOVER.INFO') }}
                    </div>
                </div>
            </div>
            <div class='h-flex h-flex-center'>
                <BookingFormHead
                    class='qa-direct-appointment-date'
                    :date='appointmentStartDate'
                    width='auto'
                    :dates='dates'
                    :category='appointmentType'
                    :isCategoryShown='!(isFirstStep || isSelectCategoryStep)'>
                </BookingFormHead>
            </div>
            <div v-if='isFirstStep || isSelectCategoryStep || isMethodStep'
                 class='h-flex-dir-column h-flex-center'>
                <div class='b-separate-line'></div>
            </div>
            <ClientAppointmentForm
                v-if='isFirstStep'
                formClass='b-cancel-appointment__form'
                iconsWrapperClass='b-cancel-appointment__form__events'
                :date='appointmentStartDate'
                :client='null'
                :eventData='eventData'
                :canUpdateDisabledFields='true'
                @updateData='$emit(`updateData`)'
                @onSubmit='onSubmitClientDetails'
                @skipStep='skipStep'>
            </ClientAppointmentForm>
            <AddNewCategoriesForm
                v-else-if='isSelectCategoryStep'
                :appointmentCategories='appointmentCategories'
                :appointmentStartDate='appointmentStartDate'
                @fetchDirectBookingComponent='fetchDirectBookingComponent'
                @updateAppointmentPayload='updateAppointmentPayload'
                @backToFirstStep='backToFirstStep'
                @toMethodPage='toMethodPage'>
            </AddNewCategoriesForm>
            <DirectAppointmentSelectLocationPage
                v-else-if='isMethodStep'
                :locationMethod='locationMethod'
                formClass='b-cancel-appointment__form'
                @backToCategoriesStep='backToCategoriesStep'
                @toBookingForm='toBookingForm'>
            </DirectAppointmentSelectLocationPage>
            <DirectAppointmentSelectRoomPage
                v-else-if='isPrivateMethodStep'
                :roomsData='roomsData'
                @backToCategoriesStep='backToCategoriesStep'
                @toBookingForm='toBookingForm'>
            </DirectAppointmentSelectRoomPage>
            <NewAppointmentBookingForm
                v-else
                formClass='b-cancel-appointment__form'
                iconsWrapperClass='b-cancel-appointment__form__events'
                :eventData='eventData'
                :client='client'
                :post_code='post_code'
                :branchAddress='branchData'
                @onSubmit='onSubmit'
                @toMethodPage='backToMethodStep'
                @close='$emit(`close`)'>
            </NewAppointmentBookingForm>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { BookApi } from '@/api/book/BookApi';

import { BookNewAppointmentType, FormBookAppointmentType } from '@/types/Book';
import { AdvisorSkillType, ClientType } from '@/types/User';
import {
    AppointmentCategoryType,
    AppointmentOnSiteDataType,
    AppointmentRoomsType, NewAppointmentDataType,
} from '@/types/Appointment';

import { AddAppointmentForm } from '@/components/forms/add/AddAppointmentForm';
import { ClientAppointmentForm } from '@/components/forms/direct/ClientAppointmentForm';
import { AddNewCategoriesForm } from '@/components/forms/direct/AddNewCategoriesForm';
import { NewAppointmentBookingForm } from '@/components/forms/direct/NewAppointmentBookingForm';
import { DirectAppointmentSelectLocationPage } from '@/components/forms/direct/DirectAppointmentSelectLocationPage';
import { DirectAppointmentSelectRoomPage } from '@/components/forms/direct/DirectAppointmentSelectRoomPage';
import { BookingFormHead } from '@/components/forms/nodes/BookingFormHead';
import { BookingFormDistance } from '@/components/forms/nodes/BookingFormDistance';

import DateMixin from '@/mixins/dateMixin';
import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';
import { BranchFullDataResponseType } from '@/types/Branch';

const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');

@Component({
    components: {
        BookingFormHead,
        AddAppointmentForm,
        BookingFormDistance,
        AddNewCategoriesForm,
        ClientAppointmentForm,
        NewAppointmentBookingForm,
        DirectAppointmentSelectLocationPage,
        DirectAppointmentSelectRoomPage,
    },
})
export default class DirectAppointmentPopup extends Mixins(DateMixin, NoDataMixin, AppointmentMixin) {
    @AppointmentStore.State('appointmentRoom') appointmentRoom!: AppointmentRoomsType;
    @AppointmentStore.State('appointmentAddress') appointmentAddress!: string;
    @AppointmentStore.Mutation('setCurrentAppointmentKind') setCurrentAppointmentKind!: (appointmentKind: string) => void;
    @AppointmentStore.Mutation('setClientData') setClientData!: (client: ClientType | null) => void;
    @AppointmentStore.Mutation('clearAppointmentState') clearAppointmentState!: () => void;

    @Prop({ type: Date, default: null }) readonly appointmentStartDate!: Date;
    @Prop({ type: Date, default: null }) readonly eventData!: NewAppointmentDataType;

    isLoading: boolean = false;
    isFirstStep: boolean = true;
    isSelectCategoryStep: boolean = false;
    isMethodStep: boolean = false;
    isPrivateMethodStep: boolean = false;
    client: ClientType | null = null;
    appointmentData: BookNewAppointmentType | null = null;
    locationMethod: AdvisorSkillType | null = null;
    post_code: string = '';
    appointmentCategories: Array<AppointmentCategoryType> = [];
    roomsData: Array<AppointmentRoomsType> = [];
    bookedAppointment: null | AppointmentOnSiteDataType = null;
    appointmentCategory: null | AppointmentCategoryType = null;
    branchData: null | BranchFullDataResponseType = null;

    get dates(): Array<string> {
        return [
            this.getCurrentDateAsMinutesAMPM(this.appointmentStartDate as Date, false),
        ];
    }

    get isMethodPage() {
        if (!this.appointmentType) {
            return false;
        }

        return this.isOnSiteFlow || this.isOnPhoneFlow || this.isOnVideoFlow;
    }

    get isPrivateMethodPage() {
        if (!this.appointmentType) {
            return false;
        }

        return this.isBranchPrivateVideoFlow || this.isVideoconferenceFlow || this.isBranchByPhonePrivateFlow || this.isBranchByVideoPrivateFlow;
    }

    backToFirstStep() {
        this.isFirstStep = true;
        this.isSelectCategoryStep = false;
    }

    skipStep() {
        this.client = null;
        this.isFirstStep = false;
        this.isSelectCategoryStep = true;
    }

    updateAppointmentPayload(payload: BookNewAppointmentType) {
        this.appointmentData = payload;
    }

    toMethodPage() {
        if (this.isMethodPage) {
            this.isMethodStep = true;
            this.isSelectCategoryStep = false;
        } else if (this.isPrivateMethodPage) {
            this.isPrivateMethodStep = true;
            this.isSelectCategoryStep = false;
        } else {
            this.isMethodStep = false;
            this.isSelectCategoryStep = false;
        }
    }

    backToCategoriesStep() {
        this.isMethodStep = false;
        this.isSelectCategoryStep = true;
    }

    backToMethodStep() {
        if (this.isMethodPage) {
            this.isMethodStep = true;
        } else if (this.isPrivateMethodPage) {
            this.isPrivateMethodStep = true;
        } else {
            this.isMethodStep = false;
            this.isPrivateMethodStep = false;
            this.isSelectCategoryStep = true;
        }
    }

    closeModal() {
        this.$emit('close');
        this.clearAppointmentState();
        this.setCurrentAppointmentKind('');
    }

    async fetchDirectBookingComponent() {
        this.isLoading = true;
        const data = await AppointmentWebApi.getDirectBookingRoomsData(this.$route.params.id);
        this.appointmentCategories = data.categories;
        this.roomsData = data.rooms;
        if (!this.isOnPhoneFlow && !this.isOnVideoFlow && !this.isOnSiteFlow) {
            this.branchData = data.branch;
        }
        this.isLoading = false;
    }

    async onSubmit(payload: FormBookAppointmentType) {
        this.isLoading = true;
        try {
            const asideParams = {
                ...(this.client && this.client.id && { client_id: this.client.id }),
            };
            if (this.isOnPhoneFlow || this.isOnVideoFlow || this.isOnSiteFlow) {
                try {
                    this.bookedAppointment = await BookApi.bookDirectAppointment(
                        {
                            appointment_category_id: this.appointmentData!.appointment_category_id as string,
                            dt_start: this.appointmentData!.dt_start as string,
                            advisor_id: this.$route.params.id,
                            account_id: this.user().account_id,
                            category: this.appointmentType,
                            ...asideParams,
                            ...payload,
                        });
                    this.$emit('updateData', this.bookedAppointment);
                    this.sentNotif('APPOINTMENT.SUCCESS.TEXT', true);
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else if (this.isFaceToFaceFlow || this.isBranchByPhonePublicFlow || this.isBranchByVideoPublicFlow || this.isOnSiteInBranchFlow) {
                try {
                    this.bookedAppointment = await BookApi.bookDirectAppointment(
                        {
                            appointment_category_id: this.appointmentData!.appointment_category_id as string,
                            dt_start: this.appointmentData!.dt_start as string,
                            advisor_id: this.$route.params.id,
                            account_id: this.user().account_id,
                            category: this.appointmentType,
                            site_id: this.branchData!.id as string,
                            ...asideParams,
                            ...payload,
                        });
                    this.$emit('updateData', this.bookedAppointment);
                    this.sentNotif('APPOINTMENT.SUCCESS.TEXT', true);
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else if (
                this.isBranchPrivateVideoFlow ||
                this.isVideoconferenceFlow ||
                this.isBranchByPhonePrivateFlow ||
                this.isBranchByVideoPrivateFlow
            ) {
                try {
                    this.bookedAppointment = await BookApi.bookDirectAppointment(
                        {
                            appointment_category_id: this.appointmentData!.appointment_category_id as string,
                            dt_start: this.appointmentData!.dt_start as string,
                            advisor_id: this.$route.params.id,
                            site_id: this.appointmentRoom.id as string,
                            category: this.appointmentType,
                            ...asideParams,
                            ...payload,
                        }
                    );
                    this.$emit('updateData', this.bookedAppointment);
                    this.sentNotif('APPOINTMENT.SUCCESS.TEXT', true);
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            }
        } finally {
            this.isLoading = false;
        }
    }

    @Emit('onSubmitClientDetails')
    async onSubmitClientDetails(payload: BookNewAppointmentType) {
        let client = null;
        this.isLoading = true;
        try {
            if (payload && payload.customer_id) {
                client = await AppointmentWebApi.getClientDetails({ remote_client_id: payload.customer_id });
            } else if (payload && payload.client_email) {
                client = await AppointmentWebApi.getClientDetailsByEmail(payload.client_email);
            } else if (payload && payload.client_phone) {
                client = await AppointmentWebApi.getClientDetailsByPhone(payload.client_phone);
            }
        } catch ({ response }) {
            this.sentNotif('No client data');
        }
        if (!client) {
            this.sentNotif('No client data');
        } else {
            this.client = client;
            this.isFirstStep = false;
            this.isSelectCategoryStep = true;
        }
        this.isLoading = false;
    }

    async fetchAppointmentOnSiteData() {
        this.appointmentOnSiteData = await AppointmentWebApi.onSiteAppointments({
            account_id: this.user().account_id,
            appointment_category_id: this.appointmentData!.appointment_category_id as string,
            location: this.appointmentAddress,
            category: 'location_category_on_site',
        });
    }

    async toBookingForm() {
        this.isFirstStep = false;
        this.isMethodStep = false;
        this.isPrivateMethodStep = false;
        this.isSelectCategoryStep = false;
    }
}
</script>

<style lang="sass">
    .b-direct-appointment-popup
        .b-appointment-left-bar_form
            .multiselect__content-wrapper
                min-width: auto !important
                overflow-x: hidden !important
                .multiselect__option
                    text-overflow: ellipsis
                    white-space: nowrap
                    overflow: hidden
            .multiselect__content-wrapper
                max-width: 400px !important
            .fw-select-address
                .multiselect__content-wrapper
                    max-width: 100% !important
        .b-select-address
            .multiselect__content-wrapper
                top: 0 !important
                max-height: 270px !important
                width: 100% !important
        .b-separate-line
            margin: 25px 0 20px
            box-sizing: border-box
            height: 1px
            width: 200px
            border: 1px solid #213F6B
            opacity: 0.31
            transform: scaleX(-1)
        .b-info
            cursor: pointer
            &-hover
                box-shadow: 0 30px 11px -17px rgba(33, 63, 107, 0.05)
                max-width: 200px
                border-radius: 10px
                z-index: 1
                padding: 10px
                background: #fff
                position: absolute
                top: 70px
                right: 20px
                display: none
            &:hover
                .b-info-hover
                    display: block

</style>
