import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { format } from 'date-fns';
import { clone } from 'ramda';
import {
    WEEK_DAYS,
    formatAMPM,
    getDateAsIsoString,
    getPrettyStringDate,
    getDateWithDelayInDays,
    getCurrentDateAsHoursAMPM,
    getCurrentDateAsMinutesAMPM,
    getHoursAndMinutesFromAMPM,
    getCurrentDateAsMinutes,
    parseToUTCDate,
    getDayDateAtNight,
} from '@/helpers/dates';

@Component
export default class DateMixin extends Vue {
    get baseFormat() {
        return `E, dd MMM yyyy HH:mm:ss`;
    }

    getDate({ delta = 0, date }: { delta: number, date: Date | null }): Date {
        const localDate = date ? new Date(date.toString()) : new Date();
        localDate.setDate(localDate.getDate() + delta);
        return localDate;
    }

    getDay(date: Date) {
        return date.getDate();
    }

    getWeekDayMame(date: Date) {
        return WEEK_DAYS[date.getDay()];
    }

    getDateAsIsoString(date: Date): string {
        return getDateAsIsoString(date);
    }

    getPrettyStringDate(date: Date): string {
        return getPrettyStringDate(date, this.$i18n);
    }

    getDateWithDelayInDays(date: Date, delay: number): Date {
        return getDateWithDelayInDays(date, delay);
    }

    getDayDateAtNight(date?: Date): Date {
        return getDayDateAtNight(date);
    }

    getCurrentDateAsHoursAMPM(date: Date | null, useUTC = false) {
        return getCurrentDateAsHoursAMPM({ date, locale: this.$i18n.locale, useUTC });
    }

    getCurrentDateAsMinutesAMPM(date: Date | null, useUtc: boolean = true) {
        return getCurrentDateAsMinutesAMPM({ date, locale: this.$i18n.locale, useUTC: useUtc });
    }

    getCurrentDateAsMinutes(date: Date | null, useUtc: boolean = true) {
        return getCurrentDateAsMinutes({ date, locale: this.$i18n.locale, useUTC: useUtc });
    }

    formatAMPM(date: Date, useUTC = true) {
        return formatAMPM({ date, locale: this.$i18n.locale, useUTC });
    }

    formatDateAsString(date: Date): string {
        return format(date, this.baseFormat);
    }

    getHoursAndMinutesFromAMPM(ampm: string | TranslateResult) {
        // @ts-ignore-next-line
        return getHoursAndMinutesFromAMPM(ampm);
    }

    parseToUTCDate(dateAsString: string): Date {
        return parseToUTCDate(dateAsString);
    }

    getDayDateNearNight(date?: Date): Date {
        const localDate = date ? clone(date) : new Date();
        localDate.setHours(23, 59, 0, 0);
        return localDate;
    }

    getDayDateNearMorning(date?: Date): Date {
        const localDate = date ? clone(date) : new Date();
        localDate.setHours(0, 0, 1, 0);
        return localDate;
    }
};
