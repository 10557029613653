export default {
    'ADD.RESERVED.SLOT.FORM.TITLE': 'Add a slot',
    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.TITLE': 'Reserved slot',
    'ADD.RESERVED.SLOT.FORM.CHANGE.TITLE': 'Change the unavailability',
    'ADD.RESERVED.SLOT.FORM.ADD.SHIFT.TITLE': 'Add a shift',
    'ADD.RESERVED.SLOT.FORM.CHANGE.SHIFT.TITLE': 'Change the shift',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE': 'Add an unavailability',
    'ADD.RESERVED.SLOT.FORM.SET.AVAILABILITY.TITLE': 'Set an availability type',
    'ADD.RESERVED.SLOT.FORM.SET.AVAILABILITY.DEFAULT.TITLE': 'Available by default',
    'ADD.RESERVED.SLOT.FORM.SET.UNAVAILABILITY.DEFAULT.TITLE': 'Unavailable by default',
    'ADD.RESERVED.SLOT.FORM.AVAILABILITY.TITLE': 'Add an availability',
    'ADD.RESERVED.SLOT.FORM.AVAILABILITY.CHANGE.TITLE': 'Availability',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE.BASE': 'Past unavailability',
    'ADD.RESERVED.SLOT.FORM.SHIFT.TITLE.BASE': 'Past shift',
    'ADD.RESERVED.SLOT.FORM.ALL_DAY': 'ALL DAY',
    'ADD.RESERVED.SLOT.FORM.MORNING': 'MORNING',
    'ADD.RESERVED.SLOT.FORM.AFTERNOON': 'AFTERNOON',
    'ADD.RESERVED.SLOT.FORM.NO_RECURRENCE': 'NO RECURRENCE',
    'ADD.RESERVED.SLOT.FORM.END_RECURRENCE': 'END RECURRENCE',

    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.CHOOSE.TITLE': 'Consult reserved slots',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.CHOOSE.TITLE': 'Consult unavailabilities',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TEXT': 'AVAILABLE: The advisor is by default available for booking, within his working hours.' +
        ' To be unavailable, the advisor has to create unavailabilities or events in his calendar.',
    'ADD.RESERVED.SLOT.FORM.AVAILABILITY.TEXT': 'UNAVAILABLE: The advisor is by default unavailable for booking.' +
        ' To be available, the advisor has to create available days. The appointments will be booked within the working hours.',

    'ADD.RESERVED.SLOT.FORM.TO': 'to',

    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_DAY': 'EVERY DAY',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_WEEK': 'EVERY WEEK',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_MONTH': 'EVERY MONTH',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_YEAR': 'EVERY YEAR',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.CHOOSE_DAY.PLACEHOLDER': 'Choose a date',

    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.ANY': 'Week of the month',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.1': 'FIRST WEEK',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.2': 'SECOND WEEK',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.3': '3 WEEK',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.4': '4 WEEK',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.LAST_WEEK': 'LAST WEEK',

    'ADD.RESERVED.SLOT.FORM.TITLE.PLACEHOLDER': 'CHOOSE A TITLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.OTHER_COMPANY': 'OTHER COMPANY',
    'ADD.RESERVED.SLOT.FORM.TITLE.DAY_OFF': 'DAY OFF',
    'ADD.RESERVED.SLOT.FORM.TITLE.TRAINING': 'TRAINING',
    'ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE': 'UNAVAILABLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.AVAILABLE': 'AVAILABLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.CLOSURE': 'CLOSURE',

    'ADD.RESERVED.SLOT.FORM.TITLE.RECEPTION': 'RECEPTION',
    'ADD.RESERVED.SLOT.FORM.TITLE.ACTIVITIES': 'ACTIVITIES',
    'ADD.RESERVED.SLOT.FORM.TITLE.INTERACTION': 'INTERACTION',
    'ADD.RESERVED.SLOT.FORM.TITLE.OFF': 'OFF',

    'ADD.RESERVED.SLOT.ON_SITE.HINT': 'On site can\'t be used with other methods',
};
