<template>
<component
    :is='canRead ? `router-link` : `div`'
    :to='canRead ? { path: `/consult/hub/${hub.id}` } : false'
    class='b-hub-card__wrapper'>
    <FwSimpleCard
        class='b-hub-card'
        width='100%'
        height='auto'
        padding='10px 30px'
        :isActive='canRead'
        :isDisabled='!canRead'>
        <div class='h-flex h-flex-dir-column h-width-100p'>
            <div class='b-hub-card__title h-font-14'>
                {{ hub.name | capitalizeAll }}
            </div>
            <div class='b-hub-card__workers-wrapper h-mt-5'>
                <div v-if='hub.users' class='b-hub-card__workers-title h-font-12'>
                    <span>
                        {{ hub.users.length }}
                    </span>
                    <span v-if='hub.users.length !== 0' class='h-ml-5'>
                        {{ $tc('CHOOSE_MANAGE.COMMON.ADVISOR.PLURAL', hub.users.length) }}
                    </span>
                    <span v-else class='h-ml-5'>
                        {{ $tc('CHOOSE_MANAGE.COMMON.NO.ADVISOR.PLURAL', hub.users.length) }}
                    </span>
                </div>
            </div>
        </div>
        <div v-if='hub.users && hub.users.length !== 0' class='h-flex'>
            <div v-for='worker in workersPart'
                 class='b-hub-card__worker-inner'>
                <WorkerAvatar
                    :worker='worker'
                    class='b-hub-card__worker'
                    :avatarSize='23'
                    :hideInfo='true'
                    align='right'>
                </WorkerAvatar>
            </div>
        </div>
    </FwSimpleCard>
</component>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BranchHubAvatarsResponseType } from '@/types/Appointment';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import PermissionsMixin from '@/mixins/permissions';

const MAX_WORKERS_QUANTITY = 5;

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class HubsCard extends Mixins(PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly hub!: BranchHubAvatarsResponseType;
    get workersPart(): BranchHubAvatarsResponseType {
        let val = null;
        if (this.hub && this.hub.users) {
            val = (this.hub.users as any).slice(0, MAX_WORKERS_QUANTITY);
        }
        return val;
    }
}
</script>

<style lang='sass'>
$worker-size: 25px
$card-min-height: 70px

.b-hub-card
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    &__wrapper
        display: flex
        max-width: 395px
        flex: 0 0 50%
        min-height: $card-min-height
        align-items: center

        @include media('<=phone')
            flex: 1
            width: 100%

        .fw-simple-card-wrapper
            min-height: $card-min-height

    &__title
        font-weight: 600
        color: $alt-blue

    &__workers-wrapper
        display: flex
        align-items: center

    &__worker
        width: $worker-size
        height: $worker-size
        border-radius: 50%
        border: 1px solid #FFFFFF
        position: relative
        background-size: cover
        background-position: center
        background-color: lighten($main-green, 30)

    &__worker-inner
        width: calc($worker-size / 1.4)

    &__workers-title
        display: flex
        align-items: center
        font-weight: 300
        color: $main-gray
        text-transform: uppercase

@include media('<tablet')
    .b-hub-card
        width: 100%
        &__wrapper
            margin: 5px 0

</style>
