<template>
<div class='b-calendar-body__row'>
    <RouterLink
        :to='{ name: "ConsultAdvisorPage", params: { id: item.user_id || item.id }}'
        class='b-calendar-body__column'>
        <WorkerAvatar
            v-if='item'
            :worker='item'
            class='h-mr-10 h-flex-0-0-20'
            size='small'
            align='right'>
        </WorkerAvatar>
        <span class='b-calendar-body__name'>
            <template v-if='item.full_name'>
                {{ item.full_name | capitalizeAll }}
            </template>
            <template v-else-if='item.first_name || item.last_name'>
                {{ item.first_name || '' | capitalizeAll }} {{ item.last_name || '' | capitalizeAll }}
            </template>
        </span>
    </RouterLink>
    <CalendarCell v-for='(date, index) in daysInMonth'
                  :key='date'
                  :type='getType(item, date, index)'
                  :isColorblind='isColorblind'
                  @click.native='onCellClickHandler(item, index, date)'
                  @mouseover.native='setCurrentIndexHandler(index)'
                  @mouseleave.native='setCurrentIndexHandler(null)'>
    </CalendarCell>
    <template v-if='isAsideInfoShow && data && data.length'>
        <CalendarCell v-for='(data, index) in data'
                      :key='`aside-${index}`'
                      :value='data.value'
                      :isColorblind='isColorblind'
                      type='COUNT_TYPE'
                      class='b-calendar-body__cell--big'>
        </CalendarCell>
    </template>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { WorkerAvatar } from '@/components/simple/WorkerAvatar';
import { CalendarCell } from '@/components/calendar/CalendarCell';
import { CalendarMonthViewUserDataType, CellDataClickType } from '@/types/Calendar';
import { EMPTY_TYPE, SQUARE_TYPES_BY_NUMBER_INDEX } from '@/components/calendar/helpers';

@Component({
    components: {
        WorkerAvatar,
        CalendarCell,
    },
})
export default class CalendarRow extends Vue {
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Number, required: true }) readonly daysInMonth!: number;
    @Prop({ type: Object, required: true }) readonly item!: CalendarMonthViewUserDataType;
    @Prop({ type: Boolean, default: false }) readonly isAsideInfoShow!: boolean;
    @Prop({ type: Boolean, required: true }) readonly isColorblind!: boolean;
    @Prop({ type: Array, default: () => ([]) }) readonly data!: Array<{ id: string, value: number }>;

    getType(item: CalendarMonthViewUserDataType, date: number, index: number) {
        const currentKey = dayjs(this.date).set('date', date).format('YYYY-MM-DD');
        return item.dates_break_down ? SQUARE_TYPES_BY_NUMBER_INDEX[item.dates_break_down[currentKey]] : EMPTY_TYPE;
    }

    @Emit('setCurrentIndex')
    setCurrentIndexHandler(index: number | null) {
        return index;
    }

    @Emit('onCellClick')
    onCellClickHandler(item: CalendarMonthViewUserDataType, index: number, type: string): CellDataClickType {
        return {
            day_index: index,
            name: item.full_name,
            // @ts-ignore
            user_id: item.user_id || item.id,
            // @ts-ignore
            color: item.color,
            type,
            currentDate: dayjs(this.date).set('date', index + 1),
        };
    }
}
</script>

<style lang='sass'>
$border-type: 1px solid #E8ECF0

.b-calendar-body
    width: 100%

    &__row
        display: flex
        width: 100%
        border-bottom: $border-type
        height: 40px
        position: relative

        .b-page-wrapper--big-container &
            height: 38px

        &:hover
            .b-calendar-body__column
                background-color: #203f6a
                border-radius: 8px
                border: 2px solid #fff

            .b-calendar-body__name
                color: #fff

    &__column
        width: 410px
        flex: 0 0 410px
        border-right: $border-type
        display: flex
        align-items: center
        padding-left: 40px

    &__name
        font-size: 14px
        font-weight: 500
        margin-top: 2px

.h-flex-0-0-20
    flex: 0 0 20px
</style>
