// @ts-ignore
import { VNode } from 'vue';

export default function(Vue: any) {
    Vue.directive(`blur-loading`, {
        // @ts-ignore
        bind(element: HTMLElement, binding) {
            element.style.transition = `all 0.3s`;
        },
        // @ts-ignore
        update(element: HTMLElement, binding) {
            if (binding.value) {
                element.style.filter = `blur(2px)`;
                element.style.opacity = `0.6`;
            } else {
                element.style.filter = `blur(0px)`;
                element.style.opacity = `1`;
            }
        },
        inserted(element: HTMLElement) {
            element.style.filter = `blur(2px)`;
            element.style.opacity = `0`;
            setTimeout(() => {
                element.style.filter = `blur(0px)`;
                element.style.opacity = `1`;
            }, 0);
        },
    });

    Vue.directive(`focus`, {
        // @ts-ignore
        inserted(el: HTMLElement, binding) {
            if (binding.value) {
                if (el instanceof HTMLInputElement) {
                    el.focus();
                } else {
                    const innerInput = el.querySelector(`input`);
                    if (innerInput) {
                        innerInput.focus();
                    }
                }
            }
        },
    });

    Vue.directive(`on-esc-keydown`, {
        // @ts-ignore
        inserted(element: HTMLElement, binding) {
            if (binding.value) {
                const { callback } = binding.value;
                // @ts-ignore
                const keyboardListeners = event => {
                    if (event.keyCode === 27) {
                        callback();
                    }
                };
                window.addEventListener(`keydown`, keyboardListeners);
                element.$destroy = () => {
                    window.removeEventListener(`keydown`, keyboardListeners);
                };
            }
        },
        unbind(element: HTMLElement) {
            if (element.$destroy) {
                element.$destroy();
            }
        },
    });

    /* eslint-disable-next-line */
    const update = (el: HTMLElement, binding: any, vnode: VNode, oldVnode: VNode) => el.style.visibility = (binding.value) ? 'hidden' : '';
    Vue.directive('hide', {
        bind: update,
        update,
    });
}
