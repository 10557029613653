<template>
<FwHorizontalScroll
    :isPageLoaded='true'
    class='b-sub-accounts'
    slotWrapperClass='h-flex h-flex-dir-column h-flex-center'
    iconClass='h-opacity-1'
    newClass='fw-arrow-big-circle-fixed'
    :arrowBgWidth='null'
    colorIcon='#fff'
    btnLocation='between'>
    <template #content>
        <CalendarTable
            :titles='subAccountsTitleArray'
            :data='subAccounts'
            :bigIndexes='bigIndexes'
            maxWidth='100%'
            class='b-sub-accounts b-calendar__table__wrapper'>
            <div
                v-for='(item, i) in subAccounts'
                :key='i'
                class='b-calendar__table'>
                <div class='b-calendar__table__outer'>
                    <div class='b-calendar__table__item'
                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(0) }'>
                        <span v-if='item.name'
                              class='b-calendar__table__item__inner'>
                            {{ item.name }}
                        </span>
                    </div>
                    <div class='b-calendar__table__item'
                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(1) }'>
                        <span v-if='item.account_id'
                              class='b-calendar__table__item__inner'>
                            {{ `${url}/accounts/${item.sc_account_id}/category` }}
                        </span>
                    </div>
                </div>
                <div class='b-calendar__table__buttons'>
                    <div class='b-calendar__table__item__delete h-pointer h-width-60'
                         @click='copyURL(item.sc_account_id)'>
                        <FwIcon
                            class='h-flex-center h-pointer'
                            icon='copy-document'
                            size='18'
                            color='rgba(33,63,107,.3)'>
                        </FwIcon>
                    </div>
                </div>
            </div>
        </CalendarTable>
    </template>
</FwHorizontalScroll>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import TranslateMixin from '@/mixins/TranslateMixin';

import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';

import i18n from '@/locale';

import { SubAccountType } from '@/types/Account';

const CommonStore = namespace('CommonStore');

@Component({
    components: {
        CalendarTableButtons,
        CalendarTable,
    },
})
export default class SubAccountsData extends Mixins(TranslateMixin) {
    @Prop({ type: Array, default: [] }) readonly subAccounts!: Array<SubAccountType>;

    bigIndexes: Array<number> = [0, 1];

    subAccountsTitleArray: Array<string> = [
        'LABEL.NAME',
        'LABEL.LINK',
    ].map(item => i18n.t(item));

    get url() {
        return process.env.NODE_ENV === 'production' && process.env.VUE_APP_DEPLOY_TYPE !== `staging` ?
            'https://client.calizy.com' :
            'https://client.staging.calizy.com';
    }

    async copyURL(id: string) {
        await navigator.clipboard.writeText(`${this.url}/accounts/${id}/category`);
        this.sentNotif(`SELFCARE.YOUR.BOOKING.LINK.COPY.TO.CLIPBOARD`, true);
    }
}
</script>

<style lang='sass'>
    .b-sub-accounts
        .b-calendar__table__inner
            max-width: 100%
            .b-calendar__table__item:not(:first-child)
                flex: 1 0 600px
                min-width: 600px
                max-width: 600px
            .b-calendar__table__header__item
                padding: 0 10px 15px 30px
        .fw-horizontal-scroll__inner, .b-calendar-page__height-wrapper
                height: auto!important
</style>
