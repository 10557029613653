<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-popup-with-sticky-close'>
    <FinalePopup
        v-if='finalePopupShow'
        :subMessage='$route.params.id && !isBranchHubHide ? $t("ADVISOR.FORM.UPDATE.POPUP.TEXT") : $t("ADVISOR.FORM.CREATE.POPUP.TEXT")'
        @close='closeAdvisorPopup'>
    </FinalePopup>
    <FwPopup
        v-show='!confirmPopup && !finalePopupShow'
        class='h-pos-rel'
        style='height: 90%;overflow-y: auto; overflow-x: hidden; justify-content: flex-start'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='1200px'
        width='auto'
        :padding='isMobile() ? "0px 20px 20px" : "35px 35px 0"'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#f4f5f6'
        popupRadius='20px'
        disableClosingOnClickOutside
        @close='closeAdvisorPopup'>
        <template #body>
            <ConsultBranchesHubsAddAdvisor
                v-show='!isGeograficPopup'
                :type='type'
                :parent_id='parent_id'
                :isBranchAdvisorAdd='isBranchAdvisorAdd'
                :isAddEditConsultAdvisors='isAddEditConsultAdvisors'
                :isBranchHubHide='isBranchHubHide'
                @showDeletePopup='showDeletePopup'
                @openGeograficPopup='openGeograficPopup'
                @closeAdvisorPopup='closeAdvisorPopup'>
            </ConsultBranchesHubsAddAdvisor>
            <AddEditGeograficPopup
                v-if='isGeograficPopup'
                @closePopup='closePopup'
                @close='isGeograficPopup = false'>
            </AddEditGeograficPopup>
        </template>
    </FwPopup>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.ADVISOR.TITLE`)'
        confirmQaClass='qa-advisor-card__confirm-delete'
        :subMessage='$t(`DELETE.APPOINTMENT.ADVISOR.TEXT`)'
        :isRequestSending='isRequestSending'
        @deleteItem='deleteAdvisor'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ConsultBranchesHubsAddAdvisor } from '@/components/forms/add/ConsultBranchesHubsAddAdvisor';
import { AddEditGeograficPopup } from '@/components/popups/AddEditGeograficPopup';
import { AdvisorsWebApi } from '@/api/workers/AdvisorsApi';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { workerDataType } from '@/types/User';

@Component({
    components: {
        ConsultBranchesHubsAddAdvisor,
        AddEditGeograficPopup,
        DeletePopup,
        FinalePopup,
    },
})
export default class AddEditAdvisorPopup extends Vue {
    @Prop({ type: Boolean, default: false }) readonly isBranchAdvisorAdd!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAddEditConsultAdvisors!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isBranchHubHide!: boolean;
    @Prop({ type: String, default: null }) readonly type!: string;
    @Prop({ type: String, default: null }) readonly parent_id!: string;

    isGeograficPopup: boolean = false;
    confirmPopup: boolean = false;
    isRequestSending: boolean = false;
    finalePopupShow: boolean = false;

    openGeograficPopup(): void {
        this.isGeograficPopup = true;
    }

    closePopup(): void {
        this.isGeograficPopup = false;
    }

    closeAdvisorPopup(data: workerDataType): void {
        // @ts-ignore-next-line
        return this.$emit('closePopup', data);
    }

    showDeletePopup(): void {
        this.confirmPopup = true;
    }

    async deleteAdvisor(): Promise<void> {
        this.isRequestSending = true;
        try {
            await AdvisorsWebApi.deleteAdvisor({
                id: this.$route.params.id,
            });
            this.confirmPopup = false;
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.$router.push({ path: '/consult/choose/advisors' });
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
}
</script>
<style lang='sass'>
@include media('<=desktop')
    .fw-popup
        width: 100% !important
</style>
