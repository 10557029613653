<template>
<div class='b-appointment-categories__wrapper'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div v-if='appointmentCategories.length' class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $t($route.meta.pageTitle) }}
            </h2>
            <p class='h-font-14 h-fw-500'>
                {{ $t('ONBOARDING.CHOOSE.CATEGORIES.SUBTEXT') }}
            </p>
            <AppointmentCategoryData
                :appointmentCategories='appointmentCategories'
                :appointmentsCategoriesTitleArray='appointmentsCategoriesTitleArray'
                @edit='editAppointment'
                @delete='deleteAppointment'>
            </AppointmentCategoryData>
            <FwPagination
                v-if='totalPage > perPage'
                style='position: sticky; bottom: 60px;'
                :totalPage='totalPage'
                :currentPage='currentPage'
                :perPage='perPage'
                :value='perPage'
                @getData='getAppointments'>
            </FwPagination>
        </div>
        <div v-else class='h-flex-dir-column h-flex-center h-mt-50'>
            <h1>
                {{ $t('ONBOARDING.NO.ITEMS.APPT') }}
            </h1>
            <FwButton
                class='h-ml-10 h-width-150'
                size='little'
                borderRadiusType='small-border'
                @click='addMoreAppointment'>
                {{ $t('BUTTON.ADD') }}
            </FwButton>
        </div>
    </template>

    <portal v-if='!isRecapRoute' to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                size='little'
                color='skip'
                borderRadiusType='small-border'
                @click.native='addMoreAppointment'>
                {{ $t('BUTTON.ADD.MORE') }}
            </FwButton>

            <FwButton
                class='h-ml-10 h-width-150'
                :to='$route.meta.next'
                size='little'
                :disabled='!appointmentCategories.length'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
    </portal>
    <portal v-else to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                :to='`${mainRouteGlobal}/recap`'
                :disabled='!appointmentCategories.length'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('Go to recap') }}
            </FwButton>
        </div>
    </portal>

    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.TYPE.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.TYPE.TEXT`)'
        @deleteItem='deleteItem'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import { DeletePopup } from '@/components/popups/DeletePopup';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { categories } from '@/helpers/categories';
import { duration } from '@/helpers/duration';
import i18n from '@/locale';
import { calculateNextPage } from '@/helpers/pagination';
import { AppointmentDataType, AppointmentCategoryType, pagePaginationData } from '@/types/Appointment';
import { AppointmentCategoryData } from '@/components/common/AppointmentCategoryData';

interface appointmentData {
    title: string,
    time: string,
    skills: Array<string>,
    location: Array<string>,
}

const AppointmentStore = namespace('AppointmentStore');

@Component({
    components: {
        DeletePopup,
        AppointmentCategoryData,
    },
})
export default class AppointmentCategories extends Mixins(ValidationMixin, TranslateMixin) {
    loading: boolean = false;
    confirmPopup: boolean = false;
    currentPage: number = 1;
    totalPage: number = 0;
    perPage: number = 6;
    appointmentCategories: Array<AppointmentCategoryType> = [];
    duration: Array<{ duration: number, title: string }> = duration;
    appointmentsCategoriesTitleArray: Array<string> = [
        'LABEL.CATEGORY',
        'LABEL.TITLE',
        'LABEL.TIME.TITLE',
        'LABEL.SKILLS.NEEDED',
        'LABEL.LOCATION.APPT',
    ].map(item => i18n.t(item));
    categories: Array<string> = categories.map(string => i18n.t(string));
    currentAppointment: AppointmentDataType | null = null;

    get mapping() {
        return {
            [i18n.t('LABEL.CATEGORY')]: `kind`,
            [i18n.t('LABEL.TITLE')]: `title`,
            [i18n.t('LABEL.TIME.TITLE')]: `duration`,
            [i18n.t('LABEL.SKILLS.NEEDED')]: `skills`,
            [i18n.t('LABEL.LOCATION.APPT')]: `location_skills`,
        };
    }

    addMoreAppointment() {
        this.$router.push({ path: `${this.mainRouteGlobal}/categories/add-category` });
    }

    editAppointment(appointment: AppointmentDataType) {
        if (this.isRecapRoute) {
            this.$router.push({ path: `${this.mainRouteGlobal}/recap/categories/add-category/${appointment.id}` });
        } else {
            this.$router.push({ path: `${this.mainRouteGlobal}/categories/add-category/${appointment.id}` });
        }
    }

    deleteAppointment(appointment: AppointmentDataType) {
        this.currentAppointment = appointment;
        this.confirmPopup = true;
    }

    async deleteItem() {
        if (this.currentAppointment) {
            try {
                await OnboardingApi.deleteOnboardingAppointmentCategories(this.currentAppointment.id);
                this.currentPage = calculateNextPage({ page: this.currentPage, per_page: this.perPage, total_items: this.totalPage });
                this.getAppointments(this.currentPage, this.perPage);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
                this.confirmPopup = false;
            } catch ({ response }) {
                this.sentNotif(response);
            }
        }
    }

    async getAppointments(el: number, val: number) {
        this.currentPage = el;
        const payload: pagePaginationData = {
            current_page: el,
            per_page: val,
        };
        this.loading = true;
        const data = await OnboardingApi.getOnboardingAppointmentCategories(payload);
        this.totalPage = data.meta.total_count;
        this.appointmentCategories = data.appointment_categories;
        this.loading = false;
    }

    async created() {
        this.getAppointments(this.currentPage, this.perPage);
    }
}
</script>

<style lang='sass'>
.b-appointment-categories__wrapper
    .fw-horizontal-scroll__inner
        height: auto !important
        padding-bottom: 100px
</style>
