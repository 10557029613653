import axios from 'axios';

import {
    EventsWorkersResponseType,
    ResrvedSlotsWorkersResponseType,
    UnavailabilityWorkersResponseType,
    AvailabilityWorkersResponseType,
    ServicePointAdvisorsResponseType,
    ShiftSlotsResponseType,
    ShiftResponseBaseData, ShiftResponseFullData,
} from '@/types/Events';
import {
    CreateEventPayload,
    CreateEventUnavailabilityType,
    CreateEventAvailabilityType,
} from '@/types/Events/Workers';
import { ReservedSlotsParamsType, UnavailabilityParamsType } from '@/types/Calendar';
import { updateUrlGetParams } from '@/helpers/url';
import {
    CreateShiftPayloadType,
    GetShiftsSlotsForDatePayloadType,
    GetShiftsSlotsPayloadType,
} from '@/types/ServicePoint';
import WebApi from '../WebApi';

class EventsWebApi extends WebApi {
    async getServicePointAdvisors({ page = 1 }: { page?: number }): Promise<ServicePointAdvisorsResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/service_point_advisors`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
        });

        const { data } = await axios.get(decodeURI(url));
        return data;
    };

    async getShiftsSlots(
        { from, sp_id, page = 1, location, advisor_id }: GetShiftsSlotsPayloadType
    ): Promise<ShiftSlotsResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/service_point_shifts/dates`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
            'dt': from,
            sp_id,
            ...(advisor_id && { advisor_id }),
        });

        const { data } = await axios.get(decodeURI(url));
        return data;
    };

    async getShiftsSlotsForDate(
        { dt, from, to, sp_id, page = 1, advisor_id }: GetShiftsSlotsForDatePayloadType
    ): Promise<ShiftSlotsResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/service_point_shifts`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
            ...(dt && { dt }),
            ...(from && { from }),
            ...(to && { to }),
            sp_id,
            ...(advisor_id && { advisor_id }),
        });

        const { data } = await axios.get(decodeURI(url));
        return data;
    };

    async getReservedSlots(
        { from, to, user_id, page = 1, location }: ReservedSlotsParamsType
    ): Promise<ResrvedSlotsWorkersResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/reserved_slots`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
            to,
            from,
            user_id,
            location,
        });

        const { data } = await axios.get(decodeURI(url));
        return data;
    };

    async getUnavailabilities(
        { from, to, user_id, page = 1 }: UnavailabilityParamsType
    ): Promise<UnavailabilityWorkersResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/unavailabilities`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
            to,
            from,
            user_id,
        });

        const { data } = await axios.get(decodeURI(url));
        return data;
    };

    async getAvailabilities(
        { from, to, user_id, page = 1 }: UnavailabilityParamsType
    ): Promise<AvailabilityWorkersResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/flexible_dates`, {
            'pagination[per_page]': 31,
            'pagination[page]': page,
            to,
            from,
            user_id,
        });

        const { data } = await axios.get(decodeURI(url));
        return data;
    };

    async getEvent(id: string): Promise<{ event: CreateEventUnavailabilityType }> {
        const { data } = await axios.get(`${this.urlPrefix}/events/${id}`);
        return data;
    };

    async createEvent(payload: CreateEventPayload): Promise<CreateEventUnavailabilityType> {
        const url: string = payload.origin === `unavailability` ? `/events` : `/reserved_slots`;
        const { data } = await axios.post(`${this.urlPrefix}${url}`, { 'event': payload });
        return data;
    };

    async createAvailabilityEvent(payload: CreateEventAvailabilityType): Promise<CreateEventAvailabilityType> {
        const { data } = await axios.post(`${this.urlPrefix}/flexible_dates`, { 'flexible_date': payload });
        return data;
    };

    async createShiftEvent(shift: CreateShiftPayloadType): Promise<ShiftResponseFullData> {
        const { data } = await axios.post(`${this.urlPrefix}/shifts`, { shift });
        return data;
    };

    async updateShiftEvent(shift: CreateShiftPayloadType, id: string): Promise<ShiftResponseFullData> {
        const { data } = await axios.put(`${this.urlPrefix}/shifts/${id}`, { shift });
        return data;
    };

    async getFullShiftData(id: string): Promise<ShiftResponseFullData> {
        const { data } = await axios.get(`${this.urlPrefix}/shifts/${id}`);
        return data.shift;
    };

    async getFullShiftRecurrenceData(id: string): Promise<ShiftResponseFullData> {
        const { data } = await axios.get(`${this.urlPrefix}/recurrences/${id}`);
        return data.recurrence;
    };

    async deleteFullShiftData(id: string, scope: null | 'future' | 'all' = null): Promise<undefined> {
        const { data } = await axios.delete(updateUrlGetParams(`${this.urlPrefix}/shifts/${id}`, {
            ...(scope && { scope }),
        }));
        return data;
    };

    async updateEvent({ payload, id }: { payload: CreateEventPayload, id: string }): Promise<CreateEventUnavailabilityType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/events/${id}`,
            {
                'event': payload,
            },
        );
        return data;
    };

    async deleteEvent(id: string): Promise<EventsWorkersResponseType> {
        const { data } = await axios.delete(`${this.urlPrefix}/events/${id}`);
        return data;
    };

    async deleteCalendarInstance(id: string, future?: boolean): Promise<undefined> {
        const params: { [key: string]: string } = {};
        if (future) {
            params.scope = `future`;
        }
        const { data } = await axios.delete(updateUrlGetParams(`${this.urlPrefix}/calendar_instances/${id}`, params));
        return data;
    };

    async deleteCalendarAvailabilityInstance(id: string): Promise<undefined> {
        const { data } = await axios.delete(`${this.urlPrefix}/flexible_dates/${id}`);
        return data;
    };

    async deleteCalendarAvailabilities(user_id: string): Promise<undefined> {
        const { data } = await axios.post(`${this.urlPrefix}/flexible_dates/bulk_delete`, { user_id });
        return data;
    };
}

const eventsWebApi = new EventsWebApi();

export {
    eventsWebApi as EventsWebApi,
};
