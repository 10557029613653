<template>
<div class='b-profile-main h-flex h-flex-center'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else
         class='b-profile-container'>
        <div class='b-page-title
                    b-page-title--base'>
            <div class='h-flex-center'>
                <h1 v-if='userRole === `advisor`'>
                    {{ userData.first_name }} {{ userData.last_name }}
                </h1>
                <h1 v-else class='h-ml-30'>
                    {{ $t('CHOOSE_MANAGE.COMMON.NO.WORKER.PLURAL') }}
                </h1>
            </div>
            <div class='h-flex-center'>
                <FwButton
                    size='little'
                    borderRadiusType='small-border'
                    :disabled='disabled'
                    @click.prevent='submit'>
                    {{ $t('BUTTON.SAVE') }}
                </FwButton>
            </div>
        </div>
        <EditProfileForm
            :formSubmit='formSubmit'
            @submit='updateProfile'
            @isEqual='value => isEqual = value'>
            <ChooseSiteLang></ChooseSiteLang>
        </EditProfileForm>
        <div class='h-flex h-ph-15'>
            <div class='b-form-hint__wrapper'>
                <div class='b-form-hint'>
                    * {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
                </div>
            </div>
        </div>
        <div class='h-flex-center h-width-100p'>
            <span class='b-remove-text qa-logout-button'
                  @click='$router.push({ path: "/logout" })'>
                {{ $t('LOGOUT.BUTTON') }}
            </span>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { namespace, State } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import { AdvisorCard } from '@/components/simple/AdvisorCard';
import { ConsultCard } from '@/components/simple/ConsultCard';
import AuthMixin from '@/mixins/auth';
import { LangOption } from '@/types/Lang';
import { setStorageLocale } from '@/helpers/auth';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { EditProfileForm } from '@/components/forms/edit/EditProfileForm';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { ProfileFormDataType } from '@/types/Profile';
import { User } from '@/types/User';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { ChooseSiteLang } from '@/components/global/ChooseSiteLang';
import { SettingsRoleSimpleType } from '@/types/Settings';

const SettingsStore = namespace('SettingsStore');

@Component({
    components: {
        EditProfileForm,
        ConsultCard,
        AdvisorCard,
        ChooseSiteLang,
    },
})
export default class ProfileIndexPage extends Mixins(AuthMixin) {
    @State(state => state.SettingsStore.roles) roles!: Array<SettingsRoleSimpleType> | null;
    @SettingsStore.Mutation('setRoles') setRoles!: (roles: Array<SettingsRoleSimpleType>) => void;

    userRole: string = 'advisor';
    loading = false;
    isEqual = false;
    formSubmit = false;
    userData: User = this.user();

    get disabled(): boolean {
        return this.formSubmit || this.isEqual;
    }

    submit() {
        if (!this.disabled) {
            this.formSubmit = true;
        }
    }

    async updateProfile(payload: ProfileFormDataType) {
        try {
            const { data } = await WorkersWebApi.updateManager(payload, this.userData.id);
            this.mutationLoginUser(data.manager);
            this.sentNotif('SETTINGS.POPUP.UPDATED', true);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.formSubmit = false;
        }
    }

    async setStorageLocale(lang: LangOption) {
        const { data } = await AuthWebApi.editUserAccount(
            {
                locale: lang.value,
            },
            this.userData.id
        );
        this.setUser(Object.assign(this.user(), { locale: lang.value }));
        setStorageLocale(lang.value);
        // @ts-ignore
        window.location.reload(true);
    }

    logOut() {
        this.loading = true;
        this.unLoginUserAndRedirect();
    }

    async fetchRoles() {
        this.loading = true;
        try {
            const roles = await SettingsWebApi.getSettingsRoleNames();
            this.setRoles(roles);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async created() {
        if (!this.roles) {
            await this.fetchRoles();
        }
    }
}
</script>

<style lang='sass'>
.b-profile-main
    .b-profile-container
        width: 910px
        max-width: 90%

    @include media('<=phone')
        margin: 0 15px
        padding: 0px 0px 60px

        .b-page-title
            padding: 20px 0

.b-remove-text
    font-size: 13px
    cursor: pointer
    color: #EB2767
    margin-top: 15px
    font-weight: 500
    text-align: center
    text-transform: uppercase
</style>
