<template>
<div class='b-final-steps__wrapper container'>
    <div class='b-final-steps__working-hours'>
        <div class='b-final-steps__image-calendar'>
            <FwImage
                :height='`128px`'
                :src='requireImage("calendar.svg")'>
            </FwImage>
        </div>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title h-mb-10'>
                {{ $t('FINAL.WORKING.HOURS.TITLE') }}
            </h2>
            <p class='h-font-14 h-mt-0'>
                {{ $t('FINAL.WORKING.HOURS.ASIDE') }}
            </p>
        </div>
    </div>
    <div class='h-flex-center'>
        <div class='b-final-steps__worker-options-wrapper'>
            <FwButton v-for='(item, index) in workingHourOptions'
                      :key='index'
                      size='large-xl'
                      color='choose'
                      borderRadiusType='tiny-border'
                      class='h-mt-20'
                      @click.native='item.method'>
                {{ $t(item.text) }}
                <FwIcon icon='arrow-right'
                        size='14'
                        color='#27dbbd'>
                </FwIcon>
            </FwButton>
        </div>
    </div>

    <div class='h-flex-center h-pt-30'>
        <FwButton
            class='h-m-20 b-final-steps__later'
            color='transparent'>
            {{ $t('BUTTON.LATER') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';

type WorkingHourOptionType = {
    text: string,
    method: Function
}

@Component
export default class FinalStepsWorkingHours extends Vue {
    @Prop({ type: String, default: '' }) readonly workerAdvisorString!: string;
    workingHourOptions: Array<WorkingHourOptionType> = [
        {
            text: `FINAL.WORKING.HOURS.${this.workerAdvisorString}.OPTION.1`,
            method: () => {},
        },
        {
            text: `FINAL.WORKING.HOURS.${this.workerAdvisorString}.OPTION.2`,
            method: () => {},
        },
    ];
}
</script>

<style lang='sass'>
.b-final-steps
    &__wrapper
        width: 800px
        margin-bottom: 50px

        @include media('<=phone')
            width: 100%

        .b-base-title__wrapper
            padding-top: 0

    &__working-hours
        display: flex
        align-items: center
        justify-content: center
        flex-direction: column

    &__texts-inner
        display: flex
        flex-direction: column
        padding-left: 90px

    &__text-title
        font-size: 22px
        line-height: 30px
        font-weight: 600
        text-transform: uppercase

    &__image-calendar
        width: 180px
        margin-top: 90px
        margin-bottom: 20px

        img
            width: 100%

    &__button
        margin: 100px

    &__worker-options-wrapper
        width: 520px
        button
            width: 100%

    &__later
        color: rgba(33, 63, 107, 0.3) !important
        font-weight: 500 !important
</style>
