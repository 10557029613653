<template>
<div class='h-mb-10 h-flex-center h-pt-15 b-booking-form-header'
     :class='iconsWrapperClass'>
    <div v-if='date'
         :class='iconsInnerClass'
         class='h-inline-flex h-mv-3 h-mh-15 h-flex-justify-end'>
        <FwIcon class='h-mh-5 h-mr-15 h-pointer h-flex-center'
                icon='calendar-confirmed'
                size='18'
                color='#213F6B'>
        </FwIcon>
        <span class='h-flex h-flex-center h-font-14 h-fw-500 qa-appointment-date-day'>
            {{ getPrettyStringDate(date) }}
            <template v-if='dateTo && getPrettyStringDate(date) !== getPrettyStringDate(dateTo)'>
                - {{ getPrettyStringDate(dateTo) }}
            </template>
        </span>
    </div>
    <div v-if='dates'
         :class='iconsInnerClass'
         class='h-inline-flex h-mv-3 h-mh-15'>
        <div v-if='dates'
             :class='iconsInnerClass'
             class='h-inline-flex h-mv-3'>
            <FwIcon
                class='h-mh-5 h-mr-15 h-pointer h-flex-center'
                icon='calendar-time'
                size='18'
                color='rgba(33,63,107)'>
            </FwIcon>
            <div class='h-flex h-flex-center'>
                <div v-for='(time, index) in dates'
                     :key='index'
                     class='b-appointment-left-bar__time'>
                    <span class='h-inline-block h-font-14 h-fw-500 qa-appointment-date-time'>
                        {{ time }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div v-if='category && isCategoryShown'
         class='h-flex h-flex-center h-font-14 h-fw-500 h-mv-3 h-mv-3 h-mh-15'>
        <FwIcon
            class='h-mh-5'
            :icon='currentMethodIcon'
            size='18'
            color='#203f6a'>
        </FwIcon>
        <span class='h-flex h-flex-center'>
            {{ currentMethodName }}
        </span>
    </div>
    <div v-if='selectedAdvisor && selectedAdvisor.name'
         class='h-flex h-flex-center h-font-14 h-fw-500 h-mv-3 h-mv-3 h-mh-15'>
        <FwIcon
            class='h-mh-5'
            icon='user'
            size='18'
            color='#203f6a'>
        </FwIcon>
        <span class='h-flex h-flex-center'>
            {{ selectedAdvisor.name }}
        </span>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Prop } from 'vue-property-decorator';
import DateMixin from '@/mixins/dateMixin';
import { appointmentLocationResponseData } from '@/mocks/events';
import i18n from '@/locale';
import { UserListType } from '@/types/User';

@Component
export default class BookingFormHead extends Mixins(DateMixin) {
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Date, default: null }) readonly dateTo!: Date | null;
    @Prop({ type: Array, default: null }) readonly dates!: Array<string> | null;
    @Prop({ type: String, default: null }) readonly iconsWrapperClass!: string;
    @Prop({ type: String, default: null }) readonly iconsInnerClass!: string;
    @Prop({ type: Boolean, default: false }) readonly isCategoryShown!: boolean;
    @Prop({ type: String, default: '200px' }) readonly width!: string;
    @Prop({ type: String, default: '200px' }) readonly rightWidth!: string;
    @Prop({ type: String, default: '200px' }) readonly category!: string;
    @Prop({ type: Object, required: true }) readonly selectedAdvisor!: UserListType | null;

    get currentMethodName() {
        // @ts-ignore-next-line
        return appointmentLocationResponseData[this.category][`${i18n.locale}_name`];
    }

    get currentMethodIcon() {
        // @ts-ignore-next-line
        return appointmentLocationResponseData[this.category].icon;
    }
}
</script>

<style lang='sass'>
.b-appointment-left-bar__time
    &:not(:first-of-type)
        &:before
            content: '-'
            margin: 0 3px

.b-booking-form-header
    @include media('<=phone')
        flex-direction: column
</style>
