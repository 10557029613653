<template>
<div class='container h-flex-center h-mt-40 h-fw-500'>
    AppointmentPartnerCompany
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class AppointmentPartnerCompany extends Vue {}
</script>

<style lang='sass'>

</style>
