export default {
    'LOGOUT.HEADER': 'Vos informations',
    'LOGOUT.BUTTON': 'Déconnexion',
    'LOGOUT.HEADER.TXT': 'Modifier mes informations personnelles',
    'LOGOUT.ROLE': 'Votre rôle',
    'LOGOUT.PHONE': 'Téléphone',
    'LOGOUT.EMAIL': 'Adresse email',
    'LOGOUT.PASSWORD': 'Mot de passe',
    'AUTH.LOG_OUT.TITLE': 'Se déconnecter de Calizy SAAS',
    'AUTH.LOG_OUT.ASIDE': 'Voulez-vous vraiment vous déconnecter ?',
    'AUTH.LOG_OUT.NOT.ACTIVE': 'Si vous vous déconnectez sans avoir créé de mot de passe, vous ne pourrez plus vous connecter avec cette adresse email',
    'AUTH.LOG_OUT.NO': 'Non',
    'AUTH.LOG_OUT.YES': 'Oui',
};
