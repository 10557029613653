<template>
<div class='h-flex'>
    <SettingsNav
        :title='$t("BRANCH.STATISTICS")'
        :accordionType='true'
        :updatePathParams='{ value: this.$route.params.id, field: `id` }'
        :asideText='hubStateData ? hubStateData.name : ""'
        showBackButton
        type='hub'
        :routes='childrenHubStatisticRoutes'>
    </SettingsNav>
    <div class='b-settings-router-view-wrapper'>
        <portal-target name='branch-statistic-header'></portal-target>
        <router-view
            :data='data'
            :ordering='ordering'
            :total='total'
            :loading='loading'
            :from='from'
            :to='to'
            :statistic='statistic'
            :tableLoading='tableLoading'
            :category_names='category_names'
            @search='search'
            @asRange='asRange'>
        </router-view>
    </div>
    <AccessLockPage v-if='accessLockStatisticPage'></AccessLockPage>
</div>
</template>

<script lang='ts'>
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import { Route } from 'vue-router';

import { format } from 'date-fns';
import { HubApi } from '@/api/hub/HubApi';

import { BranchKpiCategoryNamesType } from '@/types/Branch';
import { RouteType } from '@/types/router';
import { StatisticsKPIByCategoryType } from '@/types/Statistics';
import { BranchSitesDataType } from '@/types/Site';

import { SettingsNav } from '@/components/common/SettingsNav';
import AccessLockPage from '@/views/access/AccessLockPage.vue';

import PermissionsMixin from '@/mixins/permissions';

import { STATISTICS_KEY_MAPPING } from '@/routes/planAppointment/consts';
import childrenHubStatisticRoutes from '@/routes/planAppointment/childrenHubStatisticRoutes';

import { dateToString, getDateWithDelayInDays } from '@/helpers/dates';

const StatisticsStore = namespace('StatisticsStore');
const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        SettingsNav,
        AccessLockPage,
    },
})
export default class ConsultHubStatisticPage extends Mixins(PermissionsMixin) {
    @WorkerPageStore.State('hubStateData') hubStateData!: BranchSitesDataType;
    @State(state => state.StatisticsStore.dates) dates!: Array<Date>;
    @StatisticsStore.Mutation('setStatisticDates') setStatisticDates!: (statisticDates: Array<Date>) => void;

    childrenHubStatisticRoutes: Array<RouteType> = childrenHubStatisticRoutes;

    statistic: StatisticsKPIByCategoryType = {};
    category_names: Array<BranchKpiCategoryNamesType> = [];
    loading: boolean = false;
    tableLoading: boolean = false;
    data: { [key: string]: string } | null = null;
    total: number = 0;
    category_id: string = '';
    ordering: Array<string> = [];

    get baseFormat() {
        return `E, dd MMM yyyy`;
    }

    get fromStr(): string {
        return this.from ? format(this.from, this.baseFormat) : ``;
    }

    get toStr(): string {
        return this.to ? format(this.to, this.baseFormat) : ``;
    }

    get from(): Date {
        return this.dates[0];
    }

    get to(): Date {
        return this.dates[1];
    }

    asRange(dates: Array<Date>) {
        this.setStatisticDates(dates);
        this.getPageData();
    }

    async search(category: BranchKpiCategoryNamesType) {
        this.tableLoading = true;
        this.category_id = category.id;
        this.getPageData();
        const data = await HubApi.getBranchKpiAppointmentAdvisorByCategory(category.id, this.$route.params.id, this.fromStr, this.toStr);
        this.$set(this.statistic, category.title, data);
        if (!Object.values(data).length) {
            this.sentNotif('LABEL.HAVE.NOT.DATA.FOR.PERIOD');
        }
        this.tableLoading = false;
    }

    clear(): void {
        this.statistic = {};
    }

    async getPageData(route = this.$route) {
        this.loading = true;
        // @ts-ignore
        const data = await HubApi.getHubKpiData({
            // @ts-ignore-next-line
            hub_action: route.params.type,
            hub_id: route.params.id,
            category: route.meta ? route.meta.requestType : '',
            // @ts-ignore-next-line
            kpi_action: STATISTICS_KEY_MAPPING[route.meta.linkGroupName],
            from: this.fromStr,
            to: this.toStr,
            ...(this.category_id && { category_id: this.category_id }),
        });
        this.data = data.data;
        if (this.$route.meta && this.$route.meta.sortByKeyLength) {
            // @ts-ignore
            this.ordering = Object.keys(this.data).sort((a, b) => b.length - a.length);
        } else {
            // @ts-ignore
            this.ordering = Object.keys(this.data).sort((a, b) => {
                // @ts-ignore
                return this.$t(a).toLowerCase().localeCompare(this.$t(b).toLowerCase());
            });
        }
        this.loading = false;
    }

    async created() {
        if (!this.dates.length) {
            const todayNight: string = dateToString(new Date(), true);
            const copyDate = (time: string) => new Date(time);
            this.setStatisticDates([getDateWithDelayInDays(copyDate(todayNight), -7), copyDate(todayNight)]);
        }
        this.getPageData();
    }

    @Watch('$route')
    routeChange(to: Route) {
        this.getPageData(to);
    }
}
</script>

<style lang='sass'>
.b-settings-router-view-wrapper
    padding: 40px 95px 80px
    width: 100%
    max-height: calc(100vh - 90px)
    overflow: auto
    @include media('>phone', '<=desktop')
        padding: 30px 50px 60px !important

.b-settings__title
    font-size: 25px
    font-weight: bold
    display: flex
    justify-content: space-between
    width: 100%
    align-items: center
</style>
