import { CompanyWrapper } from '@/views/appointment/company/CompanyWrapper';
import { AppointmentWorkersAttached } from '@/views/appointment/company/AppointmentWorkersAttached';
import { AppointmentAdd } from '@/views/appointment/AppointmentAdd';
import { AppointmentCategories } from '@/views/appointment/AppointmentCategories';
import { AppointmentPartnerCompany } from '@/views/appointment/partners/AppointmentPartnerCompany';
import { AppointmentPartnerCompanies } from '@/views/appointment/partners/AppointmentPartnerCompanies';
import { AppointmentAddWorker } from '@/views/appointment/AppointmentAddWorker';
import { AppointmentBranchAdd } from '@/views/appointment/AppointmentBranchAdd';
import { AppointmentBranchAddRoom } from '@/views/appointment/AppointmentBranchAddRoom';
import { AppointmentBranchChooseBranch } from '@/views/appointment/AppointmentBranchChooseBranch';
import { AppointmentHubAddTeam } from '@/views/appointment/AppointmentHubAddTeam';
import { AppointmentBranchList } from '@/views/appointment/AppointmentBranchList';
import { AppointmentAdvisorsList } from '@/views/appointment/AppointmentAdvisorsList';
import { AppointmentHubTeams } from '@/views/appointment/AppointmentHubTeams';
import { RecapChoosePage } from '@/views/recap/RecapChoosePage';
import { FinalStepsConfirmInfo } from '@/views/final/FinalStepsConfirmInfo';
import { FinalStepsWorkingHours } from '@/views/final/FinalStepsWorkingHours';
import { OnboardingWorkingHoursChoosePage } from '@/views/onboarding/OnboardingWorkingHoursChoosePage';
import { OnboardingWorkingHoursPage } from '@/views/onboarding/OnboardingWorkingHoursPage';
import { OnboardingWorkersListWorkingHours } from '@/views/onboarding/OnboardingWorkersListWorkingHours';
import { OnboardingCalendarSyncPage } from '@/views/onboarding/OnboardingCalendarSyncPage';
import { OnboardingWorkersListCalendarSync } from '@/views/onboarding/OnboardingWorkersListCalendarSync';
import i18n from '@/locale';
import { RouteType } from '@/types/router';
import { ONBOARDING, INDIVIDUAL } from '@/helpers/rights';

const companyRoutes: Array<RouteType> = [
    {
        path: `/account-onboarding`,
        component: CompanyWrapper,
        name: `CompanyWrapper`,
        children: [
            // {
            //     path: '/account-onboarding/categories/choose-category',
            //     name: AppointmentChooseCategoryPage.name,
            //     component: AppointmentChooseCategoryPage,
            //     meta: {
            //         showAppHeader: true,
            //         backButton: true,
            //         breadCrumbsText: `BREADCRUMBS.APPOINTMENTS.TYPES`,
            //         pageTitle: i18n.tc('ONBOARDING.CHOOSE.CATEGORY.TITLE'),
            //     },
            // },
            {
                path: '/account-onboarding/categories/add-category',
                component: AppointmentAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.APPOINTMENTS.TYPES`,
                    breadCrumbsFlowKey: `base`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                    isNoKind: true,
                },
            },
            {
                path: '/account-onboarding/categories/add-category/:id',
                component: AppointmentAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.APPOINTMENTS.TYPES`,
                    breadCrumbsFlowKey: `base`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                    isNoKind: true,
                },
            },
            {
                path: '/account-onboarding/appointment/categories',
                component: AppointmentCategories,
                meta: {
                    showAppHeader: true,
                    breadCrumbsText: `BREADCRUMBS.APPOINTMENTS.TYPES`,
                    next: '/account-onboarding/choose-organization-kind',
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.CATEGORIES.HEADER'),
                    breadCrumbsFlowKey: `base`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                    isNoKind: true,
                },
            },
            {
                path: '/account-onboarding/choose-organization-kind',
                name: AppointmentWorkersAttached.name,
                component: AppointmentWorkersAttached,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.ORGANISATION`,
                    breadCrumbsFlowKey: 'base',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                    isNoKind: true,
                },
            },
            {
                path: '/account-onboarding/appointment/partner-company',
                name: AppointmentPartnerCompany.name,
                component: AppointmentPartnerCompany,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.ORGANISATION`,
                    breadCrumbsFlowKey: `base`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/appointment/partner-companies',
                name: AppointmentPartnerCompanies.name,
                component: AppointmentPartnerCompanies,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.ORGANISATION`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/appointment/add-a-team-both',
                component: AppointmentHubAddTeam,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    addTeamNextPostfix: '/appointment/team-both',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/appointment/team-both',
                component: AppointmentHubTeams,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    teamNextPostfix: `/advisors/add`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/hubs/add',
                component: AppointmentHubAddTeam,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS`,
                    hide: true,
                    addTeamNextPostfix: '/hubs',
                    breadCrumbsFlowKey: 'hubs',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/hubs/edit/:id',
                component: AppointmentHubAddTeam,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS`,
                    hide: true,
                    addTeamNextPostfix: '/hubs',
                    breadCrumbsFlowKey: 'hubs',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/hubs',
                component: AppointmentHubTeams,
                meta: {
                    showAppHeader: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS`,
                    hide: true,
                    teamNextPostfix: `/advisors/add`,
                    breadCrumbsFlowKey: 'hubs',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/hubs/add',
                component: AppointmentHubAddTeam,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    addTeamNextPostfix: '/both/hubs',
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/hubs/edit/:id',
                component: AppointmentHubAddTeam,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    addTeamNextPostfix: '/both/hubs',
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/hubs',
                component: AppointmentHubTeams,
                meta: {
                    showAppHeader: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    teamNextPostfix: '/both/branches/add',
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/branches',
                component: AppointmentBranchList,
                meta: {
                    showAppHeader: true,
                    breadCrumbsText: `BREADCRUMBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/advisors/add`,
                    breadCrumbsFlowKey: 'branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/branches/add',
                component: AppointmentBranchAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/branches/choose-branch`,
                    breadCrumbsFlowKey: 'branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/branches/edit/:id',
                component: AppointmentBranchAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/branches/choose-branch`,
                    breadCrumbsFlowKey: 'branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/branches/choose-branch',
                component: AppointmentBranchChooseBranch,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/branches`,
                    breadCrumbsFlowKey: 'branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/branches/add-rooms/:id',
                component: AppointmentBranchAddRoom,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/branches`,
                    breadCrumbsFlowKey: 'branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },

            {
                path: '/account-onboarding/both/branches',
                component: AppointmentBranchList,
                meta: {
                    showAppHeader: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/both/advisors/add`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/branches/add',
                component: AppointmentBranchAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/both/branches/choose-branch`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/branches/edit/:id',
                component: AppointmentBranchAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/both/branches/choose-branch`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/branches/choose-branch',
                component: AppointmentBranchChooseBranch,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/both/branches`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/branches/add-rooms/:id',
                component: AppointmentBranchAddRoom,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.HUBS.BRANCHES`,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/both/branches`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/advisors/add',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.ADVISORS`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/advisors/add-more',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.ADVISORS`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/appointment/edit-advisor/:id',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    next: '/account-onboarding/advisors',
                    // breadCrumbsText: `WORKERS.FORM.TITLE`,
                    breadCrumbsText: `BREADCRUMBS.ADVISORS`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/advisors',
                component: AppointmentAdvisorsList,
                meta: {
                    showAppHeader: true,
                    next: '/account-onboarding/recap',
                    breadCrumbsText: `BREADCRUMBS.ADVISORS`,
                    // breadCrumbsText: `WORKERS.FORM.TITLE`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },

            {
                path: '/account-onboarding/both/advisors/add',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.ADVISORS`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/advisors/add-more',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.ADVISORS`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/appointment/edit-advisor/:id',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    next: '/account-onboarding/both/advisors',
                    breadCrumbsText: `BREADCRUMBS.ADVISORS`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/both/advisors',
                component: AppointmentAdvisorsList,
                meta: {
                    showAppHeader: true,
                    next: '/account-onboarding/recap',
                    breadCrumbsText: `BREADCRUMBS.ADVISORS`,
                    breadCrumbsFlowKey: 'hubs-and-branches',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap',
                component: RecapChoosePage,
                meta: {
                    showAppHeader: true,
                    next: '/account-onboarding/final-steps',
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.RECAP`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },

            {
                path: '/account-onboarding/final-steps',
                component: FinalStepsConfirmInfo,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/working-hours/choose',
                component: OnboardingWorkingHoursChoosePage,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/final-steps/working-hours',
                component: FinalStepsWorkingHours,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/working-hours/all',
                component: OnboardingWorkingHoursPage,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/working-hours/list',
                component: OnboardingWorkersListWorkingHours,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/invitations/choose',
                component: OnboardingCalendarSyncPage,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/invitations/choose-advisors',
                component: OnboardingWorkersListCalendarSync,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/branches',
                component: AppointmentBranchList,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    hide: true,
                    next: '/recap',
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/branches/edit/:id',
                component: AppointmentBranchAdd,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    backButton: true,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/account-onboarding/recap/branches`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/branches/add-rooms/:id',
                component: AppointmentBranchAddRoom,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    backButton: true,
                    hide: true,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.BRANCH.TITLE'),
                    nextUrl: `/recap/branches`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/advisors',
                component: AppointmentAdvisorsList,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    hide: true,
                    next: '/recap',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/appointment/edit-advisor/:id',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    backButton: true,
                    hide: true,
                    next: '/account-onboarding/recap/advisors',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/hubs',
                component: AppointmentHubTeams,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    hide: true,
                    next: '/recap',
                    teamNextPostfix: `/recap/advisors`,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/hubs/edit/:id',
                component: AppointmentHubAddTeam,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    hide: true,
                    backButton: true,
                    addTeamNextPostfix: '/recap/hubs',
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/appointment/categories',
                component: AppointmentCategories,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    hide: true,
                    next: '/recap',
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.CATEGORIES.HEADER'),
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
            {
                path: '/account-onboarding/recap/categories/add-category/:id',
                component: AppointmentAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    kind: 'recap',
                    hide: true,
                    pageType: ONBOARDING,
                    pageKind: INDIVIDUAL,
                },
            },
        ],
    },
];

export default companyRoutes;
