<template>
<table v-if='data && data.types && data.types.length && data.data && data.data.length'
       class='b-statistic-table'>
    <thead>
        <tr>
            <th class='b-statistic-table__th'>
                {{ $t('LABEL.ID') }}
            </th>
            <th v-for='th in data.types'
                :key='th.key'
                class='b-statistic-table__th'>
                {{ $t(th.type) }}
            </th>
        </tr>
    </thead>
    <tbody>
        <tr v-for='tr in data.data'>
            <td class='b-statistic-table__td'>
                {{ $t(tr.text) }}
            </td>
            <td v-for='th in data.types'
                :key='th.key'
                class='b-statistic-table__td'>
                {{ tr.values[th.key] }}
            </td>
        </tr>
        <tr>
            <td class='b-statistic-table__td h-uppercase'>
                {{ $t('BRANCH.STATISTICS.TOTAL') }}
            </td>
            <td v-for='th in data.types'
                :key='th.key'
                class='b-statistic-table__td'>
                {{ calcTotal(th.key) }}
            </td>
        </tr>
    </tbody>
</table>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TableStatisticDataType } from '@/types/Statistics';

@Component
export default class StatisticTable extends Vue {
    @Prop({ type: Object, required: true }) readonly data!: TableStatisticDataType;

    calcTotal(key: string): number {
        if (!this.data || !this.data.data) {
            return 0;
        }
        return this.data.data.reduce((prev, next) => prev + next.values[key], 0);
    }
}
</script>

<style lang='sass'>
.b-statistic-table
    padding: 15px 27px
    background-color: #fff
    margin-top: 41px
    border-collapse: collapse

    &__th
        font-size: 12px
        color: #213F6B
        color: rgba(33, 63, 103, 0.3)
        text-transform: uppercase
        padding: 7px 0

        &:first-of-type
            text-align: left
            padding-left: 20px

        &:not(:first-of-type)
            padding: 12px 10px 3px
            max-width: 170px
            min-width: 140px

    &__td
        font-size: 14px
        padding: 12px 0

        &:first-of-type
            text-align: left
            font-weight: bold
            padding-left: 20px

        &:not(:first-of-type)
            text-align: center
            font-weight: 500
            padding: 10px
            max-width: 170px
            min-width: 140px

    tbody
        tr:not(:last-of-type)
            border-bottom: 1px solid rgba(151, 151, 151, 0.3)

.h-uppercase
    text-transform: uppercase
</style>
