<template>
<div class='h-flex h-flex-justify-start'>
    <div class='h-flex h-flex-dir-column'>
        <div class='h-flex'>
            <div class='h-flex h-flex-dir-column'>
                <div class='b-consult-card__sub-wrapper'
                     :class='wrapperClass'>
                    <div class='b-consult-card__sub-title'>
                        {{ $t('LOGOUT.ROLE') }}
                    </div>
                    <div class='b-consult-card__sub-tags'>
                        {{ userData.kind }}
                    </div>
                </div>
                <div class='b-consult-card__sub-wrapper'
                     :class='wrapperClass'>
                    <div class='b-consult-card__sub-title'>
                        {{ $t('LOGOUT.EMAIL') }}
                    </div>
                    <div class='b-consult-card__sub-tags'>
                        {{ userData.email }}
                    </div>
                </div>
            </div>
            <div class='h-flex h-flex-dir-column h-ml-50'>
                <div class='b-consult-card__sub-wrapper'
                     :class='wrapperClass'>
                    <div class='b-consult-card__sub-title'>
                        {{ $t('LOGOUT.PHONE') }}
                    </div>
                    <div class='b-consult-card__sub-tags'>
                        {{ userData.manager_profile.phone }}
                    </div>
                </div>
                <div class='b-consult-card__sub-wrapper'
                     :class='wrapperClass'>
                    <div class='b-consult-card__sub-title'>
                        {{ $t('LOGOUT.PASSWORD') }}
                    </div>
                    <div class='b-consult-card__sub-tags'>
                        ********
                    </div>
                </div>
            </div>
        </div>
        <div class='h-flex h-flex-dir-column h-mt-50 h-width-100p'>
            <div class='b-consult-card__sub-wrapper'
                 :class='wrapperClass'>
                <div class='b-consult-card__sub-title'>
                    {{ $t('LABEL.COLOR') }}
                </div>
                <div class='b-consult-card__sub-color' :style='{backgroundImage: userColor}'>
                </div>
            </div>
        </div>
    </div>
    <div class='b-profile-information__icon h-flex-center h-pointer h-bold h-pos-abs'
         @click='showEditPopup'>
        <FwIcon
            icon='edit-settings'
            size='24'
            color='#c6cdd9'>
        </FwIcon>
    </div>
    <EditUserPopup
        v-if='isEditPopup'
        :defaultFormValues='userData'
        :popupTitle='$t(`LOGOUT.HEADER.TXT`)'
        @close='showEditUserPopup(false)'>
    </EditUserPopup>
    <ResetPasswordPopup
        v-if='isResetPasswordPopup'
        :defaultFormValues='userData'
        :popupTitle='$t(`LOGOUT.HEADER.TXT`)'
        @close='showResetPasswordPopup(false)'>
    </ResetPasswordPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from '@/types/User';
import { EditUserPopup } from '@/components/popups/EditUserPopup';
import { ResetPasswordPopup } from '@/components/popups/ResetPasswordPopup';
import { separateColor, hexToRgbA } from '@/helpers/colors';

const GlobalStore = namespace('GlobalStore');

const CONSULT_CARD_TYPES: Array<string> = ['base', 'two-rows'];

@Component({
    components: {
        EditUserPopup,
        ResetPasswordPopup,
    },
})
export default class AdvisorCard extends Vue {
    @GlobalStore.Getter('isEditPopup') isEditPopup!: boolean;
    @GlobalStore.Getter('isResetPasswordPopup') isResetPasswordPopup!: boolean;
    @GlobalStore.Mutation('showEditUserPopup') showEditUserPopup!: Function;
    @GlobalStore.Mutation('showResetPasswordPopup') showResetPasswordPopup!: Function;
    @Prop({ type: Object, default: null }) readonly userData!: User;
    @Prop({ type: String, default: 'base', validator: (type: string) => CONSULT_CARD_TYPES.includes(type) })
    readonly type!: Array<string>;
    get wrapperClass(): string {
        return `b-consult-card__sub-wrapper--${this.type}`;
    }
    get userColor() {
        let result;
        if (this.userData.color) {
            const colorArray:Array<string> = separateColor(this.userData!.color!);
            result = `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
        } else {
            result = `linear-gradient(225deg, rgba(113, 143, 157, 0), rgba(105, 131, 143, 1))`;
        }
        return result;
    }
    showEditPopup() {
        this.showEditUserPopup(true);
    }
}
</script>

<style lang='sass'>
.b-consult-card
    box-shadow: $big-box-shadow

    &__sub-title
        font-weight: 500
        font-size: 16px

    &__sub-aside
        font-weight: 300
        font-size: 16px
        margin-top: 7px

        .b-consult-card__worker-arrow
            transition: .4s ease-in-out

        &:hover
            .b-consult-card__worker-arrow
                transform: translate(5px, 0)

    &__sub-wrapper
        margin-top: 10px

        &--two-rows
            flex: 0 0 50%

    &__sub-color
        width: 40px
        height: 20px
        border-radius: 5px
        margin-top: 5px

    &__sub-tags
        display: flex
        margin-top: 5px

    &__sub-tag
        background-color: $light-gray
        color: $alt-blue
        font-size: 14px
        font-weight: bold
        padding: 4px 11px
        border-radius: 3px

        &:not(:last-of-type)
            margin-right: 10px
</style>
