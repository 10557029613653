import ForbiddenNotManager from '@/views/forbidden/ForbiddenNotManager.vue';
import { RouteType } from '@/types/router';
import { FORBIDDEN } from '@/helpers/rights';

const ForbiddenRoutes: Array<RouteType> = [
    {
        path: '/forbidden/not-manager',
        name: ForbiddenNotManager.name,
        component: ForbiddenNotManager,
        meta: {
            showAppHeader: true,
            pageType: FORBIDDEN,
        },
    },
];

export default ForbiddenRoutes;
