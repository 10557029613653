import global from './global';
import plan from './plan';
import cancel from './cancel';
import form from './form';
import calendar from './calendar';
import consult from './consult';
import auth from './auth';
import settings from './settings';
import errors from './errors';
import onboarding from './onboarding';
import popups from './popups';
import worker from './worker';
import breadCrumbs from './breadCrumbs';
import hub from './hub';
import recap from './recap';
import final from './final';
import appointment from './appointment';
import managers from './managers';
import categories from './categories';
import forbidden from './forbidden';
import statistics from './statistics';
import servicePoint from './services-point';

export default {
    ...global,
    ...plan,
    ...cancel,
    ...form,
    ...calendar,
    ...consult,
    ...auth,
    ...settings,
    ...errors,
    ...onboarding,
    ...popups,
    ...worker,
    ...hub,
    ...recap,
    ...final,
    ...breadCrumbs,
    ...appointment,
    ...managers,
    ...categories,
    ...forbidden,
    ...statistics,
    ...servicePoint,
};
