export default {
    'SETTINGS.TEAM.TITLE': 'Your team',
    'SETTINGS.TEAM.RESOURCES.TITLE': 'Resources',
    'SETTINGS.TEAM.ROLES.TITLE': 'Roles',
    'SETTINGS.TEAM.ROLES.ADD': 'Add a new role',
    'SETTINGS.TEAM.ROLES.EXISTING': 'Existing roles',
    'SETTINGS.TEAM.PEOPLE.TITLE': 'People',
    'SETTINGS.TEAM.PEOPLE.ADD': 'Add someone',
    'SETTINGS.TEAM.PLACEHOLDER.SEARCH': 'Search by name',
    'SETTINGS.TEAM.PEOPLE.EXISTING': 'Existing people',
    'SETTINGS.TEAM.PEOPLE.RESULTS.PLURAL': 'Result | Results',
    'SETTINGS.TEAM.PEOPLE.OTHER': 'Others workers',
};
