<template>
<div class='b-settings-custom-page'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-settings__title'>
            {{ $t('SETTINGS.MENU.CUSTOMISATION') }}
        </div>

        <div class='b-settings-custom-page__wrapper'>
            <div class='b-settings-custom-page__row'>
                <div>
                    {{ $t('CUSTOMISATION.FEEDBACK') }}
                </div>
                <FwSwitcher
                    class='h-width-40 qa-appointment-feedback'
                    :isActive='isAppointmentFeedbackActive'
                    :isRequestSending='isRequestNotificationsSending'
                    @toggleSwitch='setNotificationSupport(!isAppointmentFeedbackActive)'>
                </FwSwitcher>
            </div>
            <div class='b-settings-custom-page__row'>
                <div>
                    {{ $t('CUSTOMISATION.UNAVAILABILITY') }}
                </div>
                <FwSwitcher
                    class='h-width-40 qa-create-unavailability'
                    :isActive='isCreateUnavailabilityActive'
                    :isRequestSending='isRequestUnavailabilitySending'
                    @toggleSwitch='setUnavailabilityProSupport(!isCreateUnavailabilityActive)'>
                </FwSwitcher>
            </div>
        </div>
    </template>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';
import { AccountType } from '@/types/Account';
import { SettingsWebApi } from '@/api/settings/SettingsApi';

const GlobalStore = namespace('GlobalStore');

@Component
export default class SettingsCustomisePage extends Mixins(PermissionsMixin, AccountMixin) {
    @State(state => state.GlobalStore.account) account!: AccountType | object;
    @GlobalStore.Mutation('setAccount') setAccount!: (account: AccountType) => void;

    isAppointmentFeedbackActive: boolean = false;
    isCreateUnavailabilityActive: boolean = false;
    loading: boolean = false;
    isRequestUnavailabilitySending: boolean = false;
    isRequestNotificationsSending: boolean = false;

    async fetchAccountData() {
        this.loading = true;
        const { data } = await SettingsWebApi.getCompanySettingsData(this.user().account_id);
        this.setAccount(data.account);
        this.loading = false;
    }

    async setUnavailabilityProSupport(value: boolean) {
        this.isRequestUnavailabilitySending = true;
        await this.updateAccount({ unavailability_pro_support: value });
        this.isRequestUnavailabilitySending = false;
    }

    async setNotificationSupport(value: boolean) {
        this.isRequestNotificationsSending = true;
        await this.updateAccount({ notification_support: value });
        this.isRequestNotificationsSending = false;
    }

    async updateAccount(payload: { unavailability_pro_support?: boolean, notification_support?: boolean }) {
        try {
            const { data } = await SettingsWebApi.changeCompanySettingsData(
                {
                    ...this.account,
                    ...payload,
                },
                this.user().account_id
            );
            this.setAccount(data.account);
            this.updateSwitchValues((this.account as AccountType));
        } catch (response) {
            this.sentNotif(response);
        }
    }

    updateSwitchValues(account: AccountType) {
        this.isAppointmentFeedbackActive = account.notification_support;
        this.isCreateUnavailabilityActive = account.unavailability_pro_support;
    }

    async created(): Promise<void> {
        if (!this.isMatmutAccount) {
            this.$router.replace('/profile');
            return;
        }

        if (!this.account || !(this.account as AccountType).id) {
            await this.fetchAccountData();
        }
        this.updateSwitchValues((this.account as AccountType));
    }
};
</script>

<style lang='sass'>
.b-settings-custom-page
    background: transparent

    &__row
        display: flex
        align-items: center
        color: #203f6a
        font-weight: 500
        padding: 20px 20px 19px
        background-color: #fff
        box-shadow: 0 30px 11px -17px rgba(33, 63, 107, 0.05)
        border-radius: 10px
        justify-content: space-between

        &:not(:last-of-type)
            margin-bottom: 10px

    &__wrapper
        margin-top: 50px
</style>
