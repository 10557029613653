import axios from 'axios';
import WebApi from '../WebApi';
/* eslint-disable camelcase */

interface loginData {
    email: string,
    password: string,
}

interface resetEmailData {
    email: string,
}

interface resetPasswordData {
    old_password: string,
    new_password: string,
}

interface editUserData {
    first_name?: string,
    last_name?: string,
    email?: string,
    phone?: string,
    locale?: string,
}

class AuthWebApi extends WebApi {
    async login({ password, email }: loginData) {
        return axios.post(
            `${this.urlPrefix}/users/authenticate`,
            {
                'user': {
                    email,
                    password,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };
    async saasAuthenticate({ password, email }: loginData, account: string) {
        return axios.post(
            `${this.urlPrefix}/saas/authenticate`,
            {
                'user': {
                    email,
                    password,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-User-Account': account,
                },
            }
        );
    };
    async ssoAuth(code: string) {
        return axios.post(
            `${this.ssoPrefix}/api/sso_saas/authenticate `,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${code}`,
                },
            }
        );
    };

    async googleAuth(token: string, account: string) {
        return axios.post(
            `${this.ssoPrefix}/api/v1/users/g_authenticate`,
            {
                user: { token },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-User-Account': account,
                },
            }
        );
    };
    async resetSendEmail({ email }: resetEmailData) {
        return axios.post(
            `${this.urlPrefix}/users/reset`,
            {
                'user': {
                    email,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async getAuth(token: string, name: string, nonce: string) {
        return axios.post(
            `${this.urlPrefix}/ima_protect/authenticate`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token} - ${name} - ${nonce}`,
                },
            }
        );
    }
    async resetPassword({ old_password, new_password }: resetPasswordData, userId: string | number) {
        return axios.post(
            `${this.urlPrefix}/users/${userId}/change_password`,
            {
                'user': {
                    old_password,
                    new_password,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async editUserAccount(payload: editUserData, userId: string) {
        return axios.put(
            `${this.urlPrefix}/managers/${userId}`,
            {
                'manager': payload,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async editUser({ first_name, last_name, email, phone }: editUserData, userId: string) {
        return axios.put(
            `${this.urlPrefix}/accounts/${userId}`,
            {
                'account': {
                    first_name,
                    last_name,
                    email,
                    phone,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
}

const authWebApi = new AuthWebApi();

export {
    authWebApi as AuthWebApi,
};
