<template>
<div v-if='getCurrentDate || dates'
     class='h-mt-10 h-mb-20 h-text-center h-flex h-flex-center b-form-tabs-header'>
    <div v-if='getCurrentDate'
         class='h-inline-flex h-align-items-center h-mv-3'>
        <FwIcon class='h-mh-5 h-mr-15 h-pointer h-opacity-0_5'
                icon='calendar-confirmed'
                size='16'
                wrapperHeight='16'
                color='#213F6B'>
        </FwIcon>
        <span>
            {{ getCurrentDate }}
        </span>
    </div>
    <div v-if='dates'
         class='h-inline-flex h-align-items-center h-mv-3 h-mh-40'>
        <FwIcon
            class='h-mh-5 h-mr-15 h-pointer h-opacity-0_5'
            icon='calendar-time'
            size='16'
            wrapperHeight='16'
            color='#213F6B'>
        </FwIcon>
        <div class='h-flex h-flex-center'>
            <div v-for='(time, index) in dates'
                 :key='index'
                 class='b-appointment-left-bar__time'>
                <span>
                    {{ appointmentData.full_day ? "00:00" : time }}
                </span>
            </div>
        </div>
    </div>
    <span>
        {{ currentMethods }}
        <span v-if='data && data.booked_in === `direct`'
              class='h-ml-5'>
            ⚠️
        </span>
    </span>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { dateAsEventTime, dateToString } from '@/helpers/dates';
import i18n from '@/locale';
import TranslateMixin from '@/mixins/TranslateMixin';
import AccountMixin from '@/mixins/account';
import DateMixin from '@/mixins/dateMixin';
import { appointmentLocationResponseData } from '@/mocks/events';
import { AppointmentSearchType } from '@/types/Appointment';
import { ConsultEventType } from '@/types/Availabilities';

@Component
export default class FormTabsHeader extends Mixins(DateMixin, AccountMixin, TranslateMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: Object, required: true }) readonly data!: ConsultEventType;

    dateFrom: string | Date = '';
    dateTo: string | Date = '';
    timeTo: string | Date = '';

    get currentMethods() {
        // @ts-ignore-next-line
        return [this.appointmentData.category].map(item => appointmentLocationResponseData[item][`${i18n.locale}_name`]).join(',');
    }

    get getCurrentDate() {
        return this.appointmentData ? dateAsEventTime(new Date(this.appointmentData.dt_start as string), i18n.locale) : '';
    }

    get dates(): Array<string> {
        if (!this.appointmentData) {
            return [];
        }
        return [
            this.getCurrentDateAsMinutesAMPM(new Date(this.appointmentData.dt_start as string)),
            this.getCurrentDateAsMinutesAMPM(new Date(this.appointmentData.dt_end as string)),
        ];
    }

    dateStringWithoutTimeZone(date: string) {
        return new Date(new Date(date as string)).toISOString().slice(0, -1);
    }

    created() {
        if (this.appointmentData) {
            this.dateFrom = dateToString(new Date(this.appointmentData.dt_start as string), true);
            this.dateTo = dateToString(new Date(this.appointmentData.dt_end as string), true);
            this.timeTo = this.dateStringWithoutTimeZone(this.appointmentData.dt_end as string);
        }
    }
}
</script>

<style lang="sass">
.b-form-tabs-header
    margin-top: 10px
    margin-bottom: 20px
    text-align: center
    display: flex
    align-items: center
    justify-content: center

    @include media('<=phone')
        flex-direction: column

        & > div
            margin-bottom: 7px

    .mx-input-wrapper
        cursor: pointer

    .mx-datepicker
        background: #EDF0F3
        border-radius: 10px
        font-weight: 500 !important
        line-height: unset

        .mx-input
            font-size: 16px

    .fw-select-base .multiselect__tags
        background: #EDF0F3
</style>
