<template>
<div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-if='branchsListArray.length && !loading' class='b-add-appointment-categories__wrapper b-branch-wrapper'>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $t('ONBOARDING.OPTION.BRANCHES') }}
            </h2>
            <p class='h-font-14 h-fw-500'>
                {{ $t('ONBOARDING.CHOOSE.BRANCH.CATEGORIES.SUBTEXT') }}
            </p>
            <FwHorizontalScroll
                :isPageLoaded='true'
                :style='scrollStyle'
                slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force h-mt-30'
                iconClass='h-opacity-1'
                newClass='fw-arrow-big-circle-fixed'
                :arrowBgWidth='null'
                colorIcon='#fff'
                btnLocation='between'>
                <template #content>
                    <CalendarTable
                        :titles='branchsListTitleArray'
                        :data='branchsListArray'
                        :bigIndexes='bigIndexes'
                        class='b-calendar__table__wrapper'>
                        <div v-for='(item, i) in branchsListArray'
                             :key='i'
                             class='b-calendar__table'>
                            <div class='b-calendar__table__outer'>
                                <div v-for='(key, j) in mapping'
                                     :key='j'
                                     class='b-calendar__table__item'
                                     :class='{ "b-calendar__table__item--big": bigIndexes.includes(j) }'>
                                    <template v-if='item[key] && item[key].length'>
                                        <span class='h-overflow-text'>
                                            {{ item[key] }}
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <CalendarTableButtons
                                isRoomButton
                                @goNextPage='goNextPage(item)'
                                @edit='editBranch(item)'
                                @delete='deleteBranch(item)'>
                            </CalendarTableButtons>
                        </div>
                    </CalendarTable>
                </template>
            </FwHorizontalScroll>
        </div>
        <FwPagination
            v-if='totalPage > perPage'
            style='position: sticky; bottom: 60px;'
            :totalPage='totalPage'
            :currentPage='currentPage'
            :perPage='perPage'
            :value='perPage'
            @getData='getBranches'>
        </FwPagination>
    </div>
    <div v-else-if='!loading' class='h-flex-dir-column h-flex-center h-mt-50'>
        <h1>
            {{ $t('ONBOARDING.NO.ITEMS.BRANCH') }}
        </h1>
        <FwButton
            class='h-ml-10 h-width-150'
            size='little'
            borderRadiusType='small-border'
            @click='$router.push({ path: `${mainRouteGlobal}${userKindBoth ? `/both/branches/add` : `/branches/add`}`})'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
    <portal v-if='!isRecapRoute' to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                size='little'
                color='skip'
                borderRadiusType='small-border'
                @click='$router.push({ path: `${mainRouteGlobal}${userKindBoth ? `/both/branches/add` : `/branches/add`}`})'>
                {{ $t('BUTTON.ADD.MORE') }}
            </FwButton>
            <FwButton
                class='h-ml-10 h-width-150'
                :to='nextRoute'
                :disabled='!branchsListArray.length'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
    </portal>
    <portal v-else to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                :to='`${mainRouteGlobal}/recap`'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('Go to recap') }}
            </FwButton>
        </div>
    </portal>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.BRANCH.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.SUB.BRANCH.TEXT`)'
        :isRequestSending='isRequestSending'
        @deleteItem='deleteItem'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { BranchRoomResponseType, pagePaginationData } from '@/types/Appointment';
import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';
import { calculateNextPage } from '@/helpers/pagination';

const OnboardingStore = namespace('OnboardingStore');
const BreadCrumbsStore = namespace('BreadCrumbsStore');

@Component({
    components: {
        DeletePopup,
        CalendarTable,
        CalendarTableButtons,
    },
})
export default class AppointmentBranchList extends Mixins(ValidationMixin, TranslateMixin) {
    @OnboardingStore.Mutation('mutationBranchData') mutationBranchData!: Function;
    @BreadCrumbsStore.Getter('currentFlowKey') currentFlowKey!: string;
    currentPage: number = 1;
    totalPage: number = 0;
    perPage: number = 6;
    confirmPopup: boolean = false;
    isRequestSending: boolean = false;
    currentBranch: BranchRoomResponseType | null = null;
    loading: boolean = true;
    branchsListTitleArray: Array<string> = [
        'PLAN.APPOINTMENT.BRANCH.LIST.NAME',
        'LABEL.ADDRESS.1',
        'LABEL.PHONE',
        'LABEL.EMAIL',
        'LABEL.BRANCH.ID',
    ];
    mapping: Array<string> = [
        `name`,
        `address`,
        `phone`,
        `email`,
        `external_id`,
    ];
    branchsListArray: Array<BranchRoomResponseType> = [];
    $refs!: {
        leftBar: HTMLElement
    };
    bigIndexes: Array<number> = [1, 3];

    get userKindBoth() {
        return this.user().account_organization_type === 'with_all';
    }
    async deleteItem() {
        this.confirmPopup = false;
        if (this.currentBranch) {
            this.isRequestSending = true;
            try {
                await OnboardingApi.deleteOnboardingBranch(this.currentBranch.id);
                this.currentPage = calculateNextPage({
                    page: this.currentPage,
                    per_page: this.perPage,
                    total_items: this.totalPage,
                });
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
                this.getBranches(this.currentPage, this.perPage);
            } catch ({ response }) {
                this.sentNotif(response);
                this.isRequestSending = false;
            } finally {
                this.isRequestSending = false;
            }
        }
    }
    goNextPage(branch: BranchRoomResponseType) {
        this.mutationBranchData(branch);
        if (this.isRecapRoute) {
            this.$router.push({ path: `${this.mainRouteGlobal}/recap/branches/add-rooms/${branch.id}` });
        } else {
            this.$router.push({ path: `${this.mainRouteGlobal}${this.userKindBoth ? `/both/branches/add-rooms/` : `/branches/add-rooms/`}${branch.id}` });
        }
    }

    deleteBranch(branch: BranchRoomResponseType) {
        this.currentBranch = branch;
        this.confirmPopup = true;
    }

    get scrollStyle() {
        if (this.$refs.leftBar && this.$el) {
            return {
                width: `${(this.$el as HTMLElement).offsetWidth - this.$refs.leftBar.offsetWidth}px`,
            };
        }
        return null;
    }
    get nextRoute() {
        return this.userKindBoth ? `${this.mainRouteGlobal}/both/advisors/add` : `${this.mainRouteGlobal}/advisors/add`;
    }

    editBranch(branch: BranchRoomResponseType) {
        return this.isRecapRoute ?
            this.$router.push({ path: `${this.mainRouteGlobal}${`/recap/branches/edit/`}${branch.id}` }) :
            this.$router.push({ path: `${this.mainRouteGlobal}${this.userKindBoth ? `/both/branches/edit/` : `/branches/edit/`}${branch.id}` });
    }

    async getBranches(el: number, val: number) {
        this.currentPage = el;
        const payload: pagePaginationData = {
            current_page: el,
            per_page: val,
        };
        this.loading = true;
        const data = await OnboardingApi.getOnboardingBranchesRoomsList('branch', payload);
        this.totalPage = data.meta.total_count;
        // @ts-ignore-next-line
        this.branchsListArray = data.sites;
        this.loading = false;
    }

    async created() {
        await this.getBranches(this.currentPage, this.perPage);
    }
}
</script>
<style lang='sass'>
    .b-add-appointment
        &__wrapper
            @include media('<=phone')
                width: 100%
            .b-time-picker
                background: #ffffff
                height: 60px
    .b-branch-wrapper
        margin-bottom: 100px
    .b-appointment-categories__body
        &--items
            margin-top: 10px
            border-radius: 5px
        &--item
            background: #ffffff
            input
                font-weight: 500!important
                font-size: 14px
        &--delete
            display: flex
            justify-content: center
            align-items: center
            background: #ffffff
            margin-left: 10px
            height: 60px
            width: 60px
            border-radius: 5px
        .fw-select-white
            box-shadow: none!important
            .multiselect__tags
                padding-left: 15px
            .multiselect__placeholder
                font-size: 14px
                color: $dark-blue
                font-weight: 500
            .multiselect__single
                font-size: 14px
                color: $dark-blue
                font-weight: 500
            .multiselect__element
                font-size: 14px
                color: $dark-blue
                font-weight: 400

</style>
