
function truncate(text: string, length: number, suffix: string) {
    let result = text;
    if (text.length >= length) {
        result = text.substring(0, length) + suffix;
    }
    return result;
}

function insertIf<T>(condition: boolean, ...elements: Array<T>): Array<T> {
    return condition ? elements : [];
}

export {
    insertIf,
    truncate,
};
