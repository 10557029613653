<template>
<div class='b-add-hub__wrapper container'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-if='teams.length && !loading'>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $t('ONBOARDING.OPTION.TEAMS.TITLE') }}
            </h2>
            <p class='h-font-14 h-fw-500'>
                {{ $t('ONBOARDING.OPTION.TEAMS.ASIDE') }}
            </p>
        </div>
        <div class='h-mb-30'>
            <div>
                {{ $t('ONBOARDING.OPTION.TEAMS.FORM.TITLE') }}
            </div>
            <TeamListItem
                v-for='team in teams'
                :key='team'
                :team='team'
                @isConfirmDeletePopup='isConfirmDeletePopup'
                @getHubs='getHubs'>
            </TeamListItem>
        </div>
        <FwPagination
            v-if='totalPage > perPage'
            v-hide='loading'
            style='position: sticky; bottom: 60px;'
            :totalPage='totalPage'
            :currentPage='currentPage'
            :perPage='perPage'
            :value='perPage'
            @getData='getHubs'>
        </FwPagination>
    </div>
    <div v-else-if='!loading' class='h-flex-dir-column h-flex-center h-mt-50'>
        <h1>
            {{ $t('ONBOARDING.NO.ITEMS.HUB') }}
        </h1>
        <FwButton
            class='h-ml-10 h-width-150'
            :to='`${mainRouteGlobal}${userKindBoth ? `/both/hubs/add`: `/hubs/add`}`'
            size='little'
            borderRadiusType='small-border'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
    <portal v-if='!isRecapRoute' to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-mr-15 h-white-space-nowrap'
                :to='`${mainRouteGlobal}${userKindBoth ? `/both/hubs/add`: `/hubs/add`}`'
                size='little'
                color='skip'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.ADD.MORE') }}
            </FwButton>

            <FwButton
                :to='`${mainRouteGlobal}${$route.meta.teamNextPostfix}`'
                size='little'
                :disabled='!teams.length'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
    </portal>
    <portal v-else to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                :to='`${mainRouteGlobal}/recap`'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('Go to recap') }}
            </FwButton>
        </div>
    </portal>

    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.HUB.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.HUB.TEXT`)'
        :isRequestSending='isRequestSending'
        @deleteItem='deleteItem'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';
import { TeamListItem } from '@/components/simple/TeamListItem';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { HubCreateType, pagePaginationData } from '@/types/Appointment';
import { calculateNextPage } from '@/helpers/pagination';
import { DeletePopup } from '@/components/popups/DeletePopup';

@Component({
    components: {
        TeamListItem,
        DeletePopup,
    },
})
export default class AppointmentHubTeams extends Vue {
    loading: boolean = false;
    currentPage: number = 1;
    totalPage: number = 0;
    perPage: number = 6;
    teams: Array<HubCreateType> = [];
    confirmPopup: boolean = false;
    isRequestSending: boolean = false;
    currentTeamId: string | null = null;

    get userKindBoth() {
        return this.user().account_organization_type === 'with_all';
    }

    isConfirmDeletePopup(teamId: string) {
        this.confirmPopup = true;
        this.currentTeamId = teamId;
    }

    async getHubs(currentPage: number | undefined, perPage: number | undefined) {
        const page = currentPage || this.currentPage;
        const per_page = perPage || this.perPage;
        this.currentPage = page;
        const payload: pagePaginationData = {
            current_page: page,
            per_page,
        };
        this.loading = true;
        const data = await OnboardingApi.getOnboardingHubs(payload);
        this.totalPage = data.meta.total_count;
        this.teams = data.hubs;
        this.loading = false;
    }

    async deleteItem() {
        if (this.currentTeamId) {
            this.isRequestSending = true;
            try {
                await OnboardingApi.deleteOnboardingHub(this.currentTeamId);
                this.currentPage = calculateNextPage({ page: this.currentPage, per_page: this.perPage, total_items: this.totalPage });
                this.confirmPopup = false;
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
                this.getHubs(this.currentPage, this.perPage);
            } catch ({ response }) {
                this.sentNotif(response);
                this.isRequestSending = false;
            } finally {
                this.isRequestSending = false;
            }
        }
    }

    created() {
        this.getHubs(this.currentPage, this.perPage);
    }
}
</script>

<style lang='sass'>
.b-add-hub
    &__wrapper
        width: 600px
        margin-bottom: 50px

        @include media('<=phone')
            width: 100%

    .b-required-field
        font-weight: 500
        font-size: 13px
        margin-bottom: 160px
</style>
