export default {
    'SELECT.APPOINTMENT.TITLE': 'Rendez-vous',
    'SELECT.TYPE.APPOINTMENT.CATEGORY.TITLE': 'Choisir une catégorie de rendez-vous',
    'SELECT.TYPE.APPOINTMENT.TITLE': 'Choisir un type de rendez-vous',
    'SELECT.TYPE.APPOINTMENT.PLACEHOLDER': 'Type de rendez-vous',
    'SELECT.TYPE.APPOINTMENT.CATEGORY.PLACEHOLDER': 'Catégorie de rendez-vous',

    'SELECT.ROOM.APPOINTMENT.TITLE': 'Dans quelle salle ?',
    'SELECT.ROOM.APPOINTMENT.PLACEHOLDER': 'Sélectionner une salle',

    'SELECT.LOCATION.APPOINTMENT.TITLE': 'Où est-ce que le rendez-vous aura lieu?',
    'SELECT.LOCATION.APPOINTMENT.PLACEHOLDER': 'Lieu',

    'SELECT.POSTCODE.APPOINTMENT.TITLE': 'Quel est le code postal du client?',
    'SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER': 'Code postal',

    'SELECT.ADDRESS.APPOINTMENT.CLIENT.TITLE': 'Quelle est l\'adresse du client?',
    'SELECT.ADDRESS.APPOINTMENT.LABEL': 'Adresse',
    'SELECT.ADDRESS.APPOINTMENT.TITLE': 'Quelle est l\'adresse du rendez-vous ?',
    'SELECT.ADDRESS.APPOINTMENT.PLACEHOLDER': 'Veuillez écrire votre adresse ici',
    'SELECTOR.ADDRESS.NO.ELEMENT.FOUND': 'Pas de résultat trouvé',

    'SELECT.ADDRESS.BRANCH.TITLE': 'Choisissez l\'agence',
    'SELECT.SERVICE_POINT.TITLE': 'Sélectionner une permanence',
    'SELECT.ADDRESS.BRANCH.CONTACT.DETAILS': 'Coordonnées',
    'SELECT.ADDRESS.BRANCH.CUSTOMER': 'Adresse client',
    'SELECT.BOOKING.PARAMETERS.TITLE': 'Paramètres de prise de rendez-vous',

    'DELETE.APPOINTMENT.TYPE.TITLE': 'Supprimer un type de RDV',
    'DELETE.APPOINTMENT.TYPE.TEXT': 'Êtes-vous certain de vouloir supprimer ce type de RDV?',
    'DELETE.APPOINTMENT.TYPE.SUCCESS.TITLE': 'Type de RDV supprimé',
    'DELETE.APPOINTMENT.TYPE.SUCCESS.TEXT': 'Le type de RDV a été supprimé',

    'DELETE.APPOINTMENT.WORKER.TITLE': 'Supprimer la ressource',
    'DELETE.APPOINTMENT.WORKER.TEXT': 'Voulez-vous vraiment supprimer cette ressource ?',
    'DELETE.APPOINTMENT.WORKER.SUCCESS.TITLE': 'Ressource supprimée',
    'DELETE.APPOINTMENT.WORKER.SUCCESS.TEXT': 'Ressource a été supprimé',

    'DELETE.APPOINTMENT.BUSINESS.COMPANY.TITLE': 'Supprimer une entreprise partenaire',
    'DELETE.APPOINTMENT.BUSINESS.COMPANY.TEXT': 'Voulez-vous vraiment supprimer cette entreprise partenaire?',
    'DELETE.APPOINTMENT.BUSINESS.COMPANY.SUCCESS.TITLE': 'Entreprise partenaire supprimée',

    'DELETE.APPOINTMENT.ADVISOR.TITLE': 'Supprimer le conseiller',
    'DELETE.APPOINTMENT.ADVISOR.TEXT': 'Voulez-vous vraiment supprimer ce conseiller ?',
    'DELETE.APPOINTMENT.ADVISOR.SUCCESS.TITLE': 'Le conseiller a supprimé',
    'DELETE.APPOINTMENT.ADVISOR.SUCCESS.TEXT': 'Le conseiller a été supprimé',

    'DELETE.APPOINTMENT.HUB.TITLE': 'Supprimer un hub',
    'DELETE.APPOINTMENT.HUB.TEXT': 'Voulez-vous vraiment supprimer ce hub ?',
    'DELETE.APPOINTMENT.HUB.SUCCESS.TITLE': 'Hub supprimé',
    'DELETE.APPOINTMENT.HUB.SUCCESS.TEXT': 'Le hub a été supprimé',

    'DELETE.APPOINTMENT.BRANCH.TITLE': 'Supprimer une agence',
    'DELETE.APPOINTMENT.SUB.BRANCH.TEXT': 'Voulez-vous vraiment supprimer cette agence ?',
    'DELETE.APPOINTMENT.BRANCH.DELETED': 'Branche supprimée',

    'ADD.ROOM.TITLE': 'Ajouter une salle',
    'EDIT.ROOM.TITLE': 'Modifier la salle',
    'ADD.ROOM.QUESTION': 'Souhaitez-vous ajouter une salle à cette agence',

    'MANAGE.ROOMS.TITLE': 'Gérer les salles',

    'DELETE.APPOINTMENT.ROOM.TITLE': 'Supprimer la salle',
    'DELETE.APPOINTMENT.ROOM.TEXT': 'Voulez-vous vraiment supprimer cette salle?',
    'DELETE.APPOINTMENT.ROOM.SUCCESS.TITLE': 'Salle supprimée',
    'DELETE.APPOINTMENT.ROOM.SUCCESS.TEXT': 'La salle a été supprimée.',

    'SKILLS.YOU.NEED.INFO': 'La compétence dont vous avez besoin n’est pas dans la liste ?',
    'SKILLS.YOU.NEED.INFO.CONTACT': ' Contactez l’équipe Calizy',
    'SKILLS.YOU.NEED.SELECT': 'Sélectionnez une compétence',

    'CONSULT.APPOINTMENT.SEARCH.TITLE': 'Rechercher un rendez-vous',
    'CONSULT.APPOINTMENT.SEARCH.CLIENTS.NAME': 'Nom du client',
    'CONSULT.APPOINTMENT.SEARCH.CLIENT.FULLNAME': 'Nom du client',
    'CONSULT.APPOINTMENT.SEARCH.SITE.CODE': 'Code site',
    'CONSULT.APPOINTMENT.SEARCH.ADVISOR.NAME': 'Nom du conseiller',
    'CONSULT.APPOINTMENT.SEARCH.ADVISORS.NAME': 'Nom du conseiller',
    'CONSULT.APPOINTMENT.SEARCH.DATE': 'Date',
    'CONSULT.APPOINTMENT.SEARCH.REFERENCE': 'Référence',
    'CONSULT.APPOINTMENT.SEARCH.POPUP.EDIT': 'Modifier un RDV',

    'CONSULT.APPOINTMENT.CLIENT.DETAILS': 'Informations client',
    'CONSULT.APPOINTMENT.COMMENT': 'Commentaire',
    'CONSULT.APPOINTMENT.COMMENTS': 'Commentaires',
    'CONSULT.APPOINTMENT.NO.COMMENTS': 'Pas de commentaire',

    'CONSULT.BRANCHES.NAME': 'Agence',
    'CONSULT.BRANCH.NAME': 'Agence',
    'CONSULT.BRANCHES.REFERENCE': 'Référence',

    'CONSULT.TYPE.NAME.PLACEHOLDER': 'Saisissez le nom',
    'CONSULT.TYPE.DATE.PLACEHOLDER': 'Sélectionnez une date',
    'CONSULT.TYPE.ADVISOR.PLACEHOLDER': 'Saisissez le nom',
    'CONSULT.TYPE.CODE.PLACEHOLDER': 'Saisissez le code site',
    'CONSULT.TYPE.BRANCH.PLACEHOLDER': 'Saisissez une agence',
    'CONSULT.TYPE.REFERENCE.PLACEHOLDER': 'Saisissez une référence',

    'CUSTOMER.DETAILS.INPUT.PLACEHOLDER': 'ID prospect ou client',
    'CUSTOMER.DETAILS.BUTTON.ID': 'Identifiant',
    'CUSTOMER.DETAILS.BUTTON.TYPE.IN': 'Manuellement',

    'PAST.APPOINTMENTS.CANT.BE.CANCELED': 'Les RDV passés ne peuvent pas être annulés',
    'PAST.APPOINTMENT.DETAILS.TITLE': 'Information rendez-vous',

    'PAST.APPOINTMENT.SET_BOOK.TITLE': 'Veuillez saisir l\'adresse email ou le numéro de téléphone du client',
    'PAST.APPOINTMENT.UPDATE.CLIENT.DETAILS': 'Je souhaite mettre à jour les détails du client',

    'APPOINTMENT.DISABLE.CUSTOMER.NOTIFICATION': 'Désactiver les notifications client',

    'MATMUT.HAVE_NOT_PERMISSIONS': 'Vous n\'avez pas les droits pour accéder à cette fonctionnalité. Merci de contacter votre responsable pour qu\'il modifie les droits',

    'APPOINTMENT.POPUP.ARCHIVE.TITLE': 'Archiver un type de RDV',
    'APPOINTMENT.POPUP.ARCHIVE.TEXT': 'Êtes-vous certain de vouloir archiver ce type de RDV?',
    'APPOINTMENT.POPUP.ARCHIVE.YES': 'Oui, archiver',
    'APPOINTMENT.POPUP.ARCHIVE.CANCEL': 'Annuler',

    'APPOINTMENT.POPUP.REACTIVE.TITLE': 'Réactiver un type de RDV',
    'APPOINTMENT.POPUP.REACTIVE.TEXT': 'Êtes-vous certain de vouloir réactiver ce type de RDV?',
    'APPOINTMENT.POPUP.REACTIVE.YES': 'Oui, réactiver',
    'APPOINTMENT.POPUP.REACTIVE.CANCEL': 'Annuler',

    'UPDATE.APPOINTMENT.TITLE': 'MODIFIER LE RENDEZ-VOUS',
    'UPDATE.APPOINTMENT.TITLE.QUESTION': 'Que souhaitez-vous modifier ?',
    'UPDATE.APPOINTMENT.DATE.TIME.ADVISOR': 'Date, heure, conseiller',
    'UPDATE.APPOINTMENT.OTHER.ELEMENT': 'Autre élément',
    'UPDATE.APPOINTMENT.BEING.UPDATED': 'Rendez-vous en cours de modification',
    'UPDATE.APPOINTMENT.NOW.TITLE.QUESTION': 'Voulez-vous effectuer d\'autres modifications ?',
    'UPDATE.APPOINTMENT.NOW.BUTTON': 'J\'ai terminé',
    'UPDATE.APPOINTMENT.OPTIONS': 'Modifier l\'heure de début',

    'CONSULT.CLOSURES': 'Fermetures',
    'CONSULT.ADD_CLOSURES': 'Ajouter une fermeture',
    'CONSULT.UPDATE_CLOSURES': 'Modifier une fermeture',

    'APPOINTMENT.DIRECT.BOOK.HOVER.INFO': 'RDV directement planifié dans le calendrier conseiller.',

    'APPOINTMENT.USE.ANOTHER.ADDRESS': 'Utiliser une autre adresse',
    'APPOINTMENT.USE.ANOTHER.POSTCODE': 'Utiliser un autre code postal',
};
