<template>
<div class='b-blue-background b-blue-background__opacity b-cancel-popup__select b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='390px'
        width='390px'
        :title='isUpdateNow ? $t(`UPDATE.APPOINTMENT.NOW.TITLE.QUESTION`) : $t(`UPDATE.APPOINTMENT.TITLE.QUESTION`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='!isUpdateNow'
        submitText='BUTTON.ADD'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :disableClosingOnClickOutside='true'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex h-flex-wrap'>
                <FwButton
                    size='large-xl'
                    color='choose'
                    borderRadiusType='tiny-border'
                    class='h-width-100p h-mt-10'
                    :fontSize='`16px`'
                    @click.native='goToCalendarHandler'>
                    <span style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>
                        {{ $t('UPDATE.APPOINTMENT.DATE.TIME.ADVISOR') }}
                    </span>
                    <FwIcon
                        icon='arrow-right'
                        size='12'
                        color='#37e6c9'>
                    </FwIcon>
                </FwButton>
            </div>
            <div class='h-flex h-flex-wrap'>
                <FwButton
                    v-if='isUpdateNow'
                    size='large-xl'
                    borderRadiusType='tiny-border'
                    class='h-width-100p h-mt-10 h-flex h-flex-center'
                    :fontSize='`16px`'
                    @click.native='$emit(`saveBookingProcess`)'>
                    <span style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width: 100%'>
                        {{ $t('UPDATE.APPOINTMENT.NOW.BUTTON') }}
                    </span>
                </FwButton>
                <FwButton
                    v-else
                    size='large-xl'
                    color='choose'
                    borderRadiusType='tiny-border'
                    class='h-width-100p h-mt-10'
                    :fontSize='`16px`'
                    @click.native='$emit(`startBookingProcess`)'>
                    <span style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>
                        {{ $t('UPDATE.APPOINTMENT.OTHER.ELEMENT') }}
                    </span>
                    <FwIcon
                        icon='arrow-right'
                        size='12'
                        color='#37e6c9'>
                    </FwIcon>
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Prop, Emit } from 'vue-property-decorator';
import { CancelReasonSelect } from '@/components/common/CancelReasonSelect';
import AccountMixin from '@/mixins/account';

@Component({
    components: {
        CancelReasonSelect,
    },
})
export default class UpdateAppointmentPopup extends Mixins(AccountMixin) {
    @Prop({ type: Boolean, required: false }) readonly isUpdateNow!: boolean;

    @Emit('goToCalendar')
    goToCalendarHandler() {}
}
</script>

<style lang='sass'>
.b-blue-background
    display: flex
    justify-content: center
    align-items: center

    .fw-popup__title
        font-size: 22px
        @include media('<=phone')
            font-size: 18px

    .fw-popup__close_popup
        @include media('<=phone')
            right: 22px
            top: 22px
</style>
