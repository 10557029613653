import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { workerDataType } from '@/types/User';
import { CompanyData } from '@/types/Companies';
import { WorkerScheduleTimeType } from '@/types/Workers';
import { BranchSitesDataType } from '@/types/Site';

@Module({
    namespaced: true,
})
class WorkerPageStore extends VuexModule {
    workerData: workerDataType | null = null;
    branchStateData: BranchSitesDataType | null = null;
    hubStateData: BranchSitesDataType | null = null;
    companyStateData: CompanyData | null = null;
    workerUpdateData: workerDataType | null = null;
    addressUpdateData: any | null = null;
    isUpdateAddressShow: boolean = false;
    workerScheduleData: WorkerScheduleTimeType | null = null;
    scheduleId: string | null = null;
    workerIds: Array<string> = [];
    workerInvitationIds: Array<string> = [];

    @Mutation
    WORKER_DATA(data: workerDataType) {
        this.workerData = data;
    }

    @Mutation
    UPDATE_ADDRESS_POPUP_SHOW(value: boolean) {
        this.isUpdateAddressShow = value;
    }

    @Mutation
    setWorkerScheduleData(data: WorkerScheduleTimeType) {
        this.workerScheduleData = data;
    }

    @Mutation
    setScheduleId(data: string) {
        this.scheduleId = data;
    }

    @Mutation
    setBranchData(data: BranchSitesDataType) {
        this.branchStateData = data;
    }

    @Mutation
    setHubData(data: BranchSitesDataType) {
        this.hubStateData = data;
    }

    @Mutation
    setCompanyData(data: CompanyData) {
        this.companyStateData = data;
    }

    @Mutation
    setCompanyUserData(data: CompanyData) {
        if (this.companyStateData) {
            // @ts-ignore-next-line
            this.companyStateData.technicians = data;
        }
    }

    @Mutation
    setWorkerIds(data: string) {
        this.workerIds = [...this.workerIds, data];
    }

    @Mutation
    setWorkerInvitationIds(data: string) {
        this.workerInvitationIds = this.workerInvitationIds.includes(data) ? this.workerInvitationIds.filter(e => e !== data) : [...this.workerInvitationIds, data];
    }

    @Mutation
    WORKER_UPDATE_DATA(data: any) {
        this.workerUpdateData = data;
    }

    @Mutation
    ADDRESS_UPDATE_DATA(data: any) {
        this.addressUpdateData = data;
    }

    @Action({ commit: 'WORKER_DATA' })
    storeWorkerData(payload: object) {
        return payload;
    }

    @Action({ commit: 'setWorkerScheduleData' })
    storeWorkerScheduleData(payload: object) {
        return payload;
    }

    @Action({ commit: 'setBranchData' })
    storeBranchData(payload: object) {
        return payload;
    }

    @Action({ commit: 'setHubData' })
    storeHubData(payload: object) {
        return payload;
    }

    @Action({ commit: 'setCompanyData' })
    storeCompanyData(payload: object) {
        return payload;
    }

    @Action({ commit: 'WORKER_UPDATE_DATA' })
    storeWorkerUpdateData(payload: object) {
        return payload;
    }

    get workerIdsGetter(): Array<string> {
        return this.workerIds;
    }

    get workerInvitationIdsGetter(): Array<string> {
        return this.workerInvitationIds;
    }
}

export default WorkerPageStore;

