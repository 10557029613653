import axios from 'axios';
import { CompanyData, CompanyUpdateAddressType } from '@/types/Companies';
import {
    BookAppointmentDataType,
    EventsAppointmentsResponseType,
    ChooseAppointmentKind,
    AppointmentCategoriesResponseType,
    ChooseOrganizationType,
    AvailabilitiesByPostCodePayloadType,
    AvailabilitiesBranchFaceToFacePayloadType,
    AvailabilitiesVideoconferencePayloadType,
    OnSiteAppointmentsPayloadType,
    OnSiteInBranchAppointmentsPayloadType,
    AppointmentOnSiteDataType,
    AvailabilitiesOnSitePayloadType,
    BranchAddressType,
    AppointmentOriginSendDataType,
    AppointmentPostUrlBuilderType,
    BookAppointmentManuallyType,
    AppointmentDataType,
    CategorySkillNamesResponseType,
} from '@/types/Appointment';
import { BookingCalendarResponseDataType } from '@/types/Calendar';
import { AccountType } from '@/types/Account';
import { ClientType } from '@/types/User';
import { updateUrlGetParams } from '@/helpers/url';
import { UpdateAppointmentDataType } from '@/types/Book';
import WebApi from '../WebApi';

interface companyData {
    reference: string,
    app_type: string,
    account_id?: string | number,
}
interface refreshData {
    appointment_id: string,
}

interface companyCreateData {
    contact_email: string,
    title: string,
    address: string,
    siret: string,
    address_confirmed: boolean,
}

interface pageData {
    from: string | number | Date | null,
    to: string | number | Date | null,
    company_id: string | number | null,
}

interface branchAppointmentDataResponse {
    from: string,
    to: string,
    branch_id: string,
}

interface hubAppointmentDataResponse {
    from: string,
    to: string,
    hub_id: string,
    page?: number,
}

interface ServicePointAppointmentsPayloadType {
    from: string,
    to: string,
    sp_id: string,
    page?: number,
}

interface userAppointmentDataResponse {
    from: string,
    to: string,
    user_id: string,
    page?: number,
}

interface companyAppointmentDataResponse {
    from: string,
    to: string,
    company_id: string,
}

interface pageWorkerData {
    from: string | number | Date | null,
    to: string | number | Date | null,
    user_id: string | number,
}
interface pagePaginationData {
    per_page: number,
    current_page: number,
    sort?: boolean,
}

class AppointmentWebApi extends WebApi {
    async chooseAppointmentKind(kind: ChooseAppointmentKind, id: string): Promise<AccountType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/account_onboarding/accounts/${id}`,
            {
                account: kind,
            },
        );
        return data.account;
    }

    async chooseOrganizationType(type: ChooseOrganizationType, account_id: string): Promise<AccountType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/account_onboarding/accounts/${account_id}/set_organization_type`,
            {
                account: {
                    organization_type: type,
                },
            },
        );
        return data.account;
    }

    async sendInterventionData(appointment: companyData) {
        return axios.post(
            `${this.urlPrefix}/appointments`,
            { appointment },
        );
    }

    async updateAppointmentData(appointment: UpdateAppointmentDataType | BookAppointmentManuallyType, id: string): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/appointments/${id}`,
            { appointment },
        );

        return data.appointment;
    }

    async refreshAppointmentData(appointment: refreshData) {
        return axios.post(
            `${this.urlPrefix}/appointments/refresh`,
            { appointment },
        );
    }
    async getCompanyAppointmentsDates(payload: pageData): Promise<{ data: any }> {
        const { company_id, from, to } = payload;
        return axios.get(
            `${this.urlPrefix}/appointments/fetch_dates`,
            {
                params: {
                    company_id,
                    from,
                    to,
                },
            },
        );
    }

    async getWorkerAppointmentsDates(payload: pageWorkerData): Promise<{ data: any }> {
        const { user_id, from, to } = payload;
        return axios.get(
            `${this.urlPrefix}/appointments/fetch_dates`,
            {
                params: {
                    user_id,
                    from,
                    to,
                },
            },
        );
    }

    async getAppointmentsData(payload: pageData): Promise<{ data: EventsAppointmentsResponseType }> {
        const { company_id, from, to } = payload;
        const urlParams = `?pagination[per_page]=100`;
        return axios.get(
            `${this.urlPrefix}/appointments${urlParams}`,
            {
                params: {
                    company_id,
                    from,
                    to,
                },
            },
        );
    }

    async getBranchAppointmentsData(payload: branchAppointmentDataResponse) {
        const { from, to } = payload;
        const url = updateUrlGetParams(`${this.urlPrefix}/appointments`, {
            'pagination[per_page]': 100,
            'branch_id': payload.branch_id,
            'from': from,
            'to': to,
        });
        const { data } = await axios.get(url);
        return data.appointments;
    }

    async getHubAppointmentsData(payload: hubAppointmentDataResponse) {
        const { from, to, page } = payload;
        const url = updateUrlGetParams(`${this.urlPrefix}/appointments`, {
            'pagination[per_page]': 100,
            'pagination[page]': page,
            'hub_id': payload.hub_id,
            'from': from,
            'to': to,
        });
        const { data } = await axios.get(url);
        return data;
    }

    async getServicePointAppointmentsData(payload: ServicePointAppointmentsPayloadType) {
        const { from, to, page } = payload;
        const url = updateUrlGetParams(`${this.urlPrefix}/service_point_appointments`, {
            'pagination[per_page]': 100,
            'pagination[page]': page,
            'sp_id': payload.sp_id,
            'from': from,
            'to': to,
        });
        const { data } = await axios.get(url);
        return data;
    }

    async getUserAppointmentsData(payload: userAppointmentDataResponse) {
        const { from, to, page } = payload;
        const url = updateUrlGetParams(`${this.urlPrefix}/appointments`, {
            'pagination[per_page]': 100,
            'pagination[page]': page,
            'user_id': payload.user_id,
            'from': from,
            'to': to,
        });
        const { data } = await axios.get(url);

        return data;
    }

    async getCompanyAppointmentsData(payload: companyAppointmentDataResponse) {
        const { from, to } = payload;
        const url = updateUrlGetParams(`${this.urlPrefix}/appointments`, {
            'pagination[per_page]': 100,
            'company_id': payload.company_id,
            'from': from,
            'to': to,
        });
        const { data } = await axios.get(url);
        return data.appointments;
    }

    async getInterventionCancelData(appointment: companyData) {
        const payload = `${appointment.app_type.toLowerCase()}_${appointment.reference}`;
        const { data } = await axios.get(`${this.urlPrefix}/appointments/${payload}/fetch`);
        return data;
    }

    async getSocietyData() {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/company_titles`);
        return data;
    }

    async getDirectBookingRoomsData(id: string) {
        const url = updateUrlGetParams(`${this.urlPrefix}/components/direct_booking`, {
            'user_id': id,
        });
        const { data } = await axios.get(url);

        return data;
    }

    async getAppointmentsCategories(
        { page, per_page = 31, opportunity, caseValue }: { page: number, per_page: number, opportunity?: boolean, caseValue?: boolean }
    ): Promise<AppointmentCategoriesResponseType> {
        const urlPostfix: string = opportunity || caseValue ? `appointment_categories_matmut` : `appointment_categories`;
        const url = updateUrlGetParams(`${this.urlPrefix}/front_helpers/${urlPostfix}`, {
            'pagination[per_page]': per_page,
            'pagination[page]': page,
            ...(opportunity && { opportunity }),
            ...(caseValue && { case: caseValue }),
        });
        const { data } = await axios.get(decodeURI(url));
        return data;
    }

    async getAdvisorAppointmentsCategories(
        { page, per_page = 31, user_id }: { page: number, per_page: number, user_id?: string }
    ): Promise<AppointmentCategoriesResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/front_helpers/advisors_appointment_categories`, {
            'pagination[per_page]': per_page,
            'pagination[page]': page,
            user_id,
        });
        const { data } = await axios.get(decodeURI(url));
        return data;
    }

    async getSocietyPaginationData(payload: pagePaginationData) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const { data } = await axios.get(`${this.urlPrefix}/companies${urlParams}`);
        return data;
    }

    async getSocietyDataBySearch(payload: pagePaginationData, searchValue: string) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const { data } = await axios.get(`${this.urlPrefix}/companies?search=${searchValue}${urlParams}`);
        return data;
    }

    async createCompany(company: companyCreateData) {
        return axios.post(
            `${this.urlPrefix}/companies`,
            { company },
        );
    }

    async onSiteAppointments(appointment: OnSiteAppointmentsPayloadType): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.post(`${this.urlPrefix}/on_site_appointments`, { appointment });
        return data.appointment;
    }

    async onSiteInBranchAppointments(appointment: OnSiteInBranchAppointmentsPayloadType): Promise<AppointmentOnSiteDataType> {
        const { data } = await axios.post(`${this.urlPrefix}/on_site_in_branch_appointments`, { appointment });
        return data.appointment;
    }

    async getAvailabilitiesByOnPhone(payload: AvailabilitiesByPostCodePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bph_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesByOnlineVideo(payload: AvailabilitiesByPostCodePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bov_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesByBranchFaceToFacePublic(payload: AvailabilitiesBranchFaceToFacePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bpb_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesInServicePoint(payload: AvailabilitiesBranchFaceToFacePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/service_point_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesByBranchPhonePublic(payload: AvailabilitiesBranchFaceToFacePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bppb_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesByBranchVideoPublic(payload: AvailabilitiesBranchFaceToFacePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bvpb_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesByBranchClientVideoPrivate(payload: AvailabilitiesVideoconferencePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bcvp_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesByBranchFaceToFacePrivate(payload: AvailabilitiesVideoconferencePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bpr_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesByBranchPhonePrivate(payload: AvailabilitiesVideoconferencePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bppr_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesByBranchVideoPrivate(payload: AvailabilitiesVideoconferencePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/apnt_bvpr_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesOnSite(payload: AvailabilitiesOnSitePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/on_site_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async getAvailabilitiesOnSiteInBranch(payload: AvailabilitiesOnSitePayloadType): Promise<BookingCalendarResponseDataType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/on_site_in_branch_availabilities`, payload);
        const { data } = await axios.get(url);
        return data;
    }

    async deleteCompany(id: string | number) {
        return axios.delete(`${this.urlPrefix}/companies/${id}`);
    }

    async getSocietyItemData(id: string): Promise<{ company: CompanyData }> {
        const { data } = await axios.get(`${this.urlPrefix}/companies/${id}`);
        return data;
    }

    async changeCompanyData(company: any, companyId: number | string) {
        return axios.put(
            `${this.urlPrefix}/companies/${companyId}`,
            { company },
        );
    }
    async bookAppointment({ dt_start, dt_end, company_id, technician_id }: BookAppointmentDataType, id: string | number) {
        return axios.put(
            `${this.urlPrefix}/appointments/${id}/book`,
            {
                'appointment': {
                    dt_start,
                    dt_end,
                    company_id,
                    technician_id,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async changeAppointmentTime(payload: BookAppointmentManuallyType, id: string): Promise<AppointmentDataType | null> {
        const { data } = await axios.put(
            `${this.urlPrefix}/appointments/${id}/update_dates`,
            {
                'appointment': payload,
            }
        );
        return data.appointment;
    }
    async cancelAppointment(appointment: AppointmentOriginSendDataType, appointmentId: string | undefined) {
        return axios.put(
            `${this.urlPrefix}/appointments/${appointmentId}/cancel`,
            { appointment },
        );
    }

    async getAppointmentsBySearch(payload: pagePaginationData, searchValue: string) {
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${searchValue}`;
        const { data } = await axios.get(`${this.urlPrefix}/searches/appointments${urlParams}`);
        return data;
    }

    async getAppointment(id: string) {
        const { data } = await axios.get(`${this.urlPrefix}/appointments/${id}`);
        return data;
    }

    async getAdvisorTechLastAutocomplete(searchValue: string) {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/last_name_autocomplete?text=${searchValue}`);
        return data;
    }

    async getBranchTitlesAutocomplete(type: string, searchValue: string) {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/${type}_titles_autocomplete?text=${searchValue}`);
        return data;
    }

    async getBranchAddresses(post_code: string, branch_external_id: string | null = null): Promise<Array<BranchAddressType>> {
        const url = updateUrlGetParams(`${this.urlPrefix}/front_helpers/branch_addresses`, { post_code, branch_external_id });
        const { data } = await axios.get(url);
        return data.branch_addresses;
    }

    async getBranchAddressesVideo(post_code: string, branch_external_id: string | null = null): Promise<Array<BranchAddressType>> {
        const url = updateUrlGetParams(`${this.urlPrefix}/front_helpers/branch_addresses_with_video_room`, { post_code, branch_external_id });
        // temp mock
        // eslint-disable-next-line max-len
        // const { branch_addresses } = { 'branch_addresses': [{ 'id': '63faaaa8-7ece-4797-b7d1-873c976c257b', 'name': 'paris_01 (t tomasz)', 'address': '9 Rue Bergère, 75009 Paris', 'external_id': null, 'branch_video_rooms': [{ 'id': '4c1bb157-d9c0-4be6-8140-26418ce97418', 'name': 'Room1', 'address': null, 'external_id': null }, { 'id': '66f3742c-7c8b-44d5-b6b6-2950422188d7', 'name': 'Awesome Room Name', 'address': null, 'external_id': '9108' }] }, { 'id': '38dea927-b3ce-42bf-95d6-8d6a723f3dfa', 'name': 'tb_branch (test tine)', 'address': '321 Rue Lecourbe, 75015 Paris, France', 'external_id': null, 'branch_video_rooms': [{ 'id': 'b6570d04-c231-4b0e-91bb-a410218dae65', 'name': 'R1', 'address': null, 'external_id': null }, { 'id': '109100f0-a68c-4d70-b2cc-1ee5a55e17df', 'name': 'R2', 'address': null, 'external_id': null }] }, { 'id': 'acfd1f9f-075a-44bb-b028-0dce5bfa4076', 'name': 't_eugeny_1', 'address': 'Rue Marx Dormoy, 75018 Paris, France', 'external_id': null, 'branch_video_rooms': [{ 'id': 'a7cdbd66-2600-4bb3-8add-daac534069ae', 'name': 'Som rume', 'address': null, 'external_id': null }] }, { 'id': 'bf590b9f-7ce4-4cb7-9c4d-18b7ef914bf0', 'name': 'test (test tine)', 'address': '66 Avenue d\'Ivry, 75013 Paris, France', 'external_id': '9108', 'branch_video_rooms': [{ 'id': '35f251d3-b188-4e65-8427-44c943707af0', 'name': 'AddRoom', 'address': null, 'external_id': null }] }], 'meta': { 'current_page': 1, 'next_page': null, 'prev_page': null, 'total_count': 4, 'total_pages': 1 }, 'code': 0, 'errors': [] };
        const { data } = await axios.get(url);
        return data.branch_addresses;
    }

    async sendAddressForGeolocation(geolocation: CompanyUpdateAddressType) {
        return axios.post(
            `${this.urlPrefix}/geolocations`,
            { geolocation },
        );
    }

    async getClientDetails(payload: { [key: string]: string }): Promise<ClientType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/clients/fetch`, payload);
        const { data } = await axios.get(url);
        return data.client;
    }

    async getClientDetailsByEmail(email: string): Promise<null | ClientType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/clients`, { email });
        const { data } = await axios.get(url);
        return data.clients && data.clients.length ? data.clients[0] : null;
    }

    async getClientDetailsByPhone(phone_number: string): Promise<null | ClientType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/clients`, { phone_number });
        const { data } = await axios.get(url);
        return data.clients && data.clients.length ? data.clients[0] : null;
    }

    async getClientDetailsByPhoneFilterByID(phone_number: string): Promise<any> {
        const url = updateUrlGetParams(`${this.urlPrefix}/clients`, { phone_number });
        const { data } = await axios.get(url);
        return data.clients && data.clients.length ? data.clients : [];
    }

    async getClientDetailsByEmailFilterByID(email: string): Promise<any> {
        const url = updateUrlGetParams(`${this.urlPrefix}/clients`, { email });
        const { data } = await axios.get(url);
        return data.clients && data.clients.length ? data.clients : [];
    }

    async bookAppointmentManually(payload: any): Promise<any> {
        const { data } = await axios.post(
            `${this.urlPrefix}/appointments`,
            {
                'appointment': payload,
            }
        );
        return data.appointment;
    }

    async getComponentsUrlBuilder(user_id: string): Promise<any> {
        const url = updateUrlGetParams(`${this.urlPrefix}/components/url_builder`, { user_id });
        const { data } = await axios.get(url);
        return data;
    }

    async getAdvisorUrlsBuilder(payload: {
        advisor_id: string,
        per_page: number,
        current_page: number,
    }): Promise<any> {
        const url = updateUrlGetParams(`${this.urlPrefix}/advisor_urls`, {
            'advisor_id': payload.advisor_id,
            'pagination[per_page]': payload.per_page,
            'pagination[page]': payload.current_page,
        });
        const { data } = await axios.get(url);
        return data;
    }

    async createAdvisorUrl(postUrlBuilder: AppointmentPostUrlBuilderType): Promise<any> {
        const { title, id, user_id, sub_account_id } = postUrlBuilder;
        const { data } = await axios.post(`${this.urlPrefix}/advisor_urls`, {
            advisor_url: {
                advisor_id: user_id,
                appointment_category_id: id,
                skill_name: title,
                sub_account_id,
            },
        });
        return data;
    }

    async createUrlWithBuilder(postUrlBuilder: AppointmentPostUrlBuilderType): Promise<any> {
        const { title, id, user_id } = postUrlBuilder;
        const url = updateUrlGetParams(`${this.urlPrefix}/url_builders`, { skill_name: title, appointment_category_id: id, user_id });
        const { data } = await axios.post(url);
        return data;
    }

    async deleteAdvisorUrlBuilder(id: string): Promise<any> {
        const { data } = await axios.delete(`${this.urlPrefix}/advisor_urls/${id}`);
        return data;
    }

    async fetchSkillNames(): Promise<CategorySkillNamesResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/skill_names`);
        return data;
    }
}

const appointmentApi = new AppointmentWebApi();

export {
    appointmentApi as AppointmentWebApi,
};
