<template>
<div class='b-advisor__info'
     :class='wrapperClass'>
    <div v-if='!hideAvatar'
         class='b-advisor__avatar'
         :style='advisorStyle'>
        <div :class='{ "b-advisor__avatar__color--gradient": !advisor.id }'
             class='b-advisor__avatar__color'>
            <FwIcon
                v-if='!advisor.avatar_url'
                class='b-advisor__avatar__icon'
                icon='no-avatar'
                :size='`${avatarSize / 2}`'
                color='#fff'>
            </FwIcon>
        </div>
    </div>
    <div v-if='!hideInfo'
         class='b-advisor__inner'>
        <div class='h-flex h-flex-dir-column'>
            <div class='b-advisor__position h-flex h-flex-space-between h-align-items-center'
                 :class='[
                     nameClass,
                     { "b-advisor__position--ghost": !advisor.first_name }
                 ]'>
                <div class='b-advisor--fullname'>
                    <span v-if='advisor.first_name'>
                        {{ advisor.first_name }}
                    </span>
                    <span v-if='advisor.last_name'>
                        {{ advisor.last_name }}
                    </span>
                    <span v-if='!advisor.first_name && !advisor.last_name'>
                        {{ $tc('WORKERS.USER.NO_NAME') }}
                    </span>
                </div>
                <div v-if='advisor.is_branch_admin || advisor.is_hub_admin' class='b-advisor--admin'>
                    ADMIN
                </div>
            </div>
            <div v-if='advisor.branch_name' class='b-advisor--item__name'>
                <span>
                    {{ advisor.branch_name }}
                </span>
            </div>
            <div v-if='advisor.hub_name' class='b-advisor--item__name'>
                <span>
                    {{ advisor.hub_name }}
                </span>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { workerDataType } from '@/types/User';
import { separateColor, hexToRgbA } from '@/helpers/colors';

const WORKER_AVATAR_SIZE_SMALL: string = 'small';
const WORKER_AVATAR_SIZE_NORMAL: string = 'normal';
const AVAILABLE_SIZES: Array<string> = [WORKER_AVATAR_SIZE_SMALL, WORKER_AVATAR_SIZE_NORMAL];

const AVAILABLE_ALIGN: Array<string> = ['base', 'right'];

@Component
export default class AdvisorAvatar extends Vue {
    @Prop({ type: Object, required: true }) readonly advisor!: workerDataType;
    @Prop({ type: Number, default: 48 }) readonly avatarSize!: number;
    @Prop({ type: String, default: null }) readonly nameClass!: string;
    @Prop({ type: Boolean, default: false }) readonly hideInfo!: boolean;
    @Prop({ type: Boolean, default: false }) readonly hideAvatar!: boolean;
    @Prop({ type: Boolean, default: false }) readonly advisorTimeIcon!: boolean;
    @Prop({ type: Boolean, default: false }) readonly hideRole!: boolean;
    @Prop({ type: String, default: 'normal', validator: (type: string) => AVAILABLE_SIZES.includes(type) })
    readonly size!: string;
    @Prop({ type: String, default: 'base', validator: (align: string) => AVAILABLE_ALIGN.includes(align) })
    readonly align!: string;

    get kindText(): string | TranslateResult {
        if (this.advisor.kind.toLowerCase() === 'technician') {
            return this.$tc(`GLOBAL.TECHNICIAN`);
        }
        return this.advisor.kind;
    }

    get advisorStyle() {
        return {
            backgroundImage: `${this.avatarColor}`,
            width: `${this.avatarSize}px`,
            height: `${this.avatarSize}px`,
        };
    }
    get avatarColor() {
        let result;
        if (this.advisor.color) {
            const colorArray = separateColor(this.advisor.color);
            result = this.advisor.avatar_url ?
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0.5)},  ${hexToRgbA(colorArray[1], 0.5)}),
             url(${this.advisor.avatar_url})` :
                `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
        } else {
            result = `linear-gradient(225deg, rgba(113, 143, 157, 0), rgba(105, 131, 165, 1))`;
        }
        return result;
    }
    get wrapperClass() {
        return [
            `b-advisor__info--${this.size}`,
            `b-advisor__info--align-${this.align}`,
        ];
    }
}
</script>

<style lang='sass'>
.b-advisor
    &__avatar
        border-radius: 50%
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        position: relative
        overflow: hidden

        &__color
            opacity: 0.4
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            display: flex
            align-items: center
            justify-content: center

            &--gradient
                opacity: 1

        &__icon
            opacity: 1

    &__position
        text-transform: capitalize
        font-size: 22px
        line-height: 26px
        font-weight: 600

        &--ghost
            opacity: 0.4
    &--fullname
        width: 120px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__inner
        display: flex
        flex-direction: row
        align-items: center
        margin-left: 15px
        flex: 1

    &__info
        display: flex
        justify-content: space-between
        align-items: center
        flex: 5

        &--align-right
            align-items: flex-end
            flex: 3

        &--small
            .b-advisor__position
                font-size: 14px

            .b-advisor__avatar
                margin-right: 0
    &--item
        &__name
            width: 140px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            text-transform: uppercase
            color: $main-gray
            font-weight: 500
            font-size: 11px
            letter-spacing: 0.3px
            margin-top: 5px
    &--admin
        display: flex
        justify-content: center
        align-items: center
        font-size: 8px
        height: 16.87px
        width: 46.11px
        border-radius: 5px
        background-color: rgba(33, 63, 107, 0.1)
        color: #213F6B!important
</style>
