import Vue from 'vue';
import uiFramework from 'ui-framework';
import SimpleVueValidation from 'simple-vue-validator';
import { ReactiveRefs } from 'vue-reactive-refs';
import VueScrollTo from 'vue-scrollto';
import PortalVue from 'portal-vue';
import { AccountType } from '@/types/Account';

import { User } from '@/types/User';
// eslint-disable-next-line
import * as firebase from 'firebase/app';
import { getErrorsTemplate } from '@/helpers/translate';
import initFilters from '@/utils/filters';
import { initBugsnag } from './helpers/bugsnag';
import App from './App.vue';
import store from './store/index';
import router from './router';

import initDirectives from './utils/directives';
import CalizyGlobalMixin from './mixins/calizy-global';
import i18n from './locale';
import { getUserFromStorage, getAccountFromStorage } from './helpers/auth';

// eslint-disable-next-line
import 'firebase/firestore';
// eslint-disable-next-line
import 'firebase/analytics';

if (document.location.hostname.search('app.calizy.com') !== -1) {
    const firebaseConfig = {
        apiKey: 'AIzaSyArEKuhyvCuENErXMQdKqatoq87irUnT44',
        authDomain: 'calizy-258209.firebaseapp.com',
        databaseURL: 'https://calizy-258209.firebaseio.com',
        projectId: 'calizy-258209',
        storageBucket: 'calizy-258209.appspot.com',
        messagingSenderId: '560097469597',
        appId: '1:560097469597:web:12364c229b0eb36a08a074',
        measurementId: 'G-EVEF7MQ364',
    };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    // alias
    Vue.prototype.$analytics = firebase.analytics();
}

const user: null | User = getUserFromStorage();
const account: null | AccountType = getAccountFromStorage();
if (user) {
    store.commit('GlobalStore/mutationLoginUser', user);
}

if (account) {
    store.commit('GlobalStore/setAccount', account);
}

if (process.env.VUE_APP_DEPLOY_TYPE === 'production') {
    initBugsnag(user);
}

SimpleVueValidation.extendTemplates(getErrorsTemplate(i18n.locale));
Vue.use(SimpleVueValidation);
Vue.use(PortalVue);
Vue.mixin(CalizyGlobalMixin);
Vue.use(ReactiveRefs);
Vue.use(VueScrollTo);

Vue.config.productionTip = false;
Vue.use(uiFramework);

initFilters(Vue);

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components';
Vue.config.warnHandler = (msg: string | null, vm: Vue | null, trace: string | null) => {
    // temp solution
    if (msg && msg.startsWith(ignoreWarnMessage)) {
        msg = null;
        vm = null;
        trace = null;
    }
};
initDirectives(Vue);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
