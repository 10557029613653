<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#fff", "#fff", "#fff"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <FinalePopup
        v-if='finalePopupShow'
        :subMessage='branchData ? $t("BRANCH.FORM.UPDATE.POPUP.TEXT") : $t("BRANCH.FORM.CREATE.POPUP.TEXT")'
        @close='closeBranchPopup'>
    </FinalePopup>
    <FwPopup
        v-else-if='!loading && !confirmPopup'
        :centered='!isMobile()'
        :isFixed='isDesktop()'
        iconColor='#BEC7D4'
        max-width='1000px'
        :width='isMobile() ? "calc(100% - 20px)" : "1000px"'
        :title='branchData ? $t(`PLAN.BRANCH.TITLE.EDIT`) : $t(`PLAN.BRANCH.TITLE.ADD`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :padding='isMobile() ? "30px 20px" : "50px 50px 60px"'
        class='h-pos-rel'
        @close='closeBranchPopup'>
        <template #body>
            <AddOrEditBranchForm
                :isRequestSending='isRequestSending'
                :branchData='branchData'
                :errorFromServer='errorFromServer'
                @onSubmit='sendDataToServer'
                @delete='showConfirm'>
            </AddOrEditBranchForm>
        </template>
    </FwPopup>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`BRANCH.FORM.DELETE.POPUP.TITLE`)'
        :subMessage='$t(`BRANCH.FORM.DELETE.POPUP.TEXT`)'
        :isRequestSending='isRequestSending'
        confirmQaClass='qa-branch-page-delete-confirm-button'
        @deleteItem='deleteBranch'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import { Component } from 'vue-property-decorator';
import { omit } from 'ramda';
import { AddOrEditBranchForm } from '@/components/forms/add/AddOrEditBranchForm';
import { SitesApi } from '@/api/sites/SitesApi';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { BranchSitesDataType } from '@/types/Site';
import { DeletePopup } from '@/components/popups/DeletePopup';

const WorkerPageStore = namespace('WorkerPageStore');
@Component({
    components: {
        AddOrEditBranchForm,
        FinalePopup,
        DeletePopup,
    },
})
export default class AddOrEditBranchByForm extends Vue {
    @WorkerPageStore.Mutation('setBranchData') setBranchData!: Function;
    @WorkerPageStore.State('branchStateData') branchStateData!: BranchSitesDataType;
    errorFromServer: string | null | TranslateResult = null;
    isRequestSending: boolean = false;
    finalePopupShow: boolean = false;
    loading: boolean = false;
    confirmPopup: boolean = false;
    branchData: BranchSitesDataType | null = null;

    async sendDataToServer(data: any) {
        const newObjData: any = omit(['isActive'], data);
        this.isRequestSending = true;
        try {
            let branch;
            if (this.$route.params.id) {
                branch = await SitesApi.updateSite(newObjData, this.$route.params.id);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
            } else {
                branch = await SitesApi.createSite(newObjData);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
            }
            this.setBranchData(branch.site);
            if (data.isActive) {
                this.$router.push({ path: `/consult/branch/add-rooms/${branch.site.id}` });
                return;
            } else if (this.$route.meta && this.$route.meta.isMainComponent) {
                this.$router.push({ path: `/consult/branch/${branch.site.id}` });
            } else {
                this.$emit(`closePopup`, branch.site);
            }
            this.isRequestSending = false;
        } catch ({ response }) {
            this.isRequestSending = false;
            this.sentNotif(response);
            this.errorFromServer = response.data.errors[0];
        }
    }
    closeBranchPopup() {
        return this.$emit(`closePopup`);
    }

    showConfirm() {
        this.confirmPopup = true;
    }
    async deleteBranch() {
        this.isRequestSending = true;
        try {
            await SitesApi.deleteSite(this.$route.params.id);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.setBranchData(null);
            this.$router.push({ path: '/consult/choose/branch' });
        } finally {
            this.isRequestSending = false;
        }
    }

    async created() {
        if (this.$route.params.id) {
            this.loading = true;
            const existBranch = { site: this.branchStateData };
            const { site } = this.branchStateData ? existBranch : await SitesApi.getSite(this.$route.params.id);
            this.branchData = site;
            this.loading = false;
        }
    }
}
</script>
