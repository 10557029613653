<template>
<form :style='{ opacity: loading ? 0 : 1 }'
      @keydown.stop.prevent.enter='addAppointmentData'>
    <div v-if='showTitle'>
        <div class='b-add-edit__form__title'>
            {{ popupTitle }}
        </div>
    </div>
    <div class='b-add-edit__form__row'>
        <FwFormInput
            class='h-flex-1-0 h-ph-15'
            labelType='label-medium'
            :title='`${$t(`LABEL.CATEGORY.PLACEHOLDER`)}*`'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='appointmentCategory'
                    :propsPlaceholder='$t(`LABEL.CATEGORY.PLACEHOLDER`)'
                    type='white'
                    :disabled='disabled'
                    :searchable='false'
                    :canNotDelete='true'
                    :options='appointmentCategoriesData'>
                </FwSelect>
            </div>
        </FwFormInput>
        <FwFormInput
            class='h-flex-1-0 h-ph-15'
            :fieldHasError='fieldHasError(APPOINTMENT_NAME)'
            labelType='label-medium'
            :title='`${$t(`LABEL.TITLE`)}*`'>
            <FwInput
                :value='appointment_name'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.PLACEHOLDER.APPOINTMENT`)'
                inputStyle='white-bigger'
                :disabled='disabled'
                :error='getFieldErrorText(APPOINTMENT_NAME)'
                @update:value='value => appointment_name = value'
                @input='clearServerErrorsBase(APPOINTMENT_NAME)'
                @blur='addCurrentInputToValidateArray(APPOINTMENT_NAME)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='h-flex-1-0 h-ph-15'
            :fieldHasError='fieldHasError(APPOINTMENT_TIME)'
            labelType='label-medium'
            :title='`${$t(`LABEL.TIME.TITLE`)}*`'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='timeFrom'
                    type='white'
                    class='fw-select-with-icon--big'
                    :propsPlaceholder='$t(`LABEL.TIME.TITLE`)'
                    :withCircles='true'
                    :clearable='false'
                    :disabled='disabled'
                    :canNotDelete='true'
                    :options='duration'
                    label='title'>
                </FwSelect>
            </div>
        </FwFormInput>
    </div>
    <div class='b-add-edit__form__row'>
        <FwFormInput
            class='h-flex-1-0 h-ph-15'
            labelType='label-medium'
            :title='`${$t(`LABEL.SKILLS.NEEDED`)}*`'>
            <FwLabelCard
                :disabled='disabled'
                class='b-add-edit__form--label-card'>
                <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                    <div class='h-flex h-flex-center'>
                        <span class='b-add-worker__is_admin_active'>
                            {{ skillNeededActive ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                        </span>
                    </div>
                    <FwSwitcher
                        :isActive='skillNeededActive'
                        :disabled='disabled'
                        :isRequestSending='isActiveAdminRequestSending'
                        @toggleSwitch='toggleSkillNeededActive'>
                    </FwSwitcher>
                </div>
            </FwLabelCard>
        </FwFormInput>
        <FwSpinLoader
            v-if='skillsLoading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader h-pos-rel'
            :style='{ left: "55px", width: "0px" }'
            :isActive='skillsLoading'
            className='h-p-20 h-mt-15'>
        </FwSpinLoader>
        <FwFormInput
            v-else-if='skillNeededActive'
            class='h-flex-1-0 h-ph-15'
            labelType='label-medium'
            :title='$t(`SKILLS.YOU.NEED.SELECT`)'>
            <div class='fw-select-base'>
                <FwSelect
                    v-model='skillChoosed'
                    class='fw-select-with-icon--big'
                    :propsPlaceholder='$t(`SKILLS.YOU.NEED.SELECT`)'
                    type='white'
                    :searchable='false'
                    :disabled='disabled'
                    :label='labelKey'
                    :multiple='true'
                    :clearable='false'
                    :withCircles='true'
                    :canNotDelete='false'
                    :options='skills'>
                </FwSelect>
            </div>
        </FwFormInput>
        <div v-if='!skillNeededActive'
             class='h-flex-1-0 h-ph-15'></div>
        <div class='h-flex-1-0 h-ph-15'></div>
    </div>
    <div v-if='skillNeededActive && !disabled' class='h-mh-15 h-mv-30 h-flex'>
        <FwIcon
            class='h-flex-center h-pointer'
            icon='alert-info'
            size='18'
            color='#213F6B'>
        </FwIcon>
        <p class='h-ml-15 h-font-14 h-fw-500'>
            {{ $t('SKILLS.YOU.NEED.INFO') }}
            <a class='h-fw-700'
               href='mailto:hello@calizy.com'
               style='text-decoration: underline'>
                {{ $t('SKILLS.YOU.NEED.INFO.CONTACT') }}
            </a>
        </p>
    </div>
    <div v-if='categoryLocationSkillNames.length'
         class='h-mt-20 b-add-edit__form__input'>
        <label class='fw-form-input__label label-medium'>
            {{ $t('LABEL.METHODS') }}*
        </label>
        <FwCheckbox
            v-for='item in categoryLocationSkillNames'
            :id='item.id'
            :key='item.id'
            v-model='checkboxLocation'
            class='h-mr-10'
            :value='$t(item)'
            type='plus'
            :disabled='disabled'
            :text='$t(item[labelKey])'
            iconRightSide='plus'
            iconCheckedSize='12'
            name='checkbox'>
        </FwCheckbox>
        <div v-if='!disabled'
             class='h-mt-10 h-mb-30 b-required-field'>
            * {{ $t(`LABEL.REQUIRED.FIELD`) }}
        </div>
    </div>
</form>
</template>

<script lang='ts'>
import { State, namespace } from 'vuex-class';
import { Component, Watch, Mixins, Prop, Emit } from 'vue-property-decorator';
import { CreateAppointmentCategoryPayloadType, CategoryLocationSkillNameType, StartAppointmentCategoryDataType, CategorySkillNameType } from '@/types/Appointment';
import { duration } from '@/helpers/duration';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import i18n from '@/locale';
import { isEqual } from '@/helpers/base';
import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';

const OnboardingStore = namespace('OnboardingStore');

@Component({
    validators: {
        appointment_id(value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class AddAppointmentTypeForm extends Mixins(ValidationMixin, TranslateMixin, PermissionsMixin, AccountMixin) {
    @State(state => state.OnboardingStore.categoryLocationSkillNames) categoryLocationSkillNames!: Array<CategoryLocationSkillNameType>;
    @State(state => state.OnboardingStore.appointmentCategoriesData) appointmentCategoriesData!: Array<string>;
    @State(state => state.OnboardingStore.skills) skills!: Array<CategorySkillNameType>;
    @OnboardingStore.Action('fetchCategoryLocationSkillNames') fetchCategoryLocationSkillNames!: () => Promise<void>;
    @OnboardingStore.Action('fetchAppointmentCategoriesData') fetchAppointmentCategoriesData!: () => Promise<void>;
    @OnboardingStore.Action('fetchSkills') fetchSkills!: () => Promise<void>;

    @Prop({ type: Object, default: null }) readonly startAppointmentCategoryData!: StartAppointmentCategoryDataType;
    @Prop({ type: Boolean, default: false }) readonly showTitle!: boolean;
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

    appointmentCategory: string = '';
    appointment_id: string = '';
    appointment_name: string | null = '';
    timeFrom: { duration: number, title: string } | null = null;
    skillsLoading: boolean = false;
    skillNeededActive: boolean = false;
    loading: boolean = false;
    savedStartAppointmentCategoryDataType: null | StartAppointmentCategoryDataType = null;
    skillChoosed: Array<CategorySkillNameType | CategoryLocationSkillNameType> = [];
    checkboxLocation: Array<CategoryLocationSkillNameType> = [];
    duration: Array<{ duration: number, title: string }> = duration;

    serverErrors: serverErrors = {
        appointment_name: null,
    };

    get disabledFormButton(): boolean {
        return !(
            this.appointment_name &&
            this.timeFrom &&
            this.appointmentCategory &&
            this.checkboxLocation.length &&
            (!this.skillNeededActive || (this.skillNeededActive && this.skillChoosed.length))
        ) || this.isEqual;
    }

    get popupTitle(): string {
        if (!this.startAppointmentCategoryData) {
            return i18n.tc('PLAN.APPOINTMENT.ADD.HEADER');
        }
        return this.canEdit ? i18n.tc('PLAN.APPOINTMENT.EDIT.HEADER') : i18n.tc('LABEL.APPOINTMENT.TYPE');
    }

    get isEqual(): boolean {
        if (this.savedStartAppointmentCategoryDataType) {
            return isEqual(this.savedStartAppointmentCategoryDataType, this.currentForm);
        }
        return false;
    }

    get currentForm(): StartAppointmentCategoryDataType {
        return {
            title: this.appointment_name || '',
            appointmentCategory: this.appointmentCategory,
            timeFrom: this.timeFrom,
            skillNeededActive: this.skillNeededActive,
            skillChoosed: this.skillChoosed,
            checkboxLocation: this.checkboxLocation,
            id: this.appointment_id,
        };
    }

    async toggleSkillNeededActive() {
        if (!this.skillNeededActive && !this.skills.length) {
            this.skillsLoading = true;
            await this.fetchSkills();
            this.skillsLoading = false;
        }
        this.skillNeededActive = !this.skillNeededActive;
    }

    addAppointmentData(): void {
        const skills = this.skillNeededActive ? this.skillChoosed : [];
        const skill_ids = [...skills.map(item => item.id), ...this.checkboxLocation.map(item => item.id)];
        this.emitAddAppointmentDataHandler({
            appointment_category: {
                title: (this.appointment_name as string),
                kind: this.appointmentCategory,
                duration: this.timeFrom ? this.timeFrom.duration : 0,
                skill_ids,
            },
        });
    }

    @Watch('disabledFormButton')
    disabledFormButtonHandler(value: boolean): void {
        this.changeDisabledFormButtonHandler(value);
    }

    @Watch('currentForm')
    currentFormHandler(): void {
        this.addAppointmentData();
    }

    @Emit('emitAddAppointmentData')
    emitAddAppointmentDataHandler(payload: CreateAppointmentCategoryPayloadType) {}

    @Emit('changeDisabledFormButton')
    changeDisabledFormButtonHandler(value: boolean): boolean {
        return value;
    }

    async created() {
        if (!this.categoryLocationSkillNames.length || !this.appointmentCategoriesData.length) {
            this.loading = true;
            if (!this.categoryLocationSkillNames.length) {
                await this.fetchCategoryLocationSkillNames();
            }

            if (!this.appointmentCategoriesData.length) {
                await this.fetchAppointmentCategoriesData();
                this.appointmentCategory = '';
            }
            this.loading = false;
        }

        if (this.startAppointmentCategoryData) {
            this.checkboxLocation = this.startAppointmentCategoryData.checkboxLocation || [];
            this.skillNeededActive = this.startAppointmentCategoryData.skillNeededActive || false;
            this.skillChoosed = this.startAppointmentCategoryData.skillChoosed || [];
            this.appointmentCategory = this.startAppointmentCategoryData.appointmentCategory;
            this.timeFrom = this.startAppointmentCategoryData.timeFrom;
            this.appointment_name = this.startAppointmentCategoryData.title;
            this.appointment_id = this.startAppointmentCategoryData.id;

            this.savedStartAppointmentCategoryDataType = JSON.parse(JSON.stringify(this.startAppointmentCategoryData));
        }
    }
}
</script>

<style lang='sass'>
.b-add-edit__form__title
    margin-bottom: 25px
    font-size: 20px
    width: 100%
    text-align: center
    font-weight: 500
</style>
