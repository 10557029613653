<template>
<div class='b-settings-reminder'>
    <FwSpinLoader
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader h-width-100p h-height-100p'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-if='!loading'>
        <div v-show='!reminder'>
            <div class='b-settings__title'>
                {{ $t('SETTINGS.MENU.APPT.REMINDERS') }}
            </div>
            <div v-if='cardsData'
                 class='b-settings-reminder__wrapper'>
                <div v-for='item in cardsData'
                     class='b-settings-reminder__inner'>
                    <div class='b-settings-reminder__card'
                         @click='editReminder(item)'>
                        <CardWithList
                            :title='item.title'
                            :canRead='canEdit || canRead'
                            :data='item.data'>
                        </CardWithList>
                    </div>
                </div>
            </div>
        </div>

        <div v-if='reminder'>
            <div class='h-flex-center'>
                <GoBackButton
                    :method='closeReminder'
                    :isRelativePosition='true'>
                </GoBackButton>
                <div class='b-settings__title h-ml-20'>
                    {{ $t(getTitleByConfig(reminder)) }} ({{ getTypeByConfig }})
                </div>
            </div>
            <div class='b-settings-reminder__sort__wrapper'>
                <div></div>
                <FwButton
                    v-if='canEdit'
                    class='h-mt-20'
                    size='little'
                    :disabled='isSaveDisabled'
                    borderRadiusType='small-border'
                    @click.native='updateNotifications'>
                    {{ $t('BUTTON.SAVE') }}
                </FwButton>
            </div>
            <div class='h-mt-30 b-settings-reminder__table'>
                <table class='b-settings-reminder__table-inner'
                       cellspacing='0'
                       cellpadding='0'>
                    <colgroup>
                        <col width='70%'>
                        <col width='30%'>
                    </colgroup>
                    <thead>
                        <tr>
                            <th class='h-pb-10'>
                                &nbsp;
                            </th>
                            <th v-if='isEmailType'
                                class='h-pb-10 h-uppercase'>
                                &nbsp;
                            </th>
                            <th v-if='isSMSType'
                                class='h-pb-10 h-uppercase'>
                                &nbsp;
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='(item, key) in configMapping'>
                            <th class='b-settings-reminder__table__row-title'>
                                {{ item }}
                            </th>
                            <th v-if='isEmailType'>
                                <FwSwitcher
                                    :disabled='!canEdit'
                                    class='h-width-40'
                                    :isActive='email_config[key]'
                                    @toggleSwitch='email_config[key] = !email_config[key]'>
                                </FwSwitcher>
                            </th>
                            <th v-if='isSMSType'>
                                <FwSwitcher
                                    :disabled='!canEdit'
                                    class='h-width-40'
                                    :isActive='sms_config[key]'
                                    @toggleSwitch='sms_config[key] = !sms_config[key]'>
                                </FwSwitcher>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class='b-settings-reminder__title'>
                {{ $t('REMINDERS.MAX') }}
            </div>

            <div class='h-mt-30 b-settings-reminder__table'>
                <table class='b-settings-reminder__table-inner'
                       cellspacing='0'
                       cellpadding='0'>
                    <colgroup>
                        <col width='70%'>
                        <col width='30%'>
                    </colgroup>
                    <tbody>
                        <tr v-for='reminderRow in reminderTableData'>
                            <th class='b-settings-reminder__table__row-title'>
                                {{ reminderRow.title }}
                            </th>
                            <th v-if='isEmailType'>
                                <FwSwitcher
                                    class='h-width-40'
                                    :disabled='!canEdit || isEmailDisabled(reminderRow.value)'
                                    :isActive='email_first_reminder === reminderRow.value || email_second_reminder === reminderRow.value'
                                    @toggleSwitch='changeEmailReminder(reminderRow.value)'>
                                </FwSwitcher>
                            </th>
                            <th v-if='isSMSType'>
                                <FwSwitcher
                                    class='h-width-40'
                                    :disabled='!canEdit || isSMSDisabled(reminderRow.value)'
                                    :isActive='sms_first_reminder === reminderRow.value || sms_second_reminder === reminderRow.value'
                                    @toggleSwitch='changeSMSReminder(reminderRow.value)'>
                                </FwSwitcher>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </template>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { clone } from 'ramda';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { CardWithList } from '@/components/simple/CardWithList';
import {
    SettingsCardListItemType,
    SettingsNotificationResponseType,
    SettingsReminderConfigType,
    SettingsReminderValueType,
    SettingsNotificationFormDataType,
} from '@/types/Settings';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { isEqual } from '@/helpers/base';
import i18n from '@/locale';
import PermissionsMixin from '@/mixins/permissions';

const CARD_TYPE_EMAIL = `email`;
const CARD_TYPE_SMS = `sms`;

type ReminderPreparedType = { title: string, type: 'client' | 'advisor', cardType: any, data: Array<SettingsCardListItemType>}

function getConfig(config: SettingsReminderConfigType, reminder: boolean) {
    return [
        {
            text: i18n.tc('REMINDERS.BOOKING').toUpperCase(),
            active: config.book,
        },
        {
            text: i18n.tc('REMINDERS.UPDATE').toUpperCase(),
            active: config.update,
        },
        {
            text: i18n.tc('REMINDERS.CANCELLATION').toUpperCase(),
            active: config.cancel,
        },
        {
            text: i18n.tc('REMINDERS.REMINDER').toUpperCase(),
            active: reminder,
        },
    ];
}

@Component({
    components: {
        CardWithList,
        GoBackButton,
    },
})
export default class SettingsAppointmentRemindersPage extends Mixins(PermissionsMixin) {
    reminder: SettingsNotificationResponseType | null = null;
    data: Array<SettingsNotificationResponseType> | null = null;
    loading: boolean = false;
    currentType: 'email' | 'sms' = CARD_TYPE_EMAIL;

    sms_config: SettingsReminderConfigType = {
        book: false,
        update: false,
        cancel: false,
        remind: false,
    }

    email_config: SettingsReminderConfigType = {
        book: false,
        update: false,
        cancel: false,
        remind: false,
    }

    configMapping = {
        book: i18n.tc('REMINDERS.BOOKING'),
        update: i18n.tc('REMINDERS.UPDATE'),
        cancel: i18n.tc('REMINDERS.CANCELLATION'),
    }

    email_first_reminder: SettingsReminderValueType | null = null;
    email_second_reminder: SettingsReminderValueType | null = null;
    sms_first_reminder: SettingsReminderValueType | null = null;
    sms_second_reminder: SettingsReminderValueType | null = null;

    reminderTableData: Array<{ title: string, value: SettingsReminderValueType }> = [
        {
            title: i18n.tc('REMINDERS.2.HOURS'),
            value: 2,
        },
        {
            title: i18n.tc('REMINDERS.1.DAY'),
            value: 24,
        },
        {
            title: i18n.tc('REMINDERS.2.DAYS'),
            value: 48,
        },
        {
            title: i18n.tc('REMINDERS.1.WEEK'),
            value: 168,
        },
    ]

    startFormData: SettingsNotificationFormDataType | null = null

    get cardsData(): Array<ReminderPreparedType> {
        const cardsData: Array<ReminderPreparedType> = [];
        if (!this.data) return cardsData;
        for (let i = 0; i < this.data.length; i++) {
            const config: SettingsNotificationResponseType = this.data[i];
            const cardDataEmail: ReminderPreparedType = {
                title: `${this.getTitleByConfig(config)} (${i18n.tc('LABEL.EMAIL')})`,
                type: config.profile,
                cardType: CARD_TYPE_EMAIL,
                data: getConfig(config.email_config, Boolean(config.email_first_reminder || config.email_second_reminder)),
            };
            const cardDataSMS: ReminderPreparedType = {
                title: `${this.getTitleByConfig(config)} (SMS)`,
                type: config.profile,
                cardType: CARD_TYPE_SMS,
                data: getConfig(config.sms_config, Boolean(config.sms_first_reminder || config.sms_second_reminder)),
            };
            cardsData.push(cardDataEmail, cardDataSMS);
        }

        return cardsData.filter(item => !(item.cardType === 'sms' && item.type === 'advisor'));
    }

    get isSaveDisabled() {
        return isEqual(this.startFormData, this.currentFormData);
    }

    get currentFormData(): SettingsNotificationFormDataType | null {
        return this.data ? this.prepareCopyOfFormData() : null;
    }

    get isSMSType(): boolean {
        return this.currentType === CARD_TYPE_SMS;
    }

    get isEmailType(): boolean {
        return this.currentType === CARD_TYPE_EMAIL;
    }

    get getTypeByConfig() {
        return this.isSMSType ? 'SMS' : i18n.tc(`LABEL.EMAIL`);
    }

    changeEmailReminder(value: SettingsReminderValueType) {
        if (this.email_first_reminder === value) {
            this.email_first_reminder = null;
        } else if (this.email_second_reminder === value) {
            this.email_second_reminder = null;
        } else if (this.email_first_reminder === null) {
            this.email_first_reminder = value;
        } else if (this.email_second_reminder === null) {
            this.email_second_reminder = value;
        }
    }

    changeSMSReminder(value: SettingsReminderValueType) {
        if (this.sms_first_reminder === value) {
            this.sms_first_reminder = null;
        } else if (this.sms_second_reminder === value) {
            this.sms_second_reminder = null;
        } else if (this.sms_first_reminder === null) {
            this.sms_first_reminder = value;
        } else if (this.sms_second_reminder === null) {
            this.sms_second_reminder = value;
        }
    }

    isEmailDisabled(value: SettingsReminderValueType) {
        return this.email_first_reminder && this.email_second_reminder && ![this.email_first_reminder, this.email_second_reminder].includes(value);
    }

    isSMSDisabled(value: SettingsReminderValueType) {
        return this.sms_first_reminder && this.sms_second_reminder && ![this.sms_first_reminder, this.sms_second_reminder].includes(value);
    }

    getTitleByConfig(reminder: SettingsNotificationResponseType): string {
        return reminder.profile === `client` ? i18n.tc('REMINDERS.FINAL.CUSTOMER') : i18n.tc(`LABEL.ADVISOR`);
    }

    editReminder(item: ReminderPreparedType) {
        if (!this.canEdit || !this.canRead) return;
        this.currentType = item.cardType;
        this.reminder = this.data!.find(config => config.profile === item.type)!;
        this.sms_config = clone(this.reminder.sms_config);
        this.sms_first_reminder = this.reminder.sms_first_reminder;
        this.sms_second_reminder = this.reminder.sms_second_reminder;
        this.email_config = clone(this.reminder.email_config);
        this.email_first_reminder = this.reminder.email_first_reminder;
        this.email_second_reminder = this.reminder.email_second_reminder;
        this.startFormData = this.prepareCopyOfFormData();
    }

    closeReminder() {
        this.reminder = null;
        this.startFormData = null;
    }

    prepareCopyOfFormData(): SettingsNotificationFormDataType {
        return clone({
            sms_config: { ...this.sms_config, remind: Boolean(this.sms_first_reminder || this.sms_second_reminder) },
            sms_first_reminder: this.sms_first_reminder,
            sms_second_reminder: this.sms_second_reminder,
            email_config: { ...this.email_config, remind: Boolean(this.email_first_reminder || this.email_second_reminder) },
            email_first_reminder: this.email_first_reminder,
            email_second_reminder: this.email_second_reminder,
        });
    }

    async getNotifications() {
        this.loading = true;
        try {
            this.data = await SettingsWebApi.getAppointmentsNotifications();
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async updateNotifications() {
        if (this.isSaveDisabled) return;
        try {
            await SettingsWebApi.updateAppointmentsNotification(this.reminder!.id, (this.currentFormData as SettingsNotificationFormDataType));
            this.closeReminder();
            this.getNotifications();
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async created() {
        this.getNotifications();
    }
};
</script>

<style lang='sass'>
.b-settings-reminder
    &__search
        width: 350px

    &__sort
        width: 350px

        &__wrapper
            margin-top: 10px
            display: flex
            justify-content: space-between

    &__inner
        flex: 0 0 50%
        padding: 10px

    &__wrapper
        display: flex
        margin: 30px -20px
        flex-wrap: wrap

    &__card
        flex: 1 0 50%

    &__title
        font-size: 18px
        margin-top: 30px
        font-weight: 700
        margin-bottom: -15px

    &__table
        tbody
            border-collapse: separate
            border-spacing: 10px
            tr
                border-radius: 10px
                box-shadow: $big-box-shadow
                padding: 10px
                padding-bottom: 7px

                th
                    background-color: #fff
                    padding: 10px
                    margin-bottom: 10px

            tr:not(:last-of-type)
                th
                    border-bottom: 10px solid #F8F9FA

            tr:first-of-type th:first-child
                border-top-left-radius: 10px

            tr:first-of-type th:last-child
                border-top-right-radius: 10px

            tr:last-of-type th:first-child
                border-bottom-left-radius: 10px

            tr:last-of-type th:last-child
                border-bottom-right-radius: 10px

        &__row-title
            text-align: left

    &__table-inner
        width: 100%
        max-width: 550px

    .fw-switcher--disabled
        cursor: not-allowed
        .fw-switcher__slider
            background-color: #eee

        .fw-switcher__circle
            background-color: #eee

.h-uppercase
    text-transform: uppercase
</style>
