import { namespace, State } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { uniq } from 'ramda';
import { BranchKpiCategoryNamesType } from '@/types/Branch';
import { StatisticsKPIByCategoryType, TableStatisticDataType } from '@/types/Statistics';

const StatisticsStore = namespace('StatisticsStore');

@Component
export default class BranchStatisticsMixin extends Vue {
    @State(state => state.StatisticsStore.dates) dates!: Array<Date>;
    @StatisticsStore.Mutation('setStatisticDates') setStatisticDates!: (statisticDates: Array<Date>) => void;

    @Prop({ type: Object, required: true }) readonly data!: { [key: string]: string };
    @Prop({ type: Array, required: true }) readonly ordering!: Array<string>;
    @Prop({ type: Number, required: true }) readonly total!: number;
    @Prop({ type: Boolean, required: true }) readonly loading!: boolean;
    @Prop({ type: Boolean, required: true }) readonly tableLoading!: boolean;
    @Prop({ type: Date, required: true }) readonly from!: Date;
    @Prop({ type: Date, required: true }) readonly to!: Date;
    @Prop({ type: Object, required: true }) readonly statistic!: StatisticsKPIByCategoryType;

    @Prop({ type: Array, required: true }) readonly category_names!: Array<BranchKpiCategoryNamesType>;

    showSelectCategory: boolean = false;

    get categoryNamesFiltered(): Array<BranchKpiCategoryNamesType> {
        return this.category_names.filter(category => !Object.keys(this.statistic).includes(category.title));
    }

    get showStatistic(): boolean {
        return !this.loading && Boolean(this.data && Object.keys(this.data).length);
    }

    get statisticTableData(): TableStatisticDataType {
        const typesData: Array<{ [key: string]: number }> = Object.values(this.statistic);
        let typesAll: Array<string> = [];
        for (let i = 0; i < typesData.length; i++) {
            const type: Array<string> = Object.keys(typesData[i]);
            typesAll = typesAll.concat(type);
        }
        typesAll = uniq(typesAll);
        const types = typesAll.map(item => ({ key: item, type: item }));
        const data: Array<{ key: string, text: string, values: { [key: string]: number }}> = [];

        const ids: Array<string> = Object.keys(this.statistic);
        for (let i = 0; i < ids.length; i++) {
            const id: string = ids[i];
            const values: { [key: string]: number } = this.statistic[id];
            for (let j = 0; j < typesAll.length; j++) {
                if (!(typesAll[j] in values)) {
                    values[typesAll[j]] = 0;
                }
            }
            data.push({
                key: id,
                text: id,
                values: this.statistic[id],
            });
        }

        return {
            data,
            types,
        };
    }
};
