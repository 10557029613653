<template>
<div class='b-calendar__table__buttons'
     :style='{
         left: left && left >= 0 ? `${left}px` : null,
         position,
     }'>
    <div v-if='canEdit || canRead'
         class='b-calendar__table__item__delete h-pointer h-width-60'
         @click='$emit("edit")'>
        <FwIcon
            class='h-flex-center h-pointer'
            icon='edit-settings'
            size='18'
            color='rgba(33,63,107,.3)'>
        </FwIcon>
    </div>
    <div v-if='withArchiveLogic'
         class='b-calendar__table__item__delete h-ml-10 h-pointer h-width-60'
         @click='$emit("archive")'>
        <FwIcon
            class='h-flex-center h-pointer'
            :icon='isArchive ? "unarchive" : "archive"'
            size='18'
            color='rgba(33,63,107,.3)'>
        </FwIcon>
    </div>
    <div v-if='canDelete'
         class='b-calendar__table__item__delete h-ml-10 h-pointer h-width-60'
         @click='$emit("delete")'>
        <FwIcon
            class='h-flex-center h-pointer'
            icon='bucket-edit'
            size='18'
            color='rgba(33,63,107,.3)'>
        </FwIcon>
    </div>
    <div v-if='isRoomButton' class='h-flex h-flex-wrap'>
        <FwButton
            size='large-xl'
            color='choose'
            borderRadiusType='tiny-border'
            style='width: 220px'
            class='h-ml-10'
            :fontSize='`16px`'
            @click.native='$emit(`goNextPage`, branch)'>
            <span style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>
                {{ $t('MANAGE.ROOMS.TITLE') }}
            </span>
            <FwIcon
                icon='arrow-right'
                size='12'
                color='#37e6c9'>
            </FwIcon>
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import PermissionsMixin from '@/mixins/permissions';

@Component
export default class CalendarTableButtons extends Mixins(PermissionsMixin) {
    @Prop({ type: Boolean, default: false }) readonly isRoomButton!: boolean;
    @Prop({ type: Boolean, default: false }) readonly withArchiveLogic!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isArchive!: boolean;

    left: number | null = null;
    position: string = `sticky`;

    mounted(): void {
        const viewportOffset = this.$el.getBoundingClientRect();
        // this.left = viewportOffset.left;
        // console.log(this.left);
        // this.position = `absolute`;
    }
}
</script>
