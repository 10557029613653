const DAILY: string = 'daily';
const WEEKLY: string = 'weekly';
const MONTHLY: string = 'monthly';
const YEARLY: string = 'yearly';

export {
    DAILY,
    WEEKLY,
    MONTHLY,
    YEARLY,
};
