<template>
<div class='b-add-worker__wrapper container'>
    <div class='b-base-title__wrapper'>
        <h2 class='b-appointment-left-bar__title'>
            {{ $route.params.id ? $t('ADVISOR.FORM.EDIT.TITLE') : $t('ADVISOR.FORM.TITLE') }}
        </h2>
    </div>
    <AddEditGeograficPopup
        v-if='isGeograficPopup'
        @closePopup='closePopup'
        @close='isGeograficPopup = false'>
    </AddEditGeograficPopup>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <form v-else
          @keydown.stop.prevent.enter='sendDataToServer'>
        <h2 class='h-font-22'>
            {{ $t('SELECT.ADDRESS.BRANCH.CONTACT.DETAILS') }}
        </h2>
        <div class='h-flex h-flex-space-between h-pb-30'>
            <FwFormInput
                class='h-flex-1-0 h-mr-30'
                :fieldHasError='fieldHasError(FIRST_NAME)'
                labelType='label-medium'
                :title='`${$t(`WORKER.FORM.FIRST.NAME.LABEL`)}*`'>
                <FwInput
                    :value='first_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`WORKER.FORM.FIRST.NAME.PLACEHOLDER`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(FIRST_NAME)'
                    @update:value='value => first_name = value'
                    @input='clearServerErrorsBase(FIRST_NAME)'
                    @blur='addCurrentInputToValidateArray(FIRST_NAME)'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-1-0 h-mr-30'
                :fieldHasError='fieldHasError(LAST_NAME)'
                labelType='label-medium'
                :title='`${$t(`WORKER.FORM.LAST.NAME.LABEL`)}*`'>
                <FwInput
                    :value='last_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`WORKER.FORM.LAST.NAME.PLACEHOLDER`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(LAST_NAME)'
                    @update:value='value => last_name = value'
                    @input='clearServerErrorsBase(LAST_NAME)'
                    @blur='addCurrentInputToValidateArray(LAST_NAME)'>
                </FwInput>
            </FwFormInput>

            <FwFormInput
                class='h-flex-1-0'
                labelType='label-medium'
                :title='`${$t(`LABEL.EXTERNAL.ID`)}`'>
                <FwInput
                    :value='remote_id'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EXTERNAL.ID`)'
                    inputStyle='white-bigger'
                    @update:value='value => remote_id = value'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-flex-space-between h-pb-30'>
            <FwFormInput
                class='h-flex-1-0 h-mr-30'
                labelType='label-medium'
                :title='`${$t(`WORKER.FORM.PHONE.LABEL`)}`'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    :placeholder='$t(`WORKER.FORM.PHONE.PLACEHOLDER`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => phone = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0'
                :fieldHasError='fieldHasError(EMAIL)'
                labelType='label-medium'
                :title='`${$t(`WORKER.FORM.EMAIL.LABEL`)}*`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`WORKER.FORM.EMAIL.PLACEHOLDER`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(EMAIL)'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase(EMAIL)'
                    @blur='addCurrentInputToValidateArray(EMAIL)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-flex-space-between h-pb-30'>
            <FwFormInput
                v-if='skills'
                labelType='label-medium'
                class='h-flex-1-0 h-mr-30'
                :title='`${$t(`WORKER.FORM.SKILLS.LABEL`)}`'>
                <div class='fw-select-base'>
                    <FwSelect
                        v-model='skillChoosed'
                        class='fw-select-with-icon--big'
                        :propsPlaceholder='$t(`SKILLS.YOU.NEED.SELECT`)'
                        type='white'
                        :searchable='false'
                        :label='labelKey'
                        openDirection='bottom'
                        :multiple='true'
                        :clearable='false'
                        :withCircles='true'
                        :canNotDelete='false'
                        :options='skills'>
                    </FwSelect>
                </div>
            </FwFormInput>
            <FwFormInput
                v-if='categoryLocationSkills'
                labelType='label-medium'
                class='h-flex-1-0'
                :title='`${$t(`LABEL.APPOINTMENT.METHODS`)}*`'>
                <div class='fw-select-base'>
                    <FwSelect
                        v-model='locationSkillChoosed'
                        class='fw-select-with-icon--big'
                        :propsPlaceholder='$t(`WORKER.FORM.SKILLS.PLACEHOLDER`)'
                        type='white'
                        :searchable='false'
                        :label='labelKey'
                        openDirection='bottom'
                        :multiple='true'
                        :clearable='false'
                        :withCircles='true'
                        :canNotDelete='false'
                        :options='categoryLocationSkills'>
                    </FwSelect>
                </div>
            </FwFormInput>
        </div>
        <template v-if='!isTypeFaceToFace'>
            <div v-if='geograficIds.length !== 0' class='h-pos-rel'>
                <FwFormInput
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                    <div class='h-flex h-flex-wrap h-pb-30' style='margin: 0 -15px;'>
                        <div v-for='item in geograficIds'
                             :key='item'
                             style='width: 435px; height: 60px;'
                             class='b-team-list h-mh-15'>
                            <div class='b-team-list__body'>
                                <div class='h-fw-500'>
                                    {{ item.post_code }} - {{ item.name }}
                                </div>
                            </div>
                            <div class='b-team-list__delete'
                                 @click='deleteItem(item)'>
                                <FwIcon
                                    class='h-flex-center h-pointer'
                                    icon='bucket-edit'
                                    size='20'
                                    color='rgba(33,63,107,.3)'
                                    @click.native='confirmPopup = true'>
                                </FwIcon>
                            </div>
                        </div>
                    </div>
                </FwFormInput>
                <div class='h-pos-abs h-fw-500 h-font-14 h-pointer'
                     style='top: 0; left: 150px;color: #27dbbd;'
                     @click='openPopup'>
                    {{ $t('LABEL.GEO.ZONE') }}
                </div>
            </div>
            <div v-else style='width: 310px' class='h-flex h-flex-space-between h-pb-30'>
                <FwFormInput
                    labelType='label-medium'
                    class='h-flex-1-0 h-mr-30'
                    :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                    <div class='fw-select-base'>
                        <div class='b-team-list__body h-pointer'
                             @click='openPopup'>
                            <div class='h-fw-500' style='color: rgba(33,63,107,.5);'>
                                {{ $t('WORKER.FORM.GEOGRAFIC.SELECT.PLACEHOLDER') }}
                            </div>
                            <FwIcon
                                class='h-flex-center h-pointer'
                                icon='edit-settings'
                                size='20'
                                color='#203f6a'>
                            </FwIcon>
                        </div>
                    </div>
                </FwFormInput>
            </div>
        </template>
        <h2 class='h-font-22'>
            {{ $t('Manager') }}
        </h2>
        <div class='h-flex h-pb-30'>
            <div style='width: 310px'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    :fieldHasError='fieldHasError(ADMIN)'
                    labelType='label-medium'
                    :title='`${$t(`Manager`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isActiveManager ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isActiveManager'
                                :isRequestSending='isActiveAdminRequestSending'
                                @toggleSwitch='toggleAdmin'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
            </div>
            <FwSpinLoader
                v-if='isLoadingRoles'
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-flex-center loader'
                :isActive='isLoadingRoles'
                className='h-p-20'>
            </FwSpinLoader>
            <div v-else style='width: 310px' class='h-pb-30'>
                <FwFormInput
                    v-if='isActiveManager'
                    labelType='label-medium'
                    class='h-flex-1-0 h-mr-30'
                    :title='`${$t(`WORKERS.FORM.INPUT.ROLE.LABEL`)}*`'>
                    <div class='fw-select-base'>
                        <FwSelect
                            v-model='roleValue'
                            type='white'
                            :propsPlaceholder='`${$t(`WORKERS.FORM.INPUT.ROLE.CHOOSE`)}`'
                            label='name'
                            :canNotDelete='true'
                            :searchable='false'
                            :multiply='false'
                            :options='roles'>
                        </FwSelect>
                    </div>
                </FwFormInput>
            </div>
        </div>
        <div v-if='(user().account_organization_type=== `with_hubs` ||
            user().account_organization_type === `with_all`) && !isBranchHubHide'>
            <h2 class='h-font-22'>
                {{ $t('WORKER.FORM.HUB.MEMBERSHIP') }}
            </h2>
            <div class='h-flex h-flex-justify-start h-pb-30'>
                <FwFormInput
                    style='width: 280px'
                    class='h-mr-30'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.HUB.LABEL`)}${user().account_organization_type === `with_all` ? `**` : `*`}`'>
                    <div class='fw-select-base'>
                        <FwSelect
                            v-if='(hubs ||
                                user().account_organization_type === `with_hubs` ||
                                user().account_organization_type === `with_all`) && !isBranchHubHide'
                            v-model='hubValue'
                            type='white'
                            :propsPlaceholder='`${$t(`WORKER.FORM.HUB.PLACEHOLDER`)}`'
                            label='name'
                            :disabled='hubValueFromServer'
                            :canNotDelete='user().account_organization_type !== `with_all`'
                            :searchable='false'
                            :multiply='false'
                            :options='hubs'>
                        </FwSelect>
                    </div>
                </FwFormInput>
                <FwFormInput
                    style='width: 280px'
                    class='h-mr-30'
                    :fieldHasError='fieldHasError(ADMIN)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.HUB.ADMIN.LABEL`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isActiveHubAdmin ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isActiveHubAdmin'
                                :isRequestSending='isActiveAdminRequestSending'
                                @toggleSwitch='isActiveHubAdmin = !isActiveHubAdmin'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
                <FwFormInput
                    v-if='isMatmutAccount'
                    style='width: 280px'
                    class='h-mr-30'
                    :fieldHasError='fieldHasError("code_site")'
                    labelType='label-medium'
                    title='Code site'>
                    <FwInput
                        :value='code_site'
                        type='text'
                        name='text'
                        :disabled='disabledForm'
                        inputStyle='white-bigger'
                        placeholder='ex: 123 456'
                        @update:value='value => code_site = value'>
                    </FwInput>
                </FwFormInput>
            </div>
        </div>
        <div v-if='($route.meta.showBranchesForm ||
            user().account_organization_type === `with_branches` ||
            user().account_organization_type === `with_all`) && !isBranchHubHide'>
            <h2 class='h-font-22'>
                {{ $t('WORKER.FORM.BRANCH.MEMBERSHIP') }}
            </h2>
            <div class='h-flex h-flex-justify-start h-pb-30'>
                <FwFormInput
                    style='width: 280px'
                    class='h-mr-30'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.BRANCH.LABEL`)}${user().account_organization_type === `with_all` ? `**` : `*`}`'>
                    <div class='fw-select-base'>
                        <FwSelect
                            v-if='(branches ||
                                user().account_organization_type === `with_branches` ||
                                user().account_organization_type === `with_all`) && !isBranchHubHide'
                            v-model='branchValue'
                            type='white'
                            :propsPlaceholder='`${$t(`WORKER.FORM.BRANCH.PLACEHOLDER`)}`'
                            label='name'
                            :disabled='branchValueFromServer'
                            :canNotDelete='user().account_organization_type !== `with_all`'
                            :searchable='false'
                            :multiply='false'
                            :options='branches'>
                        </FwSelect>
                    </div>
                </FwFormInput>
                <FwFormInput
                    style='width: 280px'
                    class='h-mr-30'
                    :fieldHasError='fieldHasError(ADMIN)'
                    labelType='label-medium'
                    :title='`${$t(`BRANCH.FORM.LABEL`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isActiveBranchAdmin ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isActiveBranchAdmin'
                                :isRequestSending='isActiveAdminRequestSending'
                                @toggleSwitch='isActiveBranchAdmin = !isActiveBranchAdmin'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
            </div>

            <div v-if='$route.meta.showAddButton'
                 class='h-flex-center'>
                <FwButton
                    class='qa-advisor-create-edit-submit'
                    size='little'
                    :disabled='!isAdminFormValid'
                    borderRadiusType='small-border'
                    @click='sendDataToServer'>
                    {{ !isBranchAdvisorAdd ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
                </FwButton>
            </div>
        </div>
        <template v-if='isTypeSite'>
            <div v-if='(branches && user().account_organization_type !== `with_hubs`) || user().account_organization_type === `with_all`'>
                <h2 class='h-font-22'>
                    {{ $t('LABEL.START.POINT') }}
                </h2>
                <div style='width: 310px' class='h-pb-30'>
                    <FwFormInput
                        class='h-flex-1-0 h-mr-30'
                        :fieldHasError='fieldHasError(ADMIN)'
                        labelType='label-medium'
                        :title='`${$t(`ADVISOR.SAME.BRANCH.ADDRESS`)}*`'>
                        <FwLabelCard
                            :disabled='isInitiaStartPoinDisabled'
                            class='h-flex-1-0 b-label-card'>
                            <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                                <div class='b-add-edit__form__option h-flex h-flex-center'>
                                    <span class='b-add-worker__is_admin_active'>
                                        {{ isBranchAddress ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                    </span>
                                </div>
                                <FwSwitcher
                                    :isActive='isBranchAddress'
                                    :isRequestSending='isActiveAdminRequestSending'
                                    @toggleSwitch='isBranchAddress = !isBranchAddress'>
                                </FwSwitcher>
                            </div>
                        </FwLabelCard>
                    </FwFormInput>
                </div>
                <div v-if='!isBranchAddress' class='h-flex h-flex-space-between h-mb-30'>
                    <FwFormInput
                        class='h-flex-1-0'
                        :fieldHasError='fieldHasError()'
                        :title='`${$t(`LABEL.ADDRESS`)}*`'
                        labelType='label-medium'>
                        <SelectAddress
                            :startAddress='address'
                            :isDisabled='isInitiaStartPoinDisabled'
                            @changePostCode='value => post_code = value'
                            @input='input'>
                        </SelectAddress>
                    </FwFormInput>
                </div>
            </div>
            <div v-if='hubs && user().account_organization_type !== `with_all` && user().account_organization_type !== `with_branches`'>
                <h2 class='h-font-22'>
                    {{ $t('LABEL.START.POINT') }}
                </h2>
                <div class='h-flex h-flex-space-between h-mb-30'>
                    <FwFormInput
                        class='h-flex-1-0'
                        :fieldHasError='fieldHasError()'
                        :title='`${$t(`LABEL.ADDRESS`)}*`'
                        labelType='label-medium'>
                        <SelectAddress
                            :startAddress='address'
                            :isDisabled='isInitiaStartPoinDisabled'
                            @changePostCode='value => post_code = value'
                            @input='input'>
                        </SelectAddress>
                    </FwFormInput>
                </div>
            </div>
        </template>
        <div v-if='user().account_organization_type === `with_all`'>
            <div class='h-fw-500 h-font-14'>
                *{{ $t('WORKER.FORM.REQUIRED.FIELDS') }}
            </div>
            <div class='h-fw-500 h-font-14 h-mt-10'>
                **{{ $t('WORKER.FORM.REQUIRED.ONE.OF.TWO.FIELDS') }}
            </div>
        </div>
        <div v-else class='h-fw-500 h-font-14'>
            *{{ $t('WORKER.FORM.REQUIRED.FIELDS') }}
        </div>
    </form>

    <portal
        v-if='!$route.meta.showAddButton'
        to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                v-if='$route.path.includes(`add-more`) && !isRecapRoute'
                class='h-mr-10'
                size='little'
                color='skip'
                borderRadiusType='small-border'
                @click='$router.push({ path: `${mainRouteGlobal}${userKindBoth ? `/both/advisors` : `/advisors`}` })'>
                {{ $t('BUTTON.SKIP') }}
            </FwButton>
            <FwButton
                class='qa-advisor-create-edit-submit'
                size='little'
                :disabled='isDisabled'
                borderRadiusType='small-border'
                @click='sendDataToServer'>
                {{ $route.params.id ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
            </FwButton>
        </div>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import {
    FIRST_NAME,
    LAST_NAME,
    SKILLS,
    COMPANY,
    PHONE,
    EMAIL,
    ADMIN,
    GEOGRAPHIC_ZONE,
    HUBS,
    BRANCHES,
    REMOTE_ID,
} from '@/helpers/worker';
import { BRANCH_KEY, HUB_KEY } from '@/helpers/consts';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { BranchHubsTitlesTypes, AdvisorType, CategoryLocationSkillNameType, GeograficCitiesType, CategoryBranchHubType } from '@/types/Appointment';
import { AddEditGeograficPopup } from '@/components/popups/AddEditGeograficPopup';
import { SelectAddress } from '@/components/common/SelectAddress';
import { ParsedAddressData } from '@/types/GoogleMap';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import AccountMixin from '@/mixins/account';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    components: {
        AddEditGeograficPopup,
        SelectAddress,
    },
    validators: {
        [FIRST_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [LAST_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [EMAIL](value: string) {
            return this.emailValidator({ value });
        },
    },
})
export default class AppointmentAddHubBranchAdvisor extends Mixins(ValidationMixin, TranslateMixin, AccountMixin) {
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @AppointmentStore.State('geograficCitiesData') geograficIds!: Array<GeograficCitiesType>;
    valueSkill: any = null;
    roleValue: any = null;
    branchValue: CategoryBranchHubType | null | undefined = null;
    branchValueFromServer: CategoryBranchHubType | null | undefined = null;
    hubValue: CategoryBranchHubType | null | undefined = null;
    hubValueFromServer: CategoryBranchHubType | null | undefined = null;
    phone: string = '';
    company: string = '';
    code_site: string = '';
    [EMAIL]: string = '';
    [FIRST_NAME]: string = '';
    [LAST_NAME]: string = '';
    [REMOTE_ID]: string = '';
    EMAIL: string = EMAIL;
    FIRST_NAME: string = FIRST_NAME;
    LAST_NAME: string = LAST_NAME;
    REMOTE_ID: string = REMOTE_ID;
    errorFromServer: string = '';
    geographic_zone: string = '';
    isActiveAdminRequestSending: boolean = false;
    isActiveHubAdmin: boolean = false;
    isActiveBranchAdmin: boolean = false;
    loading: boolean = true;
    isActiveManager: boolean = false;
    isAddresDisabled: boolean = false;
    isLoadingRoles: boolean = false;
    isGeograficPopup: boolean = false;
    isRequestSending: boolean = false;
    currentCity: GeograficCitiesType | null = null;
    isBranchAddress: boolean = false;
    isInitiaStartPoinDisabled: boolean = false;
    post_code: string | null = '';
    address: string | null = '';
    serverErrors: serverErrors = {
        [FIRST_NAME]: null,
        [LAST_NAME]: null,
        [EMAIL]: this.errorFromServer,
    };

    skills: Array<CategoryLocationSkillNameType> = [];
    roles: Array<CategoryLocationSkillNameType> = [];
    categoryLocationSkills: Array<CategoryLocationSkillNameType> = [];
    skillChoosed: Array<CategoryLocationSkillNameType> = [];
    locationSkillChoosed: Array<CategoryLocationSkillNameType> = [];
    hubs: Array<BranchHubsTitlesTypes> | null = null;
    branches: Array<BranchHubsTitlesTypes> | null = null;

    get userKindBoth() {
        return this.user().account_organization_type === 'with_all';
    }

    input(parsedAddressData: ParsedAddressData) {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
    }

    openPopup() {
        this.isGeograficPopup = true;
    }

    closePopup() {
        this.isGeograficPopup = false;
    }

    deleteItem(item: GeograficCitiesType) {
        if (item) {
            const arrayItems = this.geograficIds.filter(filterItem => filterItem.id !== item.id);
            this.setGeograficCitiesData(arrayItems);
        }
    }
    toggleAdmin() {
        this.isActiveManager = !this.isActiveManager;
        if (this.isActiveManager && !this.roleValue) {
            this.getRoles();
        }
    }

    get isTypeSite() {
        return this.locationSkillChoosed.some(item => item.searchable_name === 'location_category_on_site' || item.searchable_name === 'location_category_on_site_in_branch');
    }

    get isTypeFaceToFace() {
        return this.locationSkillChoosed.some(item => item.searchable_name === 'location_category_in_branch_public') && this.locationSkillChoosed.length === 1;
    }

    get isAdminFormValid(): boolean {
        return Boolean(
            this.locationSkillChoosed.length !== 0 &&
                (this.geograficIds.length !== 0 || this.isTypeFaceToFace) ||
                (this.geograficIds.length === 0 && this.isTypeFaceToFace) &&
                (this.branchValue || this.hubValue || true)
        );
    }

    get isDisabled(): boolean {
        return !this.isFormValid || !this.isAdminFormValid ||
            (this.isActiveManager && !this.roleValue) ||
            this.isRequestSending ||
            (this.isTypeSite && (!this.address && !this.isBranchAddress)) ||
            (this.user().account_organization_type === `with_all` && !this.hubValue && !this.branchValue) ||
            (!this.hubValue && !this.branchValue);
    }
    get hubId() {
        if (this.hubValue && this.hubValue.id) {
            return this.hubValue.id;
        }
        return this.hubValue;
    }

    get branchId() {
        if (this.branchValue && this.branchValue.id) {
            return this.branchValue.id;
        }
        return this.branchValue;
    }
    async getRoles() {
        this.isLoadingRoles = true;
        const { role_name } = await OnboardingApi.getFrontHelpers('role_names');
        this.roles = role_name;
        this.isLoadingRoles = false;
    }

    async sendDataToServer() {
        this.isRequestSending = true;
        try {
            const skill_ids = [...this.skillChoosed!.map(item => item.id), ...this.locationSkillChoosed!.map(item => item.id)];
            const geo_site_ids = !this.isTypeFaceToFace ? [...this.geograficIds!.map(item => item.id)] : null;
            const departure_point = !this.isBranchAddress && this.address ? this.address : null;
            const set_branch_as_starting_point = !!this.isBranchAddress;
            let payload = null;
            if (this.user().account_organization_type === `with_hubs` || (!this.branchValue && this.hubValue)) {
                payload = {
                    hub_id: this.hubId,
                    hub_admin: this.isActiveHubAdmin,
                };
            } else if (this.user().account_organization_type === `with_branches` || (this.branchValue && !this.hubValue)) {
                payload = {
                    branch_site_id: this.branchId,
                    branch_admin: this.isActiveBranchAdmin,
                };
            } else if (this.branchValue && this.hubValue) {
                payload = {
                    hub_id: this.hubId,
                    hub_admin: this.isActiveHubAdmin,
                    branch_site_id: this.branchId,
                    branch_admin: this.isActiveBranchAdmin,
                };
            } else {
                this.$router.push({ path: `${this.mainRouteGlobal}/account-onboarding/choose-organization-kind` });
                this.sentNotif(`Branch and hub data not exists`);
            }
            const rolePayload = this.isActiveManager && this.roleValue ? { role_id: this.roleValue.id } : null;
            const payloadData = {
                first_name: this[FIRST_NAME],
                last_name: this[LAST_NAME],
                email: this[EMAIL],
                [REMOTE_ID]: this[REMOTE_ID],
                phone: this.phone,
                kind: 'advisor',
                geo_site_ids,
                skill_ids,
                departure_point,
                set_branch_as_starting_point,
                is_manager: this.isActiveManager,
                ...(this.code_site && { code_site: this.code_site }),
                ...payload,
                ...rolePayload,
            };
            if (this.$route.params.id) {
                try {
                    await OnboardingApi.updateOnboardingAdvisor(payloadData as AdvisorType, this.$route.params.id);
                    if (this.isRecapRoute) {
                        this.$router.push({ path: `${this.mainRouteGlobal}/recap/advisors` });
                    } else {
                        this.$router.push({ path: `${this.mainRouteGlobal}${this.userKindBoth ? `/both/advisors` : `/advisors`}` });
                    }
                    this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else {
                try {
                    // @ts-ignore-next-line TODO LYMAR
                    await OnboardingApi.createOnboardingAdvisor(payloadData as AdvisorType);
                    this.$router.push({ path: `${this.mainRouteGlobal}${this.userKindBoth ? `/both/advisors` : `/advisors`}` });
                    this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    async created() {
        if (!this.user().account_organization_type) {
            this.$router.push({ path: `/account-onboarding/choose-organization-kind` });
        }
        this.loading = true;
        const { data } = await WorkersWebApi.getAdvisorHelpersData();
        if (!this.skills.length) {
            this.skills = data.skill_names;
        }
        this.categoryLocationSkills = data.category_location_skill_names;
        this.hubs = data.hub_name;
        this.branches = data.branch_titles;
        this.setGeograficCitiesData([]);
        if (this.$route.params.id) {
            const { advisor } = await OnboardingApi.getOnboardingAdvisor(this.$route.params.id);
            if ((advisor as WorkerType)) {
                this.setGeograficCitiesData(advisor.geo_sites);
                this[FIRST_NAME] = advisor.first_name;
                this[LAST_NAME] = advisor.last_name;
                this[REMOTE_ID] = advisor.technician_profile.remote_id;
                if (advisor.manager_profile) {
                    await this.getRoles();
                    this.isActiveManager = true;
                    this.roleValue = this.roles.filter(item => {
                        return advisor.manager_profile.role.id === item.id;
                    });
                }
                this[EMAIL] = advisor.email;
                this.isActiveAdmin = advisor.is_company_admin_set;
                this.phone = advisor.phone || advisor.technician_profile.phone;
                this.code_site = advisor.code_site;
                this.isActiveHubAdmin = advisor.technician_profile.hub_admin;
                this.isActiveBranchAdmin = advisor.technician_profile.branch_admin;
                this.skillChoosed = this.skills.filter(item => {
                    return advisor.skills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                });
                if (advisor.branch && advisor.branch.address) {
                    if (advisor.technician_profile && advisor.technician_profile.departure_point) {
                        if (advisor.branch.address === advisor.technician_profile.departure_point) {
                            this.isBranchAddress = true;
                        }
                    }
                }
                if (advisor.technician_profile && advisor.technician_profile.departure_point) {
                    this.address = advisor.technician_profile.departure_point;
                    this.isInitiaStartPoinDisabled = true;
                }
                this.locationSkillChoosed = this.categoryLocationSkills.filter(item => {
                    return advisor.skills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                });
                if (advisor.branch && this.branches) {
                    this.branchValue = this.branches.find(item => advisor.branch.id === item.id);
                    this.branchValueFromServer = this.branches.find(item => advisor.branch.id === item.id);
                }
                if (advisor.hub && this.hubs) {
                    this.hubValue = this.hubs.find(item => advisor.hub.id === item.id);
                    this.hubValueFromServer = this.hubs.find(item => advisor.hub.id === item.id);
                }
                if (advisor.hub && advisor.branch && this.branches && this.hubs) {
                    this.branchValue = this.branches.find(item => advisor.branch.id === item.id);
                    this.branchValueFromServer = this.branches.find(item => advisor.branch.id === item.id);
                    this.hubValue = this.hubs.find(item => advisor.hub.id === item.id);
                    this.hubValueFromServer = this.hubs.find(item => advisor.hub.id === item.id);
                }
            }
        } else if (!this.hubValue || !this.branchValue) {
            /* eslint-disable */
            this.branchValue = window.localStorage.getItem(BRANCH_KEY) &&
                this.branches!.some(item => item.id === JSON.parse(window.localStorage.getItem(BRANCH_KEY)!).id) ?
                JSON.parse(window.localStorage.getItem(BRANCH_KEY)!) :
                null;
            this.hubValue = window.localStorage.getItem(HUB_KEY) &&
                this.hubs!.some(item => item.id === JSON.parse(window.localStorage.getItem(HUB_KEY)!).id) ?
                JSON.parse(window.localStorage.getItem(HUB_KEY)!) :
                null;
            /* eslint-enable */
        }
        this.loading = false;
    }

    @Watch('errorFromServer')
    checkEmailError() {
        if (this.errorFromServer.includes('Email')) {
            this.serverErrors = {
                // @ts-ignore-next-line
                [EMAIL]: this.$i18n.t('WORKER.EMAIL.TAKEN'),
            };
        }
    }
}
</script>

<style lang='sass'>
.b-add-worker
    &__wrapper
        .b-label-card
            background-color: #fff
            height: 60px !important

    &__required-fields
        display: flex
        align-items: center
        justify-content: flex-end
        font-weight: 500
        color: #213F6B
        height: 60px
        margin-top: 26px

    &__is_admin_active
        font-weight: 500

.b-add-worker__wrapper
    .b-appt-button__clear
        height: 100%
        display: flex
        justify-content: flex-start
        align-items: center
        text-transform: uppercase
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
</style>
