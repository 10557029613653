<template>
<div class='b-blue-background
            b-blue-background__opacity
            b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='420px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <img class='h-ph-50' :src='requireImage("coming-soon.svg")'>
                <h2 class='h-font-22 h-mt-20 h-mb-0'>
                    {{ $t('POPUP.COMING.SOON.TITLE') }}
                </h2>
                <p class='b-coming-soon__aside-text h-mt-10 h-text-center'>
                    {{ $t('POPUP.COMING.SOON.ASIDE') }}
                    <a href='mailto:hello@calizy.com'>
                        hello@calizy.com
                    </a>
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center'>
                <FwButton
                    class='h-mt-10'
                    borderRadiusType='small-border'
                    size='little'
                    @click='$emit("close")'>
                    {{ $t('POPUP.COMING.SOON.BUTTON.TEXT') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class ComingSoonPopup extends Vue {}
</script>

<style lang='sass'>
.b-coming-soon
    &__aside-text
        line-height: 1.4em
        a:hover
            text-decoration: underline
</style>
