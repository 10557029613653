export default {
    'UPDATE.TITLE.POPUP': 'Quand les heures de travail doivent-elles être mises à jour ?',
    'UPDATE.TITLE.POPUP.SCHEDULED.UPDATE': 'Mise à jour programmée',
    'UPDATE.WILL.BE.UPDATE': 'Les heures de travail seront mises à jour le',
    'UPDATE.TITLE.POPUP.CANCEL': 'Annuler la mise à jour',
    'UPDATE.TITLE.POPUP.CANCEL.QUESTION': 'Voulez-vous vraiment annuler cette mise à jour ?',
    'UPDATE.TITLE.POPUP.CANCEL.YES': 'Oui, annuler',
    'UPDATE.TITLE.POPUP.CANCEL.BACK': 'Retour',
    'UPDATE.TITLE.ALREADY.UPDATED': 'Une mise à jour est déjà programmée. Veuillez l\'annuler avant d\'en programmer une nouvelle.',

    'UPDATE.ADDRESS.WHEN.TITLE': 'Quand l’adresse doit-elle être mise à jour ?',
    'UPDATE.ADDRESS.DESCRIPTION': 'L\'adresse sera mise à jour au',
    'UPDATE.ADDRESS.EDIT.TITLE': 'Modifier l\'adresse',
    'UPDATE.ADDRESS.ON': 'le',
    'UPDATE.ADDRESS.ALREADY.UPDATED': 'Une mise à jour d\'adresse est déjà planifiée. Veuillez l\'annuler avant d\'en programmer une nouvelle.',
};
