<template>
<div>
    <h1 v-if='title'
        class='b-consult-branch-stat__title'>
        {{ title }}
    </h1>
    <div v-if='!$route.meta.hideTabs'
         class='b-appointment-types__tabs'>
        <template>
            <router-link
                class='b-appointment-types__tabs__item'
                :to='currentRouteFor("for")'>
                {{ getForText }}
            </router-link>
            <router-link
                class='b-appointment-types__tabs__item'
                :to='currentRouteFor("by")'>
                {{ getByText }}
            </router-link>
        </template>
    </div>
    <div class='h-flex h-flex-justify-end h-width-100p h-mb-20'>
        <div class='h-flex-center'>
            <div v-if='false'
                 class='h-flex-center h-font-12 h-fw-700'>
                <span class='h-mr-5'>
                    123
                </span>
                <FwSwitcher
                    :isActive='isSwitcherActive'
                    :isRequestSending='false'
                    @toggleSwitch='isSwitcherActive = !isSwitcherActive'>
                </FwSwitcher>
                <span class='h-ml-5'>
                    %
                </span>
            </div>

            <FwDatepicker
                v-model='value'
                class='b-consult-branch-stat__datepicker'
                name='datepicker'
                :placeholder='$t("CALENDAR.ALL.DATES")'
                :propsRange='false'
                :propsLang='user().locale || "en"'
                :popupStyle='{
                    right: 0,
                    left: "auto",
                }'
                :isCentered='true'
                pickerStyle='sass-simple'
                :onlyButtons='true'
                :selectMode='true'
                iconName='arrow-bottom'
                iconSize='12'
                propsIconColor='#213F6B'
                :filteredSlotMenu='[1,5]'
                :translateSlotMenuTitles='translateSlotMenuTitles'
                :disabledDate='disabledDateByObject'
                @selectDate='selectDateHandler'
                @asRange='asRangeHandler'>
            </FwDatepicker>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { clone } from 'ramda';
import i18n from '@/locale';
import { BOOKED_GROUP, HUB_TYPE } from '@/routes/planAppointment/consts';

@Component
export default class StatisticsHeader extends Vue {
    @Prop({ type: String, default: null }) readonly title!: string;
    @Prop({ type: Date, default: null }) readonly startDate!: Date | Array<Date> | null;

    isSwitcherActive: boolean = true;
    value: null | Date | Array<Date> = null;
    range: Array<Date> = [];
    selectedValue: Date | Array<Date> | null = null;
    disabledDateByObject = {
        disableNextDays: new Date().setDate(new Date().getDate() + 1),
    };

    get translateSlotMenuTitles(): { [key: number]: string } {
        return {
            2: i18n.tc('CALENDAR.DAY'),
            3: i18n.tc('CALENDAR.WEEK'),
            4: i18n.tc('CALENDAR.MONTH'),
        };
    }

    get getForText(): TranslateResult {
        if (this.$route.meta && this.$route.meta.type === HUB_TYPE) {
            return this.$route.meta.linkGroupName === BOOKED_GROUP ? this.$i18n.t('HUB.STATISTICS.BOOKED.TYPE') : this.$i18n.t('HUB.STATISTICS.CANCELLED.TYPE');
        }
        // eslint-disable-next-line max-len
        return this.$route.meta && this.$route.meta.linkGroupName === BOOKED_GROUP ? this.$i18n.t('BRANCH.STATISTICS.BOOKED.TYPE') : this.$i18n.t('BRANCH.STATISTICS.CANCELLED.TYPE');
    }

    get getByText(): TranslateResult {
        if (this.$route.meta && this.$route.meta.type === HUB_TYPE) {
            return this.$route.meta.linkGroupName === BOOKED_GROUP ? this.$i18n.t('HUB.STATISTICS.BOOKED.BY.TYPE') : this.$i18n.t('HUB.STATISTICS.CANCELLED.BY.TYPE');
        }
        // eslint-disable-next-line max-len
        return this.$route.meta && this.$route.meta.linkGroupName === BOOKED_GROUP ? this.$i18n.t('BRANCH.STATISTICS.BOOKED.BY.TYPE') : this.$i18n.t('BRANCH.STATISTICS.CANCELLED.BY.TYPE');
    }

    currentRouteFor(type: 'for' | 'by') {
        return {
            name: this.$route.name,
            params: {
                id: this.$route.params.id,
                type,
            },
        };
    }

    @Emit('selectDate')
    selectDateHandler(date: Date | Array<Date>) {
        this.selectedValue = date;
    }

    @Emit('asRange')
    asRangeHandler(dates: Array<Date>) {
        if (dates.length === 1) {
            dates.push(clone(dates[0]));
        }
        this.range = dates;
    }

    created() {
        if (this.startDate) {
            if (Array.isArray(this.startDate) && this.startDate[0] && !this.startDate[1]) {
                this.value = this.startDate[0];
            } else {
                this.value = this.startDate;
            }
        }
    }
}
</script>

<style lang='sass'>
.b-consult-branch-stat
    &__datepicker
        width: 270px !important
        .mx-datepicker-popup
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.05)

.b-appointment-types
    &__tabs
        display: flex
        font-size:  18px
        color: #213F6B
        margin-bottom: 20px

        &__item
            position: relative
            font-weight: 500
            opacity: 0.5

            &.router-link-active
                opacity: 1
                &:after
                    width: 80%
                    position: absolute
                    left: 0
                    content: ''
                    right: 0
                    bottom: -7px
                    border-top: 2px solid #213F6B
                    height: 0
                    margin: auto

            &:not(.active)
                cursor: pointer

            &:not(:last-of-type)
                margin-right: 60px
</style>
