import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { EN_LOCAL } from '@/helpers/locale';

@Component
export default class TranslateMixin extends Vue {
    get timeFormat(): string {
        return this.$i18n.locale === EN_LOCAL ? `hh:mm a` : `HH:mm`;
    }

    get labelKey(): string {
        return this.$i18n.locale === EN_LOCAL ? `en_name` : `fr_name`;
    }
};
