<template>
<div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div v-if='appointmentsCategoriesArray.length' class='b-add-appointment-categories__wrapper'>
            <div class='b-base-title__wrapper'>
                <h2 class='b-appointment-left-bar__title'>
                    {{ $t('PLAN.APPOINTMENT.BUSINESS.PARTNER.TITLE') }}
                </h2>
                <p class='h-font-14 h-fw-500'>
                    {{ $t('ONBOARDING.CHOOSE.BUSINESS.PARTNERS.SUBTEXT') }}
                </p>
                <FwHorizontalScroll
                    :isPageLoaded='true'
                    :style='scrollStyle'
                    slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force h-mt-30'
                    style='padding-bottom: 100px;'
                    iconClass='h-opacity-1'
                    newClass='fw-arrow-big-circle'
                    :arrowBgWidth='null'
                    colorIcon='#fff'
                    btnLocation='between'>
                    <template #content>
                        <CalendarTable
                            :titles='appointmentsCategoriesTitleArray'
                            :data='appointmentsCategoriesArray'
                            :bigIndexes='bigIndexes'
                            class='b-calendar__table__wrapper'>
                            <div
                                v-for='(item, i) in appointmentsCategoriesArray'
                                :key='i'
                                class='b-calendar__table'>
                                <div class='b-calendar__table__outer'>
                                    <div v-for='(key, j) in mapping'
                                         :key='j'
                                         class='b-calendar__table__item'
                                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(j) }'>
                                        <template v-if='item[key] && item[key].length'>
                                            {{ item[key] }}
                                        </template>
                                    </div>
                                </div>
                                <CalendarTableButtons
                                    @edit='editAppointment(item)'
                                    @delete='deleteAppointment(item)'>
                                </CalendarTableButtons>
                            </div>
                        </CalendarTable>
                        <FwPagination
                            v-if='totalPage > perPage'
                            :totalPage='totalPage'
                            :currentPage='currentPage'
                            :perPage='perPage'
                            :value='perPage'
                            @getData='getCategories'>
                        </FwPagination>
                    </template>
                </FwHorizontalScroll>
            </div>
        </div>
        <div v-else class='h-flex-dir-column h-flex-center h-mt-50'>
            <h1>
                {{ $t('ONBOARDING.NO.ITEMS.PARTNERS') }}
            </h1>
            <FwButton
                class='h-ml-10 h-width-150'
                size='little'
                borderRadiusType='small-border'
                @click='addMoreAppointment'>
                {{ $t('BUTTON.ADD') }}
            </FwButton>
        </div>
    </template>
    <portal v-if='!isRecapRoute' to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                size='little'
                color='skip'
                borderRadiusType='small-border'
                @click='addMoreAppointment'>
                {{ $t('BUTTON.ADD.MORE') }}
            </FwButton>

            <FwButton
                class='h-ml-10 h-width-150'
                :to='$route.meta.next'
                :disabled='!appointmentsCategoriesArray.length'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
    </portal>
    <portal v-else to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                :to='`${mainRouteGlobal}/recap`'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('Go to recap') }}
            </FwButton>
        </div>
    </portal>
    <DeletePopup
        v-if='confirmPopup'
        width='600px'
        :headerText='$t("DELETE.APPOINTMENT.BUSINESS.COMPANY.TITLE")'
        :subMessage='$t("DELETE.APPOINTMENT.BUSINESS.COMPANY.TEXT")'
        @deleteItem='deleteItem'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { DeletePopup } from '@/components/popups/DeletePopup';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import { appointmentData } from '@/types/Appointment';
import { CompanyType } from '@/types/Companies';
import { AccountOnboarding } from '@/api/company/CompanyApi';
import { calculateNextPage } from '@/helpers/pagination';
import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';

@Component({
    components: {
        DeletePopup,
        CalendarTable,
        CalendarTableButtons,
    },
})
export default class AppointmentPartnerCategories extends Mixins(ValidationMixin, TranslateMixin) {
    appointmentsCategoriesTitleArray: Array<string> = [
        'LABEL.COMPANY.NAME',
        'LABEL.ADDRESS',
        'LABEL.CODE.INSEE',
        'LABEL.CODE.SIREN',
        'LABEL.CODE.SIRET',
        'LABEL.PHONE.NUMBER',
        'LABEL.EMAIL',
    ];
    currentAppointment: appointmentData | null = null;
    confirmPopup: boolean = false;
    loading: boolean = false;
    appointmentsCategoriesArray: Array<appointmentData> = [];
    $refs!: {
        leftBar: HTMLElement
    };
    mapping: Array<string> = [
        `name`,
        `address1`,
        `insee_code`,
        `siren`,
        `siret`,
        `phone`,
        `email`,
    ];
    bigIndexes: Array<number> = [1, 6];
    totalPage: number = 0;
    perPage: number = 6;
    currentPage: number = 1;

    get scrollStyle() {
        if (this.$refs.leftBar && this.$el) {
            return {
                width: `${(this.$el as HTMLElement).offsetWidth - this.$refs.leftBar.offsetWidth}px`,
                paddingRight: '120px',
            };
        }
        return null;
    }

    deleteAppointment(appointment: appointmentData) {
        this.currentAppointment = appointment;
        this.confirmPopup = true;
    }

    editAppointment(appointment: appointmentData) {
        if (this.isRecapRoute) {
            this.$router.push({ path: `${this.mainRouteGlobal}/recap/partners/edit/${appointment.id}` });
        } else {
            this.$router.push({ path: `${this.mainRouteGlobal}/partners/edit/${appointment.id}` });
        }
    }

    async deleteItem() {
        this.confirmPopup = false;
        try {
            await AccountOnboarding.deleteAccountOnboardingCompany(this.currentAppointment!.id);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.currentPage = calculateNextPage({ page: this.currentPage, per_page: this.perPage, total_items: this.totalPage });
            this.getCategories();
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    addMoreAppointment() {
        this.$router.push({ path: `${this.mainRouteGlobal}/partners/add` });
    }

    prepareCategoriesData(companies: Array<CompanyType>) {
        this.appointmentsCategoriesArray = companies.map((company: CompanyType) => {
            return {
                id: company.id,
                name: company.title,
                address1: company.address,
                insee_code: company.insee_code || null,
                siren: company.siren || null,
                siret: company.siret || null,
                phone: company.phone || null,
                email: company.contact_email || null,
            };
        });
    }

    async getCategories(page: number = this.currentPage, per_page: number = this.perPage) {
        this.loading = true;
        const data = await AccountOnboarding.getAccountOnboardingCompanies({ page: page || this.currentPages, per_page: per_page || this.perPage });
        this.prepareCategoriesData(data.companies);
        this.totalPage = data.meta.total_count;
        this.currentPage = data.meta.current_page;
        this.loading = false;
    }

    async created() {
        this.getCategories();
    }
}
</script>
<style lang='sass'>
.b-add-appointment
    &__wrapper
        @include media('<=phone')
            width: 100%
        .b-time-picker
            background: #ffffff
            height: 60px

.b-add-appointment-categories__wrapper
    .pagination
            position: sticky !important

.b-appointment-categories
    &__header
        margin: 0 100px

    &__body
        &--items
            margin: 0 100px
            display: flex
            margin-top: 10px
        &--item
            background: #ffffff
            border-radius: 5px
        &--delete
            display: flex
            justify-content: center
            align-items: center
            background: #ffffff
            margin-left: 10px
            height: 60px
            width: 60px
            border-radius: 5px
        .fw-select-white
            box-shadow: none!important
            .multiselect__tags
                padding-left: 15px
            .multiselect__placeholder
                font-size: 14px
                color: $dark-blue
                font-weight: 500
            .multiselect__single
                font-size: 14px
                color: $dark-blue
                font-weight: 500
            .multiselect__element
                font-size: 14px
                color: $dark-blue
                font-weight: 400
</style>
