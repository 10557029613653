<template>
<div :class='formClassWrapper'
     class='b-appointment-left-bar_form'>
    <GoBackButton
        style='top: 40px; left: 40px'
        class='b-route-wrapper__back_arrow'
        :method='toMethodPage'
        @click.native.stop.prevent>
    </GoBackButton>
    <form class='h-mt-15'
          :class='formClass'
          @keyup.enter='submitForm'>
        <div class='h-flex h-flex-space-between'>
            <FwFormInput
                class='h-flex-1-0 h-mr-30 qa-direct-appointment-book-form__name'
                :fieldHasError='fieldHasError(BOOKING_FIRST_NAME)'
                labelType='label-medium'
                :title='`${$t(`LABEL.FIRST.NAME`)}*`'>
                <FwInput
                    :value='first_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.FIRST.NAME`)'
                    :disabled='isFormDisabled(BOOKING_FIRST_NAME)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText(BOOKING_FIRST_NAME)'
                    @update:value='value => first_name = value'
                    @input='clearServerErrorsBase(BOOKING_FIRST_NAME)'
                    @blur='addCurrentInputToValidateArray(BOOKING_FIRST_NAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 qa-direct-appointment-book-form__last-name'
                :fieldHasError='fieldHasError(BOOKING_LAST_NAME)'
                labelType='label-medium'
                :title='`${$t(`LABEL.LAST.NAME`)}*`'>
                <FwInput
                    :value='last_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.LAST.NAME`)'
                    :disabled='isFormDisabled(BOOKING_LAST_NAME)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText(BOOKING_LAST_NAME)'
                    @update:value='value => last_name = value'
                    @input='clearServerErrorsBase(BOOKING_LAST_NAME)'
                    @blur='addCurrentInputToValidateArray(BOOKING_LAST_NAME)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-flex-space-between h-mt-20'>
            <FwFormInput
                class='h-flex-1-0 h-mr-30 qa-direct-appointment-book-form__phone'
                :fieldHasError='fieldHasError(BOOKING_PHONE)'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE`)}**`'>
                <FwInput
                    :value='phone_number'
                    type='number'
                    name='text'
                    :placeholder='$t(`LABEL.PHONE`)'
                    :disabled='isFormDisabled(BOOKING_PHONE)'
                    inputStyle='white-bigger'
                    :error='getFieldErrorText(BOOKING_PHONE)'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => phone_number = value'
                    @input='clearServerErrorsBase(BOOKING_PHONE)'
                    @blur='addCurrentInputToValidateArray(BOOKING_PHONE)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mr-30 qa-direct-appointment-book-form__email'
                :fieldHasError='fieldHasError(BOOKING_EMAIL)'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}**`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EMAIL`)'
                    :disabled='isFormDisabled(BOOKING_EMAIL)'
                    :error='getFieldErrorText(BOOKING_EMAIL)'
                    inputStyle='white-bigger'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase(BOOKING_EMAIL)'
                    @blur='addCurrentInputToValidateArray(BOOKING_EMAIL)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-if='(post_code && client)'
                class='h-flex-1-0 h-mr-30 qa-direct-appointment-book-form__post-code'
                :fieldHasError='fieldHasError(BOOKING_POST_CODE)'
                labelType='label-medium'
                :disabled='true'
                :title='$t("SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER")'>
                <FwInput
                    :value='post_code'
                    type='text'
                    name='text'
                    inputStyle='white-bigger'
                    :disabled='true'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    placeholder='ex: 75001'
                    @update:value='value => post_code = value'
                    @blur='addCurrentInputToValidateArray(BOOKING_POST_CODE)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-else-if='!client'
                class='h-flex-1-0 h-mr-30 qa-direct-appointment-book-form__post-code'
                :fieldHasError='fieldHasError("client_post_code")'
                labelType='label-medium'
                :title='`${$t(`SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER`)}***`'>
                <FwInput
                    :value='client_post_code'
                    type='text'
                    name='text'
                    inputStyle='white-bigger'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    placeholder='ex: 75001'
                    @update:value='value => client_post_code = value'
                    @blur='addCurrentInputToValidateArray("client_post_code")'>
                </FwInput>
            </FwFormInput>
        </div>
        <div v-if='!isOnPhoneFlow && !isOnVideoFlow' class='h-flex h-flex-space-between h-mt-20'>
            <FwFormInput
                class='h-flex-1-0'
                :fieldHasError='fieldHasError(`COMPANY_INFORMATION_ADDRESS`)'
                :title='`${$t(`LABEL.ADDRESS`)}***`'
                labelType='label-medium'>
                <SelectAddress
                    class='b-select-address__outer'
                    :startAddress='address'
                    :isDisabled='isFormDisabled(BOOKING_ADDRESS) || !!(client && client.address)'
                    @input='input'>
                </SelectAddress>
            </FwFormInput>
        </div>
        <div class='h-flex h-flex-space-between h-mt-20'>
            <FwFormInput
                class='h-flex-1-0'
                :fieldHasError='fieldHasError(BOOKING_NOTES)'
                labelType='label-medium'
                :title='$t(`LABEL.NOTES`)'>
                <FwInput
                    :value='notes'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.NOTES`)'
                    :error='getFieldErrorText(BOOKING_NOTES)'
                    inputStyle='white-bigger'
                    @update:value='value => notes = value'
                    @input='clearServerErrorsBase(BOOKING_NOTES)'
                    @blur='addCurrentInputToValidateArray(BOOKING_NOTES)'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>
    <div class='h-mt-25'>
        <FwCheckbox
            v-if='isAppointmentClientShouldUpdate'
            v-model='should_update_client'
            :value='should_update_client'
            type='with-text'
            :itemWidth='`290px`'
            name='checkbox'>
            <div>
                {{ $t('PAST.APPOINTMENT.UPDATE.CLIENT.DETAILS') }}
            </div>
        </FwCheckbox>

        <FwCheckbox
            v-model='skip_notifications'
            :value='skip_notifications'
            type='with-text'
            :itemWidth='`290px`'
            name='checkbox'>
            <div>
                {{ $t('APPOINTMENT.DISABLE.CUSTOMER.NOTIFICATION') }}
            </div>
        </FwCheckbox>
    </div>
    <div class='b-form-hint__wrapper h-flex-dir-column'>
        <div class='b-form-hint h-mb-10'>
            * {{ $t('WORKERS.FORM.HINT.ONE_OF_THESE') }}
        </div>
        <div class='b-form-hint h-mb-10'>
            ** {{ $t('WORKERS.FORM.HINT.ONE_OF_THESE') }}
        </div>
        <div class='b-form-hint'>
            *** {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
        </div>
    </div>

    <div class='h-pt-10 h-ph-50'>
        <div class='h-flex h-flex-center'>
            <FwButton
                class='h-mt-10 qa-direct-appointment-book-form__submit'
                borderRadiusType='small-border'
                :disabled='isDisable'
                @click.native.stop.prevent='submitForm'>
                {{ bookButtonText }}
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { SelectAddress } from '@/components/common/SelectAddress';
import { GoBackButton } from '@/components/simple/GoBackButton';

import { FormBookAppointmentType } from '@/types/Book';
import { ParsedAddressData } from '@/types/GoogleMap';
import { ClientType } from '@/types/User';
import { BookingEventType } from '@/types/Availabilities';

import DateMixin from '@/mixins/dateMixin';
import ValidationMixin from '@/mixins/validation';
import CalendarMixin from '@/mixins/calendar';
import AppointmentMixin from '@/mixins/appointment';

import {
    BOOKING_FIRST_NAME,
    BOOKING_LAST_NAME,
    BOOKING_PHONE,
    BOOKING_EMAIL,
    BOOKING_ADDRESS,
    BOOKING_NOTES,
    BOOKING_POST_CODE,
} from '@/helpers/booking';

import i18n from '@/locale';
import { BranchAddressType } from '@/types/Appointment';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    validators: {
        [BOOKING_FIRST_NAME](value: string) {
            return this.baseInputValidator({ value, isRequired: !(this.last_name), requiredHint: i18n.t('VALIDATION.FIRST_OR_LAST_NAME.REQUIRED') });
        },
        [BOOKING_LAST_NAME](value: string) {
            return this.baseInputValidator({ value, isRequired: !(this.first_name), requiredHint: i18n.t('VALIDATION.FIRST_OR_LAST_NAME.REQUIRED') });
        },
        [BOOKING_PHONE](value: string) {
            return this.baseInputValidator({ value, isRequired: !(this.email), requiredHint: i18n.t('VALIDATION.EMAIL_OR_PHONE.REQUIRED') });
        },
        [BOOKING_EMAIL](value: string) {
            return this.emailValidator({ value, notRequired: Boolean(this.phone_number), requiredHint: i18n.t('VALIDATION.EMAIL_OR_PHONE.REQUIRED') });
        },
        [BOOKING_ADDRESS](value: string) {
            return this.baseInputValidator({ value, isRequired: Boolean(this.isVideoconferenceFlow || this.isFaceToFaceFlow) });
        },
        [BOOKING_POST_CODE](value: string) {
            return this.baseInputValidator({ value, isRequired: Boolean(this.post_code) });
        },
        client_post_code(value: string) {
            return this.baseInputValidator({ value, isRequired: Boolean(!this.client) });
        },
    },
    components: {
        SelectAddress,
        GoBackButton,
    },
})
export default class NewAppointmentBookingForm extends Mixins(ValidationMixin, CalendarMixin, DateMixin, AppointmentMixin) {
    @AppointmentStore.State('appointmentAddress') appointmentAddress!: string;
    @AppointmentStore.State('post_code') post_code!: string;
    @AppointmentStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;

    @Prop({ type: Object, default: null }) readonly defaultFormValues!: BookingEventType | null;
    @Prop({ type: Object, default: null }) readonly client!: ClientType;
    @Prop({ type: String, default: null }) readonly formClassWrapper!: string;
    @Prop({ type: String, default: null }) readonly formClass!: string;
    @Prop({ type: String, default: null }) readonly iconsWrapperClass!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly iconsInnerClass!: string;
    @Prop({ type: Object, required: true }) readonly eventData!: BookingEventType;
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;
    @Prop({ type: Object, default: null }) readonly placeParsedData!: ParsedAddressData | null;
    @Prop({ type: Object, default: null }) readonly branchAddress!: BranchAddressType;

    [BOOKING_FIRST_NAME]: string = '';
    [BOOKING_LAST_NAME]: string = '';
    [BOOKING_PHONE]: string = '';
    [BOOKING_EMAIL]: string = '';
    [BOOKING_ADDRESS]: string = '';
    [BOOKING_NOTES]: string = '';
    [BOOKING_POST_CODE]: string | number = '';
    BOOKING_FIRST_NAME: string = BOOKING_FIRST_NAME;
    BOOKING_LAST_NAME: string = BOOKING_LAST_NAME;
    BOOKING_PHONE: string = BOOKING_PHONE;
    BOOKING_EMAIL: string = BOOKING_EMAIL;
    BOOKING_ADDRESS: string = BOOKING_ADDRESS;
    BOOKING_NOTES: string = BOOKING_NOTES;
    BOOKING_POST_CODE: string = BOOKING_POST_CODE;
    client_post_code: string = '';

    should_update_client: boolean = false;
    skip_notifications: boolean = false;

    serverErrors: serverErrors = {
        [BOOKING_FIRST_NAME]: '',
        [BOOKING_LAST_NAME]: '',
        [BOOKING_PHONE]: '',
        [BOOKING_EMAIL]: '',
        [BOOKING_POST_CODE]: '',
        ...(this.branchAddress && { [BOOKING_ADDRESS]: '' }),
    };

    get isAddressUpdateDisabled() {
        return this.isBranchByPhonePrivateFlow;
    }

    get isDisable(): boolean {
        if (this.isAppointmentClientShouldUpdate && !this.should_update_client && (!this[BOOKING_ADDRESS] && !this.isOnPhoneFlow && !this.isOnVideoFlow)) {
            return true;
        }

        return !this.isFormValid || this.isRequestSending || (!this[BOOKING_ADDRESS] && !this.isOnPhoneFlow && !this.isOnVideoFlow);
    }

    get requiredFields(): Array<string> {
        const required = [];
        if (!this.first_nmae) {
            required.push(BOOKING_LAST_NAME);
        }
        if (!this.last_name) {
            required.push(BOOKING_FIRST_NAME);
        }
        if (!this.phone_number) {
            required.push(BOOKING_EMAIL);
        }
        if (!this.email) {
            required.push(BOOKING_PHONE);
        }
        if (this.branchAddress) {
            required.push(BOOKING_ADDRESS);
        }
        if (this.post_code) {
            required.push(BOOKING_POST_CODE);
        }
        return required;
    }

    get bookButtonText() {
        return this.$t('LABEL.BOOK');
    }

    get isAppointmentClientShouldUpdate() {
        return this.client;
    }

    created() {
        if (this.post_code) {
            this[BOOKING_POST_CODE] = this.post_code;
            this.client_post_code = this.post_code;
        }

        if (!this.isOnPhoneFlow && !this.isOnPhoneFlow && !this.isOnVideoFlow && this.branchAddress && this.branchAddress.post_code && this.client) {
            this.setPostCode(this.branchAddress.post_code);
        }

        if (this.client) {
            this[BOOKING_FIRST_NAME] = this.client.first_name;
            this[BOOKING_LAST_NAME] = this.client.last_name;
            this[BOOKING_PHONE] = this.client.phone_number;
            this[BOOKING_EMAIL] = this.client.email;
            if (this.client.post_code) {
                this[BOOKING_POST_CODE] = this.client.post_code;
            }
            if (this.client.address) {
                this[BOOKING_ADDRESS] = this.client.address;
            }
        }

        if (this.appointmentAddress && this.isOnSiteFlow) {
            this[BOOKING_ADDRESS] = this.appointmentAddress;
        }
    }

    mounted() {
        if (this.post_code) {
            this.addCurrentInputToValidateArray(BOOKING_POST_CODE);
        }
        if (this.client) {
            for (let j = 0; j < this.requiredFields.length; j++) {
                this.addCurrentInputToValidateArray(this.requiredFields[j]);
            }
        }

        // this.addCurrentInputToValidateArray(BOOKING_EMAIL);
    }

    isFormDisabled(key: string): boolean {
        if (this.isAppointmentClientShouldUpdate && !this.should_update_client) {
            return true;
        }
        // @ts-ignore-next-line
        if (this.client && !this.client[key]) {
            return false;
        }
        return false;
    }

    input(parsedAddressData: ParsedAddressData) {
        // this[BOOKING_POST_CODE] = parsedAddressData.post_code;
        this[BOOKING_ADDRESS] = parsedAddressData.calizyAddress;
        this.addCurrentInputToValidateArray(BOOKING_ADDRESS);
    }

    submitForm() {
        if (!this.isDisable) {
            this.onSubmit();
        }
    }

    @Emit('toMethodPage')
    toMethodPage() {}

    @Emit('onSubmit')
    onSubmit(): FormBookAppointmentType {
        const updatedUserData = (this.client && this.should_update_client) || !this.client ? {
            [BOOKING_FIRST_NAME]: this[BOOKING_FIRST_NAME] || null,
            [BOOKING_LAST_NAME]: this[BOOKING_LAST_NAME] || null,
            [BOOKING_PHONE]: this[BOOKING_PHONE] || null,
            [BOOKING_EMAIL]: this[BOOKING_EMAIL] || null,
        } : null;
        // @ts-ignore-next-line
        return {
            ...updatedUserData,
            ...(this.isAppointmentClientShouldUpdate && this.should_update_client && { should_update_client: true }),
            ...((this.post_code || this.client_post_code) && { post_code: !this.client ? this.client_post_code : this.post_code }),
            ...(this.branchAddress && !this.isOnPhoneFlow && !this.isOnSiteFlow && !this.isOnVideoFlow && { [BOOKING_ADDRESS]: this[BOOKING_ADDRESS] }),
            ...(this.isOnSiteFlow && { location: this[BOOKING_ADDRESS] }),
            ...((this.isOnPhoneFlow || this.isOnVideoFlow) && this.client && this.client.id && { client_id: this.client.id }),
            [BOOKING_NOTES]: this[BOOKING_NOTES] || null,
            skip_notifications: this.skip_notifications,
        };
    }

    @Watch('phone_number')
    phoneChangeReset() {
        this.$validate([`phone_number`, `email`]);
    }

    @Watch('email')
    emailChangeReset() {
        this.$validate([`phone_number`, `email`]);
    }

    @Watch('last_name')
    lastNameChangeReset() {
        this.$validate([`first_name`, `last_name`]);
    }

    @Watch('first_name')
    firstNameChangeReset() {
        this.$validate([`first_name`, `last_name`]);
    }
}
</script>

<style lang='sass'>
.b-appointment-left-bar_form
    .fw-input--white, .fw-input--textarea
        box-shadow: none
        &::placeholder
            color: #909FB5
    .fw-input__error-text
        margin: 10px 0 15px
        font-size: 12px
        color: #FF004D
    .b-cancel-appointment__form
        .multiselect__tags
            min-height: 60px !important
            padding-left: 15px !important
            border-radius: 10px !important

    .fw-check-box__wrapper.with-text
        display: flex
        flex-direction: row
        width: 100%
        justify-content: flex-start
        margin-top: 10px
        .fw-check-box__label
            margin-left: 10px
            font-weight: 400

.b-appointment-left-bar__time
    &:not(:first-of-type)
        &:before
            content: '-'
            margin: 0 3px

.b-base-form-margin
    margin-bottom: 35px
    @include media('<tablet')
        margin-bottom: 10px

.h-align-items-end
    align-items: end
</style>
