<template>
<div class='b-appointment-info'>
    <div>
        <img :src='requireImage("logo.svg")'>
    </div>
    <h2 class='b-appointment-left-bar__title'>
        {{ companyTitle }}
    </h2>
    <ConsultCard
        v-for='information in informationData'
        :key='information.title'
        :information='information'>
    </ConsultCard>
</div>
</template>

<script lang='ts'>
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { CompanyInformationDataType } from '@/types/Companies';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

@Component({
    components: {
        ConsultCard,
    },
})
export default class CompanyCalendarInfo extends Vue {
    informationData: Array<CompanyInformationDataType> = [];
    companyTitle: string | null = null;
    get companyDate(): any {
        return this.$store.state.CalendarPageStore.company;
    }
    prepareData(company: any): Array<CompanyInformationDataType> {
        const companyDataArray: Array<CompanyInformationDataType> = [];
        if (company.address) {
            companyDataArray.push({
                title: this.$i18n.t('COMPANIES.FORM.INPUT.ADDRESS.LABEL'),
                aside: company.address,
            });
        }
        if (company.siret) {
            companyDataArray.push({
                title: 'SIRET',
                aside: company.siret,
            });
        }
        if (company.phone) {
            companyDataArray.push({
                title: this.$i18n.t('COMPANIES.FORM.INPUT.PHONE.LABEL'),
                aside: company.phone,
            });
        }
        if (company.contact_email) {
            companyDataArray.push({
                title: this.$i18n.t('LABEL.EMAIL'),
                aside: company.contact_email,
            });
        }
        return companyDataArray;
    }
    async getCompanyData(value: string) {
        const data = await AppointmentWebApi.getSocietyItemData(value);
        this.$store.commit('PLAN_COMPANY', data.company);
        this.informationData = this.prepareData(data.company);
        this.companyTitle = data.company.title;
    }
    created() {
        if (this.companyDate) {
            this.companyTitle = this.companyDate.title;
            this.informationData = this.prepareData(this.companyDate);
        } else {
            this.getCompanyData(this.$route.params.id);
        }
    }
}
</script>
