import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { without, update, findIndex } from 'ramda';

@Module({
    namespaced: true,
})
class TeamsStore extends VuexModule {
    teams: Array<string> = [];
    currentTeam: string | null = null;

    @Mutation
    setTeam(team: string) {
        if (this.currentTeam) {
            this.teams = update(
                findIndex((a: string) => team === a, this.teams), team, this.teams
            );
        } else {
            this.teams.push(team);
        }
    }

    @Mutation
    setCurrentTeam(team: string | null) {
        this.currentTeam = team;
    }

    @Mutation
    removeTeam(team: string) {
        this.teams = without([team], this.teams);
    }
}

export default TeamsStore;
