import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
    ON_SITE_ID,
    BY_PHONE_ID,
    IN_BRANCH_BY_ON_SITE,
    IN_BRANCH_BY_ONLINE_VIDEO,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
    IN_BRANCH_BY_VIDEO_PUBLIC_ID,
    IN_BRANCH_BY_VIDEO_PRIVATE_ID, IN_SERVICE_POINT,
} from '@/helpers/appointment';

const AppointmentStore = namespace('AppointmentStore');

@Component
export default class AppointmentMixin extends Vue {
    @AppointmentStore.State('appointmentType') appointmentType!: string;

    get isOnPhoneFlow(): boolean {
        return this.appointmentType === BY_PHONE_ID;
    }

    get isOnVideoFlow(): boolean {
        return this.appointmentType === IN_BRANCH_BY_ONLINE_VIDEO;
    }

    get isOnSiteFlow(): boolean {
        return this.appointmentType === ON_SITE_ID;
    }

    get isOnSiteInBranchFlow(): boolean {
        return this.appointmentType === IN_BRANCH_BY_ON_SITE;
    }

    get isFaceToFaceFlow(): boolean {
        return this.appointmentType === IN_BRANCH_PUBLIC_ID;
    }

    get isVideoconferenceFlow(): boolean {
        return this.appointmentType === IN_BRANCH_VIDEO_ID;
    }

    get isBranchPrivateVideoFlow(): boolean {
        return this.appointmentType === IN_BRANCH_PRIVATE_VIDEO_ID;
    }

    get isBranchByPhonePublicFlow(): boolean {
        return this.appointmentType === IN_BRANCH_BY_PHONE_PUBLIC_ID;
    }

    get isBranchByPhonePrivateFlow(): boolean {
        return this.appointmentType === IN_BRANCH_BY_PHONE_PRIVATE_ID;
    }

    get isBranchByVideoPublicFlow(): boolean {
        return this.appointmentType === IN_BRANCH_BY_VIDEO_PUBLIC_ID;
    }

    get isBranchByVideoPrivateFlow(): boolean {
        return this.appointmentType === IN_BRANCH_BY_VIDEO_PRIVATE_ID;
    }

    get InServicePointFlow(): boolean {
        return this.appointmentType === IN_SERVICE_POINT;
    }
};
