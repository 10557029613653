const NAME: string = 'name';
const ZIP_CODE: string = 'zip_code';
const CITY: string = 'city';
const PHONE_NUMBER: string = 'phone_number';
const EMAIL: string = 'email';
const BRANCH_ID = 'external_id';
const ADDRESS: string = 'address';
const ADDRESS_1: string = 'address_1';
const ADDRESS_2: string = 'address_2';

const CreateBranchPayloadData = {
    address: '',
    post_code: '',
    name: '',
    room: '',
    email: '',
    phone: '',
    kind: '',
};

export {
    NAME,
    ADDRESS,
    ADDRESS_1,
    ADDRESS_2,
    ZIP_CODE,
    CITY,
    PHONE_NUMBER,
    EMAIL,
    BRANCH_ID,
    CreateBranchPayloadData,
};
