<template>
<div class='b-settings-urls-view b-settings-reminder-types__list__wrapper'>
    <FwSpinLoader
        v-if='isLoadingfirst'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='isLoadingfirst'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-settings__title'>
            {{ $t('SETTINGS.ROLE.RIGHTS.GENERIC.URLS') }}
        </div>

        <div class='b-settings-reminder-types__list__wrapper'>
            <div class=' h-flex h-flex-dir-column h-align-items-center'>
                <div class='h-flex h-flex-wrap h-flex-justify-center h-width-100p'>
                    <form class='b-consult-appointments__form qa-consult-appointments-form'
                          @keyup.enter='onSubmit(currentPage, perPage)'>
                        <div class='h-flex h-width-100p'>
                            <FwFormInput
                                v-if='subAccounts && subAccounts.length'
                                class='h-flex-1-0 h-m-10'
                                labelType='label-medium'
                                :title='`${$t(`LABEL.SUB.ACCOUNTS`)}`'>
                                <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                    <FwSelect
                                        v-model='subAccount'
                                        :propsPlaceholder='$t(`LABEL.SELECT.SUB.ACCOUNTS`)'
                                        type='white'
                                        label='name'
                                        name='name'
                                        :options='subAccounts'
                                        :searchable='true'
                                        noElementsFoundText=''
                                        :show-no-results='false'
                                        :clear-on-select='false'
                                        :hide-selected='true'
                                        openDirection='bottom'
                                        isRemoveBySelect
                                        showRightIcon>
                                    </FwSelect>
                                </div>
                            </FwFormInput>
                            <FwFormInput
                                class='h-flex-1-0 h-m-10'
                                labelType='label-medium'
                                :title='$t(`LABEL.CATEGORY`)'>
                                <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                    <FwSelect
                                        v-model='ac_kind'
                                        :propsPlaceholder='$t(`LABEL.CATEGORY.PLACEHOLDER`)'
                                        type='white'
                                        :options='kinds'
                                        :searchable='true'
                                        noElementsFoundText=''
                                        :show-no-results='false'
                                        :clear-on-select='false'
                                        :hide-selected='true'
                                        openDirection='bottom'
                                        isRemoveBySelect
                                        showRightIcon>
                                    </FwSelect>
                                </div>
                            </FwFormInput>
                            <FwFormInput
                                class='h-flex-1-0 h-m-10'
                                labelType='label-medium'
                                :title='$t(`LABEL.TYPE`)'>
                                <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                    <FwSelect
                                        v-model='ac_title'
                                        :propsPlaceholder='$t(`LABEL.SELECT.TYPE`)'
                                        type='white'
                                        :options='categories'
                                        :searchable='true'
                                        noElementsFoundText=''
                                        :show-no-results='false'
                                        :clear-on-select='false'
                                        :hide-selected='true'
                                        openDirection='bottom'
                                        isRemoveBySelect
                                        showRightIcon>
                                    </FwSelect>
                                </div>
                            </FwFormInput>
                            <FwFormInput
                                class='h-flex-1-0 h-m-10'
                                labelType='label-medium'
                                :title='$t(`LABEL.METHOD`)'>
                                <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                    <FwSelect
                                        v-model='skill'
                                        :propsPlaceholder='$t(`SELFCARE.BOOKING.PLACEHOLDER.METHOD`)'
                                        type='white'
                                        :label='labelKey'
                                        :options='skills'
                                        :searchable='true'
                                        noElementsFoundText=''
                                        :show-no-results='false'
                                        :clear-on-select='false'
                                        :hide-selected='true'
                                        openDirection='bottom'
                                        isRemoveBySelect
                                        showRightIcon>
                                    </FwSelect>
                                </div>
                            </FwFormInput>
                        </div>
                    </form>
                    <div class='b-nav__buttons_wrapper'>
                        <FwButton
                            class='qa-button-appointment-search'
                            borderRadiusType='small-border'
                            @click='onSubmit(currentPage, perPage)'>
                            {{ $t('BUTTON.SEARCH') }}
                        </FwButton>
                        <div class='b-add-edit__form__delete h-mt-15'
                             @click='clearInputs'>
                            {{ $t('BUTTON.CLEAR.ALL') }}
                        </div>
                    </div>
                </div>
            </div>
            <FwSpinLoader
                v-if='loading'
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-mt-40 loader'
                :isActive='loading'
                className='h-p-20'>
            </FwSpinLoader>
            <div class='b-consult-appts__right h-flex h-width-100p h-flex-dir-column h-align-items-center'>
                <div class='b-appointment-types__list__wrapper h-width-100p h-mb-60'>
                    <div v-if='!genericUrlsListData.length' class='h-flex h-flex-justify-center'>
                        <div v-if='!loading' class='h-flex h-flex-dir-column h-align-items-center h-mt-40'>
                            <FwIcon
                                class='h-pointer'
                                icon='loupe'
                                size='45'
                                color='#BEC7D4'>
                            </FwIcon>
                            <h2 class='b-appt-result__title'>
                                {{ isNoResult ? $t('LABEL.NO.RESULT') : $t('LABEL.RESULT.HERE.INFO') }}
                            </h2>
                        </div>
                    </div>
                    <div class='h-flex h-flex-dir-column h-flex-justify-start'>
                        <FwHorizontalScroll
                            :isPageLoaded='true'
                            slotWrapperClass='h-flex h-flex-dir-column h-flex-center'
                            iconClass='h-opacity-1'
                            newClass='fw-arrow-big-circle-fixed'
                            :arrowBgWidth='null'
                            colorIcon='#fff'
                            btnLocation='between'>
                            <template #content>
                                <CalendarTable
                                    v-if='!loading && genericUrlsListData.length'
                                    :titles='genericUrlsTitleListKeys'
                                    :titleHeader='`${$tc(`SETTINGS.TEAM.PEOPLE.RESULTS.PLURAL`, totalPage)} (${totalPage})`'
                                    :data='genericUrlsListData'
                                    :bigIndexes='bigIndexes'
                                    class='b-calendar__table__wrapper'>
                                    <div
                                        v-for='(item, i) in genericUrlsListData'
                                        :key='i'
                                        class='b-calendar__table'
                                        :class='{ "h-pointer": canRead }'>
                                        <div class='b-calendar__table__outer'>
                                            <div v-for='(key, j) in mapping'
                                                 :key='j'
                                                 class='b-calendar__table__item'
                                                 :class='{ "b-calendar__table__item--big": bigIndexes.includes(j) }'>
                                                <div style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>
                                                    <template v-if='item[key] && item[key].length'>
                                                        {{ item[key] }}
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class='b-calendar__table__buttons'>
                                            <div class='b-calendar__table__item__delete h-ml-10 h-pointer h-width-60'
                                                 @click='copyLink(item)'>
                                                <FwIcon
                                                    class='h-flex-center h-pointer'
                                                    icon='copy-document'
                                                    size='18'
                                                    color='rgba(33,63,107,.3)'>
                                                </FwIcon>
                                            </div>
                                        </div>
                                    </div>
                                </CalendarTable>
                            </template>
                        </FwHorizontalScroll>
                        <FwPagination
                            v-if='genericUrlsListData && genericUrlsListData.length && totalPage > perPage'
                            :totalPage='totalPage'
                            :currentPage='currentPage'
                            :perPage='perPage'
                            :value='perPage'
                            @getData='onSubmit'>
                        </FwPagination>
                    </div>
                </div>
            </div>
        </div>
    </template>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { SettingsWebApi } from '@/api/settings/SettingsApi';

import { CalendarTable } from '@/components/common/CalendarTable';

import PermissionsMixin from '@/mixins/permissions';
import TranslateMixin from '@/mixins/TranslateMixin';

import { AppointmentAdvisorUrlsBuilderType, CategoryLocationSkillNameType } from '@/types/Appointment';
import { SubAccountType } from '@/types/Account';

const CommonStore = namespace('CommonStore');

@Component({
    components: {
        CalendarTable,
    },
})
export default class SettingsGenericUrlsPage extends Mixins(PermissionsMixin, TranslateMixin) {
    @CommonStore.Getter('subAccounts') subAccounts!: Array<SubAccountType>;

    skill: CategoryLocationSkillNameType | null = null;
    ac_kind: string = '';
    ac_title: string = '';
    isLoadingfirst: boolean = false;
    loading: boolean = false;
    isNoResult: boolean = false;
    isPageToUpdate: boolean = false;
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;
    timeout: number | NodeJS.Timeout = 0;
    categories: Array<string> = [];
    skills: Array<CategoryLocationSkillNameType> = [];
    kinds: Array<string> = [];
    genericUrlsListData: Array<any> = [];
    subAccount: SubAccountType | null = null;
    genericUrlsTitleListKeys: Array<string> = [
        'LABEL.CATEGORY',
        'LABEL.TYPE',
        'LABEL.METHOD',
        'LABEL.LINK',
    ];
    bigIndexes: Array<number> = [1, 3];

    mapping: Array<string> = [
        'appointment_category_kind',
        'appointment_category_title',
        'location_name',
        'full_url',
    ];

    get isUrlBuilderAvailable() {
        if (!this.accountData) {
            return false;
        }

        return (
            this.accountData &&
            this.accountData.account.geo_scope === 'national' &&
            this.accountData.account.url_builder_support
        );
    }

    get skillId() {
        return this.skill ? `&skill_id=${this.skill.id}` : '';
    }

    get accountKind() {
        return this.ac_kind ? `&ac_kind=${this.ac_kind}` : '';
    }

    get accountTitle() {
        return this.ac_title ? `&ac_title=${this.ac_title}` : '';
    }

    get subAccountId() {
        return this.subAccount ? `&sc_account_id=${this.subAccount.sc_account_id}` : '';
    }

    get url() {
        return `${this.subAccountId}${this.skillId}${this.accountKind}${this.accountTitle}`;
    }

    get isButtonDisabled(): boolean {
        if (!(this.skill || this.ac_kind || this.ac_title)) {
            return true;
        }
        return !this.url;
    }

    clearInputs() {
        this.skill = null;
        this.ac_kind = '';
        this.ac_title = '';
        this.genericUrlsListData = [];
        this.subAccount = null;
    }

    async copyLink(item: AppointmentAdvisorUrlsBuilderType) {
        await navigator.clipboard.writeText(item.full_url);
        this.sentNotif(`SELFCARE.YOUR.BOOKING.LINK.COPY.TO.CLIPBOARD`, true);
    }

    async getGenericUrls() {
        this.isLoadingfirst = true;
        try {
            const { categories, skills, kinds } = await SettingsWebApi.getGenericUrlsSelectors();
            this.categories = categories;
            this.skills = skills;
            this.kinds = kinds;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoadingfirst = false;
        }
    }

    async onSubmit(el: number, val: number) {
        this.isNoResult = false;
        try {
            this.loading = true;
            const payload: any = {
                current_page: el,
                per_page: val,
            };
            if (this.isPageToUpdate) {
                payload.current_page = 1;
            }
            const data = await SettingsWebApi.getGenericUrls(payload, this.url);
            if (!data.meta) {
                this.genericUrlsListData = [];
                this.totalPage = 0;
                this.currentPage = 1;
                this.isNoResult = true;
            } else {
                this.genericUrlsListData = data.generic_urls;
                this.loading = false;
                this.totalPage = data.meta.total_count;
                this.currentPage = data.meta.current_page;
                if (!this.genericUrlsListData.length) {
                    this.isNoResult = true;
                }
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
            this.isPageToUpdate = false;
        }
    }

    created() {
        if (!this.isUrlBuilderAvailable) {
            this.$router.replace('/profile');
            return;
        }
        this.getGenericUrls();
        this.onSubmit(1, 10);
    }
};
</script>

<style lang='sass'>
$min-cell-width: 190px
$min-cell-medium-width: 240px
$min-cell-big-width: 320px

.b-settings-urls-view
    &--max-width
        max-width: 800px
    .b-calendar__table__inner
        max-width: none

    &__inner
        display: flex
        align-items: baseline

    &__text
        color: #213F6B
        opacity: 0.4
        font-size: 14px
        font-weight: 600
        margin-right: 20px
        text-transform: uppercase

    &__select
        width: 140px
        .multiselect__tags, .fw-select-white
            height: auto !important

    .b-appointment__table__outer
        background-color: #ffffff
        display: flex
        flex: 1
        border-radius: 5px
        height: 60px

    .fw-pagination
        width: calc(100% - 410px)!important

    .fw-horizontal-scroll__inner, .b-calendar-page__height-wrapper
        height: 100%
</style>
