export default {
    'SELECT.SERVICES_POINT.PLURAL.TITLE': 'Service Points',
    'SELECT.SERVICES_POINT.ONE.TITLE': 'Service Point',
    'SERVICES_POINT.ADVISOR.NUMBER': 'Number of advisors',
    'SERVICES_POINT.PLACEHOLDER.SEARCH': 'Search a service point',
    'SERVICES_POINT.TITLE.ADD': 'Add a service point',
    'SERVICES_POINT.TITLE.EDIT': 'Edit Service Point',
    'SERVICES_POINT.FORM.DELETE.POPUP.TITLE': 'Delete the service point',
    'SERVICES_POINT.FORM.DELETE.POPUP.TEXT': 'Do you really want to delete this service point?',
    'SERVICES_POINT.FORM.CREATE.POPUP.TEXT': 'The service point has been added',
    'SERVICES_POINT.FORM.UPDATE.POPUP.TEXT': 'The service point was updated',
    'SERVICES_POINT.DELETE.TXT': 'DELETE THIS SERVICE POINT',
    'SERVICES_POINT.SHIFT.TXT': 'Shifts',
    'SERVICES_POINT.SHIFTS.DELETE.TITLE': 'Delete shift',
    'SERVICES_POINT.SHIFTS.DELETE.ALL.FUTURE': 'Delete all future shifts',
    'SERVICES_POINT.SHIFTS.DELETE.ONLY_THIS': 'Delete this shift',
    'SERVICES_POINT.SHIFTS.DELETE.ALL': 'Delete all shifts',
    'SERVICES_POINT.SHIFTS.DELETE.ASIDE_RECURRENCE': 'Are you sure you want to delete this shift? ' +
        'This is a recurrent shift',
    'SERVICES_POINT.SHIFTS.DELETE.ASIDE': 'Do you really want to delete this shift?',
    'SERVICES_POINT.SHIFTS.DELETE.CHOOSE.TITLE': 'Consult shift',
    'SERVICES_POINT.SHIFTS.FIND_ADVISOR': 'Find an advisor',
};
