<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='400px'
        width='auto'
        :title='title'
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        :centered='true'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <div class='h-text-center h-mb-20'>
                    {{ asideText }}
                </div>
                <template v-if='isRecurrenceOptionsActive || (isAvailabilities && isMultipleEvents)'>
                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove-this-alone'
                        borderRadiusType='small-border'
                        :disabled='isRequestSending'
                        @click='removeSlotAlone'>
                        {{ deleteTextThis }}
                    </FwButton>

                    <FwButton
                        v-if='isUnavailabilities'
                        class='h-mt-10 h-width-250 qa-remove-future'
                        :paddingHorizontal='true'
                        :disabled='isRequestSending'
                        borderRadiusType='small-border'
                        @click='removeSlotFuture'>
                        {{ deleteTextFuture }}
                    </FwButton>

                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove'
                        borderRadiusType='small-border'
                        :paddingHorizontal='true'
                        :disabled='isRequestSending'
                        @click='removeSlot'>
                        {{ deleteTextAll }}
                    </FwButton>
                </template>
                <template v-else>
                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove-alone'
                        borderRadiusType='small-border'
                        :disabled='isRequestSending'
                        @click='removeSlotBase'>
                        {{ deleteTextAlone }}
                    </FwButton>
                </template>
            </div>
        </template>
        <template #footer>
            <div class='b-company-edit__delete'
                 @click='$emit("close")'>
                {{ $t('ADD.RESERVED.SLOT.POPUP.DELETE.CANCEL') }}
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { Component, Emit, Prop } from 'vue-property-decorator';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';

const RESERVED_SLOT_IMA_PROTECT = 'reserved-slots';
const RESERVED_SLOT_UNAVAILABILITIES = 'unavailabilities';
const RESERVED_SLOT_AVAILABILITIES = 'availabilities';
const RESERVED_SLOTS_TYPES: Array<string> = [RESERVED_SLOT_IMA_PROTECT, RESERVED_SLOT_UNAVAILABILITIES];

@Component
export default class DeleteReservedSlotPopup extends Vue {
    @Prop({ type: Object, required: true }) readonly instanceData!: UnavailabilityEventInstancesType;
    @Prop({ type: String, required: true, validator: (type: string) => RESERVED_SLOTS_TYPES.includes(type) })
    readonly type!: string;
    @Prop({ type: Boolean, default: false }) readonly isMultipleEvents!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    get isImaProtectType(): boolean {
        return this.type === RESERVED_SLOT_IMA_PROTECT;
    }

    get isUnavailabilities(): boolean {
        return this.type === RESERVED_SLOT_UNAVAILABILITIES;
    }

    get isAvailabilities(): boolean {
        return this.type === RESERVED_SLOT_AVAILABILITIES;
    }

    get title(): TranslateResult {
        if (this.isAvailabilities) {
            return this.$i18n.t(`ADD.AVAILABILITY.SLOT.POPUP.DELETE.TITLE`);
        }
        if (this.isImaProtectType) {
            return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.TITLE`);
        }
        return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.TITLE`);
    }

    get deleteTextFuture(): TranslateResult {
        if (this.isImaProtectType) {
            return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ALL.FUTURE`);
        }
        return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.ALL.FUTURE`);
    }

    get deleteTextThis(): TranslateResult {
        if (this.isImaProtectType) {
            return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ONLY_THIS`);
        } else if (this.isAvailabilities) {
            return this.$t('ADD.RESERVED.SLOT.POPUP.DELETE.AVAILABILITY.ONLY_THIS');
        }
        return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.ONLY_THIS`);
    }

    get deleteTextAll(): TranslateResult {
        if (this.isImaProtectType) {
            return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ALL`);
        } else if (this.isAvailabilities) {
            return this.$t(`ADD.RESERVED.SLOT.POPUP.DELETE.AVAILABILITIES.ALL`);
        }
        return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.ALL`);
    }

    get deleteTextAlone(): TranslateResult {
        return this.isImaProtectType ? this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.BASE`) : this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.BASE`);
    }

    get asideText(): TranslateResult {
        if (this.isAvailabilities) {
            return this.$i18n.t(`ADD.AVAILABILITY.SLOT.POPUP.DELETE.ASIDE`);
        }
        if (this.isImaProtectType) {
            return this.isRecurrenceOptionsActive ?
                this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ASIDE_RECCURENCE`) :
                this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ASIDE`);
        }
        return this.isRecurrenceOptionsActive ?
            this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE_RECCURENCE`) :
            this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE`);
    }

    get isRecurrenceOptionsActive(): boolean {
        return Boolean(this.instanceData && (this.instanceData.is_recurrent || this.instanceData.parent_id));
    }

    removeSlotBase() {
        if (this.isRecurrenceOptionsActive) {
            this.removeSlotAlone();
        } else {
            this.removeSlot();
        }
    }

    @Emit('removeSlot')
    removeSlotAlone() {
        return { instance: this.instanceData, onlyInstance: true };
    }

    @Emit('removeSlot')
    removeSlotFuture() {
        return { instance: this.instanceData, future: true };
    }

    @Emit('removeSlot')
    removeSlot() {
        return { instance: this.instanceData };
    }
}
</script>

<style lang='sass'>
.h-width-250
    width: 250px

.b-company-edit__delete
    text-transform: uppercase
    margin-top: 20px
    font-size: 12px
    color: #FF004D
    font-weight: bold
    cursor: pointer

    &:hover
        opacity: 0.7
</style>
