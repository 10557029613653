<template>
<div>
    <form class='b-add-branch__form h-flex-dir-column'
          @keydown.stop.prevent.enter='onSubmit'>
        <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
            {{ $t('LABEL.CLIENT.INFORMATION') }}
        </h3>
        <div class='b-form-base-line'>
            <FwFormInput
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t("LABEL.NAME")}`'>
                <FwInput
                    :value='client_full_name'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mhd-20 b-form-base-line__d260'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE`)}`'>
                <FwInput
                    :value='client_phone'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}`'>
                <FwInput
                    :value='client_email'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
        </div>
        <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
            {{ $t('LABEL.ADVISOR.INFORMATION') }}
        </h3>
        <div class='b-form-base-line'>
            <FwFormInput
                class='b-form-base-line__inner-input h-mhd-20 fw-input--uppercase'
                labelType='label-medium'
                :title='`${$t(`LABEL.NAME`)}`'>
                <FwInput
                    :value='advisor_technician_full_name'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mhd-20 b-form-base-line__d260'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE`)}`'>
                <FwInput
                    :value='advisor_technician_phone'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}`'>
                <FwInput
                    :value='advisor_technician_email'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
        </div>
        <template v-if='appointmentData.category !== `location_category_by_phone`'>
            <template v-if='appointmentData.category !== `location_category_on_site` && appointmentData.category !== `location_category_by_online_video` && isBranchesFlow'>
                <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                    {{ $t('LABEL.APPT.LOCATION') }}
                </h3>
                <div class='b-form-base-line'>
                    <FwFormInput
                        class='b-form-base-line__inner-input h-mhd-20 fw-input--uppercase'
                        labelType='label-medium'
                        :title='$t(`LABEL.BRANCH.NAME`)'>
                        <FwInput
                            :value='branch_name'
                            type='text'
                            name='text'
                            :disabled='disabledForm'
                            inputStyle='white-bigger-shadow'>
                        </FwInput>
                    </FwFormInput>
                    <FwFormInput
                        class='h-mhd-20 fw-input--uppercase b-form-base-line__d260'
                        labelType='label-medium'
                        :title='`${$t(`LABEL.BRANCH.PHONE`)}`'>
                        <FwInput
                            :value='branch_phone'
                            type='text'
                            name='text'
                            :disabled='disabledForm'
                            inputStyle='white-bigger-shadow'>
                        </FwInput>
                    </FwFormInput>
                    <FwFormInput
                        class='b-form-base-line__inner-input h-mhd-20'
                        labelType='label-medium'
                        :title='`${$t(`LABEL.BRANCH.ADDRESS`)}`'>
                        <FwInput
                            :value='branch_address'
                            type='text'
                            name='text'
                            :disabled='disabledForm'
                            inputStyle='white-bigger-shadow'>
                        </FwInput>
                    </FwFormInput>
                </div>
            </template>
            <div v-if='appointmentData.category === `location_category_on_site`'>
                <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                    {{ $t('LABEL.APPT.LOCATION') }}
                </h3>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20'
                    labelType='label-medium'
                    :title='`${$t(`SELECT.ADDRESS.BRANCH.CUSTOMER`)}`'>
                    <FwInput
                        :value='location'
                        type='text'
                        name='text'
                        :disabled='disabledForm'
                        inputStyle='white-bigger-shadow'>
                    </FwInput>
                </FwFormInput>
            </div>
            <div v-if='appointmentData.video_conference_url' class='h-width-100p h-mt-15'>
                <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                    {{ $t('LABEL.APPT.LOCATION') }}
                </h3>
                <FwFormInput
                    class='h-mh-20'
                    labelType='label-medium'
                    :title='$t("LABEL.VIDEO.CONFERENCE.URL")'>
                    <a
                        class='h-flex h-flex-center h-flex-space-between b-link'
                        :href='video_conference_url'
                        target='_blank'>
                        <span class='b-link-text'>
                            {{ appointmentData.video_conference_url }}
                        </span>
                        <FwIcon
                            icon='link-target'
                            color='#213F6B'
                            size='24'>
                        </FwIcon>
                    </a>
                </FwFormInput>
            </div>
        </template>
    </form>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';

import { AppointmentSearchType } from '@/types/Appointment';
import ValidationMixin from '@/mixins/validation';
import PermissionsMixin from '@/mixins/permissions';

@Component
export default class InternalAppointmentRecapForm extends Mixins(ValidationMixin, PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly eventTime!: string;

    disabledForm: boolean = true;
    client_full_name: string = '';
    client_phone: string = '';
    client_email: string = '';
    client_id: string = '';
    advisor_technician_full_name: string = '';
    advisor_technician_phone: string = '';
    advisor_technician_email: string = '';
    advisor_membership: string = '';
    location: string = '';
    branch_name: string = '';
    branch_phone: string = '';
    branch_address: string = '';
    video_conference_url: string = '';
    serverErrors: serverErrors = {};

    get isManagerAdvisor(): boolean {
        return this.appointmentData.manager_id === this.appointmentData.advisor_id;
    }

    get branchInformationRow(): string {
        return this.branch_address || this.branch_phone || this.branch_name;
    }

    updateFormByServerValues(): void {
        if (this.appointmentData) {
            if (this.appointmentData.client_full_name) {
                this.client_full_name = this.appointmentData.client_full_name;
            }
            if (this.appointmentData.client_phone) {
                this.client_phone = this.appointmentData.client_phone;
            }
            if (this.appointmentData.client_id) {
                this.client_id = this.appointmentData.client_id;
            }
            if (this.appointmentData.client_email) {
                this.client_email = this.appointmentData.client_email;
            }
            if (this.appointmentData.branch_name) {
                this.branch_name = this.appointmentData.branch_name;
            }
            if (this.appointmentData.branch_address) {
                this.branch_address = this.appointmentData.branch_address;
            }
            if (this.appointmentData.location) {
                this.location = this.appointmentData.location;
            }
            if (this.appointmentData.branch_phone) {
                this.branch_phone = this.appointmentData.branch_phone;
            }
            if (this.appointmentData.advisor_technician_full_name) {
                this.advisor_technician_full_name = this.appointmentData.advisor_technician_full_name;
            }
            if (this.appointmentData.advisor_technician_phone) {
                this.advisor_technician_phone = this.appointmentData.advisor_technician_phone;
            }
            if (this.appointmentData.advisor_technician_email) {
                this.advisor_technician_email = this.appointmentData.advisor_technician_email;
            }
            if (this.appointmentData.advisor_hub_name && this.appointmentData.advisor_branch_name) {
                this.advisor_membership = `${this.appointmentData.advisor_hub_name} ${this.appointmentData.advisor_branch_name}`;
            }
            if (this.appointmentData.advisor_hub_name && !this.appointmentData.advisor_branch_name) {
                this.advisor_membership = `${this.appointmentData.advisor_hub_name}`;
            }
            if (!this.appointmentData.advisor_hub_name && this.appointmentData.advisor_branch_name) {
                this.advisor_membership = `${this.appointmentData.advisor_branch_name}`;
            }
            if (this.appointmentData.video_conference_url) {
                this.video_conference_url = this.appointmentData.video_conference_url;
            }
        }
    }

    created(): void {
        this.updateFormByServerValues();
    }

    @Emit('onSubmit')
    onSubmit() {}
}
</script>

<style lang='sass'>
.b-add-branch__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px

    &__row
        display: flex
        width: 100%
        margin-bottom: 0

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-branch__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7
    .b-link
        background: #EDF0F3
        min-height: 60px
        width: 100%
        border-radius: 10px
        font-weight: 500 !important
        padding: 0 20px
        &-text
            padding-right: 20px
            text-overflow: ellipsis
            border: none
            overflow: hidden
            white-space: nowrap
        &:hover
            text-decoration: underline
</style>
