export default {
    'COMPANIES.FORM.TITLE': 'Ajouter une société',

    'COMPANIES.SEARCH.PLACEHOLDER': 'Rechercher une société',
    'COMPANIES.CHOOSE.PLACEHOLDER': 'Sélectionner une société',
    'COMPANIES.ADD.BUTTON': 'Ajouter une société',
    'COMPANY.TITLE.TAKEN': 'Le nom est déjà utilisé',

    'COMPANIES.FORM.INPUT.NAME.LABEL': 'Nom de la société',
    'COMPANIES.FORM.INPUT.ADDRESS.LABEL': 'Adresse',
    'COMPANIES.FORM.INPUT.ADDRESS_2.LABEL': 'Adresse 2',
    'COMPANIES.FORM.INPUT.START_POINT.LABEL': 'Point de départ',
    'COMPANIES.FORM.INPUT.START_POINT.ERROR': 'Point de départ invalide',
    'COMPANIES.FORM.INPUT.PHONE.LABEL': 'Téléphone',
    'COMPANIES.FORM.INPUT.CITY.LABEL': 'Ville',
    'COMPANIES.FORM.INPUT.PLACEHOLDER.NUMBERS': 'chiffres',

    'COMPANY.POPUP.LOCATION.TITLE': 'Confirmez-vous cette adresse?',
    'COMPANY.POPUP.LOCATION.BUTTON.CONFIRM': 'Oui, je confirme cette adresse',
    'COMPANY.POPUP.LOCATION.BUTTON.CANCEL': 'Non changer d\'adresse',
    'COMPANY.POPUP.LOCATION.FAILED.TITLE': 'La géolocalisation a échouée!',
    'COMPANY.POPUP.LOCATION.FAILED.TEXT': 'Veuillez essayer une autre adresse',
    'COMPANY.POPUP.LOCATION.FAILED.BUTTON': 'Modifier l\'adresse',
    'COMPANY.YOU.CANNOT.EDIT.ADDRESS': 'Vous ne pourrez plus modifier les champs Adresse 1, Ville et Code postal après confirmation',

    'COMPANIES.FORM.SUBMIT.TEXT': 'Ajouter',
    'COMPANIES.POPUP.SUCCESS.TITLE': 'Ajout réussi!',
    'COMPANIES.POPUP.SUCCESS.MESSAGE': 'La société a bien été ajoutée',
    'COMPANIES.POPUP.SUCCESS.CHANGED.MESSAGE': 'La société a bien été modifiée',
    'COMPANIES.POPUP.SUCCESS.CLOSE': 'Fermer',
    'COMPANIES.POPUP.CHECKBOX.TXT': 'Je confirme que les informations liées à l’adresse sont correctes. ' +
        'La validation de ce bouton permet de calculer les premiers créneaux disponibles.',
};
