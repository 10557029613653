import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { CreateRoomPayloadType, CategoryLocationSkillNameType, CategorySkillNameType } from '@/types/Appointment';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { AccountOnboarding } from '@/api/company/CompanyApi';
import { CompanyType } from '@/types/Companies';
import { BRANCH_KEY } from '@/helpers/consts';

@Module({ namespaced: true })
class OnboardingStore extends VuexModule {
    branchData: CreateRoomPayloadType | null = null;
    categoryLocationSkillNames: Array<CategoryLocationSkillNameType> = [];
    appointmentCategoriesData: Array<string> = [];
    skills: Array<CategorySkillNameType> = [];
    companies: Array<CompanyType> = [];

    @Mutation
    mutationBranchData(branchData: CreateRoomPayloadType) {
        this.branchData = branchData;
        window.localStorage.setItem(BRANCH_KEY, JSON.stringify(branchData));
    }

    @Mutation
    setCategoryLocationSkillNames(category_location_skill_names: Array<CategoryLocationSkillNameType>): void {
        this.categoryLocationSkillNames = category_location_skill_names;
    }

    @Mutation
    setAppointmentCategoriesData(appointment_category_kinds: Array<string>): void {
        this.appointmentCategoriesData = appointment_category_kinds;
    }

    @Mutation
    async fetchCompanies(): Promise<void> {
        const data = await AccountOnboarding.getAccountOnboardingCompanies({ page: 1, per_page: 50 });
        this.companies = data.companies;
    }

    @Mutation
    setFetchSkills(skill_names: Array<CategoryLocationSkillNameType>): void {
        this.skills = skill_names;
    }

    @Action({ commit: 'setCategoryLocationSkillNames' })
    async fetchCategoryLocationSkillNames(): Promise<Array<CategoryLocationSkillNameType>> {
        const data = await OnboardingApi.categoryLocationSkillNames();
        return data.category_location_skill_names;
    }

    @Action({ commit: 'setAppointmentCategoriesData' })
    async fetchAppointmentCategoriesData(): Promise<Array<string>> {
        const data = await OnboardingApi.getAppointmentCategoriesData();
        return data.appointment_category_kinds;
    }

    @Action({ commit: 'setFetchSkills' })
    async fetchSkills(): Promise<Array<CategorySkillNameType>> {
        const data = await OnboardingApi.fetchSkillNames();
        return data.skill_names;
    }

    get branchAddData(): CreateRoomPayloadType | null {
        return this.branchData;
    }
}

export default OnboardingStore;
