<template>
<div>
    AppointmentPartnerCompanies
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class AppointmentPartnerCompanies extends Vue {}
</script>

<style lang='sass'>

</style>
