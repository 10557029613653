<template>
<div class='b-onboarding__wrapper b-page-container'>
    <div class='b-onboarding__title'>
        {{ $t('CONSULT.INFO.WORKING.HOURS') }}
    </div>
    <div class='b-onboarding__aside'>
        {{ $t(`Click on a ${workerAdvisor} to set up his working hours`) }}
    </div>
    <div class='b-onboarding__wrapper--hours h-flex h-flex-dir-column h-mt-30'>
        <div class='h-flex h-flex-wrap h-mb-30'>
            <div style='flex: 0 0 50%;'>
                <FwInput
                    class='h-mh-10'
                    :value='searchText'
                    :placeholder='$t(`LABEL.PLACEHOLDER.SEARCH`)'
                    iconRight='loupe'
                    iconSize='15'
                    inputStyle='gray'
                    type='search'
                    @update:value='value => searchText = value'>
                </FwInput>
            </div>
            <div class='h-flex h-flex-justify-end' style='flex: 0 0 50%;'>
                <div class='b-work-company-page fw-select-base fw-select-white-modern h-width-150 h-mh-10'>
                    <FwSelect
                        v-model='sortValue'
                        type='sort-simple'
                        label='name'
                        :canNotDelete='true'
                        :searchable='false'
                        :multiply='false'
                        :options='sortOptions'>
                        <template #title>
                            {{ $t('BUTTON.SORT_BY') }}
                        </template>
                        <template v-slot:option='{ props }'>
                            <slot
                                :props='props'
                                name='option'>
                                <div class='multiselect__content-item'>
                                    <div class='multiselect__item-header h-flex h-flex-space-between h-flex-center h-min-width-200'>
                                        <span class='multiselect__title-first'>
                                            {{ props.option.name }}
                                        </span>
                                    </div>
                                </div>
                            </slot>
                        </template>
                    </FwSelect>
                </div>
            </div>
        </div>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <div v-else class='b-consult-cards__wrapper b-consult-sync-card'>
            <WorkerCard
                v-for='worker in workersListArray'
                :key='worker.id'
                :workerTimeIcon='true'
                :worker='worker'
                class='h-mh-10 h-mb-20'
                :workerId='workerId'
                :workerIdsGetter='workerIdsGetter'
                @click.native='confirmWorkingHoursPopup(worker.id)'>
            </WorkerCard>
        </div>
    </div>
    <FwButton
        v-if='workerIdsGetter.length !== 0'
        class='h-mt-30'
        :style='{
            padding: `0 22px`,
            height: `40px`,
            minWidth: `auto`
        }'
        :fontSize='`14px`'
        borderRadiusType='tiny-border'
        :to='`${mainRouteGlobal}/invitations/choose`'>
        {{ $t('BUTTON.NEXT') }}
    </FwButton>
    <FwButton v-else
              class='h-m-20'
              color='transparent'
              :to='`${mainRouteGlobal}/invitations/choose`'
              :fontColor='`rgba(33, 63, 107, 0.3)`'
              @click='action'>
        {{ $t('BUTTON.LATER') }}
    </FwButton>
    <WorkingHoursWorkersListPopup
        v-if='workingHoursPopup'
        :currentWorker='true'
        :currentWorkerId='workerId'
        @closePopup='closePopup'>
    </WorkingHoursWorkersListPopup>
    <FwPagination
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='getTechniciansList'>
    </FwPagination>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { WorkerCard } from '@/components/simple/WorkerCard';
import { workersDataProps } from '@/helpers/workers';
import { WorkingHoursWorkersListPopup } from '@/components/popups/WorkingHoursWorkersListPopup';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { WorkersListDataType, pagePaginationData } from '@/types/Appointment';

const SORT_BY_NAME = 'name';
const SORT_BY_NUMBER = 'number';
const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        WorkerCard,
        WorkingHoursWorkersListPopup,
    },
})
export default class OnboardingWorkersListWorkingHours extends Vue {
    @WorkerPageStore.Mutation('setWorkerIds') setWorkerIds!: Function;
    @WorkerPageStore.Getter('workerIdsGetter') workerIdsGetter!: Array<string>;
    @Prop({ type: String, default: '' }) readonly workerAdvisorString!: string;
    workingHoursPopup: boolean = false;
    loading: boolean = true;
    searchText: string = '';
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;
    timeout: number | NodeJS.Timeout = 0;
    sortByWorkersCount: boolean = false;
    workerId: number | null = null;
    workersListArray: Array<WorkersListDataType> = [];
    sortValue: null | { name: string, type: string } = null;
    sortOptions: Array<{ name: any | TranslateResult, type: string }> = [];
    get workerAdvisor() {
        return this.mainRouteGlobal === `/partners-onboarding` ? 'technician' : 'advisor';
    }
    goToSpecific() {
        console.log('specific');
    }
    getTechniciansList(el: number, val: number) {
        if (this.searchText.length > 1) {
            this.getTechniciansBySearch(el, val, this.searchText, this.sortByWorkersCount);
        } else if (this.searchText.length === 0) {
            this.getTechnicians(el, val, this.sortByWorkersCount);
        }
    }
    search() {
        clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.getTechniciansBySearch(this.currentPage, this.perPage, this.searchText, this.sortByWorkersCount);
        }, 1000);
    }
    closePopup(id: number) {
        this.workerId = id;
        this.workingHoursPopup = false;
    }
    get preparedWorkersCards() {
        return workersDataProps;
    }
    confirmWorkingHoursPopup(id: number) {
        this.workerId = id;
        this.setWorkerIds(id);
        this.workingHoursPopup = true;
    }
    async getTechnicians(el: number, val: number, sort: boolean) {
        this.loading = true;
        this.currentPage = el;
        const payload: pagePaginationData = {
            current_page: el,
            per_page: val,
            sort,
        };
        if (this.workerAdvisor === 'technician') {
            // @ts-ignore-next-line
            const { data } = await WorkersWebApi.getTechniciansData(payload, 'technician');
            this.totalPage = data.meta.total_count;
            // @ts-ignore-next-line
            this.workersListArray = data.users;
        } else {
            const data = await WorkersWebApi.getAdvisors(payload);
            this.totalPage = data.meta.total_count;
            // @ts-ignore-next-line
            this.workersListArray = data.users;
        }
        this.loading = false;
    }

    async getTechniciansBySearch(el: number, val: number, searchText: string, sort: boolean) {
        if (this.searchText.length > 1) {
            this.loading = true;
            this.currentPage = el;
            const payload: pagePaginationData = {
                current_page: el,
                per_page: val,
                sort,
            };
            const payloadTechnician: any = {
                scope: 'technician',
                current_page: el,
                per_page: val,
                sort,
            };
            if (this.workerAdvisor === 'technician') {
                const { data } = await WorkersWebApi.getTechniciansDataBySearch(payloadTechnician, searchText);
                this.workersListArray = data.users;
                this.totalPage = data.meta.total_count;
            } else {
                // @ts-ignore-next-line
                const { data } = await WorkersWebApi.getAdvisorsDataBySearch(payload, searchText);
                this.workersListArray = data.users;
                this.totalPage = data.meta.total_count;
            }
            this.loading = false;
        }
    }

    created() {
        this.sortOptions = [
            {
                name: 'AZ',
                type: SORT_BY_NAME,
            },
            {
                name: 'ZA',
                type: SORT_BY_NUMBER,
            },
        ];
        this.sortValue = this.sortOptions[0];
    }
    @Watch('searchText')
    countSearchInput() {
        this.currentPage = 1;
        if (this.searchText.length > 1) {
            this.search();
        } else if (this.searchText.length === 0) {
            this.getTechnicians(this.currentPage, this.perPage, this.sortByWorkersCount);
        }
    }
    @Watch('sortValue')
    sortBy() {
        if (this.sortValue) {
            let key: string = `first_name`; // default
            if (this.sortValue.type === SORT_BY_NAME) {
                key = `first_name`;
                this.sortByWorkersCount = false;
                this.getTechniciansList(this.currentPage, this.perPage);
            } else if (this.sortValue.type === SORT_BY_NUMBER) {
                key = `number`;
                this.sortByWorkersCount = true;
                this.getTechniciansList(this.currentPage, this.perPage);
            }
        }
    }
}
</script>

<style lang='sass'>
.b-onboarding
    &__wrapper
        padding: 110px 0
        display: flex
        flex-direction: column
        align-items: center
        &--hours
            .b-worker-card__wrapper
                max-width: 290px
    &__title
        margin-top: 20px
        font-size: 30px
        line-height: 37px
        letter-spacing: 0.37px
        font-weight: 600

    &__aside
        margin-top: 9px
        font-size: 16px
    &__ol
        max-width: 600px
        margin-top: 10px
        font-size: 22px
        line-height: 30px
        font-weight: 600
        counter-reset: list

        li
            margin-top: 20px
            list-style-type: none

            &:before
                list-style-type: none
                counter-increment: list
                content: counter(list) ' - '
.b-consult-sync-card
    .b-worker-card
        width: 290px
</style>
