<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        max-width='450px'
        width='90%'
        popupClass='b-popup-style'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'>
        <template #header>
            <router-link to='/login'>
                <FwIcon icon='arrow-left'
                        style='top: 50px; left: 50px'
                        size='14'
                        class='h-pos-abs'
                        color='#27dbbd'>
                </FwIcon>
            </router-link>
            <div class='h-text-center h-mb-20'>
                <img :src='logoImageUrl'>
                <div class='b-base-title h-mt-10'>
                    {{ $t('RESET.PASSWORD.HEADER') }}
                </div>
            </div>
        </template>
        <template #body>
            <form class='h-mt-15'
                  @keyup.enter='sendDataToServer'>
                <FwFormInput
                    class='h-mb-30'
                    :fieldHasError='fieldHasError(EMAIL_NAME)'
                    labelType='label-thin'
                    :title='$t(`LOGIN.EMAIL.LABEL`)'>
                    <FwInput
                        :value='email'
                        type='email'
                        name='email'
                        autocomplete='username'
                        placeholder='johndoe@email.com'
                        :error='getFieldErrorText(EMAIL_NAME)'
                        inputStyle='white-bigger-shadow'
                        @update:value='value => email = value'
                        @input='clearServerErrorsBase(EMAIL_NAME)'
                        @blur='addCurrentInputToValidateArray(EMAIL_NAME)'>
                    </FwInput>
                </FwFormInput>

                <div v-if='globalServerError'
                     class='b-text-error'>
                    {{ globalServerError }}
                </div>
            </form>
        </template>

        <template #footer>
            <FwButton
                class='h-mt-20'
                :disabled='!isFormValid'
                borderRadiusType='small-border'
                @click='sendDataToServer'>
                {{ $t('BUTTON.SUBMIT') }}
            </FwButton>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AuthWebApi } from '@/api/auth/AuthApi';
import ValidationMixin from '@/mixins/validation';
import RedirectMixin from '@/mixins/redirect';
import AuthMixin from '@/mixins/auth';

const EMAIL_NAME = 'email';
const GLOBAL_AUTH_ERROR: string = 'Email is incorrect. Please, try again.';

@Component({
    validators: {
        [EMAIL_NAME](value: string) {
            return this.emailValidator({ value });
        },
    },
})
export default class ResetPasswordEmailPage extends Mixins(ValidationMixin, RedirectMixin, AuthMixin) {
    [EMAIL_NAME]: string = '';
    resetData: any = null;
    EMAIL_NAME: string = EMAIL_NAME;
    globalServerError: string = '';

    serverErrors: serverErrors = {
        [EMAIL_NAME]: null,
    };

    get logoImageUrl() {
        // @ts-ignore-next-line
        return this.requireImage('login-image.svg');
    }

    sendDataToServer(): void {
        if (this.isFormValid) {
            this.$router.push({ path: '/reset-password' });
            this.resetData = AuthWebApi.resetSendEmail({
                [EMAIL_NAME]: this[EMAIL_NAME],
            }).then(response => {
                if (response.status === 201) {
                    this.setUser(response.data.user);
                    this.goToProfilePage();
                }
            }).catch(({ response }) => {
                if (response.status === 401) {
                    this.globalServerError = GLOBAL_AUTH_ERROR;
                } else {
                    this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
                }
            });
        }
    }

    @Watch(`${EMAIL_NAME}`)
    onSearchTextChange() {
        if (this.globalServerError) {
            this.globalServerError = '';
        }
    }
}
</script>
