<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <div class='b-login-choose-site-lang h-width-300'>
        <ChooseSiteLang></ChooseSiteLang>
    </div>
    <FwPopup
        :centered='true'
        :isFixed='true'
        :max-width='isDesktop() ? "450px" : "calc(100% - 20px)"'
        width='90%'
        :padding='isMobile() ? "20px 30px" : "50px"'
        popupClass='b-popup-style'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'>
        <template #header>
            <router-link to='/login-company'>
                <FwIcon icon='arrow-left'
                        style='top: 50px; left: 50px'
                        size='14'
                        class='h-pos-abs'
                        color='#27dbbd'>
                </FwIcon>
            </router-link>
            <div class='h-text-center h-mb-20'>
                <div class='b-base-title h-mt-10'>
                    {{ $t('LOGIN.TXT') }}
                </div>
            </div>
        </template>
        <template #body>
            <form class='h-mt-15'
                  @keyup.enter='sendDataToServer'>
                <FwFormInput
                    class='h-mb-30'
                    :fieldHasError='fieldHasError(EMAIL_NAME)'
                    labelType='label-thin'
                    :title='$t(`LABEL.EMAIL`)'>
                    <FwInput
                        class='qa-user-email-input'
                        :value='email'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        :placeholder='$t(`LOGIN.PLACEHOLDER.EMAIL`)'
                        :error='getFieldErrorText(EMAIL_NAME)'
                        @update:value='value => email = value'
                        @input='clearServerErrorsBase(EMAIL_NAME)'
                        @blur='addCurrentInputToValidateArray(EMAIL_NAME)'>
                    </FwInput>
                </FwFormInput>
                <div v-if='false' class='b-login-page__forget-password__wrapper'>
                    <router-link
                        to='/reset-password-email'
                        class='b-login-page__forget-password'>
                        <p class='h-fw-500 h-text-center h-mv-0'>
                            {{ $t('LOGIN.FORGET.PASSWORD') }}
                        </p>
                    </router-link>
                </div>
                <FwFormInput
                    class='h-mb-15'
                    :fieldHasError='fieldHasError(PASSWORD_NAME)'
                    labelType='label-thin'
                    :title='$t(`LOGIN.PASSWORD.LABEL`)'>
                    <FwInput
                        class='qa-user-password-input'
                        :value='password'
                        type='password'
                        name='password'
                        autocomplete='current-password'
                        :placeholder='$t(`LOGIN.PASSWORD.PLACEHOLDER`)'
                        inputStyle='white-bigger-shadow'
                        :error='getFieldErrorText(PASSWORD_NAME)'
                        @update:value='value => password = value'
                        @input='clearServerErrorsBase(PASSWORD_NAME)'
                        @blur='addCurrentInputToValidateArray(PASSWORD_NAME)'>
                    </FwInput>
                </FwFormInput>

                <div v-if='globalServerError'
                     class='b-text-error'>
                    {{ globalServerError }}
                </div>
            </form>
        </template>

        <template #footer>
            <a
                v-if='isSsoShown'
                class='b-login-sso--button'
                :href='accountSsoUrl'>
                <div class='h-mv-10 h-pointer h-fw-500 h-text-center'>
                    {{ $t('LOGIN.WITH.SSO') }}
                </div>
            </a>
            <GoogleSignIn
                v-if='isHesAccount'
                :account='companyName.toLowerCase()'></GoogleSignIn>
            <div class='h-flex h-flex-center h-width-100p h-mt-10'>
                <FwButton
                    class='qa-base-login-submit'
                    :disabled='!isFormValid || isRequestSending'
                    borderRadiusType='small-border'
                    @click='sendDataToServer'>
                    {{ $t('LOGIN.TXT') }}
                </FwButton>
            </div>
            <router-link
                v-if='false'
                to='login-with-microsoft'>
                <div class='h-mt-20 h-pointer h-fw-500'>
                    {{ $t('LOGIN.WITH.AZURE') }}
                </div>
            </router-link>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { ChooseSiteLang } from '@/components/global/ChooseSiteLang';
import { GoogleSignIn } from '@/components/common/GoogleSignIn';

import { AuthWebApi } from '@/api/auth/AuthApi';

import ValidationMixin from '@/mixins/validation';
import RedirectMixin from '@/mixins/redirect';
import AuthMixin from '@/mixins/auth';

const EMAIL_NAME = 'email';
const PASSWORD_NAME = 'password';

@Component({
    components: {
        ChooseSiteLang,
        GoogleSignIn,
    },
    validators: {
        [EMAIL_NAME](value: string) {
            return this.emailValidator({ value });
        },
        [PASSWORD_NAME](value: string) {
            return this.baseInputValidator({ value, minLength: 6 });
        },
    },
})
export default class LoginPage extends Mixins(ValidationMixin, RedirectMixin, AuthMixin) {
    @State(state => state.UserStore.companyName) companyName!: string;
    [EMAIL_NAME]: string = '';
    [PASSWORD_NAME]: string = '';
    EMAIL_NAME: string = EMAIL_NAME;
    PASSWORD_NAME: string = PASSWORD_NAME;
    isRequestSending: boolean = false;
    globalServerError: string = '';

    serverErrors: serverErrors = {
        [EMAIL_NAME]: null,
        [PASSWORD_NAME]: null,
    };

    get isSsoShown() {
        return this.companyName.toLowerCase() === 'matmut' || this.isCompanyMnh;
    }

    get isHesAccount() {
        return this.companyName.toLowerCase() === 'hes';
    }

    get isCompanyMnh() {
        return this.companyName.toLowerCase() === 'mnh';
    }

    get ssoUrl() {
        return process.env.NODE_ENV === 'production' ?
            'https://backend.calizy.com' :
            'https://backend.staging.calizy.com';
    }

    get accountSsoUrl() {
        if (this.isCompanyMnh) {
            return `${this.ssoUrl}/saml/login?a=${this.companyName.toLowerCase()}`;
        }

        return `${this.ssoUrl}/saml/login`;
    }

    async sendDataToServer() {
        if (this.isFormValid) {
            try {
                this.isRequestSending = true;
                const { data } = await AuthWebApi.saasAuthenticate({
                    [EMAIL_NAME]: this[EMAIL_NAME],
                    [PASSWORD_NAME]: this[PASSWORD_NAME],
                }, this.companyName.toLowerCase());
                this.isRequestSending = false;
                this.setUser(data.user);
                // this.analyticsEvents('user_login');
                this.goToPlanAppointment(data.user);
            } catch ({ response }) {
                this.isRequestSending = false;
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        }
    }
    @Watch(`${EMAIL_NAME}`)
    @Watch(`${PASSWORD_NAME}`)
    onSearchTextChange() {
        if (this.globalServerError) {
            this.globalServerError = '';
        }
    }

    created() {
        if (!this.companyName) {
            this.$router.push({ path: '/' });
        }
    }
}
</script>

<style lang='sass'>
.b-login-choose-site-lang
    position: fixed
    right: 50px
    top: 50px
    z-index: 14
    @include media('<=phone')
        position: static
        margin: 30px 0

.b-login-sso--button
    &:hover
        text-decoration: underline

.b-login-page
    &__forget-password
        position: absolute
        right: 0
        height: 0
        color: #27DBBD
        &__wrapper
            position: relative
            width: 100%
            display: flex
            align-items: center
            justify-content: flex-end
            height: 0
</style>
