export default {
    'CANCEL.REASON.HEADER': 'Veuillez préciser le motif de l\'annulation',
    'CANCEL.ORIGIN.LABEL': 'Origine de l\'annulation',
    'CANCEL.REASON.LABEL': 'Motif de l\'annulation',
    'CANCEL.PERSON.PLACEHOLDER': 'Sélectionner une personne',
    'CANCEL.REASON.PLACEHOLDER': 'Sélectionner une origine',
    'CANCEL.BUTTON.APPOINTMENT': 'Annuler le RDV',
    'CANCEL.REASON.APPOINTMENT': 'L\'intervention a bien été annulée',
    'CANCEL.GO.HOME.BUTTON': 'Planifier une intervention',
    'CANCEL.REASON.EMERGENCY': 'Urgence société / force majeure',
    'CANCEL.REASON.SUITABILITY': 'Convenance personnelle',
    'CANCEL.REASON.COMPLIANCE': 'Conformité site',
    'CANCEL.REASON.WORKER.MISTAKE': 'Erreur planification société',
    'CANCEL.REASON.WORKER.SUITABILITY': 'Convenance société',
    'CANCEL.REASON.DELAY': 'Retard',
    'CANCEL.REASON.STOCK.ISSUE.DELAY': 'Problème de stock (réception retardée)',
    'CANCEL.REASON.STOCK': 'Stock (injustifié)',
    'CANCEL.REASON.GOOD.AGREEMENT': 'Bon pour accord KO',
    'CANCEL.REASON.BAD.APPRECIATION': 'Mauvaise appréciation distance',
    'CANCEL.REASON.REQUEST.MANAGER': 'A la demande des réseaux',
    'CANCEL.REASON.BAD.WEATHER': 'Intempéries/Evènement spécial',
    'CANCEL.REASON.OTHER.URGENT': 'Autre intervention plus urgente',
    'CANCEL.REASON.PLAN.ERROR': 'Erreur planification',
    'CANCEL.REASON.BAD.COMPLIANCE': 'Mauvaise conformité',
    'CANCEL.REASON.DELAYED': 'Réception retardée',

    'CANCEL.REASON.INTERNAL.NOT.AVAILABLE': 'Plus disponible',
    'CANCEL.REASON.INTERNAL.OTHER': 'Autre',
    'CANCEL.REASON.INTERNAL.BOOK.MISTAKE': 'Plus de besoin',
    'CANCEL.REASON.INTERNAL.NO.ANYMORE': 'Plus de besoin',
    'CANCEL.REASON.INTERNAL.NECESSARY.ANYMORE': 'Plus de besoin',
    'CANCEL.REASON.INTERNAL.STOCK': 'Problème de stock ou de matériel',
    'CANCEL.REASON.INTERNAL.TECH.ISSUE': 'Problème technique',

    'CANCEL.REASON.EXTERNAL.COMPANY.EMERGENCY': 'Urgence société',
    'CANCEL.REASON.EXTERNAL.DELAY': 'Retard',
    'CANCEL.REASON.EXTERNAL.MATERIAL.ISSUE': 'Problème de matériel',

    'CANCEL.ORIGIN.COMPANY.INTERNAL': 'Interne',
    'CANCEL.ORIGIN.COMPANY.EXTERNAL': 'Externe : client ou prospect',
    'CANCEL.ORIGIN.PARTNER.CUSTOMER': 'Client',
    'CANCEL.ORIGIN.PARTNER.BUSINESS': 'Business Partner Company',
    'CANCEL.ORIGIN.WORKER': 'Installateur',
    'CANCEL.ORIGIN.IMA': 'IMA Protect',
    'CANCEL.ORIGIN.MANAGER': 'Managers',
    'CANCEL.ORIGIN.STOCK': 'Stock',

    'CANCEL.ORIGIN.NISSAN.INTERNAL': 'Interne (IMA)',
    'CANCEL.ORIGIN.NISSAN.EXTERNAL': 'Externe (NISSAN)',
};
