<template>
<div class='b-vertical-user-list'>
    <div class='b-vertical-user-list__inner h-pv-10'>
        <div v-for='(user, index) in users'
             :key='index'
             class='b-vertical-user-list__item'
             @click='toggleUserActiveHandler(user)'>
            <div :style='{ "backgroundColor": userColor(user) }'
                 class='b-vertical-user-list__item__avatar'>
                <WorkerAvatar
                    v-if='user.isActive'
                    class='h-mv-15'
                    :avatarSize='22'
                    :hideInfo='true'
                    :worker='user'
                    size='small'
                    align='small'>
                </WorkerAvatar>
                <div v-else
                     class='b-vertical-user-list__item__avatar__inner'>
                </div>
            </div>

            <div v-if='user.first_name'
                 class='b-vertical-user-list__item__text'>
                {{ user.first_name | capitalizeAll }}
            </div>

            <div v-if='user.last_name' class='b-vertical-user-list__item__text'>
                {{ user.last_name | capitalizeAll }}
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { hexToRgbA } from '@/helpers/colors';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { CalendarViewSettingsUsertype } from '@/types/Calendar';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class VerticalUserList extends Vue {
    @Prop({ type: Array, required: true }) readonly users!: Array<CalendarViewSettingsUsertype>;

    userColor(user: CalendarViewSettingsUsertype): string {
        if (!user.color) {
            return '#27DBBD';
        }
        try {
            const color = hexToRgbA(user.color.split(' - ')[0] || user.color, 0.8);
            return color;
        } catch (e) {
            console.log(e);
        }
        return user.color || '#27DBBD';
    }

    @Emit('toggleUserActive')
    toggleUserActiveHandler(user :CalendarViewSettingsUsertype): CalendarViewSettingsUsertype {
        return user;
    }
}
</script>

<style lang='sass'>
.b-vertical-user-list
    display: block
    margin-top: 15px

    &__item
        display: flex
        align-items: center
        margin-bottom: 8px
        cursor: pointer

        &:hover
            .b-vertical-user-list__item__text
                text-decoration: underline

        &__text
            font-size: 14px
            font-weight: 500

            &:not(:first-of-type)
                margin-left: 5px

        &__avatar
            width: 22px
            height: 22px
            border-radius: 50%
            display: flex
            align-items: center
            justify-content: center
            margin-right: 7px

            &__inner
                width: 18px
                height: 18px
                background: #F6F8F9
                border-radius: 50%
</style>
