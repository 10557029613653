export default {
    // hardcoded translations for statistics back end keys
    'total': 'Total',
    'branch': 'Par cette agence',
    'outside': 'Par des utilisateurs extérieurs à cette agence',
    'client_or_prospect': 'Externe : client ou prospect',
    'internal': 'Interne',

    'hub_branch': 'Par ce hub',
    'hub_outside': 'Par des utilisateurs en dehors de ce hub',

    'for_branch': 'Pour l\'agence',
    'for_outside': 'Pour des personnes extérieures à cette agence',

    'for_hub_branch': 'Pour le hub',
    'for_hub_outside': 'Pour des personnes extérieures à ce hub',
};
