<template>
<FwHorizontalScroll
    :isPageLoaded='true'
    slotWrapperClass='h-flex h-flex-dir-column h-flex-center'
    iconClass='h-opacity-1'
    newClass='fw-arrow-big-circle-fixed'
    :arrowBgWidth='null'
    colorIcon='#fff'
    btnLocation='between'>
    <template #content>
        <CalendarTable
            :titles='appointmentsCategoriesTitleArray'
            :data='appointmentCategories'
            :bigIndexes='bigIndexes'
            class='b-calendar__table__wrapper'>
            <div
                v-for='(item, i) in appointmentCategories'
                :key='i'
                class='b-calendar__table'>
                <div class='b-calendar__table__outer'>
                    <div class='b-calendar__table__item'
                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(0) }'>
                        <span v-if='item.kind && item.kind.length'
                              class='b-calendar__table__item__inner'>
                            {{ item.kind }}
                        </span>
                    </div>
                    <div class='b-calendar__table__item'
                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(1) }'>
                        <span v-if='item.title && item.title.length'
                              class='b-calendar__table__item__inner'>
                            {{ item.title }}
                        </span>
                    </div>
                    <div class='b-calendar__table__item'
                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(2) }'>
                        <span v-if='item.duration'
                              class='b-calendar__table__item__inner'>
                            {{ calcDuration(item.duration) }}
                        </span>
                    </div>
                    <div class='b-calendar__table__item'
                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(3) }'>
                        <span v-if='item.skills && item.skills.length'
                              class='b-calendar__table__item__inner'>
                            {{ prepareData(item.skills) }} ({{ item.skills.length }})
                        </span>
                    </div>
                    <div class='b-calendar__table__item'
                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(4) }'>
                        <span v-if='item.location_skills && item.location_skills.length'
                              class='b-calendar__table__item__inner'>
                            {{ prepareData(item.location_skills) }} ({{ item.location_skills.length }})
                        </span>
                    </div>
                </div>
                <CalendarTableButtons
                    :withArchiveLogic='withArchiveLogic'
                    :isArchive='isArchive'
                    @edit='editHandler(item)'
                    @archive='archiveHandler(item)'
                    @delete='deleteHandler(item)'>
                </CalendarTableButtons>
            </div>
        </CalendarTable>
    </template>
</FwHorizontalScroll>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { AppointmentCategoryType } from '@/types/Appointment';
import TranslateMixin from '@/mixins/TranslateMixin';
import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';

import { duration } from '@/helpers/duration';

@Component({
    components: {
        CalendarTableButtons,
        CalendarTable,
    },
})
export default class AppointmentCategoryData extends Mixins(TranslateMixin) {
    @Prop({ type: Array, required: true }) readonly appointmentsCategoriesTitleArray!: Array<string>;
    @Prop({ type: Array, required: true }) readonly appointmentCategories!: Array<AppointmentCategoryType>;
    @Prop({ type: Boolean, default: false }) readonly withArchiveLogic!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isArchive!: boolean;

    bigIndexes: Array<number> = [1, 3, 4];

    calcDuration(durationValue: number) {
        const time = duration.find(item => item.duration === durationValue);
        if (time) {
            return time.title;
        }
        const horse = Math.ceil(durationValue / 60);
        const horseStr = horse.toString().length === 1 ? `0${horse}` : `${horse}`;
        const minute = durationValue % 60;
        const minuteStr = minute.toString().length === 1 ? `0${minute}` : `${minute}`;
        return `${horseStr}h${minuteStr}`;
    }

    prepareData(data: any) {
        return data.slice(0, 2).map((item: any) => item[this.labelKey]).join(', ');
    }

    @Emit('edit')
    editHandler(appointment: AppointmentCategoryType) {}

    @Emit('delete')
    deleteHandler(appointment: AppointmentCategoryType) {}

    @Emit('archive')
    archiveHandler(appointment: AppointmentCategoryType) {}
}
</script>
