import { UserType } from '@/types/User';

function unavailabilityTitleTranslate(title: string): string {
    if (title === `other_company`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.OTHER_COMPANY`;
    } else if (title === `day_off`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.DAY_OFF`;
    } else if (title === `training`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.TRAINING`;
    } else if (title === `unavailable`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE`;
    }
    return title;
}

function getUserName(user: UserType, currentUserId: string): string {
    return user.id === currentUserId ? 'TEXT.YOU' : user.first_name;
}

function getErrorsTemplate(langName: 'fr' | 'en') {
    const errors: { en: any, fr: any } = {
        en: {
            error: 'Error.',
            required: `This field is required`,
            float: 'Must be a number.',
            integer: 'Must be an integer.',
            number: 'Must be a number.',
            lessThan: 'Must be less than {0}.',
            lessThanOrEqualTo: 'Must be less than or equal to {0}.',
            greaterThan: 'Must be greater than {0}.',
            greaterThanOrEqualTo: 'Must greater than or equal to {0}.',
            between: 'Must be between {0} and {1}.',
            size: 'Size must be {0}.',
            length: 'Length must be {0}.',
            minLength: 'Must have at least {0} characters.',
            maxLength: 'Must have up to {0} characters.',
            lengthBetween: 'Length must between {0} and {1}.',
            in: 'Must be {0}.',
            notIn: 'Must not be {0}.',
            match: 'Not matched.',
            regex: 'Invalid format.',
            digit: 'Must be a digit.',
            email: `Invalid email`,
            url: 'Invalid url.',
            optionCombiner(options: Array<string>) {
                if (options.length > 2) {
                    options = [options.slice(0, options.length - 1).join(', '), options[options.length - 1]];
                }
                return options.join(' or ');
            },
        },
        fr: {
            error: 'Erreur.',
            required: `Ce champ est obligatoire`,
            float: 'Doit être un chiffre.',
            integer: 'Doit être un entier.',
            number: 'Doit être un chiffre.',
            lessThan: 'Doit être inférieur à {0}.',
            lessThanOrEqualTo: 'Doit être inférieur ou égal à {0}.',
            greaterThan: 'Doit être supérieur à {0}.',
            greaterThanOrEqualTo: 'Doit être supérieur ou égal à {0}.',
            between: 'Doit être entre {0} et {1}.',
            size: 'Taille doit être de {0}.',
            length: 'La longueur doit être de {0}.',
            minLength: 'Le mot de passe doit contenir au moins {0} caractères.',
            maxLength: 'Ne doit pas dépasser {0} caractères.',
            lengthBetween: 'La longueur doit être entre {0} et {1}.',
            in: 'Doit être {0}.',
            notIn: 'Ne doit pas être {0}.',
            match: 'Ne correspond pas.',
            regex: 'Format invalide.',
            digit: 'Doit être un nombre.',
            email: `Email invalide`,
            url: 'Url invalide.',
            optionCombiner(options: Array<string>) {
                if (options.length > 2) {
                    options = [options.slice(0, options.length - 1).join(', '), options[options.length - 1]];
                }
                return options.join(' ‘ou’ ');
            },
        },
    };
    return errors[langName];
}

export {
    unavailabilityTitleTranslate,
    getUserName,
    getErrorsTemplate,
};
