<template>
<div :key='progressBarKey'
     class='b-appointment-bar'>
    <template v-if='!isRecapRoute'>
        <div class='b-appointment-bar__progress'>
            <div>
                {{ activeIndex + 1 }}/{{ currentFlow.length }}
            </div>
            <div class='b-appointment-bar__progress__line'>
                <div class='b-appointment-bar__progress__line__active'
                     :style='{ width: `${(activeIndex + 1) / currentFlow.length * 100}%` }'></div>
            </div>
        </div>
        <div class='h-flex h-flex-center'>
            <ul v-if='!isMobile()'
                class='b-appointment-bar__breadcrumbs h-p-0'>
                <li v-for='(breadcrumb, index) in currentFlow'
                    class='b-appointment-bar__breadcrumb h-font-14'
                    :class='{ "b-appointment-bar__breadcrumb--active": isActive(index) }'>
                    {{ $t(breadcrumb) }}
                </li>
            </ul>
        </div>
        <div class='b-appointment-bar__buttons'>
            <portal-target name='appointment-bar-buttons' slim></portal-target>
        </div>
    </template>
    <div v-else class='b-appointment-bar__buttons h-width-100p'>
        <portal-target name='appointment-bar-buttons' slim></portal-target>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { State, namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';

const BreadCrumbsStore = namespace('BreadCrumbsStore');

@Component
export default class ProgressBarWrapper extends Vue {
    @State(state => state.CommonStore.progressBarKey) progressBarKey!: number;
    @BreadCrumbsStore.Getter('currentFlow') currentFlow!: Array<string>;

    get activeIndex(): number {
        return this.currentFlow.findIndex((text: string) => this.$route.meta ? text === this.$route.meta.breadCrumbsText : false);
    }

    isActive(index: number): boolean {
        return this.activeIndex >= index;
    }
}
</script>

<style lang='sass'>
.b-appointment-bar
    position: fixed
    bottom: 0
    left: 0
    height: 60px
    width: 100%
    box-shadow: rgba(33,63,107,0.1)
    background-color: #FFFFFF
    display: flex
    align-items: center
    padding: 0 80px
    justify-content: space-between
    z-index: 10

    @include media('<=phone')
        padding: 15px 10px
        height: auto

    &__progress
        font-size: 14px
        color: #27DBBD
        font-weight: bold
        display: flex
        align-items: center

        &__line
            width: 115px
            height: 6px
            margin-left: 15px
            border-radius: 3px
            background-color: #F0F2F5
            position: relative

            &__active
                height: 6px
                position: absolute
                left: 0
                top: 0
                border-radius: 3px
                background-color: #27DBBD
                transition: width 0.4s

    &__buttons, &__progress
        width: 200px

    &__buttons
        display: flex
        justify-content: flex-end

    &__breadcrumbs
        display: flex
        font-weight: 700

        @include media('<=phone')
            display: none

    &__breadcrumb
        opacity: 0.3
        margin: 0 5px
        &:not(:first-of-type):before
            content: '>'
            margin-right: 5px

        &--active
            transition: opacity 0.3s ease-in-out
            opacity: 1
            font-weight: bold
            color: #213F6B
</style>
