<template>
<div class='b-calendar-body__cell'
     :class='[generateClass, { "b-calendar-body__cell--inactive": inactive }]'>
    <div v-if='isColorType'
         class='b-calendar-body__cell__square'>
        <div class='b-calendar-body__cell__square__sub' :style='getStyles'>
            <template v-if='isMultipleType'>
                <div v-for='item in 3'
                     :key='item'></div>
            </template>
            <template v-else-if='value !== null'>
                {{ value }}
            </template>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WorkerAvatar } from '@/components/simple/WorkerAvatar';
import { SQUARE_MAPPING, getColorType, MULTIPLY_TYPE } from '@/components/calendar/helpers';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class CalendarCell extends Vue {
    @Prop({ type: String, required: true }) readonly type!: string;
    @Prop({ type: Boolean, default: false }) readonly inactive!: boolean;
    @Prop({ type: Number, default: null }) readonly value!: number;
    @Prop({ type: Boolean, required: true }) readonly isColorblind!: boolean;

    get generateClass(): string {
        return (SQUARE_MAPPING as any)[this.type];
    }

    get getStyles(): { [key: string]: string } {
        return { backgroundColor: (getColorType(this.isColorblind) as any)[this.type] };
    }

    get isMultipleType(): boolean {
        return this.type === MULTIPLY_TYPE;
    }

    get isColorType(): boolean {
        return Object.keys(getColorType(this.isColorblind)).includes(this.type);
    }
}
</script>

<style lang='sass'>
.b-calendar-body
    width: 100%

    &__cell
        flex: 1 0
        display: flex
        align-items: center
        justify-content: center
        border-right: 1px solid #E8ECF0
        font-size: 11px
        line-height: 0.75em
        font-weight: 500
        cursor: pointer

        &--count
            .b-calendar-body__cell__square__sub
                transition: transform 0.15s !important
                width: calc(100% - 4px) !important
                border-radius: 5px

        &--inactive
            cursor: default

            .b-calendar-body__cell__square__sub
                transform: scale(1) !important

        &--big
            width: 52px
            flex: 0 0 52px

            .b-calendar-body__cell__square__sub
                font-size: 10px

        &--active
            background-color: #203f6a
            border-radius: 8px
            color: #fff

            .b-calendar-body__cell__square__sub
                background-color: transparent !important

        &--empty:hover
            .b-calendar-body__cell__square__sub
                background-color: #ece9e9 !important

        &__square
            width: 100%
            height: 100%
            display: flex
            align-items: center
            justify-content: center

            &:hover
                .b-calendar-body__cell__square__sub
                    transform: scale(1.1)

            &__sub
                width: calc(100% - 6px)
                height: calc(100% - 6px)
                display: flex
                align-items: center
                justify-content: center
                will-change: transform
                transition: transform 0.15s, background 0.3s

                div
                    background-color: #8495AC
                    width: 4px
                    height: 4px
                    border-radius: 50%
                    margin: 0 1px
</style>
