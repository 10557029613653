import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { WorkerType } from '@/types/Workers';
import { UserType } from '@/types/User';

@Module({
    namespaced: true,
})
export class UserStore extends VuexModule {
    users: Array<WorkerType> = [];
    confirmUserData: Array<UserType> = [];
    companyName: string = '';

    @Mutation
    USERS_DATA(data: any) {
        this.users = data;
    }
    @Mutation
    CONFIRM_USER_DATA(data: Array<UserType>) {
        this.confirmUserData = data;
    }
    @Mutation
    COMPANY_NAME(data: string) {
        this.companyName = data;
    }
}

export default UserStore;
