export default {
    'ADD.RESERVED.SLOT.POPUP.DELETE.BASE': 'Oui, supprimer',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ALL': 'Supprimer toutes les indisponibilités',
    'ADD.RESERVED.SLOT.POPUP.DELETE.AVAILABILITIES.ALL': 'Supprimer toutes les disponibilités',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ONLY_THIS': 'Supprimer cette indisponibilité',
    'ADD.RESERVED.SLOT.POPUP.DELETE.AVAILABILITY.ONLY_THIS': 'Supprimer cette disponibilité',
    'ADD.RESERVED.SLOT.POPUP.DELETE.CANCEL': 'ANNULER',

    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ONLY_THIS': 'Supprimer ce créneau réservé',
    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ALL': 'Supprimer tous les créneaux réservés',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ALL.FUTURE': 'Supprimer toutes les futures indisponibilités',
    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ALL.FUTURE': 'Supprimer tous les futurs créneaux réservés',

    'ADD.RESERVED.SLOT.POPUP.DELETE.TITLE': 'Supprimer l\'indisponibilité',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE': 'Voulez-vous vraiment supprimer cette indisponibilité?',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE_RECCURENCE': 'Voulez-vraiment supprimer cette indisponibilité récurrente ?',

    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.TITLE': 'Supprimer le créneau réservé',
    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ASIDE': 'Voulez-vous vraiment supprimer ce créneau réservé?',
    'ADD.RESERVED.SLOT.POPUP.IMA.PROTECT.DELETE.ASIDE_RECCURENCE': `Voulez-vous vraiment supprimer cet emplacement 
        réservé? Ceci est un emplacement réservé récurrent`,

    'ADD.AVAILABILITY.SLOT.POPUP.DELETE.TITLE': 'Supprimer disponibilité',
    'ADD.AVAILABILITY.SLOT.POPUP.DELETE.ASIDE': 'Voulez-vous vraiment supprimer cette disponibilité?',
};
