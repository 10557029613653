<template>
<div class='b-add-appointment__wrapper container b-add-appointment__wrapper--margin'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $route.params.id ? $t('PLAN.APPOINTMENT.EDIT.HEADER') : $t('PLAN.APPOINTMENT.ADD.HEADER') }}
            </h2>
        </div>
        <AddAppointmentTypeForm
            v-if='!loading'
            :startAppointmentCategoryData='startAppointmentCategoryData'
            @changeDisabledFormButton='value => disabledFormButton = value'
            @emitAddAppointmentData='payload => payloadData = payload'>
        </AddAppointmentTypeForm>
        <portal to='appointment-bar-buttons'>
            <FwButton
                size='little'
                :disabled='isDisabled'
                borderRadiusType='small-border'
                @click.native='addAppointmentData'>
                {{ $route.params.id ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
            </FwButton>
        </portal>
    </template>
</div>
</template>

<script lang='ts'>
import { namespace, State } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import {
    CategoryLocationSkillNameType,
    CategorySkillNameType,
    CreateAppointmentCategoryPayloadType,
    StartAppointmentCategoryDataType,
} from '@/types/Appointment';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import DateMixin from '@/mixins/dateMixin';
import { AddAppointmentTypeForm } from '@/components/forms/add/AddAppointmentTypeForm';
import { duration } from '@/helpers/duration';

const AppointmentStore = namespace('AppointmentStore');
const OnboardingStore = namespace('OnboardingStore');

@Component({
    components: {
        AddAppointmentTypeForm,
    },
})
export default class AppointmentAdd extends Mixins(ValidationMixin, DateMixin) {
    @State(state => state.OnboardingStore.categoryLocationSkillNames) categoryLocationSkillNames!: Array<CategoryLocationSkillNameType>;
    @State(state => state.OnboardingStore.skills) skills!: Array<CategorySkillNameType>;
    @OnboardingStore.Action('fetchCategoryLocationSkillNames') fetchCategoryLocationSkillNames!: () => Promise<void>;
    @OnboardingStore.Action('fetchSkills') fetchSkills!: () => Promise<void>;

    checkboxLocation: Array<CategoryLocationSkillNameType> = [];
    duration: Array<{ duration: number, title: string }> = duration;
    sendRequest: boolean = false;
    loading: boolean = false;
    disabledFormButton: boolean = true;
    payloadData: CreateAppointmentCategoryPayloadType | null = null;
    startAppointmentCategoryData: null | StartAppointmentCategoryDataType = null;

    get isDisabled(): boolean {
        return this.disabledFormButton || this.sendRequest;
    }

    async addAppointmentData() {
        if (this.isDisabled || !this.payloadData) {
            return;
        }
        this.sendRequest = true;
        try {
            if (this.$route.params.id) {
                await OnboardingApi.editOnboardingAppointmentCategory(this.payloadData, this.$route.params.id);
            } else {
                await OnboardingApi.createOnboardingAppointmentCategory(this.payloadData);
            }
            if (this.isRecapRoute) {
                this.$router.push({ path: `${this.mainRouteGlobal}/recap/appointment/categories` });
            } else {
                this.$router.push({ path: `${this.mainRouteGlobal}/appointment/categories` });
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.sendRequest = false;
        }
    }

    async created() {
        this.loading = true;
        if (!this.categoryLocationSkillNames.length) {
            await this.fetchCategoryLocationSkillNames();
        }
        if (this.$route.params.id) {
            const { appointment_category } = await OnboardingApi.getOnboardingAppointmentCategory(this.$route.params.id);
            if (appointment_category) {
                const startAppointmentCategoryData: StartAppointmentCategoryDataType = {
                    appointmentCategory: appointment_category.kind,
                    timeFrom: this.duration.find(item => appointment_category.duration === item.duration)!,
                    title: appointment_category.title,
                    skillNeededActive: false,
                    skillChoosed: [],
                    subAccountsChoosed: [],
                    checkboxLocation: [],
                    id: appointment_category.id,
                };
                if (appointment_category.skills.length) {
                    if (!this.skills.length) {
                        await this.fetchSkills();
                    }
                    startAppointmentCategoryData.skillNeededActive = true;
                    startAppointmentCategoryData.skillChoosed = this.skills.filter(item => appointment_category.skills.map(skill => skill.id).includes(item.id));
                }
                if (appointment_category.location_skills.length) {
                    const locationIds = appointment_category.location_skills.map(item => item.id);
                    startAppointmentCategoryData.checkboxLocation = this.categoryLocationSkillNames.filter(item => locationIds.includes(item.id));
                }
                this.startAppointmentCategoryData = startAppointmentCategoryData;
            }
        }
        this.loading = false;
    }
}
</script>

<style lang='sass'>
.b-add-appointment__wrapper
    width: 1100px
    max-width: 90%

.h-flex-1-0
    flex: 1 0
</style>
