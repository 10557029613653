<template>
<div class='b-settings-calendar-view b-settings-calendar-view--max-width'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-settings__title'>
            {{ $t('SETTINGS.MENU.CALENDAR.VIEW') }}
            <FwButton
                v-if='canEdit'
                class='qa-calendar-view-edit-button'
                borderRadiusType='small-border'
                size='little'
                :disabled='disabled'
                @click='sendSettingTimeSlots'>
                {{ $t('BUTTON.SAVE') }}
            </FwButton>
        </div>

        <div class='b-settings-calendar-view__item h-mt-20'>
            <div class='b-settings-calendar-view__title'>
                {{ $t('LABEL.HOURS') }}
            </div>
            <div class='b-settings-calendar-view__inner'>
                <div class='h-flex h-mr-20'>
                    <div class='b-calendar-select__week
                                fw-select-base
                                fw-select-base__time
                                h-mt-30
                                h-flex h-flex-center
                                h-flex-space-between'>
                        <div class='b-settings-calendar-view__text'>
                            {{ $t('SETTINGS.CALENDAR.FROM') }}
                        </div>
                        <FwSelect
                            v-model='timeFrom'
                            :disabled='!canEdit'
                            class='b-settings-calendar-view__select'
                            :canNotDelete='true'
                            :propsPlaceholder='value'
                            :searchable='false'
                            type='white'
                            :withCircles='true'
                            :options='timeFromArray'>
                        </FwSelect>
                    </div>
                </div>
                <div class='h-flex'>
                    <div class='b-calendar-select__week
                                fw-select-base
                                fw-select-base__time
                                h-mt-15
                                h-flex h-flex-center
                                h-flex-space-between'>
                        <div class='b-settings-calendar-view__text'>
                            {{ $t('SETTINGS.CALENDAR.TO') }}
                        </div>
                        <FwSelect
                            v-model='timeTo'
                            :disabled='!canEdit'
                            class='b-settings-calendar-view__select'
                            :canNotDelete='true'
                            :propsPlaceholder='value'
                            :searchable='false'
                            type='white'
                            :withCircles='true'
                            :options='timeToArray'>
                        </FwSelect>
                    </div>
                </div>
            </div>
        </div>

        <div class='b-settings-calendar-view__item h-mt-20'>
            <div class='b-settings-calendar-view__title'>
                {{ $t('SETTINGS.CALENDAR.DAYS.TITLE') }}
            </div>
            <div class='b-settings-calendar-view__radio__wrapper'>
                <div v-for='(time, index) in week_days'
                     :key='index'
                     class='b-settings-calendar-view__radio'>
                    <FwCheckbox
                        :id='$t(week_days)'
                        v-model='dayList'
                        :disabled='!canEdit'
                        :value='index'
                        type='with-text'
                        :text='$t(time)'
                        class='qa-calendar-view-checkbox b-settings-calendar-view__radio__item'
                        name='checkbox'>
                        <div>
                            {{ timeText(time) }}
                        </div>
                    </FwCheckbox>
                </div>
            </div>
        </div>
        <div v-if='isDayListEmpty'
             class='b-settings-calendar-view__hint'>
            {{ $t('SETTINGS.CALENDAR.DAY.LIST.EMPTY') }}
        </div>
    </template>
    <FinalePopup
        v-if='finalePopupValue'
        :subMessage='$t(`SETTINGS.CALENDAR.POPUP.CALENDAR.TXT`)'
        popupWidth='400px'
        qaClassName='qa-calendar-view-close-button'
        @close='finalePopupValue = false'>
    </FinalePopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import i18n from '@/locale';
import { WEEK_DAYS, timeConvertor, formatDate } from '@/helpers/dates';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { dayListMapping } from '@/helpers/settings';
import { CalendarViewSettingsType } from '@/types/Calendar';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { CalendarSettingsPayloadType, CalendarSettingsPayloadDaysType } from '@/types/Settings';
import { isEqual } from '@/helpers/base';
import PermissionsMixin from '@/mixins/permissions';

const CalendarStore = namespace('CalendarStore');

@Component({
    components: {
        FinalePopup,
    },
})
export default class SettingsCalendarViewPage extends Mixins(PermissionsMixin) {
    @CalendarStore.State('calendar_view_data') calendar_view_data!: CalendarViewSettingsType;
    @CalendarStore.Mutation('setCalendarViewData') setCalendarViewData!: (calendar_view_data: CalendarViewSettingsType) => void;

    value: string | number = i18n.tc('SETTINGS.CALENDAR.DAY.TITLE');
    timeFrom: string = '';
    timeTo: string = '';
    loading: boolean = false;
    isRequestSending: boolean = false;
    finalePopupValue: boolean = false;
    week_days: Array<string> = WEEK_DAYS;
    dayList: Array<number> = [];
    currentDayList: Array<number> = [];
    currentTimeFrom: string = '';
    currentTimeTo: string = '';

    timeFromArray: Array<string> = [
        i18n.tc('CALENDAR.TIME.12.AM'),
        i18n.tc('CALENDAR.TIME.01.AM'),
        i18n.tc('CALENDAR.TIME.02.AM'),
        i18n.tc('CALENDAR.TIME.03.AM'),
        i18n.tc('CALENDAR.TIME.04.AM'),
        i18n.tc('CALENDAR.TIME.05.AM'),
        i18n.tc('CALENDAR.TIME.06.AM'),
        i18n.tc('CALENDAR.TIME.07.AM'),
        i18n.tc('CALENDAR.TIME.08.AM'),
        i18n.tc('CALENDAR.TIME.09.AM'),
        i18n.tc('CALENDAR.TIME.10.AM'),
        i18n.tc('CALENDAR.TIME.11.AM'),
        i18n.tc('CALENDAR.TIME.12.PM'),
        i18n.tc('CALENDAR.TIME.01.PM'),
    ]

    timeToArray: Array<string> = [
        i18n.tc('CALENDAR.TIME.02.PM'),
        i18n.tc('CALENDAR.TIME.03.PM'),
        i18n.tc('CALENDAR.TIME.04.PM'),
        i18n.tc('CALENDAR.TIME.05.PM'),
        i18n.tc('CALENDAR.TIME.06.PM'),
        i18n.tc('CALENDAR.TIME.07.PM'),
        i18n.tc('CALENDAR.TIME.08.PM'),
        i18n.tc('CALENDAR.TIME.09.PM'),
        i18n.tc('CALENDAR.TIME.10.PM'),
        i18n.tc('CALENDAR.TIME.11.PM'),
    ];

    get isEqual(): boolean {
        return (
            this.currentTimeFrom === this.timeFrom &&
            this.currentTimeTo === this.timeTo &&
            isEqual(this.currentDayList.sort((a, b) => b - a), this.dayList.sort((a, b) => b - a))
        );
    }

    get isDayListEmpty(): boolean {
        return this.dayList.length === 0;
    }

    get disabled(): boolean {
        return this.isRequestSending || this.isEqual || this.isDayListEmpty;
    }

    timeText(text: string) {
        return `${i18n.tc(text).substr(0, 3).toUpperCase()}.`;
    }

    async getCalendarView() {
        this.loading = true;
        try {
            const data = await SettingsWebApi.getCalendarViewData();
            this.setCalendarViewData(data.calendar_view_settings[0]);
            this.updateCalendarViewTime();
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    updateCalendarViewTime() {
        const hoursEnd = formatDate(this.calendar_view_data.monday_time_end).substring(0, 2);
        const hoursStart = formatDate(this.calendar_view_data.monday_time_start).substring(0, 2);
        const hoursFormatEnd = formatDate(this.calendar_view_data.monday_time_end).substring(2, 4);
        const hoursFormatStart = formatDate(this.calendar_view_data.monday_time_start).substring(2, 4);
        this.timeTo = i18n.tc(`CALENDAR.TIME.${hoursEnd}.${hoursFormatEnd}`);
        this.timeFrom = i18n.tc(`CALENDAR.TIME.${hoursStart}.${hoursFormatStart}`);
        // @ts-ignore
        this.dayList = dayListMapping.map((day: string, index: number) => (this.calendar_view_data[day] as boolean) ? index : null).filter(item => item !== null);
        // @ts-ignore
        this.currentDayList = JSON.parse(JSON.stringify(this.dayList));
        this.currentTimeTo = this.timeTo;
        this.currentTimeFrom = this.timeFrom;
    }

    async sendSettingTimeSlots() {
        if (this.disabled) return;
        this.isRequestSending = true;
        const from = timeConvertor(this.timeFrom);
        const to = timeConvertor(this.timeTo);
        // @ts-ignore-next-line
        const calendarsDates: CalendarSettingsPayloadDaysType = {};
        for (let i = 0; i < dayListMapping.length; i++) {
            // @ts-ignore-next-line
            calendarsDates[dayListMapping[i]] = this.dayList.includes(i);
        }
        const payload: CalendarSettingsPayloadType = {
            timeFrom: from,
            timeTo: to,
            calendar_view_setting_id: this.calendar_view_data.id,
            ...calendarsDates,
        };
        try {
            const { data } = await SettingsWebApi.sentCalendarSettingsSlot(payload);
            this.finalePopupValue = true;
            this.setCalendarViewData(data.calendar_view_setting);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    @Watch('calendar_view_data')
    calendarViewDataHandler() {
        this.updateCalendarViewTime();
    }

    created() {
        this.getCalendarView();
    }
};
</script>

<style lang='sass'>
.b-settings-calendar-view
    &--max-width
        max-width: 800px

    &__item
        display: flex
        flex-direction: column

    &__title
        font-size: 18px
        font-weight: 600
        color: #213F6B

    &__inner
        display: flex
        align-items: baseline

    &__text
        color: #213F6B
        opacity: 0.4
        font-size: 14px
        font-weight: 600
        margin-right: 20px
        text-transform: uppercase

    &__select
        width: 140px
        .multiselect__tags, .fw-select-white
            height: auto !important

    &__hint
        font-size: 16px
        color: #FF004D

    &__radio
        margin: 0 10px
        flex: 1 0
        background-color: #fff
        display: flex
        align-items: center
        justify-content: center
        position: relative
        box-shadow: $big-box-shadow
        border-radius: 10px
        min-width: 80px

        .fw-check-box__wrapper
            margin-bottom: 0

        .fw-check-box__label
            margin-top: 8px !important

        &:after
            content: ''
            display: block
            padding-top: 100%

        &__item
            position: absolute

        &__wrapper
            margin: 20px -10px
            display: flex
</style>
