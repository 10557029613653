const EN_LOCAL: string = `en`;
const FR_LOCAL: string = `fr`;

const AVAILABLE_LOCALS: Array<string> = [EN_LOCAL, FR_LOCAL];

export {
    EN_LOCAL,
    FR_LOCAL,
    AVAILABLE_LOCALS,
};
