<template>
<div ref='wrapper' class='b-access-lock-page__wrapper h-flex h-flex-center'>
    <FwButton size='medium' borderRadiusType='tiny-border' @click='mailTo()'>
        <FwIcon
            class='h-mr-10'
            icon='lock-blue'
            size='15'
            color='#ffffff'>
        </FwIcon>
        {{ $t("BUTTON.UNLOCK") }}
    </FwButton>
</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AccessLockPage extends Vue {
    private targetDiv: HTMLElement | null = null;
    private targetParent: HTMLElement | null = null;
    private observer: MutationObserver | null = null;

    mounted() {
        // Select the div element to be monitored
        this.targetDiv = this.$refs.wrapper as HTMLElement;

        if (!this.targetDiv) {
            return;
        }

        // Store a reference to the target element's parent node
        this.targetParent = this.targetDiv.parentNode as HTMLElement;

        // Create a new MutationObserver
        this.observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                // Check if the target div was removed from the DOM
                if (
                    mutation.type === 'childList' &&
                    Array.from(mutation.removedNodes).includes(this.targetDiv as HTMLElement)
                ) {
                    // If so, add it back to its original parent node
                    if (this.targetParent) {
                        this.targetParent.appendChild(this.targetDiv as HTMLElement);
                    }
                }
            });
        });

        // Configure the observer to monitor changes to the body element
        const config = { childList: true, subtree: true };
        if (this.targetDiv && this.observer) {
            this.observer.observe(document.body, config);
        }
    }

    beforeDestroy() {
        // Disconnect the observer when the component is removed from the DOM
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    mailTo() {
        window.location.href = 'mailto:hello@calizy.com;';
    }
}
</script>

<style lang="sass">
.b-access-lock-page__wrapper
    position: fixed
    width: 100%
    height: 100%
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.98) 33.09%, #FFFFFF 99.94%)
</style>
