<template>
<div>
    <form class='b-add-branch__form h-flex-dir-column'
          @keydown.stop.prevent.enter='onSubmit'>
        <template v-if='isImhAccount'>
            <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                {{ $t('LABEL.APPOINTMENT.INFORMATION') }}
            </h3>
            <div
                class='b-form-base-line'>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20 fw-input--uppercase'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.ADVISOR.DAMAGE.TYPE.NAME`)}`'>
                    <FwInput
                        :value='imh_damage_type_name'
                        type='text'
                        name='text'
                        disabled
                        inputStyle='white-bigger-shadow'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='h-mhd-20 fw-input--uppercase b-form-base-line__d260'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.ADVISOR.DAMAGE.TYPE.CODE`)}`'>
                    <FwInput
                        :value='imh_damage_type_code'
                        type='text'
                        name='text'
                        disabled
                        inputStyle='white-bigger-shadow'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20 fw-input--uppercase'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.ADVISOR.DAMAGE.TYPE.REFERENCE`)}`'>
                    <FwInput
                        :value='reference_code'
                        type='text'
                        name='text'
                        disabled
                        inputStyle='white-bigger-shadow'>
                    </FwInput>
                </FwFormInput>
            </div>
        </template>
        <template v-else>
            <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                {{ $t('LABEL.APPOINTMENT.BOOKED.BY') }}
            </h3>
            <div
                class='b-form-base-line'>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20 fw-input--uppercase'
                    labelType='label-medium'
                    :title='`${$t("LABEL.NAME")}`'>
                    <FwInput
                        :value='manager_full_name'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        :disabled='disabledForm'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='h-mhd-20 b-form-base-line__d260'
                    :style='!isPartnerFlow ? "b-form-base-line__d260" : null'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.PHONE`)}`'>
                    <FwInput
                        :value='manager_phone'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        :disabled='disabledForm'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    v-if='!isPartnerFlow'
                    class='b-form-base-line__inner-input h-mhd-20'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.EMAIL`)}`'>
                    <FwInput
                        :value='manager_email'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        :disabled='disabledForm'>
                    </FwInput>
                </FwFormInput>
            </div>
        </template>
        <div
            v-if='isPartnerFlow'
            class='b-form-base-line h-mt-20'>
            <FwFormInput
                class='h-flex-1-0 h-width-100p h-mhd-20'
                labelType='label-medium'
                :title='$t(`LABEL.NOTES`)'>
                <FwInput
                    :value='notes'
                    type='text'
                    name='text'
                    inputStyle='textarea'
                    :disabled='disabledForm'>
                </FwInput>
            </FwFormInput>
        </div>
        <template v-if='!isPartnerFlow'>
            <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                {{ $t('LABEL.ADVISOR.INFORMATION') }}
            </h3>
            <div class='b-form-base-line'>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20 fw-input--uppercase'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.NAME`)}`'>
                    <FwInput
                        :value='advisor_technician_full_name'
                        type='text'
                        name='text'
                        :disabled='disabledForm'
                        inputStyle='white-bigger-shadow'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='h-mhd-20 b-form-base-line__d260'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.PHONE`)}`'>
                    <FwInput
                        :value='advisor_technician_phone'
                        type='text'
                        name='text'
                        :disabled='disabledForm'
                        inputStyle='white-bigger-shadow'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.EMAIL`)}`'>
                    <FwInput
                        :value='advisor_technician_email'
                        type='text'
                        name='text'
                        :disabled='disabledForm'
                        inputStyle='white-bigger-shadow'>
                    </FwInput>
                </FwFormInput>
            </div>
            <template v-if='appointmentData.category !== `location_category_by_phone`'>
                <template v-if='isServicePoint'>
                    <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                        {{ $t('LABEL.APPT.LOCATION') }}
                    </h3>
                    <div class='b-form-base-line'>
                        <FwFormInput
                            class='b-form-base-line__inner-input h-mhd-20 fw-input--uppercase'
                            labelType='label-medium'
                            :title='$t(`LABEL.SERVICE_POINT.NAME`)'>
                            <FwInput
                                :value='service_point_name'
                                type='text'
                                name='text'
                                :disabled='disabledForm'
                                inputStyle='white-bigger-shadow'>
                            </FwInput>
                        </FwFormInput>
                        <FwFormInput
                            class='h-mhd-20 fw-input--uppercase b-form-base-line__d260'
                            style='flex: 0 0 260px'
                            labelType='label-medium'
                            :title='`${$t(`LABEL.SERVICE_POINT.PHONE`)}`'>
                            <FwInput
                                :value='service_point_phone'
                                type='text'
                                name='text'
                                :disabled='disabledForm'
                                inputStyle='white-bigger-shadow'>
                            </FwInput>
                        </FwFormInput>
                        <FwFormInput
                            class='b-form-base-line__inner-input h-mhd-20'
                            labelType='label-medium'
                            :title='`${$t(`LABEL.SERVICE_POINT.ADDRESS`)}`'>
                            <FwInput
                                :value='service_point_address'
                                type='text'
                                name='text'
                                :disabled='disabledForm'
                                inputStyle='white-bigger-shadow'>
                            </FwInput>
                        </FwFormInput>
                    </div>
                </template>
                <template v-if='showBranchRow'>
                    <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                        {{ $t('LABEL.APPT.LOCATION') }}
                    </h3>
                    <div class='b-form-base-line'>
                        <FwFormInput
                            class='b-form-base-line__inner-input h-mhd-20 fw-input--uppercase'
                            labelType='label-medium'
                            :title='$t(`LABEL.BRANCH.NAME`)'>
                            <FwInput
                                :value='branch_name'
                                type='text'
                                name='text'
                                :disabled='disabledForm'
                                inputStyle='white-bigger-shadow'>
                            </FwInput>
                        </FwFormInput>
                        <FwFormInput
                            class='h-mhd-20 fw-input--uppercase b-form-base-line__d260'
                            labelType='label-medium'
                            :title='`${$t(`LABEL.BRANCH.PHONE`)}`'>
                            <FwInput
                                :value='branch_phone'
                                type='text'
                                name='text'
                                :disabled='disabledForm'
                                inputStyle='white-bigger-shadow'>
                            </FwInput>
                        </FwFormInput>
                        <FwFormInput
                            class='b-form-base-line__inner-input h-mhd-20'
                            labelType='label-medium'
                            :title='`${$t(`LABEL.BRANCH.ADDRESS`)}`'>
                            <FwInput
                                :value='branch_address'
                                type='text'
                                name='text'
                                :disabled='disabledForm'
                                inputStyle='white-bigger-shadow'>
                            </FwInput>
                        </FwFormInput>
                    </div>
                </template>
                <div v-if='appointmentData.category === `location_category_on_site`'>
                    <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                        {{ $t('LABEL.APPT.LOCATION') }}
                    </h3>
                    <FwFormInput
                        class='b-form-base-line__inner-input h-mhd-20'
                        labelType='label-medium'
                        :title='`${$t(`SELECT.ADDRESS.BRANCH.CUSTOMER`)}`'>
                        <FwInput
                            :value='location'
                            type='text'
                            name='text'
                            :disabled='disabledForm'
                            inputStyle='white-bigger-shadow'>
                        </FwInput>
                    </FwFormInput>
                </div>
                <div v-if='appointmentData.video_conference_url'
                     class='h-width-100p h-mt-15'>
                    <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                        {{ $t('LABEL.APPT.LOCATION') }}
                    </h3>
                    <FwFormInput
                        class='h-mh-20'
                        labelType='label-medium'
                        :title='$t("LABEL.VIDEO.CONFERENCE.URL")'>
                        <a
                            class='h-flex h-flex-center h-flex-space-between b-link'
                            :href='video_conference_url'
                            target='_blank'>
                            <span class='b-link-text'>
                                {{ appointmentData.video_conference_url }}
                            </span>
                            <FwIcon
                                icon='link-target'
                                color='#213F6B'
                                size='24'>
                            </FwIcon>
                        </a>
                    </FwFormInput>
                </div>
            </template>
        </template>
    </form>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';

import { AppointmentSearchType } from '@/types/Appointment';
import ValidationMixin from '@/mixins/validation';
import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';

@Component
export default class InternalAppointmentForm extends Mixins(AccountMixin, PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: Boolean, default: false }) readonly isPartnerFlow!: boolean;

    disabledForm: boolean = true;

    manager_full_name: string = '';
    manager_phone: string = '';
    manager_email: string = '';
    location: string = '';
    branch_name: string = '';
    branch_phone: string = '';
    branch_address: string = '';
    video_conference_url: string = '';
    advisor_technician_full_name: string = '';
    advisor_technician_phone: string = '';
    advisor_technician_email: string = '';
    service_point_name: string = '';
    service_point_phone: string = '';
    service_point_address: string = '';
    imh_damage_type_code: string = '';
    imh_damage_type_name: string = '';
    reference_code: string = '';
    notes: string = '';

    serverErrors: serverErrors = {};

    get isSCApp(): boolean {
        return this.appointmentData.booking_platform === 'sc';
    }

    get branchInformationRow() {
        return this.branch_address || this.branch_phone || this.branch_name;
    }

    get isServicePoint(): boolean {
        return this.appointmentData.category === `location_category_in_service_point`;
    }

    get showBranchRow(): boolean {
        return (
            !this.isServicePoint &&
            this.appointmentData.category !== `location_category_on_site` &&
            this.appointmentData.category !== `location_category_by_online_video` &&
            this.isBranchesFlow
        );
    }

    updateFormByServerValues() {
        if (this.appointmentData) {
            if (this.isSCApp) {
                if (this.appointmentData.client_full_name) {
                    this.manager_full_name = this.appointmentData.client_full_name;
                }
                if (this.appointmentData.client_phone) {
                    this.manager_phone = this.appointmentData.client_phone;
                }
                if (this.appointmentData.client_email) {
                    this.manager_email = this.appointmentData.client_email;
                }
            } else {
                if (this.appointmentData.manager_full_name) {
                    this.manager_full_name = this.appointmentData.manager_full_name;
                }
                if (this.appointmentData.manager_phone) {
                    this.manager_phone = this.appointmentData.manager_phone;
                }
                if (this.appointmentData.manager_email) {
                    this.manager_email = this.appointmentData.manager_email;
                }
            }
            if (this.appointmentData.branch_name) {
                this.branch_name = this.appointmentData.branch_name;
            }
            if (this.appointmentData.branch_address) {
                this.branch_address = this.appointmentData.branch_address;
            }
            if (this.appointmentData.location) {
                this.location = this.appointmentData.location;
            }
            if (this.appointmentData.branch_phone) {
                this.branch_phone = this.appointmentData.branch_phone;
            }

            if (this.isImhAccount) {
                if (this.appointmentData.client_full_name) {
                    this.advisor_technician_full_name = this.appointmentData.client_full_name;
                }
                if (this.appointmentData.client_phone) {
                    this.advisor_technician_phone = this.appointmentData.client_phone;
                }
                if (this.appointmentData.client_email) {
                    this.advisor_technician_email = this.appointmentData.client_email;
                }
            } else {
                if (this.appointmentData.advisor_technician_full_name) {
                    this.advisor_technician_full_name = this.appointmentData.advisor_technician_full_name;
                }
                if (this.appointmentData.advisor_technician_phone) {
                    this.advisor_technician_phone = this.appointmentData.advisor_technician_phone;
                }
                if (this.appointmentData.advisor_technician_email) {
                    this.advisor_technician_email = this.appointmentData.advisor_technician_email;
                }
            }

            if (this.appointmentData.video_conference_url) {
                this.video_conference_url = this.appointmentData.video_conference_url;
            }
            if (this.appointmentData.service_point_name) {
                this.service_point_name = this.appointmentData.service_point_name;
            }
            if (this.appointmentData.service_point_address) {
                this.service_point_address = this.appointmentData.service_point_address;
            }
            if (this.appointmentData.service_point_phone) {
                this.service_point_phone = this.appointmentData.service_point_phone;
            }
            if (this.appointmentData.imh_damage_type_code) {
                this.imh_damage_type_code = this.appointmentData.imh_damage_type_code;
            }
            if (this.appointmentData.imh_damage_type_name) {
                this.imh_damage_type_name = this.appointmentData.imh_damage_type_name;
            }
            if (this.appointmentData.reference_code) {
                this.reference_code = this.appointmentData.reference_code;
            }
        }
    }

    updateFormByServerPartnersValues() {
        if (this.appointmentData) {
            if (this.appointmentData.manager_full_name) {
                this.manager_full_name = this.appointmentData.manager_full_name;
            }
            if (this.appointmentData.manager_phone) {
                this.manager_phone = this.appointmentData.manager_phone;
            }
            if (this.appointmentData.notes) {
                this.notes = this.appointmentData.notes;
            }
        }
    }

    created() {
        if (this.isPartnerFlow) {
            this.updateFormByServerPartnersValues();
        } else {
            this.updateFormByServerValues();
        }
    }

    @Emit('onSubmit')
    onSubmit() {}
}
</script>

<style lang='sass'>
    .b-add-branch__form
        display: flex
        flex-wrap: wrap
        margin: 0 -15px

        &__row
            display: flex
            width: 100%
            margin-bottom: 0

            @include media('<=phone')
                flex-direction: column
                padding: 0 15px
                margin-bottom: 0 !important

                .b-add-branch__form__input
                    margin: 8px 15px

        &__input
            flex: 1 0
            margin: 0 15px
            input
                &:disabled
                    color: darkgray

        &__delete
            text-transform: uppercase
            margin-top: 20px
            font-size: 12px
            color: #FF004D
            font-weight: bold
            cursor: pointer
            text-align: center

            &:hover
                opacity: 0.7
</style>
