export default {
    'BREADCRUMBS.APPOINTMENTS.TYPES': 'Appointment types',
    'BREADCRUMBS.CUSTOM.DETAILS': 'Customer details',
    'BREADCRUMBS.ORGANISATION': 'Organisation',
    'BREADCRUMBS.BUSINESS.PARTNER.TITLE': 'Business partner companies',
    'BREADCRUMBS.HUBS': 'Hubs',
    'BREADCRUMBS.BRANCHES': 'Branches',
    'BREADCRUMBS.HUBS.BRANCHES': 'Hubs and Branches',
    'BREADCRUMBS.ADVISORS': 'Advisors',
    'BREADCRUMBS.TECH': 'Technicians',
    'BREADCRUMBS.LOCATION': 'Location',
    'BREADCRUMBS.ADDRESS': 'Address',
    'BREADCRUMBS.RECAP': 'Recap',
};
