<template>
<div v-if='appointmentDataToUpdate' class='h-pos-abs' style='right: 40px; bottom: 80px; z-index: 11'>
    <FwSimpleCard
        v-if='isOpen'
        class='h-pos-abs'
        style='right: 0; bottom: 80px'
        width='300px'
        height='auto'
        padding='30px 25px'>
        <div class='b-consult-cards__item h-width-100p'>
            <h4 class='h-mv-0'>
                {{ $t('UPDATE.APPOINTMENT.BEING.UPDATED') }}
            </h4>
            <ul class='h-width-100p h-mt-15 h-mb-0 h-pl-0 h-font-14'>
                <li class='h-pl-0 h-mb-15'>
                    • {{ appointmentDataToUpdate.title }}
                </li>
                <li class='h-pl-0 h-mb-15'>
                    • {{ currentAppointmentMethod }}
                </li>
                <li v-if='appointmentDataToUpdate.location' class='h-pl-0 h-mb-15'>
                    • {{ appointmentDataToUpdate.location }}
                </li>
                <li class='h-pl-0 h-mb-15'>
                    • {{ getCurrentDate }}
                </li>
                <li class='h-pl-0 h-mb-15'>
                    • {{ dates }}
                </li>
                <li class='h-pl-0'>
                    • {{ appointmentDataToUpdate.advisor_technician_full_name | capitalizeAll }}
                </li>
            </ul>
        </div>
    </FwSimpleCard>
    <FwButton
        class='h-m-20'
        :style='{
            margin: 0,
            lineHeight: 1,
        }'
        min-width='60px'
        borderRadiusType='tiny-border'
        paddingHorizontal
        size='large-xl'
        color='choose'
        @click='isOpen = !isOpen'>
        <div class='h-width-100p h-flex h-flex-center'>
            <FwIcon :icon='isOpen ? `close` : `plus`'
                    :size='isOpen ? 18 : 20'
                    color='#788aa5'>
            </FwIcon>
        </div>
    </FwButton>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DateMixin from '@/mixins/dateMixin';
import { AppointmentSearchType } from '@/types/Appointment';
import { appointmentLocationResponseData } from '@/mocks/events';
import i18n from '@/locale';
import { dateAsEventTime } from '@/helpers/dates';

const AppointmentStore = namespace('AppointmentStore');

@Component
export default class AppointmentDetailsCard extends Mixins(DateMixin) {
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;

    isOpen: boolean = false;

    get currentAppointmentMethod() {
        // @ts-ignore-next-line
        return [this.appointmentDataToUpdate.category].map(item => appointmentLocationResponseData[item][`${i18n.locale}_name`]).join(',');
    }

    get getCurrentDate() {
        return this.appointmentDataToUpdate ? dateAsEventTime(new Date(this.appointmentDataToUpdate.dt_start as string), i18n.locale) : '';
    }

    get dates(): string {
        return this.appointmentDataToUpdate ? `${this.getCurrentDateAsMinutesAMPM(new Date(this.appointmentDataToUpdate.dt_start as string))}
        - ${this.getCurrentDateAsMinutesAMPM(new Date(this.appointmentDataToUpdate.dt_end as string))}` : '';
    }
}
</script>
