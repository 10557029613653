export default {
    'LABEL.COMPANY.REASON.NAME': 'Raison sociale',
    'LABEL.COMPANY.ADDRESS': 'Adresse société',
    'LABEL.FIRST.NAME': 'Prénom',
    'LABEL.LAST.NAME': 'Nom',
    'LABEL.CLIENT.INFORMATION': 'INFORMATIONS CLIENT',
    'LABEL.ADDITIONAL.INFORMATION': 'INFORMATIONS ADDITIONNELLES',
    'LABEL.HEADCOUNT': 'Effectif',
    'LABEL.FIRM.NAME': 'Nom du cabinet',
    'LABEL.CLIENT.NAME': 'Prénom du client',
    'LABEL.CLIENT.LAST.NAME': 'Nom du client',
    'LABEL.CLIENT.PROF.STATUS': 'Situation professionnelle',
    'LABEL.ADVISOR.INFORMATION': 'INFORMATIONS CONSEILLER',
    'LABEL.ADVISORS.NAME': 'Nom du conseiller',
    'LABEL.ADVISOR.NAME': 'Nom du conseiller',
    'LABEL.ADVISORS.PHONE': 'Téléphone du conseiller',
    'LABEL.ADVISOR.PHONE': 'Téléphone du conseiller',
    'LABEL.ADVISOR.DAMAGE.TYPE.NAME': 'Libellé du type de sinistre',
    'LABEL.ADVISOR.DAMAGE.TYPE.CODE': 'Code du type de sinistre',
    'LABEL.ADVISOR.DAMAGE.TYPE.REFERENCE': 'Référence',
    'LABEL.ROLE': 'Fonction',
    'LABEL.COMPANY': 'Société',
    'LABEL.ROLE.PLACEHOLDER': 'Sélectionnez un fonction',
    'LABEL.EMAIL': 'Email',
    'LABEL.COLOR': 'Couleur',
    'LABEL.TITLE': 'Titre',
    'LABEL.COPY': 'Copier',
    'LABEL.CATEGORY': 'Catégorie',
    'LABEL.TIME.TITLE': 'Durée',
    'LABEL.ACCOUNT': 'Statut du compte',
    'LABEL.STATUS': 'Statut',
    'LABEL.CONTRACT.NUMBER': 'Numéro de contrat',
    'LABEL.APPOINTMENT.LOCATION': 'Lieu de l\'intervention',
    'LABEL.APPOINTMENT.BOOKED.BY': 'RDV PRIS PAR',
    'LABEL.APPOINTMENT.INFORMATION': 'INFORMATIONS RENDEZ-VOUS',
    'LABEL.CATEGORY.PLACEHOLDER': 'Sélectionnez une catégorie',
    'LABEL.SKILLS': 'Compétences',
    'LABEL.CHOOSE_DAY': 'Sélectionner une date',

    'LABEL.MEMBERSHIP': 'Adhésion',
    'LABEL.APPT.LOCATION': 'LIEU DU RDV',

    'LABEL.BRANCH.AFFILIATED.NAME': 'Nom de l\'agence de rattachement',
    'LABEL.BRANCH.AFFILIATED.CODE': 'Code de l\'agence de rattachement',
    'LABEL.BRANCH.NAME': 'Nom de l\'agence',
    'LABEL.BRANCH.PHONE': 'Téléphone de l\'agence',
    'LABEL.BRANCH.ADDRESS': 'Adresse de l\'agence',

    'LABEL.SERVICE_POINT.NAME': 'Nom permanence',
    'LABEL.SERVICE_POINT.PHONE': 'Téléphone permanence',
    'LABEL.SERVICE_POINT.ADDRESS': 'Adresse permanence',

    'LABEL.TECH.INFO': 'INFORMATIONS TECHNICIEN',

    'POPUP.MANAGER.EDIT.HEADER': 'Modifier l\'utilisateur',
    'POPUP.MANAGER.SUCCESS': 'L\'utilisateur a été ajouté',
    'POPUP.MANAGER.SUCCESS.UPDATED': 'Modifications enregistrées',
    'POPUP.MANAGER.SUCCESS.DELETED': 'Suppression confirmée',
    'POPUP.MANAGER.DELETE.HEADER': 'Supprimer l\'utilisateur',
    'POPUP.MANAGER.DELETE.QUESTION': 'Voulez-vous vraiment supprimer cet utilisateur ?',
};
