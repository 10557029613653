<template>
<LocationPopup
    v-if='showLocationPopup'
    :isRequestSending='isRequestSending'
    :companyAddressData='companyAddressData'
    @closeLocation='closeLocation'
    @confirmAddress='sendDataToServer'>
</LocationPopup>
<div v-else class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        class='h-pos-rel'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        width='800px'
        :title=' $t("LABEL.ADDRESS")'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :padding='isMobile() ? "30px 20px" : "50px 50px 60px"'
        @close='$emit("close")'>
        <template #body>
            <EditAddressForm
                v-if='objectData'
                :isRequestSending='isRequestSending'
                :objectData='objectData'
                :isAddress='isAddress'
                :updateType='updateType'
                :userType='userType'
                :isAddressSwitch='isAddressSwitch'
                @delete='showDeletePopup = true'
                @onSubmit='sendData'>
            </EditAddressForm>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EditAddressForm } from '@/components/forms/edit/EditAddressForm';
import { LocationPopup } from '@/components/popups/LocationPopup';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { CompanyAddressDataType } from '@/types/Companies';
import { dateToString } from '@/helpers/dates';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        EditAddressForm,
        LocationPopup,
    },
})
export default class AddOrEditAddressByForm extends Vue {
    @WorkerPageStore.Mutation('ADDRESS_UPDATE_DATA') setAddressUpdateData!: Function;
    @Prop({ type: Object, default: null }) readonly objectData!: any;
    @Prop({ type: String, default: '' }) readonly updateAction!: string;
    @Prop({ type: String, default: '' }) readonly updateType!: string;
    @Prop({ type: String, default: '' }) readonly userType!: string;
    @Prop({ type: Boolean, default: false }) readonly isAddress!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAddressSwitch!: boolean;
    showLocationPopup: boolean = false;
    showDeletePopup: boolean = false;
    isRequestSending: boolean = false;
    isAddressConfirmed: boolean = false;
    companyAddressData: any = null;

    get isTechProfile() {
        return this.updateType === `TechnicianProfile`;
    }
    closeLocation() {
        this.showLocationPopup = false;
    }
    sendData(data: CompanyAddressDataType) {
        this.openLocationPopup(data);
    }

    async sendDataToServer() {
        try {
            this.isRequestSending = true;
            const { address, dateFrom, latitude, longitude } = this.companyAddressData;
            const addressData = this.isTechProfile ? { departure_point: `${address}` } : { address: `${address}` };
            const payload = {
                is_address_confirmed: this.objectData!.is_address_confirmed,
                updatable_action: this.updateAction,
                dt_execute_at: dateToString(new Date(dateFrom as string), true),
                updatable_id: this.isTechProfile ? this.objectData.technician_profile.id : this.$route.params.id,
                updatable_type: this.updateType,
                new_data: {
                    ...addressData,
                    latitude,
                    longitude,
                },
            };
            const response = await WorkersWebApi.updateTechnicianSchedulesData(payload);
            this.setAddressUpdateData(response.data.update_request);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
            this.closeLocation();
            this.$emit('close');
            this.isRequestSending = false;
        } catch ({ response }) {
            this.sentNotif(response);
            this.isRequestSending = false;
        } finally {
            this.isRequestSending = false;
        }
    }
    async openLocationPopup(data: CompanyAddressDataType) {
        try {
            const response = await AppointmentWebApi.sendAddressForGeolocation({ address: data.address });
            this.companyAddressData = { ...data, ...response.data.geolocation };
            this.showLocationPopup = true;
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    closePopup() {
        this.showDeletePopup = false;
    }
}
</script>

<style lang='sass'>
    .b-add-company
        &__wrapper
            width: 1000px
            margin-bottom: 50px

            @include media('<=phone')
                width: 100%
</style>
