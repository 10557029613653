<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        class='b-how-to-use-popup'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mb-20'>
                    {{ $t('POPUPS.HOW_TO_USE.TITLE') }}
                </h2>
            </div>
            <div class='b-how-to-use-popup__aside'>
                {{ $t('POPUPS.HOW_TO_USE.SEE_THE_ACTIVITY') }}
            </div>
            <div class='b-how-to-use-popup__list__wrapper'>
                <div v-for='item in squaresData'
                     :key='item.type'
                     class='b-how-to-use-popup__list'>
                    <CalendarCell
                        :key='item.type'
                        :style='{ border: item.border || "none" }'
                        class='b-how-to-use-popup__square'
                        :type='item.type'
                        :inactive='true'
                        :isColorblind='isColorblind'>
                    </CalendarCell>

                    <div class='b-how-to-use-popup__list__text'>
                        {{ item.text }}
                    </div>
                </div>
            </div>
            <div class='b-how-to-use-popup__aside'>
                {{ $t('POPUPS.HOW_TO_USE.CLICKING_ON_SQUARE') }}
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CalendarCell } from '@/components/calendar/CalendarCell';
import {
    getColorType,
    APPOINTMENT_TYPE,
    UNAVAILABILITY_TYPE,
    RESERVED_SLOT_TYPE,
    MULTIPLY_TYPE,
    EMPTY_TYPE,
} from '@/components/calendar/helpers';

@Component({
    components: {
        CalendarCell,
    },
})
export default class HowToUsePopup extends Vue {
    @Prop({ type: Boolean, required: true }) readonly isColorblind!: boolean;

    get squaresData() {
        return [
            {
                type: APPOINTMENT_TYPE,
                color: getColorType(this.isColorblind)[APPOINTMENT_TYPE],
                text: this.$t(`POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_APPT`),
            },
            {
                type: RESERVED_SLOT_TYPE,
                color: getColorType(this.isColorblind)[RESERVED_SLOT_TYPE],
                text: this.$t(`POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_RESERVED_SLOTS`),
            },
            {
                type: UNAVAILABILITY_TYPE,
                color: getColorType(this.isColorblind)[UNAVAILABILITY_TYPE],
                text: this.$t(`POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_UNAVAILABILITIES`),
            },
            {
                type: MULTIPLY_TYPE,
                color: getColorType(this.isColorblind)[MULTIPLY_TYPE],
                text: this.$t(`POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_DIFFERENT_ELEMENTS`),
            },
            {
                type: EMPTY_TYPE,
                color: getColorType(this.isColorblind)[EMPTY_TYPE],
                text: this.$t(`POPUPS.HOW_TO_USE.NOTHING_IS_HAPPENING`),
                border: `2px solid #BDBDBDFF`,
            },
        ];
    }
}
</script>

<style lang='sass'>
.b-how-to-use-popup
    width: 460px !important
    color: #213F6B

    .b-calendar-body__cell__square
        padding: 0 !important

    &__list
        display: flex
        align-items: center
        justify-content: center
        margin: 5px 0

        &__wrapper
            display: flex
            align-items: flex-start
            flex-direction: column
            margin: 10px 0

        &__text
            padding-left: 10px
            color: #8495AC
            font-weight: 500
            font-size: 14px

    &__square
        width: 30px
        height: 30px
        flex: 0 0 30px

    &__aside
        text-align: center
        font-size: 14px
        line-height: 18px
        font-weight: 500
</style>
