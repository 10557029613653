export default {
    'BREADCRUMBS.APPOINTMENTS.TYPES': 'Types de rendez-vous',
    'BREADCRUMBS.CUSTOM.DETAILS': 'Information clients',
    'BREADCRUMBS.ORGANISATION': 'Organisation',
    'BREADCRUMBS.BUSINESS.PARTNER.TITLE': 'Entreprises partenaires',
    'BREADCRUMBS.HUBS': 'Hubs',
    'BREADCRUMBS.BRANCHES': 'Agences',
    'BREADCRUMBS.HUBS.BRANCHES': 'Hubs et Agences',
    'BREADCRUMBS.ADVISORS': 'Conseillers',
    'BREADCRUMBS.TECH': 'Ressources',
    'BREADCRUMBS.LOCATION': 'Lieu',
    'BREADCRUMBS.ADDRESS': 'Adresse',
    'BREADCRUMBS.RECAP': 'Résumé',
};
