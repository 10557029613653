export default {
    'CHOOSE_MANAGE.COMMON.TITLE': 'Que souhaitez-vous gérer?',

    'CHOOSE_MANAGE.COMMON.WORKER.PLURAL': ' technicien | techniciens',
    'CHOOSE_MANAGE.COMMON.ADVISOR.PLURAL': ' conseiller | conseillers',
    'CHOOSE_MANAGE.COMMON.NO.WORKER.PLURAL': ' technicien',
    'CHOOSE_MANAGE.COMMON.NO.ADVISOR.PLURAL': ' conseiller',

    'CHOOSE_MANAGE.COMMON.WORKERS.FILTER.NAME': 'Nom ou prénom',
    'CHOOSE_MANAGE.COMMON.WORKERS.FILTER.NUMBER': 'Nombre de techniciens',
    'CHOOSE_MANAGE.COMMON.WORKERS.FILTER.ID': 'ID',

    'ADD_COMPANIES.COMMON.WORKERS.TITLE': 'Ajouter une ou plusieurs ressources',
    'ADD_COMPANIES.COMMON.COMPANIES.TITLE': 'Ajouter une ou plusieurs entreprises',

    'ADD_COMPANIES.COMMON.MANUAL.TITLE': 'Manuellement',
    'ADD_COMPANIES.COMMON.MANUAL.DESCRIPTION': 'Renseignez les informations vous-même',

    'ADD_COMPANIES.COMMON.FILE.TITLE': 'Importer un fichier',
    'ADD_COMPANIES.COMMON.FILE.DESCRIPTION': 'Formats acceptés : CSV, XLS ou XLSX',
    'CONSULT.INFO.RESERVED.SLOTS': 'Créneaux réservés',
    'CONSULT.INFO.UNAVAILABILITIES': 'Indisponibilités',
    'CONSULT.INFO.AVAILABILITIES': 'Jours de disponibilité',
    'CONSULT.INFO.WORKING.HOURS': 'Horaires de travail',
    'CONSULT.INFO.ADD.DISTANCE.TEXT': 'Ajoutez le trajet pour le premier et le dernier rendez-vous de la journée',
    'CONSULT.INFO.APPT.STATISTICS': 'Statistiques des RDV',
    'CONSULT.ADD.FILE.TITLE': 'Importer un fichier',
    'CONSULT.ADD.FILE.QUESTION': 'Mon fichier est-il prêt pour l\'importation?',
    'CONSULT.ADD.FILE.DRAG': 'Glissez-déposez',
    'CONSULT.ADD.FILE.FRAG_ASIDE_TEXT': 'ou cliquez ici pour importer un fichier depuis votre ordinateur.' +
        ' Les formats supportés sont : CSV, XLS ou XLXS',

    'CONSULT.ADD.FILE.TUTORIAL.TITLE': 'Tutoriel pour l\'importation de fichier',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.1': 'Dans un nouveau Tableur comme <b>Excel</b> ou <b>Google Sheet</b>, ouvrez une <b>nouvelle ' +
        'feuille de calcul</b>.',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.2.COMPANIES': 'Créez et nommez <b>10 colonnes</b> comme ci-dessous. Les colonnes A, B, D, E et F ' +
        'doivent obligatoirement être remplies. Collez-y toutes les informations que vous avez sur les différentes ' +
        'compagnies à partir de la ligne 2.',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.2.WORKERS': 'Create and name <b>7 columns</b> as below. Columns A, B and C must be filled. ' +
        'Paste all the informations you have on the differents compagnies starting to the line 2.',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.3': '<b>Sauvegardez le fichier</b> en format <b>.CSV</b> ou <b>Excel</b>. Vous pouvez simplement accéder' +
        ' à cette fonctionnalité dans le menu Fichier de votre tableur, "Exporter en tant que .CSV" ou "Télécharger ' +
        'en tant que .CSV.',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.4': '<b>Importez votre fichier</b> en cliquant sur "Importer" (CSV, XLS ou XLXS) ou avec ' +
        'un glisser-déposer dans la zone prévue à cet effet. Si vous tentez d\'importer un autre type de fichier, vous ' +
        'ne pourrez pas importer le fichier',
    'CONSULT.ADD.FILE.TUTORIAL.STEP.5': 'Vous pourrez maintenant <b>vérifier vos données</b> et les "Importer" dans Calizy.',

    'CONSULT.DELETE.BUTTON': 'supprimer la société',
    'CONSULT.DELETE.WORKER.BUTTON': 'supprimer la ressource',
    'CONSULT.DELETE.WORKER.TITLE': 'Supprimer la ressource',
    'CONSULT.DELETE.WORKER.SUBTITLE': 'Voulez-vous vraiment supprimer cette ressource ?',

    'CONSULT.ADD.POPUP.ERROR.TITLE': 'Erreur',
    'CONSULT.ADD.POPUP.ERROR.TEXT': 'Veuillez consulter notre tutoriel pour l\'importation de fichier et réessayez',
    'CONSULT.ADD.POPUP.ERROR.BUTTON_TEXT': 'Lire le tutoriel d\'importation',

    'CONSULT.ADD.FILE.RESULT.BAR.TEXT': 'Tout est bon, vous pouvez importer vos données',
    'CONSULT.ADD.FILE.RESULT.BAR.BUTTON_TEXT': 'Importer',
    'CONSULT.ADD.FILE.RESULT.TITLE': 'Lecture de votre fichier',
    'CONSULT.ADD.FILE.RESULT.COMPANIES_FOUND_PLURAL': 'no entreprises trouvées | 1 entreprise trouvée | {count} entreprises trouvées',

    'CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME': 'Nom de l\'entreprise',
    'CONSULT.ADD.FILE.RESULT.FIELDS.ADDRESS': 'Adresse 1',
    'CONSULT.ADD.FILE.RESULT.FIELDS.ADDRESS_2': 'Adresse 2',
    'CONSULT.ADD.FILE.RESULT.FIELDS.ZIP_CODE': 'Code postal',
    'CONSULT.ADD.FILE.RESULT.FIELDS.INSEE_CODE': 'Code INSEE',
    'CONSULT.ADD.FILE.RESULT.FIELDS.COUNTRY': 'Pays',
    'CONSULT.ADD.FILE.RESULT.FIELDS.SIREN': 'SIREN',
    'CONSULT.ADD.FILE.RESULT.FIELDS.SIRET': 'SIRET',
    'CONSULT.ADD.FILE.RESULT.FIELDS.PHONE_NUMBER': 'Téléphone',
    'CONSULT.ADD.FILE.RESULT.FIELDS.EMAIL': 'Email',

    'CONSULT.DELETE.COMPANY.TITLE': 'Supprimer la société',
    'CONSULT.DELETE.COMPANY.SUBTITLE': 'Voulez-vous vraiment supprimer cette société ?',
    'CONSULT.DELETE.COMPANY.WORKERS': 'Des ressources sont toujours présentes dans cette société. Veuillez supprimer les ressources avant de supprimer la société',

    'CACHED.POPUP.INFO.TEXT': 'Si ce rendez-vous n\'est pas à jour, veuillez rafraîchir pour afficher les dernières données.',
    'CACHED.POPUP.CONTINUE.BUTTON': 'Continuer',
    'CACHED.POPUP.REFRESH.BUTTON': 'Rafraîchir',
};
