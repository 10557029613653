import { render, staticRenderFns } from "./UnavailabilitiesPopup.vue?vue&type=template&id=2f091850"
import script from "./UnavailabilitiesPopup.vue?vue&type=script&lang=ts"
export * from "./UnavailabilitiesPopup.vue?vue&type=script&lang=ts"
import style0 from "./UnavailabilitiesPopup.vue?vue&type=style&index=0&id=2f091850&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports