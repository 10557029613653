export default {
    'LOGOUT.HEADER': 'Your informations',
    'LOGOUT.BUTTON': 'Log out',
    'LOGOUT.HEADER.TXT': 'Edit my personal information',
    'LOGOUT.ROLE': 'Your role',
    'LOGOUT.PHONE': 'Phone',
    'LOGOUT.EMAIL': 'Email',
    'LOGOUT.PASSWORD': 'Password',
    'AUTH.LOG_OUT.TITLE': 'Log out from Calizy SAAS',
    'AUTH.LOG_OUT.ASIDE': 'Do you really want to log out?',
    'AUTH.LOG_OUT.NOT.ACTIVE': 'If you log out without creating a password, you won’t have access to your account with this email address',
    'AUTH.LOG_OUT.NO': 'No',
    'AUTH.LOG_OUT.YES': 'Yes',
};
