const COMPANY_NAME = 'company_name';
const CLIENT_COMPANY_TITLE = 'client_company_title';
const CLIENT_FULL_NAME = 'client_full_name';
const ADDRESS = 'address';
const CLIENT_PHONE = 'client_phone';
const ADVISOR_MEMBERSHIP = 'advisor_membership';
const IMH_DAMAGE_TYPE_CODE = 'imh_damage_type_code';
const IMH_DAMAGE_TYPE_NAME = 'imh_damage_type_name';
const REFERENCE_CODE = 'reference_code';
const CLIENT_ID = 'client_id';
const CLIENT_ADDRESS = 'client_address';
const REASON_COMPANY_NAME = 'reason_company_name';
const COMPANY_MANAGER = 'company_manager';
const CLIENT_SURNAME = 'client_surname';
const CLIENT_NAME = 'client_full_name';
const CLIENT_EMAIL = 'client_email';
const COMPANY_TITLE = 'company_title';
const ADVISOR_TECHNICIAN_FULL_NAME = 'advisor_technician_full_name';
const ADVISOR_TECHNICIAN_PHONE = 'advisor_technician_phone';
const ADVISOR_NAME = 'advisor_name';
const ADVISOR_PHONE = 'advisor_phone';
const ADVISOR_TECHNICIAN_EMAIL = 'advisor_technician_email';
const BRANCH_NAME = 'branch_name';
const BRANCH_PHONE = 'branch_phone';
const BRANCH_ADDRESS = 'branch_address';
const MANAGER_NAME = 'manager_name';
const MANAGER_PHONE = 'manager_phone';
const MANAGER_EMAIL = 'manager_email';
const MANAGER_BRANCH_NAME = 'manager_branch_name';
const NOTES = 'notes';
const BRANCH_VALUE = 'branches_name';
const CONTRACT_NUMBER = 'contract_number';
const LOCATION_VALUE = 'location_value';
const LOCATION = 'location';
const COMPANY = 'company';
const COMPANY_ADDRESS = 'company_address';

const AFF_BRANCH_NAME = 'affiliate_branch_name';
const AFF_BRANCH_CODE = 'affiliate_branch_code';

const APPOINTMENT_NAME = 'appointment_name';
const APPOINTMENT_SC_NAME = 'appointment_sc_name';
const APPOINTMENT_TIME = 'appointment_time';
const SKILL_NAME = 'skill_name';

const TITLE = 'title';
const FIRST_NAME = 'first_name';
const LAST_NAME = 'last_name';
const PHONE = 'phone';
const CONTACT_EMAIL = 'email';
const CLIENT_PROFESSIONAL_STATUS = 'professional_status';
const VIDEO_CONFERENCE_URL = 'video_conference_url';

const CLIENT_MME_COMPANY_NAME = 'mme_company_name';
const CLIENT_MME_FIRM_NAME = 'mme_firm_name';
const CLIENT_MME_HEADCOUNT = 'mme_headcount';

const CLIENT_APIZEE_COMPANY_NAME = 'apizee_company';
const CLIENT_APIZEE_BUSINESS_SECTOR = 'apizee_business_sector';

export {
    CLIENT_FULL_NAME,
    CLIENT_PHONE,
    CLIENT_ID,
    CLIENT_ADDRESS,
    COMPANY_NAME,
    CLIENT_COMPANY_TITLE,
    ADDRESS,
    AFF_BRANCH_NAME,
    AFF_BRANCH_CODE,
    REASON_COMPANY_NAME,
    COMPANY_MANAGER,
    CLIENT_SURNAME,
    CLIENT_NAME,
    CLIENT_EMAIL,
    COMPANY_TITLE,
    ADVISOR_TECHNICIAN_FULL_NAME,
    ADVISOR_TECHNICIAN_PHONE,
    ADVISOR_NAME,
    ADVISOR_PHONE,
    ADVISOR_TECHNICIAN_EMAIL,
    BRANCH_NAME,
    BRANCH_PHONE,
    BRANCH_ADDRESS,
    MANAGER_NAME,
    MANAGER_PHONE,
    MANAGER_EMAIL,
    MANAGER_BRANCH_NAME,
    NOTES,
    ADVISOR_MEMBERSHIP,
    IMH_DAMAGE_TYPE_CODE,
    IMH_DAMAGE_TYPE_NAME,
    REFERENCE_CODE,
    BRANCH_VALUE,
    CONTRACT_NUMBER,
    LOCATION_VALUE,
    LOCATION,
    COMPANY,
    COMPANY_ADDRESS,
    APPOINTMENT_NAME,
    APPOINTMENT_SC_NAME,
    APPOINTMENT_TIME,
    SKILL_NAME,
    TITLE,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    CONTACT_EMAIL,
    CLIENT_PROFESSIONAL_STATUS,
    VIDEO_CONFERENCE_URL,
    CLIENT_MME_COMPANY_NAME,
    CLIENT_MME_FIRM_NAME,
    CLIENT_MME_HEADCOUNT,
    CLIENT_APIZEE_COMPANY_NAME,
    CLIENT_APIZEE_BUSINESS_SECTOR,
};
