import { CompaniesAddressDataType } from '@/types/Companies';

function getAddressAsString(data: CompaniesAddressDataType): string {
    return `${data.address}, ${data.zip_code}${data.zip_code_postfix}, ${data.country}`;
}

function parseAddress(string: string): CompaniesAddressDataType {
    const data: Array<string> = string.split(', ');
    const zip_code: string = data[1].substr(0, data[1].indexOf(' '));
    const zip_code_postfix: string = data[1].substr(data[1].indexOf(' '));
    return {
        address: data[0],
        zip_code,
        zip_code_postfix,
        country: data[2],
    };
}

function addPostCodeToAddress(calizyAddress: string, post_code: string): string {
    const { zip_code, country, zip_code_postfix, address } = parseAddress(calizyAddress);
    const currentAddressCode = parseInt(zip_code, 10);
    if (Number.isInteger(currentAddressCode) && currentAddressCode === +post_code) {
        return calizyAddress;
    }
    return getAddressAsString({
        address,
        country,
        zip_code_postfix,
        zip_code: `${post_code} ${zip_code}`,
    });
}

export {
    getAddressAsString,
    parseAddress,
    addPostCodeToAddress,
};
