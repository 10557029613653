const dayListMapping: Array<string> = [
    `monday`,
    `tuesday`,
    `wednesday`,
    `thursday`,
    `friday`,
    `saturday`,
    `sunday`,
];

export {
    dayListMapping,
};
