export default {
    'LABEL.COMPANY.REASON.NAME': 'Social reason',
    'LABEL.COMPANY.ADDRESS': 'Company address',
    'LABEL.FIRST.NAME': 'First name',
    'LABEL.LAST.NAME': 'Last name',
    'LABEL.CLIENT.INFORMATION': 'CLIENT INFORMATION',
    'LABEL.ADDITIONAL.INFORMATION': 'ADDITIONAL INFORMATION',
    'LABEL.HEADCOUNT': 'Headcount',
    'LABEL.FIRM.NAME': 'Firm name',
    'LABEL.CLIENT.NAME': 'Client name',
    'LABEL.CLIENT.LAST.NAME': 'Client surname',
    'LABEL.CLIENT.PROF.STATUS': 'Professional status',
    'LABEL.ADVISOR.INFORMATION': 'ADVISOR INFORMATIONS',
    'LABEL.ADVISORS.NAME': 'Advisors name',
    'LABEL.ADVISOR.NAME': 'Advisor name',
    'LABEL.ADVISORS.PHONE': 'Advisors phone number',
    'LABEL.ADVISOR.PHONE': 'Advisor phone number',
    'LABEL.ADVISOR.DAMAGE.TYPE.NAME': 'Damage type name',
    'LABEL.ADVISOR.DAMAGE.TYPE.CODE': 'Damage type code',
    'LABEL.ADVISOR.DAMAGE.TYPE.REFERENCE': 'Reference',
    'LABEL.ROLE': 'Role',
    'LABEL.COMPANY': 'Company',
    'LABEL.BUSINESS.SECTOR': 'Business sector',
    'LABEL.ROLE.PLACEHOLDER': 'Select a role',
    'LABEL.EMAIL': 'Email',
    'LABEL.SERVICE_POINT_CAPACITY': 'Limit of advisors per day',
    'LABEL.COLOR': 'Color',
    'LABEL.TITLE': 'Title',
    'LABEL.COPY': 'Copy',
    'LABEL.CATEGORY': 'Category',
    'LABEL.TIME.TITLE': 'Duration',
    'LABEL.ACCOUNT': 'Account status',
    'LABEL.STATUS': 'Status',
    'LABEL.CONTRACT.NUMBER': 'Contract number',
    'LABEL.APPOINTMENT.LOCATION': 'Appointment location',
    'LABEL.APPOINTMENT.BOOKED.BY': 'APPOINTMENT BOOKED BY',
    'LABEL.APPOINTMENT.INFORMATION': 'APPOINTMENT INFORMATION',
    'LABEL.CATEGORY.PLACEHOLDER': 'Select a category',
    'LABEL.SKILLS': 'Skills',
    'LABEL.CHOOSE_DAY': 'Choose a date',

    'LABEL.MEMBERSHIP': 'Membership',
    'LABEL.APPT.LOCATION': 'APPOINTMENT LOCATION',

    'LABEL.BRANCH.AFFILIATED.NAME': 'Affiliated branch name',
    'LABEL.BRANCH.AFFILIATED.CODE': 'Affiliated branch code',
    'LABEL.BRANCH.NAME': 'Branch name',
    'LABEL.BRANCH.PHONE': 'Branch phone',
    'LABEL.BRANCH.ADDRESS': 'Branch address',

    'LABEL.SERVICE_POINT.NAME': 'Service point name',
    'LABEL.SERVICE_POINT.PHONE': 'Service point phone',
    'LABEL.SERVICE_POINT.ADDRESS': 'Service point address',

    'LABEL.TECH.INFO': 'TECHNICIAN INFORMATION',

    'POPUP.MANAGER.EDIT.HEADER': 'Edit user',
    'POPUP.MANAGER.SUCCESS': 'The user has been added',
    'POPUP.MANAGER.SUCCESS.UPDATED': 'The user has been updated',
    'POPUP.MANAGER.SUCCESS.DELETED': 'The user has been deleted',
    'POPUP.MANAGER.DELETE.HEADER': 'Delete the user',
    'POPUP.MANAGER.DELETE.QUESTION': 'Do you really want to delete this user?',
};
