export default {
    'BRANCH.STATISTICS': 'Statistiques',

    'BRANCH.STATISTICS.TYPES': 'Types',
    'BRANCH.STATISTICS.TOTAL': 'Total',
    'BRANCH.STATISTICS.METHODS': 'Modes',
    'BRANCH.STATISTICS.HONORED': 'Statuts',
    'BRANCH.STATISTICS.SOURCE': 'Sources',
    'BRANCH.STATISTICS.RECIPIENTS': 'Destinataires',
    'BRANCH.STATISTICS.CANCELLED.SOURCE': 'Sources',
    'BRANCH.STATISTICS.CANCELLED.RECIPIENTS': 'Destinataires',
    'BRANCH.STATISTICS.NOT.HONORED': 'Non honorés',
    'BRANCH.STATISTICS.ORIGIN': 'Origine',
    'BRANCH.STATISTICS.CANCELLATION.ORIGIN': 'Origines des annulations',
    'BRANCH.STATISTICS.CANCELLATION.TIME.BETWEEN': 'Délai entre l\'annulation du RDV et son début',

    'BRANCH.STATISTICS.BY_TIME_SLOT': 'Heure de début',
    'BRANCH.STATISTICS.TIME_BETWEEN': 'Délai entre la planification du RDV et son début',

    'BRANCH.STATISTICS.CANCELLATION': 'Origine des annulations',
    'BRANCH.STATISTICS.TIME_BETWEEN_2': 'Délai entre l\'annulation du RDV et son début',

    'BRANCH.STATISTICS.BOOKED.TYPE': 'RDV planifiés pour l\'agence',
    'BRANCH.STATISTICS.BOOKED.BY.TYPE': 'RDV planifiés par l\'agence',
    'BRANCH.STATISTICS.CANCELLED.TYPE': 'RDV annulés pour l\'agence',
    'BRANCH.STATISTICS.CANCELLED.BY.TYPE': 'RDV annulés par l\'agence',

    'HUB.STATISTICS.BOOKED.TYPE': 'RDV planifiés pour hub',
    'HUB.STATISTICS.BOOKED.BY.TYPE': 'RDV planifiés par hub',
    'HUB.STATISTICS.CANCELLED.TYPE': 'RDV annulés pour hub',
    'HUB.STATISTICS.CANCELLED.BY.TYPE': 'RDV annulés par hub',

    'BRANCH.STATISTICS.BOOKED': 'RDV planifiés',
    'BRANCH.STATISTICS.CANCELLED': 'RDV annulés',

    'BRANCH.STATISTICS.APPOINTMENT.SOURCE': 'Sources des RDV',
    'BRANCH.STATISTICS.APPOINTMENT.HONORED': 'RDV honorés',
    'BRANCH.STATISTICS.APPOINTMENT.RECIPIENTS': 'Destinataires des RDV',
    'BRANCH.STATISTICS.APPOINTMENT.CANCEL.RECIPIENTS': 'Destinataires des annulations',
    'BRANCH.STATISTICS.APPOINTMENT.CANCEL.SOURCE': 'Sources des annulations',
    'BRANCH.STATISTICS.APPOINTMENT.CANCELED': 'RDV annulés',
    'BRANCH.STATISTICS.DISTRIBUTION.APPOINTMENT': 'Répartition des RDV par heure de début',
    'BRANCH.STATISTICS.TIME.BTW.APPOINTMENT.CANCELLATION.APPOINTMENT': 'Délai entre l\'annulation du RDV et son début',
    'BRANCH.STATISTICS.TIME.BTW.MAKING.APPOINTMENT.APPOINTMENT': 'Délai entre la planification du RDV et son début',

    'BRANCH.STATISTICS.LOAN.APPOINTMENT': 'RDV emprunt',
    'BRANCH.STATISTICS.HEALTH.APPOINTMENT': 'RDV assurance santé',
    'BRANCH.STATISTICS.AUTO.APPOINTMENT': 'RDV auto',
    'BRANCH.STATISTICS.LOAN.CONSULTATION': 'Consultation emprunt',
    'BRANCH.STATISTICS.LEGAL.CONSULTATION': 'Consultation juridique',
    'BRANCH.STATISTICS.PERSONAL.CONSULTATION': 'Consultation personnelle',
    'BRANCH.STATISTICS.PROFESSIONAL.INSURANCE': 'Assurance professionnelle',
    'BRANCH.STATISTICS.PROFESSIONAL.CONSULTATION': 'Consultation professionnelle',
    'BRANCH.STATISTICS.PET.INSURANCE': 'Assurance animaux',

    'BRANCH.STATISTICS.FOR_BRANCH_ADVISORS': 'Pour des conseillers de l’agence',
    'BRANCH.STATISTICS.FROM_BRANCH_ADVISORS': 'Par des conseillers de l’agence',
    'BRANCH.STATISTICS.NOT.FROM_BRANCH_ADVISORS': 'Par des conseillers externes à l’agence',
    'BRANCH.STATISTICS.NOT.FOR_BRANCH_ADVISORS': 'Pour des conseillers externes à l’agence',

    'BRANCH.STATISTICS.BY_PHONE': 'Par téléphone',
    'BRANCH.STATISTICS.ON_SITE': 'Sur site',
    'BRANCH.STATISTICS.BRANCH_FACE_TO_FACE': 'En agence - RDV face à face',
    'BRANCH.STATISTICS.BRANCH_VIDEOCONFERENCE': 'En agence - RDV visioconférence',

    'BRANCH.STATISTICS.BEFORE': 'Avant',
    'BRANCH.STATISTICS.AFTER': 'Après',

    'BRANCH.STATISTICS.LESS.THAN.24h': 'Moins de 24h',
    'BRANCH.STATISTICS.BETWEEN.24h.48h': 'Entre 24h et 48h',
    'BRANCH.STATISTICS.BETWEEN.48h.72h': 'Entre 48h et 72h',
    'BRANCH.STATISTICS.BETWEEN.3d.7d': 'Entre 3 et 7 jours',
    'BRANCH.STATISTICS.MORE.THAN.7d': 'Plus de 7 jours',

    'BRANCH.STATISTICS.INTERNAL.CANCELLATION': 'Annulation interne',
    'BRANCH.STATISTICS.EXTERNAL.CANCELLATION': 'Annulation externe',
};
