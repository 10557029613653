<template>
<div>
    <div class='b-settings__title'>
        <span>
            {{ $t("SETTINGS.ROLES.TITLE") }}
            <template v-if='!(loading && !totalPage)'>
                ({{ totalPage }})
            </template>
        </span>
        <FwInput
            v-if='false'
            class='b-settings-roles__search'
            :value='query'
            :placeholder='$t("SETTINGS.ROLES.SEARCH.TEXT")'
            type='text'
            inputStyle='white-bigger-search'
            iconRight='loupe'
            @update:value='value => query = value'>
        </FwInput>
    </div>

    <div class='b-settings-roles__sort__wrapper'>
        <FwButton
            v-if='canCreate'
            class='h-mt-20 qa-add-role-button'
            size='little'
            :disabled='disableElements'
            borderRadiusType='small-border'
            :to='`/settings/roles/add`'
            @click.native='isAddManagerPopupOpen = true'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else
         class='h-flex h-flex-wrap h-mh--10 h-mt-20'>
        <component
            :is='canRead ? `router-link` : `div`'
            v-for='role in roles'
            :key='role.id'
            v-bind='canRead ? { to: `/settings/role/${role.id}` } : null'
            class='h-ph-10 h-mb-20 h-flex-0-0-50p'>
            <FwSimpleCard
                class='b-settings-roles__card'
                width='100%'
                height='auto'
                padding='20px'
                :isActive='canRead'
                :isDisabled='!canRead'>
                {{ role.name }}
            </FwSimpleCard>
        </component>
        <div v-if='query && preparedRoles.length === 0'>
            {{ $t('LABEL.NO.RESULT') }}
        </div>
    </div>
    <FwPagination
        v-if='totalPage > perPage'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { SettingsRoleSimpleType } from '@/types/Settings';
import PermissionsMixin from '@/mixins/permissions';
import { pagePaginationData } from '@/types/Appointment';
import { updateUrlPageAndQuery } from '@/helpers/url';

@Component
export default class SettingsRolesPage extends Mixins(PermissionsMixin) {
    roles!: Array<SettingsRoleSimpleType> | null;
    query: string = '';
    loading: boolean = false;
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;

    updateState(page: number = this.currentPage) {
        this.$router.push(updateUrlPageAndQuery(`/settings/roles`, page));
    }

    get preparedRoles() {
        return this.query && this.roles ? this.roles.filter(role => {
            return role.name.toLowerCase().includes(this.query.toLowerCase());
        }) : this.roles;
    }

    takeParamsFromUrl(): void {
        this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
    }

    async fetchRoles() {
        this.loading = true;
        this.takeParamsFromUrl();
        const payload: pagePaginationData = {
            current_page: this.currentPage,
            per_page: this.perPage,
        };
        try {
            const { data } = await SettingsWebApi.getSettingsRoles(payload);
            this.roles = data.roles;
            this.totalPage = data.meta.total_count;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    created() {
        this.fetchRoles();
    }

    @Watch(`$route`)
    onRouterChange(): void {
        this.fetchRoles();
    }
};
</script>

<style lang='sass'>
.b-settings-roles
    &__search
        width: 350px

    &__sort
        &__wrapper
            margin-top: 10px
            display: flex
            justify-content: flex-end

    &__card
        justify-content: flex-start !important
        font-weight: 500
</style>
