<template>
<div>
    <router-view :workerAdvisorString='workerAdvisorString'></router-view>
    <ProgressBarWrapper v-if='showFooterProgress'></ProgressBarWrapper>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ProgressBarWrapper } from '@/components/global/ProgressBarWrapper';

@Component({
    components: {
        ProgressBarWrapper,
    },
})
export default class PartnersWrapper extends Vue {
    get workerAdvisorString() {
        return this.mainRouteGlobal === `/partners-onboarding` ? `WORKERS` : `ADVISORS`;
    }
    get showFooterProgress() {
        return Boolean(this.$route.meta && this.$route.meta.breadCrumbsText) || this.isRecapRoute;
    }
}
</script>
