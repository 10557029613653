export default {
    'ONBOARDING.TITLE': 'Bienvenue sur Calizy',
    'ONBOARDING.ASIDE': 'Voici les étapes pour commencer à paramétrer Calizy',
    'ONBOARDING.OPTION.1': 'Catégories de rendez-vous',
    'ONBOARDING.OPTION.2': 'Créez votre équipe',
    'ONBOARDING.OPTION.ORGANISATION': 'Organisation',
    'ONBOARDING.OPTION.BRANCH': 'Agence',
    'ONBOARDING.OPTION.BRANCHES': 'Agences',
    'ONBOARDING.BUTTON': 'Allons-y',

    'ONBOARDING.CHOOSE.TITLE': 'Les rendez-vous seront planifiés avec des personnes:',
    'ONBOARDING.CHOOSE.MY.COMPANY.TEXT': 'De mon entreprise',
    'ONBOARDING.CHOOSE.PARTNER.COMPANY.TEXT': 'D\'entreprises partenaires',

    'ONBOARDING.CHOOSE.BRANCH.TITLE': 'Ajouter une agence',

    'ONBOARDING.CHOOSE.CATEGORY.TITLE': 'Ajouter une catégorie de rendez-vous',
    'ONBOARDING.CHOOSE.CATEGORY.MANUAL.TITLE': 'Manuellement',
    'ONBOARDING.CHOOSE.CATEGORY.MANUAL.DESCRIPTION': 'Renseignez les informations vous-même',
    'ONBOARDING.CHOOSE.CATEGORY.FILE.TITLE': 'Importer un fichier',
    'ONBOARDING.CHOOSE.CATEGORY.FILE.DESCRIPTION': 'Formats acceptés : CSV, XLS ou XLSX',

    'ONBOARDING.PARTNERS.CHOOSE.CATEGORY.TITLE': 'Ajouter une société partenaire',

    'ONBOARDING.CHOOSE.CATEGORIES.HEADER': 'Types de rendez-vous',
    'ONBOARDING.CHOOSE.TYPES.HEADER': 'Types des rendez-vous',
    'ONBOARDING.PARTNERS.CHOOSE.CATEGORIES.HEADER': 'Catégories de rendez-vous',
    'ONBOARDING.CHOOSE.CATEGORIES.SUBTEXT': 'Vous pouvez ajouter, modifier ou supprimer les types de rendez-vous directement sur cette page ou passer à l’étape suivante',
    'ONBOARDING.CHOOSE.BUSINESS.PARTNERS.SUBTEXT': 'Vous pouvez ajouter, modifier ou supprimer les entreprisespartenaires directement sur cette page ou passer à l’étape suivante',
    'ONBOARDING.CHOOSE.BRANCH.CATEGORIES.SUBTEXT': 'Vous pouvez ajouter, modifier ou supprimer les agences directement sur cette page ou passer à l’étape suivante',

    'ONBOARDING.WORKING.HOURS.CHOOSE.HOW': 'Veuillez choisir comment vous voulez valider les horaires de travail',
    'ONBOARDING.WORKING.HOURS.WORKERS.CHOOSE.IDENTICAL': 'Toutes mes ressources ont les mêmes heures de travail',
    'ONBOARDING.WORKING.HOURS.ADVISORS.CHOOSE.IDENTICAL': 'Toutes mes conseillers ont les mêmes heures de travail',
    'ONBOARDING.WORKING.HOURS.WORKERS.CHOOSE.SPECIFIC': 'Mes ressources ont des heures de travail différentes',
    'ONBOARDING.WORKING.HOURS.ADVISORS.CHOOSE.SPECIFIC': 'Mes conseillers ont des horaires de travail différentes',
    'ONBOARDING.WORKING.HOURS.WORKERS.PAGE.SUBTITLE': 'Quelles sont les horaires de travail de vos ressources ?',
    'ONBOARDING.WORKING.HOURS.ADVISORS.PAGE.SUBTITLE': 'Quelles sont les heures de travail de vos conseillers?',
    'ONBOARDING.WORKING.HOURS.WORKERS.CONFIRM': 'Valider pour toutes vos ressources',
    'ONBOARDING.WORKING.HOURS.ADVISORS.CONFIRM': 'Valider pour toutes vos conseillers',
    'ONBOARDING.WORKING.HOURS.WORKERS.LIST.SUBTITLE': 'Cliquez sur une ressource pour paramétrer ses horaires de travail',

    'ONBOARDING.CALENDAR.SYNC.WORKERS.TITLE': 'Synchronisation du calendrier',
    'ONBOARDING.CALENDAR.SYNC.ADVISORS.TITLE': 'Synchronisation du calendrier',
    'ONBOARDING.CALENDAR.SYNC.WORKERS.SUBTITLE': 'Veuillez inviter vos ressources à synchroniser leur calendrier',
    'ONBOARDING.CALENDAR.SYNC.ADVISORS.SUBTITLE': 'Veuillez inviter vos conseillers à synchroniser leur calendrier',
    'ONBOARDING.CALENDAR.SYNC.WORKERS.BUTTON.INVITE': 'Inviter toutes mes ressources',
    'ONBOARDING.CALENDAR.SYNC.ADVISORS.BUTTON.INVITE': 'Inviter toutes mes conseillers',
    'ONBOARDING.CALENDAR.SYNC.BUTTON.WORKERS.CONFIRM': 'Choisir les ressources',
    'ONBOARDING.CALENDAR.SYNC.BUTTON.ADVISORS.CONFIRM': 'Choisir les conseillers',
    'ONBOARDING.CALENDAR.SYNC.SELECT.WORKERS': 'Sélectionnez les ressources vous voulez inviter à synchroniser leur calendrier',
    'ONBOARDING.CALENDAR.SYNC.SELECT.ADVISORS': 'Sélectionnez les conseillers vous voulez inviter à synchroniser leur calendrier',

    'ONBOARDING.FINALE.PAGE.TITLE': 'Vous êtes prêt',
    'ONBOARDING.FINALE.PAGE.TEXT': 'Vous êtes prêt à utiliser Calizy et à planifier votre premier rendez-vous',
    'ONBOARDING.FINALE.PAGE.BUTTON': 'Planifier un rendez-vous',

    'ONBOARDING.INVITATION.PREVIEW.WELCOME': 'Bienvenue sur Calizy Pro !',
    'ONBOARDING.INVITATION.PREVIEW.CUSTOMER.NAME': 'Vous avez été invité par [Calizy_customer_name] à rejoindre Calizy Pro pour faciliter la prise de RDV.',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE': 'Calizy Pro va vous permettre de synchroniser votre calendrier professionnel pour optimiser vos RDV. Pour accéder à ' +
        'votre compte, rien de plus simple :',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE1': 'Téléchargez l’application',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE1.1': 'disponible pour Iphone et Android',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2': 'Confirmer votre compte',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2.1': 'en renseignant',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2.2': 'votre email et le code [XXXXX]',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE3': 'Définissez votre',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE3.1': 'mot de passe personnel',
    'ONBOARDING.INVITATION.PREVIEW.CONGRATS1': 'Félicitations ! Vous pouvez désormais',
    'ONBOARDING.INVITATION.PREVIEW.CONGRATS1.1': 'synchroniser votre calendrier professionnel',
    'ONBOARDING.INVITATION.PREVIEW.CONGRATS1.2': 'avec Calizy Pro et',
    'ONBOARDING.INVITATION.PREVIEW.CONGRATS1.3': 'retrouver tous vos évènement dans l’application.',
    'ONBOARDING.INVITATION.PREVIEW.QUESTIONS1': 'Vous avez des questions ? N’hésitez pas à nous contacter à l’adresse',
    'ONBOARDING.INVITATION.PREVIEW.QUESTIONS1.1': 'nous serons ravis de vous aider.',
    'ONBOARDING.INVITATION.PREVIEW.FOOTER': 'Vous ne voulez pas recevoir notre email? Cliquez ici pour vous désabonner',

    'ONBOARDING.INVITATION.WORKERS.MESSAGE1': 'Cette invitation sera envoyée à',
    'ONBOARDING.INVITATION.WORKERS.MESSAGE1.1': 'toutes vos ressources',

    'ONBOARDING.INVITATION.FINALE.SET.TITLE': 'Vous êtes prêt',
    'ONBOARDING.INVITATION.FINALE.SET.TEXT': 'Vous êtes prêt à utiliser Calizy et à planifier votre premier rendez-vous',
    'ONBOARDING.INVITATION.FINALE.SET.BUTTON': 'Planifier un rendez-vous',

    'ONBOARDING.POPUP.PROGRESS.TITLE': 'Envoi des données en cours',
    'ONBOARDING.POPUP.PROGRESS.TEXT': 'Veuillez patienter quelques minutes',

    'ONBOARDING.NO.ITEMS.PARTNERS': 'Vous devez ajouter une société partenaire',
    'ONBOARDING.NO.ITEMS.TECH': 'Vous devez ajouter un technicien',
    'ONBOARDING.NO.ITEMS.ADVISOR': 'Vous devez ajouter un conseiller',
    'ONBOARDING.NO.ITEMS.BRANCH': 'Vous devez ajouter une agence',
    'ONBOARDING.NO.ITEMS.HUB': 'Vous devez ajouter un hub',
    'ONBOARDING.NO.ITEMS.APPT': 'Vous devez ajouter un type de RDV',
};
