<template>
<div class='b-consult-appts-main h-flex h-flex-dir-column'
     :class='{
         "b-consult-appts-main--open": isOpen
     }'>
    <div class='b-consult-appt-wrapper'>
        <div :class='{ "b-consult-appts-main--has-result": appointmentResultListData.length }'
             class='b-consult-appts__left h-flex h-flex-dir-column h-align-items-center'>
            <h1 class='h-width-100p h-ph-20 h-pt-10 h-text-center'>
                {{ $t('CONSULT.APPOINTMENT.SEARCH.TITLE') }}
            </h1>
            <div class='b-consult-appointments__form__wrapper'
                 :class='{
                     "b-consult-appointments__form__wrapper--static": isStatic && !isOpen,
                     "b-consult-appointments__form__wrapper--open": isOpen
                 }'>
                <form class='b-consult-appointments__form qa-consult-appointments-form'
                      @keyup.enter='onSubmit(currentPage, perPage)'>
                    <div class='b-consult-appointments__form__inner'>
                        <FwFormInput
                            class='h-flex-1-0 h-m-10 qa-input-client-name'
                            labelType='label-medium'
                            :title='$t(`CONSULT.APPOINTMENT.SEARCH.CLIENT.FULLNAME`)'>
                            <FwInput
                                :value='client_name'
                                type='search'
                                name='text'
                                inputStyle='white-bigger-shadow'
                                :iconRight='client_name ? `close` : null'
                                iconSize='16'
                                :placeholder='$t(`CONSULT.TYPE.NAME.PLACEHOLDER`)'
                                @update:value='value => client_name = value'>
                            </FwInput>
                        </FwFormInput>
                        <FwFormInput
                            class='h-flex-1-0 h-m-10 qa-input-from'
                            labelType='label-medium'
                            :title='$t(`GLOBAL.FROM`)'>
                            <FwDatepicker
                                v-model='dateFrom'
                                name='datepicker'
                                :placeholder='$t(`GLOBAL.FROM`)'
                                :propsRange='false'
                                :propsLang='`${$i18n.locale}`'
                                :selectMode='false'
                                pickerStyle='form'
                                hideIcon
                                propsType='date'
                                propsFormat='DD/MM/YYYY'
                                :openOnTop='false'
                                propsInputClass='b-add-reserved-slot-form__datepicker'
                                :disabledDate='disabledDateFrom'
                                @selectDate='updatePaginationData'>
                                <template v-if='dateFrom' #icon>
                                    <FwIcon
                                        class='h-pointer'
                                        icon='close'
                                        size='16'
                                        color='#b1bbca'
                                        @click.native='dateFrom = ``'>
                                    </FwIcon>
                                </template>
                            </FwDatepicker>
                        </FwFormInput>
                        <FwFormInput
                            class='h-flex-1-0 h-m-10 qa-input-to'
                            labelType='label-medium'
                            :title='$t(`GLOBAL.TO`)'>
                            <FwDatepicker
                                v-model='dateTo'
                                name='datepicker'
                                :placeholder='$t(`GLOBAL.TO`)'
                                :propsRange='false'
                                :propsLang='`${$i18n.locale}`'
                                :selectMode='false'
                                pickerStyle='form'
                                hideIcon
                                propsType='date'
                                propsFormat='DD/MM/YYYY'
                                :openOnTop='false'
                                propsInputClass='b-add-reserved-slot-form__datepicker'
                                :disabledDate='disabledDateTo'
                                @selectDate='updatePaginationData'>
                                <template v-if='dateTo' #icon>
                                    <FwIcon
                                        class='h-pointer'
                                        icon='close'
                                        size='16'
                                        color='#b1bbca'
                                        @click.native='dateTo = ``'>
                                    </FwIcon>
                                </template>
                            </FwDatepicker>
                        </FwFormInput>
                        <FwFormInput
                            class='h-flex-1-0 h-m-10 qa-input-advisor-name'
                            labelType='label-medium'
                            :title='isPartnerFlow ? `${$t(`CONSULT.APPOINTMENT.SEARCH.TECH.NAME`)}` : $t(`CONSULT.APPOINTMENT.SEARCH.ADVISOR.NAME`)'>
                            <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                <FwSelect
                                    v-model='advisorName'
                                    :propsPlaceholder='`${$t(`CONSULT.TYPE.ADVISOR.PLACEHOLDER`)}`'
                                    type='white'
                                    :options='advisorsNameArray'
                                    :searchable='true'
                                    noElementsFoundText=''
                                    :loading='isLoadingAdvisor'
                                    :show-no-results='false'
                                    :clear-on-select='false'
                                    :hide-selected='true'
                                    openDirection='bottom'
                                    showRightIcon
                                    @search-change='searchAdvisor'>
                                    <template #noOptions>
                                        {{ $t('LABEL.NO.RESULT') }}
                                    </template>
                                    <template #noResult>
                                        {{ $t('LABEL.NO.RESULT') }}
                                    </template>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                        <FwFormInput
                            v-if='!isHubsFlow'
                            class='h-flex-1-0 h-m-10 qa-input-branch-names'
                            labelType='label-medium'
                            :title='isPartnerFlow ? `${$t(`CONSULT.COMPANY.NAME`)}` : $t(`CONSULT.BRANCH.NAME`)'>
                            <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                <FwSelect
                                    v-model='branchName'
                                    :propsPlaceholder='$t(`CONSULT.TYPE.ADVISOR.PLACEHOLDER`)'
                                    type='white'
                                    :options='branchesNameArray'
                                    :searchable='true'
                                    :loading='isLoadingBranch'
                                    noElementsFoundText=''
                                    :show-no-results='false'
                                    :clear-on-select='false'
                                    :hide-selected='true'
                                    openDirection='bottom'
                                    showRightIcon
                                    @search-change='searchBranch'>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                        <FwFormInput
                            class='h-flex-1-0 h-m-10'
                            labelType='label-medium'
                            :title='$t(`CONSULT.BRANCHES.REFERENCE`)'>
                            <FwInput
                                :value='reference'
                                type='search'
                                name='text'
                                inputStyle='white-bigger-shadow'
                                :iconRight='reference ? `close` : null'
                                iconSize='16'
                                :placeholder='$t(`CONSULT.TYPE.REFERENCE.PLACEHOLDER`)'
                                @update:value='value => reference = value'>
                            </FwInput>
                        </FwFormInput>
                    </div>
                </form>
                <div class='b-nav__buttons_wrapper b-nav__buttons_wrapper--line'>
                    <FwButton
                        :disabled='isButtonDisabled'
                        class='qa-button-appointment-search'
                        borderRadiusType='small-border'
                        @click='onSubmit(currentPage, perPage)'>
                        {{ $t('BUTTON.SEARCH') }}
                    </FwButton>
                    <div class='b-add-edit__form__delete h-mt-15'
                         @click='clearInputs'>
                        {{ $t('BUTTON.CLEAR.ALL') }}
                    </div>
                </div>
            </div>
        </div>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-mt-40 loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <div class='b-consult-appts__right h-flex h-width-100p h-flex-dir-column h-align-items-center'>
            <div class='b-appointment-types__list__wrapper h-width-100p h-mb-60'>
                <div v-if='!appointmentResultListData.length' class='h-flex h-flex-justify-center'>
                    <div v-if='!loading' class='h-flex h-flex-dir-column h-align-items-center h-mt-40'>
                        <FwIcon
                            class='h-pointer'
                            icon='loupe'
                            size='45'
                            color='#BEC7D4'>
                        </FwIcon>
                        <h2 class='b-appt-result__title'>
                            {{ isNoResult ? $t('LABEL.NO.RESULT') : $t('LABEL.RESULT.HERE.INFO') }}
                        </h2>
                    </div>
                </div>
                <div class='h-width-100p h-flex h-flex-dir-column h-flex-center'>
                    <CalendarTable
                        v-if='!loading && appointmentResultListData.length'
                        :titles='appointmenTitleListKeys'
                        :titleHeader='`${$tc(`SETTINGS.TEAM.PEOPLE.RESULTS.PLURAL`, totalPage)} (${totalPage})`'
                        :data='appointmentResultListData'
                        :bigIndexes='bigIndexes'
                        class='b-appointment__table__wrapper'>
                        <div
                            v-for='(item, i) in appointmentResultListData'
                            :key='i'
                            class='b-calendar__table'
                            :class='{ "h-pointer": canRead }'
                            @click='canRead ? addAppointmentData(item.id) : null'>
                            <div class='b-appointment__table__outer'>
                                <div v-for='(key, j) in mapping'
                                     :key='j'
                                     class='b-appointment__table__item'
                                     :class='{ "b-calendar__table__item--big": bigIndexes.includes(j) }'>
                                    <template v-if='item[key] && item[key].length'>
                                        {{ getItemText(item, key) }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </CalendarTable>
                    <FwPagination
                        v-if='appointmentResultListData && appointmentResultListData.length && totalPage > perPage'
                        style='position: absolute; bottom: 0;'
                        :totalPage='totalPage'
                        :currentPage='currentPage'
                        :perPage='perPage'
                        :value='perPage'
                        @getData='onSubmit'>
                    </FwPagination>
                </div>
            </div>
        </div>
    </div>
    <AddOrEditAppointmentByForm
        v-if='isAppointmentPopup'
        class='b-consult-appts-main__popup'
        :appointmentData='appointmentData'
        :loading='isLoadingAppt'
        @close='isAppointmentPopup = false'
        @updateAppointmentData='updateAppointmentData'
        @cancel='removeAppointment'>
    </AddOrEditAppointmentByForm>

    <div v-if='isMobile() && !isStatic'
         class='b-consult-appointments__form__arrow'
         @click='isOpen = !isOpen'>
        <FwIcon
            icon='arrow-right'
            size='24px'
            color='#fff'>
        </FwIcon>
    </div>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { AppointmentConsultSearchType } from '@/types/Appointment';
import { AddOrEditAppointmentByForm } from '@/views/consult/add-appointment/AddOrEditAppointmentByForm';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { CalendarTable } from '@/components/common/CalendarTable';
import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';
import {
    formatDateDayMonthYearByLocale,
    isValidDate,
    dateToString,
} from '@/helpers/dates';
import { capitalizeAll } from '@/helpers/string';
import {
    APPOINTMENT_INFORMATION_NAME,
    APPOINTMENT_INFORMATION_DATE,
    APPOINTMENT_INFORMATION_ADVISOR_NAME,
    APPOINTMENT_INFORMATION_BRANCH_NAME,
    APPOINTMENT_INFORMATION_REFERENCE_NAME,
} from '@/helpers/appointment';
import DateMixin from '@/mixins/dateMixin';

const KEYS_FOR_CAPITALIZE = [`client_details`, `advisor_last_name`, `technician_last_name`, `advisor_last_name`, `company_title`, `branch_name`];

@Component({
    components: {
        AddOrEditAppointmentByForm,
        CalendarTable,
    },
})
export default class ConsultAppointmentsPage extends Mixins(AccountMixin, PermissionsMixin, DateMixin) {
    dateFrom: string = '';
    dateTo: string = '';

    isPageToUpdate: boolean = false;
    // @ts-ignore-next-line
    [APPOINTMENT_INFORMATION_NAME]: string = '';
    // @ts-ignore-next-line
    [APPOINTMENT_INFORMATION_DATE]: string = '';
    // @ts-ignore-next-line
    [APPOINTMENT_INFORMATION_ADVISOR_NAME]: string = '';
    // @ts-ignore-next-line
    [APPOINTMENT_INFORMATION_BRANCH_NAME]: string = '';
    // @ts-ignore-next-line
    [APPOINTMENT_INFORMATION_REFERENCE_NAME]: string = '';
    isAppointmentPopup: boolean = false;
    isNoResult: boolean = false;
    advisorName: string = '';
    branchName: string = '';
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 6;
    timeout: number | NodeJS.Timeout = 0;
    loading: boolean = false;
    isStatic: boolean = true;
    isOpen: boolean = false;
    isLoadingAdvisor: boolean = false;
    isLoadingBranch: boolean = false;
    isLoadingAppt: boolean = false;
    branchesNameArray: Array<string> = [];
    advisorsNameArray: Array<string> = [];
    bigIndexes: Array<number> = [];

    appointmenTitleListKeys: Array<string> = [];
    mapping: Array<string> = [];
    appointmentData: AppointmentConsultSearchType | null = null;

    appointmentResultListData: Array<AppointmentConsultSearchType> = [];

    get isPartnerFlow() {
        return this.user().account_kind === 'partner';
    }

    get isHubsFlow() {
        return this.user().account_organization_type === `with_hubs`;
    }
    get clientName() {
        return this.client_name ? `&client_last_name=${this.client_name}` : '';
    }

    get dateToNearNight(): Date {
        return this.getDayDateNearNight(new Date(this.dateTo));
    }

    get apptWithDate() {
        return `${this.dateFrom ? `&from=${dateToString(new Date(this.dateFrom), true)}` : ''}${this.dateTo ? `&to=${dateToString(new Date(this.dateToNearNight))}` : ''}`;
    }

    get workerNameByKind() {
        return this.isAccountKindIndividual ? `&advisor_last_name=${this.advisorName}` : `&technician_last_name=${this.advisorName}`;
    }

    get companyNameByKind() {
        return this.isAccountKindIndividual ? `&branch_name=${this.branchName}` : `&company_name=${this.branchName}`;
    }

    get advisorNameValue() {
        return this.advisorName ? this.workerNameByKind : '';
    }

    get branchNameValue() {
        return this.branchName ? this.companyNameByKind : '';
    }

    get referenceCode() {
        return this.reference ? `&reference_code=${this.reference}` : '';
    }

    get maxDurationDaysToSet(): number {
        const date = this.dateTo || this.dateFrom;
        return dayjs(date || new Date()).daysInMonth();
    }

    get disabledDateFrom() {
        return {
            disablePrevDays: this.dateTo ? new Date(this.dateTo).setDate(new Date(this.dateTo).getDate() - this.maxDurationDaysToSet + 1) : null,
        };
    }

    get disabledDateTo() {
        return {
            disablePrevDays: this.dateFrom ? new Date(this.dateFrom) : null,
            disableNextDays: (
                this.dateFrom ?
                    new Date(this.dateFrom).setDate(new Date(this.dateFrom).getDate() + this.maxDurationDaysToSet + 1) :
                    new Date().setDate(new Date().getDate() + this.maxDurationDaysToSet + 1)
            ),
        };
    }

    get searchTypeText() {
        return this.isPartnerFlow ? 'company' : 'branch';
    }

    get isAccountKindIndividual() {
        return this.user().account_kind === `individual`;
    }

    get isButtonDisabled(): boolean {
        if ((this.dateFrom && !this.dateTo) || (!this.dateFrom && this.dateTo)) {
            return true;
        }
        return !this.url;
    }

    get url() {
        return `${this.clientName}${this.apptWithDate}${this.branchNameValue}${this.advisorNameValue}${this.referenceCode}`;
    }

    removeAppointment(id: string) {
        if (id) {
            this.onSubmit(this.currentPage, this.perPage);
        }
        this.isAppointmentPopup = false;
        this.reference = '';
    }

    updatePaginationData() {
        this.isPageToUpdate = true;
        this.isNoResult = false;
        this.appointmentResultListData = [];
    }

    updateAppointmentData(data: AppointmentConsultSearchType | null) {
        this.appointmentData = data;
    }

    async addAppointmentData(id: string) {
        this.appointmentData = null;
        try {
            this.isLoadingAppt = true;
            this.isAppointmentPopup = true;
            const data = await AppointmentWebApi.getAppointment(id as string);
            this.appointmentData = data.appointment;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoadingAppt = false;
        }
    }

    async getAdvisorTechDataBySearch(searchText: string) {
        this.isLoadingAdvisor = true;
        const data = await AppointmentWebApi.getAdvisorTechLastAutocomplete(searchText);
        this.isLoadingAdvisor = false;
        this.advisorsNameArray = data.last_name_autocomplete;
    }

    async getBranchCompanyDataBySearch(searchText: string) {
        this.isLoadingBranch = true;
        const data = await AppointmentWebApi.getBranchTitlesAutocomplete(this.searchTypeText, searchText);
        this.isLoadingBranch = false;
        this.branchesNameArray = this.isAccountKindIndividual ? data.branch_titles_autocomplete : data.company_titles_autocomplete;
    }

    clearInputs() {
        this.client_name = '';
        this.dateFrom = '';
        this.dateTo = '';
        this.advisorName = '';
        this.branchName = '';
        this.reference = '';
        this.appointmentResultListData = [];
    }

    getItemText(value: { [key: string]: string }, key: string) {
        if (!value[key]) {
            return ``;
        }
        const validDate = key === `date_start` && isValidDate(new Date(value[key]));
        if (validDate) {
            return formatDateDayMonthYearByLocale(new Date(value[key]), this.$i18n.locale);
        }
        return KEYS_FOR_CAPITALIZE.includes(key) ? capitalizeAll(value[key]) : value[key];
    }

    searchBranch(query: string) {
        if (query.length >= 3) {
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                this.getBranchCompanyDataBySearch(query);
            }, 500);
        }
    }

    searchAdvisor(query: string) {
        if (query.length >= 3) {
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                this.getAdvisorTechDataBySearch(query);
            }, 500);
        }
    }

    async onSubmit(el: number, val: number) {
        if (this.isButtonDisabled) return;
        this.isNoResult = false;
        try {
            if (this.url) {
                this.loading = true;
                const payload: any = {
                    current_page: el,
                    per_page: val,
                };
                if (this.isPageToUpdate) {
                    payload.current_page = 1;
                }
                const data = await AppointmentWebApi.getAppointmentsBySearch(payload, this.url);
                if (data.searches && !data.searches.length && !data.meta) {
                    this.appointmentResultListData = [];
                    this.totalPage = 0;
                    this.currentPage = 1;
                    this.isNoResult = true;
                } else {
                    this.appointmentResultListData = data.appointments;
                    this.loading = false;
                    this.totalPage = data.meta.total_count;
                    this.currentPage = data.meta.current_page;
                    this.isStatic = false;
                    if (!this.appointmentResultListData.length) {
                        this.isNoResult = true;
                    }
                }
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
            this.isPageToUpdate = false;
        }
    }

    @Watch('dateFrom')
    dateFromHandler(dateFrom: string) {
        if (!this.dateTo) {
            this.dateTo = dateFrom;
        } else if (dateFrom && this.dateTo < dateFrom) {
            this.dateTo = dateFrom;
        }
    }

    @Watch('dateTo')
    dateToHandler(dateTo: string) {
        if (!this.dateFrom) {
            this.dateFrom = dateTo;
        } else if (dateTo && this.dateFrom > dateTo) {
            this.dateFrom = dateTo;
        }
    }

    created() {
        if (this.$route.query.id && typeof this.$route.query.id === `string`) {
            this.addAppointmentData(this.$route.query.id);
        }

        this.mapping = this.isHubsFlow ? [
            `client_details`,
            `date_start`,
            `advisor_last_name`,
            `reference_code`,
        ] : [
            `client_details`,
            `date_start`,
            `${this.isPartnerFlow ? 'technician_last_name' : 'advisor_last_name'}`,
            `${this.isPartnerFlow ? 'company_title' : 'branch_name'}`,
            `reference_code`,
        ];

        this.appointmenTitleListKeys = this.isHubsFlow ? [
            'CONSULT.APPOINTMENT.SEARCH.CLIENT.FULLNAME',
            'Date',
            `CONSULT.APPOINTMENT.SEARCH.ADVISORS.NAME`,
            'CONSULT.APPOINTMENT.SEARCH.REFERENCE',
        ] :
            [
                'CONSULT.APPOINTMENT.SEARCH.CLIENTS.NAME',
                'Date',
                `${this.isPartnerFlow ? `CONSULT.APPOINTMENT.SEARCH.TECHS.NAME` : `CONSULT.APPOINTMENT.SEARCH.ADVISORS.NAME`}`,
                `${this.isPartnerFlow ? `CONSULT.COMPANIES.NAME` : `CONSULT.BRANCHES.NAME`}`,
                'CONSULT.APPOINTMENT.SEARCH.REFERENCE',
            ];
    }
}
</script>

<style lang='sass'>
.b-consult-appts-main
    height: 100%
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: center

    &--open
        @include media('<=phone')
            .b-consult-appointments__form__arrow
                transform: rotate(180deg)

                z-index: 51

    &__popup
        .fw-popup
            min-height: 620px
            justify-content: start !important

    .multiselect__single, .multiselect__tags input
        font-weight: 500

    .b-consult-appt-wrapper
        max-width: 1300px
        width: 100%

    .b-appt-result__title
        color: rgba(33,63,107,0.5)
        font-weight: 500

    .b-appt-button__clear
        text-transform: uppercase
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

.b-consult-appointments__form
    width: 100%
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

    &__inner
        display: flex
        width: 100%

        @include media('<=phone')
            flex-direction: column

    &__row
        display: flex
        width: 100%
        margin-bottom: 20px

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-consult-appointments__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7

    .mx-datepicker
        background: #ffffff
        min-height: 60px
        border-radius: 10px
        font-weight: 500 !important
        box-shadow: $big-box-shadow
        line-height: unset
        .mx-datepicker-popup
            width: 350px
        .b-add-reserved-slot-form__datepicker
            &::placeholder
                font-size: 16px
                color: rgba(33,63,107,0.5)
                font-weight: 500

    .multiselect--active
        .fw-select__caret
            opacity: 0!important

.b-appointment-types
    &__search
        width: 350px

    &__sort
        width: 350px

        &__wrapper
            margin-top: 10px
            display: flex
            justify-content: space-between
            align-items: baseline

    &__list
        &__wrapper
            width: 100%
            height: 100%

        &__select
            width: 100%

        &__flex
            display: flex
            margin-bottom: 10px

            &--disabled
                opacity: 0.5
                position: relative
                &:after
                    content: ''
                    position: absolute
                    left: 0
                    top: 0
                    cursor: not-allowed
                    width: 100%
                    height: 100%

            &--edit
                .b-settings-reminder-types__list__type
                    padding: 0
                .fw-select
                    box-shadow: none !important

            &--head
                .b-settings-reminder-types__list__type
                    padding: 0 0 0 0
                    color: #213F6B
                    font-size: 14px
                    font-weight: 500

                .b-settings-reminder-types__list__icon
                    background-color: transparent

        &__icon
            flex: 0 0 53px
            margin-left: 10px
            display: flex
            align-items: center
            justify-content: center
            background-color: #fff
            border-radius: 5px

            &__wrapper
                height: 100%
                width: 100%
                display: flex
                align-items: center
                justify-content: center
                flex-direction: column
                cursor: pointer
                &:hover
                    opacity: 0.9

            &__success
                background-color: #27DBBD
                color: #fff
                flex-direction: column

            &__cancel
                background-color: #EB2767
                color: #fff
                flex-direction: column

            &__text
                font-size: 9px
                text-transform: uppercase
                color: #fff
                margin-top: 7px
                font-weight: 500

        &__type
            flex: 1 0
            color: #203f6a
            display: flex
            font-weight: 500
            padding: 16px 10px 16px 20px

            &--big
                flex: 2 0

        &__white-bg
            background-color: #fff
            border-radius: 5px
            display: flex
            width: 100%

.b-appointment__table__outer
    background-color: #ffffff
    display: flex
    flex: 1
    border-radius: 5px
    height: 60px

    @include media('<=phone')
        height: auto

.b-appointment__table__item
    flex: 1 0
    width: auto
    display: flex
    align-items: center
    justify-content: space-between
    margin: 10px
    font-weight: 500
    overflow: hidden
    text-overflow: ellipsis
    text-align: left

.b-appointment__table__wrapper
    width: 100%
    max-width: 100%
    height: 100%

    .b-calendar__table__title
        padding: 0 10px

    .b-calendar__table__header__item
        text-transform: none
        flex: 1 0
        padding: 10px
        min-width: auto
        max-width: none

        @include media('<=phone')
            padding: 7px 2px

.b-consult-appointments__form
    &__wrapper
        display: flex
        flex-wrap: wrap
        justify-content: center
        width: 100%

        @include media('<=phone')
            position: fixed
            left: 0
            z-index: 1
            padding: 10px
            width: calc(100vw - 20px)
            transform: translate(-100%, 0)
            overflow: visible
            transition: transform
            min-height: calc(100vh - 100px)
            background-color: #ffffff
            overflow: visible
            top: 0
            height: 100vh

            &--open
                transform: none
                z-index: 50
                overflow-y: auto

                .b-consult-appointments__form__arrow
                    z-index: 51

            &--static
                position: static
                width: 100%
                transform: none
                background-color: transparent

            &:not(.b-consult-appointments__form__wrapper--static)
                &:before
                    content: ''
                    right: 0
                    width: 20px
                    top: 0
                    height: 100%
                    background-color: rgba(0, 0, 0, 0.7)
                    position: fixed

    &__arrow
        display: none

        @include media('<=phone')
            display: block !important
            left: 20px
            bottom: 60px
            position: fixed
            padding: 10px 12px
            border-radius: 8px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
            background-color: #213F6B
            z-index: 12

            span
                display: flex

            &--open
                transform: none

                .b-settings-nav__arrow
                    left: 20px
                    z-index: 51
</style>
