import { RawLocation } from 'vue-router';
import { pickBy } from 'ramda';

function updateUrlGetParams(url: string, params: { [key: string]: string | number} | {}) {
    let newUrl;
    if (url.startsWith(`http`)) {
        newUrl = new URL(url);
    } else {
        newUrl = new URL(url, new URL(window.location.origin));
    }

    const { searchParams } = newUrl;
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        // @ts-ignore-next-line
        if (params[key] !== null) {
            // @ts-ignore-next-line
            searchParams.set(key, String(params[key]));
        }
    }
    return newUrl.href;
}

function updateUrlPageAndQuery(url: string, page?: number, query?: { [key: string]: string | boolean } | undefined): RawLocation {
    const pagePostFix = !page || page === 1 ? '' : `/${page}`;
    return { path: `${url}${pagePostFix}`, query: query ? pickBy(param => !!param, query) : query };
}

export {
    updateUrlPageAndQuery,
    updateUrlGetParams,
};
