<template>
<div class='container'>
    <div class='b-base-title__wrapper'>
        <h2 v-if='$route.meta.pageTitle'
            class='b-appointment-left-bar__title'>
            {{ $route.meta.pageTitle }}
        </h2>
    </div>
    <div class='b-choose__wrapper'>
        <router-link
            :to='`${mainRouteGlobal}/categories/add-category`'
            class='b-choose'>
            <FwImage
                :src='requireImage("manual.svg")'>
            </FwImage>
            <h2 class='b-choose__title h-mt-25'>
                {{ $t('ONBOARDING.CHOOSE.CATEGORY.MANUAL.TITLE') }}
            </h2>
            <div class='b-choose__aside'>
                {{ $t('ONBOARDING.CHOOSE.CATEGORY.MANUAL.DESCRIPTION') }}
            </div>
        </router-link>

        <a
            class='b-choose'
            @click.prevent='showComingSoonPopup = true'>
            <FwImage
                :src='requireImage("import-file.svg")'>
            </FwImage>
            <h2 class='b-choose__title h-mt-25'>
                {{ $t('ONBOARDING.CHOOSE.CATEGORY.FILE.TITLE') }}
            </h2>
            <div class='b-choose__aside'>
                {{ $t('ONBOARDING.CHOOSE.CATEGORY.FILE.DESCRIPTION') }}
            </div>
        </a>
    </div>

    <portal v-if='showComingSoonPopup'
            to='popupWrapper'>
        <ComingSoonPopup @close='showComingSoonPopup = false'></ComingSoonPopup>
    </portal>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ComingSoonPopup } from '@/components/popups/ComingSoonPopup';

@Component({
    components: {
        ComingSoonPopup,
    },
})
export default class AppointmentChooseCategoryPage extends Vue {
    showComingSoonPopup: boolean = false;
}
</script>
