<template>
<div v-if='distance_to_in_km_text || duration_to_in_min_text'
     :class='iconsInnerClass'
     class='h-flex-center'>
    <div v-if='distance_to_in_km_text'
         :class='iconsInnerClass ? "h-align-items-end" : "h-flex-justify-end h-mh-15"'
         class='h-width-100p h-flex h-flex-1-0'>
        <FwIcon
            class='h-mh-5 h-mr-15 h-pointer h-flex-center'
            icon='icon-go-arrow'
            size='16'
            color='#213F6B'>
        </FwIcon>
        <span>
            {{ distance_to_in_km_text }}
        </span>
    </div>
    <div v-if='duration_to_in_min_text'
         :class='iconsInnerClass ? "h-align-items-end" : "h-flex-justify-start h-mh-15"'
         class='h-width-100p h-flex h-flex-1-0'>
        <FwIcon
            class='h-mh-5 h-mr-15 h-pointer h-flex-center'
            icon='time-icon'
            size='16'
            color='#213F6B'>
        </FwIcon>
        <span>
            {{ duration_to_in_min_text }}
        </span>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BookingFormDistance extends Vue {
    @Prop({ type: String, default: null }) readonly iconsInnerClass!: string;
    @Prop({ type: String, default: null }) readonly distance_to_in_km_text!: string | null;
    @Prop({ type: String, default: null }) readonly duration_to_in_min_text!: string | null;
}
</script>
