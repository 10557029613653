<template>
<div class='b-calendar__table__inner'>
    <div v-if='titleHeader' class='b-calendar__table__title'>
        {{ titleHeader }}
    </div>
    <div class='b-calendar__table__header h-flex h-width-100p h-flex-center'>
        <div v-for='(title, index) in titles'
             class='b-calendar__table__header__item'
             :class='{ "b-calendar-table__table__header__item--big": bigIndexes.includes(index) }'>
            <p class='h-font-14 h-fw-500 h-m-0 h-flex h-flex-justify-start h-flex-center'>
                {{ $t(title) }}
            </p>
        </div>
    </div>
    <slot></slot>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class CalendarTable extends Vue {
    @Prop({ type: Array, required: true }) readonly titles!: Array<string>;
    @Prop({ type: String, default: null }) readonly titleHeader!: string;
    @Prop({ type: Array, required: true }) readonly bigIndexes!: Array<number>;
    @Prop({ type: Array, required: true }) readonly data!: Array<{ [key: string]: any }>;
}
</script>

<style lang='sass'>
$min-cell-width: 190px
$min-cell-medium-width: 240px
$min-cell-big-width: 320px

.b-calendar__table
    &__title
        font-size: 25px
        font-weight: bold
        display: flex
        justify-content: space-between
        width: 100%
        align-items: center
    &__wrapper
        .fw-horizontal-scroll__inner
            height: auto !important
            padding-bottom: 60px

    &__buttons
        position: sticky
        right: 0
        display: flex
        background-color: #F6F8F9
        box-shadow: 3px 3px 10px #F6F8F9
        &:before
            content: ''
            left: -40px
            width: 40px
            top: 0
            position: absolute
            background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(246,248,249,0.74) 26%,rgba(246,248,249,1) 100%)
            height: 100%

        &:after
            content: ''
            right: 0
            width: 0
            top: 0
            position: absolute
            background: linear-gradient(to left, transparent, #F6F8F9)
            height: 100%

    &__inner
        width: 100%
        max-width: 90%
        margin: 0 auto
        height: 100%

        .fw-select-white, .fw-input--white-bigger-shadow
            box-shadow: none !important

    display: flex
    margin-bottom: 10px
    align-items: center

    &__outer
        background-color: #ffffff
        display: flex
        flex: 1
        border-radius: 5px
        height: 60px
        padding-right: 30px

    &__item
        flex: 2 0 $min-cell-width
        display: flex
        align-items: center
        justify-content: space-between
        padding: 6px 10px 6px 30px
        font-weight: 500
        overflow: hidden
        text-overflow: ellipsis
        text-align: left
        max-width: $min-cell-width
        min-width: $min-cell-width

        &.b-calendar__table__item--medium
            flex: 3 0 $min-cell-medium-width
            min-width: $min-cell-medium-width
            max-width: $min-cell-medium-width

        &.b-calendar__table__item--big
            flex: 4 0 $min-cell-big-width
            min-width: $min-cell-big-width
            max-width: $min-cell-big-width

        &__inner
            max-height: 3.6em
            line-height: 1em
            overflow: hidden

        &__delete, &__edit
            display: flex
            justify-content: center
            align-items: center
            background: #ffffff
            height: 60px
            width: 60px
            border-radius: 5px

    &__header
        display: flex
        justify-content: flex-start
        &__item
            text-transform: uppercase
            flex: 2 0 $min-cell-width
            padding: 15px 10px 15px 30px
            min-width: $min-cell-width
            max-width: $min-cell-width
            &.b-calendar-table__table__header__item--big
                flex: 4 0 $min-cell-big-width
                min-width: $min-cell-big-width
                max-width: $min-cell-big-width
</style>
