<template>
<div>
    <router-view></router-view>
    <ProgressBarWrapper v-if='showFooterProgress'></ProgressBarWrapper>
    <AppointmentDetailsCard></AppointmentDetailsCard>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ProgressBarWrapper } from '@/components/global/ProgressBarWrapper';
import { AppointmentDetailsCard } from '@/components/simple/AppointmentDetailsCard';

@Component({
    components: {
        ProgressBarWrapper,
        AppointmentDetailsCard,
    },
})
export default class AppointmentWrapper extends Vue {
    get showFooterProgress() {
        return this.$route.meta && (Boolean(this.$route.meta.breadCrumbsText) || this.isRecapRoute) && !this.$route.meta.hideBreadCrumbs;
    }
}
</script>
