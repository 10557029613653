<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        class='b-settings-popup'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mb-20'>
                    {{ $t('POPUPS.SETTINGS.TITLE') }}
                </h2>
            </div>
            <FwLabelCard v-if='false' class='h-mb-10 b-settings-popup__label'>
                <div class='h-flex h-flex-center h-flex-space-between h-height-100p '>
                    {{ $t('POPUPS.SETTINGS.STATISTICS') }}
                    <FwSwitcher
                        :isActive='isSettingsActive'
                        :isRequestSending='isSettingsActiveRequestSettings'
                        @toggleSwitch='isSettingsActive = !isSettingsActive'>
                    </FwSwitcher>
                </div>
            </FwLabelCard>

            <FwLabelCard class='b-settings-popup__label h-mb-20'>
                <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                    {{ $t('POPUPS.SETTINGS.ACCESSIBILITY_MODE') }}
                    <FwSwitcher
                        :isActive='isColorblindActive'
                        :isRequestSending='isColorblindActiveRequestSettings'
                        @toggleSwitch='isColorblindActive = !isColorblindActive'>
                    </FwSwitcher>
                </div>
            </FwLabelCard>
            <FwButton
                class='h-margin-0-auto'
                borderRadiusType='tiny-border'
                :disabled='isButtonDisabled'
                @click='updateSettings'>
                {{ $t('BUTTON.SAVE') }}
            </FwButton>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { clone } from 'ramda';
import { CalendarSettingsType } from '@/types/Calendar';
import { isEqual } from '@/helpers/base';

@Component
export default class SettingsPopup extends Vue {
    @Prop({ type: String, required: true }) readonly calendarSettings!: CalendarSettingsType;

    isColorblindActive: boolean = false;
    isSettingsActive: boolean = false;
    isSettingsActiveRequestSettings: boolean = false;
    isColorblindActiveRequestSettings: boolean = false;

    startSettingsState: CalendarSettingsType = { isColorblindActive: false, isSettingsActive: false };

    get isButtonDisabled(): boolean {
        return isEqual(this.currentSettingsState, this.startSettingsState);
    }

    get currentSettingsState(): CalendarSettingsType {
        return {
            isColorblindActive: this.isColorblindActive,
            isSettingsActive: this.isSettingsActive,
        };
    }

    updateSettings(): void {
        this.$emit('updateSettings', this.currentSettingsState);
        this.$emit('close');
    }

    created(): void {
        this.startSettingsState = clone(this.calendarSettings);
        this.isColorblindActive = this.startSettingsState.isColorblindActive;
        this.isSettingsActive = this.startSettingsState.isSettingsActive;
    }
}
</script>

<style lang='sass'>
.b-settings-popup
    width: 420px !important

    &__label
        flex: 1
        font-weight: 500

.b-remove-text
    font-size: 13px
    cursor: pointer
    color: #EB2767
    margin-top: 15px
    font-weight: 500
    text-align: center
    text-transform: uppercase
</style>
