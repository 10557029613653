<template>
<div class='b-add-appointment__wrapper container h-pb-150'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $route.params.id ? $t('PLAN.APPOINTMENT.BUSINESS.PARTNER.EDIT') : $t('PLAN.APPOINTMENT.BUSINESS.PARTNER.ADD') }}
            </h2>
        </div>
        <form @keydown.stop.prevent.enter='submit'>
            <div class='h-flex h-flex-space-between h-mb-30'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-20'
                    :fieldHasError='fieldHasError(COMPANY_NAME)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.COMPANY.NAME`)}*`'>
                    <FwInput
                        :value='company_name'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.COMPANY.NAME`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(COMPANY_NAME)'
                        @update:value='value => company_name = value'
                        @input='clearServerErrorsBase(COMPANY_NAME)'
                        @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError(CODE_INSEE)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.CODE.INSEE`)}`'>
                    <FwInput
                        :value='insee_code'
                        type='number'
                        name='text'
                        :placeholder='$t(`LABEL.CODE.INSEE`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        :error='getFieldErrorText(CODE_INSEE)'
                        @update:value='value => insee_code = value'
                        @input='clearServerErrorsBase(CODE_INSEE)'
                        @blur='addCurrentInputToValidateArray(CODE_INSEE)'>
                    </FwInput>
                </FwFormInput>
            </div>
            <div class='h-flex h-flex-space-between h-mb-30'>
                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError()'
                    :title='`${$t(`LABEL.ADDRESS`)}*`'
                    labelType='label-medium'>
                    <SelectAddress
                        :startAddress='address'
                        :isDisabled='isAddressDisabled'
                        @changePostCode='value => post_code = value'
                        @input='input'>
                    </SelectAddress>
                </FwFormInput>
            </div>

            <div class='h-flex h-flex-space-between h-mb-30'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-20'
                    :fieldHasError='fieldHasError(SIREN)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.CODE.SIREN`)}`'>
                    <FwInput
                        :value='siren'
                        type='number'
                        name='text'
                        :placeholder='$t(`LABEL.CODE.SIREN`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        :error='getFieldErrorText(CODE_INSEE)'
                        @update:value='value => siren = value'
                        @input='clearServerErrorsBase(CODE_INSEE)'
                        @blur='addCurrentInputToValidateArray(CODE_INSEE)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError(SIRET)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.CODE.SIRET`)}`'>
                    <FwInput
                        :value='siret'
                        type='number'
                        name='text'
                        :placeholder='$t(`LABEL.CODE.SIRET`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        :error='getFieldErrorText(SIRET)'
                        @update:value='value => siret = value'
                        @input='clearServerErrorsBase(SIRET)'
                        @blur='addCurrentInputToValidateArray(SIRET)'>
                    </FwInput>
                </FwFormInput>
            </div>

            <div class='h-flex h-flex-space-between h-mb-30'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-20'
                    :fieldHasError='fieldHasError(PHONE_NUMBER)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.PHONE.NUMBER`)}`'>
                    <FwInput
                        :value='phone'
                        type='number'
                        name='text'
                        :placeholder='$t(`LABEL.PHONE.NUMBER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        :error='getFieldErrorText(PHONE_NUMBER)'
                        @update:value='value => phone = value'
                        @input='clearServerErrorsBase(PHONE_NUMBER)'
                        @blur='addCurrentInputToValidateArray(PHONE_NUMBER)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError(EMAIL)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.EMAIL`)}`'>
                    <FwInput
                        :value='email'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.EMAIL`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(EMAIL)'
                        @update:value='value => email = value'
                        @input='clearServerErrorsBase(EMAIL)'
                        @blur='addCurrentInputToValidateArray(EMAIL)'>
                    </FwInput>
                </FwFormInput>
            </div>

            <div class='h-mb-30 b-required-field'>
                * {{ $t(`LABEL.REQUIRED.FIELD`) }}
            </div>
        </form>
    </template>

    <portal to='appointment-bar-buttons'>
        <FwButton
            size='little'
            :disabled='isDisabled'
            borderRadiusType='small-border'
            @click='submit'>
            {{ $route.params.id ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
        </FwButton>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import { AddNewSkillPopup } from '@/components/popups/AddNewSkillPopup/index';
import { AccountOnboarding } from '@/api/company/CompanyApi';
import { ParsedAddressData } from '@/types/GoogleMap';
import { SelectAddress } from '@/components/common/SelectAddress';
import { addPostCodeToAddress } from '@/helpers/address';
import {
    COMPANY_NAME,
    ADDRESS,
    EMAIL,
} from '@/helpers/partner';
import { CompanyPayloadType } from '@/types/Companies';

@Component({
    validators: {
        [COMPANY_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [ADDRESS](value: string) {
            return this.requiredValidator({ value });
        },
        [EMAIL](value: string) {
            return this.emailValidator({ value, notRequired: true });
        },
    },
    components: {
        AddNewSkillPopup,
        SelectAddress,
    },
})
export default class BusinessPartnerAdd extends Mixins(ValidationMixin, TranslateMixin) {
    company_name: string = '';
    // @ts-ignore-next-line
    [COMPANY_NAME]: string = '';
    // @ts-ignore-next-line
    [EMAIL]: string = '';
    EMAIL: string = EMAIL;
    COMPANY_NAME: string = COMPANY_NAME;
    address: string = '';
    insee_code: string = '';
    post_code: string = '';
    siren: string = '';
    siret: string = '';
    country: string = '';
    phone: string = '';
    calizyAddress: string = '';
    email: string = '';
    sendRequest: boolean = false;
    loading: boolean = false;
    postCodeCannotDetect: boolean = false;
    isAddressDisabled: boolean = false;

    serverErrors: serverErrors = {
        [COMPANY_NAME]: null,
        address: null,
        [EMAIL]: this.errorFromServer,
    };

    get isDisabled(): boolean {
        return this.disabled || this.sendRequest;
    }

    get disabled(): boolean {
        if (this.$route.params.id) {
            return false;
        }
        return !(this.company_name && this.address && this.post_code);
    }

    input(parsedAddressData: ParsedAddressData) {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.address;
        this.country = parsedAddressData.country;
        this.calizyAddress = parsedAddressData.calizyAddress;
    }

    async submit() {
        if (this.isDisabled) return;
        this.sendRequest = true;
        // this.isAddressDisabled
        try {
            const payload: CompanyPayloadType = {
                title: (this.company_name as string),
                ...(!this.isAddressDisabled && { address: addPostCodeToAddress(this.calizyAddress, this.post_code) }),
                siren: (this.siren as string),
                zip_code: (this.post_code as string),
                siret: this.siret,
                phone: this.phone,
                contact_email: this.email,
                insee_code: this.insee_code || '',
            };
            if (this.$route.params.id) {
                await AccountOnboarding.putAccountOnboardingCompany(payload, this.$route.params.id);
            } else {
                await AccountOnboarding.postAccountOnboardingCompany(payload);
            }
            if (this.isRecapRoute) {
                this.$router.push({ path: `${this.mainRouteGlobal}/recap/partners` });
            } else {
                this.$router.push({ path: `${this.mainRouteGlobal}/partners` });
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.sendRequest = false;
        }
    }

    async created() {
        this.loading = true;
        if (this.$route.params.id) {
            const { company } = await AccountOnboarding.getAccountOnboardingCompany(this.$route.params.id);
            this.isAddressDisabled = true;
            if (company) {
                this.company_name = company.title!;
                this.address = company.address!;
                // this.post_code = company.address ? parseAddress(company.address).zip_code : '';
                this.insee_code = company.insee_code || '';
                this.siren = company.siren || '';
                this.siret = company.siret || '';
                this.email = company.contact_email || '';
                this.phone = company.phone || '';
            }
        }
        this.loading = false;
    }
}
</script>

<style lang='sass'>
.b-add-appointment
    &__wrapper
        width: 1100px !important
        @include media('<=phone')
            width: 100%

        .b-time-picker
            background: #ffffff
            height: 60px

        .fw-select-address
            .multiselect__content-wrapper
                position: absolute !important
                top: 60px
                min-width: 100% !important
                width: 100% !important
                height: 300px
                .multiselect__content
                    .multiselect__element
                        background-color: #fff !important

    .b-required-field
        font-weight: bold
        margin-bottom: 160px

.h-pb-150
    padding-bottom: 150px
</style>
