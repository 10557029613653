<template>
<div :class='formClassWrapper'
     class='b-appointment-left-bar_form'>
    <GoBackButton
        style='top: 40px; left: 40px'
        class='b-route-wrapper__back_arrow'
        :method='backToFirstStep'
        @click.native.stop.prevent>
    </GoBackButton>
    <form class='h-mt-15'
          :class='formClass'
          @keyup.enter='submitForm'>
        <div class='b-add-appointment-type'>
            <div class='h-text-center h-pos-rel'>
                <h2 class='h-font-20'>
                    {{ $t('SELECT.TYPE.APPOINTMENT.CATEGORY.TITLE') }}
                </h2>
            </div>
            <div class='b-society-selection h-flex-center'>
                <div style='width: 330px' class='fw-select-base fw-select-white-modern'>
                    <FwFormInput
                        class='h-flex-1 h-mt-15 qa-direct-appointment__category'
                        labelType='label-medium'
                        :title='`${$t(`LABEL.CATEGORY`)}`'>
                        <FwSelect
                            v-model='currentAppointmentKind'
                            v-focus='appointmentKinds.length'
                            class='fw-select-white'
                            :clear-on-select='true'
                            :propsPlaceholder='$t(`LABEL.CATEGORY.PLACEHOLDER`)'
                            type='white'
                            :pagination='true'
                            :searchIcon='false'
                            :options='appointmentKinds'
                            :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                            @input='updateAppointmentState'
                            @select='resetForm'>
                        </FwSelect>
                    </FwFormInput>
                </div>
            </div>
            <div class='b-society-selection h-flex-center'>
                <div style='width: 330px' class='fw-select-base fw-select-white-modern'>
                    <FwFormInput
                        class='h-flex-1 h-mt-15 qa-direct-appointment__category-type'
                        labelType='label-medium'
                        :title='`${$t(`LABEL.TYPE`)}`'>
                        <FwSelect
                            :key='currentAppointmentKindState'
                            v-model='value'
                            class='fw-select-white'
                            :clear-on-select='true'
                            :propsPlaceholder='$t(`LABEL.SELECT.TYPE`)'
                            :disabled='!currentAppointmentKind'
                            type='white'
                            :pagination='true'
                            :searchIcon='false'
                            :options='sortedAppointmentCategories'
                            :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                            label='title'
                            @input='fetchDataWithLocationMethod'
                            @select='resetLocationMethod'>
                        </FwSelect>
                    </FwFormInput>
                </div>
            </div>
            <div class='b-society-selection h-flex-center'>
                <div style='width: 330px' class='fw-select-base fw-select-white-modern'>
                    <FwFormInput
                        class='h-flex-1 h-mt-15 qa-direct-appointment__category-method'
                        labelType='label-medium'
                        :title='`${$t(`CALENDAR.METHOD.LABEL`)}`'>
                        <FwSelect
                            v-model='locationMethod'
                            class='fw-select-white'
                            :clear-on-select='true'
                            :propsPlaceholder='$t(`SELFCARE.BOOKING.PLACEHOLDER.METHOD`)'
                            :disabled='!value || isLoading'
                            type='white'
                            :pagination='true'
                            :searchIcon='false'
                            :options='categoryLocationSkillNames'
                            :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                            :label='labelKey'
                            @input='onInput'>
                        </FwSelect>
                    </FwFormInput>
                </div>
            </div>
        </div>
    </form>

    <div class='h-ph-50 h-mt-40'>
        <div class='h-flex h-flex-center'>
            <FwButton
                class='qa-direct-appointment__category-submit'
                borderRadiusType='small-border'
                :disabled='!(currentAppointmentKind && value && locationMethod)'
                @click.native.stop.prevent='toMethodPage'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { OnboardingApi } from '@/api/onboarding/OnboardingApi';

import { GoBackButton } from '@/components/simple/GoBackButton';

import { BookNewAppointmentType } from '@/types/Book';
import {
    AppointmentCategoryType,
    CategoryLocationSkillNameType,
} from '@/types/Appointment';
import {
    BY_PHONE_ID,
    IN_BRANCH_BY_ONLINE_VIDEO,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_VIDEO_PRIVATE_ID, IN_BRANCH_BY_VIDEO_PUBLIC_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    ON_SITE_ID,
} from '@/helpers/appointment';

import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import AppointmentMixin from '@/mixins/appointment';

import { dateToString } from '@/helpers/dates';

const AppointmentStore = namespace('AppointmentStore');

const PRO_PREFIX = 'PRO';

@Component({
    components: {
        GoBackButton,
    },
})
export default class AddNewCategoriesForm extends Mixins(ValidationMixin, TranslateMixin, AppointmentMixin) {
    @AppointmentStore.State('currentAppointmentKindState') currentAppointmentKindState!: string;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('locationName') locationName!: CategoryLocationSkillNameType;
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (categoryName: AppointmentCategoryType | null) => void;
    @AppointmentStore.Mutation('setCurrentAppointmentKind') setCurrentAppointmentKind!: (appointmentKind: string) => void;
    @AppointmentStore.Mutation('setLocationName') setLocationName!: (locationName: CategoryLocationSkillNameType | null) => void;
    @AppointmentStore.Mutation('setAppointmentType') setAppointmentType!: (type: string) => void;
    @AppointmentStore.Mutation('setAppointmentAddress') setAppointmentAddress!: (address: string) => void;
    @AppointmentStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;

    @Prop({ type: String, default: null }) readonly formClassWrapper!: string;
    @Prop({ type: String, default: null }) readonly formClass!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Boolean, default: false }) readonly canUpdateDisabledFields!: boolean;
    @Prop({ type: Array, default: null }) readonly appointmentCategories!: Array<AppointmentCategoryType>;
    @Prop({ type: Date, default: null }) readonly appointmentStartDate!: Date;

    isLoading: boolean = false;
    currentAppointmentKind: null | string = null;
    value: AppointmentCategoryType | null = null;
    locationMethod: CategoryLocationSkillNameType | null = null;
    categoryLocationSkillNames: Array<CategoryLocationSkillNameType> = [];

    routeMapping = {
        [ON_SITE_ID]: '/appointment/address/on-site',
        [BY_PHONE_ID]: '/appointment/postcode',
        [IN_BRANCH_BY_ONLINE_VIDEO]: '/appointment/postcode',
        [IN_BRANCH_VIDEO_ID]: '/appointment/choose-branch',
        [IN_BRANCH_PUBLIC_ID]: '/appointment/choose-branch',
        [IN_BRANCH_PRIVATE_VIDEO_ID]: '/appointment/choose-branch',
        [IN_BRANCH_BY_PHONE_PUBLIC_ID]: '/appointment/choose-branch',
        [IN_BRANCH_BY_PHONE_PRIVATE_ID]: '/appointment/choose-branch',
        [IN_BRANCH_BY_VIDEO_PRIVATE_ID]: '/appointment/choose-branch',
        [IN_BRANCH_BY_VIDEO_PUBLIC_ID]: '/appointment/choose-branch',
    }

    categoriesMappingArr = [
        'location_category_in_branch_public',
        'location_category_in_branch_private',
        'location_category_by_online_video',
        'location_category_in_branch_by_video_public',
        'location_category_in_branch_by_video_private',
        'location_category_by_phone',
        'location_category_in_branch_by_phone_public',
        'location_category_in_branch_by_phone_private',
        'location_category_on_site',
        'location_category_on_site_in_branch',
        'location_category_in_branch_client_video_private',
    ];

    get sortedAppointmentCategories() {
        const categories = this.appointmentCategories.filter(item => !this.currentAppointmentKindState || item.kind === this.currentAppointmentKindState);
        const categoriesPro = categories.filter(item => item.title.startsWith(PRO_PREFIX)).sort((a, b) => a.title.localeCompare(b.title));
        const categoriesWithOut = categories.filter(item => !item.title.startsWith(PRO_PREFIX)).sort((a, b) => a.title.localeCompare(b.title));
        return categoriesWithOut.concat(categoriesPro);
    }

    get appointmentKinds() {
        return [...new Set(this.appointmentCategories.map(item => item.kind))].sort((a, b) => a.localeCompare(b));
    }

    get isDisable(): boolean {
        return !this.isFormValid;
    }

    submitForm() {
        if (!this.isDisable) {
            this.updateAppointmentPayload();
        }
    }

    isFormDisabled(key: string): boolean {
        return Boolean(this.disabledForm) && !this.canUpdateDisabledFields;
    }

    updateAppointmentState() {
        this.setCurrentAppointmentKind(this.currentAppointmentKind as string);
    }

    resetForm() {
        this.value = null;
        this.setAppointmentCategory(null);
        this.setLocationName(null);
        this.locationMethod = null;
    }

    resetLocationMethod() {
        this.locationMethod = null;
        this.setLocationName(null);
    }

    onInput() {
        if (this.locationMethod) {
            this.setLocationName(this.locationMethod);
            this.setAppointmentType(this.locationMethod.searchable_name);
        }
    }

    async fetchDataWithLocationMethod(value: AppointmentCategoryType) {
        this.setAppointmentCategory(value);
        if (!this.value) return;
        this.isLoading = true;
        const data = await OnboardingApi.categoryLocationSkillNamesForAppointment(this.value.id, this.user().id);
        const { locations_skills_for_appointment_category } = data;

        this.isLoading = false;
        this.categoryLocationSkillNames = [];

        this.categoriesMappingArr.forEach(item => {
            locations_skills_for_appointment_category.forEach(item1 => {
                if (item1.searchable_name === item) {
                    this.categoryLocationSkillNames.push(item1);
                }
            });
        });
    }

    @Emit('updateAppointmentPayload')
    updateAppointmentPayload(): BookNewAppointmentType {
        return {
            appointment_category_id: this.value!.id,
            user_id: this.$route.params.id,
            dt_start: dateToString(this.appointmentStartDate),
        };
    }

    @Emit('fetchDirectBookingComponent')
    fetchDirectBookingComponent() {}

    @Emit('backToFirstStep')
    backToFirstStep() {}

    @Emit('toBookingForm')
    toBookingForm() {
        this.updateAppointmentPayload();
    }

    @Emit('backToCategoriesStep')
    backToCategoriesStep() {}

    @Emit('toMethodPage')
    toMethodPage() {
        this.updateAppointmentPayload();
        return this.locationMethod;
    }

    async created() {
        await this.fetchDirectBookingComponent();
        this.setAppointmentAddress('');
        this.setPostCode('');
        if (this.currentAppointmentKindState) {
            this.currentAppointmentKind = this.currentAppointmentKindState;
        }
        if (this.appointmentCategory) {
            this.value = this.appointmentCategory;
        }
        if (this.locationName) {
            this.locationMethod = this.locationName;
        }
    }
}
</script>

<style lang='sass'>
.b-appointment-left-bar_form
    .fw-input--white, .fw-input--textarea
        box-shadow: none
        &::placeholder
            color: #909FB5
    .fw-input__error-text
        margin: 3px 0 15px
        font-size: 12px
        color: #FF004D
    .b-cancel-appointment__form
        .multiselect__tags
            min-height: 60px !important
            padding-left: 15px !important
            border-radius: 10px !important

    .fw-check-box__wrapper.with-text
        display: flex
        flex-direction: row
        width: 100%
        justify-content: flex-start
        margin-top: 10px
        .fw-check-box__label
            margin-left: 10px
            font-weight: 400

    .fw-select-white
        box-shadow: none !important

    .multiselect__input
        opacity: 1

    input.multiselect__input
        padding: 0 !important

    &__head
        height: 35px

.b-base-form-margin
    margin-bottom: 35px
    @include media('<tablet')
        margin-bottom: 10px

.h-align-items-end
    align-items: end

.b-booking-popup__wrapper
    .fw-popup
        overflow: auto
        display: block
</style>
