<template>
<FwSpinLoader
    v-if='loading'
    :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
    class='h-flex-center loader'
    :isActive='loading'
    className='h-p-20'>
</FwSpinLoader>
<div v-else class='b-page-container b-consult-worker'>
    <div class='b-consult-card__inner'>
        <GoBackButton
            class='h-mt-30'
            isRelativePosition>
        </GoBackButton>
    </div>
    <div class='b-worker-title
                h-mt-5'>
        <div class='b-worker-title__inner'>
            <WorkerAvatar :worker='workerData' :hideAvatar='true' ></WorkerAvatar>
            <div v-if='!loading && workerData.technician_profile.company_admin' class='b-worker-panel__admin h-font-12 h-fw-700 h-ml-30 h-flex-center'>
                ADMIN
            </div>
        </div>
        <div class='h-mv-10'>
            <FwButton
                v-if='currentUserRole.calendar_profile_rights.read'
                :to='`/consult/calendar/user/${$route.params.id}`'
                size='little'
                class='h-white-space-nowrap'
                fontSize='12px'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.SEE_CALENDAR') }}
            </FwButton>
        </div>
    </div>
    <div class='h-ph-10'>
        <div class='b-consult-card__wrapper'>
            <div class='b-consult-cards__wrapper-main h-flex h-flex-dir-column'>
                <div class='b-consult-card h-pv-20'>
                    <div class='b-consult-card__title h-pos-abs'>
                        <FwIcon
                            v-if='canEdit'
                            class='h-pointer qa-advisor-page-edit-button'
                            icon='edit-settings'
                            size='25'
                            color='#BEC7D4'
                            @click.native='isAddEditWorkerPopup = true'>
                        </FwIcon>
                    </div>
                    <div class='b-consult-card__inner b-consult-card__scroll-inner'>
                        <ConsultCard
                            v-for='information in prepareData(workerData)'
                            :key='information.title'
                            :information='information'>
                        </ConsultCard>
                    </div>
                </div>
                <div v-if='workerData && workerData.technician_profile.departure_point && isOnSite'
                     class='b-consult-card b-consult-mt'>
                    <div class='h-pl-30 h-pv-20 h-pr-20'>
                        <div class='h-flex h-flex-space-between h-flex-center'>
                            <div class='h-font-20 h-fw-700 h-mv-10'>
                                {{ $t('LABEL.START.POINT') }}
                            </div>
                            <FwIcon
                                v-if='canEdit'
                                class='h-pointer'
                                icon='edit-settings'
                                size='25'
                                color='#BEC7D4'
                                @click.native='goToTechAddress'>
                            </FwIcon>
                        </div>
                        <div class='b-consult-card__address h-fw-500'>
                            {{ $t('COMPANIES.FORM.INPUT.ADDRESS.LABEL') }}
                        </div>
                        <div class='b-consult-card__sub-aside'>
                            {{ workerData.technician_profile.departure_point }}
                        </div>
                        <WorkingHoursUpdateInfo v-if='addressUpdateData' isDeparture isAddress></WorkingHoursUpdateInfo>
                    </div>
                </div>
                <div v-if='workerUpdateData' class='b-consult-card b-consult-mt'>
                    <div class='h-pl-30 h-pv-20 h-pr-20'>
                        <div class='h-font-20 h-fw-700 h-mt-10'>
                            {{ $t('CONSULT.INFO.WORKING.HOURS') }}
                        </div>
                        <WorkingHoursUpdateInfo></WorkingHoursUpdateInfo>
                    </div>
                </div>
            </div>
            <div class='b-consult-card b-consult-card--transparent'>
                <div v-for='panel in workerPanel'
                     :key='panel.text'
                     :style='{ cursor: panel.isPointer ? "pointer" : "auto" }'
                     class='b-worker-panel'
                     :class='{ "b-worker-panel--disabled": panel.disabled | !canEdit }'
                     @click='swapComponent(panel.popup, panel.disabled)'>
                    <div class='b-worker-panel__text'>
                        {{ $t(panel.text) }}
                        <span v-if='panel.email' class='h-fw-700'>
                            :
                        </span>
                        <span v-if='panel.email && workerData.technician_profile.invitation_status === `not_invited`'
                              class='h-fw-300'>
                            {{ $t('WORKER.POPUP.INVITATION.SENT') }}
                        </span>
                        <span v-if='panel.email && workerData.technician_profile.invitation_status === `confirmed` && !workerData.active'
                              class='h-fw-300'>
                            {{ $t('WORKER.POPUP.INVITATION.ACCEPTED') }}
                        </span>
                        <span v-if='panel.email && workerData.technician_profile.invitation_status === `confirmed` && workerData.active'
                              class='h-fw-300'>
                            {{ $t('WORKER.POPUP.INVITATION.CONFIRMED') }}
                        </span>
                    </div>
                    <div v-if='panel.email'
                         class='h-flex-center'>
                        <FwButton
                            v-if='workerData.technician_profile.invitation_status === `not_invited`'
                            :style='{
                                color: `#27dbbd`
                            }'
                            color='transparent'
                            :disabled='isRequestSending || !canEdit'
                            fontSize='12px'
                            @click='canEdit ? sendInvitationToUser(workerData) : null'>
                            {{ $t('WORKER.POPUP.INVITATION.SEND') }}
                        </FwButton>
                        <FwButton
                            v-if='workerData.technician_profile.invitation_status === `invited`'
                            :style='{
                                color: `#27dbbd`
                            }'
                            color='transparent'
                            :disabled='isRequestSending || !canEdit'
                            fontSize='16px'
                            @click='canEdit ? sendInvitationToUser(workerData) : null'>
                            {{ $t('WORKER.POPUP.INVITATION.RESEND') }}
                        </FwButton>
                        <FwIcon
                            v-if='workerData.technician_profile.invitation_status === `confirmed`'
                            class='h-mh-5 h-mr-15 h-pointer'
                            icon='ok'
                            size='18'
                            color='#213F6B'>
                        </FwIcon>
                    </div>
                    <div v-if='panel.switchValue'>
                        <FwSwitcher
                            :disabled='panel.disabled || !canEdit'
                            :isActive='travelTimeStatus || panel.disabled'
                            @toggleSwitch='toggleSwitch()'>
                        </FwSwitcher>
                    </div>
                    <div v-if='panel.arrow'
                         class='b-consult-card__worker-arrow'>
                        <FwIcon
                            class='h-mh-5'
                            icon='arrow-right'
                            size='14'
                            color='#27dbbd'>
                        </FwIcon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AddEditAdvisorPopup
        v-if='isAdvisorEditPopup'
        @closePopup='isAdvisorEditPopup = false'>
    </AddEditAdvisorPopup>
    <AddOrEditAddressByForm
        v-if='isAddressUpdatePopup'
        :objectData='workerData'
        updateAction='ValidateDeparturePoint'
        updateType='TechnicianProfile'
        userType='Technician'
        isAddress
        isAddressSwitch
        @close='isAddressUpdatePopup = false'>
    </AddOrEditAddressByForm>
    <AddEditWorkerPopup
        v-if='isAddEditWorkerPopup'
        @closePopup='closeWorkerAddPopup'>
    </AddEditWorkerPopup>
    <component :is='currentComponent' :skills='skills' @close='currentComponent = false'></component>
    <InformationPopup
        v-if='checkWorkingHours'
        :headerText='$t(`WORKER.POPUP.INVITATION.OOPS`)'
        :subMessage='$t(`WORKER.POPUP.INVITATION.CHECK`)'
        :workingHours='workerData.is_working_schedule_confirmed'
        :listToApprove='true'
        :buttonText='$t(`FINISH.POPUP.SUCCESS.CLOSE`)'
        addressConfirm
        @close='checkWorkingHours = false'>
    </InformationPopup>
    <InformationPopup
        v-if='isPreventPopupShow'
        :headerText='$t(`UPDATE.ADDRESS.EDIT.TITLE`)'
        :bodyText='$t(`UPDATE.ADDRESS.ALREADY.UPDATED`)'
        :buttonText='$t(`FINISH.POPUP.SUCCESS.CLOSE`)'
        @close='isPreventPopupShow = false'>
    </InformationPopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import { informationDataType } from '@/types/Consult';
import { ConsultCard } from '@/components/simple/ConsultCard/index';
import {
    LOCATION_SKILLS_SEARCH_NAMES,
    BY_PHONE_ID,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
} from '@/helpers/appointment';
import { informationData } from '@/mocks/userData';
import { capitalizeAll } from '@/helpers/string';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar/index';
import { InformationPopup } from '@/components/popups/InformationPopup/index';
import { UnavailabilitiesPopup } from '@/components/popups/UnavailabilitiesPopup/index';
import { AddEditAdvisorPopup } from '@/components/popups/AddEditAdvisorPopup';
import { CalendarSyncPopup } from '@/components/popups/CalendarSyncPopup/index';
import { ReservedSlotsPopup } from '@/components/popups/ReservedSlotsPopup/index';
import { WorkingHoursPopup } from '@/components/popups/WorkingHoursPopup/index';
import { AddEditWorkerPopup } from '@/components/popups/AddEditWorkerPopup/index';
import { AddOrEditAddressByForm } from '@/views/consult/update-address/AddOrEditAddressByForm';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { WorkerScheduleTimeType, WorkerType } from '@/types/Workers';
import { workerDataType } from '@/types/User';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { AccountApi } from '@/api/account/AccountApi';
import { separateColor, hexToRgbA } from '@/helpers/colors';
import TranslateMixin from '@/mixins/TranslateMixin';
import { WorkingHoursUpdateInfo } from '@/components/simple/WorkingHoursUpdateInfo/index';
import PermissionsMixin from '@/mixins/permissions';
import {
    CategoryLocationSkillNameType,
    CategorySkillNameType,
} from '@/types/Appointment';
import AccountMixin from '@/mixins/account';

const WorkerPageStore = namespace('WorkerPageStore');

type workerPanelType = {
    href?: string,
    text: string,
    switchValue?: boolean,
    arrow?: boolean,
    state?: boolean,
    popup?: string,
    email?: string,
    disabled?: boolean
    isPointer?: boolean
}

@Component({
    components: {
        WorkerAvatar,
        ConsultCard,
        InformationPopup,
        CalendarSyncPopup,
        ReservedSlotsPopup,
        WorkingHoursPopup,
        UnavailabilitiesPopup,
        AddEditAdvisorPopup,
        AddEditWorkerPopup,
        GoBackButton,
        WorkingHoursUpdateInfo,
        AddOrEditAddressByForm,
    },
})
export default class ConsultWorkerPage extends Mixins(TranslateMixin, PermissionsMixin, AccountMixin) {
    @WorkerPageStore.Action('storeWorkerData') storeWorkerData!: Function;
    @WorkerPageStore.Mutation('setScheduleId') setScheduleId!: Function;
    @WorkerPageStore.Mutation('WORKER_DATA') setWorkerData!: Function;
    @WorkerPageStore.Mutation('WORKER_UPDATE_DATA') setWorkerUpdateData!: Function;
    @WorkerPageStore.Mutation('ADDRESS_UPDATE_DATA') setAddressUpdateData!: Function;
    @WorkerPageStore.State('workerData') workerData!: workerDataType;
    @WorkerPageStore.State('isUpdateAddressShow') isUpdateAddressShow!: Boolean;
    @WorkerPageStore.State('workerUpdateData') workerUpdateData!: WorkerScheduleTimeType;
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;
    informationData: Array<informationDataType> = informationData;
    currentComponent: string = '';
    loading: boolean = true;
    travelTimeStatus: boolean = false;
    checkWorkingHours: boolean = false;
    isRequestSending: boolean = false;
    isPreventPopupShow: boolean = false;
    isAddressUpdatePopup: boolean = false;
    isAddEditWorkerPopup: boolean = false;
    workerPanel: Array<workerPanelType> = [];

    get workerStoreData(): any {
        return this.$store.state.WorkerPageStore.workerData;
    }
    get workerScheduleStoreData(): any {
        return this.$store.state.WorkerPageStore.workerScheduleData;
    }

    get userColor() {
        const colorArray:Array<string> = separateColor(this.workerData!.color!);
        return `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
    }

    get isRouteAdvisor() {
        return this.$route.path.includes('advisors');
    }

    get kindWorker() {
        return this.isRouteAdvisor ? 'advisors' : 'technicians';
    }

    get skills() {
        return this.workerData.skills ?
            this.workerData.skills.filter((item: CategorySkillNameType) => LOCATION_SKILLS_SEARCH_NAMES.includes(item.searchable_name)) :
            [];
    }

    get isOnSite() {
        return this.workerData.skills.some(e => e.searchable_name === 'location_category_on_site');
    }

    addWorkerMethod() {
        this.isAddEditWorkerPopup = true;
    }
    closeWorkerAddPopup() {
        this.isAddEditWorkerPopup = false;
        if (this.isUpdateAddressShow) {
            this.isAddressUpdatePopup = true;
        }
    }

    goToTechAddress() {
        if (this.addressUpdateData) {
            this.isPreventPopupShow = true;
        } else {
            this.isAddressUpdatePopup = true;
        }
    }

    toggleSwitch() {
        this.travelTimeStatus = !this.travelTimeStatus;
        this.changeWorkerTravelStatus();
    }

    swapComponent(component: string, disabled?: boolean) {
        if (disabled) return;
        this.currentComponent = component;
    }

    prepareData(workerData: any): Array<informationDataType> {
        const workerDataArray: Array<informationDataType> = [];
        if (workerData.company_title) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.COMPANY'),
                aside: capitalizeAll(workerData.company_title),
                header: this.$i18n.t('SELECT.ADDRESS.BRANCH.CONTACT.DETAILS'),
            });
        }
        if (workerData.id) {
            workerDataArray.push({
                title: 'ID',
                aside: workerData.id,
            });
        }
        if (workerData.email) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.EMAIL'),
                aside: workerData.email,
            });
        }
        if (workerData.geo_sites) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.GEOGRAFIC.ZONE'),
                aside: `(${workerData.geo_sites.length}) ${workerData.geo_sites.map((worker: WorkerType) => worker.name).join(', ')}`,
            });
        }
        if (workerData.phone || workerData.technician_profile.phone) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.PHONE'),
                aside: workerData.phone || workerData.technician_profile.phone,
            });
        }
        if (workerData.company_name) {
            workerDataArray.push({
                title: this.$i18n.t('LABEL.COMPANY'),
                aside: capitalizeAll(workerData.company_name),
            });
        }
        if (workerData.skills && workerData.skills.length) {
            const locationSkills = workerData.skills.filter(
                (item: CategoryLocationSkillNameType | CategorySkillNameType) => LOCATION_SKILLS_SEARCH_NAMES.includes(item.searchable_name)
            );
            const skills = workerData.skills.filter(
                (item: CategoryLocationSkillNameType | CategorySkillNameType) => !LOCATION_SKILLS_SEARCH_NAMES.includes(item.searchable_name)
            );
            if (skills && skills.length) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.SKILLS'),
                    // @ts-ignore-next-line
                    aside: `(${skills.length}) ${skills.map((worker: WorkerType) => worker[this.labelKey]).join(', ')}`,
                });
            }
            if (locationSkills && locationSkills.length) {
                workerDataArray.push({
                    title: this.$i18n.t('LABEL.APPOINTMENT.METHODS'),
                    // @ts-ignore-next-line
                    aside: `(${locationSkills.length}) ${locationSkills.map((worker: WorkerType) => worker[this.labelKey]).join(', ')}`,
                });
            }
        }
        return workerDataArray;
    }

    changeWorkerTravelStatus() {
        if (this.workerData) {
            WorkersWebApi.userToggleTravelTime(
                {
                    user_id: this.workerData.id as string,
                    toggle_travel_time: this.travelTimeStatus,
                },
                this.workerData.id as string,
            ).then(response => {
                if (response.status === 200) {
                    console.log('ok');
                }
            }).catch(({ response }) => {
                if (response.status === 401) {
                    console.log('some error');
                } else {
                    this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
                }
            });
        }
    }

    sendInvitationToUser(worker: WorkerType) {
        this.isRequestSending = true;
        if (worker.is_working_schedule_confirmed || (this.workerScheduleStoreData && this.workerScheduleStoreData.confirmed_at)) {
            AccountApi.sendInvitationUser({
                user_id: worker.id,
            },
            ).then(response => {
                if (response.status === 200) {
                    this.isRequestSending = false;
                    this.getWorker();
                    this.sentNotif(`WORKER.POPUP.INVITATION.SENT.MESSAGE`);
                }
            }).catch(({ response }) => {
                this.isRequestSending = false;
                this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
            });
        } else {
            this.isRequestSending = false;
            this.checkWorkingHours = true;
        }
    }
    async getWorker() {
        try {
            const existTechnician = { data: { user: this.workerData }};
            const { data } = this.workerData ? existTechnician : await WorkersWebApi.getTechnicianData({ id: this.$router.currentRoute.params.id });
            this.setWorkerData(data.user);
            this.loading = false;
            this.informationData = this.prepareData(data.user);
            this.setScheduleId(data.user.working_schedule_id);
            this.setWorkerUpdateData(data.user.working_hours_update);
            this.setAddressUpdateData(data.user.departure_point_update);
            this.travelTimeStatus = data.user.technician_profile.add_travel_time;
            const disabledDistance: boolean = this.skills.length ?
                this.skills.some(item => [
                    BY_PHONE_ID, IN_BRANCH_PUBLIC_ID, IN_BRANCH_VIDEO_ID, IN_BRANCH_PRIVATE_VIDEO_ID, IN_BRANCH_BY_PHONE_PUBLIC_ID, IN_BRANCH_BY_PHONE_PRIVATE_ID,
                ].includes(item.searchable_name)) :
                true;
            const workerPanel: Array<workerPanelType> = [
                {
                    text: 'LABEL.ACCOUNT',
                    email: 'john.doe@gmail.com',
                    isPointer: false,
                },
                {
                    text: 'GLOBAL.SYNCHRONISATION',
                    arrow: true,
                    popup: 'CalendarSyncPopup',
                    isPointer: true,
                },
                {
                    text: 'CONSULT.INFO.RESERVED.SLOTS',
                    arrow: true,
                    popup: 'ReservedSlotsPopup',
                    disabled: this.skills.length <= 1,
                    isPointer: true,
                },
                {
                    text: 'CONSULT.INFO.UNAVAILABILITIES',
                    arrow: true,
                    popup: 'UnavailabilitiesPopup',
                    isPointer: true,
                },
                {
                    text: 'CONSULT.INFO.WORKING.HOURS',
                    arrow: true,
                    popup: 'WorkingHoursPopup',
                    isPointer: true,
                },
                {
                    text: 'CONSULT.INFO.ADD.DISTANCE.TEXT',
                    switchValue: true,
                    state: this.travelTimeStatus,
                    disabled: disabledDistance,
                    isPointer: true,
                },
            ];
            this.workerPanel = workerPanel;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    created() {
        this.setWorkerData(null);
        this.getWorker();
    }
}
</script>

