<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='800px'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        class='b-over-fixed-background'
        @close='$emit("close")'>
        <template #body>
            <div class='h-text-center'>
                <h1 v-if='companyEvent'>
                    {{ companyEvent.title }}
                </h1>
                <h1 v-else>
                    {{ data.title }}
                </h1>
            </div>
            <div class='h-flex-center'>
                <EventForm
                    formClass='b-cancel-appointment__form'
                    iconsWrapperClass='b-cancel-appointment__form__events'
                    iconsInnerClass='h-mh-20'
                    :eventTime='eventTime'
                    :dates='dates'
                    :defaultFormValues='data'
                    :disabledForm='true'
                    submitText=''>
                </EventForm>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { EventForm } from '@/components/forms/event/EventForm';
import { dateAsEventTime } from '@/helpers/dates';
import DateMixin from '@/mixins/dateMixin';
import { UnavailabilityEventInstancesType, CreateEventUnavailabilityType } from '@/types/Events/Workers';
import CalendarMixin from '@/mixins/calendar';

@Component({
    components: {
        EventForm,
    },
})
export default class CompanyPopup extends Mixins(DateMixin, CalendarMixin) {
    @Prop({ type: Object, required: true }) readonly data!: UnavailabilityEventInstancesType;
    @Prop({ type: Date, required: true }) readonly date!: Date;

    companyEvent: null | CreateEventUnavailabilityType = null;

    get eventTime(): string {
        return dateAsEventTime(new Date(this.data.dt_start), this.user);
    }

    get dates(): Array<string> {
        return [
            this.getCurrentDateAsMinutesAMPM(new Date(this.data.dt_start)),
            this.getCurrentDateAsMinutesAMPM(new Date(this.data.dt_end)),
        ];
    }

    sendDataToServer(data: any): void {
        console.log(data);
    }
}
</script>
<style lang='sass'>
    .b-blue-background
        z-index: 32
</style>
