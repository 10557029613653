<template>
<div class='b-blue-background--fixed b-blue-background b-blue-background__opacity'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        max-width='440px'
        width='100%'
        title=''
        iconColor='#BEC7D3'
        popupBackground='#F8F9FA'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupRadius='20px'
        heightValue='100%'
        @close='closePopup'>
        <template #body>
            <div class='b-choose-shift-slot-form__wrapper'>
                <ChooseShiftsSlotForm
                    :date='calendarDate'
                    :currentSlots='currentInstancesData'
                    :currentInstancesData='currentInstancesData'
                    reedOnly
                    :isReservedSots='false'
                    :recurrenceLoading='false'
                    @closePopup='closePopup'>
                </ChooseShiftsSlotForm>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins, Vue } from 'vue-property-decorator';
import { ChooseShiftsSlotForm } from '@/components/forms/add/ChooseShiftsSlotForm';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';

@Component({
    components: {
        ChooseShiftsSlotForm,
    },
})
export default class ShiftsListPopup extends Vue {
    @Prop({ type: Array, required: true }) readonly currentInstancesData!: Array<UnavailabilityEventInstancesType>;
    @Prop({ type: Date, required: true }) readonly calendarDate!: Date;

    @Emit('close')
    closePopup() {}
}
</script>

<style lang='sass'>
.b-choose-shift-slot-form__wrapper
    width: 340px
    background-color: #F8F9FA
</style>
