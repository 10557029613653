import axios from 'axios';
import { updateUrlGetParams } from '@/helpers/url';
import {
    GeoReassignmentPostPayloadType,
    GeoReassignmentsResponseType,
    GeoReassignmentsUsersResponseType,
    ReassignmentUserType,
    SiteReassignmentsResponseType,
} from '@/types/Consult';
import { EditUpdateAdvisorPayloadType } from '@/types/Workers';
import WebApi from '../WebApi';

interface workerIdData {
    id: string | number | null,
    title?: string,
}

class AdvisorsWebApi extends WebApi {
    async createAdvisor(payload: EditUpdateAdvisorPayloadType) {
        return axios.post(
            `${this.urlPrefix}/advisors`,
            {
                'advisor': payload,
            }
        );
    }

    async getAdvisorData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/advisors/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async updateAdvisor(payload: EditUpdateAdvisorPayloadType, id: string) {
        return axios.put(
            `${this.urlPrefix}/advisors/${id}`,
            {
                'advisor': payload,
            }
        );
    }

    async deleteAdvisor({ id }: workerIdData | { id: string }) {
        return axios.delete(
            `${this.urlPrefix}/advisors/${id}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getSiteReassignments(payload: {
        user_from_id: string,
        per_page: number,
        current_page: number,
        from: boolean,
        advisor: null | ReassignmentUserType
    }): Promise<SiteReassignmentsResponseType> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/site_reassignments`, {
            'user_id': payload.user_from_id,
            'pagination[per_page]': payload.per_page,
            'pagination[page]': payload.current_page,
            ...(!payload.advisor && { direction: payload.from ? `from` : `to` }),
            ...(payload.from && payload.advisor && { user_to_id: payload.advisor.id }),
            ...(!payload.from && payload.advisor && { user_from_id: payload.advisor.id }),
        }));
        return data;
    }

    async geoReassignments(user_id: string): Promise<GeoReassignmentsResponseType> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/components/geo_reassignment`, { user_id }));
        return data;
    }

    async fetchReassignmentsUsers(user_id: string): Promise<GeoReassignmentsUsersResponseType> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/front_helpers/geo_reassigned_advisors`, { user_id }));
        return data;
    }

    async doGeoReassignments(site_reassignment: GeoReassignmentPostPayloadType): Promise<GeoReassignmentsResponseType> {
        const { data } = await axios.post(`${this.urlPrefix}/site_reassignments`, { site_reassignment });
        return data;
    }

    async removeSiteReassignments(site_reassignment: {
        user_id?: string,
        site_ids?: Array<string>,
        all?: true,
        from_advisor?: string,
        to_advisor?: string
        due_from?: string
    }): Promise<GeoReassignmentsResponseType> {
        const { data } = await axios.post(`${this.urlPrefix}/site_reassignments/bulk_destroy`, { site_reassignment });
        return data;
    }
}

const advisorsApi = new AdvisorsWebApi();

export {
    advisorsApi as AdvisorsWebApi,
};
