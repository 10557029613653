<template>
<div class='h-mt-30 h-flex h-flex-space-between h-align-items-center h-flex-dir-column'>
    <div class='b-add-edit-room__inner'>
        <FwFormInput
            class='h-mh-10 h-flex-2-0'
            :fieldHasError='fieldHasError(NAME)'
            labelType='label-medium'
            :title='`${$t(`PLAN.APPOINTMENT.BRANCH.ROOMS.NAME`)}*`'>
            <FwInput
                class='qa-room-name'
                :value='name'
                type='text'
                name='text'
                :placeholder='$t(`PLAN.APPOINTMENT.BRANCH.ROOMS.NAME`)'
                inputStyle='white-bigger'
                :disabled='disabledByPermission'
                :error='getFieldErrorText(NAME)'
                @update:value='value => name = value'
                @input='clearServerErrorsBase(NAME)'
                @blur='addCurrentInputToValidateArray(NAME)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='h-mh-10 h-flex-2-0'
            :fieldHasError='fieldHasError(EMAIL)'
            labelType='label-medium'
            :title='`${$t(`LABEL.EMAIL`)}`'>
            <FwInput
                class='qa-room-email'
                :value='email'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.EMAIL`)'
                inputStyle='white-bigger'
                :disabled='disabledByPermission'
                :error='getFieldErrorText(EMAIL)'
                @update:value='value => email = value'
                @input='clearServerErrorsBase(EMAIL)'
                @blur='addCurrentInputToValidateArray(EMAIL)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='h-mh-10'
            :style='isDesktop() ? "flex: 0 0 140px" : ""'
            labelType='label-medium'
            :disabled='true'
            :title='`${$t(`PLAN.APPOINTMENT.BRANCH.VIDEO.ROOM`)}`'>
            <FwLabelCard
                :disabled='true'
                class='b-add-edit__form--label-card'>
                <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                    <div class='h-flex h-flex-center'>
                        <span class='b-add-worker__is_admin_active'>
                            {{ isActive ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                        </span>
                    </div>
                    <FwSwitcher
                        class='h-mh-10'
                        :isActive='isActive'
                        :disabled='true'
                        @toggleSwitch='isActive = !isActive'>
                    </FwSwitcher>
                </div>
            </FwLabelCard>
        </FwFormInput>
    </div>
    <div class='h-flex h-flex-center'>
        <FwButton
            v-if='!disabledByPermission'
            :class='isDesktop() ? "h-mt-30" : "h-mt-15"'
            class='qa-room-form-submit'
            :disabled='disabled'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import {
    NAME,
    EMAIL,
} from '@/helpers/branch';
import { BranchRoomResponseType } from '@/types/Appointment';
import { isEqual } from '@/helpers/base';
import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';

@Component({
    validators: {
        [NAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [EMAIL](value: string) {
            return this.emailValidator({ value, notRequired: true });
        },
    },
    components: {
        CalendarTableButtons,
        CalendarTable,
    },
})
export default class AddOrEditRoomForm extends Mixins(ValidationMixin) {
    @Prop({ type: Object, default: null }) readonly roomData!: BranchRoomResponseType;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Boolean, default: false }) readonly disabledByPermission!: boolean;

    isActive: boolean | string = true;
    // @ts-ignore-next-line
    [NAME]: string = '';
    // @ts-ignore-next-line
    [EMAIL]: string = '';
    NAME: string = NAME;
    EMAIL: string = EMAIL;

    serverErrors: serverErrors = {
        [NAME]: null,
        [EMAIL]: null,
    };

    requiredFields: Array<string> = [NAME];

    get disabled(): boolean {
        return this.isRequestSending || !this.isFormValid || this.isEqual;
    }

    get isEqual(): boolean {
        const name = this.roomData ? this.roomData.name : '';
        const email = this.roomData ? this.roomData.email : '';
        const facility = this.roomData ? this.roomData.facility : '';
        return isEqual(
            { name, email, facility },
            { name: this.name, email: this.email, facility: this.isActive },
        );
    }

    updateFormByServerValues() {
        if (this.roomData.name) {
            this[NAME] = this.roomData.name;
        }
        if (this.roomData.email) {
            this[EMAIL] = this.roomData.email;
        }
        if (this.roomData.facility) {
            this.isActive = this.roomData.facility;
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return {
            [EMAIL]: this[EMAIL],
            [NAME]: this[NAME],
            kind: 'room',
            facility: this.isActive ? 'video' : '',
        };
    }

    created() {
        if (this.roomData) {
            this.updateFormByServerValues();
        }
    }

    mounted() {
        if (this.roomData) {
            this.addCurrentInputToValidateArray(NAME);
            this.addCurrentInputToValidateArray(EMAIL);
        }
    }
}
</script>

<style lang='sass'>
.h-flex-2-0
    flex: 2 0

.b-add-edit-room__inner
    display: flex
    flex: wrap
    width: 100%

    @include media('<=phone')
        flex-direction: column

        .fw-form-input__wrapper
            margin-bottom: 12px
</style>
