<template>
<div :key='currentKey'>
    <div class='b-vertical-statistics__title'>
        {{ title }}
    </div>
    <div class='b-vertical-statistics__wrapper'>
        <div ref='chart'
             class='b-vertical-statistics__vertical-part'>
            <div v-for='item in timeLine'
                 :key='item.time'
                 class='b-vertical-statistics__vertical-part__item'>
                <div class='b-vertical-statistics__vertical-part__item__column'
                     :style='style(item)'>
                    <span v-if='calcValue(item.calendarViewTime)'>
                        {{ calcValue(item.calendarViewTime) }}
                    </span>
                </div>
            </div>
        </div>

        <div class='b-vertical-statistics__timeline'>
            <div v-for='item in timeLine'
                 :key='item'
                 class='b-vertical-statistics__timeline__item'>
                {{ item.calendarViewTime }}
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventDataType } from '@/types/Events';

@Component({
    refs: [`chart`],
})
export default class VerticalStatistic extends Vue {
    @Prop({ type: Array, required: true }) readonly timeLine!: Array<EventDataType>;
    @Prop({ type: Object, required: true }) readonly data!: { [key: string]: number };
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: String, required: true }) readonly color!: string;

    $refs!: {
        chart: HTMLElement
    };

    currentKey: number = 1;
    animateStart: boolean = false;

    get calcMax(): number {
        return Math.max(...Object.values(this.data));
    }

    get maxSize(): number {
        if (!this.$refs.chart) return 0;
        return this.isDesktop() ? 200 : this.$refs.chart.offsetWidth;
    }

    calcValue(time: string): number {
        return this.animateStart ? this.data[time] || 0 : 0;
    }

    calculateSize(time: string): string {
        return `${this.calcValue(time) / this.calcMax * this.maxSize}px`;
    }

    isDesktop(): boolean {
        return window.innerWidth >= 1280;
    }

    style(item: EventDataType) {
        return this.currentKey && {
            backgroundColor: this.color,
            height: this.isDesktop() ? this.calculateSize(item.calendarViewTime || '') : null,
            width: !this.isDesktop() ? this.calculateSize(item.calendarViewTime || '') : null,
            opacity: this.animateStart ? '1' : '0',
        };
    }

    updateChart() {
        this.currentKey += 1;
    }

    mounted() {
        setTimeout(() => {
            this.animateStart = true;
        }, 100);
        window.addEventListener('resize', this.updateChart);
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.updateChart);
    }
}
</script>

<style lang='sass'>
.b-vertical-statistics
    &__wrapper
        width: 100%
        background-color: #fff
        padding: 12px 15px
        border-radius: 0 0 20px 20px
        @include media('<=desktop')
            flex-direction: row-reverse
            display: flex
            padding: 8px 0
            margin-top: 10px
            border-radius: 20px

    &__title
        font-size: 12px
        text-transform: uppercase
        color: #213F6B
        letter-spacing: 0.2px
        text-align: center
        padding: 8px 0 12px
        background: #fff
        font-weight: bold
        border-radius: 20px 20px 0 0

        @include media('<=desktop')
            padding: 0
            background: transparent

    &__timeline
        display: flex
        font-size: 10px
        line-height: 1.4em
        color: #213F6B
        font-weight: bold
        width: 100%
        border-top: 1px solid rgba(151, 151, 151, 0.4)
        padding-top: 5px

        @include media('<=desktop')
            display: block
            width: auto
            padding-top: 0
            border-top: 0
            flex: 0 0 65px

        &__item
            flex: 1 0
            text-align: center

            @include media('<=desktop')
                display: flex
                align-items: center
                justify-content: center
                height: 24px
                margin: 0 5px
                padding: 2px 10px

    &__vertical-part
        display: flex
        height: 200px

        @include media('<=desktop')
            display: block
            width: 200px
            flex: 1
            height: auto

        &__item
            flex: 1 0
            text-align: center
            display: flex
            justify-content: center
            align-items: flex-end

            @include media('<=desktop')
                justify-content: flex-start
                padding: 2px 10px

            &__column
                width: 20px
                border-radius: 10px
                color: #fff
                font-size: 14px
                font-weight: 500
                display: flex
                align-items: flex-end
                justify-content: center
                transition: height 0.6s cubic-bezier(.09,.8,.95,.97), opacity 0.3s ease-in

                @include media('<=desktop')
                    height: 20px
                    margin: 0 5px
                    width: auto
                    align-items: center
                    line-height: 20px
                    justify-content: center

                span
                    margin-bottom: 7px
                    @include media('<=desktop')
                        margin-bottom: 0
</style>
