<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwSpinLoader
        v-if='isLoading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='isLoading'
        className='h-p-20'>
    </FwSpinLoader>
    <FwPopup
        v-else
        class='b-calendar-sync-popup qa-advisor-sync-popup'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='780px'
        :title='$t("GLOBAL.SYNCHRONISATION")'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        :padding='isMobile() ? "30px 20px" : "50px"'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :hideSubPopup='!showSubPopup'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex h-flex-wrap'>
                <template v-if='!isMatmutAccount'>
                    <div
                        v-for='card in dataSyncCard'
                        :key='card.id'
                        style='width: 300px; padding: 25px 30px; border-radius: 10px'
                        class='fw-simple-card-wrapper h-flex-dir-column h-m-20'>
                        <h2 class='text-left h-width-100p h-m-0'>
                            {{ $t(card.title) }}
                        </h2>
                        <div class='h-flex h-flex-space-between h-flex-center h-width-100p h-mb-5 h-mt-25'>
                            <span>
                                {{ $t('CALENDAR.POPUP.SYNC.AUTHORIZATION') }}:
                            </span>
                            <span
                                :class='card.provider ? "b-label-yes" : "b-label-no"'
                                class='b-label-block h-font-12 h-fw-700'>
                                {{ $t(card.provider ? 'CALENDAR.POPUP.SYNC.CALENDAR.YES' : 'CALENDAR.POPUP.SYNC.CALENDAR.NO') }}
                            </span>
                        </div>
                        <div class='h-flex h-flex-space-between h-flex-center h-width-100p h-mb-5'>
                            <span>
                                {{ $t('CALENDAR.POPUP.SYNC.TOKEN.VALIDITY') }}:
                            </span>
                            <span :class='card.tokens_valid ? "b-label-yes" : "b-label-no"'
                                  class='b-label-block h-font-12 h-fw-700'>
                                {{ $t(card.tokens_valid ? 'CALENDAR.POPUP.SYNC.CALENDAR.YES' : 'CALENDAR.POPUP.SYNC.CALENDAR.NO') }}
                            </span>
                        </div>
                        <div class='h-flex h-flex-space-between h-flex-center h-width-100p h-mb-5'>
                            <span>
                                {{ $t('CALENDAR.POPUP.SYNC.CALENDAR.SYNCED') }}:
                            </span>
                            <span :class='card.is_calendar_sync ? "b-label-yes" : "b-label-no"'
                                  class='b-label-block h-font-12 h-fw-700'>
                                {{ $t(card.is_calendar_sync ? 'CALENDAR.POPUP.SYNC.CALENDAR.YES' : 'CALENDAR.POPUP.SYNC.CALENDAR.NO') }}
                            </span>
                        </div>
                    </div>
                </template>
                <div
                    :style='isDesktop() ? "width: 300px;" : "width: 100%"'
                    style='width: 300px; padding: 25px 30px; border-radius: 10px'
                    class='fw-simple-card-wrapper h-flex-dir-column h-m-20'>
                    <h2 class='text-left h-width-100p h-m-0'>
                        Zoom
                    </h2>
                    <div class='h-flex h-flex-space-between h-flex-center h-width-100p h-mb-5 h-mt-25'>
                        <span>
                            {{ $t('CALENDAR.POPUP.SYNC.USER.ID.RETRIEVED') }}:
                        </span>
                        <span
                            :class='zoomUserId ? "b-label-yes" : "b-label-no"'
                            class='b-label-block h-font-12 h-fw-700'>
                            {{ $t(zoomUserId ? 'CALENDAR.POPUP.SYNC.CALENDAR.YES' : 'CALENDAR.POPUP.SYNC.CALENDAR.NO') }}
                        </span>
                    </div>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { CalendarApi } from '@/api/calendar/CalendarApi';
import AccountMixin from '@/mixins/account';
import { SyncDataCardType, CalendarSyncType } from '@/types/Calendar';

@Component
export default class CalendarSyncPopup extends Mixins(AccountMixin) {
    @Prop({ type: String, default: null }) readonly zoomUserId!: string;

    calendarSyncCredentials: Array<CalendarSyncType> = [];
    isLoading: boolean = false;

    created() {
        this.getCredentials();
    }

    dataSyncCard: SyncDataCardType = {
        google: {
            title: 'CALENDAR.POPUP.SYNC.GOOGLE.CALENDAR',
            created_at: '',
            id: '',
            is_calendar_sync: false,
            provider: '',
            tokens_valid: false,
            updated_at: '',
            user_id: '',
        },
        outlook: {
            title: 'CALENDAR.POPUP.SYNC.OUTLOOK.CALENDAR',
            created_at: '',
            id: '',
            is_calendar_sync: false,
            provider: '',
            tokens_valid: false,
            updated_at: '',
            user_id: '',
        },
    }

    async getCredentials() {
        this.isLoading = true;
        try {
            const { remote_credentials } = await CalendarApi.getRemoteCredentials(this.$route.params.id);
            remote_credentials.forEach(item => {
                this.dataSyncCard[item.provider as keyof SyncDataCardType] = {
                    ...this.dataSyncCard[item.provider as keyof SyncDataCardType], ...item,
                };
            });
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoading = false;
        }
    }
}
</script>

<style lang="sass">
.b-calendar-sync-popup

    .b-label-block
        padding: 8px

    .b-label-yes
        border-radius: 5px
        background-color: rgba(39, 219, 189, 0.1)
        color: #27DBBD

    .b-label-no
        border-radius: 5px
        background-color: rgba(255, 0, 77, 0.1)
        color: #FF004D

    @include media('<=phone')
        .fw-simple-card-wrapper
            width: 100% !important
</style>
