export default {
    'ADD.RESERVED.SLOT.FORM.TITLE': 'Ajouter un créneau',
    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.TITLE': 'Créneaux réservés',

    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.CHOOSE.TITLE': 'Consulter les créneaux réservés',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.CHOOSE.TITLE': 'Consulter les indisponibilités',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TEXT': 'DISPONIBLE: Le conseiller est disponible par défaut pour la prise de rendez-vous,' +
        ' dans le cadre des horaires de travail. Pour se rendre indisponible, le conseiller doit créer des indisponibilités ou des événements dans son agenda.',
    'ADD.RESERVED.SLOT.FORM.AVAILABILITY.TEXT': 'INDISPONIBLE : Le conseiller est indisponible par défaut pour' +
        ' la prise de rendez-vous. Pour se rendre disponible, le conseiller doit ouvrir des jours de' +
        ' disponibilité. Les rendez-vous seront pris dans le cadre des heures de travail.',

    'ADD.RESERVED.SLOT.FORM.CHANGE.TITLE': 'Modifier l\'indisponibilité',
    'ADD.RESERVED.SLOT.FORM.ADD.SHIFT.TITLE': 'Ajouter une shift',
    'ADD.RESERVED.SLOT.FORM.CHANGE.SHIFT.TITLE': 'Changer le shift',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE': 'Ajouter une indisponibilité',
    'ADD.RESERVED.SLOT.FORM.SET.AVAILABILITY.TITLE': 'Définir le type de disponibilité',
    'ADD.RESERVED.SLOT.FORM.SET.AVAILABILITY.DEFAULT.TITLE': 'Disponible par défaut',
    'ADD.RESERVED.SLOT.FORM.SET.UNAVAILABILITY.DEFAULT.TITLE': 'Indisponible par défaut',
    'ADD.RESERVED.SLOT.FORM.AVAILABILITY.TITLE': 'Ajouter une disponibilité',
    'ADD.RESERVED.SLOT.FORM.AVAILABILITY.CHANGE.TITLE': 'Disponibilité',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE.BASE': 'Indisponibilité passée',
    'ADD.RESERVED.SLOT.FORM.ALL_DAY': 'JOUR ENTIER',
    'ADD.RESERVED.SLOT.FORM.MORNING': 'MATIN',
    'ADD.RESERVED.SLOT.FORM.AFTERNOON': 'APRÈS-MIDI',
    'ADD.RESERVED.SLOT.FORM.NO_RECURRENCE': 'PAS DE RECURRENCE',
    'ADD.RESERVED.SLOT.FORM.END_RECURRENCE': 'FIN DE RECURRENCE',

    'ADD.RESERVED.SLOT.FORM.TO': 'à',

    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_DAY': 'TOUS LES JOURS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_WEEK': 'TOUTES LES SEMAINES',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_MONTH': 'TOUS LES MOIS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_YEAR': 'TOUS LES ANS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.CHOOSE_DAY.PLACEHOLDER': 'SELECTIONNER UNE DATE',

    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.ANY': 'Semaine du mois',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.1': 'Première semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.2': 'Deuxième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.3': 'Troisième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.4': 'Quatrième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.LAST_WEEK': 'Dernière semaine',

    'ADD.RESERVED.SLOT.FORM.TITLE.PLACEHOLDER': 'CHOISISSEZ UN TITRE',
    'ADD.RESERVED.SLOT.FORM.TITLE.OTHER_COMPANY': 'AUTRE ENTREPRISE',
    'ADD.RESERVED.SLOT.FORM.TITLE.DAY_OFF': 'JOUR DE CONGÉ',
    'ADD.RESERVED.SLOT.FORM.TITLE.TRAINING': 'FORMATION',
    'ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE': 'INDISPONIBLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.AVAILABLE': 'DISPONIBLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.CLOSURE': 'STOPPAGE',

    'ADD.RESERVED.SLOT.FORM.TITLE.RECEPTION': 'RÉCEPTION',
    'ADD.RESERVED.SLOT.FORM.TITLE.ACTIVITIES': 'ACTIVITÉS',
    'ADD.RESERVED.SLOT.FORM.TITLE.INTERACTION': 'ECHANGES',
    'ADD.RESERVED.SLOT.FORM.TITLE.OFF': 'ABSENCES',

    'ADD.RESERVED.SLOT.ON_SITE.HINT': 'Sur site ne peut pas être cumulé à une autre méthode',
};
