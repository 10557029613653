import company from './company';
import team from './team';
import calendar from './calendar';
import rolePopups from './role-popups';
import userPopups from './user-popups';
import roles from './roles';
import reminders from './reminders';
import customisation from './customisation';

export default {
    ...company,
    ...team,
    ...calendar,
    ...rolePopups,
    ...userPopups,
    ...roles,
    ...reminders,
    ...customisation,
};
