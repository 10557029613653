<template>
<div class='b-appointment-choose-address'>
    <div class='h-text-center h-pt-30'>
        <h1>
            {{ isOnSiteFlow ? $t('SELECT.ADDRESS.APPOINTMENT.TITLE') : $t('SELECT.ADDRESS.APPOINTMENT.CLIENT.TITLE') }}
        </h1>
    </div>
    <div class='h-flex-center h-flex-dir-column'>
        <div class='fw-select-address b-classic-width-input'>
            <FwFormInput
                class='h-mb-25'
                labelType='label-thin'
                :title='$t("SELECT.ADDRESS.APPOINTMENT.LABEL")'>
                <SelectAddress
                    class='qa-select-appointment-address'
                    :startAddress='clientAddress'
                    isDisabled
                    @sendPlace='setAppointmentPlace'
                    @changePostCode='value => post_code = value'
                    @input='input'>
                </SelectAddress>
            </FwFormInput>
        </div>
        <FwButton
            size='little'
            borderRadiusType='small-border'
            @click.native='goNext'>
            {{ $t('BUTTON.CONFIRM2') }}
        </FwButton>
        <div class='h-flex h-flex-wrap h-mt-20'>
            <FwButton
                size='large-xl'
                color='transparent'
                borderRadiusType='tiny-border'
                class='h-width-100p h-mt-10'
                :fontSize='`16px`'
                @click.native='$router.push({ path: `/appointment/address/${isOnSiteInBranchFlow ? "in-branch-on-site" : "on-site"}` })'>
                <span style='overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>
                    {{ $t('APPOINTMENT.USE.ANOTHER.ADDRESS') }}
                </span>
                <FwIcon
                    class='h-flex h-ml-10'
                    icon='arrow-right'
                    size='12'
                    color='#203f6a'>
                </FwIcon>
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

import { CategoryLocationSkillNameType, AppointmentCategoryType, AppointmentOnSiteDataType } from '@/types/Appointment';
import { ParsedAddressData, GoogleMapPlaceType } from '@/types/GoogleMap';
import { ClientType } from '@/types/User';
import { CompaniesAddressDataType } from '@/types/Companies';

import { SelectAddress } from '@/components/common/SelectAddress';

import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';

import { parseAddress, addPostCodeToAddress } from '@/helpers/address';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');

@Component({
    components: {
        SelectAddress,
    },
})
export default class AppointmentChooseClientAddressPage extends Mixins(NoDataMixin, AppointmentMixin) {
    @AppointmentStore.State('client') client!: ClientType | null;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('locationName') locationName!: CategoryLocationSkillNameType;
    @AppointmentStore.State('placeParsedData') placeParsedData!: ParsedAddressData;
    @AppointmentStore.State('opportunityId') opportunityId!: string;
    @AppointmentStore.Mutation('setAppointmentPlace') setAppointmentPlace!: (place: GoogleMapPlaceType) => void;
    @AppointmentStore.Mutation('setPlaceParsedData') setPlaceParsedData!: (address: ParsedAddressData | null) => void;
    @AppointmentStore.Mutation('setAppointmentOnSiteData') setAppointmentOnSiteData!: (appointment: AppointmentOnSiteDataType) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;

    post_code: string = '';
    address: null | ParsedAddressData = null;

    get clientAddress() {
        return this.client ? this.client.address : '';
    }

    input(address: ParsedAddressData) {
        this.address = address;
        this.post_code = address.post_code;
        if (this.post_code) {
            this.goNext();
        }
    }

    handleKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.goNext();
        }
    }

    async goNext() {
        if (this.address) {
            if (this.post_code && !this.address.post_code) {
                this.address.calizyAddress = addPostCodeToAddress(this.address.calizyAddress, this.post_code);
            }
            this.address.post_code = this.post_code;
            this.setPlaceParsedData(this.address);
            if (this.$route.meta && this.$route.meta.onSite) {
                try {
                    const appointment = await AppointmentWebApi.onSiteAppointments({
                        account_id: this.user().account_id,
                        appointment_category_id: this.appointmentCategory.id,
                        location: this.placeParsedData.calizyAddress,
                        category: 'location_category_on_site',
                        ...(this.opportunityId && { opportunity_id: this.opportunityId }),
                        ...(this.client && this.client.id && { client_id: this.client.id }),
                    });
                    this.setAppointmentOnSiteData(appointment);
                    this.$router.push({ path: '/appointment/calendar/on-site' });
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else if (this.$route.meta && this.$route.meta.onSiteInBranch) {
                try {
                    this.$router.push({ path: '/appointment/choose-branch' });
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else {
                this.$router.push({ path: '/appointment/address/branch' });
            }
        }
    }

    created() {
        document.addEventListener('keyup', this.handleKeyUp);

        if (!this.appointmentCategory || !this.locationName) {
            this.$_NoDataMixin_bookFlowNoData();
        } else if (this.client && this.client.address) {
            const address: CompaniesAddressDataType = parseAddress(this.client.address);
            const parsedAddress: ParsedAddressData = {
                post_code: address.zip_code,
                address: address.address,
                country: address.country,
                calizyAddress: this.client.address,
            };
            this.address = parsedAddress;
            this.post_code = parsedAddress.post_code;

            this.setPostCode(this.client.post_code);
        }
    }

    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    }
}
</script>

<style lang='sass'>
.fw-select-address .multiselect .fw-select__caret
    opacity: 0
.fw-select-address
    .fw-select-white
        box-shadow: none!important

.b-appointment-choose-address
    .multiselect__content
        width: 600px !important
</style>
