<template>
<div class='h-flex h-flex-center h-flex-dir-column h-mt-60'>
    <div class='h-flex h-flex-center h-flex-dir-column'>
        <h1>
            {{ $t('CONSULT.INFO.WORKING.HOURS') }}
        </h1>
        <p>
            {{ currentWorker ? 'Set here working hours for the worker.' : $t(`ONBOARDING.WORKING.HOURS.${workerAdvisorString}.PAGE.SUBTITLE`) }}
        </p>
        <div class='h-flex h-flex-center b-working-hours'>
            <div v-for='day in workingTimeValue'>
                <h3 class='h-text-center'>
                    {{ $t(day.day) }}
                </h3>
                <div class='b-switch-time-day h-pt-0 h-pb-10 h-mh-5'>
                    <div v-for='(half, index) in day.dayPart' class='h-p-10 h-mv-10 b-switch-time-day__block' >
                        <div class='b-switch-day-time__half h-flex h-flex-dir-column h-flex-space-between'>
                            <div class='b-switch-day h-flex h-flex-center h-flex-space-between h-width-100p'>
                                <p class='b-switch-font h-bold h-mr-15'>
                                    {{ $t(half.label) }}
                                </p>
                                <FwSwitcher
                                    class='h-width-40'
                                    :isActive='half.state'
                                    @toggleSwitch='toggleSwitch(day, half, index)'>
                                </FwSwitcher>
                            </div>
                            <div class='b-switch-time h-width-100p'>
                                <div class='b-switch-time__input h-flex h-flex-center h-flex-space-between h-width-100p'>
                                    <label class='b-switch-font__label h-bold'>
                                        {{ $t('WORKER.TIME.SLOT.FROM') }}
                                    </label>
                                    <div style='width: 85px; position: relative;'
                                         class='b-calendar-select__week fw-select-base fw-select-base__time h-ml-10'>
                                        <div style='width: 100%;'>
                                            <div style='position: relative;'
                                                 :class='{ "b-calendar-select__week--active": !half.state}'
                                                 class='b-calendar-select__week fw-select-base fw-select-base__time'>
                                                <FwSelect
                                                    v-model='half.timeFrom'
                                                    :canNotDelete='true'
                                                    :disabled='!half.state'
                                                    :propsPlaceholder='checkTimeProps(half.timeFrom)'
                                                    :searchable='false'
                                                    :options='checkDayStatus(index, null, day)'
                                                    @input='checkMorningTime(day)'>
                                                </FwSelect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class='b-switch-time__input h-flex h-flex-center h-flex-space-between h-width-100p h-mt-10'>
                                    <label class='b-switch-font__label h-bold'>
                                        {{ $t('WORKER.TIME.SLOT.TO') }}
                                    </label>
                                    <div style='width: 85px; position: relative;'
                                         :class='{"b-calendar-select__week--active": !half.state}'
                                         class='b-calendar-select__week fw-select-base fw-select-base__time h-ml-10'>
                                        <div style='position: relative'>
                                            <FwSelect
                                                v-model='half.timeTo'
                                                :canNotDelete='true'
                                                :disabled='!half.state'
                                                :propsPlaceholder='checkTimeProps(half.timeTo)'
                                                :searchable='false'
                                                :options='checkDayStatus(index, half, day)'
                                                @input='checkAfternoonTime(day)'>
                                            </FwSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FwButton
            v-if='currentWorker'
            class='h-mv-30'
            :style='{
                padding: `0 22px`,
                height: `40px`,
                minWidth: `auto`
            }'
            :fontSize='`14px`'
            borderRadiusType='tiny-border'
            :disabled='isRequestSending || isRequestSendingNoPopup'
            @click.native='onSubmit'>
            {{ $t('BUTTON.CONFIRM.HOURS') }}
        </FwButton>
        <div v-else class='h-flex h-flex-dir-column'>
            <FwButton
                class='h-m-30'
                :style='{
                    padding: `0 22px`,
                    height: `40px`,
                    minWidth: `auto`
                }'
                :fontSize='`14px`'
                borderRadiusType='tiny-border'
                :disabled='isRequestSending || isRequestSendingNoPopup'
                @click.native='onSubmit'>
                {{ $t(`BUTTON.CONFIRM2`) }}
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import {
    WorkerTimeType,
    WorkerScheduleTimeType,
} from '@/types/Workers';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { ConfirmPopup } from '@/components/popups/ConfirmPopup';
import { WORKING_HOURS } from '@/helpers/dates';
import { workingHoursData } from '@/mocks/workingHours';

@Component({
    components: { ConfirmPopup },
})
export default class OnboardingWorkingHoursPage extends Vue {
    @Prop({ type: String, default: null }) readonly currentWorkerId!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    workingTimeValue: WorkerTimeType = workingHoursData;
    isRequestSendingNoPopup: boolean = false;
    timeDay: Array<string> = WORKING_HOURS;
    timeLunch: Array<string> = WORKING_HOURS;
    get switcherState() {
        const obj: { [key: string]: any; } = this.workingTimeValue;
        return Object.keys(obj).every(k => (
            obj[k].dayPart.lunch.state === false &&
            obj[k].dayPart.day.state === false &&
            obj[k].state === false
        ));
    }

    get workerAdvisorString() {
        return this.mainRouteGlobal === `/partners-onboarding` ? `WORKERS` : `ADVISORS`;
    }

    addMinutesMethod(time: string, minsToAdd: string) {
        function dateString(day: any) { return (day < 10 ? '0' : '') + day; }
        let result = null;
        if (time) {
            const piece = time.split(':');
            const minutes = (piece[0] as any) * 60 + +piece[1] + +minsToAdd;
            const dayHours = dateString(minutes % (24 * 60) / 60 | 0);
            const dayMinutes = dateString(minutes % 60);
            result = `${dayHours}:${dayMinutes}`;
        }
        return result;
    }

    checkDayStatus(value: string | null, halfDay: any, dayData: any) {
        let statusResult = null;
        if (value === `day`) {
            const arrMorning = [];
            let lunchFrom = null;
            let lunchTo = null;
            if (dayData) {
                lunchFrom = dayData.dayPart.lunch.timeFrom;
                lunchTo = dayData.dayPart.lunch.timeTo;
            }
            if (halfDay) {
                if (!lunchTo || !lunchFrom) {
                    for (let i = 0; i < this.timeDay.length; i++) {
                        if (this.timeDay[i] > halfDay.timeFrom) {
                            arrMorning.push(this.timeDay[i]);
                        } else if (!halfDay.timeFrom) {
                            halfDay.timeTo = halfDay.timeFrom;
                            arrMorning.push(this.timeDay[i]);
                        }
                    }
                } else {
                    for (let i = 0; i < this.timeDay.length; i++) {
                        if (halfDay.timeFrom && this.timeDay[i] >= lunchTo) {
                            arrMorning.push(this.timeDay[i]);
                        } else if (!lunchTo || !lunchFrom) {
                            arrMorning.push(this.timeDay[i]);
                        }
                    }
                }
                statusResult = arrMorning;
            } else {
                const arrFirstHalfMorning = [];
                for (let i = 0; i < this.timeDay.length; i++) {
                    if (this.timeDay[i] <= lunchFrom) {
                        arrFirstHalfMorning.push(this.timeDay[i]);
                    } else if (!lunchTo || !lunchFrom) {
                        arrFirstHalfMorning.push(this.timeDay[i]);
                    }
                }
                statusResult = arrFirstHalfMorning;
            }
        } else {
            const arrMorning = [];
            if (halfDay) {
                for (let i = 0; i < this.timeLunch.length; i++) {
                    if (this.timeLunch[i] > halfDay.timeFrom) {
                        arrMorning.push(this.timeLunch[i]);
                    } else if (!halfDay.timeFrom) {
                        arrMorning.push(this.timeDay[i]);
                    }
                }
                statusResult = arrMorning;
            } else {
                statusResult = this.timeLunch;
            }
        }
        return statusResult;
    }
    checkMorningTime(day: any) {
        if (day.dayPart.day.timeFrom > day.dayPart.lunch.timeFrom) {
            day.dayPart.day.timeFrom = day.dayPart.lunch.timeFrom;
        } else if ((day.dayPart.day.timeFrom > day.dayPart.day.timeTo)) {
            day.dayPart.day.timeTo = this.addMinutesMethod(day.dayPart.day.timeFrom, '15');
        } else if ((day.dayPart.lunch.timeFrom > day.dayPart.lunch.timeTo)) {
            day.dayPart.lunch.timeTo = this.addMinutesMethod(day.dayPart.lunch.timeFrom, '15');
        } else if ((day.dayPart.lunch.timeFrom) && !(day.dayPart.lunch.timeFrom)) {
            day.dayPart.lunch.timeTo = this.addMinutesMethod(day.dayPart.lunch.timeFrom, '15');
        }
    }
    checkAfternoonTime(day: any) {
        if (day.dayPart.day.timeTo < day.dayPart.lunch.timeTo) {
            day.dayPart.day.timeTo = day.dayPart.lunch.timeTo;
        } else if ((!day.dayPart.lunch.timeFrom && day.dayPart.lunch.timeTo)) {
            day.dayPart.lunch.timeFrom = this.addMinutesMethod(day.dayPart.lunch.timeTo, '-15');
        }
    }
    checkTimeProps(value: string) {
        return !value ? 'HH:MM' : value;
    }
    toggleSwitch(day: any, value: any, index: string) {
        value.state = !value.state;
        if (index === `lunch`) {
            day.dayPart.day.state = true;
            if (!value.state) {
                day.dayPart.lunch.timeFrom = null;
                day.dayPart.lunch.timeTo = null;
            } else if (!day.dayPart.day.state) {
                day.dayPart.day.timeFrom = '08:00';
                day.dayPart.day.timeTo = '18:00';
            } else {
                day.dayPart.lunch.timeFrom = '12:00';
                day.dayPart.lunch.timeTo = '14:00';
            }
        } else if (index === `day`) {
            day.dayPart.lunch.state = false;
            if (!value.state) {
                day.dayPart.day.timeFrom = null;
                day.dayPart.day.timeTo = null;
                day.dayPart.lunch.timeFrom = null;
                day.dayPart.lunch.timeTo = null;
            } else {
                day.dayPart.day.timeFrom = '08:00';
                day.dayPart.day.timeTo = '18:00';
            }
        }
    }

    async onSubmit() {
        const data: WorkerScheduleTimeType = {
            monday_start: this.workingTimeValue.monday.dayPart.day.timeFrom,
            monday_end: this.workingTimeValue.monday.dayPart.day.timeTo,
            monday_break_start: this.workingTimeValue.monday.dayPart.lunch.timeFrom,
            monday_break_end: this.workingTimeValue.monday.dayPart.lunch.timeTo,
            tuesday_start: this.workingTimeValue.tuesday.dayPart.day.timeFrom,
            tuesday_end: this.workingTimeValue.tuesday.dayPart.day.timeTo,
            tuesday_break_start: this.workingTimeValue.tuesday.dayPart.lunch.timeFrom,
            tuesday_break_end: this.workingTimeValue.tuesday.dayPart.lunch.timeTo,
            wednesday_start: this.workingTimeValue.wednesday.dayPart.day.timeFrom,
            wednesday_end: this.workingTimeValue.wednesday.dayPart.day.timeTo,
            wednesday_break_start: this.workingTimeValue.wednesday.dayPart.lunch.timeFrom,
            wednesday_break_end: this.workingTimeValue.wednesday.dayPart.lunch.timeTo,
            thursday_start: this.workingTimeValue.thursday.dayPart.day.timeFrom,
            thursday_end: this.workingTimeValue.thursday.dayPart.day.timeTo,
            thursday_break_start: this.workingTimeValue.thursday.dayPart.lunch.timeFrom,
            thursday_break_end: this.workingTimeValue.thursday.dayPart.lunch.timeTo,
            friday_start: this.workingTimeValue.friday.dayPart.day.timeFrom,
            friday_end: this.workingTimeValue.friday.dayPart.day.timeTo,
            friday_break_start: this.workingTimeValue.friday.dayPart.lunch.timeFrom,
            friday_break_end: this.workingTimeValue.friday.dayPart.lunch.timeTo,
            saturday_start: this.workingTimeValue.saturday.dayPart.day.timeFrom,
            saturday_end: this.workingTimeValue.saturday.dayPart.day.timeTo,
            saturday_break_start: this.workingTimeValue.saturday.dayPart.lunch.timeFrom,
            saturday_break_end: this.workingTimeValue.saturday.dayPart.lunch.timeTo,
            sunday_start: this.workingTimeValue.sunday.dayPart.day.timeFrom,
            sunday_end: this.workingTimeValue.sunday.dayPart.day.timeTo,
            sunday_break_start: this.workingTimeValue.sunday.dayPart.lunch.timeFrom,
            sunday_break_end: this.workingTimeValue.sunday.dayPart.lunch.timeTo,
        };
        Object.keys(data).forEach(i => {
            // @ts-ignore-next-line
            if (data[i]) {
                // @ts-ignore-next-line
                data[i] = `${data[i]}:00`;
            }
        });
        if (this.currentWorkerId) {
            this.isRequestSendingNoPopup = true;
            try {
                await WorkersWebApi.confirmAdvisorSchedulesData(data, this.currentWorkerId);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CONFIRMED', true);
                this.$emit('confirmWorkingHours');
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSendingNoPopup = false;
            }
        } else {
            this.isRequestSendingNoPopup = true;
            try {
                await WorkersWebApi.confirmAdvisorsSchedulesData(data);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CONFIRMED', true);
                this.$router.push(`${this.mainRouteGlobal}/invitations/choose`);
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSendingNoPopup = false;
            }
        }
    }
}
</script>

<style lang='sass'>
    .b-working-hours
        .fw-select-base .multiselect
            height: 30px!important
            width: 81px!important
            border-radius: 8px!important
            background-color: #FFFFFF
            box-shadow: $big-box-shadow !important
            &::after
                bottom: 10px
                height: 10px
            .multiselect__tags
                background: #fff
                font-size: 10px!important
                font-weight: 500!important
                line-height: 12px!important
                max-height: 30px!important
                min-height: 30px!important
                .multiselect__single
                    min-height: 30px
            .fw-select__caret svg
                width: 8px!important
            .multiselect__element
                background: #fff!important
                padding: 0 5px
                font-size: 10px
            .multiselect__option
                display: flex
                justify-content: flex-start
                align-items: center
                color: #213F6B
                font-weight: 500!important
                line-height: 12px!important
                padding: 5px 5px!important
                background: #fff!important
            .multiselect__option--highlight
                color: #fff!important
                border-radius: 5px!important
                background: $aqua-marine !important
        .b-switch-time-day
            &__block
                background-color: #F0F2F5
                border-radius: 10px
            .mx-datepicker--form
                background: #fff
                box-shadow: $big-box-shadow
            .mx-datepicker
                height: 30px
                input::placeholder
                    line-height: normal
                    text-align: left
                    display: flex
                    justify-content: center
                    align-items: center
                    font-size: 10px
                    font-weight: 500
            .mx-datepicker-popup
                background: #fff
                margin-top: 35px
            .mx-input-wrapper
                input
                    text-align: left
                    font-size: 11px
                    font-weight: 500
            .b-switch-font
                font-size: 10px
                &__label
                    font-size: 10px
                    opacity: 0.3
                    color: rgb(33, 63, 107)
            .mx-input-append
                margin-right: 12px!important
    .b-calendar-select__week--active
        .multiselect__tags
            opacity: 0.3!important
            color: #213F6B!important
    .b-switch-day-time__half:nth-child(1)
        margin-top: 10px
    @include media('<tablet')
        .b-working-hours
            flex-flow: row wrap
</style>
