<template>
<div class='b-add-hub-team__wrapper container'>
    <div class='b-base-title__wrapper'>
        <h2 class='b-appointment-left-bar__title'>
            {{ currentRoute ? $t('HUB.POPUP.TITLE.EDIT') : $t('ONBOARDING.OPTION.HUB.TITLE') }}
        </h2>
    </div>

    <form @keydown.stop.prevent.enter='sendDataToServer'>
        <div class='h-flex h-flex-space-between h-mb-30'>
            <FwFormInput
                class='h-flex-1-0 h-mr-30'
                :fieldHasError='fieldHasError(TEAM_NAME)'
                labelType='label-medium'
                :title='`${$t(`ONBOARDING.OPTION.TEAMS.FORM.TITLE`)}*`'>
                <FwInput
                    v-focus='true'
                    :value='team_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`ONBOARDING.OPTION.TEAMS.FORM.TITLE`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(TEAM_NAME)'
                    @update:value='value => team_name = value'
                    @input='clearServerErrorsBase(TEAM_NAME)'
                    @blur='addCurrentInputToValidateArray(TEAM_NAME)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-mb-30 b-required-field'>
            * {{ $t(`LABEL.REQUIRED.FIELD`) }}
        </div>
    </form>

    <portal to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                size='little'
                :disabled='isDisabled'
                borderRadiusType='small-border'
                @click='sendDataToServer'>
                {{ currentRoute ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
            </FwButton>
        </div>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { State, namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import { TEAM_NAME } from '@/helpers/hub';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { HubCreateType, CategoryBranchHubType } from '@/types/Appointment';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    validators: {
        [TEAM_NAME](value: string) {
            return this.requiredValidator({ value });
        },
    },
})
export default class AppointmentHubAddTeam extends Mixins(ValidationMixin) {
    @AppointmentStore.Mutation('setHubData') setHubData!: (siteData: CategoryBranchHubType) => void;
    @State(state => state.TeamsStore.currentTeam) currentTeam!: HubCreateType;
    team_name: string | null = '';
    sendRequest: boolean = false;

    serverErrors: serverErrors = {
        team_name: null,
    };

    get disabledFormButton() {
        return !this.team_name;
    }
    get currentRoute() {
        return this.$route.params.id;
    }

    get isDisabled(): boolean {
        return this.disabledFormButton || this.sendRequest;
    }

    async sendDataToServer() {
        if (this.isDisabled) return;
        this.sendRequest = true;
        try {
            const { hub } = this.currentRoute ?
                await OnboardingApi.updateOnboardingHub(
                    { name: this.team_name } as HubCreateType, this.currentRoute as string,
                ) :
                await OnboardingApi.createOnboardingHub({ name: this.team_name } as HubCreateType);
            if (this.$route.meta) {
                this.$router.push({ path: `${this.mainRouteGlobal}${this.$route.meta.addTeamNextPostfix}` });
            }
            this.setHubData(hub);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.sendRequest = false;
        }
    }
    async created() {
        if (this.currentRoute) {
            const { hub } = await OnboardingApi.getOnboardingHub(this.currentRoute);
            this.team_name = hub.name;
        }
    }
}
</script>
