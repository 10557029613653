<template>
<div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-if='workersListArray.length && !loading'
         class='b-add-appointment-categories__wrapper'>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $t(workersAdvisors) }}
            </h2>
            <p class='h-font-14 h-fw-500'>
                {{ $t('WORKER.LIST.NEXT.STEP') }} {{ $t(workersAdvisors) }} {{ $t('WORKER.LIST.NEXT.STEP2') }}
            </p>
            <FwHorizontalScroll
                v-if='workersListArray.length'
                :isPageLoaded='true'
                :style='scrollStyle'
                slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force h-mt-30'
                style='padding-bottom: 100px;'
                iconClass='h-opacity-1'
                newClass='fw-arrow-big-circle-fixed'
                :arrowBgWidth='null'
                colorIcon='#fff'
                btnLocation='between'>
                <template #content>
                    <CalendarTable
                        :titles='workersListTitleArray'
                        :data='workersListArray'
                        :bigIndexes='bigIndexes'
                        class='b-calendar__table__wrapper'>
                        <div
                            v-for='(item, i) in workersListArray'
                            :key='i'
                            class='b-calendar__table'>
                            <div class='b-calendar__table__outer'>
                                <div v-for='(key, j) in mapping'
                                     :key='j'
                                     class='b-calendar__table__item'
                                     :class='{ "b-calendar__table__item--big": bigIndexes.includes(j) }'>
                                    <template v-if='item[key] && item[key].length'>
                                        <span class='h-overflow-text'>
                                            {{ item[key] }}
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <CalendarTableButtons
                                @edit='editWorker(item)'
                                @delete='deleteWorker(item)'>
                            </CalendarTableButtons>
                        </div>
                    </CalendarTable>
                </template>
            </FwHorizontalScroll>
            <div v-else>
                We have not technicians yet
            </div>
        </div>
        <FwPagination
            v-if='totalPage > perPage'
            style='position: sticky; bottom: 60px;'
            :totalPage='totalPage'
            :currentPage='currentPage'
            :perPage='perPage'
            :value='perPage'
            @getData='getTechnicians'>
        </FwPagination>
    </div>
    <div v-else-if='!loading' class='h-flex-dir-column h-flex-center h-mt-50'>
        <h1>
            {{ $t('ONBOARDING.NO.ITEMS.ADVISOR') }}
        </h1>
        <FwButton
            class='h-ml-10 h-width-150'
            size='little'
            borderRadiusType='small-border'
            @click='$router.push({ path: `${mainRouteGlobal}${userKindBoth ? `/both/advisors/add-more` : `/advisors/add-more`}` })'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
    <portal v-if='!isRecapRoute' to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                size='little'
                color='skip'
                borderRadiusType='small-border'
                @click='$router.push({ path: `${mainRouteGlobal}${userKindBoth ? `/both/advisors/add-more` : `/advisors/add-more`}` })'>
                {{ $t('BUTTON.ADD.MORE') }}
            </FwButton>
            <FwButton
                class='h-ml-10 h-width-150'
                :to='$route.meta.next'
                :disabled='!workersListArray.length'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
    </portal>
    <portal v-else to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                class='h-ml-10 h-width-150'
                :to='`${mainRouteGlobal}/recap`'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('Go to recap') }}
            </FwButton>
        </div>
    </portal>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.ADVISOR.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.ADVISOR.TEXT`)'
        @deleteItem='deleteItem'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { WorkersListDataType, pagePaginationData } from '@/types/Appointment';
import { calculateNextPage } from '@/helpers/pagination';

@Component({
    components: {
        DeletePopup,
        CalendarTable,
        CalendarTableButtons,
    },
})
export default class AppointmentAdvisorsList extends Mixins(ValidationMixin, TranslateMixin) {
    loading: boolean = false;
    currentPage: number = 1;
    totalPage: number = 0;
    perPage: number = 6;
    workersListTitleArray: Array<string> = [
        'LABEL.FIRST.NAME',
        'LABEL.LAST.NAME',
        // 'LABEL.SKILLS',
        'LABEL.PHONE',
        'LABEL.EMAIL',
        // 'LABEL.ADMIN',
        // 'LABEL.GEOGRAPHIC.ZONE',
    ];
    mapping: Array<string> = [
        `first_name`,
        `last_name`,
        // `skills`,
        `phone`,
        `email`,
        // `time_zone`,
    ];
    bigIndexes: Array<number> = [3];
    workersListArray: Array<WorkersListDataType> = [];
    selectedIndex: number | null = null;
    selectedName: string = '';
    confirmPopup: boolean = false;
    isRequestSending: boolean = false;
    currentWorker: WorkersListDataType | null = null;
    $refs!: {
        leftBar: HTMLElement
    };

    async deleteItem() {
        this.confirmPopup = false;
        try {
            if (this.currentWorker) {
                this.isRequestSending = true;
                try {
                    // @ts-ignore-next-line
                    await OnboardingApi.deleteOnboardingAdvisor(this.currentWorker.id);
                    this.currentPage = calculateNextPage({
                        page: this.currentPage,
                        per_page: this.perPage,
                        total_items: this.totalPage,
                    });
                    this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
                    this.getTechnicians(this.currentPage, this.perPage);
                } finally {
                    this.isRequestSending = false;
                }
            }
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    get userKindBoth() {
        return this.user().account_organization_type === 'with_all';
    }

    get workersAdvisors() {
        return this.mainRouteGlobal === `/partners-onboarding` ? 'LABEL.TECHNICIANS' : 'BREADCRUMBS.ADVISORS';
    }

    get scrollStyle() {
        if (this.$refs.leftBar && this.$el) {
            return {
                width: `${(this.$el as HTMLElement).offsetWidth - this.$refs.leftBar.offsetWidth}px`,
            };
        }
        return null;
    }

    deleteWorker(worker: WorkersListDataType) {
        this.currentWorker = worker;
        this.confirmPopup = true;
    }

    disabledInput(index: number, name: string) {
        return index === this.selectedIndex && name === this.selectedName;
    }

    enableInput(index: number, name: string) {
        this.selectedIndex = index;
        this.selectedName = name;
    }

    async getTechnicians(el: number, val: number) {
        this.currentPage = el;
        const payload: pagePaginationData = {
            current_page: el,
            per_page: val,
        };
        this.loading = true;
        const data = await OnboardingApi.getOnboardingAdvisors(payload);
        // @ts-ignore-next-line
        this.totalPage = data.meta.total_count;
        this.workersListArray = data.advisors;
        this.loading = false;
    }

    async editWorker(worker: WorkersListDataType) {
        return this.isRecapRoute ?
            this.$router.push({ path: `${this.mainRouteGlobal}${`/recap/appointment/edit-advisor/`}${worker.id}` }) :
            this.$router.push({ path: `${this.mainRouteGlobal}${this.userKindBoth ? `/both/appointment/edit-advisor/` : `/appointment/edit-advisor/`}${worker.id}` });
    }

    async created() {
        await this.getTechnicians(this.currentPage, this.perPage);
    }
}
</script>
<style lang='sass'>
.b-add-appointment
    &__wrapper
        @include media('<=phone')
            width: 100%
        .b-time-picker
            background: #ffffff
            height: 60px
.b-appointment-categories__body
    &--items
        margin-top: 10px
        border-radius: 5px
    &--item
        background: #ffffff
        input
            font-weight: 500!important
            font-size: 14px
    &--delete
        display: flex
        justify-content: center
        align-items: center
        background: #ffffff
        margin-left: 10px
        height: 60px
        width: 60px
        border-radius: 5px
    .fw-select-white
        box-shadow: none!important
        .multiselect__tags
            padding-left: 15px
        .multiselect__placeholder
            font-size: 14px
            color: $dark-blue
            font-weight: 500
        .multiselect__single
            font-size: 14px
            color: $dark-blue
            font-weight: 500
        .multiselect__element
            font-size: 14px
            color: $dark-blue
            font-weight: 400

</style>
