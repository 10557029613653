<template>
<component
    :is='canRead ? `router-link` : `div`'
    v-bind='getAttrs(branch.id)'
    class='b-branch-card__wrapper'>
    <FwSimpleCard
        width='100%'
        height='auto'
        padding='10px 30px'
        :isActive='canRead'
        :isDisabled='!canRead'>
        <div class='h-flex h-flex-dir-column h-width-100p'>
            <div class='b-branch-card__title h-font-14'>
                {{ branch.name | capitalizeAll }}
            </div>
            <div class='b-branch-card__workers-wrapper'>
                <div v-if='branch.advisors_count' class='b-branch-card__workers-title h-font-12'>
                    <span>
                        {{ branch.advisors_count }}
                    </span>
                    <span v-if='branch.advisors_count !== 0' class='h-ml-5'>
                        {{ $tc('CHOOSE_MANAGE.COMMON.ADVISOR.PLURAL', branch.advisors_count) }}
                    </span>
                    <span v-else class='h-ml-5'>
                        {{ $tc('CHOOSE_MANAGE.COMMON.NO.ADVISOR.PLURAL', branch.advisors_count.length) }}
                    </span>
                </div>
            </div>
        </div>
        <div v-if='branch.users && branch.users.length !== 0' class='h-flex'>
            <div v-for='worker in workersPart'
                 class='b-branch-card__worker-inner'>
                <WorkerAvatar
                    :worker='worker'
                    class='b-branch-card__worker'
                    :avatarSize='23'
                    :hideInfo='true'
                    align='right'>
                </WorkerAvatar>
            </div>
        </div>
    </FwSimpleCard>
</component>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BranchHubAvatarsResponseType } from '@/types/Appointment';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import PermissionsMixin from '@/mixins/permissions';

const MAX_WORKERS_QUANTITY = 5;
@Component({
    components: {
        WorkerAvatar,
    },
})
export default class BranchCard extends Mixins(PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly branch!: BranchHubAvatarsResponseType;

    getAttrs(id: string): null | { to: string } {
        return this.canRead ? { to: `/consult/branch/${id}` } : null;
    }

    get workersPart(): BranchHubAvatarsResponseType {
        let val = null;
        if (this.branch && this.branch.users) {
            val = (this.branch.users as any).slice(0, MAX_WORKERS_QUANTITY);
        }
        return val;
    }
}
</script>

<style lang='sass'>
$worker-size: 25px
$card-min-height: 70px

.b-branch-card
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    &__wrapper
        display: flex
        max-width: 395px
        flex: 0 0 50%
        min-height: $card-min-height
        align-items: center

        @include media('<=phone')
            flex: 1
            width: 100%

        .fw-simple-card-wrapper
            min-height: $card-min-height

    &__title
        font-weight: 600
        color: $alt-blue

    &__workers-wrapper
        display: flex
        align-items: center

    &__worker
        width: $worker-size
        height: $worker-size
        border-radius: 50%
        border: 1px solid #FFFFFF
        position: relative
        background-size: cover
        background-position: center
        background-color: lighten($main-green, 30)

    &__worker-inner
        width: calc($worker-size / 1.4)

    &__workers-title
        display: flex
        align-items: center
        font-weight: 300
        color: $main-gray
        text-transform: uppercase

@include media('<tablet')
    .b-branch-card
        width: 100%
        &__wrapper
            margin: 5px 0

</style>
