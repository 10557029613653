<template>
<div>
    <form class='b-add-branch__form h-flex-dir-column'
          @keydown.stop.prevent.enter='onSubmit'>
        <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
            {{ $t('LABEL.CLIENT.INFORMATION') }}
        </h3>
        <div class='h-flex h-width-100p'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'
                :title='`${$t("LABEL.NAME")}`'>
                <FwInput
                    :value='client_full_name'
                    type='text'
                    name='text'
                    disabled
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mh-20'
                style='flex: 0 0 260px'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE`)}`'>
                <FwInput
                    :value='client_phone'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.ID`)}`'>
                <FwInput
                    :value='client_id'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
        </div>
        <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
            {{ $t('LABEL.TECH.INFO') }}
        </h3>
        <div class='h-flex h-width-100p'>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.NAME`)}`'>
                <FwInput
                    :value='advisor_technician_full_name'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mh-20'
                style='flex: 0 0 260px'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE`)}`'>
                <FwInput
                    :value='advisor_technician_phone'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'
                :title='`${$t(`CANCEL.ORIGIN.PARTNER.BUSINESS`)}`'>
                <FwInput
                    :value='company_title'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
        </div>
        <template v-if='appointmentData.category === `location_category_on_site`'>
            <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                {{ $t('LABEL.APPT.LOCATION') }}
            </h3>
            <FwFormInput
                class='h-flex-1-0 h-mh-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.ADDRESS`)}`'>
                <FwInput
                    :value='location'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger-shadow'>
                </FwInput>
            </FwFormInput>
        </template>
    </form>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';

import {
    CLIENT_NAME,
    CLIENT_PHONE,
    CLIENT_ID,
    ADVISOR_TECHNICIAN_FULL_NAME,
    ADVISOR_TECHNICIAN_PHONE,
    COMPANY_TITLE,
    LOCATION,
} from '@/helpers/events';
import { AppointmentSearchType } from '@/types/Appointment';
import ValidationMixin from '@/mixins/validation';

@Component
export default class PartnerAppointmentRecallForm extends Mixins(ValidationMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly eventTime!: string;

    disabledForm: boolean = true;

    client_full_name: string = '';
    client_phone: string = '';
    client_id: string = '';
    advisor_technician_full_name: string = '';
    advisor_technician_phone: string = '';
    company_title: string = '';
    location: string = '';
    isActiveAdminRequestSending: boolean = false;
    isActiveAdmin: boolean = false;
    serverErrors: serverErrors = {};

    updateFormByServerValues(): void {
        if (this.appointmentData) {
            if (this.appointmentData.client_full_name) {
                this.client_full_name = this.appointmentData.client_full_name;
            }
            if (this.appointmentData.client_phone) {
                this.client_phone = this.appointmentData.client_phone;
            }
            if (this.appointmentData.client_external_id) {
                this.client_id = this.appointmentData.client_external_id;
            }
            if (this.appointmentData.advisor_technician_full_name) {
                this.advisor_technician_full_name = this.appointmentData.advisor_technician_full_name;
            }
            if (this.appointmentData.advisor_technician_phone) {
                this.advisor_technician_phone = this.appointmentData.advisor_technician_phone;
            }
            if (this.appointmentData.company_title) {
                this.company_title = this.appointmentData.company_title;
            }
            if (this.appointmentData.location) {
                this.location = this.appointmentData.location;
            }
        }
    }

    created(): void {
        this.updateFormByServerValues();
    }

    @Emit('onSubmit')
    onSubmit() {}
}
</script>

<style lang='sass'>
.b-add-branch__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px

    &__row
        display: flex
        width: 100%
        margin-bottom: 0

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-branch__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7
</style>
