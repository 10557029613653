export default {
    'FINAL.STEPS.TITLE': 'Final steps',
    'FINAL.STEPS.ASIDE': 'You need to complete these last steps:',
    'FINAL.STEPS.WORKERS.TITLE': 'Workers',
    'FINAL.STEPS.WORKERS.OPTION.1': 'Confirm your technicians working hours',
    'FINAL.STEPS.ADVISORS.OPTION.1': 'Confirm your advisors working hours',
    'FINAL.STEPS.WORKERS.OPTION.2': 'Invite them to synchronise their calendars',
    'FINAL.STEPS.ROOMS.TITLE': 'Rooms',
    'FINAL.STEPS.ROOMS.OPTION.1': 'Confirm your rooms opening hours ',
    'FINAL.STEPS.ROOMS.OPTION.2': 'Invite them to synchronise their calendars',
    'FINAL.STEPS.ROOMS.OPTION.3': 'Fill in your rooms’ unavailabilities',
    'FINAL.STEPS.BUTTON': 'Let\'s go',

    'FINAL.WORKING.HOURS.TITLE': 'Technicians - Working hours',
    'FINAL.WORKING.HOURS.ASIDE': 'What are your technicians working hours?',
    'FINAL.WORKING.HOURS.WORKERS.OPTION.1': 'All my technicians have identical working hours',
    'FINAL.WORKING.HOURS.ADVISORS.OPTION.1': 'All my advisors have identical working hours',
    'FINAL.WORKING.HOURS.WORKERS.OPTION.2': 'Each technician has specific working hours',
    'FINAL.WORKING.HOURS.ADVISORS.OPTION.2': 'Each advisor has specific working hours',

};
