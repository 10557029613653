<template xmlns:v-slot='http://www.w3.org/1999/XSL/Transform'>
<div class='b-address-branch'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div :style='{ opacity: loading ? 0 : 1 }'>
        <div class='h-text-center h-pt-30'>
            <h1>
                {{ $t('SELECT.ADDRESS.BRANCH.TITLE') }}
            </h1>
        </div>
        <div style='align-items: flex-start' class='h-flex h-flex-center'>
            <div v-if='haveDataForMap'
                 class='h-flex h-flex-center h-pr-15'>
                <div ref='map' class='b-branch-office-map-size'></div>
            </div>
            <div class='fw-select-address b-classic-width-input'>
                <p class='h-mb-15 h-bold h-mt-0'>
                    {{ $t('SELECT.ADDRESS.BRANCH.CUSTOMER') }}
                </p>
                <FwSpinLoader
                    v-if='!optionsGot'
                    :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                    class='h-flex-center loader'
                    :isActive='!optionsGot'
                    className='h-p-20'>
                </FwSpinLoader>
                <FwSelect
                    v-else
                    v-model='value'
                    v-focus='true'
                    :multiple='false'
                    label='address'
                    class='b-address-branch__input--disable'
                    :clear-on-select='false'
                    :propsPlaceholder='value ? value.address : $t(`SELECT.ADDRESS.APPOINTMENT.PLACEHOLDER`)'
                    type='white'
                    noOptions='No results matching given address'
                    :options='options'
                    :disabled='true'>
                    <template v-slot:option='{ props }'>
                        <slot
                            :props='props'
                            name='option'>
                            <div v-if='options.length'
                                 :class='{ "multiselect__content-item--active-item": client && props.option.external_id === client.branch_external_id }'
                                 class='multiselect__content-item'
                                 @click='goToInterventionPage(props.option)'>
                                <div class='multiselect__item-header h-flex h-flex-dir-column h-pl-15 b-multiselect__item-header'>
                                    <span class='multiselect__title-first h-text-overflow-ellipsis'>
                                        {{ props.option.name }}
                                    </span>
                                    <span class='multiselect__title-second h-text-overflow-ellipsis h-font-16'>
                                        {{ props.option.address }}
                                    </span>
                                </div>
                                <FwIcon
                                    class='h-flex-center h-pointer h-mr-15'
                                    icon='arrow-right'
                                    size='14'
                                    color='#27DBBD'>
                                </FwIcon>
                            </div>
                        </slot>
                    </template>
                    <template #noOptions>
                        <div class='h-p-10 b-address-branch__input__no-results'>
                            No results matching given address
                        </div>
                    </template>
                </FwSelect>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GoogleApi from '@/mixins/google-api';
import { GoogleMapPlaceType, OptionGoogleAddressType, ParsedAddressData, GoogleMapCenterType } from '@/types/GoogleMap';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { BranchAddressType } from '@/types/Appointment';
import NoDataMixin from '@/mixins/no-data-mixin';
import AppointmentMixin from '@/mixins/appointment';
import { ClientType } from '@/types/User';

const AppointmentStore = namespace('AppointmentStore');
const BreadCrumbsStore = namespace('BreadCrumbsStore');

@Component
export default class AppointmentChooseBranchOfficePage extends Mixins(GoogleApi, AppointmentMixin, NoDataMixin) {
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @AppointmentStore.State('appointmentPlace') appointmentPlace!: GoogleMapPlaceType;
    @AppointmentStore.State('placeParsedData') placeParsedData!: ParsedAddressData;
    @AppointmentStore.State('client') client!: ClientType | null;
    @AppointmentStore.Mutation('setBranchAddress') setBranchAddress!: (branchAddress: BranchAddressType) => void;
    @AppointmentStore.Mutation('setPlaceParsedData') setPlaceParsedData!: (parsedAddressData: ParsedAddressData) => void;

    value: OptionGoogleAddressType | null = null;
    options: Array<BranchAddressType | OptionGoogleAddressType> = [];
    mapGetParam: string = `callback=initMap`;
    optionsGot: boolean = false;
    loading: boolean = true;
    place: null | GoogleMapPlaceType = null;
    post_code: null | string = null;
    country: null | string = null;
    city: null | string = null;

    $refs!: {
        map: HTMLElement
    };

    get haveDataForMap(): boolean {
        return Boolean(!this.client || (this.client && this.client.latitude && this.client.last_name));
    }

    async getSites(code: string) {
        if (code) {
            const branch_external_id = this.client ? this.client.branch_external_id : null;
            let options: Array<BranchAddressType> = [];
            if (this.isFaceToFaceFlow || this.isBranchByPhonePublicFlow || this.isBranchByVideoPublicFlow) {
                options = await AppointmentWebApi.getBranchAddresses(code, branch_external_id);
            } else if (this.isVideoconferenceFlow || this.isBranchByPhonePrivateFlow || this.isBranchPrivateVideoFlow || this.isBranchByVideoPrivateFlow) {
                options = await AppointmentWebApi.getBranchAddressesVideo(code, branch_external_id);
            }
            this.options = this.client ? options.sort((x, y) => {
                /* eslint-disable-next-line no-nested-ternary */
                return x.external_id === this.client!.branch_external_id ? -1 : y.external_id === this.client!.branch_external_id ? 1 : 0;
            }) : options;
            this.optionsGot = true;
        }
    }

    created() {
        if (!this.appointmentPlace && !this.client) {
            this.$_NoDataMixin_bookFlowNoData();
        }
    }

    async mounted() {
        if (this.haveDataForMap) {
            window.initMap = () => {
                let center: null | GoogleMapCenterType = null;
                if (this.client) {
                    center = { lat: parseFloat(this.client.latitude!), lng: parseFloat(this.client.longitude!) };
                } else {
                    const { location } = this.appointmentPlace.geometry;
                    center = { lat: location.lat(), lng: location.lng() };
                }
                this.map = new window.google.maps.Map(this.$refs.map, { zoom: 7, center });
            };
        }
        const value: OptionGoogleAddressType = {
            post_code: this.placeParsedData.post_code,
            country: this.placeParsedData.address,
            city: this.placeParsedData.country,
            address: this.placeParsedData.calizyAddress,
        };
        this.options.push(value);
        this.value = value;
        this.loading = false;
        this.getSites(this.placeParsedData.post_code);
    }

    beforeDestroy() {
        if (this.$refs.map) {
            this.$refs.map.remove();
        }
    }

    goToInterventionPage(branchAddress: BranchAddressType) {
        this.setBranchAddress(branchAddress);
        if (this.isFaceToFaceFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-face-to-face` });
        } else if (this.isVideoconferenceFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-videoconference` });
        } else if (this.isBranchPrivateVideoFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-face-to-face-private` });
        } else if (this.isBranchByPhonePublicFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-phone-public` });
        } else if (this.isBranchByPhonePrivateFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-phone-private` });
        } else if (this.isBranchByVideoPublicFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-video-public` });
        } else if (this.isBranchByVideoPrivateFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-video-private` });
        }
    }
}
</script>

<style lang='sass'>
.b-address-branch
    .multiselect__content-wrapper
        width: 100% !important

    .multiselect__option.multiselect__option--selected .multiselect__title-second
        font-weight: normal

    .multiselect__option.multiselect__option--selected
        background-color: #757575 !important

    .multiselect__content
        background-color: #fff
        max-width: 100%

    .multiselect__content-wrapper
        display: block !important
        margin-top: 15px !important
        max-height: 320px !important

    &__input--disable
        &.fw-select-disabled
            opacity: 1

    &__input__no-results
        color: #203f6a

.fw-select-address .multiselect .fw-select__caret
    opacity: 0

.fw-select-address
    .fw-select-white
        box-shadow: none!important

.b-branch-office-map-size
    width: 390px
    height: 400px

.fw-select-address .multiselect__placeholder
    padding-top: 0 !important

.b-multiselect__item-header
    width: calc(100% - 35px)

.multiselect__content-item--active-item
    background-color: rgba(33, 63, 107, 0.15)
    path
        fill: #213F6B !important
</style>
