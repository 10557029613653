<template>
<div class='b-add-closures-slot-form'>
    <div v-if='itemLoading'
         class='b-add-closures-slot-form__loading'>
        <FwSpinLoader
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader h-width-100p h-height-100p'
            :isActive='itemLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </div>
    <div class='b-add-closures-slot-form__title'>
        {{ formTitle }}
    </div>
    <div v-if='date'
         class='b-add-closures-slot-form__date qa-branch-closures-form__date'>
        {{ dateTitle }}
    </div>

    <span class='b-add-closures-slot-form__close_popup'>
        <FwIcon
            icon='close'
            size='15'
            color='#BEC7D4'
            @click.native='close'>
        </FwIcon>
    </span>

    <div class='b-add-closures-slot-form__wrapper'>
        <div>
            <FwLabelCard
                class='h-mb-10 qa-branch-closures__date-label'
                height='40px'
                padding='0 15px'>
                <div class='b-add-closures-slot-form__label h-flex-space-between'>
                    <div class='b-add-closures-slot-form__label__text h-uppercase h-mr-10'>
                        {{ $t('SETTINGS.CALENDAR.FROM') }}
                    </div>
                    <div class='h-flex'>
                        <div class='h-pos-rel b-calendar-select__week fw-select-base fw-select-base__time h-flex-1-0 h-width-115'>
                            <FwDatepicker
                                v-model='startDay'
                                :calendarPanelKey='calendarPanelKey'
                                class='b-add-closures-slot-form__day_datepicker'
                                name='datepicker'
                                :propsRange='false'
                                :selectMode='false'
                                propsFormat='DD MMM YY'
                                :hideIcon='true'
                                pickerStyle='form'
                                iconName='arrow-bottom'
                                iconSize='10'
                                :customArrowColor='true'
                                :iconRight='true'
                                openOnTop
                                :propsLang='`${$i18n.locale}`'
                                :disabledDate='disabledDateByObjectStartDay'
                                :defaultValue='date'
                                :disabled='!isEventInFuture'
                                propsInputClass='h-text-center'>
                                <template #icon>
                                    &nbsp;
                                </template>
                            </FwDatepicker>
                        </div>
                    </div>
                </div>
            </FwLabelCard>

            <FwLabelCard
                class='h-mb-10'
                height='40px'
                padding='0 15px'>
                <div class='b-add-closures-slot-form__label h-flex-space-between'>
                    <div class='b-add-closures-slot-form__label__text h-uppercase h-mr-10'>
                        {{ $t('SETTINGS.CALENDAR.TO') }}
                    </div>
                    <div class='h-flex'>
                        <div class='h-pos-rel b-calendar-select__week fw-select-base fw-select-base__time h-flex-1-0 h-width-115'>
                            <FwDatepicker
                                v-model='endDay'
                                class='b-add-closures-slot-form__day_datepicker'
                                :calendarPanelKey='calendarPanelKey'
                                name='datepicker2'
                                :propsRange='false'
                                :selectMode='false'
                                propsFormat='DD MMM YY'
                                :hideIcon='true'
                                pickerStyle='form'
                                iconName='arrow-bottom'
                                iconSize='10'
                                openOnTop
                                :propsLang='`${$i18n.locale}`'
                                :customArrowColor='true'
                                :iconRight='true'
                                :disabledDate='disabledDateByObjectEndDay'
                                :defaultValue='date'
                                :disabled='!isEventInFuture'
                                propsInputClass='h-text-center'>
                                <template #icon>
                                    &nbsp;
                                </template>
                            </FwDatepicker>
                        </div>
                    </div>
                </div>
            </FwLabelCard>
        </div>
    </div>

    <div class='h-flex-center b-add-closures-slot-form__button h-mh--10'>
        <FwButton
            v-if='instanceData && isEventEditInFuture'
            size='little'
            class='b-add-closures-slot-form__button__inner'
            color='alert'
            :disabled='isRequestSendingStatus'
            borderRadiusType='tiny-border'
            @click.stop.prevent='removeSlot'>
            {{ $t('LABEL.DELETE') }}
        </FwButton>
        <FwButton
            v-if='isEventInFuture || isEventEditInFuture'
            size='little'
            class='b-add-closures-slot-form__button__inner qa-branch-closures__button-submit'
            :disabled='!isButtonAvailable || isRequestSendingStatus'
            borderRadiusType='tiny-border'
            @click.stop.prevent='addSlot'>
            {{ instanceData ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import dayjs from 'dayjs';
import {
    formatDateForTitle,
    dateToString,
    parseToUTCDate,
} from '@/helpers/dates';
import DateMixin from '@/mixins/dateMixin';
import { ClosuresItemType } from '@/types/Consult';

@Component
export default class AddClosuresSlotForm extends Mixins(DateMixin) {
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Object, default: null }) readonly instanceData!: ClosuresItemType;
    @Prop({ type: Boolean, default: false }) readonly isRequestSendingStatus!: boolean;

    calendarPanelKey: number = 1;
    startDay: Date = this.date;
    endDay: Date = this.date;
    itemLoading: boolean = false;

    get formTitle(): TranslateResult {
        return this.instanceData ? this.$t('CONSULT.UPDATE_CLOSURES') : this.$t('CONSULT.ADD_CLOSURES');
    }

    get dateTitle(): string {
        return formatDateForTitle(this.date);
    }

    get disabledDateByObjectStartDay() {
        return {
            disablePrevDays: new Date(),
        };
    }

    get disabledDateByObjectEndDay() {
        return {
            disablePrevDays: new Date(),
        };
    }

    get isEventInFuture(): boolean {
        if (!this.instanceData) {
            return this.date >= parseToUTCDate(dateToString(new Date(), true));
        }
        return parseToUTCDate(this.instanceData.dt_end) >= new Date();
    }

    get isEventEditInFuture(): boolean {
        if (!this.instanceData) {
            return false;
        }
        return parseToUTCDate(this.instanceData.dt_end) >= new Date();
    }

    get isButtonAvailable(): boolean {
        if (!this.instanceData) {
            return true;
        }
        return !dayjs(this.endDay).isSame(parseToUTCDate(this.instanceData.dt_end), `day`) || !dayjs(this.startDate).isSame(parseToUTCDate(this.instanceData.dt_start), `day`);
    }

    get startDate(): Date {
        return dayjs(this.startDay).startOf('day').toDate();
    }

    get endDate(): Date {
        return dayjs(this.endDay).endOf('day').toDate();
    }

    get preparedSlotData(): { dt_start: string, dt_end: string } {
        return {
            dt_start: dayjs(this.startDate).startOf('day').format(),
            dt_end: dayjs(this.endDate).endOf('day').format(),
        };
    }

    dateToString(date: Date): string {
        return dateToString(date);
    }

    async setInstanceData(data: any): Promise<void> {
        this.startDay = parseToUTCDate(this.instanceData.dt_start);
        this.endDay = parseToUTCDate(this.instanceData.dt_end);
    }

    setTimeToBaseValues(): void {
        this.startDay = this.date;
        this.endDay = this.date;
    }

    setBaseValues(): void {
        this.setTimeToBaseValues();
    }

    created() {
        this.setTimeToBaseValues();
        if (this.instanceData) {
            this.setInstanceData(this.instanceData);
        }
    }

    @Emit('addSlot')
    addSlot() {
        return this.preparedSlotData;
    }

    @Emit('removeSlot')
    removeSlot() {
        return this.instanceData;
    }

    @Emit('closePopup')
    close() {}

    @Watch('instanceData')
    instanceDataHandler(instance: any | null) {
        if (instance) {
            this.setInstanceData(instance);
        } else {
            this.setBaseValues();
        }
    }

    @Watch('startDay')
    startDayHandler(startDay: Date) {
        if (startDay > this.endDay) {
            this.endDay = startDay;
        }
        this.calendarPanelKey += 1;
    }

    @Watch('endDay')
    endDayHandler(endDay: Date) {
        if (endDay < this.startDay) {
            this.startDay = endDay;
        }
        this.calendarPanelKey += 1;
    }

    @Watch('date')
    dateHandler(value: Date) {
        if (value) {
            if (!this.instanceData) {
                this.startDay = value;
                this.endDay = value;
            }
        }
    }
}
</script>

<style lang='sass'>
.h-uppercase
    text-transform: uppercase

.b-add-closures-slot-form
    text-align: center
    position: absolute
    width: 340px
    right: -260px
    margin: auto
    background: #fff
    z-index: 1000
    padding: 30px
    border-radius: 20px
    box-shadow: 0 3px 35px 0 rgba(33,63,107,0.05)

    @include media('<=phone')
        left: 0
        right: 0

    &__loading
        width: 100%
        height: 100%
        position: absolute
        left: 0
        top: 0
        display: flex
        align-items: center
        justify-content: center
        z-index: 5

    .fw-label-card-wrapper
        background-color: #F0F2F5

    .multiselect__single, .multiselect__option
        font-weight: 500 !important

    .multiselect__single, .multiselect__option
        font-size: 14px !important

    .fw-select-base .multiselect:after
        display: none !important

    &__title
        font-size: 14px
        line-height: 17px
        color: $main-gray
        text-transform: uppercase
        font-weight: bold

    &__date
        font-size: 25px
        font-weight: bold
        color: $dark-blue
        margin: 15px 0 20px

    .mx-datepicker--form
        background-color: #F0F2F5

    &__label
        display: flex
        align-items: center
        justify-content: space-between
        height: 100%

    .b-calendar-select__week
        transition: background 0.3s
        cursor: pointer

        input
            cursor: pointer

        &:hover
            input
                color: #182E4DFF !important

        .multiselect__option
            text-transform: uppercase!important

        &--disabled
            position: relative
            opacity: 0.5
            &:after
                content: ''
                background-color: rgba(0,0,0,0.1)
                border-radius: 10px
                width: 100%
                height: 100%
                position: absolute
                z-index: 1
                left: 0
                top: 0

    &__label__text
        font-size: 14px
        color: $main-gray
        font-weight: 500 !important

        &--active
            color: $dark-blue

    &__button
        z-index: 4
        position: relative

        &__inner
            margin-top: 20px
            margin-left: 10px
            margin-right: 10px
            flex: 1 1 50%

    &__to
        width: 40px
        margin: 0 10px
        color: $main-gray

    &__wrapper
        position: relative

        &--disable
            &:after
                position: absolute
                content: ''
                left: 0
                width: 100%
                top: 0
                height: 100%
                z-index: 10

            .multiselect__single,
            .b-add-closures-slot-form__label__text--active,
            .mx-datepicker--form .mx-input,
            .b-add-closures-slot-form__datepicker.mx-input
                color: #787e85 !important
            .fw-switcher__slider, .fw-check-box__wrapper.fw-is-selected .fw-check-box__container
                background-color: #787e85 !important
            .fw-check-box__container
                color: #fff

    .fw-check-box__container.form-light
        height: 37px
        padding: 5px
        flex: 0 0 37px
        font-weight: 500

    &__datepicker
        font-weight: 500 !important
        color: $dark-blue

        &::placeholder
            font-weight: 500 !important

    &__close_popup
        position: absolute
        right: 40px
        top: 40px
        cursor: pointer
        transition: opacity .3s
        will-change: opacity

        &:hover
            opacity: 0.7

    .multiselect__placeholder
        font-size: 14px !important
        font-weight: 500 !important
        color: #BEC7D3 !important

.b-add-closures-slot-form__day_datepicker
    .mx-datepicker-popup
        min-width: 290px
        left: -85px !important
        margin-top: 50px

    .mx-input
        font-weight: 500 !important
        &::placeholder
            opacity: 0 !important

.b-calendar-select__time
    .multiselect__tags
        display: flex
        align-items: center
        justify-content: center

    .multiselect__tags
        background: transparent !important

    .multiselect__option
        text-align: center

.h-width-115
    min-width: 115px
    width: 115px
</style>
