<template>
<div class='b-page-container b-consult-branch'>
    <div class='b-consult-card__inner'>
        <GoBackButton
            class='h-mt-30'
            isRelativePosition>
        </GoBackButton>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-common-item-title-wrapper b-common-item-title-wrapper--start'>
            <h1 v-if='branchStateData' class='h-pr-20 qa-branch-page-title'>
                {{ branchStateData.name | capitalizeAll }}
            </h1>
            <div class='b-common-item-title-wrapper__inner'>
                <!--ONLY FOR STAGING-->
                <FwButton
                    v-if='canEdit && isNotProd'
                    color='green'
                    size='little'
                    borderRadiusType='tiny-border'
                    fontSize='12px'
                    :loading='isActivateLoading'
                    @click='activate'>
                    {{ $t('Activate') }}
                </FwButton>
                <div class='h-ml-10'>
                    <FwButton
                        v-if='currentUserRole && currentUserRole.calendar_entity_rights.read'
                        :to='`/consult/calendar/branch/${$route.params.id}`'
                        size='little'
                        borderRadiusType='small-border'
                        fontSize='12px'>
                        {{ $t('BUTTON.SEE_CALENDAR') }}
                    </FwButton>
                </div>
            </div>
        </div>
        <div class='b-consult-card__wrapper'>
            <div class='b-consult-card__inner h-flex-1-0'>
                <div class='b-consult-card'>
                    <div class='b-consult-card__title'>
                        <div>
                            {{ $t('HEADER.INFORMATION') }}
                        </div>
                        <FwIcon
                            v-if='canEdit'
                            class='h-pointer qa-branch-page-edit-button'
                            icon='edit-settings'
                            size='25'
                            color='#BEC7D4'
                            @click.native='isBranchEditPopup = true'>
                        </FwIcon>
                    </div>
                    <div class='b-consult-card__sub__inner b-consult-card__scroll-inner'>
                        <ConsultCard
                            v-for='information in prepareData(branchStateData)'
                            :key='information.title'
                            :information='information'>
                        </ConsultCard>
                        <div v-if='branchStateData'>
                            <div class='h-mt-40 h-flex h-flex-space-between'>
                                <div class='b-consult-card__address h-fw-700'>
                                    {{ $t('COMPANIES.FORM.INPUT.ADDRESS.LABEL') }}
                                </div>
                                <FwIcon
                                    v-if='canEdit'
                                    class='h-pointer'
                                    icon='edit-settings'
                                    size='25'
                                    color='#BEC7D4'
                                    @click.native='goToBranchAddress'>
                                </FwIcon>
                            </div>
                            <div class='b-consult-card__sub-aside'>
                                {{ branchStateData.address }}
                            </div>
                        </div>
                        <WorkingHoursUpdateInfo
                            v-if='addressUpdateData'
                            isAddress>
                        </WorkingHoursUpdateInfo>
                    </div>
                </div>
                <div class='b-consult-card b-consult-card--wh b-consult-mt qa-branch-wh'>
                    <div class='h-flex h-flex-center h-flex-space-between'>
                        <div class='b-consult-card__title--wh'>
                            {{ $t('WORKER.TIME.SLOT.TITLE') }}
                        </div>
                        <FwIcon
                            v-if='canEdit'
                            class='h-pointer'
                            icon='edit-settings'
                            size='25'
                            color='#BEC7D4'
                            @click.native='isWorkingHoursPopup = true'>
                        </FwIcon>
                    </div>
                    <WorkingHoursUpdateInfo v-if='workerUpdateData'></WorkingHoursUpdateInfo>
                </div>
                <div class='b-consult-card b-consult-mt'>
                    <div class='b-consult-card__title b-consult-card__title--padding-fix h-flex h-flex-space-between h-align-items-center'>
                        <span>
                            {{ $t('LABEL.ROOMS') }}
                        </span>
                        <div class='b-consult-button-size'>
                            <FwButton
                                v-if='canEdit'
                                class='h-flex h-flex-center h-text-center qa-branch-room-add'
                                size='small'
                                borderRadiusType='small-border'
                                fontSize='12px'
                                @click.native='addRoomMethod'>
                                {{ $t('BUTTON.ADD') }}
                            </FwButton>
                        </div>
                    </div>
                    <div v-if='branchStateData && branchStateData.branch_rooms && branchStateData.branch_rooms.length !== 0'
                         class='h-pv-15'>
                        <div class='b-consult-card__scroll-inner'>
                            <a
                                v-for='room in branchStateData.branch_rooms'
                                :key='room.id'
                                class='b-consult-card__worker-wrapper qa-branch-room-name'
                                @click='openEditBranchRoom(room.id)'>
                                {{ room.name }}
                                <div class='b-consult-card__worker-arrow'>
                                    <FwIcon
                                        class='h-mh-5'
                                        icon='arrow-right'
                                        size='20'
                                        color='#27dbbd'>
                                    </FwIcon>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <ConsultCardArrow
                    :id='$route.params.id'
                    class='qa-branch-closures-name'
                    :title='$t("CONSULT.CLOSURES")'
                    type='hub'
                    @click.native='isClosuresPopupOpen = true'>
                </ConsultCardArrow>
                <router-link
                    v-if='currentUserRole.kpis_rights.read'
                    :to='`/consult/branch/statistic/${$route.params.id}/appointment-booked-types/for`'
                    class='b-consult-card b-consult-mt b-consult-card--arrow h-flex h-align-items-center h-flex-space-between'>
                    <span class='b-consult-card__title b-consult-card__title--base'>
                        {{ $t('SETTINGS.ROLE.APPOINTMENT.STATISTICS') }}
                        <FwIcon
                            v-if='accessLockStatisticPage'
                            class='b-icon-lock h-ml-10'
                            icon='lock-blue'
                            size='15'
                            color='#203f6a'>
                        </FwIcon>
                    </span>
                    <div>
                        <FwIcon
                            class='h-mh-5 h-mr-30'
                            icon='arrow-right'
                            size='20'
                            color='#27dbbd'>
                        </FwIcon>
                    </div>
                </router-link>
            </div>
            <div class='b-consult-card__inner--mobile'>
                <div v-if='currentUserRole && currentUserRole.advisor_rights.list'
                     class='b-consult-card'>
                    <div class='b-consult-card__title b-consult-card__title--padding-fix h-flex h-flex-space-between h-align-items-center'>
                        <span>
                            {{ $t('BREADCRUMBS.ADVISORS') }}
                        </span>
                        <div class='b-consult-button-size'>
                            <FwButton
                                v-if='currentUserRole && currentUserRole.advisor_rights.create'
                                class='h-flex h-flex-center h-text-center qa-branch-add-advisor'
                                size='small'
                                borderRadiusType='small-border'
                                fontSize='12px'
                                @click.native='addWorkerMethod'>
                                {{ $t('BUTTON.ADD') }}
                            </FwButton>
                        </div>
                    </div>
                    <FwInput
                        v-if='workersData.length'
                        class='h-flex-1 h-ph-30 h-mt-20'
                        :value='searchText'
                        :placeholder='$t(`WORKERS.SEARCH.PLACEHOLDER`)'
                        iconRight='loupe'
                        iconSize='15'
                        inputStyle='search'
                        type='search'
                        @update:value='value => searchText = value'>
                    </FwInput>

                    <div v-if='preparedAdvisorsCards.length !== 0'
                         class='b-consult-card__scroll-inner h-mt-15'>
                        <component
                            :is='currentUserRole.advisor_rights.read ? "router-link" : "div"'
                            v-for='worker in preparedAdvisorsCards'
                            :key='worker.id'
                            :to='currentUserRole.advisor_rights.read ? { path: `/consult/advisor/${worker.id}` } : false'
                            class='b-consult-card__worker-wrapper qa-advisor-branch-to-advisor'
                            :class='{ "h-pointer": currentUserRole && currentUserRole.advisor_rights.read }'>
                            <WorkerAvatar
                                :worker='worker'
                                nameClass='b-consult-card__worker-name'
                                size='small'
                                :hideRole='true'
                                align='right'>
                            </WorkerAvatar>
                            <div class='b-consult-card__worker-arrow'>
                                <FwIconh-mh-15
                                    class='h-mh-5'
                                    icon='arrow-right'
                                    size='20'
                                    color='#27dbbd'>
                                </FwIconh-mh-15>
                            </div>
                        </component>
                    </div>
                </div>
            </div>
        </div>
    </template>
    <AddClosuresPopup
        v-if='isClosuresPopupOpen'
        :id='$route.params.id'
        :readonly='!currentUserRole.branch_rights.update'
        type='branch'
        @close='isClosuresPopupOpen = false'>
    </AddClosuresPopup>
    <AddOrEditAddressByForm
        v-if='isAddressUpdatePopup'
        :objectData='branchStateData'
        updateAction='ValidateSiteAddress'
        updateType='Site'
        isAddress
        @close='isAddressUpdatePopup = false'>
    </AddOrEditAddressByForm>
    <AddOrEditBranchByForm
        v-if='isBranchEditPopup'
        @closePopup='closeBranchPopup'>
    </AddOrEditBranchByForm>
    <AddEditRoomPopup
        v-if='isAddEditRoomPopup && !isConfirmPopup'
        :roomData='currentRoomData'
        :branch_id='branchStateData.id'
        :canRemoveAndEdit='canEdit'
        @update='updateRooms'
        @showDelete='showDelete'
        @close='clearAddOrEditData'>
    </AddEditRoomPopup>
    <DeletePopup
        v-if='isConfirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.ROOM.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.ROOM.TEXT`)'
        @deleteItem='deleteItem'
        @close='isConfirmPopup = false'>
    </DeletePopup>
    <AddEditAdvisorPopup
        v-if='isAddEditAdvisorPopup'
        :type='`branch`'
        :parent_id='branchStateData.id'
        isBranchAdvisorAdd
        isBranchHubHide
        @closePopup='closeAdvisorAddPopup'>
    </AddEditAdvisorPopup>
    <WorkingHoursPopup
        v-if='isWorkingHoursPopup'
        :actionUpdate='`ValidateWorkingHoursBranch`'
        @close='closeWhPopup'>
    </WorkingHoursPopup>
    <InformationPopup
        v-if='isPreventPopupShow'
        :headerText='$t(`UPDATE.ADDRESS.EDIT.TITLE`)'
        :bodyText='$t(`UPDATE.ADDRESS.ALREADY.UPDATED`)'
        :buttonText='$t(`FINISH.POPUP.SUCCESS.CLOSE`)'
        @close='isPreventPopupShow = false'>
    </InformationPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { workerDataType } from '@/types/User';
import { CompanyInformationDataType } from '@/types/Companies';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { AddEditRoomPopup } from '@/components/popups/AddEditRoomPopup';
import { AddEditAdvisorPopup } from '@/components/popups/AddEditAdvisorPopup';
import { InformationPopup } from '@/components/popups/InformationPopup';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { WorkingHoursPopup } from '@/components/popups/WorkingHoursPopup';
import { AddOrEditBranchByForm } from '@/views/consult/add-branches/AddOrEditBranchByForm';
import { SitesApi } from '@/api/sites/SitesApi';
import { BranchSitesDataType } from '@/types/Site';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { WorkerScheduleTimeType } from '@/types/Workers';
import { WorkingHoursUpdateInfo } from '@/components/simple/WorkingHoursUpdateInfo/index';
import { AddOrEditAddressByForm } from '@/views/consult/update-address/AddOrEditAddressByForm';
import PermissionsMixin from '@/mixins/permissions';
import { AddClosuresPopup } from '@/components/popups/AddClosuresPopup';
import { ConsultCardArrow } from '@/components/simple/ConsultCardArrow';

const AppointmentStore = namespace('AppointmentStore');
const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        ConsultCard,
        WorkerAvatar,
        AddEditRoomPopup,
        AddEditAdvisorPopup,
        InformationPopup,
        DeletePopup,
        WorkingHoursPopup,
        AddOrEditBranchByForm,
        GoBackButton,
        WorkingHoursUpdateInfo,
        AddOrEditAddressByForm,
        AddClosuresPopup,
        ConsultCardArrow,
    },
})
export default class ConsultBranchPage extends Mixins(PermissionsMixin) {
    @WorkerPageStore.Action('storeBranchData') storeBranchData!: Function;
    @WorkerPageStore.Mutation('setBranchData') setBranchData!: Function;
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @WorkerPageStore.Mutation('setScheduleId') setScheduleId!: Function;
    @WorkerPageStore.Mutation('WORKER_UPDATE_DATA') setWorkerUpdateData!: Function;
    @WorkerPageStore.Mutation('ADDRESS_UPDATE_DATA') setAddressUpdateData!: Function;
    @WorkerPageStore.State('branchStateData') branchStateData!: BranchSitesDataType;
    @WorkerPageStore.State('workerUpdateData') workerUpdateData!: WorkerScheduleTimeType;
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;
    roomId: string | null = null;
    currentRoomData: any | null = null;
    searchText: string = '';
    deleteRoomId: string = '';
    workersData: Array<workerDataType> = [];
    loading: boolean = true;
    isAddressUpdatePopup: boolean = false;
    createdAdvisor: workerDataType | null = null;
    isAddEditRoomPopup: boolean = false;
    isPreventPopupShow: boolean = false;
    isConfirmPopup: boolean = false;
    isBranchEditPopup: boolean = false;
    isActivateLoading: boolean = false;
    isAddEditAdvisorPopup: boolean = false;
    isWorkingHoursPopup: boolean = false;
    isClosuresPopupOpen: boolean = false;

    get preparedAdvisorsCards() {
        const arrAdvisors = this.createdAdvisor ? this.workersData.concat(this.createdAdvisor) : this.workersData;
        return this.searchText ? arrAdvisors.filter(worker => {
            return (
                // @ts-ignore-next-line
                worker.first_name && worker.first_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                worker.last_name && worker.last_name.toLowerCase().includes(this.searchText.toLowerCase())
            );
        }) : arrAdvisors;
    }

    get isNotProd() {
        return process.env.VUE_APP_DEPLOY_TYPE !== `production` && process.env.VUE_APP_DEPLOY_TYPE !== `training`;
    }

    async activate() {
        this.isActivateLoading = true;
        try {
            const data = await SitesApi.activateBranch(this.$route.params.id);
            this.sentNotif('Successfully activated', true);
            this.isActivateLoading = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isActivateLoading = false;
        }
    }

    async getBranchData() {
        try {
            const data = await SitesApi.getSite(this.$route.params.id);
            this.loading = false;
            this.setBranchData(data.site);
            this.setScheduleId(data.site.working_schedule_id);
            this.setWorkerUpdateData(data.site.working_hours_update);
            this.setAddressUpdateData(data.site.update_request);
            this.workersData = data.site.users;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async deleteItem() {
        try {
            if (this.deleteRoomId) {
                await SitesApi.deleteSite(this.deleteRoomId as string);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
                this.isConfirmPopup = false;
                this.isAddEditRoomPopup = false;
                this.currentRoomData = null;
                this.getBranchData();
            }
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    goToBranchAddress() {
        if (this.addressUpdateData) {
            this.isPreventPopupShow = true;
        } else {
            this.isAddressUpdatePopup = true;
        }
    }

    showDelete(id: string) {
        this.isConfirmPopup = true;
        this.deleteRoomId = id;
    }

    updateBranchScheduleData(data: WorkerScheduleTimeType) {
        if (this.branchStateData) {
            this.branchStateData.working_schedule = data;
        }
    }

    closeBranchPopup() {
        this.isBranchEditPopup = false;
    }

    closeWhPopup() {
        this.isWorkingHoursPopup = false;
    }

    addRoomMethod() {
        this.isAddEditRoomPopup = true;
        // this.$router.push({ path: `/consult/room/add/new` });
    }

    addWorkerMethod() {
        // this.$router.push({ path: `/consult/advisors/add/new` });
        this.isAddEditAdvisorPopup = true;
        this.setGeograficCitiesData([]);
    }

    closeAdvisorAddPopup() {
        this.isAddEditAdvisorPopup = false;
        this.getBranchData();
    }

    clearAddOrEditData() {
        this.isAddEditRoomPopup = false;
        this.roomId = null;
        this.currentRoomData = null;
    }

    prepareData(branch: BranchSitesDataType) {
        const branchDataArray: Array<CompanyInformationDataType> = [];
        if (branch) {
            if (branch.phone) {
                branchDataArray.push({
                    title: this.$i18n.t('COMPANIES.FORM.INPUT.PHONE.LABEL'),
                    aside: branch.phone,
                });
            }
            if (branch.email) {
                branchDataArray.push({
                    title: this.$i18n.t('LABEL.EMAIL'),
                    aside: branch.email,
                });
            }
            if (branch.external_id) {
                branchDataArray.push({
                    title: this.$i18n.t('LABEL.BRANCH.ID'),
                    aside: branch.external_id,
                });
            }
        }
        return branchDataArray;
    }

    openEditBranchRoom(roomId: string) {
        if (this.branchStateData && this.branchStateData.branch_rooms) {
            this.currentRoomData = this.branchStateData!.branch_rooms.find(item => item.id === roomId);
            this.isAddEditRoomPopup = true;
        }
    }

    updateRooms() {
        this.clearAddOrEditData();
        this.getBranchData();
    }

    created() {
        this.setBranchData(null);
        this.getBranchData();
    }
}
</script>

<style lang='sass'>
.b-consult-branch
    .b-consult-card, .b-consult-card--wh
        &--arrow
            cursor: pointer

        &--small
            flex: 0 0 320px

        &--transparent
            background-color: transparent
            box-shadow: none !important

        &__wrapper
            margin: 0 -15px
            display: flex
            justify-content: space-between

        &__inner
            display: flex
            flex-direction: column
            justify-content: center

        &__worker-wrapper
            display: flex
            padding: 10px 20px
            align-items: center
            justify-content: space-between

            &:hover
                cursor: pointer
                background-color: $light-gray

                .b-consult-card__worker-arrow
                    transform: translate(5px, 0)

        &__worker-name
            flex: 1
            padding: 0
            font-size: 18px
            font-weight: 500
            color: $dark-blue

        &__scroll-inner
            max-height: 400px
            overflow: auto

        &__worker-arrow
            transform: translate(0, 0)
            transition: transform .4s ease-in-out

.b-page-title
    padding: 20px 0 10px
    display: flex
    align-items: center
    justify-content: space-between

@include media('<=phone')
    .b-page-title
        flex-wrap: wrap
        padding: 20px 15px

        & > div:not(:first-of-type)
            margin-left: 15px
</style>
