import { createDecorator } from 'vue-class-component';

export function Debounce(delay: number) {
    return createDecorator((opts, handler) => {
        if (!opts.methods) throw new Error(`This decorator must be used on a vue component method.`);

        const originalFn = opts.methods[handler];
        let timeoutId = 0;

        const clear = () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = 0;
            }
        };
        // eslint-disable-next-line
        opts.methods[handler] = function(...args: any[]) {
            clear();
            timeoutId = window.setTimeout(() => {
                timeoutId = 0;
                originalFn.apply(this, args);
            }, delay);
        };
    });
}
