<template>
<div>
    <form class='b-add-branch__form h-flex-dir-column'
          @keydown.stop.prevent.enter='onSubmit'>
        <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
            {{ $t('LABEL.CLIENT.INFORMATION') }}
        </h3>
        <div class='b-form-base-line'>
            <FwFormInput
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t("LABEL.NAME")}`'>
                <FwInput
                    :value='client_full_name'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE`)}`'>
                <FwInput
                    :value='client_phone'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}`'>
                <FwInput
                    :value='client_email'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
        </div>
        <div v-if='!isImhAccount'
             class='b-form-base-line h-mt-15'>
            <FwFormInput
                v-if='isMgasAccount'
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.CLIENT.PROF.STATUS`)}`'>
                <FwInput
                    :value='client_professional_status'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.CLIENT.PROF.STATUS`)'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-else
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.ADDRESS`)}`'>
                <FwInput
                    :value='client_address'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-if='!isApgisAccount && !isMgasAccount'
                class='h-mhd-20 b-form-base-line__inner-input'
                labelType='label-medium'
                :title='`${$t(`LABEL.COMPANY.NAME`)}`'>
                <FwInput
                    :value='client_company_title'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-if='isMatmutAccount'
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.COMPANY.ADDRESS`)}`'>
                <FwInput
                    :value='company_address'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
        </div>
        <template v-if='appointmentData && appointmentData.client_topics'>
            <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                {{ $t('LABEL.APPOINTMENT.INFORMATION') }}
            </h3>
            <div class='h-flex h-flex-dir-column h-width-100p'>
                <FwFormInput
                    class='h-flex-1-0 h-mhd-20'
                    labelType='label-medium'
                    :title='`${$t("LABEL.TOPIC.TO.DISCUSS")}`'>
                </FwFormInput>
                <div class='h-flex h-flex-wrap h-mt--10'>
                    <FwInput
                        v-for='label in appointmentData.client_topics'
                        :key='label'
                        style='flex: 1 0 300px; max-width: 336px'
                        class='h-mt-10 h-mhd-20'
                        :value='label'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        disabled>
                    </FwInput>
                </div>
            </div>
        </template>

        <div v-if='isMatmutAccount'
             class='b-form-base-line h-mt-15 h-width-100p'>
            <FwFormInput
                class='b-form-base-line__inner-input h-mhd-20'
                labelType='label-medium'
                :title='`${$t(`LABEL.BRANCH.AFFILIATED.NAME`)}`'>
                <FwInput
                    :value='affiliate_branch_name'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mhd-20 b-form-base-line__inner-input'
                labelType='label-medium'
                :title='`${$t(`LABEL.BRANCH.AFFILIATED.CODE`)}`'>
                <FwInput
                    :value='affiliate_branch_code'
                    type='text'
                    name='text'
                    inputStyle='white-bigger-shadow'
                    disabled>
                </FwInput>
            </FwFormInput>
            <div class='b-form-base-line__inner-input h-mh-20'></div>
        </div>
        <template v-if='isApgisAccount'>
            <div class='b-form-base-line h-pt-30'>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20'
                    labelType='label-medium'
                    :title='`${$t(`CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME`)}`'>
                    <FwInput
                        :value='cf__company_name'
                        type='tel'
                        name='text'
                        :placeholder='$t(`CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME`)'
                        inputStyle='white-bigger-shadow'
                        disabled>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='b-form-base-line__inner-input'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.CODE.SIREN`)}`'>
                    <FwInput
                        :value='cf__siren'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.CODE.SIREN`)'
                        inputStyle='white-bigger-shadow'
                        disabled>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20'
                    labelType='label-medium'
                    :title='$t("LABEL.TECHICIANS.NUMBER")'>
                    <FwInput
                        :value='cf__number_of_employees'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        disabled>
                    </FwInput>
                </FwFormInput>
            </div>
        </template>

        <div v-if='prepareAttributeSchema && prepareAttributeSchema.length' class='b-form-base-line h-mt-15 h-mb-20'>
            <FormAttributes
                class='b-booking-form__outer'
                clearable
                disabled
                :schemas='prepareAttributeSchema'
                @change='updateSchema'>
            </FormAttributes>
        </div>

        <template v-if='isMmeAccount'>
            <h3 class='h-font-16 h-fw-600 h-ml-20 h-mt-15 h-mb-10'>
                {{ $t('LABEL.ADDITIONAL.INFORMATION') }}
            </h3>
            <div class='b-form-base-line'>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20'
                    labelType='label-medium'
                    :title='`${$t("CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME")}`'>
                    <FwInput
                        :value='client_mme_company_name'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        disabled>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.HEADCOUNT`)}`'>
                    <FwInput
                        :value='client_mme_headcount'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        disabled>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    class='b-form-base-line__inner-input h-mhd-20'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.FIRM.NAME`)}`'>
                    <FwInput
                        :value='client_mme_firm_name'
                        type='text'
                        name='text'
                        inputStyle='white-bigger-shadow'
                        disabled>
                    </FwInput>
                </FwFormInput>
            </div>
        </template>
    </form>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { clone } from 'ramda';
import { AppointmentSearchType } from '@/types/Appointment';
import ValidationMixin from '@/mixins/validation';
import AccountMixin from '@/mixins/account';
import { FormAttributes } from '@/components/forms/attributes';
import DynamicFormMixin from '@/mixins/dynamicFormMixin';

const CommonStore = namespace('CommonStore');

@Component
export default class InternalAppointmentClientForm extends Mixins(DynamicFormMixin, ValidationMixin, AccountMixin) {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly eventTime!: string;

    client_full_name: string = '';
    client_phone: string = '';
    client_email: string = '';
    client_address: string = '';
    client_id: string = '';
    client_company_title: string = '';
    client_mme_company_name: string = '';
    client_mme_firm_name: string = '';
    client_mme_headcount: string = '';
    apizee_company: string = '';
    apizee_business_sector: string = '';
    address: string = '';
    affiliate_branch_name: string = '';
    affiliate_branch_code: string = '';
    company_address: string = '';
    cf__siren: string = '';
    cf__company_name: string = '';
    cf__number_of_employees: string = '';
    client_professional_status: string = '';

    serverErrors: serverErrors = {};

    updateFormByServerValues() {
        if (this.appointmentData) {
            if (this.appointmentData.client_full_name) {
                this.client_full_name = this.appointmentData.client_full_name;
            }
            if (this.appointmentData.client_phone) {
                this.client_phone = this.appointmentData.client_phone;
            }
            if (this.appointmentData.client_email) {
                this.client_email = this.appointmentData.client_email;
            }
            if (this.appointmentData.client_external_id) {
                this.client_id = this.appointmentData.client_external_id;
            }
            if (this.appointmentData.client_address) {
                this.client_address = this.appointmentData.client_address;
            }
            if (this.appointmentData.client_company_title) {
                this.client_company_title = this.appointmentData.client_company_title;
            }
            if (this.appointmentData.mme_company_name) {
                this.client_mme_company_name = this.appointmentData.mme_company_name;
            }
            if (this.appointmentData.mme_firm_name) {
                this.client_mme_firm_name = this.appointmentData.mme_firm_name;
            }
            if (this.appointmentData.mme_headcount) {
                this.client_mme_headcount = this.appointmentData.mme_headcount;
            }
            if (this.appointmentData.apizee_company) {
                this.apizee_company = this.appointmentData.apizee_company;
            }
            if (this.appointmentData.apizee_business_sector) {
                this.apizee_business_sector = this.appointmentData.apizee_business_sector;
            }
            if (this.appointmentData.client_branch_name) {
                this.affiliate_branch_name = this.appointmentData.client_branch_name;
            }
            if (this.appointmentData.client_branch_external_id) {
                this.affiliate_branch_code = this.appointmentData.client_branch_external_id;
            }
            if (this.appointmentData.company_address) {
                this.company_address = this.appointmentData.company_address;
            }
            if (this.appointmentData.client_professional_status) {
                this.client_professional_status = this.appointmentData.client_professional_status;
            }
            if (this.isApgisAccount) {
                this.cf__siren = this.appointmentData.cf__siren || '';
                this.cf__company_name = this.appointmentData.cf__company_name || '';
                this.cf__number_of_employees = this.appointmentData.cf__number_of_employees || '';
            }
            this.schema = clone(this.accountData.appointment_attributes.map(
                item => ({
                    ...item,
                    // @ts-ignore-next-line
                    value: item.searchable_name in this.appointmentData ? this.appointmentData[item.searchable_name] || '' : '',
                }),
            ));
        } else {
            this.schema = clone(this.accountData.appointment_attributes);
        }
    }

    created() {
        this.updateFormByServerValues();
    }

    @Emit('onSubmit')
    onSubmit() {}
}
</script>

<style lang='sass'>
.b-add-branch__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px

    &__row
        display: flex
        width: 100%
        margin-bottom: 0

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-add-branch__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7
</style>
