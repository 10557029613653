export default {
    'CANCEL.REASON.HEADER': 'Please specify the reason of the cancellation',
    'CANCEL.ORIGIN.LABEL': 'Origin of cancellation',
    'CANCEL.REASON.LABEL': 'Reason of cancellation',
    'CANCEL.PERSON.PLACEHOLDER': 'Select a person',
    'CANCEL.REASON.PLACEHOLDER': 'Select a reason',
    'CANCEL.BUTTON.APPOINTMENT': 'Cancel appointment',
    'CANCEL.SUCCESS.APPOINTMENT': 'Appointment has been cancelled',
    'CANCEL.GO.HOME.BUTTON': 'Back to the home page',
    'CANCEL.REASON.EMERGENCY': 'Company emergency',
    'CANCEL.REASON.SUITABILITY': 'Personal reason',
    'CANCEL.REASON.COMPLIANCE': 'Compliance site',
    'CANCEL.REASON.WORKER.MISTAKE': 'Worker planning mistake',
    'CANCEL.REASON.WORKER.SUITABILITY': 'Worker suitability',
    'CANCEL.REASON.DELAY': 'Delay',
    'CANCEL.REASON.STOCK': 'Stock',
    'CANCEL.REASON.GOOD.AGREEMENT': 'Good for agreement dead',
    'CANCEL.REASON.BAD.APPRECIATION': 'Bad appreciation distance',
    'CANCEL.REASON.REQUEST.MANAGER': 'At the request of the managers',
    'CANCEL.REASON.BAD.WEATHER': 'Bad weather / special event',
    'CANCEL.REASON.OTHER.URGENT': 'Other more urgent intervention',
    'CANCEL.REASON.PLAN.ERROR': 'Planning error',
    'CANCEL.REASON.BAD.COMPLIANCE': 'Bad compliance',
    'CANCEL.REASON.DELAYED': 'Delayed reception',
    'CANCEL.REASON.STOCK.ISSUE.DELAY': 'Stock issue (delay)',

    'CANCEL.REASON.INTERNAL.NOT.AVAILABLE': 'Not available anymore',
    'CANCEL.REASON.INTERNAL.OTHER': 'Other',
    'CANCEL.REASON.INTERNAL.BOOK.MISTAKE': 'Booking mistake',
    'CANCEL.REASON.INTERNAL.NO.ANYMORE': 'No need anymore',
    'CANCEL.REASON.INTERNAL.NECESSARY.ANYMORE': 'Not necessary anymore',
    'CANCEL.REASON.INTERNAL.STOCK': 'Stock issue',
    'CANCEL.REASON.INTERNAL.TECH.ISSUE': 'Technical issue',

    'CANCEL.REASON.EXTERNAL.COMPANY.EMERGENCY': 'Company emergency',
    'CANCEL.REASON.EXTERNAL.DELAY': 'Delay',
    'CANCEL.REASON.EXTERNAL.MATERIAL.ISSUE': 'Material issue',

    'CANCEL.ORIGIN.COMPANY.INTERNAL': 'Internal',
    'CANCEL.ORIGIN.COMPANY.EXTERNAL': 'External : client or prospect',
    'CANCEL.ORIGIN.PARTNER.CUSTOMER': 'Client',
    'CANCEL.ORIGIN.PARTNER.BUSINESS': 'Business Partner Company',
    'CANCEL.ORIGIN.WORKER': 'Worker',
    'CANCEL.ORIGIN.IMA': 'IMA Protect',
    'CANCEL.ORIGIN.MANAGER': 'Manager',
    'CANCEL.ORIGIN.STOCK': 'Stock',

    'CANCEL.ORIGIN.NISSAN.INTERNAL': 'Internal (IMA)',
    'CANCEL.ORIGIN.NISSAN.EXTERNAL': 'External (NISSAN)',
};
