import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import routes from '@/routes';
import store from '@/store';
import { isUserAuth, user, removeUserFromStorage } from '@/helpers/auth';
import { getUserRole, hasPermissionsToVisit } from '@/helpers/roles';
import { User } from '@/types/User';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    // @ts-ignore
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

// @ts-ignore
const router: any = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve({ x: 0, y: savedPosition.y });
                }, 1);
            });
        } else if (to.meta && to.meta.saveScrollPosition) {
            return { x: 0, y: window.scrollY };
        }

        return { x: 0, y: 0 };
    },
});
const routeGuardsMapping: any = {
    onboarding_account_type: {
        mainRoute: '/onboarding',
        initialRoute: `/account-onboarding/appointment/categories`,
        individual: {
            with_all: {
                route: `/account-onboarding/both/hubs`,
            },
            with_hubs: {
                route: `/account-onboarding/hubs`,
            },
            with_branches: {
                route: `/account-onboarding/branches`,
            },
        },
        partner: {
            with_companies: {
                route: `/partners-onboarding/appointment/categories`,
            },
        },

    },
    published: {
        mainRoute: '/consult/choose',
        initialRoute: '/consult/choose',
        individual: {
            with_all: {
                route: `/consult/choose`,
            },
            with_hubs: {
                route: `/consult/choose`,
            },
            with_branches: {
                route: `/consult/choose`,
            },
        },
        partner: {
            with_companies: {
                route: `/consult/choose`,
            },
        },
    },
    draft: {
        mainRoute: '/consult/choose',
        initialRoute: '/consult/choose',
        individual: {
            with_all: {
                route: `/consult/choose`,
            },
            with_hubs: {
                route: `/consult/choose`,
            },
            with_branches: {
                route: `/consult/choose`,
            },
        },
        partner: {
            with_companies: {
                route: `/consult/choose`,
            },
        },
    },
};

function stagingRouteGuards(to: Route, from: Route, next: (param?: { path: string } | boolean) => void) {
    const userAuth: boolean = isUserAuth(store);
    if (to.meta && to.meta.notAuthRequired && userAuth) {
        next({ path: '/onboarding' });
        return;
    }
    if (to.meta && !to.meta.breadCrumbsText) {
        store.commit(`BreadCrumbsStore/clearFlowKey`);
    }
    // @ts-ignore-next-line
    if (to.meta && to.meta.breadCrumbsFlowKey && !(store.state.BreadCrumbsStore.flowKey !== 'base' && from.meta.saveBreadCrumbsFlowKey)) {
        store.commit(`BreadCrumbsStore/setFlowKey`, to.meta.breadCrumbsFlowKey);
    }
    // @ts-ignore-next-line
    if (to.meta.redirectIfUserWasInCalendar && store.state.CalendarStore.user_on_calendar) {
        store.commit(`CalendarStore/setUserOnCalendar`, false);
        next({ path: '/appointment/matmut/fetch-details' });
    }
    const currentUser = user(store);
    for (let i = 0; i < to.matched.length; i++) {
        const match = to.matched[i];
        // @ts-ignore-next-line
        if (!hasPermissionsToVisit(getUserRole(currentUser), match.meta)) {
            next({ path: '/consult/choose' });
        }
    }
    if (to.meta && to.meta.notAuthRequired) {
        if (userAuth) {
            next({ path: '/onboarding' });
        } else {
            next();
        }
    }
    store.commit(`GlobalStore/SET_PREVIOUS_SCREEN_NAME`, from.name);
    next();
}
router.beforeEach((to: Route, from: Route, next: (param?: { path: string } | boolean) => void) => {
    if (!to.path.includes('/appointment/') || to.path.includes('/fetch-details')) {
        store.commit('AppointmentStore/removeAppointmentDataToUpdate');
        store.commit('AppointmentStore/setCurrentAppointmentKind', '');
    }
    if (process.env.VUE_APP_DEPLOY_TYPE === `staging` && process.env.NODE_ENV === `development`) {
        stagingRouteGuards(to, from, next);
    } else {
        const currentUser: User = user(store);
        const userAuth: boolean = isUserAuth(store);
        if (to && to.meta && to.meta.notAuthRequired && userAuth) {
            next({ path: '/onboarding' });
            return;
        }

        if (userAuth && !currentUser.account_name) {
            store.commit('GlobalStore/mutationUnLoginUser');
            window.location.href = '/';
        } else if (to.meta && !userAuth && !to.meta.notAuthRequired) {
            next({ path: '/' });
        } else if (userAuth && !(currentUser as User).manager_profile) {
            if (to.path === `/forbidden/not-manager` || to.path === `/profile` || to.path === `/logout`) {
                next();
            } else {
                next({ path: '/forbidden/not-manager' });
            }
        } else {
            if (to.meta && !to.meta.breadCrumbsText) {
                store.commit(`BreadCrumbsStore/clearFlowKey`);
            }
            // @ts-ignore-next-line
            if (to.meta && to.meta.breadCrumbsFlowKey && !(store.state.BreadCrumbsStore.flowKey !== 'base' && from.meta.saveBreadCrumbsFlowKey)) {
                store.commit(`BreadCrumbsStore/setFlowKey`, to.meta.breadCrumbsFlowKey);
            }
            // @ts-ignore-next-line
            if (to.meta.redirectIfUserWasInCalendar && store.state.CalendarStore.user_on_calendar) {
                store.commit(`CalendarStore/setUserOnCalendar`, false);
                next({ path: '/appointment/matmut/fetch-details' });
            }
            for (let i = 0; i < to.matched.length; i++) {
                const match = to.matched[i];
                // @ts-ignore-next-line
                if (!hasPermissionsToVisit(getUserRole(currentUser), match.meta)) {
                    next({ path: '/consult/choose' });
                }
            }
            store.commit(`GlobalStore/SET_PREVIOUS_SCREEN_NAME`, from.name);
            if (userAuth && !currentUser.account_organization_type) {
                next({ path: '/onboarding' });
            } else if (to.meta && to.meta.isFirstPage && currentUser.account_organization_type) {
                next({ path: routeGuardsMapping[currentUser.account_status!].initialRoute });
            } else if (to.meta && to.meta.pageType && currentUser.account_status !== to.meta.pageType) {
                if (from.meta && from.meta.pageType === currentUser.account_status) {
                    next(false);
                } else if (currentUser.account_kind && currentUser.account_organization_type) {
                    next({ path: routeGuardsMapping[currentUser.account_status!][currentUser.account_kind!][currentUser.account_organization_type!].route });
                } else {
                    next({ path: routeGuardsMapping[currentUser.account_status!].initialRoute });
                }
            } else if (to.meta && to.meta.pageType && currentUser.account_status === to.meta.pageType && to.meta.pageKind) {
                if (currentUser.account_kind && to.meta && to.meta.pageKind !== currentUser.account_kind || (to.meta.isNoKind && currentUser.account_kind)) {
                    next({ path: routeGuardsMapping[currentUser.account_status!][currentUser.account_kind!][currentUser.account_organization_type!].route });
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    }
});
export default router;
