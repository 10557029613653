import axios from 'axios';
import {
    RoleSwitcherSettingsType,
    RoleFullPayloadType,
} from '@/types/Roles';
import { pagePaginationData, CreateAppointmentCategoryPayloadType } from '@/types/Appointment';
import { BaseMetaType } from '@/types/Response';
import { updateUrlGetParams } from '@/helpers/url';
import { CalendarViewSettingsResponseType } from '@/types/Calendar';
import {
    GetManagerPayload,
    CalendarSettingsPayloadType,
    SettingsNotificationResponseType,
    SettingsNotificationFormDataType,
    SettingsRoleSimpleType,
    SettingsManagerFormDataType,
} from '@/types/Settings';
import { dayListMapping } from '@/helpers/settings';
import { User } from '@/types/User';
import { AccountType } from '@/types/Account';
import WebApi from '../WebApi';

interface settingsCompanyData {
    name: string,
    address: string,
    logo?: string,
    contact_email?: string,
    contact_phone?: string,
}

class SettingsWebApi extends WebApi {
    async getSettingsRoles(payload: pagePaginationData): Promise<{ data: { roles: Array<SettingsRoleSimpleType>, meta: BaseMetaType } }> {
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        return axios.get(
            `${this.urlPrefix}/roles${urlParams}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async getSettingsRoleNames(): Promise<Array<SettingsRoleSimpleType>> {
        const { data } = await axios.get(
            `${this.urlPrefix}/front_helpers/role_names`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return data.role_name;
    };

    async getSettingsRole(roleObj: RoleSwitcherSettingsType | { id: string }) {
        return axios.get(
            `${this.urlPrefix}/roles/${roleObj.id}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async addSettingsRole(role: RoleFullPayloadType, name: string) {
        return axios.post(
            `${this.urlPrefix}/roles`,
            {
                role: {
                    ...role,
                    ...{ name },
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async updateSettingsRole(role: RoleFullPayloadType, name: string, id: string) {
        return axios.put(
            `${this.urlPrefix}/roles/${id}`,
            { role: {
                ...role,
                ...{ name },
            }},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async deleteSettingsRole(role: RoleFullPayloadType | { id: string }) {
        return axios.delete(
            `${this.urlPrefix}/roles/${role.id}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async sentCalendarSettingsSlot(payload: CalendarSettingsPayloadType) {
        const calendar_view_setting: { [key: string]: string | boolean | null} = {};
        for (let i = 0; i < dayListMapping.length; i++) {
            const day: string = dayListMapping[i];
            // @ts-ignore-next-line
            calendar_view_setting[day] = payload[day];
            // @ts-ignore-next-line
            calendar_view_setting[`${day}_time_start`] = payload.timeFrom;
            // @ts-ignore-next-line
            calendar_view_setting[`${day}_time_end`] = payload.timeTo;
        }
        return axios.put(
            `${this.urlPrefix}/calendar_view_settings/${payload.calendar_view_setting_id}`,
            { calendar_view_setting },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getCompanySettingsData(id: string) {
        return axios.get(
            `${this.urlPrefix}/accounts/${id}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async changeCompanySettingsData(account: settingsCompanyData | { unavailability_pro_support?: boolean, notification_support?: boolean }, accountId: string) {
        return axios.put(
            `${this.urlPrefix}/accounts/${accountId}`,
            { account },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getAppointmentTypes({ per_page, page, search, isArchive }: { per_page: number, page: number, search?: string, isArchive?: boolean }) {
        const urlPostfix = isArchive ? `appointment_categories/archived` : `/appointment_categories`;
        const url = updateUrlGetParams(`${this.urlPrefix}/${urlPostfix}`, {
            'pagination[per_page]': per_page,
            'pagination[page]': page,
            ...(search && { search }),
        });
        const { data } = await axios.get(decodeURI(url));
        return data;
    }

    async createAppointmentCategory(payload: CreateAppointmentCategoryPayloadType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/appointment_categories`,
            payload
        );
        return data;
    }

    async editAppointmentType(payload: CreateAppointmentCategoryPayloadType | { appointment_category: { archived: boolean} }, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/appointment_categories/${id}`,
            payload
        );
        return data;
    }

    async deleteAppointmentType(id: string) {
        const { data } = await axios.delete(`${this.urlPrefix}/appointment_categories/${id}`);
        return data;
    }

    async getManagers(payload: GetManagerPayload) {
        const url = updateUrlGetParams(`${this.urlPrefix}/users`, {
            'pagination[per_page]': payload.per_page,
            'pagination[page]': payload.current_page,
            'scope': 'manager',
            ...(payload.search && { detail: payload.search }),
            ...(payload.sort && { sort: payload.sort }),
        });
        return await axios.get(decodeURI(url));
    }

    async getManagersList(payload: GetManagerPayload) {
        const url = updateUrlGetParams(`${this.urlPrefix}/managers`, {
            'pagination[per_page]': payload.per_page,
            'pagination[page]': payload.current_page,
            ...(payload.search && { last_name: payload.search }),
            ...(payload.sort && { sort: payload.sort }),
        });
        return await axios.get(decodeURI(url));
    }

    async getManager(id: string): Promise<User> {
        const { data } = await axios.get(`${this.urlPrefix}/managers/${id}`);
        return data.manager;
    }

    async getCalendarViewData(): Promise<CalendarViewSettingsResponseType> {
        const { data } = await axios.get(`${this.urlPrefix}/calendar_view_settings`);
        return data;
    }

    async getAppointmentsNotifications(): Promise<Array<SettingsNotificationResponseType>> {
        const { data } = await axios.get(`${this.urlPrefix}/appointment_notifications`);
        return data.appointment_notifications;
    }

    async updateAppointmentsNotification(id: string, payload: SettingsNotificationFormDataType): Promise<SettingsNotificationResponseType> {
        const { data } = await axios.put(`${this.urlPrefix}/appointment_notifications/${id}`, { appointment_notification: payload });
        return data.appointment_notification;
    }

    async createManager(manager: SettingsManagerFormDataType) {
        const { data } = await axios.post(`${this.urlPrefix}/managers`, { manager });
        return data.manager;
    }

    async editManager(manager: SettingsManagerFormDataType, id: string) {
        const { data } = await axios.put(`${this.urlPrefix}/managers/${id}`, { manager });
        return data.manager;
    }

    async deleteManager(id: string): Promise<''> {
        const { data } = await axios.delete(`${this.urlPrefix}/managers/${id}`);
        return data;
    }

    async getGenericUrlsSelectors() {
        const { data } = await axios.get(`${this.urlPrefix}/components/generic_url_selectors`);
        return data;
    }

    async getGenericUrls(payload: pagePaginationData, searchValue: string) {
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${searchValue}`;
        const { data } = await axios.get(`${this.urlPrefix}/generic_urls${urlParams}`);
        return data;
    }
}

const settingsWebApi = new SettingsWebApi();

export {
    settingsWebApi as SettingsWebApi,
};
