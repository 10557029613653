import addCompanies from './add-companies';
import addWorkers from './add-workers';
import worker from './worker';
import common from './common';
import forms from './forms';
import reservedSlots from './reserved-solots';
import branch from './branch';
import hub from './hub';
import update from './update';
import branchStatistics from './branch-statistics';

export default {
    ...addCompanies,
    ...addWorkers,
    ...worker,
    ...common,
    ...forms,
    ...reservedSlots,
    ...branch,
    ...update,
    ...hub,
    ...branchStatistics,
};
