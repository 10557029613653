export default {
    'PLAN.KIND.APPOINTMENT': 'Quel type d’intervention ?',
    'PLAN.CALENDAR.APPOINTMENT.HEADER': 'Planifier un rendez-vous',
    'PLAN.CHOOSE.APPOINTMENT': 'Choisissez une intervention',
    'PLAN.HEADER': 'Code d\'intervention',
    'PLAN.IWS.CODE': 'code IWS',
    'PLAN.COHERIS.CODE': 'code Coheris',
    'PLAN.SOCIETY.NAME': 'Sélection d’une société',
    'PLAN.SOCIETY.LABEL': 'Nom de la société',
    'PLAN.SOCIETY.PLACEHOLDER': 'Rechercher une société',
    'PLAN.SOCIETY.NO.ELEMENT.FOUND': 'Aucun résultat. Essayez avec d\'autres mots-clés',
    'APPOINTMENT.SUCCESS.POPUP.TXT': 'Rendez-vous planifié avec succès',
    'APPOINTMENT.SUCCESS.TEXT': 'Rendez-vous planifié',
    'APPOINTMENT.UPDATED.SUCCESS.TEXT': 'Rendez-vous modifié',
    'POPUP.SUCCESS.HEADER': 'Succès !',

    'PLAN.APPOINTMENT.ADD.HEADER': 'Ajouter un type de rendez-vous',
    'PLAN.APPOINTMENT.EDIT.HEADER': 'Modifier un type de rendez-vous',
    'PLAN.APPOINTMENT.DELETE.HEADER': 'Supprimer un type de rendez-vous',
    'PLAN.APPOINTMENT.NO.EVENTS': 'Aucune compétence n\'a été encore ajoutée',
    'PLAN.APPOINTMENT.CREATE.SKILL': 'Créer une compétence',

    'PLAN.APPOINTMENT.BUSINESS.PARTNER.TITLE': 'Entreprises partenaires',
    'PLAN.APPOINTMENT.BUSINESS.PARTNER.ADD': 'Ajouter une société partenaire',
    'PLAN.APPOINTMENT.BUSINESS.PARTNER.EDIT': 'Modifier une société partenaire',

    'PLAN.APPOINTMENT.PLACEHOLDER.GIVE.TITLE': 'Donnez un nom à la compétence',

    'PLAN.APPOINTMENT.ADDRESS.ALL': 'All',
    'PLAN.APPOINTMENT.ADDRESS.ON.SITE': 'En clientèle',
    'PLAN.APPOINTMENT.ADDRESS.BY.PHONE': 'Au téléphone',
    'PLAN.APPOINTMENT.ADDRESS.BY.VIDEO': 'En visioconférence',
    'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.FACE': 'En agence',
    'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.VIDEO': 'En agence - exterieur avec vidéoconference',
    'PLAN.APPOINTMENT.IN.BRANCH.PRIVATE': 'En agence avec salle',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PUBLIC': 'Au téléphone (agence)',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PUBLIC': 'En visioconférence (agence)',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PRIVATE': 'Au téléphone avec salle (agence)',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PRIVATE': 'En visioconférence avec salle (agence)',

    'PLAN.APPOINTMENT.WORKERS.TITLE': 'Mon organisation est composée :',
    'PLAN.APPOINTMENT.WORKERS.HUB': 'De hub',
    'PLAN.APPOINTMENT.WORKERS.BRANCH': 'D\\\'agence',
    'PLAN.APPOINTMENT.WORKERS.BOTH': 'Des deux',

    'PLAN.APPOINTMENT.BRANCH.ROOMS.TITLE': 'Ajouter une salle à cette agence',
    'PLAN.APPOINTMENT.BRANCH.ROOMS.ADD.TITLE': 'Ajouter une salle',
    'PLAN.APPOINTMENT.BRANCH.ROOMS.NAME': 'Nom de la salle',
    'PLAN.APPOINTMENT.BRANCH.ROOMS.ASIDE': 'Combien de salles souhaitez-vous ajouter ?',
    'PLAN.APPOINTMENT.BRANCH.VIDEO.ROOM': 'Salle visio',

    'PLAN.APPOINTMENT.BRANCH.LIST.NAME': 'Nom',

    'PLAN.BRANCH.TITLE.ADD': 'Ajouter une agence',
    'PLAN.BRANCH.TITLE.EDIT': 'Modifier les détails de l’agence',
    'PLAN.BRANCH.DELETE.TXT': 'SUPPRIMER UNE AGENCE',
    'PLAN.BRANCH.CHOOSE.ROOM': 'Sélectionner une agence pour ajouter une salle',
    'PLAN.BRANCH.PLACEHOLDER.ROOM': 'Rechercher une salle',
};
