import axios from 'axios';
import { WorkerScheduleTimeType, workerTravelTimeData, UpdateManagerDataPayloadType, WorkerScheduleUpdateType } from '@/types/Workers';
import { availabilityPayloadType } from '@/types/Events/Workers';
import { pagePaginationData } from '@/types/Appointment';
import { updateUrlGetParams } from '@/helpers/url';
import WebApi from '../WebApi';

interface workerCreateData {
    email?: string,
    first_name?: string,
    last_name?: string,
    label?: string,
    kind?: string,
    company_id?: string,
    role_id?: string,
    role_name?: string,
    phone?: string,
    color?: string,
    add_travel_time?: boolean,
    company_admin?: boolean,
    set_company_as_starting_point?: boolean,
}
interface workerIdData {
    id: string | number | null,
    title?: string,
}

type pageData = {
    scope?: string,
    per_page: number,
    current_page: number,
    sort?: string,
} & pagePaginationData;

class WorkersWebApi extends WebApi {
    async getTechniciansData(payload: pageData, kind: string) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        return axios.get(
            `${this.urlPrefix}/users?scope=${kind}${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getAdvisorsData(payload: pageData) {
        const url = updateUrlGetParams(`${this.urlPrefix}/advisors`, {
            'consult': true,
            'pagination[per_page]': payload.per_page,
            'pagination[page]': payload.current_page,
            'sort': payload.sort,
        });
        return axios.get(url);
    }

    async getAdvisorsDataBySearch(payload: pageData, searchValue: string, param?: string) {
        const url = updateUrlGetParams(`${this.urlPrefix}/advisors`, {
            'last_name': searchValue,
            'consult': true,
            'pagination[per_page]': payload.per_page,
            'pagination[page]': payload.current_page,
            'sort': payload.sort,
        });
        return axios.get(url);
    }

    async getWorkerDataByAdvancedSearch(payload: pageData, customPayload: any, pageType: string, urlArrayString: string) {
        const url = updateUrlGetParams(`${this.urlPrefix}/searches/${pageType}`, {
            'pagination[per_page]': payload.per_page,
            'pagination[page]': payload.current_page,
            ...customPayload,
        });

        return axios.get(`${url}${urlArrayString}`);
    }

    async getWorkerAutocompleteSearch(payload: pageData, param: string, pageType: string) {
        const url = updateUrlGetParams(`${this.urlPrefix}/front_helpers/${pageType}${param}_autocomplete`, payload);

        return axios.get(url);
    }

    async getTechniciansDataBySearch(payload: pageData, searchValue: string) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const userTechParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        // eslint-disable-next-line
        const urlParams = payload.scope ? userTechParams + `&scope=${payload.scope}` : userTechParams;
        return axios.get(
            `${this.urlPrefix}/users?detail=${searchValue}${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getManagersData(payload: pageData) {
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        return axios.get(
            `${this.urlPrefix}/users?scope=manager${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getTechnicianData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/users/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getTechnicianCaledarData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/remote_credentials?${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getAdvisorHelpersData() {
        return axios.get(
            `${this.urlPrefix}/components/advisor`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getTechnicianHelpersData() {
        return axios.get(
            `${this.urlPrefix}/components/technician`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async createWorker(payload: workerCreateData) {
        return axios.post(
            `${this.urlPrefix}/users`,
            {
                'user': payload,
            }
        );
    }

    async updateWorker(payload: workerCreateData, id: string) {
        return axios.put(
            `${this.urlPrefix}/users/${id}`,
            {
                'user': payload,
            }
        );
    }

    async updateWorkerAvailabilityType(payload: availabilityPayloadType, id: string) {
        return axios.put(
            `${this.urlPrefix}/users/${id}/set_availability_type`,
            {
                'user': payload,
            }
        );
    }

    async updateManager(payload: UpdateManagerDataPayloadType, id: string) {
        return axios.put(
            `${this.urlPrefix}/managers/${id}`,
            {
                'manager': payload,
            }
        );
    }

    async createManager({ email, first_name, last_name, kind, role_id, role_name, phone }: workerCreateData) {
        return axios.post(
            `${this.urlPrefix}/users`,
            {
                'user': {
                    email,
                    first_name,
                    last_name,
                    kind,
                    role_id,
                    role_name,
                    phone,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async editWorker({ email, first_name, last_name, kind, company_id, color, add_travel_time, company_admin, phone }: workerCreateData, workerId: string | null) {
        return axios.put(
            `${this.urlPrefix}/users/${workerId}`,
            {
                'user': {
                    email,
                    first_name,
                    last_name,
                    kind,
                    company_id,
                    color,
                    add_travel_time,
                    phone,
                    company_admin,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async userToggleTravelTime({ user_id, toggle_travel_time }: workerTravelTimeData, workerId: string | null) {
        return axios.put(
            `${this.urlPrefix}/users/${workerId}/toggle_travel_time`,
            {
                'user': {
                    user_id,
                    toggle_travel_time,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async editManager({ email, first_name, last_name, kind, role_id, role_name, phone }: workerCreateData, workerId: string | null) {
        return axios.put(
            `${this.urlPrefix}/users/${workerId}`,
            {
                'user': {
                    email,
                    first_name,
                    last_name,
                    kind,
                    role_id,
                    role_name,
                    phone,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async deleteWorker({ id }: workerIdData | { id: string }) {
        return axios.delete(
            `${this.urlPrefix}/users/${id}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getTechnicianSchedulesData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/working_schedules/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async editTechnicianSchedulesData(payload: WorkerScheduleTimeType, id: string) {
        return axios.put(
            `${this.urlPrefix}/working_schedules/${id}`,
            {
                'working_schedule': payload,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async confirmTechnicianSchedulesData(payload: WorkerScheduleTimeType, currentData: Date, id: workerIdData) {
        payload.confirmed_at = currentData;
        return axios.put(
            `${this.urlPrefix}/working_schedules/${id}/confirm`,
            {
                'working_schedule': payload,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async confirmBranchSchedulesData(payload: WorkerScheduleTimeType, id: string) {
        return axios.post(
            `${this.urlPrefix}/working_schedules`,
            {
                'working_schedule': { ...payload, 'site_id': id, 'confirmed_at': new Date() },
            },
        );
    }
    async confirmAdvisorSchedulesData(payload: WorkerScheduleTimeType, id: string) {
        return axios.post(
            `${this.urlPrefix}/working_schedules/bulk_create`,
            {
                'working_schedule': payload,
                'user_ids': [id],
            },
        );
    }
    async confirmWorkerSchedulesData(payload: WorkerScheduleTimeType, id: string) {
        return axios.post(
            `${this.urlPrefix}/working_schedules`,
            {
                'working_schedule': { ...payload, 'user_id': id, 'confirmed_at': new Date() },
            },
        );
    }
    async confirmAdvisorsSchedulesData(payload: WorkerScheduleTimeType) {
        return axios.post(
            `${this.urlPrefix}/working_schedules/bulk_create`,
            {
                'working_schedule': payload,
            },
        );
    }
    async confirmAdvisorInvitationData(isAdvisorsAll: boolean, id: Array<string>) {
        const users =
            {
                'all': isAdvisorsAll,
                'user_ids': id,
            };
        return axios.post(
            `${this.urlPrefix}/users/bulk_invite`,
            { users },
        );
    }
    async confirmAdvisorsAllInvitationData(isAdvisorsAll: boolean) {
        const users =
            {
                'all': isAdvisorsAll,
                'user_ids': [],
            };
        return axios.post(
            `${this.urlPrefix}/users/bulk_invite`,
            { users },
        );
    }

    async getAdvisors(payload: pagePaginationData) {
        const sortBy = payload.sort ? `&sort=${payload.sort}` : '';
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}${sortBy}`;
        const { data } = await axios.get(`${this.urlPrefix}/advisors${urlParams}`);
        return data;
    }
    async updateTechnicianSchedulesData(payload: WorkerScheduleUpdateType) {
        return axios.post(
            `${this.urlPrefix}/update_requests`,
            {
                'update_request': payload,
            },
        );
    }

    async deleteTechnicianUpdateData(id: string) {
        return axios.delete(
            `${this.urlPrefix}/update_requests/${id}`,
        );
    }
}

const workersApi = new WorkersWebApi();

export {
    workersApi as WorkersWebApi,
};
