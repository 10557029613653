export default {
    'BRANCH.PLACEHOLDER.SEARCH': 'Search a branch',
    'BRANCH.ADVISORS.NUMBER': 'Number of advisors',

    'BRANCH.FORM.LABEL': 'Branch admin',
    'BRANCH.FORM.DELETE.POPUP.TITLE': 'Delete the branch',
    'BRANCH.FORM.DELETE.POPUP.TEXT': 'Do you really want to delete this branch?',
    'BRANCH.FORM.CREATE.POPUP.TEXT': 'The branch has been added',
    'BRANCH.FORM.UPDATE.POPUP.TEXT': 'The branch was updated',
    'BRANCH.GO.TO.BRANCH': 'Go to branch',
    'BRANCH.ROOM.TITLE': 'Room',
};
