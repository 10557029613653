export default {
    'ERRORS.ONLY.ONE.SLOT': 'Désolé ! Vous ne pouvez pas créer plus d\'une indisponibilité/créneau réservé dans le même jour.',
    'ERRORS.PERMISSION': 'Désolé, vous n\'avez pas l\'autorisation d\'accéder à cette fonctionnalité',
    'ERRORS.LOGIN.PASSWORD': 'L\'email ou le mot de passe est invalide. Réessayez',
    'ERRORS.404': 'Enregistrement non trouvé',
    'ERRORS.401': 'Non autorisé',
    'ERRORS.403': 'Vous n\'avez pas de droits d\'accès',
    'ERRORS.500': 'Internal server error',
    'ERRORS.SERVER.ISSUE': 'Désolé, erreur de serveur ! Merci de réessayer plus tard',

    'ERRORS.CALENDAR.NO.DATA': 'Les données de réservation du calendrier n\'existent pas\n',
};
