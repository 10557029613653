<template>
<div class='b-add-worker__wrapper container'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $route.params.id && !isWorkerAdd ? $t('WORKERS.FORM.TITLE.EDIT') : $t('WORKERS.FORM.TITLE') }}
            </h2>
        </div>
        <form @keydown.stop.prevent.enter='submit'>
            <div class='b-form-base-line'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    :fieldHasError='fieldHasError(FIRST_NAME)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.FIRST.NAME.LABEL`)}*`'>
                    <FwInput
                        :value='first_name'
                        type='text'
                        name='text'
                        :placeholder='$t(`WORKER.FORM.FIRST.NAME.PLACEHOLDER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(FIRST_NAME)'
                        @update:value='value => first_name = value'
                        @input='clearServerErrorsBase(FIRST_NAME)'
                        @blur='addCurrentInputToValidateArray(FIRST_NAME)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    :fieldHasError='fieldHasError(LAST_NAME)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.LAST.NAME.LABEL`)}*`'>
                    <FwInput
                        :value='last_name'
                        type='text'
                        name='text'
                        :placeholder='$t(`WORKER.FORM.LAST.NAME.PLACEHOLDER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(LAST_NAME)'
                        @update:value='value => last_name = value'
                        @input='clearServerErrorsBase(LAST_NAME)'
                        @blur='addCurrentInputToValidateArray(LAST_NAME)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    labelType='label-medium'
                    class='h-flex-1-0'
                    :title='`${$t(`WORKER.FORM.COMPANY.LABEL`)}*`'>
                    <div>
                        <div class='fw-select-base'>
                            <FwSelect
                                v-model='company'
                                class='fw-select-with-icon--big'
                                type='white'
                                :propsPlaceholder='`${$t(`WORKER.FORM.COMPANY.PLACEHOLDER`)}`'
                                label='title'
                                :disabled='($route.params.id && !isWorkerAdd) || isCompanySelectDisable'
                                :canNotDelete='true'
                                :searchable='false'
                                :multiply='false'
                                :withCircles='true'
                                :clearable='false'
                                :options='companies'>
                            </FwSelect>
                        </div>
                    </div>
                </FwFormInput>
            </div>
            <div class='b-form-base-line'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.PHONE.LABEL`)}`'>
                    <FwInput
                        :value='phone'
                        type='number'
                        name='text'
                        :placeholder='$t(`WORKER.FORM.PHONE.PLACEHOLDER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        @update:value='value => phone = value'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    v-if='categoryLocationSkills'
                    labelType='label-medium'
                    class='h-flex-1-0 h-mr-30'
                    :title='`${$t(`LABEL.APPOINTMENT.METHODS`)}*`'>
                    <div class='fw-select-base'>
                        <FwSelect
                            v-model='locationSkillChoosed'
                            class='fw-select-with-icon--big'
                            :propsPlaceholder='$t(`WORKER.FORM.SKILLS.PLACEHOLDER`)'
                            type='white'
                            :searchable='false'
                            :label='labelKey'
                            openDirection='bottom'
                            :multiple='true'
                            :clearable='false'
                            :withCircles='true'
                            :canNotDelete='false'
                            :options='categoryLocationSkills'>
                        </FwSelect>
                    </div>
                </FwFormInput>
                <FwFormInput
                    labelType='label-medium'
                    class='h-flex-1-0'
                    :title='`${$t(`WORKER.FORM.SKILLS.LABEL`)}*`'>
                    <div >
                        <div class='fw-select-base'>
                            <FwSelect
                                v-model='skillChoosed'
                                class='fw-select-with-icon--big'
                                type='white'
                                :propsPlaceholder='`${$t(`WORKER.FORM.SKILLS.PLACEHOLDER`)}`'
                                :searchable='false'
                                :multiple='true'
                                :label='labelKey'
                                :clearable='false'
                                :withCircles='true'
                                :canNotDelete='false'
                                :options='skills'>
                            </FwSelect>
                        </div>
                    </div>
                </FwFormInput>
            </div>
            <div class='b-form-base-line'>
                <FwFormInput
                    :style='!isMobile() ? "flex: 0 0 280px" : null'
                    :fieldHasError='fieldHasError(EMAIL)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.EMAIL.LABEL`)}*`'>
                    <FwInput
                        :value='email'
                        type='text'
                        name='text'
                        :placeholder='$t(`WORKER.FORM.EMAIL.PLACEHOLDER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(EMAIL)'
                        @update:value='value => email = value'
                        @input='clearServerErrorsBase(EMAIL)'
                        @blur='addCurrentInputToValidateArray(EMAIL)'>
                    </FwInput>
                </FwFormInput>
            </div>

            <template>
                <div v-if='geograficIds.length !== 0' class='h-pos-rel'>
                    <FwFormInput
                        labelType='label-medium'
                        :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                        <div class='h-flex h-flex-wrap h-pb-30' style='margin: 0 -15px;'>
                            <div v-for='item in geograficIds'
                                 :key='item'
                                 style='width: 435px; height: 60px;'
                                 class='b-team-list h-mh-15'>
                                <div class='b-team-list__body'>
                                    <div class='h-fw-500'>
                                        {{ item.post_code }} - {{ item.name }}
                                    </div>
                                </div>
                                <div class='b-team-list__delete'
                                     @click='deleteItem(item)'>
                                    <FwIcon
                                        class='h-flex-center h-pointer'
                                        icon='bucket-edit'
                                        size='20'
                                        color='rgba(33,63,107,.3)'
                                        @click.native='confirmPopup = true'>
                                    </FwIcon>
                                </div>
                            </div>
                        </div>
                    </FwFormInput>
                    <div class='h-pos-abs h-fw-500 h-font-14 h-pointer'
                         style='top: 0; left: 150px;color: #27dbbd;'
                         @click='$emit(`openGeograficPopup`)'>
                        {{ $t('LABEL.GEO.ZONE') }}
                    </div>
                </div>
                <div v-else
                     :style='!isMobile() ? "width: 310px" : null'
                     class='b-form-base-line'>
                    <FwFormInput
                        labelType='label-medium'
                        class='h-flex-1-0 h-mr-30'
                        :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                        <div class='fw-select-base'>
                            <div class='b-team-list__body h-pointer'
                                 @click='$emit(`openGeograficPopup`)'>
                                <div class='h-fw-500' style='color: rgba(33,63,107,.5);'>
                                    {{ $t('WORKER.FORM.GEOGRAFIC.SELECT.PLACEHOLDER') }}
                                </div>
                                <FwIcon
                                    class='h-flex-center h-pointer'
                                    icon='edit-settings'
                                    size='20'
                                    color='#203f6a'>
                                </FwIcon>
                            </div>
                        </div>
                    </FwFormInput>
                </div>
            </template>

            <div class='b-form-base-line'>
                <FwFormInput
                    :style='!isMobile() ? "flex: 0 0 280px h-mr-30" : null'
                    :fieldHasError='fieldHasError(ADMIN)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.ADMIN.LABEL`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isActiveAdmin ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isActiveAdmin'
                                :isRequestSending='isActiveAdminRequestSending'
                                @toggleSwitch='isActiveAdmin = !isActiveAdmin'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
                <div class='h-flex-1-0 h-mr-30'></div>

                <FwFormInput
                    v-if='false'
                    labelType='label-medium'
                    class='h-flex-1-0 h-mr-30'
                    :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                    <div >
                        <div class='fw-select-base fw-select-white-modern'>
                            <FwSelect
                                v-model='zoneValue'
                                class='fw-select-with-icon--big'
                                type='white'
                                :propsPlaceholder='`${$t(`WORKER.FORM.ZONE.PLACEHOLDER`)}`'
                                :searchable='false'
                                :multiply='false'
                                :withCircles='true'
                                :clearable='false'
                                :canNotDelete='true'
                                :options='zones'>
                            </FwSelect>
                        </div>
                    </div>
                </FwFormInput>
            </div>
            <template v-if='isTypeSite'>
                <h2 class='h-font-22'>
                    {{ $t('LABEL.START.POINT') }}
                </h2>
                <div class='h-flex h-flex-justify-start'>
                    <div style='width: 310px' class='h-pb-30'>
                        <FwFormInput
                            class='h-flex-1-0 h-mr-30'
                            :fieldHasError='fieldHasError(ADMIN)'
                            labelType='label-medium'
                            :title='`${$t(`TECH.SAME.COMPANY.ADDRESS`)}*`'>
                            <FwLabelCard
                                :disabled='isInitiaStartPoinDisabled'
                                class='h-flex-1-0 b-label-card'>
                                <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                                    <div class='b-add-edit__form__option h-flex h-flex-center'>
                                        <span class='b-add-worker__is_admin_active'>
                                            {{ isCompanyAddress ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                        </span>
                                    </div>
                                    <FwSwitcher
                                        :isActive='isCompanyAddress'
                                        :isRequestSending='isActiveAdminRequestSending'
                                        @toggleSwitch='isCompanyAddress = !isCompanyAddress'>
                                    </FwSwitcher>
                                </div>
                            </FwLabelCard>
                        </FwFormInput>
                    </div>
                    <div v-if='isDeparturePoint && !isWorkerAdd' class='h-flex h-flex-center h-flex-justify-start b-update-address__checkbox'>
                        <div v-if='addressUpdateData' style='width: 310px'>
                            <div class='h-flex h-flex-space-between h-flex-center'>
                                <div class='h-flex h-flex-center'>
                                    <FwIcon
                                        icon='reccurance-icon'
                                        size='20'
                                        color='#BEC7D4'>
                                    </FwIcon>
                                    <span class='h-ml-10 h-fw-500 h-font-14'>
                                        {{ $t('UPDATE.TITLE.POPUP.SCHEDULED.UPDATE') }}
                                    </span>
                                </div>
                            </div>
                            <p class='h-mt-5 h-font-12'>
                                {{ $t('UPDATE.ADDRESS.DESCRIPTION') }} "{{ addressUpdateData.new_data.departure_point }}" {{ $t('UPDATE.ADDRESS.ON') }} {{ updateDate }}
                            </p>
                        </div>
                        <FwCheckbox
                            v-else
                            v-model='isUpdateAddress'
                            :value='isUpdateAddress'
                            type='white-with-text-transparent'
                            :text='$t(`UPDATE.ADDRESS.LABEL`)'
                            :itemWidth='`auto`'
                            name='checkbox'>
                        </FwCheckbox>
                    </div>
                </div>
                <div v-if='!isCompanyAddress'>
                    <div class='h-flex h-flex-space-between h-mb-30'>
                        <FwFormInput
                            class='h-flex-1-0'
                            :fieldHasError='fieldHasError()'
                            :title='`${$t(`LABEL.ADDRESS`)}*`'
                            labelType='label-medium'>
                            <SelectAddress
                                :startAddress='address'
                                :isDisabled='isInitiaStartPoinDisabled'
                                @changePostCode='value => post_code = value'
                                @input='input'>
                            </SelectAddress>
                        </FwFormInput>
                    </div>
                </div>
            </template>
            <div class='h-fw-500 h-font-14 h-mb-30'>
                *{{ $t('WORKER.FORM.REQUIRED.FIELDS') }}
            </div>
        </form>
    </template>
    <div v-if='!loading'
         class='h-width-100p h-flex h-flex-center h-flex-dir-column b-popup-sticky-bottom-buttons'>
        <FwButton
            size='little'
            :disabled='!isFormValid || isDisabled'
            borderRadiusType='small-border'
            @click='submit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
        <div v-if='$route.params.id && !isWorkerAdd && canDelete'
             class='b-add-branch__form__delete'
             @click='$emit(`showDeletePopup`)'>
            {{ $t('TECH.FORM.DELETE.POPUP.BUTTON') }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import { SelectAddress } from '@/components/common/SelectAddress';
import { ParsedAddressData } from '@/types/GoogleMap';
import {
    FIRST_NAME,
    LAST_NAME,
    SKILLS,
    COMPANY,
    EMAIL,
} from '@/helpers/worker';
import {
    CategoryLocationSkillNameType,
    GeograficCitiesType,
} from '@/types/Appointment';
import TranslateMixin from '@/mixins/TranslateMixin';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { CompanyType, CompanyData } from '@/types/Companies';
import { WorkerType } from '@/types/Workers';
import { getPrettyStringDate } from '@/helpers/dates';
import { EditAddressForm } from '@/components/forms/edit/EditAddressForm';
import { WorkingHoursUpdateInfo } from '@/components/simple/WorkingHoursUpdateInfo/index';
import PermissionsMixin from '@/mixins/permissions';

const AppointmentStore = namespace('AppointmentStore');
const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        SelectAddress,
        EditAddressForm,
        WorkingHoursUpdateInfo,
    },
    validators: {
        [FIRST_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [LAST_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [EMAIL](value: string) {
            return this.requiredValidator({ value });
        },
        // [GEOGRAPHIC_ZONE](value: string) {
        //     return this.requiredValidator({ value });
        // },
    },
})
export default class ConsultPartnerAddTechnician extends Mixins(ValidationMixin, TranslateMixin, PermissionsMixin) {
    @WorkerPageStore.Action('storeWorkerUpdateData') storeWorkerUpdateData!: Function;
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @WorkerPageStore.Mutation('setCompanyUserData') setCompanyUserData!: Function;
    @WorkerPageStore.Mutation('WORKER_DATA') setWorkerData!: Function;
    @WorkerPageStore.Mutation('UPDATE_ADDRESS_POPUP_SHOW') setUpdateAddressShow!: Function;
    @WorkerPageStore.State('workerData') workerData!: WorkerType;
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;
    @WorkerPageStore.State('companyStateData') companyStateData!: CompanyData;
    @AppointmentStore.State('geograficCitiesData') geograficIds!: Array<GeograficCitiesType>;
    @Prop({ type: Boolean, default: false }) readonly isWorkerAdd!: boolean;
    zoneValue: string | null = null;
    [FIRST_NAME]: string = '';
    [LAST_NAME]: string = '';
    company: null | CompanyType = null;
    [EMAIL]: string = '';
    EMAIL: string = EMAIL;
    FIRST_NAME: string = FIRST_NAME;
    LAST_NAME: string = LAST_NAME;
    phone: string = '';
    address: string | null = '';
    post_code: string | null = '';
    skills: Array<CategoryLocationSkillNameType> = [];
    categoryLocationSkills: Array<CategoryLocationSkillNameType> = [];
    companies: Array<CompanyType> = [];
    geographic_zone: string = '';
    isActiveAdminRequestSending: boolean = false;
    isCompanyAddress: boolean = false;
    isUpdateAddress: boolean = false;
    isCompanySelectDisable: boolean = false;
    isInitiaStartPoinDisabled: boolean = false;
    isActiveAdmin: boolean = false;
    loading: boolean = false;
    sendRequest: boolean = false;
    skillChoosed: Array<CategoryLocationSkillNameType> = [];
    locationSkillChoosed: Array<CategoryLocationSkillNameType> = [];
    errorFromServer: string = '';
    serverErrors: serverErrors = {
        [FIRST_NAME]: null,
        [LAST_NAME]: null,
        [EMAIL]: null,
    };
    zones: Array<string> = [`Zone 1`, `Zone 2`];

    get isTypeSite() {
        return this.locationSkillChoosed.some(item => item.searchable_name === 'location_category_on_site' || item.searchable_name === 'location_category_on_site_in_branch');
    }

    get isDisabled(): boolean {
        return !this.isAdminFormValid || (this.isTypeSite && (!this.address && !this.isCompanyAddress)) || this.sendRequest || this.geograficIds.length === 0;
    }

    get isAdminFormValid(): boolean {
        return Boolean(this.skillChoosed && this.locationSkillChoosed.length !== 0 && this.company);
    }

    get isDeparturePoint() {
        return this.workerData ? this.workerData.technician_profile.departure_point : null;
    }
    get updateDate() {
        if (this.addressUpdateData) {
            return getPrettyStringDate(new Date(this.addressUpdateData.dt_execute_at), this.$i18n, true);
        }

        return null;
    }
    deleteItem(item: GeograficCitiesType) {
        if (item) {
            const arrayItems = this.geograficIds.filter(filterItem => filterItem.id !== item.id);
            this.setGeograficCitiesData(arrayItems);
        }
    }
    input(parsedAddressData: ParsedAddressData) {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
    }

    async created() {
        this.loading = true;
        const helpers = await WorkersWebApi.getTechnicianHelpersData();
        if (!this.skills.length) {
            this.skills = helpers.data.skill_names;
        }
        this.companies = helpers.data.company_titles;
        this.categoryLocationSkills = helpers.data.category_location_skill_names;
        this.setGeograficCitiesData([]);
        if (this.$route.params.id && this.isWorkerAdd) {
            this.company = this.companies.find(item => item.id === this.$route.params.id) || null;
            this.isCompanySelectDisable = true;
        }
        if (this.$route.params.id && !this.isWorkerAdd) {
            this.setUpdateAddressShow(false);
            const existTechnician = { data: { user: this.workerData }};
            const { data } = this.workerData ? existTechnician : await WorkersWebApi.getTechnicianData({
                id: this.$router.currentRoute.params.id,
            });
            const { user } = data;
            if ((user as WorkerType)) {
                this.setGeograficCitiesData(user.geo_sites);
                this.company = this.companies.find(item => item.id === user.company_id) || null;
                this.zoneValue = user.time_zone; // wrong
                this.isCompanyAddress = user.is_company_address_used;
                this[FIRST_NAME] = user.first_name;
                this[LAST_NAME] = user.last_name;
                this[EMAIL] = user.email;
                this.phone = user.phone || user.technician_profile.phone;
                this.storeWorkerUpdateData(user.working_hours_update);
                // this.geographic_zone = technician.time_zone; // wrong
                this.isActiveAdmin = user.technician_profile.company_admin;
                if (user.technician_profile && user.technician_profile.departure_point) {
                    this.address = user.technician_profile.departure_point;
                    this.isInitiaStartPoinDisabled = true;
                }
                // @ts-ignore-next-line
                this.skillChoosed = this.skills.filter(item => user.skills.map(skill => skill.id).includes(item.id));
                this.locationSkillChoosed = this.categoryLocationSkills.filter(item => {
                    return user.skills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                });
            }
        }
        this.loading = false;
    }

    async submit() {
        if (this.isDisabled) return;
        this.isRequestSending = true;
        try {
            await this.sendDataToServer();
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    async sendDataToServer() {
        this.sendRequest = true;
        if (this.isUpdateAddress && this.isTypeSite) {
            this.setUpdateAddressShow(this.isUpdateAddress);
        }
        try {
            const departure_point = !this.isCompanyAddress && this.address ? this.address : null;
            const skill_ids = [...this.skillChoosed!.map(item => item.id), ...this.locationSkillChoosed!.map(item => item.id)];
            const geo_site_ids = [...this.geograficIds!.map(item => item.id)];
            const set_company_as_starting_point = !!this.isCompanyAddress;
            const payload = {
                first_name: this[FIRST_NAME],
                last_name: this[LAST_NAME],
                label: '',
                kind: 'technician',
                company_id: this.company ? this.company.id : '',
                email: this[EMAIL],
                phone: this.phone,
                company_admin: this.isActiveAdmin,
                skill_ids,
                geo_site_ids,
                departure_point,
                set_company_as_starting_point,
            };
            if (this.$route.params.id && !this.isWorkerAdd) {
                const { data } = await WorkersWebApi.updateWorker(payload, this.$route.params.id);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
                this.$emit('closeAdvisorPopup');
                this.setWorkerData(data.user);
            } else {
                const { data } = await WorkersWebApi.createWorker(payload);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
                if (this.$route.meta && this.$route.meta.isMainComponent) {
                    this.$router.push({ path: `/consult/technician/${data.user.id}` });
                } else {
                    // @ts-ignore-next-line
                    this.setCompanyUserData(this.companyStateData.technicians.concat(data.user));
                }
            }
            this.$emit('closeWorkerPopup');
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.sendRequest = false;
        }
    }
}
</script>

<style lang='sass'>
.b-add-worker
    &__wrapper
        .b-label-card
            background-color: #fff
            height: 60px !important

    &__required-fields
        display: flex
        align-items: center
        justify-content: flex-end
        font-weight: 500
        color: #213F6B
        height: 60px
        margin-top: 26px

    &__is_admin_active
        font-weight: 500

.b-update-address__checkbox
    .icon-white__background--transparent
        padding: 0!important

    .icon-white__background p, .icon-white__background--transparent p
        max-width: 100%!important
        padding-right: 10px

.h-flex-1-0
    min-width: 0
</style>
