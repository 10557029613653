import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { SettingsRoleSimpleType } from '@/types/Settings';

@Module({
    namespaced: true,
})
class SettingsStore extends VuexModule {
    roles: Array<SettingsRoleSimpleType> | null = null;

    @Mutation
    setRoles(roles: Array<SettingsRoleSimpleType>) {
        this.roles = roles;
    }
}

export default SettingsStore;
