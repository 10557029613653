export default {
    'BRANCH.PLACEHOLDER.SEARCH': 'Rechercher une agence',
    'BRANCH.ADVISORS.NUMBER': 'Nombre de ressources',

    'BRANCH.FORM.LABEL': 'Administrateur de l\'agence',
    'BRANCH.FORM.DELETE.POPUP.TITLE': 'Supprimer l\'agence',
    'BRANCH.FORM.DELETE.POPUP.TEXT': 'Voulez-vous vraiment supprimer cette agence ?',
    'BRANCH.FORM.CREATE.POPUP.TEXT': 'L\'agence a bien été ajoutée',
    'BRANCH.FORM.UPDATE.POPUP.TEXT': 'L\'agence a été mise à jour',
    'BRANCH.GO.TO.BRANCH': 'Retour à l\'agence',
    'BRANCH.ROOM.TITLE': 'Pièce',
};
