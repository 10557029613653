import { Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class GoogleApi extends Vue {
    registerOnMounted: boolean = false;

    get googleApiKey() {
        return `AIzaSyAriMGhbuma-dXNbZIR1lJm7em6qJBuwHM`;
    }

    get scriptId() {
        return `google-map-apis/${this.mapGetParam}`;
    }

    mounted() {
        if (this.mapGetParam) {
            this.removeGoogleMapScript();
            const script = document.createElement(`script`);
            script.type = `text/javascript`;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&${this.mapGetParam}&language=${this.$i18n.locale}`;
            script.id = this.scriptId;
            script.async = true;
            script.onload = () => this.mountGoogleMaps && this.mountGoogleMaps();
            document.head.appendChild(script);
        }
    }

    removeGoogleMapScript() {
        const keywords = ['maps.googleapis'];
        // Remove google map scripts from DOM
        const scripts = document.head.getElementsByTagName('script');
        for (let i = scripts.length - 1; i >= 0; i--) {
            const scriptSource = scripts[i].getAttribute('src');
            if (scriptSource !== null) {
                if (keywords.filter(item => scriptSource.includes(item)).length) {
                    scripts[i].remove();
                }
            }
        }
        // @ts-ignore
        window.google = {};
    }

    beforeDestroy(): void {
        this.removeGoogleMapScript();
    }

    @Watch(`$route`)
    onSearchTextChange() {
        this.removeGoogleMapScript();
    }
};
