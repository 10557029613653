import Vue from 'vue';
import dayjs from 'dayjs';
import { Component, Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { clone, omit } from 'ramda';
import { EventsWebApi } from '@/api/events/EventsApi';
import {
    AvailabilityEventInstancesType,
    CreateEventPayload,
    UnavailabilityEventInstancesType,
} from '@/types/Events/Workers';
import {
    ResrvedSlotsWorkersResponseType,
    UnavailabilityWorkersResponseType,
    AvailabilityWorkersResponseType,
} from '@/types/Events';
import { BaseMetaType } from '@/types/Response';
import { ReservedSlotsType } from '@/types/ReservedSlots';
import { ReservedSlotType } from '@/types/Events/ReservedSlots';
import {
    getFirstDayOfMonth,
    getLastDayOfMonth,
    formatDateForTitle,
    equalByDay,
    getDateAsIsoString,
    formatDateDayMonthYearByLocale,
} from '@/helpers/dates';
import { CalendarInstancesParamsType } from '@/types/Calendar';
import { CreateShiftPayloadType } from '@/types/ServicePoint';

@Component
export default class ReservedSlotsMixin extends Vue {
    loading: boolean = false;
    localDate: null | Date = new Date();
    changedCalendarDate: null | Date = new Date();
    lastEventDate: null | Date = new Date();
    reservedSlots: Array<ReservedSlotsType> = [];
    availabilitySlots: Array<ReservedSlotsType> = [];
    forceTempHidePopup: boolean = false;
    showSubPopup: boolean = false;
    instanceData: null | UnavailabilityEventInstancesType = null;
    showReservedSlotPopup: boolean = false;
    meta: BaseMetaType | null = null;
    events: Array<UnavailabilityEventInstancesType> | Array<ReservedSlotType> | Array<AvailabilityEventInstancesType> = [];
    currentSlot: ReservedSlotsType | null = null;
    currentAvailabilitySlot: AvailabilityEventInstancesType | null = null;
    selectedSlot: UnavailabilityEventInstancesType | null = null;
    forceShowAddReservedSlot: boolean = false;
    isRequestSending: boolean = false;
    forceEmptyInstance: boolean = false;
    baseFormat: string = `E, dd MMM yyyy HH:mm:ss`;

    get date(): null | string {
        if (this.localDate) {
            return formatDateForTitle(this.localDate);
        }
        return null;
    }

    get currentSlots(): Array<ReservedSlotsType> | null {
        if (this.localDate) {
            return this.reservedSlots.filter(item => this.equalByDay(item.date));
        }
        return null;
    }

    get currentInstancesData(): Array<UnavailabilityEventInstancesType> | null {
        if (this.localDate) {
            return (this.events as Array<UnavailabilityEventInstancesType>).filter(
                event => {
                    const condition = this.selectedSlot ? event.id === this.selectedSlot.id : true;

                    return condition && this.equalByDay(new Date(event.dt_start));
                }
            );
        }
        return null;
    }

    get currentInstanceData(): UnavailabilityEventInstancesType | null {
        if (this.currentInstancesData === null || this.forceEmptyInstance) {
            return null;
        }
        return this.currentInstancesData && this.currentInstancesData.length === 1 ? this.currentInstancesData[0] : null;
    }

    get showChooseReservedSlot() {
        return !this.forceShowAddReservedSlot && !this.currentSlot && this.currentInstancesData && this.currentInstancesData.length;
    }

    addSlot(data: ReservedSlotsType): void {
        if (this.localDate) {
            const value = this.getDateAsSting(this.localDate);
            this.reservedSlots.push({
                // @ts-ignore-next-line
                date: new Date(value),
                ...data,
            });
            this.showSubPopup = false;
        }
    }

    closePopup() {
        this.forceEmptyInstance = false;
        this.selectedSlot = null;
        this.showSubPopup = false;
    }

    closeAddReservedSlotPopup() {
        this.forceShowAddReservedSlot = false;
        this.closePopup();
    }

    setSlot(instance: UnavailabilityEventInstancesType): void {
        this.selectedSlot = instance;
        this.currentSlot = this.reservedSlots.find(slot => slot.id === instance.id) || null;
    }

    setShowAddReservedSlot() {
        this.forceEmptyInstance = true;
        this.selectedSlot = null;
        this.forceShowAddReservedSlot = true;
    }

    async createEvent(payload: CreateEventPayload) {
        try {
            const shiftPayload: CreateShiftPayloadType | null = this.isShift ? {
                ...omit(['origin', 'title'], { ...payload }),
                site_id: this.$route.params.id,
                ...(this.currentInstanceData?.user_id && { user_id: this.currentInstanceData.user_id }),
            } : null;
            this.isRequestSending = true;
            if (this.currentInstanceData && !this.isReservedSotsPopup) {
                if (this.isShift && shiftPayload) {
                    await EventsWebApi.updateShiftEvent(shiftPayload, this.currentInstanceData.id);
                } else {
                    await EventsWebApi.updateEvent({ payload, id: this.currentInstanceData.event_id });
                }
            } else if (this.forceShowAddReservedSlot || !this.currentInstanceData) {
                if (this.isReservedSotsPopup || this.isUnavailability) {
                    if (this.isUnavailability) {
                        payload.origin = 'unavailability';
                    } else {
                        payload.origin = 'reserved_slot';
                    }

                    await EventsWebApi.createEvent(payload);
                } else if (this.isAvailability) {
                    const { dt_start, dt_end, user_id } = payload;
                    const dateStart = formatDateDayMonthYearByLocale(new Date(dt_start), 'en');
                    const dateEnd = formatDateDayMonthYearByLocale(new Date(dt_end), 'en');

                    await EventsWebApi.createAvailabilityEvent({
                        from: dateStart,
                        to: dateEnd,
                        user_id,
                    });
                } else if (this.isShift && shiftPayload) {
                    await EventsWebApi.createShiftEvent(shiftPayload);
                }
            }

            this.forceEmptyInstance = false;
            this.lastEventDate = new Date(payload.dt_end);
            this.showSubPopup = false;
            this.getMonthData({ isFullMonthRequest: true });
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    async removeEvent(
        { instance, onlyInstance, future }: { instance: UnavailabilityEventInstancesType | AvailabilityEventInstancesType, onlyInstance?: boolean, future?: boolean }
    ) {
        if (this.isAvailability && instance) {
            this.isRequestSending = true;
            if (onlyInstance) {
                try {
                    await this.deleteAvailabilityInstance((instance as AvailabilityEventInstancesType).id);
                    this.isRequestSending = false;
                } catch (e) {
                    this.isRequestSending = false;
                }
            } else {
                try {
                    await this.deleteAvailabilities((instance as AvailabilityEventInstancesType).user_id);
                    this.isRequestSending = false;
                } catch (e) {
                    this.isRequestSending = false;
                }
            }
        } else if (this.currentInstanceData) {
            this.isRequestSending = true;
            if (onlyInstance || future || (this.isShift && this.selectedSlot?.recurrence_id)) {
                // eslint-disable-next-line
                const kind = future ? 'future' : (onlyInstance ? null : 'all');
                try {
                    await this.deleteInstance(instance.id, Boolean(future), kind);
                    this.isRequestSending = false;
                } catch (e) {
                    this.isRequestSending = false;
                }
            } else {
                try {
                    await this.deleteEvent(this.isShift ? instance.id : (instance as UnavailabilityEventInstancesType).event_id);
                    this.isRequestSending = false;
                } catch {
                    this.isRequestSending = false;
                }
            }
            this.getMonthData({ isFullMonthRequest: true });
        }
    }

    async deleteEvent(id: string) {
        this.isRequestSending = true;
        try {
            if (this.isShift) {
                await EventsWebApi.deleteFullShiftData(id);
                this.selectedSlot = null;
                this.currentSlot = null;
            } else {
                await EventsWebApi.deleteEvent(id);
            }
            // @ts-ignore-next-line
            this.events = this.events.filter((instance: any) => !this.equalByDay(new Date(instance.dt_start)));
            this.showSubPopup = false;
            this.showReservedSlotPopup = false;
            this.isRequestSending = false;
        } catch (e) {
            this.isRequestSending = false;
        }
    }

    async deleteInstance(id: string, future: boolean, kind: null | 'future' | 'all' = null) {
        this.isRequestSending = true;
        try {
            if (this.isShift) {
                await EventsWebApi.deleteFullShiftData(id, kind);
            } else {
                await EventsWebApi.deleteCalendarInstance(id, future);
            }
            if (this.isShift) {
                this.selectedSlot = null;
                this.currentSlot = null;
            }
            // @ts-ignore-next-line
            this.events = this.events.filter((instance: any) => !this.equalByDay(new Date(instance.dt_start)));
            this.showSubPopup = false;
            this.showReservedSlotPopup = false;
            this.isRequestSending = false;
        } catch (e) {
            this.isRequestSending = false;
        }
    }

    async deleteAvailabilityInstance(id: string) {
        this.isRequestSending = true;
        try {
            await EventsWebApi.deleteCalendarAvailabilityInstance(id);
            this.events = (this.events as Array<AvailabilityEventInstancesType>).filter((instance: any) => !this.equalByDay(new Date(instance.due_on)));
            this.availabilitySlots = (this.events as Array<AvailabilityEventInstancesType>).map(item => ({ date: new Date(item.due_on), id: item.id }));
            this.showSubPopup = false;
            this.showReservedSlotPopup = false;
            this.isRequestSending = false;
        } catch (e) {
            this.isRequestSending = false;
        }
    }

    async deleteAvailabilities(id: string) {
        this.isRequestSending = true;
        try {
            await EventsWebApi.deleteCalendarAvailabilities(id);
            this.events = [];
            this.availabilitySlots = [];
            this.showSubPopup = false;
            this.showReservedSlotPopup = false;
            this.isRequestSending = false;
        } catch (e) {
            this.isRequestSending = false;
        }
    }

    filterDuplicates<T extends Array<UnavailabilityEventInstancesType> | Array<ReservedSlotType>>(slots: T): T {
        // @ts-ignore-next-line
        return slots.filter((element, index) => slots.findIndex(
            obj => (
                // @ts-ignore-next-line
                obj.event_id === element.event_id &&
                obj.dt_start_view === element.dt_start_view &&
                obj.dt_end_view === element.dt_end_view &&
                obj.dt_start === element.dt_start &&
                obj.dt_end === element.dt_end
            )) === index
        );
    }

    prepareSlots(slots: Array<UnavailabilityEventInstancesType | ReservedSlotType>): Array<UnavailabilityEventInstancesType> | Array<ReservedSlotType> {
        const slotsCopy = clone(slots);
        const pseudoSlots: Array<UnavailabilityEventInstancesType> | Array<ReservedSlotType> = [];
        for (let i = 0; i < slots.length; i++) {
            const startDate = new Date(slots[i].dt_start);
            const endDate = new Date(slots[i].dt_end);
            const diffTime = Math.abs(+endDate - +startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays === 1) continue;
            for (let j = 0; j < diffDays; j++) {
                const pseudoSlot = clone(slots[i]);
                const dtStartDate = new Date(pseudoSlot.dt_start);
                const dtEndDate = new Date(pseudoSlot.dt_end);
                pseudoSlot.dt_start_view = pseudoSlot.dt_start;
                pseudoSlot.dt_end_view = pseudoSlot.dt_end;
                pseudoSlot.dt_start = format(new Date(dtStartDate.setDate(dtStartDate.getDate() + j)), this.baseFormat);
                pseudoSlot.dt_end = format(new Date(dtEndDate.setDate(dtEndDate.getDate() - (diffDays - j - 1))), this.baseFormat);
                pseudoSlot.is_pseudo_slot = true;
                // @ts-ignore-next-line
                pseudoSlots.push(pseudoSlot);
            }
            const currentIndex = slotsCopy.findIndex((item: UnavailabilityEventInstancesType | ReservedSlotType) => item.id === slots[i].id);
            slotsCopy.splice(currentIndex, 1);
        }
        const seen = new Set();
        // @ts-ignore-next-line
        return this.filterDuplicates([...slotsCopy, ...pseudoSlots]);
    }

    async getMonthData({ date, isFullMonthRequest }: { date?: null | Date, isFullMonthRequest?: boolean }) {
        this.isRequestSending = false;
        const localDate = date || (this.localDate as Date);
        let from;
        let to;
        if (!isFullMonthRequest && date) {
            from = date.toDateString();
            to = date.toDateString();
        } else {
            const firstDayOfMonth: Date = getFirstDayOfMonth(localDate);
            const lastDayOfMonth: Date = getLastDayOfMonth(localDate, 1);
            from = firstDayOfMonth.toDateString();
            to = lastDayOfMonth.toDateString();
        }
        let origin = 'reserved_slot';
        if (this.isUnavailability) {
            origin = 'unavailability';
        } else if (this.isAvailability) {
            origin = 'availability';
        }

        const page = this.meta && this.meta.next_page ? this.meta.next_page : 1;
        // @ts-ignore-next-line
        await this.fetchCalendarDataRec({
            to,
            from,
            user_id: this.$route.params.id,
            // @ts-ignore-next-line
            origin,
            page,
            location: this.startActiveId,
        });
    }

    async fetchCalendarDataByPage({ to, from, user_id, page, location }: CalendarInstancesParamsType) {
        try {
            this.isRequestSending = false;
            if (this.isUnavailability) {
                const data: UnavailabilityWorkersResponseType = await EventsWebApi.getUnavailabilities({ to, from, user_id, page });
                if (page === 1) {
                    this.events = this.prepareSlots(data.unavailabilities);
                } else {
                    // @ts-ignore-next-line
                    this.events = [...this.events, ...this.prepareSlots(data.unavailabilities)];
                }
                this.meta = data.meta;
            } else if (this.isAvailability) {
                const data: AvailabilityWorkersResponseType = await EventsWebApi.getAvailabilities({
                    to,
                    from,
                    user_id,
                    page,
                });
                if (page === 1) {
                    // @ts-ignore-next-line
                    this.events = data.flexible_dates;
                } else {
                    // @ts-ignore-next-line
                    this.events = [...this.events, ...data.flexible_dates];
                }
                this.meta = data.meta;
            } else if (this.isShift) {
                // @ts-ignore-next-line
                const data: any = await EventsWebApi.getShiftsSlots({
                    from,
                    sp_id: user_id,
                    page,
                    advisor_id: this.shiftsAdvisor ? this.shiftsAdvisor.id : ``,
                });
                if (page === 1) {
                    this.slots = data.dates.map((date: string) => ({ date: dayjs(date).toDate() }));
                } else {
                    // @ts-ignore-next-line
                    this.slots = [...this.events, ...data.dates].map((date: string) => ({ date: dayjs(date).toDate() }));
                }
                this.meta = data.meta;
            } else {
                // @ts-ignore-next-line
                const data: ResrvedSlotsWorkersResponseType = await EventsWebApi.getReservedSlots({ to, from, user_id, page, location });
                if (page === 1) {
                    this.events = this.prepareSlots(data.reserved_slots);
                } else {
                    // @ts-ignore-next-line
                    this.events = [...this.events, ...this.prepareSlots(data.reserved_slots)];
                }
                this.meta = data.meta;
            }
            if (this.isAvailability) {
                this.availabilitySlots = (this.events as Array<AvailabilityEventInstancesType>).map(item => ({ date: new Date(item.due_on), id: item.id }));
            } else {
                this.reservedSlots = (this.events as Array<UnavailabilityEventInstancesType>).map(item => ({ date: new Date(item.dt_start), id: item.id }));
            }
        } catch (e) {
            this.isRequestSending = false;
        }
    }

    async fetchCalendarDataRec({ to, from, user_id, origin, page, location }: CalendarInstancesParamsType) {
        await this.fetchCalendarDataByPage({ to, from, user_id, origin, page, location });
        if (this.meta && this.meta.next_page) {
            this.fetchCalendarDataRec({ to, from, user_id, origin, page: this.meta.next_page, location });
        }
    }

    equalByDay(date: Date): boolean {
        if (!this.localDate) {
            return false;
        }

        return equalByDay(date, this.localDate);
    }

    getDateAsSting(date: Date): string {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

    closeReservedSlotPopup(): void {
        this.showReservedSlotPopup = false;
    }

    openDeletePopup(instance: UnavailabilityEventInstancesType): void {
        this.instanceData = instance;
        this.showReservedSlotPopup = true;
    }

    changeCalendarMonth(data: { year: number, month: number, flag: number, vm: any, sibling: undefined } | null | Date, forceDate: null | Date) {
        if (!data && !forceDate) return;
        // @ts-ignore-next-line
        if (forceDate) {
            this.changedCalendarDate = forceDate;
        } else if (data) {
            this.changedCalendarDate = data instanceof Date ? data : new Date(data.year, data.month + data.flag + 1, 0);
        }
        if (!this.changedCalendarDate) return;
        this.forceTempHidePopup = true;
        this.getMonthData({ date: this.changedCalendarDate, isFullMonthRequest: true });
    }

    selectDate(date: Date) {
        this.forceTempHidePopup = false;
        if (this.isAvailability && !!this.events.length) {
            // @ts-ignore-next-line
            this.currentAvailabilitySlot = this.events.find((item: AvailabilityEventInstancesType) => {
                return getDateAsIsoString(new Date(item.due_on)) === getDateAsIsoString(date);
            });
        } else {
            this.currentAvailabilitySlot = null;
        }

        this.currentSlot = null;
        this.selectedSlot = null;
        this.showSubPopup = true;
    }

    async created() {
        if (this.isShift) {
            this.fetchShiftAdvisors();
        }
        await this.getMonthData({ isFullMonthRequest: true });
        this.loading = false;
    }

    @Watch('currentSlots')
    currentSlotsHandler(slots: Array<ReservedSlotsType>) {
        if (slots && slots.length !== 1) {
            this.currentSlot = null;
        }
    }

    @Watch('localDate')
    localDateHandler(date: Date) {
        this.forceShowAddReservedSlot = false;
    }

    @Watch('showSubPopup')
    showSubPopupHandler(value: boolean) {
        if (value) {
            this.forceTempHidePopup = false;
        }
    }
};
