export default {
    'FINAL.STEPS.TITLE': 'Dernières étapes',
    'FINAL.STEPS.ASIDE': 'Vous devez suivre ces deux dernières étapes',
    'FINAL.STEPS.WORKERS.TITLE': 'Ressources',
    'FINAL.STEPS.WORKERS.OPTION.1': 'Confirmer les horaires de travail de vos ressources',
    'FINAL.STEPS.ADVISORS.OPTION.1': 'Valider les heures de travail de vos conseillers',
    'FINAL.STEPS.WORKERS.OPTION.2': 'Les inviter à synchroniser leur calendrier',
    'FINAL.STEPS.ROOMS.TITLE': 'Salles',
    'FINAL.STEPS.ROOMS.OPTION.1': 'Confirmez les heures d\'ouverture de vos chambres',
    'FINAL.STEPS.ROOMS.OPTION.2': 'Invitez-les à synchroniser leurs calendriers',
    'FINAL.STEPS.ROOMS.OPTION.3': 'Remplissez les indisponibilités de vos chambres',

    'FINAL.STEPS.BUTTON': 'Allons-y',

    'FINAL.WORKING.HOURS.TITLE': 'Ressources - Ressources de travail',
    'FINAL.WORKING.HOURS.ASIDE': 'Quelles sont vos кessources de travail?',
    'FINAL.WORKING.HOURS.WORKERS.OPTION.1': 'Tous mes employés ont des horaires de travail identiques',
    'FINAL.WORKING.HOURS.ADVISORS.OPTION.1': 'Tous mes conseillers ont les mêmes horaires de travail identiques',
    'FINAL.WORKING.HOURS.WORKERS.OPTION.2': 'Chaque travailleur a des horaires de travail spécifiques',
    'FINAL.WORKING.HOURS.ADVISORS.OPTION.2': 'Chaque travailleur a des horaires de travail spécifiques',
};
