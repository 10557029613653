<template>
<div :class='[mainWrapperClass, `container`]'>
    <div v-if='!isPopupForm' class='b-base-title__wrapper'>
        <h2 class='b-appointment-left-bar__title'>
            {{ $t('PLAN.APPOINTMENT.BRANCH.ROOMS.TITLE') }}
        </h2>
    </div>
    <GoBackButton
        v-if='isRouteConsult'
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <div v-if='branch' :class='mainRoomsClass'>
        <div v-if='!isPopupForm' class='h-mt-0 h-flex h-flex-dir-column h-align-items-center'>
            <router-link :to='isRouteConsult ? `/consult/branch/${$route.params.id}` : `${mainRouteGlobal}/branches/edit/${$route.params.id}`'>
                <ConsultCard
                    v-if='branch'
                    class='b-add-branch__card'
                    :information='information'>
                </ConsultCard>
            </router-link>
        </div>
        <div style='width: 310px' class='h-flex h-flex-space-between h-pb-30'>
            <FwFormInput
                labelType='label-medium'
                class='h-flex-1-0 h-mr-30 h-mt-30'>
                <div class='fw-select-base'>
                    <div class='b-team-list__body h-pointer'
                         @click='addOrEditRoom(null)'>
                        <div class='h-fw-500' style='color: rgba(33,63,107,.5);'>
                            {{ $t('ADD.ROOM.TITLE') }}
                        </div>
                        <FwIcon
                            class='h-flex-center h-pointer'
                            icon='edit-settings'
                            size='20'
                            color='#203f6a'>
                        </FwIcon>
                    </div>
                </div>
            </FwFormInput>
        </div>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <CalendarTable
            v-if='!loading && rooms.length'
            :titles='roomListTitles'
            :data='rooms'
            :bigIndexes='bigIndexes'
            :style='maxWidthWrapper'
            class='b-calendar__table__wrapper'>
            <div
                v-for='(item, i) in rooms'
                :key='i'
                class='b-calendar__table'>
                <div class='b-calendar__table__outer'>
                    <div v-for='(key, j) in mapping'
                         :key='j'
                         class='b-calendar__table__item'
                         :class='{ "b-calendar__table__item--big": bigIndexes.includes(j) }'>
                        <template v-if='item[key] && item[key].length'>
                            {{ item[key] }}
                        </template>
                    </div>
                </div>
                <CalendarTableButtons
                    @edit='addOrEditRoom(item)'
                    @delete='openDeletePopup(item)'>
                </CalendarTableButtons>
            </div>
        </CalendarTable>
        <div v-if='isRouteConsult'
             class='h-width-100p h-flex h-flex-center h-mv-40'>
            <FwButton
                :to='`/consult/branch/${$route.params.id}`'
                class='h-width-180'
                size='little'
                borderRadiusType='small-border'>
                {{ $t('BRANCH.GO.TO.BRANCH') }}
            </FwButton>
        </div>
    </div>
    <div v-if='isPopupForm' class='h-flex h-flex-center h-mt-40'>
        <FwButton
            class='h-mt-20'
            size='little'
            borderRadiusType='small-border'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
    <portal to='appointment-bar-buttons'>
        <FwButton
            :to='`${mainRouteGlobal}${$route.meta.nextUrl}`'
            size='little'
            borderRadiusType='small-border'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </portal>

    <AddEditRoomPopup
        v-if='isAddEditRoomPopup'
        :roomData='currentRoomData'
        :branch_id='$route.params.id'
        @update='onAddRoom'
        @close='isAddEditRoomPopup = false'>
    </AddEditRoomPopup>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.ROOM.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.ROOM.TEXT`)'
        @deleteItem='deleteItem'
        @close='confirmPopup = false'>
    </DeletePopup>
    <FinalePopup
        v-if='finalePopup'
        :headerText='$t(`DELETE.APPOINTMENT.ROOM.SUCCESS.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.ROOM.SUCCESS.TEXT`)'
        popupWidth='400px'
        @close='closeFinalePopup'>
    </FinalePopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { isNil } from 'ramda';
import ValidationMixin from '@/mixins/validation';
import { ConsultCard } from '@/components/simple/ConsultCard';
import { NAME, EMAIL } from '@/helpers/branch';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { SitesApi } from '@/api/sites/SitesApi';
import { BranchRoomResponseType } from '@/types/Appointment';
import { AddEditRoomPopup } from '@/components/popups/AddEditRoomPopup';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { SitesBranchDataType, BranchSitesDataType } from '@/types/Site';
import { BranchTitles } from '@/types/Branch';
import { capitalizeAll } from '@/helpers/string';
import i18n from '@/locale';

const OnboardingStore = namespace('OnboardingStore');

@Component({
    validators: {
        [NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [EMAIL](value: string) {
            return this.requiredValidator({ value });
        },
    },
    components: {
        ConsultCard,
        AddEditRoomPopup,
        DeletePopup,
        FinalePopup,
        CalendarTable,
        CalendarTableButtons,
        GoBackButton,
    },
})
export default class AppointmentBranchAddRoom extends Mixins(ValidationMixin) {
    @OnboardingStore.Mutation('mutationBranchData') mutationBranchData!: Function;
    @Prop({ type: Boolean, default: false }) readonly isPopupForm!: boolean;
    rooms: Array<SitesBranchDataType> = [];
    name: string | null = '';
    email: string | null = '';
    choosenBranch: BranchTitles | null = null;
    editableIndex: null | number = 0;
    confirmPopup: boolean = false;
    finalePopup: boolean = false;
    branches: Array<BranchTitles> = [];
    loading: boolean = false;
    isAddEditRoomPopup: boolean = false;
    currentRoomData: BranchTitles | null = null;
    branch: BranchSitesDataType | null = null;
    roomListTitles: Array<string> = [
        i18n.t('LABEL.NAME'),
        i18n.t('LABEL.EMAIL.ADDRESS'),
        i18n.t('PLAN.APPOINTMENT.BRANCH.VIDEO.ROOM'),
    ];
    mapping: Array<string> = [
        `name`,
        `email`,
        `facility`,
    ];
    bigIndexes: Array<number> = [];

    serverErrors: serverErrors = {
        name: null,
        email: null,
    };

    get mainClass() {
        return !this.isPopupForm ? `b-add-branch` : `b-popup-branch`;
    }

    get disableElements(): boolean {
        return !isNil(this.editableIndex);
    }

    get information() {
        if (!this.branch) {
            return {};
        }
        return {
            title: capitalizeAll(this.branch.name),
            aside: `${this.branch.address}, ${this.branch.post_code}`,
        };
    }

    get isRouteConsult() {
        return this.$route.meta && this.$route.meta.part === 'consult';
    }

    get mainRoomsClass() {
        return !this.isRouteConsult ? `${this.mainClass}__rooms` : null;
    }

    get mainWrapperClass() {
        return !this.isRouteConsult ? `${this.mainClass}__wrapper` : 'b-consult-branch--wrapper';
    }
    get maxWidthWrapper() {
        return this.isRouteConsult ? { maxWidth: `none` } : null;
    }
    closeFinalePopup() {
        this.finalePopup = false;
        this.getRooms();
    }

    addOrEditRoom(room: BranchRoomResponseType) {
        this.currentRoomData = room;
        this.isAddEditRoomPopup = true;
    }

    isDisabled(index: number): boolean {
        return this.disableElements && index !== (this.editableIndex as number);
    }

    updateBranchAddStore(branch: BranchRoomResponseType) {
        this.mutationBranchData(branch);
        this.getRooms();
    }

    preventIfDisabled(index: number, event: Event) {
        if (this.isDisabled(index)) {
            event.stopPropagation();
        }
    }

    async deleteItem() {
        if (this.currentRoomData) {
            if (this.isRouteConsult) {
                await SitesApi.deleteSite(this.currentRoomData.id as string);
            } else {
                await OnboardingApi.deleteOnboardingRoom(this.currentRoomData.id as string);
            }
            this.confirmPopup = false;
            this.finalePopup = true;
        }
    }

    openDeletePopup(room: BranchRoomResponseType) {
        this.confirmPopup = true;
        this.currentRoomData = room;
    }

    onAddRoom() {
        this.isAddEditRoomPopup = false;
        this.getRooms();
    }

    async getRooms() {
        this.loading = true;
        if (this.isRouteConsult) {
            const { site } = await SitesApi.getSite(this.$route.params.id);
            this.branch = site;
            this.rooms = this.branch!.branch_rooms;
        } else {
            this.branch = await OnboardingApi.getOnboardingSite(this.$route.params.id);
            this.rooms = this.branch.branch_rooms;
        }
        this.loading = false;
    }

    async created() {
        await this.getRooms();
    }
}
</script>

<style lang='sass'>
.b-consult-branch
    &--wrapper
        width: 900px

.b-popup-branch
    &__card
        max-width: 400px
        padding: 20px 50px
        border-radius: 10px
        background-color: rgba(32,63,106,0.05)
    &__wrapper
        width: 100%
        @include media('<=phone')
            width: 100%

        .b-required-field
            font-weight: 500
            font-size: 14px
            margin-bottom: 0

    &__rooms
        &__title
            font-size: 25px
            font-weight: 500
            margin-bottom: 0

        &__form
            display: flex
            justify-content: space-between
            align-items: center

        &__aside
            font-size: 16px
            font-weight: 500

        &__select
            width: 100px
.b-add-branch
    &__card
        max-width: 400px
        padding: 20px 50px
        border-radius: 10px
        background-color: rgba(32,63,106,0.05)
    &__wrapper
        width: 900px !important
        margin-bottom: 50px

        @include media('<=phone')
            width: 100%

        .b-required-field
            font-weight: 500
            font-size: 14px
            margin-bottom: 70px

    &__rooms
        margin-bottom: 170px
        &__title
            font-size: 25px
            font-weight: 500
            margin-bottom: 0

        &__form
            display: flex
            justify-content: space-between
            align-items: center

        &__aside
            font-size: 16px
            font-weight: 500

        &__select
            width: 100px
        &__bottom
            margin-bottom: 150px

        .b-calendar__table__inner
            max-width: none

.b-rooms-check
    &__list
        &__flex
            display: flex
            margin-bottom: 10px

            &--disabled
                opacity: 0.5
                position: relative
                &:after
                    content: ''
                    position: absolute
                    left: 0
                    top: 0
                    cursor: not-allowed
                    width: 100%
                    height: 100%
            &--edit
                .b-rooms-check__list__type
                    padding: 0
</style>
