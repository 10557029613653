const duration: Array<{ duration: number, title: string }> = [];

for (let i = 1; i < 8 * 4 + 1; i++) {
    const durationInMinutes = i * 15;
    const hours = Math.floor(durationInMinutes / 60);
    const hoursAsString = hours.toString().length === 1 ? `0${hours}` : `${hours}`;
    const minutesAsString = durationInMinutes % 60 ? `${durationInMinutes % 60}` : '00';
    duration.push({
        title: `${hoursAsString}h${minutesAsString}`,
        duration: durationInMinutes,
    });
}

export {
    duration,
};
