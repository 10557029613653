<template>
<div class='b-calendar-header-nav__wrapper'>
    <div class='b-calendar-header-nav__inner'>
        <div v-if='isMonthsCalendarExists'
             class='b-calendar-header-nav__buttons'>
            <FwButton
                :to='calendarMonthViewTo'
                class='b-calendar-header-nav__button h-flex-1-0'
                :color='isMonthSelected ? "blue" : "choose"'
                size='little'
                borderRadiusType='small-border'
                fontSize='12px'>
                {{ $t('CALENDAR.WEEK') }}
            </FwButton>
            <FwButton
                :to='calendarWeekViewTo'
                class='b-calendar-header-nav__button h-flex-1-0'
                :color='$route.path === calendarWeekViewTo ? "blue" : "choose"'
                size='little'
                borderRadiusType='small-border'
                fontSize='12px'>
                {{ $t('CALENDAR.MONTH') }}
                <FwIcon
                    v-if='accessLockMonthPage'
                    class='b-icon-lock h-ml-10'
                    icon='lock-blue'
                    size='15'
                    :color='$route.path === calendarWeekViewTo ? "#fff" : "#203f6a"'>
                </FwIcon>
            </FwButton>
        </div>
        <div v-if='isMonthsCalendarExists' class='b-calendar-header-nav__line'></div>
        <div class='b-calendar-header-nav__time-nav'>
            <div v-if='$slots["main-button"]'
                 class='h-flex b-calendar-header-nav__time-nav__main-buttons'>
                <slot name='main-button'></slot>
            </div>
            <div class='h-flex h-mh-10'>
                <div class='b-calendar-header-nav__arrow b-calendar-header-nav__arrow--left'
                     :class='{ disabled: hideBackButton }'
                     @click='hideBackButton ? null : changeDayHandler(-1)'>
                    <FwIcon
                        class='h-mt-2'
                        icon='arrow-left'
                        size='13'
                        color='#213F6B'>
                    </FwIcon>
                </div>
                <div class='b-calendar-header-nav__arrow b-calendar-header-nav__arrow--right'
                     :class='{ disabled: hideForwardButton }'
                     @click='hideForwardButton ? null : changeDayHandler(1)'>
                    <FwIcon
                        class='h-mt-2'
                        size='13'
                        icon='arrow-right'
                        color='#213F6B'>
                    </FwIcon>
                </div>
                <div class='b-calendar-header-nav__title'>
                    <slot name='current-time'></slot>
                </div>

                <div v-if='$slots["extra-title"]'
                     class='b-calendar-header-nav__title h-pl-50'>
                    <slot name='extra-title'></slot>
                </div>
            </div>
        </div>
    </div>
    <div v-if='$slots["right-block"]'
         class='h-flex'>
        <div class='b-calendar-header-nav__line'></div>
        <slot name='right-block'></slot>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Mixins } from 'vue-property-decorator';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { CONSULT_MONTH_VIEW_CALENDARS } from '@/helpers/calendar';

import PermissionsMixin from '@/mixins/permissions';

@Component({
    components: {
        GoBackButton,
    },
})
export default class CalendarHeaderNav extends Mixins(PermissionsMixin) {
    @Prop({ type: Date, required: true }) readonly calendarDate!: Date;
    @Prop({ type: Date, required: true }) readonly hideBackButton!: boolean;
    @Prop({ type: Date, required: true }) readonly hideForwardButton!: boolean;

    get calendarWeekViewTo(): string {
        return `/consult/calendar-month/${this.$route.params.type}/${this.$route.params.id}`;
    }

    get isMonthsCalendarExists(): boolean {
        return CONSULT_MONTH_VIEW_CALENDARS.includes(this.$route.params.type);
    }

    get isMonthSelected(): boolean {
        return this.$route.path.startsWith(this.calendarMonthViewTo);
    }

    get calendarMonthViewTo(): string {
        return `/consult/calendar/${this.$route.params.type}/${this.$route.params.id}`;
    }

    @Emit('changeDay')
    changeDayHandler(delta: number): { delta: number, date: Date } {
        return { delta, date: this.calendarDate };
    }
}
</script>

<style lang='sass'>
.b-calendar-header-nav
    height: 100%

    &__inner
        display: flex

        @include media('<=phone')
            flex-wrap: wrap
            justify-content: center

            .b-calendar-header-nav__line
                display: none

    &__title
        display: flex
        white-space: nowrap
        align-items: center
        font-size: 16px
        font-weight: bold
        color: #213F6B
        margin-left: 4px

    &__line
        height: 40px
        margin-top: -5px
        width: 0
        margin: 0px 30px auto
        position: relative

        &:after
            content: ''
            top: -2px
            left: 0
            border-right: 1px solid #979797
            width: 0px
            height: 44px
            position: absolute

    &__buttons
        position: relative
        z-index: 1
        display: flex
        max-height: 40px

        @include media('<=phone')
            display: none !important

    &__button
        padding: 0 20px
        height: 36px
        display: inline-flex
        width: auto
        padding: 12px 24px 10px !important
        white-space: nowrap

        &.router-link-active
            cursor: default !important

        &--small
            padding: 12px 15px 10px !important

        &.fw-button--choose
            border: 1px solid #E8EBF0

        &:not(:last-of-type)
            margin-right: 10px

    &__time-nav
        display: flex
        align-items: center

    &__arrow
        height: 40px
        width: 40px
        border-radius: 50%
        border: 1px solid #E8EBF0
        background-color: #fff
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer

        &.disabled
            cursor: default
            background-color: #E8EBF0
            svg
                fill: gray !important

        &:not(.disabled)
            cursor: pointer
            svg
                transition: transform .2s

            &:hover
                &.b-calendar-header-nav__arrow--left
                    svg
                        transform: translate(-2px, 0)

                &.b-calendar-header-nav__arrow--right
                    svg
                        transform: translate(2px, 0)

        &:not(:last-of-type)
            margin-right: 4px

    &__wrapper
        width: 100%
        background-color: green
        border-radius: 20px
        height: 76px
        background-color: #F8F9FA
        box-shadow: 0 5px 11px 0 rgba(33,63,107,0.1)
        display: flex
        align-items: center
        justify-content: space-between
        padding: 20px 30px

        @include media('<=phone')
            padding: 10px 15px

            .b-calendar-header-nav__buttons
                margin-bottom: 10px
</style>
