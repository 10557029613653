<template>
<div class='b-choose-room h-mt-30 h-flex h-flex-space-between h-align-items-center h-flex-dir-column'>
    <GoBackButton
        style='top: 40px; left: 40px'
        class='b-route-wrapper__back_arrow'
        :method='backToStep'
        @click.native.stop.prevent>
    </GoBackButton>
    <div class='b-society-selection h-flex-center'>
        <div style='width: 330px' class='fw-select-base fw-select-white-modern'>
            <div class='h-text-center h-pos-rel'>
                <h2 class='h-font-20'>
                    {{ $t('SELECT.ROOM.APPOINTMENT.TITLE') }}
                </h2>
            </div>
            <FwFormInput
                class='h-flex-1 h-mt-15 qa-direct-appointment__room'
                labelType='label-medium'
                :title='`${$t(`LABEL.TYPE`)}`'>
                <FwSelect
                    v-model='name'
                    class='fw-select-white'
                    :clear-on-select='true'
                    :propsPlaceholder='$t(`SELECT.ROOM.APPOINTMENT.PLACEHOLDER`)'
                    type='white'
                    :pagination='true'
                    :searchIcon='false'
                    :options='roomsData'
                    :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                    label='name'
                    @input='updateRoomsState'
                    @select='resetForm'>
                </FwSelect>
            </FwFormInput>
        </div>
    </div>
    <div class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-30 qa-direct-appointment__room-submit'
            :disabled='disabled'
            borderRadiusType='small-border'
            @click.native.stop.prevent='toBookingForm'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import {
    NAME,
} from '@/helpers/branch';

import { AppointmentRoomsType } from '@/types/Appointment';

import { GoBackButton } from '@/components/simple/GoBackButton';

import ValidationMixin from '@/mixins/validation';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    validators: {
        [NAME](value: string) {
            return this.baseInputValidator({ value });
        },
    },
    components: {
        GoBackButton,
    },
})
export default class DirectAppointmentSelectRoomPage extends Mixins(ValidationMixin) {
    @AppointmentStore.Mutation('setAppointmentRoom') setAppointmentRoom!: AppointmentRoomsType;

    @Prop({ type: Array, default: null }) readonly roomsData!: Array<AppointmentRoomsType>;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    isActive: string | boolean = false;
    // @ts-ignore-next-line
    [NAME]: string = '';
    NAME: string = NAME;

    serverErrors: serverErrors = {
        [NAME]: null,
    };

    get disabled(): boolean {
        return this.isRequestSending || !this.isFormValid;
    }

    updateRoomsState() {}

    resetForm(item: AppointmentRoomsType) {
        // @ts-ignore-next-line
        this.setAppointmentRoom(item);
    }

    @Emit('toBookingForm')
    toBookingForm() {}

    @Emit('backToCategoriesStep')
    backToStep() {}

    created() {}
}
</script>

<style lang='sass'>
.b-choose-room
    input.multiselect__input
        padding: 0 !important
.h-flex-2-0
    flex: 2 0
</style>
