<template>
<div class='b-input-attribute'
     :class='schema.className || null'>
    <FwFormInput
        :fieldHasError='false'
        labelType='label-medium'
        :title='currentTitle'>
        <FwInput
            :value='schema.value'
            :name='schema.name'
            :type='inputType'
            :placeholder='title'
            inputStyle='white-bigger'
            :disabled='disabled || schema.disabled'
            :maxlength='$_AttributeValidationMixin_maxlength'
            :errors='$_AttributeValidationMixin_fieldErrors'
            :error='firstError'
            @focus='emitFocusEvent'
            @blur='emitBlurEvent'
            @update:value='valueUpdate'>
        </FwInput>
    </FwFormInput>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { AttributeValidationMixin } from '@/components/forms/attributes/mixins/AttributeValidationMixin';
import { AttributeExtendedType } from '@/types/Attribute';
import ValidationMixin from '@/mixins/validation';

const INPUT_TYPE_MAP = {
    int: `number`,
    str: `text`,
};
const DEFAULT_INPUT_TYPE = INPUT_TYPE_MAP.str;

@Component
export default class InputAttribute extends Mixins(AttributeValidationMixin, ValidationMixin) {
    @Prop({ type: Object, required: true }) schema!: AttributeExtendedType
    @Prop({ type: String, required: true }) title!: string
    @Prop({ type: Boolean, default: false }) disabled!: boolean
    @Prop({ type: Boolean, default: true }) clearable!: boolean
    isValidationIsActive: boolean = false;

    get currentTitle(): string {
        // @ts-ignore
        if (this.title && this.schema.validator.input_required && this.clearable) {
            return `${this.title}*`;
        }
        return this.title;
    }

    get firstErrorWithOutTouchCheck(): string {
        return this.$_AttributeValidationMixin_fieldErrors ? this.$_AttributeValidationMixin_fieldErrors[0] : ``;
    }

    get firstError(): string {
        if (!this.isValidationIsActive) return ``;
        return this.firstErrorWithOutTouchCheck;
    }

    get currentInputIsValid() {
        return !this.firstErrorWithOutTouchCheck;
    }

    get inputType(): string {
        return DEFAULT_INPUT_TYPE;
    }

    get label(): string {
        if (!this.schema.title) {
            return '';
        }
        return this.$_AttributeValidationMixin_isRequired ? `${this.schema.title}*` : this.schema.title;
    }

    valueUpdate(value: any) {
        this.onValueChange(value);
    }

    onValueChange(value: string): void {
        this.emitChangeEvent({
            ...this.schema,
            ...{
                value,
                firstError: this.firstErrorWithOutTouchCheck,
                // @ts-ignore-next-line
                errors: this.getValidatorErrors(this.schema.validator, this.schema.value || ''),
            }});
    }

    @Emit(`isValid`)
    emitIsValidEvent(): boolean {
        return this.currentInputIsValid;
    }

    @Emit(`change`)
    emitChangeEvent(newSchema: AttributeExtendedType) {}

    @Emit(`focus`)
    emitFocusEvent() {
        if (this.$parent) {
            this.$parent.$emit(`focus`);
        }
    }

    @Emit(`blur`)
    emitBlurEvent() {
        this.isValidationIsActive = true;
        this.$_AttributeValidationMixin_validateAttribute();
        if (this.$parent) {
            this.$parent.$emit(`blur`);
        }
    }
}
</script>

<style lang='sass'>
.b-input-attribute
    flex: 1
</style>
