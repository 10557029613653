<template>
<div v-if='workingTimeValue.length !== 0'
     class='b-blue-background b-blue-background__opacity b-cancel-popup__select b-blue-background--fixed b-wh-popup'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center h-height-100p loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <FwPopup
        v-else
        class='qa-wh-popup'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        :style='{
            width: popupWidth,
            padding: isMobile() ? "30px 20px" : `50px 30px`
        }'
        :title='$t(`WORKER.TIME.SLOT.TITLE`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='checkSavePopup'>
        <template #body>
            <div v-if='workerUpdateData' class='b-form-tabs h-mt-30 h-mb-50'>
                <div
                    v-for='tab in tabs'
                    :key='tab.name'
                    class='b-form-tabs__tab h-fw-500 h-flex-1 h-text-center'
                    :class='{ "b-form-tabs__active-tab": tab.isActive }'
                    @click='selectTab(tab)'>
                    {{ $t(tab.name) }}
                    <div v-if='tab.id === `wh_update` && tab.isActive' class='b-form-tabs-info'>
                        <FwIcon
                            class='h-mh-0 h-pointer'
                            icon='alert-info'
                            size='16'
                            color='#BEC7D4'>
                        </FwIcon>
                        <span class='b-form-tabs-info__hidden h-font-12 h-opacity-0_5'>
                            {{ $t('WORKER.TIME.SCHEDULED.UPDATED.AT') }} {{ updateDate }}
                        </span>
                    </div>
                </div>
            </div>
            <ChangeWorkersWorkingHours
                :workingTimeValue='workingTimeValue'
                :servicePointsScheduleId='servicePointsScheduleId'
                :disabledForm='false'
                :savePopup='savePopup'
                :hoursPopup='hoursPopup'
                :actionUpdate='actionUpdate'
                submitText=''
                @closePopup='$emit("close")'
                @toggleSwitch='toggleSwitch'
                @showSavePopup='showSavePopup'
                @closeWorkingHoursPopup='closeWorkingHoursPopup'
                @onSubmit='sendDataToServer'>
            </ChangeWorkersWorkingHours>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import { ChangeWorkersWorkingHours } from '@/components/common/ChangeWorkersWorkingHours';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { SitesApi } from '@/api/sites/SitesApi';
import {
    WorkerScheduleTimeType,
    WorkerTimeType,
} from '@/types/Workers';
import { WORKER_SCHEDULE_DATA } from '@/helpers/workers';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { getPrettyStringDate } from '@/helpers/dates';

const WorkerPageStore = namespace('WorkerPageStore');

interface AppointmentTabType {
    name: string,
    id: string,
    isActive: boolean,
}

@Component({
    components: {
        ChangeWorkersWorkingHours,
        FinalePopup,
    },
})

export default class WorkingHoursPopup extends Vue {
    @WorkerPageStore.Action('storeWorkerScheduleData') storeWorkerScheduleData!: Function;
    @WorkerPageStore.Action('storeWorkerUpdateData') storeWorkerUpdateData!: Function;
    @WorkerPageStore.State('scheduleId') scheduleId!: string;
    @WorkerPageStore.State('workerUpdateData') workerUpdateData!: any;

    @Prop({ type: String, default: 'ValidateWorkingHours' }) readonly actionUpdate!: string;
    @Prop({ type: String, default: null }) readonly servicePointsScheduleId!: string | null;

    savePopup: boolean = false;
    showPopup: boolean = false;
    loading: boolean = true;
    hoursPopup: boolean = true;
    workingTimeValue: WorkerTimeType = WORKER_SCHEDULE_DATA;
    tabs: Array<AppointmentTabType> = [{
        id: 'wh_current',
        name: 'WORKER.TIME.CURRENT.TITLE',
        isActive: true,
    }, {
        id: 'wh_update',
        name: 'WORKER.TIME.SCHEDULED.TITLE',
        isActive: false,
    }];

    get popupWidth() {
        return this.isDesktop() ? `auto` : `100%`;
    }

    get workerStoreData(): any {
        return this.$store.state.WorkerPageStore.workerData;
    }

    get workerScheduleStoreData(): any {
        return this.$store.state.WorkerPageStore.workerScheduleData;
    }

    get updateDate() {
        if (this.workerUpdateData) {
            return getPrettyStringDate(new Date(this.workerUpdateData.dt_execute_at), this.$i18n, true);
        }

        return null;
    }

    toggleSwitch(value: any) {
        value.state = !value.state;
    }

    showSavePopup(val: boolean) {
        if (this.workerScheduleStoreData.confirmed_at) {
            this.showPopup = val;
        }
    }

    selectTab(selectedTab: AppointmentTabType) {
        this.tabs.forEach(tab => {
            if (tab.name === selectedTab.name && selectedTab.id === `wh_current`) {
                tab.isActive = true;
                this.prepareData(this.workerScheduleStoreData);
            } else if (tab.name === selectedTab.name && selectedTab.id === `wh_update`) {
                this.prepareData(this.workerUpdateData.new_data);
                tab.isActive = true;
            } else {
                tab.isActive = false;
            }
        });
    }

    checkSavePopup() {
        if (this.showPopup) {
            this.hoursPopup = false;
            this.savePopup = true;
        } else {
            this.hoursPopup = true;
            this.$emit('close');
        }
    }

    prepareData(workerTimeData: any): WorkerTimeType {
        const workerDataObj: WorkerTimeType = WORKER_SCHEDULE_DATA;
        if (workerTimeData.monday_start === null || workerTimeData.monday_start) {
            workerDataObj.monday = {
                day: 'CALENDAR.WEEK.MONDAY',
                monday: true,
                state: !!workerTimeData.monday_start,
                dayPart: {
                    day: {
                        timeTo: workerTimeData.monday_end ? workerTimeData.monday_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.monday_start ? workerTimeData.monday_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.DAY',
                        state: !!workerTimeData.monday_start,
                    },
                    lunch: {
                        timeTo: workerTimeData.monday_break_end ? workerTimeData.monday_break_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.monday_break_start ? workerTimeData.monday_break_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.LUNCH',
                        state: !!workerTimeData.monday_break_start,
                    },
                },
            };
        }
        if (workerTimeData.tuesday_start === null || workerTimeData.tuesday_start) {
            workerDataObj.tuesday = {
                day: 'CALENDAR.WEEK.TUESDAY',
                tuesday: true,
                state: !!workerTimeData.tuesday_start,
                dayPart: {
                    day: {
                        timeTo: workerTimeData.tuesday_end ? workerTimeData.tuesday_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.tuesday_start ? workerTimeData.tuesday_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.DAY',
                        state: !!workerTimeData.tuesday_start,
                    },
                    lunch: {
                        timeTo: workerTimeData.tuesday_break_end ? workerTimeData.tuesday_break_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.tuesday_break_start ? workerTimeData.tuesday_break_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.LUNCH',
                        state: !!workerTimeData.tuesday_break_start,
                    },
                },
            };
        }
        if (workerTimeData.wednesday_start === null || workerTimeData.wednesday_start) {
            workerDataObj.wednesday = {
                day: 'CALENDAR.WEEK.WEDNESDAY',
                wednesday: true,
                state: !!workerTimeData.wednesday_start,
                dayPart: {
                    day: {
                        timeTo: workerTimeData.wednesday_end ? workerTimeData.wednesday_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.wednesday_start ? workerTimeData.wednesday_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.DAY',
                        state: !!workerTimeData.wednesday_start,
                    },
                    lunch: {
                        timeTo: workerTimeData.wednesday_break_end ? workerTimeData.wednesday_break_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.wednesday_break_start ? workerTimeData.wednesday_break_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.LUNCH',
                        state: !!workerTimeData.wednesday_break_start,
                    },
                },
            };
        }
        if (workerTimeData.thursday_start === null || workerTimeData.thursday_start) {
            workerDataObj.thursday = {
                day: 'CALENDAR.WEEK.THURSDAY',
                thursday: true,
                state: !!workerTimeData.thursday_start,
                dayPart: {
                    day: {
                        timeTo: workerTimeData.thursday_end ? workerTimeData.thursday_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.thursday_start ? workerTimeData.thursday_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.DAY',
                        state: !!workerTimeData.thursday_start,
                    },
                    lunch: {
                        timeTo: workerTimeData.thursday_break_end ? workerTimeData.thursday_break_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.thursday_break_start ? workerTimeData.thursday_break_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.LUNCH',
                        state: !!workerTimeData.thursday_break_start,
                    },
                },
            };
        }
        if (workerTimeData.friday_start === null || workerTimeData.friday_start) {
            workerDataObj.friday = {
                day: 'CALENDAR.WEEK.FRIDAY',
                friday: true,
                state: !!workerTimeData.friday_start,
                dayPart: {
                    day: {
                        timeTo: workerTimeData.friday_end ? workerTimeData.friday_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.friday_start ? workerTimeData.friday_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.DAY',
                        state: !!workerTimeData.friday_start,
                    },
                    lunch: {
                        timeTo: workerTimeData.friday_break_end ? workerTimeData.friday_break_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.friday_break_start ? workerTimeData.friday_break_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.LUNCH',
                        state: !!workerTimeData.friday_break_start,
                    },
                },
            };
        }
        if (workerTimeData.saturday_start === null || workerTimeData.saturday_start) {
            workerDataObj.saturday = {
                day: 'CALENDAR.WEEK.SATURDAY',
                saturday: true,
                state: !!workerTimeData.saturday_start,
                dayPart: {
                    day: {
                        timeTo: workerTimeData.saturday_end ? workerTimeData.saturday_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.saturday_start ? workerTimeData.saturday_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.DAY',
                        state: !!workerTimeData.saturday_start,
                    },
                    lunch: {
                        timeTo: workerTimeData.saturday_break_end ? workerTimeData.saturday_break_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.saturday_break_start ? workerTimeData.saturday_break_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.LUNCH',
                        state: !!workerTimeData.saturday_break_start,
                    },
                },
            };
        }
        if (workerTimeData.sunday_start === null || workerTimeData.sunday_start) {
            workerDataObj.sunday = {
                day: 'CALENDAR.WEEK.SUNDAY',
                sunday: true,
                state: !!workerTimeData.sunday_start,
                dayPart: {
                    day: {
                        timeTo: workerTimeData.sunday_end ? workerTimeData.sunday_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.sunday_start ? workerTimeData.sunday_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.DAY',
                        state: !!workerTimeData.sunday_start,
                    },
                    lunch: {
                        timeTo: workerTimeData.sunday_break_end ? workerTimeData.sunday_break_end.substring(0, 5) : null,
                        timeFrom: workerTimeData.sunday_break_start ? workerTimeData.sunday_break_start.substring(0, 5) : null,
                        label: 'WORKER.TIME.SLOT.LUNCH',
                        state: !!workerTimeData.sunday_break_start,
                    },
                },
            };
        }
        return workerDataObj;
    }

    closeWorkingHoursPopup() {
        this.hoursPopup = false;
        this.savePopup = false;
        this.$emit('close');
    }

    async sendDataToServer(data: WorkerScheduleTimeType) {
        try {
            await WorkersWebApi.editTechnicianSchedulesData(data, this.scheduleId);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
            this.closeWorkingHoursPopup();
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async getWorkerSchedules() {
        if (this.servicePointsScheduleId) {
            try {
                const data = await SitesApi.getServicePointSchedulesData(this.servicePointsScheduleId);
                this.storeWorkerScheduleData(data.working_schedule);
                this.storeWorkerUpdateData(data.working_schedule.update_request);
                this.workingTimeValue = this.prepareData(data.working_schedule);
                this.loading = false;
            } finally {
                this.loading = false;
            }
        } else if (this.scheduleId) {
            try {
                const response = await WorkersWebApi.getTechnicianSchedulesData({ id: this.scheduleId });
                this.storeWorkerScheduleData(response.data.working_schedule);
                this.storeWorkerUpdateData(response.data.working_schedule.update_request);
                this.workingTimeValue = this.prepareData(response.data.working_schedule);
                this.loading = false;
            } finally {
                this.loading = false;
            }
        } else {
            this.storeWorkerScheduleData(WORKER_SCHEDULE_DATA);
            this.workingTimeValue = this.prepareData(WORKER_SCHEDULE_DATA);
            this.loading = false;
        }
    }
    created() {
        this.getWorkerSchedules();
    }
}
</script>
<style lang='sass'>
.b-wh-popup
    .mx-calendar-content
        .mx-panel-year
            .cell.actived
                margin: 1px 5%!important

        .mx-panel-month
            .cell.actived
                margin: 1px 1% !important
    .b-form-tabs
        display: flex
        justify-content: center
        align-items: center
        color: #b5c0ce

        &__active-tab
            position: relative
            display: flex
            justify-content: center
            align-items: center
            flex-flow: column nowrap
            color: $dark-blue

            &:after
                position: absolute
                content: ''
                top: 30px
                height: 2px
                width: 40px
                background: $dark-blue
        &__tab
            cursor: pointer
            max-width: 200px

        &-info
            position: absolute
            right: 0

            &__hidden
                border-radius: 5px
                min-width: 120px
                position: absolute
                opacity: 0
                transition: opacity 0.2s ease-in-out
                background: #fff
                box-shadow: $base-box-shadow
                left: 15px
                bottom: 25px
                padding: 10px

        &-info:hover .b-form-tabs-info__hidden
            opacity: 1

    .multiselect__content-wrapper
        top: 30px
        border-radius: 15px !important
        bottom: auto !important
        max-height: 300px !important

    .multiselect--active
        .multiselect__tags::after
            content: ''
            background: #fff
            height: 18px
            width: 100%
            bottom: -12px
            position: absolute
            left: 0
</style>

