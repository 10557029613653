<template>
<div class='h-flex-center b-gray-background b-black-background--fixed b-add-manager-popup'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <FwPopup
        v-else
        class='h-pos-rel qa-add-manager-popup'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        :title='title'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <AddManagerNewForm
                iconsWrapperClass='h-flex-dir-column h-flex'
                :disabledForm='disabledForm'
                :manager='manager'
                :roles='roles'
                submitText='BUTTON.SAVE'
                :isRequestSending='isRequestSending'
                :errorFromServer='errorFromServer'
                @remove='confirmPopup = true'
                @onSubmit='sendDataToServer'>
            </AddManagerNewForm>
        </template>
    </FwPopup>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`MANAGERS.DELETE.FORM.CONFIRM.TITLE`)'
        :subMessage='$t(`MANAGERS.DELETE.FORM.CONFIRM.SUB`)'
        confirmQaClass='qa-qa-manager-card__confirm-delete'
        :isRequestSending='isRequestSending'
        @deleteItem='removeManager'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { State, namespace } from 'vuex-class';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { AddManagerNewForm } from '@/components/forms/add/AddManagerNewForm';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { SettingsManagerFormDataType, SettingsRoleSimpleType } from '@/types/Settings';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { UserManagerProfileRole, User } from '@/types/User';
import PermissionsMixin from '@/mixins/permissions';
import i18n from '@/locale';

const SettingsStore = namespace('SettingsStore');

@Component({
    components: {
        AddManagerNewForm,
        DeletePopup,
    },
})
export default class AddManagerPopup extends Mixins(PermissionsMixin) {
    @State(state => state.SettingsStore.roles) roles!: Array<UserManagerProfileRole> | null;
    @SettingsStore.Mutation('setRoles') setRoles!: (roles: Array<SettingsRoleSimpleType>) => void;

    @Prop({ type: Object, required: true }) readonly manager!: User;

    isRequestSending: boolean = false;
    loading: boolean = false;
    confirmPopup: boolean = false;
    errorFromServer: string | null = null;
    headerText: string = ``;

    get disabledForm(): boolean {
        return this.isEdit ? !this.canEdit : !this.canCreate;
    }

    get isEdit(): boolean {
        return !!this.manager;
    }

    get title(): string | null {
        if (!this.isEdit) {
            return i18n.tc('MANAGERS.ADD.FORM.TITLE');
        }
        return this.canEdit ? i18n.tc('MANAGERS.EDIT.FORM.TITLE') : null;
    }

    async fetchRoles() {
        this.loading = true;
        try {
            const roles = await SettingsWebApi.getSettingsRoleNames();
            this.setRoles(roles);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async removeManager() {
        try {
            await SettingsWebApi.deleteManager(this.manager.id);
            this.headerText = i18n.t('POPUP.MANAGER.SUCCESS.DELETED');
            this.confirmPopup = false;
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.$emit('close', this.manager.id);
            this.$emit('getUsers');
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async sendDataToServer(managerFormData: SettingsManagerFormDataType): Promise<void> {
        this.isRequestSending = true;

        if (!this.isEdit) {
            try {
                await SettingsWebApi.createManager(managerFormData);
                this.headerText = i18n.t('POPUP.MANAGER.SUCCESS');
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
                this.$emit('close');
                this.$emit('getUsers');
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        } else {
            try {
                const manager = await SettingsWebApi.editManager(managerFormData, this.manager.id);
                this.headerText = i18n.t('POPUP.MANAGER.SUCCESS.UPDATED');
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
                this.$emit('close', manager);
                this.$emit('getUsers');
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        }
    }

    async created() {
        if (!this.roles) {
            await this.fetchRoles();
        }
    }
}
</script>

<style lang='sass'>
.b-black-background
    background-color: #000

.b-add-manager-popup
    .fw-popup
        position: relative
</style>
