<template>
<div class='b-appointment-form'>
    <h2 class='b-appointment-left-bar__title'>
        {{ appointmentData.title }}
    </h2>
    <EventForm
        :eventTime='eventTime'
        :dates='dates'
        :isRequestSending='isRequestSending'
        :defaultFormValues='appointmentData'
        :companyData='companyData'
        iconsWrapperClass='h-flex-dir-column h-flex'
        @onSubmit='sendDataToServer'>
    </EventForm>
    <FinalePopup
        v-if='finalePopupValue'
        :subMessage='$t(`APPOINTMENT.SUCCESS.POPUP.TXT`)'
        @close='closePopup'>
    </FinalePopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { dateAsEventTime } from '@/helpers/dates';
import DateMixin from '@/mixins/dateMixin';
import { EventForm } from '@/components/forms/event/EventForm';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { CompanyData } from '@/types/Companies';
import { AppointmentDataType } from '@/types/Appointment';
import { FinalePopup } from '@/components/popups/FinalePopup';

@Component({
    components: {
        EventForm,
        FinalePopup,
    },
})
export default class PlanAppointmentForm extends Mixins(DateMixin) {
    appointmentData: null | AppointmentDataType = null;
    companyData: null | CompanyData = null;
    isRequestSending: boolean = false;
    finalePopupValue: boolean = false;
    get appointmentStoreData(): any {
        return this.$store.state.AppointmentStore.appointmentData;
    }
    get companyDate(): any {
        return this.$store.state.CalendarPageStore.company;
    }
    get eventDate(): any {
        return this.$store.state.CalendarPageStore.date;
    }
    get eventTime(): string {
        return dateAsEventTime(this.eventDate.date, this.user);
    }
    get dates(): Array<string> {
        return [
            this.getCurrentDateAsMinutesAMPM(new Date(this.eventDate.data.dt_start)),
            this.getCurrentDateAsMinutesAMPM(new Date(this.eventDate.data.dt_end)),
        ];
    }
    closePopup() {
        this.finalePopupValue = false;
        this.$router.push({ path: '/plan/intervention-code' });
    }
    sendDataToServer(): void {
        this.isRequestSending = true;
        if (this.companyData && this.appointmentData) {
            AppointmentWebApi.bookAppointment(
                {
                    dt_start: this.eventDate.data.dt_start,
                    dt_end: this.eventDate.data.dt_end,
                    company_id: this.companyData.id,
                    technician_id: this.eventDate.data.technician_id,
                },
                this.appointmentData.id
            ).then(response => {
                if (response.status === 200) {
                    this.isRequestSending = false;
                    this.finalePopupValue = true;
                    console.log('ok');
                }
            }).catch(({ response }) => {
                this.isRequestSending = false;
                this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
            });
        }
    }
    created() {
        this.companyData = this.companyDate;
        this.appointmentData = this.appointmentStoreData;
    }
}
</script>

<style lang='sass'>
.b-appointment-left-bar
    .mx-datepicker-popup
        z-index: 5!important

    .mx-datepicker--hide
        height: 250px!important

    .b-appointment-form-back_button
        top: 30px
        right: 30px
</style>
