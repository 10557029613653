<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        class='b-geo-zone-reassignments-popup'
        :class='{ "b-geo-zone-reassignments-popup--empty": !isLoading && reassignments.length }'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='1020px'
        :title='$tc("ADVISOR.GEO_ZONE_REASSIGNMENT.PLURAL", 1)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        :padding='isMobile() ? "30px 20px" : "50px"'
        :disableClosingOnClickOutside='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @click.native.stop
        @close='$emit("close")'>
        <template #body>
            <div class='b-geo-zone-reassignments-popup__inner'>
                <div class='b-geo-zone-reassignments-popup__tabs'>
                    <div
                        class='b-geo-zone-reassignments-popup__tabs__item'
                        :class='{ "active": fromActive }'
                        @click='updateTab'>
                        {{ $t('ADVISOR.GEO_ZONE_REASSIGNED_ZONE') }}
                    </div>
                    <div
                        class='b-geo-zone-reassignments-popup__tabs__item'
                        :class='{ "active": !fromActive }'
                        @click='updateTab'>
                        {{ $t('ADVISOR.GEO_ZONE_RECEIVED_ZONE') }}
                    </div>
                </div>

                <div v-if='showGeoZoneReassignmentsAdd'
                     class='b-geo-zone-reassignments-popup__buttons'>
                    <div class='h-width-300'>
                        <div v-if='currentOptions.length >= 1'
                             class='fw-select-base h-flex-1'>
                            <FwSelect
                                v-model='advisor'
                                :propsPlaceholder='$t(`ADVISOR.GEO_ZONE_SELECT_ADVISOR`)'
                                type='white'
                                :searchable='false'
                                label='title'
                                :multiple='false'
                                :clearable='true'
                                :withCircles='true'
                                :canNotDelete='false'
                                :options='currentOptions'
                                @input='updateValue'>
                            </FwSelect>
                        </div>
                    </div>

                    <FwButton
                        v-if='fromActive'
                        borderRadiusType='small-border'
                        size='little'
                        @click.native='addGeoZoneHandler'>
                        {{ $t('BUTTON.ADD') }}
                    </FwButton>
                </div>

                <FwSpinLoader
                    v-if='isLoading'
                    :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                    class='h-flex-center loader'
                    :isActive='isLoading'
                    className='h-p-20'>
                </FwSpinLoader>
                <div class='b-geo-zone-reassignments-popup__table__wrapper'>
                    <FwHorizontalScroll
                        v-if='!isLoading && reassignments.length'
                        :isPageLoaded='true'
                        slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force h-mt-30'
                        iconClass='h-opacity-1'
                        newClass='fw-arrow-big-circle-fixed'
                        :arrowBgWidth='null'
                        colorIcon='#fff'
                        btnLocation='between'
                        hideBtn>
                        <template #content>
                            <CalendarTable
                                :titles='titles'
                                :data='reassignments'
                                :bigIndexes='[]'
                                class='b-calendar__table__wrapper'>
                                <div v-for='(item, i) in reassignments'
                                     :key='i'
                                     class='b-calendar__table'>
                                    <div class='b-calendar__table__outer'>
                                        <div v-for='(key, j) in mapping'
                                             :key='j'
                                             class='b-calendar__table__item'>
                                            <template v-if='item[key] && item[key].length'>
                                                <span class='h-overflow-text'>
                                                    {{ reassignmentsText(item, key) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                    <div v-if='showGeoZoneReassignmentsDelete && fromActive'
                                         class='b-calendar__table__buttons'>
                                        <div class='b-calendar__table__item__delete h-ml-10 h-pointer h-width-60'
                                             @click='showDeleteGeoZone(item)'>
                                            <FwIcon
                                                class='h-flex-center h-pointer'
                                                icon='bucket-edit'
                                                size='18'
                                                color='rgba(33,63,107,.3)'>
                                            </FwIcon>
                                        </div>
                                    </div>
                                </div>
                            </CalendarTable>
                        </template>
                    </FwHorizontalScroll>
                    <div v-else-if='!isLoading'
                         class='b-geo-zone-reassignments-popup__empty-text'>
                        {{ $t('ADVISOR.GEO_ZONE.NO_REASSIGNMENT') }}
                    </div>
                    <FwPagination
                        v-if='totalPage > perPage'
                        :totalPage='totalPage'
                        :currentPage='currentPage'
                        :perPage='perPage'
                        :value='perPage'
                        @getData='getData'>
                    </FwPagination>

                    <div v-if='!isLoading && totalPage >= 1 && fromActive'
                         class='b-geo-zone-reassignments-popup__delete-all'>
                        <div class='b-geo-zone-reassignments-popup__delete-all__inner'
                             @click='deleteAllReassignments'>
                            <FwIcon
                                icon='bucket-edit'
                                size='14'
                                color='#FF004D'>
                            </FwIcon>
                            <div class='h-ml-10'>
                                {{ $t('ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_ALL') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </FwPopup>
    <DeletePopup
        v-if='isDeletePopupShow'
        :headerText='$tc(`ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_TITLE`, geoZoneItem ? 1 : 2)'
        :subMessage='$tc(`ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_TEXT`, geoZoneItem ? 1 : 2)'
        :isRequestSending='isDeleteLoading'
        @deleteItem='deleteGeoZone'
        @close='closeDeletePopup'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { AdvisorsWebApi } from '@/api/workers/AdvisorsApi';
import { ReassignmentUserType, SiteReassignmentsType } from '@/types/Consult';
import { CalendarTable } from '@/components/common/CalendarTable';
import { BaseMetaType } from '@/types/Response';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { capitalizeAll } from '@/helpers/string';
import { formatDateDayMonthYearByLocale, isValidDate } from '@/helpers/dates';

@Component({
    components: {
        CalendarTable,
        DeletePopup,
    },
})
export default class GeoZoneReassignmentsPopup extends Vue {
    @Prop({ type: Number, required: true }) readonly updateListKey!: number;

    isUsersLoading: boolean = false;
    isLoading: boolean = false;
    fromActive: boolean = true;
    isDeleteLoading: boolean = false;
    reassignments: Array<SiteReassignmentsType> = [];
    currentPage: number = 1;
    totalPage: number = 0;
    perPage: number = 5;
    meta: BaseMetaType | null = null;
    geoZoneItem: SiteReassignmentsType | null = null;
    isDeletePopupShow: boolean = false;
    advisor: null | ReassignmentUserType = null;
    fromAdvisors: Array<ReassignmentUserType> = [];
    toAdvisors: Array<ReassignmentUserType> = [];

    get titles(): Array<string> {
        return this.fromActive ? [
            'ADVISOR.GEO_ZONE.START_DATE',
            'ADVISOR.GEO_ZONE.END_DATE',
            'ADVISOR.GEO_ZONE_DISTRICT',
            'WORKER.TIME.SLOT.TO',
        ] : [
            'ADVISOR.GEO_ZONE.START_DATE',
            'ADVISOR.GEO_ZONE.END_DATE',
            'ADVISOR.GEO_ZONE_DISTRICT',
            'WORKER.TIME.SLOT.FROM',
        ];
    }

    get mapping(): Array<string> {
        return this.fromActive ? [
            `due_from`,
            `due_to`,
            `title`,
            `user_full_name`,
        ] : [
            `due_from`,
            `due_to`,
            `title`,
            `user_from_full_name`,
        ];
    }

    get showGeoZoneReassignmentsDelete(): boolean {
        return this.currentUserRole && this.currentUserRole.advisor_rights && this.currentUserRole.advisor_rights.update;
    }

    get showGeoZoneReassignmentsAdd(): boolean {
        return this.currentUserRole && this.currentUserRole.advisor_rights && this.currentUserRole.advisor_rights.create;
    }

    get currentOptions(): Array<ReassignmentUserType> {
        return !this.fromActive ? this.fromAdvisors : this.toAdvisors;
    }

    reassignmentsText(item: SiteReassignmentsType, key: string): any {
        // @ts-ignore
        const data = (item[key] as string);
        const validDate = [`due_from`, `due_to`].includes(key) && isValidDate(new Date(data));
        if (validDate) {
            return formatDateDayMonthYearByLocale(new Date(data), this.$i18n.locale);
        }
        // @ts-ignore
        return [`user_full_name`, `user_from_full_name`].includes(key) ? capitalizeAll(data) : data;
    }

    closeDeletePopup(): void {
        this.geoZoneItem = null;
        this.isDeletePopupShow = false;
    }

    showDeleteGeoZone(item: SiteReassignmentsType): void {
        this.isDeletePopupShow = true;
        this.geoZoneItem = item;
    }

    getData(currentPage: number, perPage: number = this.perPage): void {
        this.currentPage = currentPage;
        this.perPage = perPage;
        this.getSiteReassignments();
    }

    updateMeta(meta: BaseMetaType): void {
        this.meta = meta;
        this.totalPage = meta.total_count;
        this.currentPage = meta.current_page;
    }

    async deleteGeoZone(): Promise<void> {
        this.isDeleteLoading = true;
        try {
            if (this.geoZoneItem) {
                await AdvisorsWebApi.removeSiteReassignments({
                    user_id: this.$route.params.id,
                    site_ids: [this.geoZoneItem.site_id],
                    due_from: this.geoZoneItem.due_from,
                });
            } else if (this.advisor) {
                await AdvisorsWebApi.removeSiteReassignments({
                    user_id: (this.$route.params.id as string),
                    ...(this.fromActive && { user_to_id: this.advisor.id }),
                });
                this.advisor = null;
            } else {
                await AdvisorsWebApi.removeSiteReassignments({
                    user_id: this.$route.params.id,
                    site_ids: [],
                    all: true,
                });
            }
            this.currentPage = 1;
            this.getSiteReassignments();
            this.geoZoneItem = null;
            this.isDeletePopupShow = false;
            this.getReassignedAdvisors();
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isDeleteLoading = false;
        }
    }

    cleanPageMeta(): void {
        this.currentPage = 1;
        this.totalPage = 0;
        this.perPage = 5;
    }

    updateTab(): void {
        this.fromActive = !this.fromActive;
        this.advisor = null;
        this.cleanPageMeta();
        this.getSiteReassignments();
    }

    async getReassignedAdvisors() {
        this.isUsersLoading = true;
        try {
            const data = await AdvisorsWebApi.fetchReassignmentsUsers(this.$route.params.id);
            this.fromAdvisors = data.from_advisor.map(item => ({ ...item, ...{ title: capitalizeAll(`${item.first_name || ''} ${item.last_name || ''}`) }}));
            this.toAdvisors = data.to_advisor.map(item => ({ ...item, ...{ title: capitalizeAll(`${item.first_name || ''} ${item.last_name || ''}`) }}));
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isUsersLoading = false;
        }
    }

    async getSiteReassignments(): Promise<void> {
        this.isLoading = true;
        try {
            const data = await AdvisorsWebApi.getSiteReassignments({
                user_from_id: this.$route.params.id,
                current_page: this.currentPage,
                per_page: this.perPage,
                from: this.fromActive,
                advisor: this.advisor,
            });
            this.reassignments = data.site_reassignments.map(item => ({ ...item, ...{ title: `${item.post_code} - ${item.name}` }}));
            this.updateMeta(data.meta);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoading = false;
        }
    }

    deleteAllReassignments(): void {
        this.isDeletePopupShow = true;
    }

    @Emit('addGeoZone')
    addGeoZoneHandler(): void {}

    @Watch('updateListKey')
    updateListKeyHandler() {
        this.getSiteReassignments();
        this.getReassignedAdvisors();
    }

    updateValue() {
        this.getSiteReassignments();
    }

    created() {
        this.getSiteReassignments();
        this.getReassignedAdvisors();
    }
}
</script>

<style lang="sass">
.b-geo-zone-reassignments-popup
    width: 1020px
    justify-content: flex-start !important
    min-height: 720px

    .b-calendar__table__inner
        max-width: 100%

    &--empty
        min-height: none

    &__inner
        display: block

    &__buttons
        display: flex
        justify-content: space-between

        .fw-button
            height: 50px !important

    .fw-arrow-big-circle-fixed.fw-arrow-big-circle-fixed--right
        display: none !important

    .fw-pagination
        background: transparent !important
        bottom: 46px

    &__empty-text
        font-size: 16px
        text-align: center
        font-weight: 500
        margin-top: 20px

    &__delete-all
        font-size: 14px
        font-weight: 500
        color: $cancel-red
        display: flex
        align-items: center
        justify-content: center
        width: 100%
        position: absolute
        bottom: 0
        height: 60px
        width: calc(100% - 100px)

        &__inner
            display: flex
            align-items: center
            cursor: pointer
            transition: opacity 0.2s

            &:hover
                opacity: 0.8

    .fw-input::placeholder
        width: 100%

    &__tabs
        display: flex
        font-size: 14px
        color: #213F6B
        margin-left: 40px
        align-items: center
        justify-content: center
        width: 100%
        margin: 10px 0 20px

        &__item
            position: relative
            font-weight: 500
            opacity: 0.5

            &.active
                opacity: 1
                &:after
                    width: 80%
                    position: absolute
                    left: 0
                    content: ''
                    right: 0
                    bottom: -7px
                    border-top: 2px solid #213F6B
                    height: 0
                    margin: auto

            &:not(.active)
                cursor: pointer

            &:not(:last-of-type)
                margin-right: 60px

    .multiselect__placeholder
        color: #909FB5 !important
</style>
