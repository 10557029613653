export default {
    'ONBOARDING.TITLE': 'Welcome to Calizy',
    'ONBOARDING.ASIDE': 'We\'re gonna set up your Calizy tool together',
    'ONBOARDING.OPTION.1': 'Appointment types',
    'ONBOARDING.OPTION.2': 'Your teams workers',
    'ONBOARDING.OPTION.ORGANISATION': 'Organisation',
    'ONBOARDING.OPTION.BRANCH': 'Branch',
    'ONBOARDING.OPTION.BRANCHES': 'Branches',
    'ONBOARDING.BUTTON': 'Let\'s go',

    'ONBOARDING.CHOOSE.TITLE': 'Appointments will be booked with people from:',
    'ONBOARDING.CHOOSE.MY.COMPANY.TEXT': 'My company',
    'ONBOARDING.CHOOSE.PARTNER.COMPANY.TEXT': 'Partner companies',

    'ONBOARDING.CHOOSE.BRANCH.TITLE': 'Add a branch',

    'ONBOARDING.CHOOSE.CATEGORY.TITLE': 'Add an appointment type',
    'ONBOARDING.CHOOSE.CATEGORY.MANUAL.TITLE': 'Manually',
    'ONBOARDING.CHOOSE.CATEGORY.MANUAL.DESCRIPTION': 'Fill in the form yourself',
    'ONBOARDING.CHOOSE.CATEGORY.FILE.TITLE': 'Import a file',
    'ONBOARDING.CHOOSE.CATEGORY.FILE.DESCRIPTION': 'Supported formats : CSV, XLS or XLSX',

    'ONBOARDING.PARTNERS.CHOOSE.CATEGORY.TITLE': 'Add a partner company',

    'ONBOARDING.CHOOSE.CATEGORIES.HEADER': 'Appointment types',
    'ONBOARDING.CHOOSE.TYPES.HEADER': 'Appointment types',
    'ONBOARDING.PARTNERS.CHOOSE.CATEGORIES.HEADER': 'Appointment types',
    'ONBOARDING.CHOOSE.CATEGORIES.SUBTEXT': 'You can either edit appointment types information or go to the next step',
    'ONBOARDING.CHOOSE.BUSINESS.PARTNERS.SUBTEXT': 'You can either edit business partner companies or go to the next step',
    'ONBOARDING.CHOOSE.BRANCH.CATEGORIES.SUBTEXT': 'You can either edit branches information or go to the next step',

    'ONBOARDING.WORKING.HOURS.CHOOSE.HOW': 'Please choose how you want to confirm the working hours',
    'ONBOARDING.WORKING.HOURS.WORKERS.CHOOSE.IDENTICAL': 'All my technicians have identical working hours',
    'ONBOARDING.WORKING.HOURS.ADVISORS.CHOOSE.IDENTICAL': 'All my advisors have identical working hours',
    'ONBOARDING.WORKING.HOURS.WORKERS.CHOOSE.SPECIFIC': 'Each technician has specific working hours',
    'ONBOARDING.WORKING.HOURS.ADVISORS.CHOOSE.SPECIFIC': 'Each advisor has specific working hours',
    'ONBOARDING.WORKING.HOURS.WORKERS.PAGE.SUBTITLE': 'What are your technicians working hours?',
    'ONBOARDING.WORKING.HOURS.ADVISORS.PAGE.SUBTITLE': 'What are your advisors working hours?',
    'ONBOARDING.WORKING.HOURS.WORKERS.CONFIRM': 'Confirm for all technicians',
    'ONBOARDING.WORKING.HOURS.ADVISORS.CONFIRM': 'Confirm for all advisors',
    'ONBOARDING.WORKING.HOURS.WORKERS.LIST.SUBTITLE': 'Click on a technician to set up his working hours',

    'ONBOARDING.CALENDAR.SYNC.WORKERS.TITLE': 'Technicians - Calendar synchronisation',
    'ONBOARDING.CALENDAR.SYNC.ADVISORS.TITLE': 'Advisors - Calendar synchronisation',
    'ONBOARDING.CALENDAR.SYNC.WORKERS.SUBTITLE': 'Please invite your technicians to synchronize their calendars',
    'ONBOARDING.CALENDAR.SYNC.ADVISORS.SUBTITLE': 'Please invite your advisors to synchronize their calendars',
    'ONBOARDING.CALENDAR.SYNC.WORKERS.BUTTON.INVITE': 'Invite all my technicians',
    'ONBOARDING.CALENDAR.SYNC.ADVISORS.BUTTON.INVITE': 'Invite all my advisors',
    'ONBOARDING.CALENDAR.SYNC.BUTTON.WORKERS.CONFIRM': 'Choose technicians',
    'ONBOARDING.CALENDAR.SYNC.BUTTON.ADVISORS.CONFIRM': 'Choose advisors',
    'ONBOARDING.CALENDAR.SYNC.SELECT.WORKERS': 'Select technicians you want to invite to synchronise their calendar',
    'ONBOARDING.CALENDAR.SYNC.SELECT.ADVISORS': 'Select advisors you want to invite to synchronise their calendar',

    'ONBOARDING.FINALE.PAGE.TITLE': 'You\'re all set',
    'ONBOARDING.FINALE.PAGE.TEXT': 'You are now ready to use Calizy and plan your first appointment',
    'ONBOARDING.FINALE.PAGE.BUTTON': 'Plan an appointment',

    'ONBOARDING.INVITATION.PREVIEW.WELCOME': 'Welcome to Calizy Pro!',
    'ONBOARDING.INVITATION.PREVIEW.CUSTOMER.NAME': 'You have been invited by [Calizy_customer_name] to join Calizy Pro to ' +
        'improve appointment planning.',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE': 'Calizy pro will optimise your appointment organisation by synchronising with your ' +
        'professional calendar. To access to your account, it\'s easy :',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE1': 'Download the app',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE1.1': 'on the Apple for Iphone or Google Store for Android',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2': 'Confirm your account',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2.1': 'with your email and',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2.2': 'this code [XXXXX]',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE3': 'Choose a',
    'ONBOARDING.INVITATION.PREVIEW.OPTIMIZE3.1': 'password',
    'ONBOARDING.INVITATION.PREVIEW.CONGRATS1': 'Congrats ! You can now',
    'ONBOARDING.INVITATION.PREVIEW.CONGRATS1.1': 'synchronise your professional calendar',
    'ONBOARDING.INVITATION.PREVIEW.CONGRATS1.2': 'with Calizy Pro and',
    'ONBOARDING.INVITATION.PREVIEW.CONGRATS1.3': 'check out all your events in the app!',
    'ONBOARDING.INVITATION.PREVIEW.QUESTIONS1': 'Questions? Send us an email, our email address is',
    'ONBOARDING.INVITATION.PREVIEW.QUESTIONS1.1': 'and would be glad to help ! See you soon !',
    'ONBOARDING.INVITATION.PREVIEW.FOOTER': 'You don\'t want to receive our mail? Click here to unsubscribe',

    'ONBOARDING.INVITATION.WORKERS.MESSAGE1': 'This invitation will be sent to',
    'ONBOARDING.INVITATION.WORKERS.MESSAGE1.1': 'your technicians.',

    'ONBOARDING.INVITATION.FINALE.SET.TITLE': 'You\'re all set',
    'ONBOARDING.INVITATION.FINALE.SET.TEXT': 'You are now ready to use Calizy and plan your first appointment',
    'ONBOARDING.INVITATION.FINALE.SET.BUTTON': 'Plan an appointment',

    'ONBOARDING.POPUP.PROGRESS.TITLE': 'Sending in progress',
    'ONBOARDING.POPUP.PROGRESS.TEXT': 'Please wait a few minutes',

    'ONBOARDING.NO.ITEMS.PARTNERS': 'You need to add a business partner',
    'ONBOARDING.NO.ITEMS.TECH': 'You need to add a technician',
    'ONBOARDING.NO.ITEMS.ADVISOR': 'You need to add an advisor',
    'ONBOARDING.NO.ITEMS.BRANCH': 'You need to add a branch',
    'ONBOARDING.NO.ITEMS.HUB': 'You need to add a hub',
    'ONBOARDING.NO.ITEMS.APPT': 'You need to add appointments',
};
