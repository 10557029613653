import { clone, toLower, includes, compose, filter, curry, propOr, trim } from 'ramda';

const omit =
    <T, K extends keyof T>(property: K, obj: T): Omit<T, K> => (Object.keys(obj) as Array<keyof T>).reduce(
        (acc, key) => {
            if (key !== property) {
                acc[key] = obj[key];
            }

            return acc;
        }, {} as T,
    );

const existsIn = <T>(arr: Array<T>) => (item: T): boolean => arr.includes(item);

const pluck =
    <T, K extends keyof T>(property: K, arr: Array<T>): Array<T[K]> => arr.map(
        item => item[property],
    );

const isEmptyArray =
    <T>(arr: Array<T>): boolean => Array.isArray(arr) && arr.length <= 0;

const insertAll =
    <T, U>(idx: number, elts: Array<T>, list: Array<U>): Array<T | U> => ([
        ...list.slice(0, idx),
        ...elts,
        ...list.slice(idx),
    ]);

const insert =
<T, U>(idx: number, elm: T, list: Array<U>): Array<T | U> => list.map(
        (item, index) => index === idx ? elm : item,
    );

const omitByIndex =
    <T>(index: number, arr: Array<T>): Array<T> => ([
        ...arr.slice(0, index),
        ...arr.slice(index + 1, arr.length),
    ]);

const setByIndex =
    <T, U>(idx: number, elt: T, arr: Array<U>): Array<T | U> => arr.map(
        (item, index) => index === idx ? elt : item,
    );

const deepClone =
    <T>(obj: T): T => clone(obj);

const takeLast =
    <T>(count: number, arr: Array<T>): Array<T> => arr.slice(
        Math.max(0, count >= 0 ? arr.length - count : 0),
        Infinity,
    );

function isEmptyValue(value: any): boolean {
    const isEmptyObject = (v: any) =>
        Object.prototype.toString.call(v) === `[object Object]` &&
        v !== null &&
        Object.keys(v).length === 0;

    if (isEmptyArray(value) || isEmptyObject(value)) {
        return true;
    }

    if (value === 0 || value === false) {
        return false;
    }

    return !value;
}

const searchByProp = curry(
    <T extends Object>(property: string, searchText: string, arr: Array<T>): Array<T> => filter(
        compose(
            includes(toLower(trim(searchText))),
            trim,
            toLower,
            (prop: any) => prop.toString(),
            propOr(``, property),
        ),
        arr,
    ),
);

const processTimestamp = (timestamp: string | number | null, offset: number = 0): number => +new Date((Number(timestamp || 0) * 1000) + offset);

export {
    omit,
    existsIn,
    pluck,
    isEmptyArray,
    insertAll,
    insert,
    omitByIndex,
    deepClone,
    setByIndex,
    takeLast,
    isEmptyValue,
    searchByProp,
    processTimestamp,
};
