<template>
<div class='b-page-container b-page-container--big b-consult-workers'>
    <GoBackButton
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <div class='h-flex-dir-column h-mh-10'>
        <div class='b-common-title-wrapper'>
            <h1>
                <span class='qa-advisors-page-title'>
                    {{ $t(`BREADCRUMBS.ADVISORS`) }}
                </span>
                <template v-if='!(loading && !totalPage)'>
                    ({{ totalPage }})
                </template>
            </h1>
            <div class='h-flex h-flex-center b-consult-workers__buttons'>
                <FwInput
                    class='h-flex-1 h-ml-15 b-common-title-wrapper__search'
                    :value='searchText'
                    :placeholder='$t(`LABEL.SEARCH.BY.LAST`)'
                    iconLeft='loupe'
                    iconLeftSize='15'
                    inputStyle='white-bigger-search'
                    type='search'
                    @update:value='value => searchText = value'>
                </FwInput>
                <FwButton
                    class='b-consult-workers__filter'
                    min-width='50px'
                    min-height='50px'
                    color='base'
                    style='background: #fff'
                    borderRadiusType='small-border'
                    paddingHorizontal
                    size='base'
                    @click='moveToAdvancedSearch'>
                    <FwIcon icon='filter-list'
                            size='24'
                            color='#BEC7D3'>
                    </FwIcon>
                </FwButton>
            </div>
        </div>
        <div class='b-common-button-wrapper'>
            <div class='b-consult-button-size h-flex h-flex-justify-end h-mr-0'>
                <FwButton
                    v-if='canCreate'
                    class='h-ph-40 qa-create-advisor-button'
                    size='small'
                    borderRadiusType='small-border'
                    fontSize='12px'
                    @click.native='addWorkerMethod'>
                    {{ $t('BUTTON.ADD') }}
                </FwButton>
            </div>
            <div class='fw-select-base fw-select-white-modern h-width-250' style='box-shadow: none!important;'>
                <FwSelect
                    v-model='sortValue'
                    label='name'
                    :propsPlaceholder='`${$t(`BUTTON.SORT_BY`)}:`'
                    type='sort-simple'
                    :canNotDelete='true'
                    :withCircles='true'
                    :searchable='false'
                    :options='sortOptions'>
                </FwSelect>
            </div>
        </div>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else
         class='b-consult-workers__wrapper'>
        <WorkerConsultCard
            v-for='worker in workersCardsData'
            :key='worker.id'
            class='b-consult-workers__item'
            :worker='worker'
            :workerId='workerId'
            @click.native='workerId = worker.id'>
        </WorkerConsultCard>
    </div>

    <FwPagination
        v-if='totalPage > perPage'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>
    <AddEditAdvisorPopup
        v-if='isAddEditAdvisorPopup'
        isBranchAdvisorAdd
        isAddEditConsultAdvisors
        @closePopup='closeAdvisorAddPopup'>
    </AddEditAdvisorPopup>
</div>
</template>

<script lang='ts'>
import { TranslateResult } from 'vue-i18n';
import { namespace } from 'vuex-class';
import throttle from 'lodash.throttle';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { updateUrlPageAndQuery } from '@/helpers/url';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { WorkerType } from '@/types/Workers';
import { WorkerConsultCard } from '@/components/simple/WorkerConsultCard/index';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import OrganizationMixin from '@/mixins/organization';
import { AddEditAdvisorPopup } from '@/components/popups/AddEditAdvisorPopup/index';
import PermissionsMixin from '@/mixins/permissions';

const ConsultStore = namespace('ConsultStore');

const SORT_BY_FIRST_NAME = 'first_name';
const SORT_BY_LAST_NAME = 'last_name';

@Component({
    components: {
        WorkerConsultCard,
        AddEditAdvisorPopup,
        GoBackButton,
    },
})
export default class ConsultAdvisorsPage extends Mixins(OrganizationMixin, PermissionsMixin) {
    @ConsultStore.Mutation('setAdvancedSearch') setAdvancedSearch!: (data: null) => void;

    totalPage: number | null = 0;
    perPage: number = 12;
    currentPage: number = 1;
    loading: boolean = true;
    searchText: string = '';
    isAddEditAdvisorPopup: boolean = false;
    sortKey: 'first_name' | 'last_name' = `first_name`;
    workerId: number | null = null;
    sortOptions: Array<{ name: any | TranslateResult, type: 'first_name' | 'last_name' }> = [
        {
            name: this.$i18n.t('LABEL.FIRST.NAME'),
            type: SORT_BY_FIRST_NAME,
        },
        {
            name: this.$i18n.t('LABEL.LAST.NAME'),
            type: SORT_BY_LAST_NAME,
        },
    ];
    sortValue: { name: string, type: 'first_name' | 'last_name' } = this.sortOptions[0];
    workersCardsData: Array<WorkerType> = [];
    throttleSearch = throttle(this.search, 1500, { leading: false, trailing: true });
    unwatch = () => { };
    unwatchSort = () => { };

    updateState(page: number = this.currentPage) {
        this.$router.push(updateUrlPageAndQuery(`/consult/choose/advisors`, page, {
            query: this.searchText,
            sort: this.sortKey !== 'first_name' ? this.sortKey : '',
        }));
    }

    moveToAdvancedSearch(): void {
        this.setAdvancedSearch(null);
        this.$router.push({ path: `/consult/choose/advisors/search` });
    }

    search(): void {
        this.currentPage = 1;
        this.updateState();
    }

    addWorkerMethod() {
        this.isAddEditAdvisorPopup = true;
    }

    closeAdvisorAddPopup() {
        this.isAddEditAdvisorPopup = false;
    }

    async getWorkers(current_page: number, per_page: number, sort: string) {
        this.loading = true;
        const payload: { current_page: number, per_page: number, sort: string } = {
            current_page,
            per_page,
            sort,
        };
        // @ts-ignore-next-line
        const { data } = await WorkersWebApi.getAdvisorsData(payload);
        this.workersCardsData = data.advisors;
        this.totalPage = data.meta.total_count;
        this.loading = false;
    }

    async getWorkersBySearch(current_page: number, per_page: number, searchText: string, sort: string) {
        if (this.searchText.length > 1) {
            this.loading = true;
            const payload: { current_page: number, per_page: number, sort: string } = {
                current_page,
                per_page,
                sort,
            };
            // @ts-ignore-next-line
            const { data } = await WorkersWebApi.getAdvisorsDataBySearch(payload, searchText);
            this.workersCardsData = data.advisors;
            this.totalPage = data.meta.total_count;
            this.loading = false;
        }
    }

    takeParamsFromUrl(): void {
        this.unwatch();
        this.unwatchSort();
        this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
        this.searchText = (this.$route.query.query as string) || ``;
        if (this.$route.query.sort) {
            const sotValue = this.sortOptions.find(item => item.type === this.$route.query.sort);
            if (sotValue) {
                this.sortValue = sotValue;
            }
        }
        this.unwatch = this.$watch('searchText', this.throttleSearch);
        this.unwatchSort = this.$watch('sortValue', this.sortBy);
    }

    doRequest(): void {
        if (this.searchText.length > 1) {
            this.getWorkersBySearch(this.currentPage, this.perPage, this.searchText, this.sortKey);
        } else if (this.searchText.length === 0) {
            this.getWorkers(this.currentPage, this.perPage, this.sortKey);
        }
    }

    sortBy(): void {
        if (this.sortValue) {
            this.currentPage = 1;
            this.sortKey = this.sortValue.type;
            this.updateState();
        }
    }

    created(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }

    @Watch(`$route`)
    onRouterChange(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }
}
</script>

<style lang='sass'>
.b-consult-workers
    @include media('<=phone')
        padding: 0 15px

        &__buttons
            width: 100%

        &__item
            margin: 5px 0

    @include media('>phone')
        &__item
            margin: 0 10px 20px

    &__filter
        @include media('<=phone')
            margin: 0 0 0 10px

        @include media('>phone')
            margin: 20px

    &.b-page-container--big
        max-width: 830px

    .b-consult-button-size
        height: 44px
        display: flex
        align-items: center
</style>
