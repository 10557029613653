<template>
<div class='b-add-reserved-slot-form'>
    <div v-if='recurrenceLoading'
         class='b-add-reserved-slot-form__loading'>
        <FwSpinLoader
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader h-width-100p h-height-100p'
            :isActive='recurrenceLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </div>
    <div class='b-add-reserved-slot-form__title'>
        {{ formTitle }}
    </div>
    <div v-if='date'
         class='b-add-reserved-slot-form__date qa-advisor-reserved-slots-form__date'>
        {{ dateTitle }}
    </div>
    <div>
        <FwLabelCard
            v-for='instance in currentInstancesData'
            :key='instance.id'
            class='h-mb-10 h-pointer qa-advisor-reserved-slots__date-label'
            height='40px'
            padding='0 15px'
            @click.native.stop='chooseSlot(instance)'>
            <div class='b-add-reserved-slot-form__label h-flex-space-between'>
                <div class='b-add-reserved-slot-form__label__text
                            b-add-reserved-slot-form__label__text--active
                            b-add-reserved-slot-form__label__inner
                            h-mr-10'>
                    {{ timeIntervalText(instance) }}
                </div>
                <div v-if='instance.locations'
                     class='h-flex'>
                    <div v-for='location in instance.locations'
                         v-if='getLocationIconName(location)'
                         class='b-add-reserved-slot-form__icon h-ml-5'>
                        <FwIcon
                            class='h-flex'
                            :icon='getLocationIconName(location)'
                            size='11'
                            color='#fff'>
                        </FwIcon>
                    </div>
                </div>
            </div>
        </FwLabelCard>
    </div>

    <div>
        <FwButton
            v-if='showAddButton'
            size='little'
            class='h-width-100p h-mt-20'
            borderRadiusType='tiny-border'
            @click.stop.prevent='addNewSlotHandler'>
            {{ $t('BUTTON.ADD.NEW') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { ReservedSlotsType } from '@/types/ReservedSlots';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';
import DateMixin from '@/mixins/dateMixin';
import {
    formatDateForTitle,
} from '@/helpers/dates';
import { RESERVED_SLOTS_MAPPING } from '@/helpers/appointment';

@Component
export default class ChooseReservedSlotForm extends Mixins(DateMixin) {
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Object, default: null }) readonly instanceData!: UnavailabilityEventInstancesType;
    @Prop({
        validator: prop => Array.isArray(prop) || prop === null,
        required: true,
    }) readonly currentSlots!: Array<ReservedSlotsType> | null;
    @Prop({ type: Boolean, default: false }) readonly isReservedSots!: boolean;
    @Prop({ type: Array, required: true }) readonly currentInstancesData!: Array<UnavailabilityEventInstancesType>;

    get formTitle(): TranslateResult {
        if (this.isReservedSots) {
            return this.$i18n.t('ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.CHOOSE.TITLE');
        }
        return this.$i18n.t('ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.CHOOSE.TITLE');
    }

    get dateTitle(): string {
        return formatDateForTitle(this.date);
    }

    get showAddButton(): boolean {
        if (!this.isReservedSots && this.currentInstancesData.find(item => item.full_day)) {
            return false;
        }
        return +this.date > +this.getDayDateAtNight(new Date());
    }

    timeIntervalText(instance: UnavailabilityEventInstancesType): string | TranslateResult {
        if (instance.full_day) {
            const startDate = new Date(instance.dt_start);
            const endDate = new Date(instance.dt_end);
            if (startDate.getHours() === 0 && startDate.getMinutes() === 0 && endDate.getHours() === 23 && endDate.getMinutes() === 59) {
                return this.$i18n.t('CALENDAR.FULL.DAY');
            }
        }
        return `${this.getFrom(instance)} - ${this.getTo(instance)}`;
    }

    getFrom(instance: UnavailabilityEventInstancesType) {
        return this.getCurrentDateAsMinutesAMPM(new Date(instance.dt_start), false);
    }

    getTo(instance: UnavailabilityEventInstancesType) {
        return this.getCurrentDateAsMinutesAMPM(new Date(instance.dt_end), false);
    }

    getLocationIconName(id: string): string | null {
        const type = RESERVED_SLOTS_MAPPING[id];
        return type ? type.icon : null;
    }

    @Emit('chooseSlot')
    chooseSlot(instance: UnavailabilityEventInstancesType) {
        return instance;
    }

    @Emit('addNewSlot')
    addNewSlotHandler() {}
}
</script>

<style lang='sass' scoped>
.b-add-reserved-slot-form
    .fw-label-card-wrapper
        transition: background 0.2s

        &:hover
            background-color: #e3e4e7

    &__label__inner
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%

    &__icon
        padding: 4px
        border-radius: 3px
        background-color: $dark-blue
        display: flex
        align-items: center
        justify-content: center
</style>
