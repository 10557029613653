<template>
<div class='b-closures-calendar__wrapper'>
    <div class='h-width-100p'>
        <FwDatepicker
            v-model='localDate'
            :class='{ "qa-branch-closures-datepicker-slot": localDate }'
            name='datepicker'
            class='h-mv-20 b-closures-calendar__calendar'
            :propsRange='false'
            :selectMode='false'
            :propsLang='`${$i18n.locale}`'
            pickerStyle='static'
            :hideIcon='true'
            :showWeeksNumbers='true'
            :reservedSlots='reservedSlots'
            :hideInput='true'
            reservedSlotsColor='red'
            @change-calendar-month='changeCalendarMonthHandler'
            @selectDate='updateDatesHandler'>
        </FwDatepicker>
    </div>

    <AddClosuresSlotForm
        v-if='showAddClosuresForm && !readonly'
        :date='localDate'
        :instanceData='instanceData'
        :isRequestSendingStatus='isRequestSending'
        @closePopup='showAddClosuresForm = false'
        @addSlot='addSlot'
        @removeSlot='removeSlot'>
    </AddClosuresSlotForm>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConsultApi } from '@/api/consult/ConsultApi';
import { AddClosuresSlotForm } from '@/components/forms/add/AddClosuresSlotForm';
import { ClosuresItemType, StoppageTypes } from '@/types/Consult';
import { parseToUTCDate } from '@/helpers/dates';

@Component({
    components: {
        AddClosuresSlotForm,
    },
})
export default class ClosuresCalendar extends Vue {
    @Prop({ type: String, required: true }) readonly type!: StoppageTypes;
    @Prop({ type: String, required: true }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly readonly!: boolean;

    localDate: Date = new Date();
    changedCalendarDate: Date = this.localDate;
    stoppages: Array<ClosuresItemType> = [];
    showAddClosuresForm: boolean = false;
    formKey: number = 1;
    isRequestSending: boolean = false;

    get reservedSlots(): Array<{ date: Date, id: string }> {
        const dots = [];
        for (let i = 0; i < this.stoppages.length; i++) {
            const item = this.stoppages[i];
            const daysCount = dayjs(parseToUTCDate(item.dt_end)).endOf('day').diff(parseToUTCDate(item.dt_start), 'day');
            if (!daysCount) {
                dots.push({ id: item.id, date: parseToUTCDate(item.dt_start) });
            } else {
                for (let j = 0; j <= daysCount; j++) {
                    dots.push({ id: item.id, date: dayjs(parseToUTCDate(item.dt_start)).add(j, 'day').toDate() });
                }
            }
        }
        return dots;
    }

    get instanceData(): null | ClosuresItemType {
        const currentDot = this.reservedSlots.find(item => dayjs(item.date).isSame(this.localDate.toString(), `day`)) || null;
        if (!currentDot) {
            return null;
        }
        return this.stoppages.find(item => item.id === currentDot.id) || null;
    }

    disablePrevDays(date: Date) {
        return +date.setDate(date.getDate() + 1) <= +new Date();
    }

    async removeSlot() {
        if (this.instanceData) {
            await ConsultApi.deleteStoppageEvent(this.instanceData.id);
            this.doRequest();
        }
    }

    async doRequest() {
        try {
            this.stoppages = await ConsultApi.fetchStoppagesEvents({
                organization_type: this.type,
                organization_id: this.id,
                dt_start: dayjs(this.changedCalendarDate).startOf('month').format(),
                dt_end: dayjs(this.changedCalendarDate).endOf('month').format(),
                per_page: 31,
            });
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async addSlot(payload: { dt_start: string, dt_end: string }) {
        try {
            this.isRequestSending = true;
            if (this.instanceData) {
                await ConsultApi.updateStoppageEvent(this.instanceData.id, {
                    organization_type: this.type,
                    organization_id: this.id,
                    ...payload,
                });
            } else {
                await ConsultApi.postStoppageEvent({
                    organization_type: this.type,
                    organization_id: this.id,
                    ...payload,
                });
            }
            this.doRequest();
            this.showAddClosuresForm = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    updateDatesHandler(date: Date) {
        this.localDate = date;
        this.showAddClosuresForm = true;
        this.formKey += 1;
    }

    changeCalendarMonthHandler(data: { year: number, month: number, flag: number, vm: any, sibling: undefined } | null, forceDate: null | Date) {
        if (!data && !forceDate) return;
        // @ts-ignore-next-line
        this.changedCalendarDate = forceDate || new Date(data.year, data.month + data.flag + 1, 0);
        this.doRequest();
    }

    async created() {
        this.doRequest();
    }
}
</script>

<style lang='sass'>
.b-closures-calendar
    &__wrapper
        padding: 0 0 10px
        position: relative
        display: flex
        align-items: center
        .mx-datepicker
            box-shadow: none

    &__calendar
        max-width: 340px
        margin: 20px auto 0
</style>
