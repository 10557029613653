<template>
<div class='b-invitation-preview b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='650px'
        width='auto'
        title=''
        :allowBackgroundScrolling='false'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column  h-flex-center'>
                <h2 class='h-font-26 h-mv-20 h-text-center'>
                    {{ headerText }}
                </h2>
                <img class='h-width-100p' :src='requireImage("invitation-preview-001.png")'>
                <div class='h-width-100p'>
                    <p class='h-fw-700'>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.WELCOME') }}
                    </p>
                    <p>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.CUSTOMER.NAME') }}
                    </p>
                    <p>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.OPTIMIZE') }}
                    </p>
                    <p>
                        1) <span class='h-fw-700'>
                            {{ $t('ONBOARDING.INVITATION.PREVIEW.OPTIMIZE1') }}
                        </span>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.OPTIMIZE1.1') }}
                    </p>
                    <FwButton borderRadiusType='tiny-border'
                              class='h-mt-20 h-width-100p'
                              @click.native='item.method'>
                        <span class='h-width-100p h-text-center'>
                            Calizy Pro - Android
                        </span>
                    </FwButton>
                    <FwButton borderRadiusType='tiny-border'
                              class='h-mt-20 h-width-100p'
                              @click.native='item.method'>
                        <span class='h-width-100p h-text-center'>
                            Calizy Pro - IOS
                        </span>
                    </FwButton>
                    <img class='h-width-100p h-mt-20' :src='requireImage("invitation-preview-002.png")'>
                    <p>
                        2) <span class='h-fw-700'>
                            {{ $t('ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2') }}
                        </span>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2.1') }}
                        <span class='h-fw-700'>
                            {{ $t('ONBOARDING.INVITATION.PREVIEW.OPTIMIZE2.2') }}
                        </span>
                    </p>
                    <p>
                        3) {{ $t('ONBOARDING.INVITATION.PREVIEW.OPTIMIZE3') }}
                        <span class='h-fw-700'>
                            {{ $t('ONBOARDING.INVITATION.PREVIEW.OPTIMIZE3.1') }}
                        </span>
                    </p>
                    <hr>
                    <p>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.CONGRATS1') }}
                        <span class='h-fw-700'>
                            {{ $t('ONBOARDING.INVITATION.PREVIEW.CONGRATS1.1') }}
                        </span>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.CONGRATS1.2') }}
                        <span class='h-fw-700'>
                            {{ $t('ONBOARDING.INVITATION.PREVIEW.CONGRATS1.3') }}
                        </span>
                    </p>
                    <hr>
                    <p>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.QUESTIONS1') }}
                        <span class='h-fw-700'>
                            hello@calizypro.com
                        </span>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.QUESTIONS1.1') }}
                    </p>
                </div>
                <div>
                    <p class='h-font-12 h-text-center'>
                        {{ $t('ONBOARDING.INVITATION.PREVIEW.FOOTER') }}
                    </p>
                    <p class='h-font-12 h-text-center'>
                        Copyright © Calizy Pro - All rights reserved
                    </p>
                </div>
            </div>
        </template>
    </FwPopup>
    <div class='b-invitation-preview__footer h-flex h-flex-center'>
        <p>
            {{ $t('ONBOARDING.INVITATION.WORKERS.MESSAGE1') }}
            {{ workerCountCheck ? `${workerCountCheck} of` : `all` }}
            {{ $t('ONBOARDING.INVITATION.WORKERS.MESSAGE1.1') }}
        </p>
        <FwButton
            class='b-invitation-preview__footer--button h-m-0'
            borderRadiusType='small-border'
            @click.native='$emit("invitationStatusSent")'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class InvitationPreviewPopup extends Vue {
    @Prop({ type: String, default: null }) readonly headerText!: string;
    @Prop({ type: String, default: null }) readonly subMessage!: string;
    @Prop({ type: String, default: null }) readonly buttonText!: string;
    @Prop({ type: String, default: null }) readonly companyId!: string;
    @Prop({ type: Boolean, default: false }) readonly listToApprove!: boolean;
    @Prop({ type: Number, default: null }) readonly workerCountCheck!: number;
    @Prop({ type: Boolean, default: false }) readonly workingHours!: boolean;
    @Prop({ type: Boolean, default: false }) readonly addressConfirm!: boolean;
}
</script>
<style lang='sass'>
    .b-invitation-preview
        overflow-y: scroll
        justify-content: center
        align-items: flex-start
        .fw-popup
            position: relative
            margin: 40px 0 100px
            transform: none!important
            top: 0!important
        &__footer
            display: flex
            justify-content: center
            align-items: center
            height: 60px
            background: #ffffff
            position: fixed
            width: 100%
            left: 0
            bottom: 0
            &--button
                position: absolute
                right: 30px
</style>
