import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import i18n from '@/locale';
import { User } from '@/types/User';

@Component
export default class RedirectMixin extends Vue {
    goToPlanAppointment(user: User): void {
        if (user) {
            i18n.locale = user.locale;
            this.$router.push({ path: '/' }).catch(() => {});
        }
    }
};
