<template>
<div class='b-gray-background b-blue-background--fixed h-flex h-flex-center b-popup-with-sticky-close'>
    <FwPopup
        class='h-pos-rel'
        style='height: 90%;overflow-y: auto; justify-content: flex-start'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='1200px'
        width='100%'
        padding='35px 35px 0'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#f4f5f6'
        popupRadius='20px'
        @close='closePopup'>
        <template #body>
            <AddOrEditAppointmentTypeForm
                :showTitle='true'
                :disabled='disableByPermission'
                :startAppointmentCategoryData='startAppointmentCategoryData'
                @changeDisabledFormButton='value => disabled = value'
                @emitAddAppointmentData='payload => payloadData = payload'>
            </AddOrEditAppointmentTypeForm>

            <div class='h-flex-center b-popup-sticky-bottom-buttons'>
                <FwButton
                    v-if='!disableByPermission'
                    size='medium'
                    :disabled='isDisabled'
                    borderRadiusType='small-border'
                    @click.native='editAppointmentData'>
                    {{ startAppointmentCategoryData ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { AddOrEditAppointmentTypeForm } from '@/components/forms/add/AddOrEditAppointmentTypeForm';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import PermissionsMixin from '@/mixins/permissions';
import {
    CreateAppointmentCategoryPayloadType,
    StartAppointmentCategoryDataType,
} from '@/types/Appointment';

@Component({
    components: {
        AddOrEditAppointmentTypeForm,
    },
})
export default class AddEditAppointmentTypePopup extends Mixins(PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly startAppointmentCategoryData!: StartAppointmentCategoryDataType;

    disabled: boolean = true;
    sendRequest: boolean = false;
    payloadData: CreateAppointmentCategoryPayloadType | null = null;

    get isDisabled(): boolean {
        return this.disabled || this.sendRequest;
    }

    get isEdit(): boolean {
        return Boolean(this.startAppointmentCategoryData);
    }

    get disableByPermission(): boolean {
        return this.isEdit ? !this.canEdit : !this.canCreate;
    }

    async editAppointmentData() {
        if (this.isDisabled || !this.payloadData) {
            return;
        }
        this.sendRequest = true;
        try {
            if (this.startAppointmentCategoryData) {
                await SettingsWebApi.editAppointmentType(this.payloadData, this.startAppointmentCategoryData.id);
            } else {
                await SettingsWebApi.createAppointmentCategory(this.payloadData);
            }
            this.closePopup();
            this.$emit('updateAppointments');
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.sendRequest = false;
        }
    }

    @Emit('close')
    closePopup() {}
}
</script>
