<template>
<div class='b-consult-branches b-page-container b-page-container--big'>
    <GoBackButton
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <div class='h-flex-dir-column h-mh-10'>
        <div class='b-common-title-wrapper'>
            <h1>
                {{ $t('PLAN.APPOINTMENT.BUSINESS.PARTNER.TITLE') }}
                <template v-if='!(loading && !totalPage)'>
                    ({{ totalPage }})
                </template>
            </h1>
            <div class='b-common-title-wrapper__search'>
                <FwInput
                    class='h-flex-1 h-ml-15'
                    :value='searchText'
                    :placeholder='$t(`COMPANIES.SEARCH.PLACEHOLDER`)'
                    iconRight='loupe'
                    iconSize='15'
                    inputStyle='white-bigger-search'
                    type='search'
                    @update:value='value => searchText = value'>
                </FwInput>
            </div>
        </div>
        <div class='h-flex h-width-100p h-flex-space-between'>
            <div class='b-consult-button-size h-flex h-flex-justify-end h-mr-0'>
                <FwButton
                    v-if='canCreate'
                    class='h-ph-40'
                    size='small'
                    borderRadiusType='small-border'
                    fontSize='12px'
                    @click.native='addCompanyMethod'>
                    {{ $t('BUTTON.ADD') }}
                </FwButton>
            </div>
            <div class='fw-select-base fw-select-white-modern h-width-250' style='box-shadow: none!important;'>
                <FwSelect
                    v-model='sortValue'
                    :propsPlaceholder='`${$t(`BUTTON.SORT_BY`)}:`'
                    label='name'
                    type='sort-simple'
                    :withCircles='true'
                    :options='sortOptions'>
                </FwSelect>
            </div>
        </div>
    </div>
    <div class='b-consult-cards__wrapper h-pb-70'>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <CompanyCard
            v-for='company in companiesCards'
            v-else
            :key='company.id'
            :company='company'
            class='h-mh-10 h-mb-20'
            @click='goToCompanyPage'>
        </CompanyCard>
    </div>
    <AddOrEditCompanyByForm
        v-if='isAddEditCompanyPopup'
        @closePopup='closeCompanyAddPopup'>
    </AddOrEditCompanyByForm>
    <FwPagination
        v-if='totalPage > perPage'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>
</div>
</template>

<script lang='ts'>
import { Component, Watch, Mixins } from 'vue-property-decorator';
import throttle from 'lodash.throttle';
import { CompanyData } from '@/types/Companies';
import { CompanyCard } from '@/components/simple/CompanyCard';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { AddOrEditCompanyByForm } from '@/views/consult/add-companies/AddOrEditCompanyByForm';
import PermissionsMixin from '@/mixins/permissions';
import { updateUrlPageAndQuery } from '@/helpers/url';

@Component({
    components: {
        CompanyCard,
        GoBackButton,
        AddOrEditCompanyByForm,
    },
})
export default class ConsultCompaniesPage extends Mixins(PermissionsMixin) {
    searchText: string = '';
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;
    loading: boolean = true;
    isAddEditCompanyPopup: boolean = false;
    sortOptions: Array<{ name: str, type: 'name' | 'number' }> = [
        {
            name: 'AZ',
            type: 'name',
        },
        {
            name: this.$i18n.t('BRANCH.ADVISORS.NUMBER'),
            type: 'number',
        },
    ];
    sortValue: { name: str, type: 'name' | 'number' } = this.sortOptions[0];
    companiesCards: Array<CompanyData> = [];
    throttleSearch = throttle(this.search, 1500, { leading: false, trailing: true });
    unwatch = () => { };
    unwatchSort = () => { };

    get sortByWorkersCount(): boolean {
        return this.sortValue.type === `number`;
    }

    updateState(page: number = this.currentPage) {
        this.$router.push(updateUrlPageAndQuery(`/consult/choose/companies`, page, {
            query: this.searchText,
            sort: this.sortValue.type !== 'name' ? this.sortValue.type : '',
        }));
    }

    doRequest() {
        if (this.searchText.length > 1) {
            this.getCompaniesBySearch(this.currentPage, this.perPage, this.searchText, this.sortByWorkersCount);
        } else if (this.searchText.length === 0) {
            this.getCompanies(this.currentPage, this.perPage, this.sortByWorkersCount);
        }
    }

    search(): void {
        this.currentPage = 1;
        this.updateState();
    }

    addCompanyMethod() {
        this.isAddEditCompanyPopup = true;
    }

    closeCompanyAddPopup() {
        this.isAddEditCompanyPopup = false;
    }

    async getCompanies(current_page: number, per_page: number, sort: boolean) {
        this.loading = true;
        const payload: any = {
            current_page,
            per_page,
            sort,
        };
        const data = await AppointmentWebApi.getSocietyPaginationData(payload);
        this.totalPage = data.meta.total_count;
        this.loading = false;
        this.companiesCards = data.companies;
    }

    async getCompaniesBySearch(current_page: number, per_page: number, searchText: string, sort: boolean) {
        if (this.searchText.length > 1) {
            this.loading = true;
            const payload: any = {
                current_page,
                per_page,
                sort,
            };
            const data = await AppointmentWebApi.getSocietyDataBySearch(payload, searchText);
            this.totalPage = data.meta.total_count;
            this.loading = false;
            this.companiesCards = data.companies;
        }
    }

    takeParamsFromUrl(): void {
        this.unwatch();
        this.unwatchSort();
        this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
        this.searchText = (this.$route.query.query as string) || ``;
        if (this.$route.query.sort) {
            const sotValue = this.sortOptions.find(item => item.type === this.$route.query.sort);
            if (sotValue) {
                this.sortValue = sotValue;
            }
        }
        this.unwatch = this.$watch('searchText', this.throttleSearch);
        this.unwatchSort = this.$watch('sortValue', this.sortBy);
    }

    async goToCompanyPage(value: { id: string }) {
        const data = await AppointmentWebApi.getSocietyItemData(value.id);
        this.$store.commit('PLAN_COMPANY', data.company);
        this.$router.push({ name: 'PlanAnAppointmentCalendar' });
    }

    created() {
        this.takeParamsFromUrl();
        this.doRequest();
    }

    sortBy(): void {
        if (this.sortValue) {
            this.currentPage = 1;
            this.updateState();
        }
    }

    @Watch(`$route`)
    onRouterChange(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }
}
</script>

<style lang='sass'>
    .b-consult-branches.b-page-container--big
        max-width: 830px
        .b-consult-button-size
            height: 44px
            display: flex
            align-items: center
</style>
