import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { PreparedAdvancedSearchParams } from '@/types/Consult';

@Module({
    namespaced: true,
})
class ConsultStore extends VuexModule {
    advancedSearchData: null | PreparedAdvancedSearchParams = null;

    @Mutation
    setAdvancedSearch(data: null | PreparedAdvancedSearchParams) {
        this.advancedSearchData = data;
    }
}

export default ConsultStore;
