<template>
<div class='b-company-time-card h-mh-5'>
    <div class='b-company-time-card__inner'>
        <div v-if='!data.start_from_morning'
             class='b-company-time-card__time'>
            {{ timeFromDate }}
            <span v-if='data && data.booked_in === `direct`'
                  class='h-ml-5'>
                ⚠️
            </span>
        </div>
        <div class='b-company-time-card__title'>
            <p class='h-m-0 b-company-time-card__overflow-text'>
                {{ title }}
            </p>
        </div>
    </div>
    <slot></slot>

    <div v-if='data.booking_platform && !isConsultCalendarServicePoint'
         class='b-company-time-card__appointment'
         :class='{
             "b-company-time-card__appointment--saas": data.booking_platform === "saas",
             "b-company-time-card__appointment--sc": data.booking_platform === "sc",
         }'>
    </div>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import DateMixin from '@/mixins/dateMixin';
import CalendarMixin from '@/mixins/calendar';
import { ConsultEventType } from '@/types/Availabilities';
import { capitalizeAll } from '@/helpers/string';
import { ServicePointShiftListType } from '@/types/Events';

@Component
export default class ConsultCalendarEventCard extends Mixins(DateMixin, CalendarMixin) {
    @Prop({ type: Object, required: true }) readonly data!: ConsultEventType | ServicePointShiftListType;

    get title(): string {
        // @ts-ignore-next-line
        return this.isConsultCalendarServicePointShifts ? `${capitalizeAll(this.data.first_name || '')} ${capitalizeAll(this.data.last_name || '')}` : this.data.title;
    }

    get timeFromDate() {
        return this.getCurrentDateAsMinutesAMPM(new Date(this.data.dt_start), !this.isConsultCalendarServicePointShifts);
    }
}
</script>

<style lang='sass'>
.b-company-time-card
    position: relative
    width: 100%
    display: flex
    padding: 3px
    border-radius: 5px
    background-color: #fff
    overflow: hidden
    box-shadow: $base-box-shadow
    z-index: 1

    &:hover
        box-shadow: 0 12px 24px 0 rgba(39,219,189,0.2)

    &__inner
        display: flex
        flex-wrap: wrap
        width: 100%
        align-self: flex-start

    &__time
        padding: 1px 0
        width: 100%
        display: flex
        align-items: center
        justify-content: center
        font-weight: 500
        font-size: 12px

    &__overflow-text
        min-width: 100px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__title
        font-size: 12px
        padding: 1px 0 1px 5px
        width: 100%
        font-weight: 400
        display: flex
        justify-content: center
        word-break: break-word
        align-items: flex-start
        line-height: 1.5em

    &__appointment
        right: 0
        width: 8px
        height: 8px
        position: absolute
        bottom: 0
        border-radius: 5px 0 5px 0

        &--sc
            background: #37B4FB !important
        &--saas
            background: #FB7C07 !important
</style>
