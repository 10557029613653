import { RouteType } from '@/types/router';
import { AppointmentBookedTypes } from '@/views/consult/branch-statistics/AppointmentBookedTypes';
import { AppointmentBookedMethods } from '@/views/consult/branch-statistics/AppointmentBookedMethods';
import { AppointmentBookedOrigin } from '@/views/consult/branch-statistics/AppointmentBookedOrigin';
import { AppointmentBookedByTimeSlot } from '@/views/consult/branch-statistics/AppointmentBookedByTimeSlot';
import { AppointmentBookedByTimeBetweenMaking } from '@/views/consult/branch-statistics/AppointmentBookedByTimeBetweenMaking';
import { AppointmentBookedByTimeBetweenAppointment } from '@/views/consult/branch-statistics/AppointmentBookedByTimeBetweenAppointment';
import { AppointmentBookedRecipients } from '@/views/consult/branch-statistics/AppointmentBookedRecipients';
import { AppointmentBookedTypesTaken } from '@/views/consult/branch-statistics/AppointmentBookedTypesTaken';
import { RIGHT_READ, CONSULT } from '@/helpers/rights';
import { BOOKED_GROUP, CANCELLED_GROUP, HUB_TYPE } from './consts';

const BASE_META = {
    showAppHeader: true,
    showAppHeaderProgress: true,
    rightBlock: 'kpis_rights',
    right: RIGHT_READ,
    pageType: CONSULT,
    type: HUB_TYPE,
};

const childrenHubStatisticRoutes: Array<RouteType> = [
    {
        path: `/consult/hub/statistic/:id/appointment-booked-types/:type`,
        name: 'AppointmentBookedTypesHub',
        component: AppointmentBookedTypes,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.TYPES',
            titleFor: 'HUB.STATISTICS.BOOKED.TYPE',
            titleBy: 'HUB.STATISTICS.BOOKED.BY.TYPE',
            linkGroupName: BOOKED_GROUP,
            requestType: 'types',
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-booked-methods/:type',
        name: `AppointmentBookedMethodsHub`,
        component: AppointmentBookedMethods,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.METHODS',
            titleFor: 'HUB.STATISTICS.BOOKED.TYPE',
            titleBy: 'HUB.STATISTICS.BOOKED.BY.TYPE',
            linkGroupName: BOOKED_GROUP,
            requestType: 'methods',
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-booked-starting-time/:type',
        name: `AppointmentBookedByTimeSlotHub`,
        component: AppointmentBookedByTimeSlot,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.BY_TIME_SLOT',
            titleFor: 'HUB.STATISTICS.BOOKED.TYPE',
            titleBy: 'HUB.STATISTICS.BOOKED.BY.TYPE',
            linkGroupName: BOOKED_GROUP,
            requestType: 'starting_times',
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-booked-time-between-booking/:type',
        name: `AppointmentBookedByTimeBetweenMakingHub`,
        component: AppointmentBookedByTimeBetweenMaking,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.TIME_BETWEEN',
            titleFor: 'HUB.STATISTICS.BOOKED.TYPE',
            titleBy: 'HUB.STATISTICS.BOOKED.BY.TYPE',
            linkGroupName: BOOKED_GROUP,
            requestType: 'times_between',
            sortByKeyLength: true,
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-booked-source/:type',
        name: `AppointmentBookedOriginHub`,
        component: AppointmentBookedOrigin,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.SOURCE',
            titleFor: 'HUB.STATISTICS.BOOKED.TYPE',
            titleBy: 'HUB.STATISTICS.BOOKED.BY.TYPE',
            hideTabs: true,
            linkGroupName: BOOKED_GROUP,
            requestType: 'sources',
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-booked-recipients/:type',
        name: 'AppointmentBookedByRecipientsHub',
        component: AppointmentBookedRecipients,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.RECIPIENTS',
            titleFor: 'HUB.STATISTICS.BOOKED.BY.TYPE',
            titleBy: 'HUB.STATISTICS.BOOKED.BY.TYPE',
            hideTabs: true,
            linkGroupName: BOOKED_GROUP,
            requestType: 'recipients',
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-types-cancelled-branch/:type',
        name: 'AppointmentTypeCancelledForBranchHub',
        component: AppointmentBookedTypesTaken,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.CANCELLATION.ORIGIN',
            titleFor: 'HUB.STATISTICS.CANCELLED.TYPE',
            titleBy: 'HUB.STATISTICS.CANCELLED.BY.TYPE',
            linkGroupName: CANCELLED_GROUP,
            title: 'HUB.STATISTICS.CANCELLED.TYPE',
            requestType: 'origins',
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-types-cancelled-time-between/:type',
        name: `AppointmentBookedByTimeBetweenAppointmentHub`,
        component: AppointmentBookedByTimeBetweenAppointment,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.CANCELLATION.TIME.BETWEEN',
            titleFor: 'HUB.STATISTICS.CANCELLED.TYPE',
            titleBy: 'HUB.STATISTICS.CANCELLED.BY.TYPE',
            linkGroupName: CANCELLED_GROUP,
            requestType: 'times_between',
            sortByKeyLength: true,
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-cancelled-source/:type',
        name: 'AppointmentBookedForOriginHub',
        component: AppointmentBookedOrigin,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.CANCELLED.SOURCE',
            titleFor: 'HUB.STATISTICS.CANCELLED.TYPE',
            titleBy: 'HUB.STATISTICS.CANCELLED.BY.TYPE',
            hideTabs: true,
            linkGroupName: CANCELLED_GROUP,
            requestType: 'sources',
        },
    },
    {
        path: '/consult/hub/statistic/:id/appointment-cancelled-recipients/:type',
        name: 'AppointmentBookedForRecipientsHub',
        component: AppointmentBookedRecipients,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.CANCELLED.RECIPIENTS',
            titleFor: 'HUB.STATISTICS.CANCELLED.BY.TYPE',
            titleBy: 'HUB.STATISTICS.CANCELLED.BY.TYPE',
            hideTabs: true,
            linkGroupName: CANCELLED_GROUP,
            requestType: 'recipients',
        },
    },
];

export default childrenHubStatisticRoutes;
