<template>
<div class='b-address-branch'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div :style='{ opacity: loading ? 0 : 1 }'>
        <div class='h-text-center h-pt-30'>
            <h1>
                {{ InServicePointFlow ? $t('SELECT.SERVICE_POINT.TITLE') : $t('SELECT.ADDRESS.BRANCH.TITLE') }}
            </h1>
        </div>
        <div style='align-items: flex-start' class='h-flex h-flex-center'>
            <div v-if='false'
                 class='h-flex h-flex-center h-pr-15'>
                <div ref='map' class='b-branch-office-map-size'></div>
            </div>
            <div class='fw-select-address h-ph-15 b-classic-width-input'>
                <div class='h-width-100p'>
                    <form class='h-flex-center'
                          @keyup.prevent.enter='getBranches(currentPage, perPage, true)'
                          @submit.prevent.stop>
                        <FwInput
                            v-focus='true'
                            class='h-flex-1 qa-select-appointment-branch-address input'
                            :value='searchText'
                            :placeholder='$t(`SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER`)'
                            iconRight='loupe'
                            iconSize='15'
                            :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                            inputStyle='white-bigger-search'
                            type='search'
                            @update:value='value => searchText = value'>
                        </FwInput>
                        <FwButton
                            class='b-address-branch__submit qa-select-appointment-branch-address__submit'
                            :disabled='!searchText.length || (lastSearchCode === searchText && !isButtonActiveOn)'
                            borderRadiusType='small-border'
                            size='little'
                            @click='getBranches(currentPage, perPage, true)'>
                            {{ $t('BUTTON.SUBMIT') }}
                        </FwButton>
                    </form>
                </div>
                <div v-if='options.length'
                     ref='infiniteScroll'
                     class='b-address-branch__option__wrapper'>
                    <div v-for='option in options'
                         class='b-address-branch__option qa-select-appointment-branch-option'
                         :class='{ "multiselect__content-item--active-item": client && option.external_id === client.branch_external_id }'
                         @click='goToInterventionPage(option)'>
                        <div class='multiselect__item-header h-flex h-flex-dir-column b-multiselect__item-header'>
                            <span class='b-address-branch__option__name'>
                                {{ option.name | capitalizeAll }}
                            </span>
                            <span class='b-address-branch__option__address'>
                                {{ option.address }}
                            </span>
                        </div>
                        <FwIcon
                            class='h-flex-center h-pointer h-mr-15'
                            icon='arrow-right'
                            size='14'
                            color='#27DBBD'>
                        </FwIcon>
                    </div>
                </div>
                <FwSpinLoader
                    v-if='optionsLoading'
                    :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                    class='h-flex-center loader'
                    :isActive='optionsLoading'
                    className='h-pt-30 h-mt-15'>
                </FwSpinLoader>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GoogleApi from '@/mixins/google-api';
import { OptionGoogleAddressType, ParsedAddressData } from '@/types/GoogleMap';
import {
    BranchAddressType,
    BranchByPostCodeItem,
    AppointmentCategoryType,
    CategoryLocationSkillNameType,
    AppointmentSearchType, AppointmentOnSiteDataType,
} from '@/types/Appointment';
import NoDataMixin from '@/mixins/no-data-mixin';
import ValidationMixin from '@/mixins/validation';
import AppointmentMixin from '@/mixins/appointment';
import { ClientType } from '@/types/User';
import { SitesApi } from '@/api/sites/SitesApi';
import {
    FLOWS_WITH_ROOM_ID_REQUIRE,
} from '@/helpers/appointment';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

const AppointmentStore = namespace('AppointmentStore');
const BreadCrumbsStore = namespace('BreadCrumbsStore');
const CalendarStore = namespace('CalendarStore');

@Component
export default class AppointmentChooseBranchPage extends Mixins(GoogleApi, AppointmentMixin, NoDataMixin, ValidationMixin) {
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @AppointmentStore.Mutation('setBranchAddress') setBranchAddress!: (branchAddress: BranchAddressType) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (categoryName: AppointmentCategoryType) => void;
    @AppointmentStore.State('locationName') locationName!: CategoryLocationSkillNameType;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;
    @AppointmentStore.State('placeParsedData') placeParsedData!: ParsedAddressData;
    @AppointmentStore.Mutation('setAppointmentOnSiteData') setAppointmentOnSiteData!: (appointment: AppointmentOnSiteDataType | AppointmentSearchType) => void;
    @AppointmentStore.State('client') client!: ClientType | null;
    @CalendarStore.State('post_code') post_code!: string;

    value: OptionGoogleAddressType | null = null;
    // mapGetParam: string = `callback=initMap`;
    loading: boolean = true;
    isButtonActiveOn: boolean = false;
    optionsLoading: boolean = false;
    searchText: string = '';
    lastSearchCode: string = '';
    options: Array<BranchByPostCodeItem> = [];
    currentPage: number = 1;
    perPage: number = 20;
    totalCount: number = 0;
    map: google.maps.Map | null = null;
    currentMapMarkers: Array<google.maps.Marker> = [];

    $refs!: {
        map: HTMLElement,
        infiniteScroll: HTMLElement
    };

    scroll(e: any) {
        if (e && e.target) {
            const element = e.target;
            if (element.offsetHeight + element.scrollTop + 25 >= element.scrollHeight &&
                this.totalCount > this.options.length) {
                this.getBranches(this.currentPage, this.perPage, false);
            }
        }
    }

    async getBranches(currentPage: number, perPage: number, isSubmit: boolean = false) {
        if (this.options.length) {
            this.$refs.infiniteScroll.removeEventListener('scroll', this.scroll);
        }
        if (isSubmit) {
            this.options = [];
            currentPage = 1;
            this.totalCount = 0;
        }
        if (this.searchText) {
            this.optionsLoading = true;
            try {
                const branch_external_id = this.client ? this.client.branch_external_id : null;
                let sites, meta;
                if (this.InServicePointFlow) {
                    const data = await SitesApi.getServicePointsByPostCode(
                        this.searchText,
                        {
                            current_page: currentPage,
                            per_page: perPage,
                        },
                    );
                    sites = data.service_points;
                    ({ meta } = data);
                } else {
                    ({ sites, meta } = await SitesApi.getBranchesByPostCode(
                        this.searchText,
                        FLOWS_WITH_ROOM_ID_REQUIRE.includes(this.appointmentType),
                        branch_external_id && this.client && this.client.post_code === this.searchText && !this.isButtonActiveOn ? branch_external_id : null,
                        {
                            current_page: currentPage,
                            per_page: perPage,
                        },
                    ));
                }
                if (branch_external_id) {
                    if (!sites.length && this.client && this.client.post_code === this.searchText) {
                        this.isButtonActiveOn = true;
                    }
                    this.options = [...this.options, ...this.client ? sites.sort((x, y) => {
                        /* eslint-disable-next-line no-nested-ternary */
                        return x.external_id === branch_external_id ? -1 : y.external_id === branch_external_id ? 1 : 0;
                    }) : sites];
                } else {
                    this.options = [...this.options, ...sites];
                }
                if (meta && meta.next_page) {
                    this.currentPage = meta.next_page;
                    this.totalCount = meta.total_count;
                }
                this.lastSearchCode = this.searchText;
                if (!sites.length) {
                    this.sentNotif('LABEL.HAVE.NOT.DATA.FOR.POST_CODE');
                } else {
                    this.updateMap(this.options);
                }
                if (this.options.length && this.totalCount > this.perPage) {
                    this.$nextTick(() => {
                        if (this.$refs.infiniteScroll) {
                            this.$refs.infiniteScroll.addEventListener('scroll', this.scroll, false);
                        }
                    });
                }
            } catch ({ response }) {
                this.optionsLoading = false;
                this.sentNotif(response);
            } finally {
                this.optionsLoading = false;
            }
        }
    }

    async goToCalendarWithOnSite(option: BranchAddressType) {
        this.setAppointmentCategory({ ...this.appointmentCategory, site_id: option.id });

        try {
            const appointment = await AppointmentWebApi.onSiteInBranchAppointments({
                account_id: this.user().account_id,
                appointment_category_id: this.appointmentCategory.id,
                location: this.placeParsedData.calizyAddress,
                site_id: option.id || '',
                category: 'location_category_on_site_in_branch',
                ...(this.client && this.client.id && { client_id: this.client.id }),
            });

            this.setAppointmentOnSiteData(appointment);
            this.$router.push({ path: '/appointment/calendar/in-branch-on-site' });
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    clearCurrentMarkers() {
        for (let i = 0; i < this.currentMapMarkers.length; i++) {
            this.currentMapMarkers[i].setMap(null);
        }
    }

    updateMap(sites: Array<BranchByPostCodeItem>) {
        if (!this.$refs.map) return;
        this.clearCurrentMarkers();
        const positions = [];
        for (let i = 0; i < sites.length; i++) {
            const markerData: BranchByPostCodeItem = sites[i];
            const position = new window.google.maps.LatLng(markerData.latitude, markerData.longitude);
            const marker = new window.google.maps.Marker({
                position,
                // icon: icons[features[i].type].icon,
                map: this.map,
            });
            this.currentMapMarkers.push(marker);
            positions.push(position);
        }
        if (this.map) {
            if (positions.length > 1) {
                const bounds = new window.google.maps.LatLngBounds();
                for (let k = 0; k < positions.length; k++) {
                    bounds.extend(positions[k]);
                }
                this.map.fitBounds(bounds);
            } else {
                this.map.setCenter(positions[0]);
                this.map.setZoom(12);
            }
        }
    }

    async mounted() {
        if (this.appointmentDataToUpdate) {
            this.setPostCode('');
        }

        if (!this.appointmentCategory || !this.locationName) {
            this.$_NoDataMixin_bookFlowNoData();
        } else if (this.$refs.map) {
            window.initMap = () => {
                this.map = new window.google.maps.Map(
                    this.$refs.map,
                    {
                        zoom: 7,
                        center: { lat: 48.864716, lng: 2.349014 },
                        disableDefaultUI: true,
                    });
            };
        }

        if ((this.client && this.client.post_code) || this.post_code) {
            if (this.client && this.client.post_code) {
                this.searchText = this.client.post_code;
            } else {
                this.searchText = this.post_code;
            }

            this.getBranches(this.currentPage, this.perPage, true);
        }

        this.loading = false;
    }

    beforeDestroy() {
        if (this.$refs.map) {
            this.$refs.map.remove();
        }
    }

    goToInterventionPage(branchAddress: BranchAddressType) {
        this.setBranchAddress(branchAddress);

        if (branchAddress.post_code) {
            this.setPostCode(branchAddress.post_code);
        }
        if (this.isFaceToFaceFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-face-to-face` });
        } else if (this.isOnSiteInBranchFlow) {
            this.goToCalendarWithOnSite(branchAddress);
        } else if (this.isVideoconferenceFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-videoconference` });
        } else if (this.isBranchPrivateVideoFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-face-to-face-private` });
        } else if (this.isBranchByPhonePublicFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-phone-public` });
        } else if (this.isBranchByPhonePrivateFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-phone-private` });
        } else if (this.isBranchByVideoPublicFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-video-public` });
        } else if (this.isBranchByVideoPrivateFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-video-private` });
        } else if (this.InServicePointFlow) {
            this.$router.push({ path: `/appointment/calendar/in-service-point` });
        }
    }
}
</script>

<style lang='sass'>
.b-address-branch
    .multiselect__content-wrapper
        width: 100% !important

    .multiselect__option.multiselect__option--selected .multiselect__title-second
        font-weight: normal

    .multiselect__option.multiselect__option--selected
        background-color: #757575 !important

    .multiselect__content
        background-color: #fff
        max-width: 100%

    .multiselect__content-wrapper
        display: block !important
        margin-top: 15px !important
        max-height: 320px !important

    &__input--disable
        &.fw-select-disabled
            opacity: 1

    &__input__no-results
        color: #203f6a

    .fw-input--white-bigger-search
        box-shadow: none
        border-radius: 5px !important

    &__submit
        height: 50px !important
        margin-left: 15px

.fw-select-address .multiselect .fw-select__caret
    opacity: 0

.fw-select-address
    .fw-select-white
        box-shadow: none!important

.b-branch-office-map-size
    width: 390px
    height: 400px

.fw-select-address .multiselect__placeholder
    padding-top: 0 !important

.b-multiselect__item-header
    width: calc(100% - 35px)

.multiselect__content-item--active-item
    background-color: rgba(33, 63, 107, 0.15)
    path
        fill: #213F6B !important

    .b-address-branch__option__name, .b-address-branch__option__address
        color: #213F6B

.b-address-branch__option
    display: flex
    padding: 15px
    border-bottom: 1px solid #a2a8b1
    cursor: pointer

    svg
        will-change: transform
        transition: transform 0.26s
        transform: translate(0px)

    &:hover
        .b-address-branch__option__name, .b-address-branch__option__address
            color: #203f6a

        path
            fill: #203f6a !important

        svg
            transform: translate(4px)

    &__name
        font-size: 20px
        font-weight: bold
        margin-bottom: 7px
        color: #27DBBD

    &__address
        font-weight: 300
        color: #a2a8b1
        text-transform: uppercase

    &__wrapper
        max-height: 355px
        overflow-y: auto
        margin-top: 10px

    .fw-button--little
        padding: 10px 24px 10px
</style>
