import { User, UserHeaders } from '@/types/User';
import i18n from '@/locale';
import { AccountType } from '@/types/Account';
import {
    HUB_KEY,
    USER_LS_KEY,
    ACCOUNT_LS_KEY,
    USER_HEADERS_KEY,
    LOCALE_LS_KEY,
    SASS_FLOW_NAME,
} from '@/helpers/consts';

const setUserToStorage = (user: User): void => {
    window.localStorage.setItem(USER_LS_KEY, JSON.stringify(user));
};

const setAccountToStorage = (account: AccountType): void => {
    window.localStorage.setItem(ACCOUNT_LS_KEY, JSON.stringify(account));
};

const setStorageLocale = (value: string): void => {
    window.localStorage.setItem(LOCALE_LS_KEY, value);
};
const setXHeadersLocale = (user: User): void => {
    const headersData = { 'auth_token': user.authentication_token, 'auth_email': user.email };
    window.localStorage.setItem(USER_HEADERS_KEY, JSON.stringify(headersData));
};

const getStorageXHeaders = (): null | UserHeaders => {
    const user = window.localStorage.getItem(USER_HEADERS_KEY);
    return user ? JSON.parse(user) : null;
};
const getStorageLocale = (): string | null => {
    return window.localStorage.getItem(LOCALE_LS_KEY);
};

const getUserFromStorage = (): null | User => {
    const user = window.localStorage.getItem(USER_LS_KEY);
    return user ? JSON.parse(user) : null;
};

const getAccountFromStorage = (): null | AccountType => {
    const account = window.localStorage.getItem(ACCOUNT_LS_KEY);
    return account ? JSON.parse(account) : null;
};

const removeUserFromStorage = (): void => {
    window.localStorage.removeItem(USER_LS_KEY);
    window.localStorage.removeItem(ACCOUNT_LS_KEY);
    window.localStorage.removeItem(SASS_FLOW_NAME);
    window.localStorage.removeItem(HUB_KEY);
    i18n.locale = navigator.language;
};

const removeHeadersFromStorage = (): void => {
    window.localStorage.removeItem(USER_HEADERS_KEY);
    i18n.locale = navigator.language;
};

const isUserAuth = (store: any): boolean => Boolean(store.getters['GlobalStore/user'].authentication_token || store.getters['GlobalStore/user'].account_id);

const user = (store: any): User => store.getters['GlobalStore/user'];

export {
    setUserToStorage,
    getAccountFromStorage,
    setXHeadersLocale,
    getStorageXHeaders,
    getUserFromStorage,
    removeUserFromStorage,
    removeHeadersFromStorage,
    isUserAuth,
    user,
    setStorageLocale,
    getStorageLocale,
    setAccountToStorage,
};
