export default {
    'WORKER.FORM.FIRST.NAME.LABEL': 'Prénom',
    'WORKER.FORM.FIRST.NAME.PLACEHOLDER': 'ex: John',

    'WORKER.FORM.LAST.NAME.LABEL': 'Nom',
    'WORKER.FORM.LAST.NAME.PLACEHOLDER': 'ex: Doe',

    'WORKER.FORM.SKILLS.LABEL': 'Compétences',
    'WORKER.FORM.SKILLS.PLACEHOLDER': 'Veuillez sélectionner une option',

    'WORKER.FORM.COMPANY.LABEL': 'Société',
    'WORKER.FORM.COMPANY.PLACEHOLDER': 'Sélectionnez une entreprise',

    'WORKER.FORM.PHONE.LABEL': 'Téléphone',
    'WORKER.FORM.PHONE.PLACEHOLDER': 'ex: 0620394762',

    'WORKER.FORM.EMAIL.LABEL': 'Email',
    'WORKER.FORM.EMAIL.PLACEHOLDER': 'ex: adresse@email.com',

    'WORKER.FORM.ADMIN.LABEL': 'Admin de sa société',
    'WORKER.FORM.HUB.ADMIN.LABEL': 'Administrateur du hub',
    'WORKER.FORM.ADMIN.NO': 'NON',
    'WORKER.FORM.ADMIN.YES': 'OUI',

    'WORKER.FORM.ZONE.LABEL': 'Zone géographique',
    'WORKER.FORM.ZONE.PLACEHOLDER': 'Sélectionnez une zone',

    'WORKER.FORM.HUB.LABEL': 'Hub',
    'WORKER.FORM.HUB.PLACEHOLDER': 'Sélectionner un hub',
    'WORKER.FORM.HUB.MEMBERSHIP': 'Adhésion à un hub',

    'WORKER.FORM.BRANCH.LABEL': 'Agence',
    'WORKER.FORM.BRANCH.PLACEHOLDER': 'Choisissez l\'agence',
    'WORKER.FORM.BRANCH.MEMBERSHIP': 'Adhésion à une agence',

    'WORKER.FORM.REQUIRED.FIELDS': 'Champs obligatoires',
    'WORKER.FORM.REQUIRED.ONE.OF.TWO.FIELDS': 'Veuillez remplir au moins un de ces champs',

    'WORKERS.LIST.HEADER': 'Ressources',
    'WORKER.LIST.NEXT.STEP': 'Vous pouvez ajouter, modifier ou supprimer des ',
    'WORKER.LIST.NEXT.STEP2': 'ou passer à l’étape suivante',

    'WORKER.HOURS.SET': 'Paramétrez ici leshoraires de travail de',

    'WORKER.FORM.GEOGRAFIC.TITLE': 'Pays entier',
    'WORKER.FORM.GEOGRAFIC.SELECT.PLACEHOLDER': 'Sélectionner un département/une ville',
    'WORKER.FORM.GEOGRAFIC.SELECT.DISTRICT': 'Sélectionner un département',
    'WORKER.FORM.GEOGRAFIC.SEARCH.PLACEHOLDER': 'Recherche par code postal',
    'WORKER.FORM.GEOGRAFIC.SELECT.ENTIRE.DISTRICT': 'Sélectionner le département entier',
};
