import { ProfileIndexPage } from '@/views/profile/ProfileIndexPage';

const profileRoutes: any = [
    {
        path: '/profile',
        name: ProfileIndexPage.name,
        component: ProfileIndexPage,
        meta: {
            showAppHeader: true,
            showAppHeaderProgress: true,
        },
    },
];

export default profileRoutes;
