<template>
<div v-if='workingTimeValue.length !== 0' class='b-blue-background b-blue-background__opacity b-cancel-popup__select b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        :style='{
            width: popupWidth,
            padding: `0 30px`
        }'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        submitText='BUTTON.ADD'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("closePopup")'>
        <template #body>
            <OnboardingWorkingHoursPage
                :currentWorkerId='currentWorkerId'
                :isRequestSending='isRequestSending'
                @onSubmit='sendDataToServer'
                @confirmWorkingHours='confirmWorkingHours'>
            </OnboardingWorkingHoursPage>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import {
    WorkerTimeType,
    WorkerScheduleTimeType,
} from '@/types/Workers';
import { WORKER_SCHEDULE_DATA } from '@/helpers/workers';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { OnboardingWorkingHoursPage } from '@/views/onboarding/OnboardingWorkingHoursPage';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        OnboardingWorkingHoursPage,
    },
})

export default class WorkingHoursWorkersListPopup extends Vue {
    @WorkerPageStore.Mutation('setWorkerIds') setWorkerIds!: Function;
    @Prop({ type: Boolean, default: false }) readonly currentWorker!: boolean;
    @Prop({ type: String, default: null }) readonly currentWorkerId!: string;
    savePopup: boolean = false;
    showPopup: boolean = false;
    loading: boolean = true;
    hoursPopup: boolean = true;
    workingTimeValue: WorkerTimeType = WORKER_SCHEDULE_DATA;
    showFinalePopup: boolean = false;
    isRequestSending: boolean = false;
    get popupWidth() {
        return this.isDesktop() ? `auto` : `100%`;
    }
    confirmWorkingHours() {
        this.$emit('closePopup', this.currentWorkerId);
    }
    async sendDataToServer(data: WorkerScheduleTimeType) {
        this.isRequestSending = true;
        try {
            const scheduleData = await WorkersWebApi.confirmAdvisorSchedulesData(data, this.currentWorkerId);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.CONFIRMED', true);
            this.setWorkerIds(this.currentWorkerId);
            this.confirmWorkingHours();
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
}
</script>
<style lang='sass'>
    .mx-calendar-content
        .mx-panel-year
            .cell.actived
                margin: 1px 5%!important
        .mx-panel-month
            .cell.actived
                margin: 1px 1%!important
</style>

