export default {
    'HUB.PLACEHOLDER.SEARCH': 'Rechercher un hub',
    'HUB.ADVISORS.NUMBER': 'Nombre de ressources',
    'HUB.POPUP.TITLE.ADD': 'Ajouter un hub',
    'HUB.POPUP.TITLE.EDIT': 'Modifier un hub',

    'HUB.FORM.LABEL': 'Administrateur du hub',
    'HUB.FORM.DELETE.POPUP.TITLE': 'Supprimer hub',
    'HUB.FORM.DELETE.POPUP.BUTTON': 'Supprimer ce hub',
    'HUB.FORM.DELETE.POPUP.TEXT': 'Voulez-vous vraiment supprimer ce hub ?',
    'HUB.FORM.CREATE.POPUP.TEXT': 'Hub a bien été ajoutée',
    'HUB.FORM.UPDATE.POPUP.TEXT': 'Hub a été mise à jour',
};
