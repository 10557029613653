<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        class='b-filters-popup'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mb-20'>
                    {{ $t('CALENDAR.FILTER.TITLE') }}
                </h2>
                <div class='h-width-100p'>
                    <h3 class='h-font-20 h-mb-20'>
                        {{ $t('CALENDAR.FILTER.BY') }}
                    </h3>
                    <div class='h-mh--15 h-flex b-filters-popup__skills'>
                        <FwFormInput
                            labelType='label-medium'
                            class='h-flex-1-0 h-mh-15'
                            :title='`${$t(`CALENDAR.FILTER.SKILLS`)}`'>
                            <div class='fw-select-base fw-select-white-modern'>
                                <FwSelect
                                    v-model='currentSkills'
                                    class='fw-select-with-icon--big'
                                    type='white'
                                    :propsPlaceholder='`${$t(`CALENDAR.FILTER.SKILLS`)}`'
                                    :searchable='true'
                                    :multiple='true'
                                    :label='labelKey'
                                    :clearable='false'
                                    :withCircles='true'
                                    :canNotDelete='false'
                                    :options='skills'>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                        <div class='h-flex-1-0 h-mh-15'></div>
                    </div>
                </div>
                <div class='h-width-100p h-pb-25'>
                    <h3 class='h-font-20 h-mb-20'>
                        {{ $t('CALENDAR.FILTER.SORT_BY') }}
                    </h3>
                    <div class='h-mh--15 h-flex'>
                        <FwFormInput
                            labelType='label-medium'
                            class='h-flex-1-0 h-mh-15'
                            :title='`${$t(`CALENDAR.FILTER.SORTING_VARIABLE`)}`'>
                            <div class='fw-select-base fw-select-white-modern'>
                                <FwSelect
                                    v-model='currentSort'
                                    class='fw-select-with-icon--big'
                                    type='white'
                                    :propsPlaceholder='`${$t(`CALENDAR.FILTER.SORTING_VARIABLE`)}`'
                                    :searchable='true'
                                    :multiple='false'
                                    :label='labelKey'
                                    :clearable='true'
                                    :withCircles='true'
                                    :canNotDelete='false'
                                    :options='sortValues'>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                        <FwFormInput
                            labelType='label-medium'
                            class='h-flex-1-0 h-mh-15'
                            :title='`${$t(`CALENDAR.FILTER.SORTING_ORDER`)}`'>
                            <div class='fw-select-base fw-select-white-modern'>
                                <FwSelect
                                    v-model='currentSortType'
                                    class='fw-select-with-icon--big'
                                    type='white'
                                    :propsPlaceholder='`${$t(`CALENDAR.FILTER.SORTING_ORDER`)}`'
                                    :searchable='true'
                                    :multiple='false'
                                    :label='labelKey'
                                    :clearable='true'
                                    :withCircles='true'
                                    :canNotDelete='true'
                                    :options='sortTypes'>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                    </div>
                </div>
                <div>
                    <FwButton
                        class='h-margin-0-auto'
                        borderRadiusType='tiny-border'
                        :disabled='isButtonDisabled'
                        @click='updateFilters'>
                        {{ $t('CALENDAR.FILTER.APPLY') }}
                    </FwButton>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { clone } from 'ramda';
import { namespace, State } from 'vuex-class';
import { SkillType } from '@/types/Filters';
import { sortValues, sortTypes } from '@/helpers/skills';
import TranslateMixin from '@/mixins/TranslateMixin';
import { FiltersSettingsType } from '@/types/Calendar';
import { isEqual } from '@/helpers/base';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { CategorySkillNameType } from '@/types/Appointment';

const CommonStore = namespace('CommonStore');

@Component
export default class FiltersPopup extends Mixins(TranslateMixin) {
    @CommonStore.Mutation('setSkills') setSkills!: (skills: Array<CategorySkillNameType>) => void;
    @State(state => state.CommonStore.skills) skills!: Array<CategorySkillNameType>;

    @Prop({ type: Object, required: true }) readonly filtersSettings!: FiltersSettingsType;

    currentSkills: Array<CategorySkillNameType> = [];

    sortValues: Array<SkillType> = sortValues;
    currentSort: null | SkillType = null;

    sortTypes: Array<SkillType> = sortTypes;
    currentSortType: SkillType = sortTypes[0];

    startFiltersState: FiltersSettingsType = { currentSkills: [], currentSort: null, currentSortType: sortTypes[0] };

    get isButtonDisabled(): boolean {
        return isEqual(this.currentFiltersState, this.startFiltersState);
    }

    get currentFiltersState(): FiltersSettingsType {
        return {
            currentSkills: this.currentSkills,
            currentSort: this.currentSort,
            currentSortType: this.currentSortType,
        };
    }

    updateFilters() {
        this.$emit('updateFilters', this.currentFiltersState);
        this.$emit('close');
    }

    async created(): Promise<void> {
        if (!this.skills.length) {
            const data = await AppointmentWebApi.fetchSkillNames();
            this.setSkills(data.skill_names);
        }
        this.startFiltersState = clone(this.filtersSettings);
        this.currentSkills = this.filtersSettings.currentSkills;
        this.currentSort = this.filtersSettings.currentSort;
        this.currentSortType = this.filtersSettings.currentSortType;
    }
}
</script>

<style lang='sass'>
.b-filters-popup
    width: 840px !important
    color: #213F6B

    input
        z-index: -1

    &__skills
        max-height: calc(100% - 30px)
</style>
