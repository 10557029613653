<template>
<div class='h-flex h-flex-space-between h-align-items-center'>
    <div v-for='title in titles'
         class='h-width-100 h-text-center h-flex-center'>
        <FwSwitcher
            class='h-width-40'
            :isActive='rights[title.key]'
            :disabled='disabled[title.key] || disabledByPermission'
            @toggleSwitch='toggleSwitch(title.key)'>
        </FwSwitcher>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { clone } from 'ramda';
import { RoleRightType } from '@/types/Roles';
import { RightKeyType } from '@/types/Rights';

@Component
export default class SettingsRolesRow extends Vue {
    @Prop({ type: Object, required: true }) readonly rights!: RoleRightType;
    @Prop({ type: Object, required: true }) readonly disabled!: RoleRightType;
    // only for debugging
    @Prop({ type: String, required: true }) readonly rightKey!: string;
    @Prop({ type: Boolean, default: false }) readonly disabledByPermission!: boolean;
    @Prop({ type: Array, required: true }) readonly titles!: Array<{ title: string, key: RightKeyType }>

    toggleSwitch(key: string) {
        const rights = clone(this.rights);
        // @ts-ignore-next-line
        rights[key] = !rights[key];
        this.rightsHandler(rights);
    }

    @Emit('rightUpdate')
    rightsHandler(rights: RoleRightType): RoleRightType {
        return rights;
    }
}
</script>
