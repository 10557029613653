import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { AccountApi } from '@/api/account/AccountApi';
import { AccountTitlesType, SubAccountType } from '@/types/Account';
import { CategorySkillNameType } from '@/types/Appointment';
import { ACCOUNT_LS_KEY } from '@/helpers/consts';

const EMPTY_ACCOUNT_TITLES: AccountTitlesType = {
    account: {
        id: '',
        internal_name: '',
        name: '',
        url_builder_support: false,
        client_api_support: false,
        plan: '',
        licence: '',
        current_month_email_count: 0,
        current_month_sms_count: 0,
        sub_accounts: [],
        geo_scope: '',
        service_point_support: false,
        country_iso_code: '',
    },
    appointment_attributes: [],
    cancellation_origins: [],
    cancellation_reasons: [],
    unavailability_titles: [],
};

@Module({
    namespaced: true,
})
class CommonStore extends VuexModule {
    isFixedBgOpen: boolean = false;
    hideBgStatus: boolean = true;
    progressBarKey: number = 1;
    accountData: AccountTitlesType = EMPTY_ACCOUNT_TITLES;
    skills: Array<CategorySkillNameType> = [];

    @Mutation
    setAccountTitles(titles: AccountTitlesType) {
        this.accountData = titles;
        window.localStorage.setItem(ACCOUNT_LS_KEY, JSON.stringify(titles));
    }

    @Mutation
    setFixedBgStatus(value: boolean) {
        this.isFixedBgOpen = value;
    }

    @Mutation
    setHideBgStatus(value: boolean) {
        this.hideBgStatus = value;
    }

    @Mutation
    updateProgressBar() {
        this.progressBarKey += 1;
    }

    @Mutation
    setSkills(skills: Array<CategorySkillNameType>) {
        this.skills = skills;
    }

    @Action({ commit: 'setAccountTitles' })
    async fetchAccountTitles(): Promise<AccountTitlesType> {
        try {
            return await AccountApi.fetchAccountTitles();
        } catch (e) {
            console.log(e);
        }
        return EMPTY_ACCOUNT_TITLES;
    }

    get isFixedBg(): boolean {
        return this.isFixedBgOpen;
    }

    get hideFixedBgStatus(): boolean {
        return this.hideBgStatus;
    }

    get subAccounts(): Array<SubAccountType> {
        return this.accountData.account.sub_accounts;
    }
}

export default CommonStore;
