export default {
    'CATEGORY.LEGAL.ADVISE': 'Legal Advise',
    'CATEGORY.INDIVIDUAL.INSURANCE': 'Individual Insurance',
    'CATEGORY.PROFESSIONAL.INSURANCE': 'Professional Insurance',
    'CATEGORY.HEALTH.INSURANCE': 'Health Insurance',
    'CATEGORY.ASSET.MANAGEMENT': 'Asset Management',
    'CATEGORY.PRO.1A': 'Pro 1 à 2',
    'CATEGORY.PRO.3A': 'Pro 3 à 9',
    'CATEGORY.PRO.+9': 'Pro +9',

    'CATEGORY.AGENCES': 'Agences',
    'CATEGORY.CHARGE': 'Chargé d\'Affaires Entreprise',

    'CATEGORY.TECHNICAL.MISSION': 'Technical mission',

    'CATEGORY.EXPERTISE': 'Expertise',
    'CATEGORY.ADMINISTRATIVE': 'Administrative',
    'CATEGORY.ABSENCE': 'Absence',
};
