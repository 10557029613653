const BOOKED_GROUP = 'BRANCH.STATISTICS.BOOKED';
const BOOKED_GROUP_KEY = 'booking';
const CANCELLING_GROUP_KEY = 'cancelling';
const CANCELLED_GROUP = 'BRANCH.STATISTICS.CANCELLED';
const HUB_TYPE = 'hub';
const BRANCH_TYPE = 'branch';

const STATISTICS_KEY_MAPPING = {
    [BOOKED_GROUP]: BOOKED_GROUP_KEY,
    [CANCELLED_GROUP]: CANCELLING_GROUP_KEY,
};

const STATISTICS_ICON_MAPPING = {
    [BOOKED_GROUP]: `booked`,
    [CANCELLED_GROUP]: `cancelled`,
};

export {
    HUB_TYPE,
    BRANCH_TYPE,
    BOOKED_GROUP,
    BOOKED_GROUP_KEY,
    CANCELLED_GROUP,
    CANCELLING_GROUP_KEY,
    STATISTICS_KEY_MAPPING,
    STATISTICS_ICON_MAPPING,
};
