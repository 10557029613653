<template>
<div class='h-pos-rel'
     :class='{ "b-settings-nav__wrapper--open": isOpen }'>
    <div class='b-settings-nav'
         :class='{ "b-settings-nav--accordion": accordionType, "b-settings-nav--open": isOpen }'>
        <div v-if='title'
             class='b-settings-nav__title'>
            {{ title }}
        </div>
        <div v-if='asideText'
             class='b-settings-nav__branch-name'>
            {{ asideText }}
        </div>
        <div class='b-settings-nav__link-wrapper'>
            <template v-if='accordionType'>
                <div v-for='(item, index) in preparedAccordionLinks'
                     :key='index'
                     class='h-pb-15'>
                    <div class='h-flex'>
                        <FwIcon
                            class='b-settings-nav__group__main-icon'
                            :icon='$options.STATISTICS_ICON_MAPPING[item.title]'
                            size='22'
                            color='#213F6B'>
                        </FwIcon>
                        <div class='b-settings-nav__group__title'
                             :class='{ "b-settings-nav__group__title--active": accordionActiveIndexes.includes(index) }'
                             @click='updateAccordion(index)'>
                            <span>
                                {{ $t(item.title) }}
                            </span>

                            <FwIcon
                                class='b-settings-nav__group__icon'
                                icon='arrow-bottom'
                                size='17'
                                color='#203f6a'>
                            </FwIcon>
                        </div>
                    </div>
                    <template v-if='accordionActiveIndexes.includes(index)'>
                        <SettingsNavLink
                            v-for='link in item.links'
                            :key='link.path'
                            :class='{ "b-settings-nav__group__link--active": $route.meta.text === link.text }'
                            :link='link'>
                        </SettingsNavLink>
                    </template>
                </div>
            </template>
            <template v-else>
                <SettingsNavLink
                    v-for='link in links'
                    :key='link.path'
                    :link='link'>
                </SettingsNavLink>
            </template>
        </div>

        <div v-if='showBackButton'
             class='b-settings-nav__button'>
            <FwButton
                size='small'
                borderRadiusType='small-border'
                color='blue'
                class='h-mt-20'
                @click='$router.go(-1)'>
                {{ $t('BUTTON.BACK') }}
            </FwButton>
        </div>
    </div>
    <div v-if='isMobile()'
         class='b-settings-nav__arrow'
         @click='isOpen = !isOpen'>
        <FwIcon
            icon='arrow-right'
            size='24px'
            color='#fff'>
        </FwIcon>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { uniq } from 'ramda';
import { UserManagerProfileRole } from '@/types/User';
import { RouteType } from '@/types/router';
import { SettingsNavLinkType } from '@/types/Settings';
import SettingsNavLink from '@/components/common/SettingsNav/SettingsNavLink.vue';
import { STATISTICS_ICON_MAPPING } from '@/routes/planAppointment/consts';

const WorkerPageStore = namespace('WorkerPageStore');

const filterLinkByRights = (item: RouteType, role: UserManagerProfileRole): boolean => {
    // @ts-ignore-next-line
    // eslint-disable-next-line
    return !Boolean(!item.meta!.rightBlock || role[item.meta!.rightBlock][item.meta!.right]);
};

@Component({
    STATISTICS_ICON_MAPPING,
    components: {
        SettingsNavLink,
    },
})
export default class SettingsNav extends Vue {
    @Prop({ type: Array, required: true }) readonly routes!: Array<RouteType>;
    @Prop({ type: String, default: null }) readonly title!: string | null;
    @Prop({ type: String, default: '' }) readonly asideText!: string;
    @Prop({ type: Boolean, default: false }) readonly showBackButton!: boolean;
    @Prop({ type: Boolean, default: false }) readonly accordionType!: boolean;
    @Prop({ type: String, required: true }) readonly type!: `branch` | `hub`;
    @Prop({ type: Object, default: null }) readonly updatePathParams!: { value: string, field: string };

    links: Array<SettingsNavLinkType> = [];
    accordionActiveIndexes: Array<number> = [];
    isOpen: boolean = false;

    get preparedAccordionLinks(): Array<{ links: Array<RouteType>, title: string }> {
        const routes = this.routes.filter((item: RouteType) => item.meta!.text && !item.meta!.hide);
        const groups = uniq(routes.map(item => item.meta!.linkGroupName));
        const data: Array<{ links: Array<RouteType>, title: string }> = [];
        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            data.push({ title: group, links: this.prepareLinks(routes.filter(item => item.meta!.linkGroupName === group), this.updatePathParams) });
        }
        return data;
    }

    updateAccordion(index: number): void {
        if (this.accordionActiveIndexes.includes(index)) {
            this.accordionActiveIndexes.splice(this.accordionActiveIndexes.findIndex(ind => ind === index), 1);
        } else {
            this.accordionActiveIndexes.push(index);
        }
    }

    prepareLinks(links: Array<RouteType>, updatePathParams?: { value: string, field: string }): Array<SettingsNavLinkType> {
        return links.map(item => ({
            path: updatePathParams ? (item.path as string).replace(`/:${updatePathParams.field}/`, `/${updatePathParams.value}/`) : (item.path as string),
            icon: (item.meta!.icon as string),
            text: (item.meta!.text as string),
            name: (item.name as string),
            disabled: this.currentUserRole ? filterLinkByRights(item, this.currentUserRole) : true,
        }));
    }

    @Watch('$route')
    routeChange() {
        this.isOpen = false;
    }

    created() {
        // @ts-ignore-next-line
        this.links = this.prepareLinks(this.routes.filter((item: RouteType) => item.meta!.text && !item.meta!.hide), this.updatePathParams);
        for (let i = 0; i <= this.preparedAccordionLinks.length; i++) {
            this.accordionActiveIndexes.push(i);
        }
    }
}
</script>

<style lang='sass'>
.b-settings-nav
    width: 410px
    background-color: #fff
    height: calc(100vh - 90px)
    padding: 37px 0 37px 64px
    flex: 0 0 410px
    overflow-y: auto

    &__wrapper--open
        .b-settings-nav__arrow
            z-index: 51

            svg
                transform: rotate(180deg)

    &__button
        display: flex
        align-items: center
        justify-content: center
        margin-top: -20px

        @include media('<=phone')
            margin-top: -10px
            top: 0px
            right: 10px
            position: absolute

    .b-settings-nav__arrow
        display: none

    @include media('<=phone')
        position: fixed
        left: 0
        z-index: 1
        padding: 10px
        width: calc(100vw - 20px)
        transform: translate(-100%, 0)
        overflow: visible
        top: 0
        z-index: 50
        height: 100vh

        span
            display: flex

        &--open
            transform: none
            overflow-y: auto !important

            .b-settings-nav__arrow
                left: 20px

                svg
                    transform: rotate(180deg)
                    z-index: 51

        &__arrow
            display: block !important
            left: 20px
            bottom: 20px
            position: fixed
            padding: 10px 12px
            border-radius: 8px
            display: flex
            align-items: center
            justify-content: center
            cursor: pointer
            background-color: #213F6B

        &:before
            content: ''
            right: 0
            width: 20px
            top: 0
            height: 100%
            background-color: rgba(0, 0, 0, 0.7)
            position: fixed

    &__branch-name
        font-size: 12px
        text-transform: uppercase
        margin-bottom: 24px
        font-weight: 500

    &--accordion
        .b-settings-nav__text
            border: none !important

        .b-settings-nav__link
            padding-left: 30px

    &__title
        margin-bottom: 8px
        font-size: 22px
        font-weight: bold

    &__group
        &__link--active
            opacity: 1 !important

        &__title
            font-size: 16px
            font-weight: bold
            line-height: 23px
            color: #213F6B
            display: flex
            align-items: center
            cursor: pointer
            transition: opacity 0.3s
            &:hover
                opacity: 0.8

            &:not(.b-settings-nav__group__title--active)
                .b-settings-nav__group__icon
                    transform: rotate(180deg)

        &__icon
            display: flex
            align-items: center
            margin-left: 20px
            margin-bottom: 3px
            transition: transform 0.4s

        &__main-icon
            margin-right: 10px

.b-settings-router-view-wrapper
    @include media('<=phone')
        padding: 20px 15px !important
        overflow: visible
        min-height: 100vh

        .fw-menu
            flex-direction: row
            width: 100%
            padding: 0
            margin: 20px 0 -20px 0
            position: static

    .fw-pagination
        position: fixed
        width: calc(100% - 400px)
        right: 0
        left: auto
</style>
