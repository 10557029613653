export default {
    'SELECT.SERVICES_POINT.PLURAL.TITLE': 'Permanences',
    'SELECT.SERVICES_POINT.ONE.TITLE': 'Permanence',
    'SERVICES_POINT.ADVISOR.NUMBER': 'Nombre de conseillers',
    'SERVICES_POINT.PLACEHOLDER.SEARCH': 'Rechercher une permanence',
    'SERVICES_POINT.TITLE.ADD': 'Ajouter une permanence',
    'SERVICES_POINT.TITLE.EDIT': 'Modifier une Permanence',
    'SERVICES_POINT.FORM.DELETE.POPUP.TITLE': 'Supprimer cette permanence',
    'SERVICES_POINT.FORM.DELETE.POPUP.TEXT': 'Souhaitez-vous vraiment supprimer cette permamence ?',
    'SERVICES_POINT.FORM.CREATE.POPUP.TEXT': 'La permanence a été ajoutée',
    'SERVICES_POINT.FORM.UPDATE.POPUP.TEXT': 'La permanence a été modifiée',
    'SERVICES_POINT.DELETE.TXT': 'SUPPRIMER CETTE PERMANENCE',
    'SERVICES_POINT.SHIFT.TXT': 'Shifts',
    'SERVICES_POINT.SHIFTS.DELETE.TITLE': 'Supprimer un shift',
    'SERVICES_POINT.SHIFTS.DELETE.ALL.FUTURE': 'Supprimer tous les futurs shifts',
    'SERVICES_POINT.SHIFTS.DELETE.ONLY_THIS': 'Supprimer ce shift',
    'SERVICES_POINT.SHIFTS.DELETE.ALL': 'Supprimer tous les shifts',
    'SERVICES_POINT.SHIFTS.DELETE.ASIDE_RECURRENCE': 'Etes-vous certain de vouloir supprimer ce shift? ' +
        'C\'est un shift récurrent',
    'SERVICES_POINT.SHIFTS.DELETE.ASIDE': 'Souhaitez-vous vraiment supprimer ce shift ?',
    'SERVICES_POINT.SHIFTS.DELETE.CHOOSE.TITLE': 'Consulter l\'équipe',
    'SERVICES_POINT.SHIFTS.FIND_ADVISOR': 'Trouver un conseiller',
};
