const COMPANY_NAME: string = 'company_name';
const ADDRESS: string = 'address';
const ADDRESS_1: string = 'address_1';
const ADDRESS_2: string = 'address_2';
const ZIP_CODE: string = 'zip_code';
const CODE_INSEE: string = 'insee_code';
const CITY: string = 'city';
const SIREN: string = 'siren';
const SIRET: string = 'siret';
const PHONE_NUMBER: string = 'phone_number';
const EMAIL: string = 'email';

export {
    COMPANY_NAME,
    ADDRESS,
    ADDRESS_1,
    ADDRESS_2,
    ZIP_CODE,
    CODE_INSEE,
    CITY,
    SIREN,
    SIRET,
    PHONE_NUMBER,
    EMAIL,
};
