<template>
<div class='b-form-attributes qa-b-form-attributes'>
    <component
        :is='getAttributeType(schema.type)'
        v-for='(schema, index) in schemas'
        :key='schema.localized_name'
        :schema='schema'
        :title='schema[currentLangKey]'
        :disabled='disabled || !clearable'
        :clearable='clearable'
        :isLoading='isLoadingAttributes'
        class='b-form-attributes__attribute qa-b-form-attributes__attribute'
        :class='`qa-${schema.searchable_name}`'
        @change='onSchemaChange($event, index)'>
    </component>

    <div v-if='isLoadingAttributes'
         class='b-form-attributes__overlay'>
        <FwSpinLoader
            :isActive='true'>
        </FwSpinLoader>
    </div>
</div>
</template>

<script lang='ts'>
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { InputAttribute } from '@/components/forms/attributes/InputAttribute';
import { ATTRIBUTE_TYPE_MAP, ATTRIBUTE_TYPES } from '@/components/forms/attributes/helpers';
import { insert } from '@/helpers/utils';
import { AttributeExtendedType } from '@/types/Attribute';
import { EN_LOCAL } from '@/helpers/locale';

@Component({
    model: {
        prop: `schemas`,
        event: `change`,
    },
    components: {
        InputAttribute,
    },
})
export default class FormAttributes extends Vue {
    @Prop({ type: Array, default: () => [] }) schemas!: Array<AttributeExtendedType>;
    @Prop({ type: Boolean, default: false }) disabled!: boolean;
    @Prop({ type: Boolean, default: true }) clearable!: boolean;

    isLoadingAttributes: boolean = false;
    predefinedValuesTestMap: { [key: string]: { value: string | number | null; settled: boolean; } } = {};

    get currentLangKey(): `en_name` | `localized_name` {
        return this.$i18n.locale === EN_LOCAL ? `en_name` : `localized_name`;
    }

    async onSchemaChange(newSchema: AttributeExtendedType, index: number): Promise<void> {
        const { title, value } = newSchema;
        const isNamePredefined = title && Object.keys(this.predefinedValuesTestMap).includes(title);
        const isValueSettled = isNamePredefined && this.predefinedValuesTestMap[title].settled;
        if (isValueSettled) {
            // @ts-ignore
            this.emitChangePredefinedValues({
                attribute_name: title,
                generated_value: this.predefinedValuesTestMap[title].value,
                new_value: value || null,
            });
        } else if (isNamePredefined) {
            this.predefinedValuesTestMap[title].settled = true;
        }
        // @ts-ignore
        const newSchemas: Array<AttributeExtendedType> = insert(index, newSchema, this.schemas);
        this.emitChangeEvent(newSchemas);
    }

    getAttributeType(inputType: string | null): string {
        if (!inputType) return ATTRIBUTE_TYPE_MAP[ATTRIBUTE_TYPES.INPUT];
        // @ts-ignore
        return ATTRIBUTE_TYPE_MAP[inputType];
    }

    @Emit(`change`)
    emitChangeEvent(newSchemas: Array<AttributeExtendedType>): void {}

    @Emit(`log-change-predefined-values`)
    emitChangePredefinedValues(values: { attribute_name: string; generated_value: string | number | null; new_value: string | null; }): void {}
}
</script>

<style lang='sass'>
.b-form-attributes
    position: relative
    display: flex
    flex-wrap: wrap

    &__overlay
        position: absolute
        top: -5px
        left: 0
        width: 100%
        height: 100%
        background-color: #fff
        opacity: .5
        z-index: 7
        display: flex
        align-items: center
        justify-content: center

    .b-field-tips-popup
        margin-top: -10px
        margin-bottom: 25px
</style>
