export default {
    'REMINDERS.MAX': 'Rappels (max 2 rappels)',
    'REMINDERS.BOOKING': 'Réservation',
    'REMINDERS.UPDATE': 'Modification',
    'REMINDERS.CANCELLATION': 'Annulation',
    'REMINDERS.REMINDER': 'Rappel',

    'REMINDERS.2.HOURS': '2 heures avant',
    'REMINDERS.1.DAY': '1 jour avant',
    'REMINDERS.2.DAYS': '2 jours avant',
    'REMINDERS.1.WEEK': '1 semaine avant',
    'REMINDERS.FINAL.CUSTOMER': 'Client final',
};
