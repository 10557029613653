import login from './login';
import resetPassword from './reset-password';
import logout from './logout';
import createPassword from './create-password';
import forgetPassword from './forget-password';
import linkSent from './link-sent';

export default {
    ...login,
    ...resetPassword,
    ...logout,
    ...createPassword,
    ...forgetPassword,
    ...linkSent,
};
