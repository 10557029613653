import { SkillType } from '@/types/Filters';

const sortValues: Array<SkillType> = [
    {
        'en_name': 'First name',
        'fr_name': 'Prénom',
        'searchable_name': 'first_name',
    },
    {
        'en_name': 'Last name',
        'fr_name': 'Nom',
        'searchable_name': 'last_name',
    },
];

const sortTypes: Array<SkillType> = [
    {
        'en_name': 'Ascending',
        'fr_name': 'Ascendant',
        'searchable_name': 'ascending',
    },
    {
        'en_name': 'Descending',
        'fr_name': 'Descendant',
        'searchable_name': 'descending',
    },
];

export {
    sortValues,
    sortTypes,
};
