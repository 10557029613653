<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <img class='h-ph-50' :src='requireImage("error.svg")'>
                <h2 class='h-font-22 h-mt-20 h-mb-0'>
                    {{ $t('CONSULT.ADD.POPUP.ERROR.TITLE') }}
                </h2>
                <p v-if='text'
                   class='h-mt-10 h-mb-20 h-text-center'>
                    {{ text }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-20'
                    borderRadiusType='small-border'
                    @click='$emit("close")'>
                    {{ buttonText }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class EditPopup extends Vue {
    @Prop({ type: String, default: null }) readonly text!: string;
    @Prop({ type: String, required: true }) readonly buttonText!: string;
}
</script>
