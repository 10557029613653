function capitalize(str: string): string {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

function capitalizeAll(str: string) {
    if (!str) return '';
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export {
    capitalize,
    capitalizeAll,
};
