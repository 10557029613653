export default {
    'RECAP.TITLE': 'Résumé',
    'RECAP.SUBTITLE': 'Veuillez vérifier les informations ci-dessous avant de confirmer.',
    'RECAP.KIND.LABEL': 'Les rendez-vous seront planifiés avec des personnes :',
    'RECAP.KIND.INDIVIDUAL.COMPANY': 'De mon entreprise',
    'RECAP.KIND.INDIVIDUAL.PARTNER': 'D\'entreprises partenaires',

    'RECAP.KIND.ORGANIZATION.LABEL': 'Mon organisation est composée :',
    'RECAP.KIND.ORGANIZATION.HUB': 'De hub',
    'RECAP.KIND.ORGANIZATION.BRANCH': 'D\\\'agence',
    'RECAP.KIND.ORGANIZATION.HUB.BRANCH': 'De hub et d\\\'agence',
    'RECAP.KIND.ORGANIZATION.APPT.TYPES': 'Types de rendez-vous',
    'RECAP.KIND.ORGANIZATION.HUBS': 'Hubs',
    'RECAP.KIND.ORGANIZATION.BRANCHES': 'Agences',
    'RECAP.KIND.ORGANIZATION.ADVISORS': 'Conseillers',

    'RECAP.YOU.HAVE.ADDED': 'Vous avez ajouté',
};
