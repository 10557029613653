export default {
    'CATEGORY.LEGAL.ADVISE': 'Conseil Juridique',
    'CATEGORY.INDIVIDUAL.INSURANCE': 'Assurance particulier',
    'CATEGORY.PROFESSIONAL.INSURANCE': 'Assurance professionnelle',
    'CATEGORY.HEALTH.INSURANCE': 'Assurance santé',
    'CATEGORY.ASSET.MANAGEMENT': 'Gestion patrimoniale',
    'CATEGORY.PRO.1A': 'Pro 1 à 2',
    'CATEGORY.PRO.3A': 'Pro 3 à 9',
    'CATEGORY.PRO.+9': 'Pro +9',

    'CATEGORY.AGENCES': 'Agences',
    'CATEGORY.CHARGE': 'Chargé d\'Affaires Entreprise',

    'CATEGORY.TECHNICAL.MISSION': 'Mission technique',
    'CATEGORY.EXPERTISE': 'Expertise',
    'CATEGORY.ADMINISTRATIVE': 'Administratif',
    'CATEGORY.ABSENCE': 'Absence',
};
