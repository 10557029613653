<template>
<router-link
    :to='workerTimeIcon ? false : workerUrl'
    class='b-worker-card__wrapper'>
    <div class='b-worker-card'>
        <WorkerAvatar :workerTimeIcon='workerTimeIcon' :worker='worker' size='small' align='right'></WorkerAvatar>
        <div class='b-worker-card__info b-worker-card__info--align-right'>
            <div v-if='worker.company_title && !workerTimeIcon'
                 :style='{ "opacity": worker.id ? 1 : 0 }'
                 class='b-worker-card__tag--nick h-text-center b-worker-card__tag'>
                <span>
                    {{ worker.company_title }}
                </span>
            </div>
            <FwIcon v-if='workerTimeIcon'
                    :icon='isConfirmedWorkerId? `circle-arrow-bottom` : `calendar-time`'
                    size='14'
                    :color='isConfirmedWorkerId ? `#27dbbd` : `rgba(33,63,107,.3)`'>
            </FwIcon>
            <div v-else
                 class='b-worker-card__tag--id b-worker-card__tag h-text-center'>
                ID: {{ worker.id }}
            </div>
        </div>
    </div>
</router-link>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { WorkerType } from '@/types/Workers';
import { ConsultWorkerPage } from '@/views/consult/workers/tech/ConsultWorkerPage';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class WorkerCard extends Vue {
    @Prop({ type: Object, default: null }) readonly worker!: WorkerType;
    @Prop({ type: Array, default: null }) readonly workerIdsGetter!: Array<string>;
    @Prop({ type: Number, default: null }) readonly workerId!: number;
    @Prop({ type: Boolean, default: false }) readonly workerTimeIcon!: boolean;

    get workerUrl(): { name: string, params: { id: string } } {
        return {
            name: ConsultWorkerPage.name,
            params: {
                id: this.worker.id,
            },
        };
    }
    get isConfirmedWorkerId() {
        return this.workerIdsGetter.includes(this.worker.id);
    }
}
</script>

<style lang='sass'>
$worker-avatar-size: 48px

.b-worker-card
    padding: 20px
    background-color: #fff
    border-radius: 20px
    box-shadow: $big-box-shadow
    margin: 0 10px
    display: flex
    align-items: center

    @include media('<=phone')
        min-height: 110px

    &__wrapper
        flex: 0 0 50%

        @include media('<=phone')
            flex: 1
    &__tag
        max-width: 200px
        padding: 3px 10px
        border-radius: 5px
        font-size: 14px
        font-weight: 500

        &:not(:last-of-type)
            margin-bottom: 5px

    &__tag--nick
        font-size: 10px
        background-color: #EEFDFA
        color: $main-green
    &__tag--id
        font-size: 10px
        background-color: #F2F4F6
        color: $alt-blue
</style>
