import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Vue from 'vue';
import { User } from '@/types/User';

const warningsToIgnore = [
    'Redirected when going from',
    'Avoided redundant navigation',
    'ResizeObserver loop completed with undelivered notifications',
];

function initBugsnag(user: User | null) {
    Bugsnag.start({
        apiKey: '7f8b7c612819bb614f2da245ebea245c',
        plugins: [new BugsnagPluginVue()],
        onError(event) {
            if (user) {
                event.setUser(user.id);
            }
            if ('originalError' in event && 'isAxiosError' in event.originalError && event.originalError.isAxiosError) {
                return false;
            }
            if ('originalError' in event && 'message' in event.originalError && warningsToIgnore.some(item => event.originalError.message.includes(item))) {
                return false;
            }
            return true;
        },
    });
    const bugsnagVue = Bugsnag.getPlugin('vue');
    if (bugsnagVue) {
        bugsnagVue.installVueErrorHandler(Vue);
    }
}

export {
    initBugsnag,
};
