import { Mixins, Component } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';

import ValidationMixin from '@/mixins/validation';

import { getValidatorByType } from '@/helpers/getValidatorByType';
import { VALIDATOR_TYPES, ValidatorType } from '@/components/forms/attributes/helpers';

const PATH_TO_VALUE = `schema.value`;

@Component({
    validators: {
        [PATH_TO_VALUE]: function v(value: any) {
            return Validator.custom(
                this.customValidator(value, this.$_AttributeValidationMixin_validatorsList),
            );
        },
    },
})
export default class AttributeValidationMixin extends Mixins(ValidationMixin) {
    get $_AttributeValidationMixin_fieldErrors(): Array<string> | null {
        const firstFieldError = this.validation.firstError(PATH_TO_VALUE);
        const currentErrors = this.getValidatorErrors(this.$_AttributeValidationMixin_validatorsList, this.schema.value);
        return firstFieldError ? [firstFieldError] : currentErrors || [];
    }

    get $_AttributeValidationMixin_validatorsList(): Array<ValidatorType> {
        // @ts-ignore
        return Object.keys(this.schema.validator).filter(key => this.schema.validator[key]);
    }

    get $_AttributeValidationMixin_maxlength(): number | undefined {
        const maxLengthValidator = getValidatorByType(
            VALIDATOR_TYPES.MAX_LENGTH,
            this.$_AttributeValidationMixin_validatorsList,
        );

        if (!maxLengthValidator) {
            return undefined;
        }

        return Number(maxLengthValidator.value);
    }

    get $_AttributeValidationMixin_isRequired(): boolean {
        const requiredValidator = getValidatorByType(
            VALIDATOR_TYPES.DATA_REQUIRED,
            this.$_AttributeValidationMixin_validatorsList,
        );

        return Boolean(requiredValidator);
    }

    async $_AttributeValidationMixin_validateAttribute(): Promise<boolean> {
        this.addCurrentInputToValidateArray(PATH_TO_VALUE);
        await this.$nextTick();
        return !(this.$_AttributeValidationMixin_fieldErrors && !!this.$_AttributeValidationMixin_fieldErrors.length);
    }
}
