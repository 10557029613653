<template>
<div class='b-calendar-body__wrapper'
     :class='{ "b-calendar-body__wrapper--with-aside": isAsideInfoShow }'>
    <div class='b-calendar-body__head'>
        <div class='b-calendar-body__column b-calendar-body__column--filter'>
            <div class='h-flex-center'>
                <div v-if='filtersLength'
                     class='b-calendar-body__column--filter--applied'>
                    {{ filtersLength }}
                    <div class='b-calendar-body__column--filter--clear-filters'
                         @click='$emit("cleanFilters")'>
                        {{ $t('CALENDAR.CLEAR_FILTER.TITLE') }}
                    </div>
                </div>
                <div class='b-calendar-body__column--filter__title'
                     @click='$emit("openFilters")'>
                    <FwIcon
                        class='h-mr-5'
                        icon='filters-bottom'
                        size='16'
                        color='#8595AD'>
                    </FwIcon>
                    {{ $t('CALENDAR.FILTER.TITLE') }}
                </div>
            </div>
        </div>
        <div v-for='date in daysInMonth'
             :key='date'
             class='b-calendar-body__cell b-calendar-body__cell--count'
             :class='{ "b-calendar-body__cell--active" : activeIndex === date - 1 }'>
            <div class='b-calendar-body__cell__square'>
                <div class='b-calendar-body__cell__square__sub' :style='getStyles'>
                    {{ date }}
                </div>
            </div>
        </div>
        <template v-if='isAsideInfoShow'>
            <div v-for='item in asideData.head'
                 :key='item.id'
                 class='b-calendar-body__cell b-calendar-body__cell--count b-calendar-body__cell--big'>
                <div class='b-calendar-body__cell__square'>
                    <div class='b-calendar-body__cell__square__sub' :style='getStyles'>
                        {{ item.name }}
                    </div>
                </div>
            </div>
        </template>
    </div>
    <div class='b-calendar-body'>
        <RecycleScroller
            ref='scroller'
            :keyField='keyField'
            :items='users'
            :itemSize='40'
            :buffer='500'
            :pageMode='true'
            class='scroller'>
            <template v-slot='{ item, index }'>
                <CalendarRow
                    :key='item.user_id'
                    :item='item'
                    :isColorblind='isColorblind'
                    :date='date'
                    :daysInMonth='daysInMonth'
                    :isAsideInfoShow='isAsideInfoShow'
                    :data='asideData && asideData.data && asideData.data[index] ? asideData.data[index] : []'
                    @onCellClick='onCellClickHandler'
                    @setCurrentIndex='setCurrentIndex'>
                </CalendarRow>
            </template>
        </RecycleScroller>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { RecycleScroller } from 'vue-virtual-scroller';
import { CalendarRow } from '@/components/calendar/CalendarRow';
import { getColorType, COUNT_TYPE } from '@/components/calendar/helpers';
import { CalendarMonthViewUserDataType, CellDataClickType } from '@/types/Calendar';

@Component({
    components: {
        CalendarRow,
        RecycleScroller,
    },
})
export default class CalendarBody extends Vue {
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Array, required: true }) readonly users!: Array<CalendarMonthViewUserDataType>;
    @Prop({ type: Number, required: true }) readonly daysInMonth!: number;
    @Prop({ type: Boolean, required: true }) readonly isAsideInfoShow!: boolean;
    @Prop({ type: Boolean, required: true }) readonly isColorblind!: boolean;
    @Prop({ type: Object, default: null }) readonly asideData!: any;
    @Prop({ type: Number, default: 0 }) readonly filtersLength!: number;
    @Prop({ type: String, default: 'user_id' }) readonly keyField!: number;

    activeIndex: null | number = null;

    get getStyles(): { [key: string]: string } {
        return { backgroundColor: (getColorType(this.isColorblind) as any)[COUNT_TYPE] };
    }

    setCurrentIndex(index: number | null) {
        this.activeIndex = index;
    }

    @Emit('changeDay')
    changeDayHandler(data: { delta: number, date: Date }) {
        return data;
    }

    @Emit('onCellClick')
    onCellClickHandler(data: CellDataClickType): CellDataClickType {
        return data;
    }
}
</script>

<style lang='sass'>
$border-type: 1px solid #E8ECF0

.b-calendar-body
    width: 100%

    &__wrapper
        margin: 0 auto

    &__wrapper--with-aside
        max-width: 1640px
        width: 100%
        margin: 0 auto

        .b-calendar-body__cell:not(.b-calendar-body__cell--big)
            flex: 0 0 33px
            height: 33px

            .b-calendar-body__cell__square
                padding: 2px

    &__head, &__footer
        display: flex
        position: sticky
        top: 0
        z-index: 2
        width: 100vw
        padding-right: 50px

    &__footer__outer
        position: sticky
        bottom: 0
        left: 0
        width: 100%
        min-width: 100vw
        display: flex
        align-items: center
        justify-items: center
        background: #fff

    &__footer__wrapper

        &--with-aside
            max-width: 1640px
            width: 100%
            margin: 0 auto

            .b-calendar-body__cell
                flex: 0 0 33px
                height: 33px

                .b-calendar-body__cell__square
                    padding: 2px

    &__column
        &--filter
            font-size: 13px
            font-weight: 500
            position: relative
            color: #8595AD
            height: 40px

            .b-calendar-body__column--filter__title
                cursor: pointer

            &--applied
                padding: 2px 7px
                font-size: 12px
                font-weight: normal
                position: relative
                top: -25px
                left: -3px
                background: #27dbbd
                color: #fff
                border-radius: 10px
                cursor: default
                display: flex

            &--clear-filters
                font-size: 11px
                display: flex
                width: 100%
                align-items: center
                position: absolute
                color: black
                left: 100%
                width: 200px
                margin-left: 4px
                top: 3px
                cursor: pointer
                &:hover
                    text-decoration: underline

            &__title
                display: flex
                align-items: center
                &:hover
                    color: black

.b-calendar-body__cell--big

    .b-calendar-body__cell__square
        cursor: default !important

    .b-calendar-body__cell__square__sub
        transform: scale(1) !important

.scroller
    min-height: 100%

.vue-recycle-scroller__item-wrapper
    min-height: 100vh

.vue-recycle-scroller__item-view
    width: 100vw !important
    padding-right: 50px
    height: 40px !important
    &:first-of-type
        .b-calendar-body__row
            border-top: 1px solid #E8ECF0
</style>
