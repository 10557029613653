import axios from 'axios';
import { CommentPaginationData, CommentPayloadData } from '@/types/Comment';
import { updateUrlGetParams } from '@/helpers/url';
import WebApi from '../WebApi';

class CommentsWebApi extends WebApi {
    async updateCommentData(comment: CommentPayloadData, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/comments/${id}`,
            {
                comment,
            },
        );
        return data.comment;
    }

    async sendCommentData(comment: CommentPayloadData) {
        const { data } = await axios.post(
            `${this.urlPrefix}/comments`,
            { comment },
        );

        return data.comment;
    }

    async getComments(params: CommentPaginationData) {
        const url = updateUrlGetParams(`${this.urlPrefix}/comments`, {
            'pagination[per_page]': params.per_page,
            'pagination[page]': params.current_page,
            'appointment_id': params.appointment_id,
        });
        const { data } = await axios.get(url);

        return data;
    }

    async getComment(id: string) {
        const { data } = await axios.get(`${this.urlPrefix}/comments/${id}`);

        return data.comments;
    }

    async deleteComment(id: string) {
        const { data } = await axios.delete(
            `${this.urlPrefix}/comments/${id}`,
        );
        return data;
    }
}

const commentsApi = new CommentsWebApi();

export {
    commentsApi as CommentsWebApi,
};
