import axios from 'axios';
import { AccountTitlesType } from '@/types/Account';
import WebApi from '../WebApi';

interface userAvatarData {
    avatar: string | null,
}

interface userIdData {
    user_id: number | string | null,
}

class AccountApi extends WebApi {
    async fetchData(user_id: string) {
        return axios.get(`${this.urlPrefix}/accounts/${user_id}`);
    }

    async putData(user_id: string) {
        return axios.put(`${this.urlPrefix}/accounts/${user_id}`);
    }

    async patchData(user_id: string) {
        return axios.patch(`${this.urlPrefix}/accounts/${user_id}`);
    }

    async sendAvatarUser({ avatar }: userAvatarData, userId: number | null) {
        return axios.put(
            `${this.urlPrefix}/users/${userId}/upload_avatar`,
            avatar,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
    };
    async sendInvitationUser(user: userIdData) {
        return axios.post(
            `${this.urlPrefix}/users/invite`,
            {
                user,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async getRecapDate(account_id: string) {
        return axios.get(`${this.urlPrefix}/account_onboarding/accounts/${account_id}/recap`);
    }

    async publishAccount(account_id: string) {
        return axios.put(`${this.urlPrefix}/account_onboarding/accounts/${account_id}/publish`);
    }

    async fetchAccountTitles(): Promise<AccountTitlesType> {
        const { data } = await axios.get(`${this.urlPrefix}/front_helpers/saas_state`);
        return data;
    }
}

const accountApi = new AccountApi();

export {
    accountApi as AccountApi,
};
