import axios from 'axios';
import { pagePaginationData } from '@/types/Appointment';
import WebApi from '../WebApi';

interface workerCreateData {
    email?: string,
    first_name?: string,
    last_name?: string,
    label?: string,
    kind?: string,
    company_id?: string,
    role_id?: string,
    role_name?: string,
    phone?: string,
    color?: string,
    add_travel_time?: boolean,
    company_admin?: boolean,
    skill_ids?: Array<string>,
    departure_point?: string | null,
    set_company_as_starting_point?: boolean | null,
    geo_site_ids: Array<string>,
}
interface workerIdData {
    id: string | number | null,
    title?: string,
}

class TechniciansApi extends WebApi {
    async getTechnicianData({ id }: workerIdData) {
        return axios.get(
            `${this.urlPrefix}/account_onboarding/technicians/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getTechnicians(payload: pagePaginationData) {
        const urlParams = `?pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        return axios.get(
            `${this.urlPrefix}/account_onboarding/technicians${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    // eslint-disable-next-line max-len
    async createTechnician({ email, first_name, label, last_name, kind, company_id, company_admin, phone, skill_ids, departure_point, set_company_as_starting_point, geo_site_ids }: workerCreateData) {
        return axios.post(
            `${this.urlPrefix}/account_onboarding/technicians`,
            {
                'technician': {
                    email,
                    first_name,
                    last_name,
                    label,
                    kind,
                    company_id,
                    company_admin,
                    phone,
                    skill_ids,
                    departure_point,
                    set_company_as_starting_point,
                    geo_site_ids,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    // eslint-disable-next-line max-len
    async editTechnician({ email, first_name, label, last_name, kind, company_id, company_admin, phone, skill_ids, departure_point, set_company_as_starting_point, geo_site_ids }: workerCreateData, workerId: string) {
        return axios.put(
            `${this.urlPrefix}/account_onboarding/technicians/${workerId}`,
            {
                'technician': {
                    email,
                    first_name,
                    last_name,
                    kind,
                    company_id,
                    phone,
                    company_admin,
                    skill_ids,
                    departure_point,
                    set_company_as_starting_point,
                    geo_site_ids,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async deleteTechnician({ id }: workerIdData) {
        return axios.delete(
            `${this.urlPrefix}/account_onboarding/technicians/${id}`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
}

const techniciansApi = new TechniciansApi();

export {
    techniciansApi as TechniciansApi,
};
