export default {
    'SETTINGS.ROLES.TITLE': 'Fonction',
    'SETTINGS.ROLES.SEARCH.TEXT': 'Rechercher une fonction',

    'SETTINGS.MENU.HEADER': 'Paramètres',
    'SETTINGS.MENU.ACCOUNT': 'Compte',
    'SETTINGS.MENU.ACCOUNT.DETAILS': 'Informations du compte',
    'SETTINGS.MENU.ACCOUNT.YOUR.SUBSCRIPTION': 'Votre abonnement',
    'SETTINGS.MENU.ACCOUNT.SEE.PLANS': 'VOIR LES FORMULES',
    'SETTINGS.MENU.ACCOUNT.EMAIL.THIS.MONTH': 'EMAILS ENVOYÉS CE MOIS',
    'SETTINGS.MENU.ACCOUNT.SMS.THIS.MONTH': 'SMS ENVOYÉS CE MOIS',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.TITLE': 'Allez plus loin avec la formule Advanced 🚀',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.STATISTIC.TITLE': 'Dashboard statistiques',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.STATISTIC.TEXT': 'Gérez les performances de vos ressources pour une meilleure expérience client.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.SSO.TITLE': 'Gagnez du temps avec le SSO',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.SSO.TEXT': 'Réduisez le temps passé à gérer les utilisateurs et les invitations.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.DISTANCE.TITLE': 'Planifiez avec des temps de trajet',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.DISTANCE.TEXT': 'Prenez en compte le temps de trajet pour les itinérants',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.CRM.TITLE': 'Intégration CRM',
    'SETTINGS.MENU.ACCOUNT.PLAN.ESSENTIAL.CRM.TEXT': 'Simplifiez vos processus en intégrant Calizy à vos outils existants.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.TITLE': 'Allez plus loin avec la formule Enterprise 🚀',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CALENDAR.TITLE': 'Calendrier mois',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CALENDAR.TEXT': 'Voyez ce que font vos équipes sur un mois entier, en un coup d\'œil.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.KPIS.TITLE': 'Fichiers KPI',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.KPIS.TEXT': 'Obtenez des informations sur votre activité de planification grâce aux exportations de KPI.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.FIELDS.TITLE': 'Champs personnalisés',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.FIELDS.TEXT': 'Personnalisez l\'expérience de vos utilisateurs avec des paramètres personnalisés.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.INTEGRATION.TITLE': 'Intégration personnalisée',
    'SETTINGS.MENU.ACCOUNT.PLAN.ADVANCED.CUSTOM.INTEGRATION.TEXT': 'Intégrez Calizy à n\'importe quel outil que vous utilisez.',
    'SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TITLE': 'Faites passer Calizy au niveau supérieur 💫 ',
    'SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TEXT1': 'Avez-vous besoin d\'une fonctionnalité spécifique ? Une suggestion pour améliorer votre expérience Calizy ?',
    'SETTINGS.MENU.ACCOUNT.PLAN.ENTERPRISE.TEXT2': 'Prenons contact pour en discuter !',
    'SETTINGS.MENU.ACCOUNT.SUB.ACCOUNT.LIST.URL': 'Liste des sous-comptes et url',
    'SETTINGS.MENU.MANAGERS': 'Managers',
    'SETTINGS.MENU.ROLES': 'Fonctions',
    'SETTINGS.MENU.ACTIVE': 'Actifs',
    'SETTINGS.MENU.ARCHIVED': 'Archivés',
    'SETTINGS.MENU.APPT.TYPES': 'Types de rendez-vous',
    'SETTINGS.MENU.APPT.REMINDERS': 'Notification des rendez-vous',
    'SETTINGS.MENU.CALENDAR.VIEW': 'Vue calendrier',
    'SETTINGS.MENU.CUSTOMISATION': 'Personnalisation',
    'SETTINGS.SEARCH.APPT.PLACEHOLDER': 'Rechercher un rendez-vous',

    'SETTINGS.APPOINTMENT.TYPE.ACTIVE.EMPTY': 'Aucun type de rendez-vous actif',
    'SETTINGS.APPOINTMENT.TYPE.ARCHIVED.EMPTY': 'Aucun type de rendez-vous archivé',
    'SETTINGS.APPOINTMENT.TYPE.VISIBLE.TO.ALL': 'Visible pour tous',
    'SETTINGS.APPOINTMENT.TYPE.VISIBLE.WITH.LINK': 'Seulement visible avec un lien',

    'SETTINGS.ROLE.CONSULT': 'Consulter',
    'SETTINGS.ROLE.VIEW': 'Vue',
    'SETTINGS.ROLE.ADD': 'Ajouter',
    'SETTINGS.ROLE.EDIT': 'Modifier',
    'SETTINGS.ROLE.LIST': 'Liste',
    'SETTINGS.ROLE.READ': 'Lecture',
    'SETTINGS.ROLE.CANCEL': 'Annuler',
    'SETTINGS.ROLE.DELETE': 'Supprimer',
    'SETTINGS.ROLE.CREATE': 'Créer',
    'SETTINGS.ROLE.BOOK': 'Réserver',

    'SETTINGS.ROLE.RIGHTS.TITLE': 'Configuration',
    'SETTINGS.ROLE.NAME.TITLE': 'Nom de rôle',

    'SETTINGS.ROLE.RIGHTS.ACCOUNT': 'Compte',
    'SETTINGS.ROLE.RIGHTS.ADVISOR': 'Conseiller',
    'SETTINGS.ROLE.RIGHTS.ANONYMOUS_CALENDAR': 'Anonymiser le calendrier',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT_CATEGORY': 'Types de rendez-vous',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT_NOTIFICATION': 'Notification des rendez-vous',
    'SETTINGS.ROLE.RIGHTS.APPOINTMENT': 'Rendez-vous',
    'SETTINGS.ROLE.RIGHTS.BRANCH': 'Agence',

    'SETTINGS.ROLE.RIGHTS.TECHNICIAN.CALENDAR': 'Calendrier des ressources',
    'SETTINGS.ROLE.RIGHTS.ADVISOR.CALENDAR': 'Calendrier des conseillers',
    'SETTINGS.ROLE.RIGHTS.COMPANY_CALENDAR': 'Calendrier des entreprises',
    'SETTINGS.ROLE.RIGHTS.HUB_BRANCH_CALENDAR': 'Calendrier des agences/hubs',
    'SETTINGS.ROLE.RIGHTS.HUB_CALENDAR': 'Calendrier des hubs',
    'SETTINGS.ROLE.RIGHTS.BRANCH_CALENDAR': 'Calendrier des agences',
    'SETTINGS.ROLE.RIGHTS.CALENDAR': 'Calendrier',

    'SETTINGS.ROLE.RIGHTS.CALENDAR.VIEW': 'Vue calendrier',
    'SETTINGS.ROLE.RIGHTS.GENERIC.URLS': 'URL génériques',
    'SETTINGS.ROLE.RIGHTS.COMPANY': 'Entreprise',
    'SETTINGS.ROLE.RIGHTS.HUB': 'Hub',
    'SETTINGS.ROLE.RIGHTS.KPI': 'Statistiques',
    'SETTINGS.ROLE.RIGHTS.MANAGER': 'Managers',
    'SETTINGS.ROLE.RIGHTS.RIGHTS': 'Fonctions',
    'SETTINGS.ROLE.RIGHTS.SETTINGS': 'Réglages',
    'SETTINGS.ROLE.RIGHTS.TECHNICIAN': 'Ressource',
    'SETTINGS.ROLE.RIGHTS.ONBOARDING': 'Onboarding',
    'SETTINGS.ROLE.RIGHTS.SHIFTS': 'Shifts',
    'SETTINGS.ROLE.RIGHTS.SERVICE_POINTS': 'Permanences',

    'SETTINGS.ROLE.UPDATE.SUCCESSFULLY': 'Fonction mise à jour avec succès',
    'SETTINGS.ROLE.CREATE.SUCCESSFULLY': 'Fonction créée avec succès',
    'SETTINGS.ROLE.DELETE.SUCCESSFULLY': 'Fonction supprimée avec succès',
    'SETTINGS.ROLE.DELETE.TITLE': 'Supprimer une fonction',
    'SETTINGS.ROLE.DELETE.SUB': 'Voulez-vous vraiment supprimer cette fonction?',

    'SETTINGS.ROLE.GROUP.ONBOARDING': 'Onboarding',
    'SETTINGS.ROLE.GROUP.BOOKING_FLOW': 'Prise de rendez-vous',
    'SETTINGS.ROLE.GROUP.RESOURCES': 'Ressources',
    'SETTINGS.ROLE.GROUP.CONFIGURATION': 'Configuration',
};
