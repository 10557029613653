import { WorkerType, WorkerTimeType } from '@/types/Workers';

const WORKERS_INFORMATION_FIRST_NAME_TITLE = 'First name';
const WORKERS_INFORMATION_LAST_NAME_TITLE = 'Last name';
const WORKERS_INFORMATION_PHONE_TITLE = 'Phone';
const WORKERS_INFORMATION_COLOR_TITLE = 'Color';
const WORKERS_INFORMATION_COMPANIES_TITLE = 'Companies';
const WORKERS_INFORMATION_COMPANY_TITLE = 'Company';
const WORKERS_INFORMATION_EMAIL_TITLE = 'Email';
const WORKERS_INFORMATION_ROLE_TITLE = 'Role';
const WORKERS_INFORMATION_ID_TITLE = 'ID';
const WORKERS_INFORMATION_LABEL_TITLE = 'Label';

const WORKERS_INFORMATION_FIRST_NAME = 'first_name';
const WORKERS_INFORMATION_LAST_NAME = 'last_name';
const WORKERS_INFORMATION_PHONE = 'phone';
const WORKERS_INFORMATION_COLOR = 'color';
const WORKERS_INFORMATION_COMPANIES = 'companies';
const WORKERS_INFORMATION_EMAIL = 'email';
const WORKERS_INFORMATION_AVATAR = 'avatar';
const WORKERS_INFORMATION_ROLE = 'role';
const WORKERS_INFORMATION_ID = 'id';
const WORKERS_INFORMATION_LABEL = 'label';
const WORKERS_INFORMATION_COMPANY = 'company';
const WORKERS_INFORMATION_CODE = 'code';
const WORKERS_COMPANY_ADMIN = 'company_admin';

const workerDataProps: WorkerType = {
    id: '1',
    created_at: '',
    color: '#FFF020 - #F0E220',
    updated_at: '',
    email: null,
    first_name: null,
    last_name: null,
    locale: '',
    time_zone: '',
    account_id: '',
    company_title: 'Some comp',
    kind: 'technician',
    technician_profile: {
        add_travel_time: false,
        company_admin: false,
        created_at: '',
        departure_point: '',
        id: '',
        invitation_status: '',
        label: '',
        phone: '',
        remote_id: '',
        updated_at: '',
        user_id: '',
    },
    working_schedule: {
        id: 1,
    },
    is_company_admin_set: false,
    is_working_schedule_confirmed: false,
    is_company_address_confirmed: false,
};
const workersDataProps: Array<WorkerType> = [
    {
        id: '2',
        created_at: '2020-04-14T09:59:28.175+02:00',
        color: '#FFF020 - #F0E220',
        updated_at: '',
        email: null,
        first_name: 'Lina',
        last_name: null,
        locale: '',
        time_zone: '',
        account_id: '',
        company_title: 'Some comp',
        kind: 'technician',
        technician_profile: {
            add_travel_time: false,
            company_admin: false,
            created_at: '',
            departure_point: '',
            id: '',
            invitation_status: '',
            label: '',
            phone: '',
            remote_id: '',
            updated_at: '',
            user_id: '',
        },
        working_schedule: {
            id: 1,
        },
        is_company_admin_set: false,
        is_working_schedule_confirmed: false,
        is_company_address_confirmed: false,
    },
    {
        id: '3',
        created_at: '2020-04-14T09:59:28.175+02:00',
        color: '#FFF020 - #F0E220',
        updated_at: '',
        email: null,
        first_name: 'Alex',
        last_name: null,
        locale: '',
        time_zone: '',
        account_id: '',
        company_title: 'Some comp',
        kind: 'technician',
        technician_profile: {
            add_travel_time: false,
            company_admin: false,
            created_at: '',
            departure_point: '',
            id: '',
            invitation_status: '',
            label: '',
            phone: '',
            remote_id: '',
            updated_at: '',
            user_id: '',
        },
        working_schedule: {
            id: 1,
        },
        is_company_admin_set: false,
        is_working_schedule_confirmed: false,
        is_company_address_confirmed: false,
    },
    {
        id: '4',
        created_at: '2020-04-14T09:59:28.175+02:00',
        color: '#FFF020 - #F0E220',
        updated_at: '',
        email: null,
        first_name: 'Tomasz',
        last_name: null,
        locale: '',
        time_zone: '',
        account_id: '',
        company_title: 'Some comp',
        kind: 'technician',
        technician_profile: {
            add_travel_time: false,
            company_admin: false,
            created_at: '',
            departure_point: '',
            id: '',
            invitation_status: '',
            label: '',
            phone: '',
            remote_id: '',
            updated_at: '',
            user_id: '',
        },
        working_schedule: {
            id: 1,
        },
        is_company_admin_set: false,
        is_working_schedule_confirmed: false,
        is_company_address_confirmed: false,
    },
];

const WORKER_SCHEDULE_DATA: WorkerTimeType = {
    monday: {
        day: 'CALENDAR.WEEK.MONDAY',
        monday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: '18:00:00',
                timeFrom: '08:00:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00:00',
                timeFrom: '12:00:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    tuesday: {
        day: 'CALENDAR.WEEK.TUESDAY',
        tuesday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: '18:00:00',
                timeFrom: '08:00:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00:00',
                timeFrom: '12:00:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    wednesday: {
        day: 'CALENDAR.WEEK.WEDNESDAY',
        wednesday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: '18:00:00',
                timeFrom: '08:00:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00:00',
                timeFrom: '12:00:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    thursday: {
        day: 'CALENDAR.WEEK.THURSDAY',
        thursday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: '18:00:00',
                timeFrom: '08:00:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00:00',
                timeFrom: '12:00:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    friday: {
        day: 'CALENDAR.WEEK.FRIDAY',
        friday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: '18:00:00',
                timeFrom: '08:00:00',
                label: 'WORKER.TIME.SLOT.DAY',
                state: true,
            },
            lunch: {
                timeTo: '14:00:00',
                timeFrom: '12:00:00',
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: true,
            },
        },
    },
    saturday: {
        day: 'CALENDAR.WEEK.SATURDAY',
        saturday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.DAY',
                state: false,
            },
            lunch: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: false,
            },
        },
    },
    sunday: {
        day: 'CALENDAR.WEEK.SUNDAY',
        sunday: true,
        state: false,
        dayPart: {
            day: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.DAY',
                state: false,
            },
            lunch: {
                timeTo: null,
                timeFrom: null,
                label: 'WORKER.TIME.SLOT.LUNCH',
                state: false,
            },
        },
    },
};

const WORKERS_INFORMATION_FORM_MAPPING = {
    [WORKERS_INFORMATION_FIRST_NAME]: WORKERS_INFORMATION_FIRST_NAME_TITLE,
    [WORKERS_INFORMATION_LAST_NAME]: WORKERS_INFORMATION_LAST_NAME_TITLE,
    [WORKERS_INFORMATION_PHONE]: WORKERS_INFORMATION_PHONE_TITLE,
    [WORKERS_INFORMATION_COLOR]: WORKERS_INFORMATION_COLOR_TITLE,
    [WORKERS_INFORMATION_COMPANIES]: WORKERS_INFORMATION_COMPANIES_TITLE,
    [WORKERS_INFORMATION_EMAIL]: WORKERS_INFORMATION_EMAIL_TITLE,
    [WORKERS_INFORMATION_LABEL]: WORKERS_INFORMATION_LABEL_TITLE,
    [WORKERS_INFORMATION_ID]: WORKERS_INFORMATION_ID_TITLE,
};

const ADVISORS_DEPARTMENT_CITIES = {
    id: 'ab6c198a-27c9-4471-b494-84660de55665',
    name: 'NEUVY',
    parent_id: 'a5985db0-4ec3-4866-86b3-6a2cb2d1ab98',
    post_code: '3000',
};

export {
    WORKERS_INFORMATION_FORM_MAPPING,
    WORKERS_INFORMATION_FIRST_NAME,
    workerDataProps,
    workersDataProps,
    WORKER_SCHEDULE_DATA,
    WORKERS_INFORMATION_LAST_NAME,
    WORKERS_INFORMATION_PHONE,
    WORKERS_INFORMATION_COLOR,
    WORKERS_INFORMATION_ID,
    WORKERS_INFORMATION_ID_TITLE,
    WORKERS_INFORMATION_COMPANIES,
    WORKERS_INFORMATION_EMAIL,
    WORKERS_INFORMATION_COLOR_TITLE,
    WORKERS_INFORMATION_EMAIL_TITLE,
    WORKERS_INFORMATION_AVATAR,
    WORKERS_INFORMATION_ROLE,
    WORKERS_INFORMATION_ROLE_TITLE,
    WORKERS_INFORMATION_LABEL,
    WORKERS_INFORMATION_LABEL_TITLE,
    WORKERS_INFORMATION_COMPANY,
    WORKERS_INFORMATION_CODE,
    WORKERS_COMPANY_ADMIN,
    ADVISORS_DEPARTMENT_CITIES,
};
