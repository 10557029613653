<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mb-0'>
                    {{ isArchive ? $t('APPOINTMENT.POPUP.REACTIVE.TITLE') : $t('APPOINTMENT.POPUP.ARCHIVE.TITLE') }}
                </h2>
                <p class='h-mt-10 h-mb-20 h-text-center'>
                    {{ isArchive ? $t('APPOINTMENT.POPUP.REACTIVE.TEXT') : $t('APPOINTMENT.POPUP.ARCHIVE.TEXT') }}
                </p>
            </div>
        </template>
        <template #footer>
            <div style='height: 20%'>
                <FwButton
                    class='h-mt-10 h-pv-20'
                    borderRadiusType='tiny-border'
                    @click='$emit("submit")'>
                    {{ isArchive ? $t('APPOINTMENT.POPUP.REACTIVE.YES') : $t('APPOINTMENT.POPUP.ARCHIVE.YES') }}
                </FwButton>

                <div class='h-flex-center'>
                    <FwButton
                        class='h-mt-20'
                        :style='{
                            color: `rgba(32, 63, 106, 0.3)`,
                            textTransform: `uppercase`
                        }'
                        color='transparent'
                        fontSize='14px'
                        @click='$emit(`close`)'>
                        {{ $t('BUTTON.CANCEL') }}
                    </FwButton>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class ArchivePopup extends Vue {
    @Prop({ type: Boolean, default: false }) readonly isArchive!: boolean;
}
</script>
