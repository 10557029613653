<template>
<div class='b-add-appointment__wrapper container h-pb-30'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $route.params.id ? $t('PLAN.APPOINTMENT.BUSINESS.PARTNER.EDIT') : $t('PLAN.APPOINTMENT.BUSINESS.PARTNER.ADD') }}
            </h2>
        </div>
        <form @keydown.stop.prevent.enter='submit'>
            <div class='b-add-or-edit-company__line'>
                <FwFormInput
                    class='h-flex-1-0'
                    :class='{ "h-mr-20": !isMobile() }'
                    :fieldHasError='fieldHasError(COMPANY_NAME)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.COMPANY.NAME`)}*`'>
                    <FwInput
                        :value='company_name'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.COMPANY.NAME`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(COMPANY_NAME)'
                        @update:value='value => company_name = value'
                        @input='clearServerErrorsBase(COMPANY_NAME)'
                        @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError(CODE_INSEE)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.CODE.INSEE`)}`'>
                    <FwInput
                        :value='insee_code'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.CODE.INSEE`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(CODE_INSEE)'
                        @update:value='value => insee_code = value'
                        @input='clearServerErrorsBase(CODE_INSEE)'
                        @blur='addCurrentInputToValidateArray(CODE_INSEE)'>
                    </FwInput>
                </FwFormInput>
            </div>
            <div v-if='!$route.params.id' class='h-flex h-flex-space-between h-mb-30'>
                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError()'
                    :title='`${$t(`LABEL.ADDRESS`)}*`'
                    labelType='label-medium'>
                    <SelectAddress
                        :startAddress='address'
                        :isDisabled='$route.params.id && address'
                        @changePostCode='value => post_code = value'
                        @input='input'>
                    </SelectAddress>
                </FwFormInput>
            </div>

            <div class='b-add-or-edit-company__line'>
                <FwFormInput
                    class='h-flex-1-0'
                    :class='{ "h-mr-20": !isMobile() }'
                    :fieldHasError='fieldHasError(SIREN)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.CODE.SIREN`)}`'>
                    <FwInput
                        :value='siren'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.CODE.SIREN`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(CODE_INSEE)'
                        @update:value='value => siren = value'
                        @input='clearServerErrorsBase(CODE_INSEE)'
                        @blur='addCurrentInputToValidateArray(CODE_INSEE)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError(SIRET)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.CODE.SIRET`)}`'>
                    <FwInput
                        :value='siret'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.CODE.SIRET`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(SIRET)'
                        @update:value='value => siret = value'
                        @input='clearServerErrorsBase(SIRET)'
                        @blur='addCurrentInputToValidateArray(SIRET)'>
                    </FwInput>
                </FwFormInput>
            </div>

            <div class='b-add-or-edit-company__line'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-20'
                    :fieldHasError='fieldHasError(PHONE_NUMBER)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.PHONE.NUMBER`)}`'>
                    <FwInput
                        :value='phone'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.PHONE.NUMBER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(PHONE_NUMBER)'
                        @update:value='value => phone = value'
                        @input='clearServerErrorsBase(PHONE_NUMBER)'
                        @blur='addCurrentInputToValidateArray(PHONE_NUMBER)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    class='h-flex-1-0'
                    :fieldHasError='fieldHasError(EMAIL)'
                    labelType='label-medium'
                    :title='`${$t(`LABEL.EMAIL`)}`'>
                    <FwInput
                        :value='email'
                        type='text'
                        name='text'
                        :placeholder='$t(`LABEL.EMAIL`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(EMAIL)'
                        @update:value='value => email = value'
                        @input='clearServerErrorsBase(EMAIL)'
                        @blur='addCurrentInputToValidateArray(EMAIL)'>
                    </FwInput>
                </FwFormInput>
            </div>

            <div class='h-mb-30 b-required-field'>
                * {{ $t(`LABEL.REQUIRED.FIELD`) }}
            </div>
        </form>
        <div class='h-flex h-flex-center h-flex-dir-column'>
            <FwButton
                size='little'
                :disabled='isDisabled'
                borderRadiusType='small-border'
                @click='onSubmit'>
                {{ $t('BUTTON.SAVE') }}
            </FwButton>
            <div v-if='$route.params.id && canDelete'
                 class='b-add-branch__form__delete'
                 @click='$emit("delete")'>
                {{ $t('CONSULT.DELETE.COMPANY.TITLE') }}
            </div>
        </div>
    </template>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import TranslateMixin from '@/mixins/TranslateMixin';
import { AddNewSkillPopup } from '@/components/popups/AddNewSkillPopup/index';
import { ParsedAddressData } from '@/types/GoogleMap';
import { SelectAddress } from '@/components/common/SelectAddress';
import { addPostCodeToAddress } from '@/helpers/address';
import PermissionsMixin from '@/mixins/permissions';
import {
    COMPANY_NAME,
    ADDRESS,
} from '@/helpers/partner';
import { CompanyPayloadType, CompanyData } from '@/types/Companies';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    validators: {
        [COMPANY_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [ADDRESS](value: string) {
            return this.requiredValidator({ value });
        },
    },
    components: {
        AddNewSkillPopup,
        SelectAddress,
    },
})
export default class AddOrEditCompanyForm extends Mixins(ValidationMixin, TranslateMixin, PermissionsMixin) {
    @WorkerPageStore.State('companyStateData') companyStateData!: CompanyData;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    company_name: string | null = '';
    address: string | null = '';
    insee_code: string | null = '';
    post_code: string | null = '';
    siren: string | null = '';
    siret: string | null = '';
    phone: string | null = '';
    email: string | null = '';
    postCodeCannotDetect: boolean = false;
    loading: boolean = false;

    serverErrors: serverErrors = {
        company_name: null,
        address_1: null,
    };

    get isDisabled(): boolean {
        return this.disabled || this.isRequestSending;
    }

    get disabled(): boolean {
        return !(this.company_name && this.address);
    }

    input(parsedAddressData: ParsedAddressData) {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
    }

    async created() {
        this.loading = true;
        if (this.$route.params.id) {
            const existCompany = { company: this.companyStateData };
            const { company } = this.companyStateData ? existCompany : await AppointmentWebApi.getSocietyItemData(this.$route.params.id);
            this.company_name = company.title;
            this.address = company.address;
            this.insee_code = company.insee_code || '';
            this.siren = company.siren || '';
            this.siret = company.siret;
            this.email = company.contact_email;
            this.phone = company.phone || null;
        }
        this.loading = false;
    }
    @Emit('onSubmit')
    onSubmit(): CompanyPayloadType {
        return {
            title: (this.company_name as string),
            address: addPostCodeToAddress(this.address!, this.post_code!),
            siren: (this.siren as string),
            zip_code: (this.post_code as string),
            siret: this.siret,
            phone: this.phone,
            contact_email: this.email,
            insee_code: this.insee_code || '',
        };
    }
}
</script>

<style lang='sass'>
    .b-add-appointment
        &__wrapper
            width: 1100px !important
            @include media('<=phone')
                width: 100%

            .b-time-picker
                background: #ffffff
                height: 60px

            .fw-select-address
                .multiselect__content-wrapper
                    position: absolute !important
                    top: 60px
                    min-width: 100% !important
                    width: 100% !important
                    height: 300px
                    .multiselect__content
                        .multiselect__element
                            background-color: #fff !important
</style>
