<template>
<div class='b-onboarding__wrapper'>
    <div>
        <FwImage
            :height='`107px`'
            :src='requireImage("working-hours.svg")'>
        </FwImage>
    </div>
    <div class='b-onboarding__title'>
        {{ $t('CONSULT.INFO.WORKING.HOURS') }}
    </div>
    <div class='b-onboarding__aside'>
        {{ $t('ONBOARDING.WORKING.HOURS.CHOOSE.HOW') }}
    </div>

    <ol class='b-onboarding__ol h-p-0'>
        <div class='h-flex h-flex-center h-flex-dir-column'>
            <FwButton size='large-xl'
                      color='choose'
                      borderRadiusType='tiny-border'
                      class='h-m-10'
                      :to='`${mainRouteGlobal}/working-hours/all`'
                      :fontSize='`16px`'>
                {{ $t(`ONBOARDING.WORKING.HOURS.${workerAdvisorString}.CHOOSE.IDENTICAL`) }}
                <FwIcon icon='arrow-right'
                        size='12'
                        color='#27dbbd'>
                </FwIcon>
            </FwButton>
            <FwButton size='large-xl'
                      color='choose'
                      borderRadiusType='tiny-border'
                      class='h-m-10'
                      :to='`${mainRouteGlobal}/working-hours/list`'
                      :fontSize='`16px`'
                      @click.native='goToSpecific'>
                {{ $t(`ONBOARDING.WORKING.HOURS.${workerAdvisorString}.CHOOSE.SPECIFIC`) }}
                <FwIcon icon='arrow-right'
                        size='12'
                        color='#27dbbd'>
                </FwIcon>
            </FwButton>
        </div>
    </ol>

    <FwButton
        :to='`${this.mainRouteGlobal}/invitations/choose`'
        class='h-m-20'
        color='transparent'
        :fontColor='`rgba(33, 63, 107, 0.3)`'>
        {{ $t('BUTTON.LATER') }}
    </FwButton>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class OnboardingWorkingHoursChoosePage extends Vue {
    @Prop({ type: String, default: '' }) readonly workerAdvisorString!: string;
    goToSpecific() {
        console.log('specific');
    }
}
</script>

<style lang='sass'>
.b-onboarding
    &__wrapper
        padding: 110px 0
        display: flex
        flex-direction: column
        align-items: center

    &__title
        margin-top: 20px
        font-size: 30px
        line-height: 37px
        letter-spacing: 0.37px
        font-weight: 600

    &__aside
        margin-top: 9px
        font-size: 16px

    &__ol
        max-width: 600px
        margin-top: 10px
        font-size: 22px
        line-height: 30px
        font-weight: 600
        counter-reset: list

        li
            margin-top: 20px
            list-style-type: none

            &:before
                list-style-type: none
                counter-increment: list
                content: counter(list) ' - '
</style>
