import { GoogleMapPlaceType, ParsedAddressData } from '@/types/GoogleMap';

function parseGooglePlaceAsAddress(place: GoogleMapPlaceType): ParsedAddressData {
    const postCodeData = place.address_components.find(item => item.types.includes('postal_code'));
    const countryData = place.address_components.find(item => item.types.includes('country'));
    const zip_code = postCodeData ? postCodeData.long_name : '';
    const country = countryData ? countryData.long_name : '';
    const address = place.formatted_address;
    return {
        calizyAddress: address,
        post_code: zip_code,
        address: address.split(', ')[0],
        country,
    };
}

export {
    parseGooglePlaceAsAddress,
};
