<template>
<div class='b-calendar-info'>
    <div class='b-calendar-info__grid'>
        <FwIcon
            class='h-mt-5'
            icon='grid'
            color='#B7C0CE'
            size='23'>
        </FwIcon>
    </div>
    <div class='b-calendar-info__text__wrapper'>
        <div v-for='item in information'
             class='b-calendar-info__text'>
            {{ item.aside }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { informationDataType } from '@/types/Consult';
import { capitalizeAll } from '@/helpers/string';
import { CalendarViewSettingsResponseType } from '@/types/Calendar';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    components: {
        GoBackButton,
    },
})
export default class CalendarInfo extends Vue {
    @Prop({ type: Object, required: true }) readonly informationData!: CalendarViewSettingsResponseType | null;

    get information(): Array<informationDataType> {
        // @ts-ignore
        return this.informationData ? this.prepareLocalInformation(this.informationData) : [];
    }

    prepareLocalInformation(information: { [key: string]: string }) {
        const appointmentDataArray: Array<informationDataType> = [];
        if (information.title) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.NAME.LABEL'),
                aside: capitalizeAll(information.title),
            });
        }
        if (information.name) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.NAME.LABEL'),
                aside: capitalizeAll(information.name),
            });
        }
        if (information.address) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.LOCATION.LABEL'),
                aside: information.address,
            });
        }
        if (information.first_name || information.last_name) {
            appointmentDataArray.push({
                title: this.$i18n.t('CALENDAR.NAME.LABEL'),
                aside: capitalizeAll(`${information.first_name} ${information.last_name}`),
            });
        }
        if (information.email) {
            appointmentDataArray.push({
                title: this.$i18n.t('LABEL.EMAIL'),
                aside: information.email,
            });
        }
        return appointmentDataArray;
    }
}
</script>

<style lang='sass'>
.b-calendar-info
    display: flex
    align-items: center
    border-top: 1px solid #D8D8D8
    margin: 0 -40px
    padding: 20px 25px 0
    word-break: break-all

    @include media('<=phone')
        padding-top: 10px
        margin: 0 -30px

    &__grid
        width: 46px
        height: 46px
        border-radius: 50%
        background-color: #E9ECF0
        display: flex
        align-items: center
        justify-content: center
        flex: 0 0 46px
        margin-right: 20px

    &__text
        font-size: 12px
        margin-top: 5px

        &:first-of-type
            font-weight: bold
            font-size: 16px

        &:not(:first-of-type)
            text-transform: uppercase
</style>
