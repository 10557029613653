<template>
<div class='qa-reserved-slot-popup b-add-reserved-slot-form'>
    <div v-if='recurrenceLoading'
         class='b-add-reserved-slot-form__loading'>
        <FwSpinLoader
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader h-width-100p h-height-100p'
            :isActive='recurrenceLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </div>
    <div class='b-add-reserved-slot-form__title'>
        {{ formTitle }}
    </div>
    <div v-if='date'
         class='b-add-reserved-slot-form__date'>
        {{ dateTitle }}
    </div>

    <span class='b-add-reserved-slot-form__close_popup'>
        <FwIcon
            icon='close'
            size='15'
            color='#BEC7D4'
            @click.native='close'>
        </FwIcon>
    </span>

    <template v-if='isAvailabilitySlots'>
        <FwLabelCard
            class='h-mb-10'
            height='40px'
            padding='0 15px'>
            <div class='b-add-reserved-slot-form__label h-flex-space-between'>
                <div class='b-add-reserved-slot-form__label__text h-uppercase h-mr-10'
                     :class='{ "b-add-reserved-slot-form__label__text--active": endRecurrenceIsActive }'>
                    {{ $t('SETTINGS.CALENDAR.FROM') }}
                </div>
                <div class='h-flex'>
                    <div class='h-pos-rel b-calendar-select__week fw-select-base fw-select-base__time h-flex-1-0 h-width-115'>
                        <FwDatepicker
                            v-model='startDay'
                            :calendarPanelKey='calendarPanelKey'
                            class='b-add-reserved-slot-form__day_datepicker'
                            name='datepicker'
                            :propsRange='false'
                            :selectMode='false'
                            :disabled='disableStartDay || isReservedSlotExists'
                            propsFormat='DD MMM YY'
                            :hideIcon='true'
                            pickerStyle='form'
                            iconName='arrow-bottom'
                            iconSize='10'
                            :customArrowColor='true'
                            :iconRight='true'
                            :propsLang='`${$i18n.locale}`'
                            :disabledDate='disabledDateByObjectStartDay'
                            :defaultValue='startDay'
                            propsInputClass='h-text-center'>
                            <template #icon>
                                &nbsp;
                            </template>
                        </FwDatepicker>
                    </div>
                </div>
            </div>
        </FwLabelCard>

        <FwLabelCard
            class='h-mb-10'
            height='40px'
            padding='0 15px'>
            <div class='b-add-reserved-slot-form__label h-flex-space-between'>
                <div class='b-add-reserved-slot-form__label__text h-uppercase h-mr-10'
                     :class='{ "b-add-reserved-slot-form__label__text--active": endRecurrenceIsActive }'>
                    {{ $t('SETTINGS.CALENDAR.TO') }}
                </div>
                <div class='h-flex'>
                    <div :class='{ "b-calendar-select--disable": disableEndDay }'
                         class='h-pos-rel b-calendar-select__week fw-select-base fw-select-base__time h-flex-1-0 h-width-115'>
                        <FwDatepicker
                            v-model='endDay'
                            class='b-add-reserved-slot-form__day_datepicker'
                            :calendarPanelKey='calendarPanelKey'
                            :disabled='disableEndDay || isReservedSlotExists'
                            name='datepicker2'
                            :propsRange='false'
                            :selectMode='false'
                            propsFormat='DD MMM YY'
                            :hideIcon='true'
                            pickerStyle='form'
                            iconName='arrow-bottom'
                            iconSize='10'
                            :propsLang='`${$i18n.locale}`'
                            :customArrowColor='true'
                            :iconRight='true'
                            :disabledDate='disabledDateByObjectEndDay'
                            :defaultValue='endDay'
                            propsInputClass='h-text-center'>
                            <template #icon>
                                &nbsp;
                            </template>
                        </FwDatepicker>
                    </div>
                </div>
            </div>
        </FwLabelCard>
    </template>

    <template v-else>
        <div class='b-add-reserved-slot-form__wrapper'
             :class='{ "b-add-reserved-slot-form__wrapper--disable": isFormDisable }'>
            <div v-if='!isReservedSots && !isAvailabilitySlots && !isShift'
                 class='b-add-reserved-slot-form__label h-mb-10'>
                <div class='h-pos-rel b-calendar-select__week
                        fw-select-base fw-select-base__time h-flex-1-0'>
                    <FwSelect
                        v-model='title'
                        :searchable='false'
                        :canNotDelete='true'
                        :propsPlaceholder='$t("ADD.RESERVED.SLOT.FORM.TITLE.PLACEHOLDER")'
                        label='title'
                        :options='titleOptions'>
                    </FwSelect>
                </div>
            </div>

            <div v-if='isReservedSots'
                 class='b-reserved-slots__category-locations'
                 :class='{ "b-reserved-slots__category-locations--disabled": isFormDisable }'>
                <label class='fw-form-input__label label-medium b-reserved-slots__category-locations__title'>
                    {{ $t('LABEL.METHODS') }}*
                </label>
                <div class='b-reserved-slots__category-locations__inner'>
                    <FwCheckbox
                        v-for='item in categoryLocationSkillNamesFiltered'
                        :id='item.id'
                        :key='item.id'
                        v-model='checkboxLocation'
                        class='h-mr-10'
                        :value='$t(item)'
                        type='plus'
                        :disabled='isLocationSkillDisabled(item)'
                        :text='$t(item[labelKey])'
                        iconRightSide='plus'
                        iconCheckedSize='12'
                        name='checkbox'>
                    </FwCheckbox>
                </div>
                <div v-if='isOnSiteExists && categoryLocationSkillNamesFiltered.length >= 2'
                     class='b-reserved-slots_on-site__hint'>
                    *{{ $t('ADD.RESERVED.SLOT.ON_SITE.HINT') }}
                </div>
            </div>

            <div>
                <FwLabelCard
                    class='h-mb-10'
                    height='40px'
                    padding='0 15px'>
                    <div class='b-add-reserved-slot-form__label'>
                        <div class='b-add-reserved-slot-form__label__text'
                             :class='{ "b-add-reserved-slot-form__label__text--active": allDayIsActive }'>
                            {{ $t('ADD.RESERVED.SLOT.FORM.ALL_DAY') }}
                        </div>
                        <FwSwitcher
                            :isActive='allDayIsActive'
                            :isRequestSending='false'
                            @toggleSwitch='allDayIsActive = !allDayIsActive'>
                        </FwSwitcher>
                    </div>
                </FwLabelCard>

                <FwLabelCard
                    class='h-mb-10'
                    height='40px'
                    padding='0 15px'>
                    <div class='b-add-reserved-slot-form__label h-flex-space-between'>
                        <div class='b-add-reserved-slot-form__label__text h-uppercase h-mr-10'
                             :class='{ "b-add-reserved-slot-form__label__text--active": endRecurrenceIsActive }'>
                            {{ $t('SETTINGS.CALENDAR.FROM') }}
                        </div>
                        <div class='h-flex'>
                            <div class='h-pos-rel b-calendar-select__week fw-select-base fw-select-base__time h-flex-1-0 h-width-115'>
                                <FwDatepicker
                                    v-model='startDay'
                                    :calendarPanelKey='calendarPanelKey'
                                    class='b-add-reserved-slot-form__day_datepicker'
                                    name='datepicker'
                                    :propsRange='false'
                                    :selectMode='false'
                                    :disabled='disableStartDay'
                                    propsFormat='DD MMM YY'
                                    :hideIcon='true'
                                    pickerStyle='form'
                                    iconName='arrow-bottom'
                                    iconSize='10'
                                    :customArrowColor='true'
                                    :iconRight='true'
                                    :propsLang='`${$i18n.locale}`'
                                    :disabledDate='disabledDateByObjectStartDay'
                                    :defaultValue='startDay'
                                    propsInputClass='h-text-center'>
                                    <template #icon>
                                        &nbsp;
                                    </template>
                                </FwDatepicker>
                            </div>
                            <div
                                v-if='!allDayIsActive'
                                class='h-pos-rel b-calendar-select__time fw-select-base fw-select-base__time h-flex-1-0 h-width-115'>
                                <FwSelect
                                    v-model='timeFrom'
                                    :searchable='false'
                                    :canNotDelete='true'
                                    :showRightIcon='false'
                                    :options='timeFromOptions'>
                                </FwSelect>
                            </div>
                        </div>
                    </div>
                </FwLabelCard>

                <FwLabelCard
                    class='h-mb-10'
                    height='40px'
                    padding='0 15px'>
                    <div class='b-add-reserved-slot-form__label h-flex-space-between'>
                        <div class='b-add-reserved-slot-form__label__text h-uppercase h-mr-10'
                             :class='{ "b-add-reserved-slot-form__label__text--active": endRecurrenceIsActive }'>
                            {{ $t('SETTINGS.CALENDAR.TO') }}
                        </div>
                        <div class='h-flex'>
                            <div :class='{ "b-calendar-select--disable": disableEndDay }'
                                 class='h-pos-rel b-calendar-select__week fw-select-base fw-select-base__time h-flex-1-0 h-width-115'>
                                <FwDatepicker
                                    v-model='endDay'
                                    class='b-add-reserved-slot-form__day_datepicker'
                                    :calendarPanelKey='calendarPanelKey'
                                    :disabled='disableEndDay'
                                    name='datepicker2'
                                    :propsRange='false'
                                    :selectMode='false'
                                    propsFormat='DD MMM YY'
                                    :hideIcon='true'
                                    pickerStyle='form'
                                    iconName='arrow-bottom'
                                    iconSize='10'
                                    :propsLang='`${$i18n.locale}`'
                                    :customArrowColor='true'
                                    :iconRight='true'
                                    :disabledDate='disabledDateByObjectEndDay'
                                    :defaultValue='endDay'
                                    propsInputClass='h-text-center'>
                                    <template #icon>
                                        &nbsp;
                                    </template>
                                </FwDatepicker>
                            </div>
                            <div
                                v-if='!allDayIsActive'
                                class='h-pos-rel b-calendar-select__time fw-select-base fw-select-base__time h-flex-1-0 h-width-115'>
                                <FwSelect
                                    v-model='timeTo'
                                    :searchable='false'
                                    :canNotDelete='true'
                                    :showRightIcon='false'
                                    :options='timeToOptions'>
                                </FwSelect>
                            </div>
                        </div>
                    </div>
                </FwLabelCard>
            </div>

            <div class='b-add-reserved-slot-form__label h-mb-10'>
                <div class='h-pos-rel b-calendar-select__week
                        fw-select-base fw-select-base__time h-flex-1-0'>
                    <FwSelect
                        v-model='recurrence'
                        :class='{ "b-recurrence--not-active": !isRecurrenceOptionsActive }'
                        :searchable='false'
                        :canNotDelete='true'
                        label='text'
                        :options='recurrenceOptions'>
                    </FwSelect>
                </div>
            </div>

            <div v-if='isRecurrenceOptionsActive'>
                <div class='h-width-100p h-flex h-mb-10'>
                    <FwCheckbox
                        id='testM'
                        v-model='dayList'
                        value='1'
                        type='form-light'
                        :text='$t(`CALENDAR.DAY.MONDAY.SYMBOL`)'
                        class='h-mh-5 h-flex-1-0'
                        :singleSelect='isEveryMonthStateActive || isEveryYearStateActive'
                        name='checkbox'>
                    </FwCheckbox>
                    <FwCheckbox
                        id='testT'
                        v-model='dayList'
                        value='2'
                        type='form-light'
                        :text='$t(`CALENDAR.DAY.TUESDAY.SYMBOL`)'
                        class='h-mh-5 h-flex-1-0'
                        :singleSelect='isEveryMonthStateActive || isEveryYearStateActive'
                        name='checkbox'>
                    </FwCheckbox>
                    <FwCheckbox
                        id='testW'
                        v-model='dayList'
                        value='3'
                        type='form-light'
                        :text='$t(`CALENDAR.DAY.WEDNESDAY.SYMBOL`)'
                        class='h-mh-5 h-flex-1-0'
                        :singleSelect='isEveryMonthStateActive || isEveryYearStateActive'
                        name='checkbox'>
                    </FwCheckbox>
                    <FwCheckbox
                        id='testT2'
                        v-model='dayList'
                        value='4'
                        type='form-light'
                        :text='$t(`CALENDAR.DAY.THURSDAY.SYMBOL`)'
                        class='h-mh-5 h-flex-1-0'
                        :singleSelect='isEveryMonthStateActive || isEveryYearStateActive'
                        name='checkbox'>
                    </FwCheckbox>
                    <FwCheckbox
                        id='testF'
                        v-model='dayList'
                        value='5'
                        type='form-light'
                        :text='$t(`CALENDAR.DAY.FRIDAY.SYMBOL`)'
                        class='h-mh-5 h-flex-1-0'
                        :singleSelect='isEveryMonthStateActive || isEveryYearStateActive'
                        name='checkbox'>
                    </FwCheckbox>
                    <FwCheckbox
                        id='testS'
                        v-model='dayList'
                        value='6'
                        type='form-light'
                        :text='$t(`CALENDAR.DAY.SATURDAY.SYMBOL`)'
                        class='h-mh-5 h-flex-1-0'
                        :singleSelect='isEveryMonthStateActive || isEveryYearStateActive'
                        name='checkbox'>
                    </FwCheckbox>
                    <FwCheckbox
                        id='testS2'
                        v-model='dayList'
                        value='7'
                        type='form-light'
                        :text='$t(`CALENDAR.DAY.SUNDAY.SYMBOL`)'
                        class='h-mh-5 h-flex-1-0'
                        :singleSelect='isEveryMonthStateActive || isEveryYearStateActive'
                        name='checkbox'>
                    </FwCheckbox>
                </div>
            </div>

            <div v-if='isEveryMonthStateActive || isEveryYearStateActive'
                 class='b-add-reserved-slot-form__label h-mb-10'>
                <div class='h-pos-rel b-calendar-select__week
                        fw-select-base fw-select-base__time h-flex-1-0'>
                    <FwSelect
                        v-model='weekIndex'
                        :class='{ "b-recurrence--not-active": !isWeekIndexActive }'
                        :searchable='false'
                        :canNotDelete='true'
                        label='text'
                        :options='weekIndexOptions'>
                    </FwSelect>
                </div>
            </div>

            <div v-if='isRecurrenceOptionsActive'>
                <FwLabelCard
                    :class='{ "b-add-reserved-slot-form__label--disabled": disableEndRecurrenceIsActive }'
                    class='h-mb-10'
                    height='40px'
                    padding='0 15px'>
                    <div class='b-add-reserved-slot-form__label'>
                        <div class='b-add-reserved-slot-form__label__text'
                             :class='{ "b-add-reserved-slot-form__label__text--active": endRecurrenceIsActive }'>
                            {{ $t('ADD.RESERVED.SLOT.FORM.END_RECURRENCE') }}
                        </div>
                        <FwSwitcher
                            :isActive='endRecurrenceIsActive'
                            :isRequestSending='false'
                            @toggleSwitch='endRecurrenceActiveSwitch'>
                        </FwSwitcher>
                    </div>
                </FwLabelCard>

                <div v-if='endRecurrenceIsActive'
                     class='h-flex-center h-mb-10'>
                    <FwDatepicker
                        v-model='endRecurrenceDate'
                        name='datepicker'
                        :placeholder='$t(`ADD.RESERVED.SLOT.FORM.RECURRENCE.CHOOSE_DAY.PLACEHOLDER`)'
                        :propsRange='false'
                        :propsLang='`${$i18n.locale}`'
                        :selectMode='false'
                        pickerStyle='form'
                        :hideIcon='true'
                        propsType='date'
                        propsFormat='DD/MM/YYYY'
                        :openOnTop='true'
                        :disabledDate='disableEndRecurrenceDays'
                        :defaultValue='startDay'
                        propsInputClass='b-add-reserved-slot-form__datepicker'>
                        <template #icon>
                            &nbsp;
                        </template>
                    </FwDatepicker>
                </div>
            </div>
        </div>
    </template>

    <div v-if='!this.isInstanceTitleStoppage' class='h-flex-center b-add-reserved-slot-form__button h-mh--10'>
        <FwButton
            v-if='isReservedSlotExists && !isOldSlot'
            size='little'
            class='b-add-reserved-slot-form__button__inner'
            color='alert'
            :disabled='isRequestSendingStatus'
            borderRadiusType='tiny-border'
            @click.stop.prevent='removeSlot'>
            {{ $t('LABEL.DELETE') }}
        </FwButton>
        <FwButton
            v-if='showSaveButton'
            size='little'
            class='b-add-reserved-slot-form__button__inner qa-advisor-reserved-slots__button-submit'
            :disabled='!isButtonAvailable || isRequestSendingStatus'
            borderRadiusType='tiny-border'
            @click.stop.prevent='addSlot'>
            {{ isReservedSlotExists ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import { State, namespace } from 'vuex-class';
import { TranslateResult } from 'vue-i18n';
import {
    FULL_TIME,
    FULL_TIME_EVERY_30,
    getHoursAndMinutesFromAMPM,
    formatDateForTitle,
    dateToString,
    equalByDay,
} from '@/helpers/dates';
import { AccountTitlesType } from '@/types/Account';
import { ReservedSlotsType } from '@/types/ReservedSlots';
import {
    CreateEventPayload,
    EventAvailableTypes,
    UnavailabilityEventInstancesType,
    CreateEventUnavailabilityType,
} from '@/types/Events/Workers';
import { DAILY, WEEKLY, MONTHLY, YEARLY } from '@/helpers/recurrence';
import { EventsWebApi } from '@/api/events/EventsApi';
import { ReservedSlotType } from '@/types/Events/ReservedSlots';
import DateMixin from '@/mixins/dateMixin';
import TranslateMixin from '@/mixins/TranslateMixin';
import { CategoryLocationSkillNameType, CategorySkillNameType } from '@/types/Appointment';
import { ON_SITE_ID } from '@/helpers/appointment';
import AccountMixin from '@/mixins/account';
import { EN_LOCAL } from '@/helpers/locale';
import { ShiftResponseFullData } from '@/types/Events';

const CommonStore = namespace('CommonStore');
const OnboardingStore = namespace('OnboardingStore');

const ALL_DAY_FROM = '00:00';
const ALL_DAY_TO = '23:59';

type RecurrenceOptionType = {
    text?: TranslateResult,
    value: string | null,
}

type TitleOptionType = {
    title: TranslateResult,
    value: string,
}

type WeekIndexOptionType = {
    text?: TranslateResult,
    value: number | null,
}

@Component
export default class AddReservedSlotForm extends Mixins(DateMixin, TranslateMixin, AccountMixin) {
    @Prop({ type: Array, required: true }) readonly skills!: Array<CategorySkillNameType>;
    @OnboardingStore.Action('fetchCategoryLocationSkillNames') fetchCategoryLocationSkillNames!: () => Promise<void>;
    @CommonStore.State('accountData') accountData!: AccountTitlesType;
    @OnboardingStore.State('categoryLocationSkillNames') categoryLocationSkillNames!: Array<CategoryLocationSkillNameType>;

    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: String, default: `` }) readonly shiftsAdvisorId!: string;
    @Prop({ type: Boolean, default: false }) readonly isReservedSots!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isShift!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isAvailabilitySlots!: boolean;
    @Prop({ type: Object, default: null }) readonly instanceData!: UnavailabilityEventInstancesType;
    @Prop({ type: String, default: 'unavailability' }) readonly type!: EventAvailableTypes;
    @Prop({ type: Boolean, default: false }) readonly isRequestSendingStatus!: boolean;
    @Prop({
        validator: prop => typeof prop === 'object' || prop === null,
        required: true,
    }) readonly reservedSlot!: ReservedSlotsType | null;

    calendarPanelKey: number = 1;

    title: TitleOptionType | null = null;

    allDayIsActive: boolean = false;
    timeFrom: string | TranslateResult = '';
    timeTo: string | TranslateResult = '';

    timeOptions: Array<string | TranslateResult> = [];

    recurrenceLoading: boolean = false;

    startDay: Date = this.date;
    endDay: Date = this.date;

    disableStartDay: boolean = false;
    disableEndDay: boolean = false;

    recurrence: RecurrenceOptionType = { value: null };
    recurrenceOptions: Array<RecurrenceOptionType> = [];

    dayList: Array<string> = [];

    weekIndex: WeekIndexOptionType = { value: null };
    weekIndexOptions: Array<WeekIndexOptionType> = [];

    endRecurrenceIsActive: boolean = false;
    disableEndRecurrenceIsActive: boolean = false;
    endRecurrenceDate: null | Date = null;

    isPseudoSlot: boolean = false;
    checkboxLocation: Array<CategoryLocationSkillNameType> = []

    get disabledDateByObjectStartDay() {
        return {
            disablePrevDays: new Date(),
        };
    }

    get disabledDateByObjectEndDay() {
        return {
            disablePrevDays: this.startDay || new Date(),
        };
    }

    get disableEndRecurrenceDays() {
        const startDate = new Date();
        const endDate = new Date(startDate.getTime());
        endDate.setFullYear(endDate.getFullYear() + 3);
        return {
            disablePrevDays: startDate,
            disableNextDays: endDate,
        };
    }

    get formTitle(): TranslateResult {
        if (this.isAvailabilitySlots) {
            return this.reservedSlot ? this.$t(`ADD.RESERVED.SLOT.FORM.AVAILABILITY.CHANGE.TITLE`) : this.$t('ADD.RESERVED.SLOT.FORM.AVAILABILITY.TITLE');
        }
        if (this.isReservedSots) {
            return this.$t('ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.TITLE');
        }
        if (this.isOldSlot) {
            if (this.isShift) {
                return this.$t('ADD.RESERVED.SLOT.FORM.SHIFT.TITLE.BASE');
            }
            return this.$t('ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE.BASE');
        }
        if (this.isShift) {
            return this.isReservedSlotExists ? this.$t('ADD.RESERVED.SLOT.FORM.CHANGE.SHIFT.TITLE') : this.$t('ADD.RESERVED.SLOT.FORM.ADD.SHIFT.TITLE');
        }
        return (
            this.isReservedSlotExists ?
                this.$t('ADD.RESERVED.SLOT.FORM.CHANGE.TITLE') :
                this.$t('ADD.RESERVED.SLOT.FORM.TITLE')
        );
    }

    get isUnavailability(): boolean {
        return !this.isReservedSots;
    }

    get isReservedSlotExists(): boolean {
        return !!(this.reservedSlot);
    }

    get showSaveButton(): boolean {
        if (this.isOldSlot || (this.isAvailabilitySlots && this.reservedSlot)) {
            return false;
        }
        if (!this.isReservedSots) {
            return true;
        }
        return !this.isReservedSlotExists;
    }

    get timeFromOptions(): Array<string | TranslateResult> {
        const currentIndex = this.timeOptions.findIndex(time => time === this.timeTo);
        return this.timeOptions.slice(0, currentIndex);
    }

    get timeToOptions(): Array<string | TranslateResult> {
        const currentIndex = this.timeOptions.findIndex(time => time === this.timeFrom);
        return this.timeOptions.slice(currentIndex + 1, this.timeOptions.length);
    }

    get isButtonAvailable(): boolean {
        if (this.isAvailabilitySlots) {
            return true;
        }

        if ((this.isReservedSots && !this.checkboxLocation.length)) {
            return false;
        }

        if ((this.isReservedSots || (this.title && this.title.value) || this.isShift)) {
            if (this.isRecurrenceOptionsActive) {
                if (this.endRecurrenceIsActive && !this.endRecurrenceDate) return false;
                if (this.isEveryDayStateActive && this.dayList.length === 0) return false;
                if (this.isEveryMonthStateActive || this.isEveryYearStateActive) {
                    if (this.dayList.length !== 0 && !this.isWeekIndexActive || this.isWeekIndexActive && this.dayList.length === 0) {
                        return false;
                    }
                }
            }
            if (this.isShift && !this.instanceData && !this.shiftsAdvisorId) {
                return false;
            }
            return (this.allDayIsActive || Boolean(this.timeTo && this.timeFrom));
        }
        return false;
    }

    get isRecurrenceOptionsActive() {
        return this.recurrence.value !== null;
    }

    get isWeekIndexActive() {
        return this.weekIndex.value !== null;
    }

    get isEveryDayStateActive(): boolean {
        return this.recurrence.value === DAILY;
    }

    get isEveryWeekStateActive(): boolean {
        return this.recurrence.value === WEEKLY;
    }

    get isEveryMonthStateActive(): boolean {
        return this.recurrence.value === MONTHLY;
    }

    get isEveryYearStateActive(): boolean {
        return this.recurrence.value === YEARLY;
    }

    get timeFromMin(): string | TranslateResult {
        return this.timeFrom || ALL_DAY_FROM;
    }

    get timeToMax(): string | TranslateResult {
        return this.timeTo || ALL_DAY_TO;
    }

    get dateTitle(): string {
        if (this.instanceData && this.isPseudoSlot && this.instanceData.dt_start_view && this.instanceData.dt_end_view) {
            return `${formatDateForTitle(new Date(this.instanceData.dt_start_view))} - ${formatDateForTitle(new Date(this.instanceData.dt_end_view))}`;
        }
        return formatDateForTitle(this.date);
    }

    get startDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeFromMin as string));
        return new Date(this.startDay.setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    get endDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeToMax as string));
        return new Date(this.endDay.setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    get preparedSlotData(): CreateEventPayload {
        const data: CreateEventPayload = {
            user_id: this.isShift ? this.shiftsAdvisorId : this.$route.params.id,
            dt_start: this.dateToString(this.startDate),
            dt_end: this.dateToString(this.endDate),
            full_day: this.allDayIsActive,
        };

        if (this.isUnavailability) {
            data.title = this.title ? this.title.value : '';
            data.origin = this.type;
        } else {
            data.locations = this.checkboxLocation.map(item => item.searchable_name);
        }

        if (this.recurrence.value) {
            data.freq = this.recurrence.value;
            if (this.isEveryMonthStateActive || this.isEveryYearStateActive) {
                data.week_index = this.weekIndex.value || null;
            }
            if (this.dayList.length) {
                data.by_day = this.dayList.map(item => +item);
            }
            data.dt_until = this.endRecurrenceDate ? this.dateToString(this.endRecurrenceDate) : null;
        } else {
            data.freq = null;
            data.week_index = null;
            data.by_day = [];
            data.dt_until = null;
        }
        return data;
    }

    get isFormDisable(): boolean {
        return !!(this.isReservedSots && this.reservedSlot) || this.isOldSlot || this.isInstanceTitleStoppage;
    }

    get isOldSlot(): boolean {
        if (equalByDay(new Date(), this.date)) {
            return false;
        }
        return +new Date() > +this.date;
    }

    get isOnSiteExists() {
        return this.categoryLocationSkillNamesFiltered.some(e => e.searchable_name === ON_SITE_ID);
    }

    get categoryLocationSkillNamesFiltered(): Array<CategoryLocationSkillNameType> {
        if (this.isUnavailability || !this.categoryLocationSkillNames || !this.categoryLocationSkillNames.length) return [];
        const skillIds = this.skills.map(skill => skill.searchable_name);
        return this.categoryLocationSkillNames.filter(item => skillIds.includes(item.searchable_name));
    }

    get isInstanceTitleStoppage(): boolean {
        return Boolean(this.instanceData && this.instanceData.title === 'stoppage');
    }

    get initialTitleOptions(): Array<TitleOptionType> {
        if (!this.accountData) return [];
        const key = this.$i18n.locale === EN_LOCAL ? `en_name` : `localized_name`;
        return this.accountData.unavailability_titles.map(item => ({ title: item[key], value: item.searchable_name }));
    }

    get titleOptions() {
        return this.isInstanceTitleStoppage ? [...this.initialTitleOptions, {
            title: this.$i18n.t('ADD.RESERVED.SLOT.FORM.TITLE.CLOSURE'),
            value: 'stoppage',
        }] : this.initialTitleOptions;
    }

    dateToString(date: Date): string {
        return dateToString(date);
    }

    endRecurrenceActiveSwitch(): void {
        if (!this.disableEndRecurrenceIsActive) {
            this.endRecurrenceIsActive = !this.endRecurrenceIsActive;
        }
    }

    async fetchRecurrenceData(instance: UnavailabilityEventInstancesType): Promise<CreateEventUnavailabilityType | ReservedSlotType> {
        const { event } = await EventsWebApi.getEvent(instance.event_id);
        return event;
    }

    async setInstanceData(instance: UnavailabilityEventInstancesType): Promise<void> {
        this.isPseudoSlot = Boolean(instance.is_pseudo_slot);
        this.allDayIsActive = instance.full_day;
        const dateStart = new Date(instance.dt_start_view || instance.dt_start);
        const dateEnd = new Date(instance.dt_end_view || instance.dt_end);
        const startAmpm = this.getCurrentDateAsMinutesAMPM(dateStart, false);
        const endAmpm = this.getCurrentDateAsMinutesAMPM(dateEnd, false);
        if (this.isPseudoSlot) {
            if (instance.dt_start_view) {
                this.startDay = new Date(instance.dt_start_view);
            }
            if (instance.dt_end_view) {
                this.endDay = new Date(instance.dt_end_view);
            }
        }
        this.title = this.titleOptions.find(item => item.value === instance.title) || null;
        if (instance.locations) {
            this.checkboxLocation = this.categoryLocationSkillNamesFiltered.filter(item => instance.locations.includes(item.searchable_name));
        }
        if (instance.is_recurrent || instance.parent_id || instance.recurrence_id) {
            this.recurrenceLoading = true;
            let event: CreateEventUnavailabilityType | ReservedSlotType | ShiftResponseFullData;
            if (this.isShift) {
                // @ts-ignore
                event = instance;
            } else {
                event = await this.fetchRecurrenceData(instance);
            }
            this.recurrenceLoading = false;
            const recurrenceType = event.freq;
            const recurrence = this.recurrenceOptions.find(item => recurrenceType === item.value);
            const weekIndex = this.weekIndexOptions.find(item => event.week_index === item.value);
            this.weekIndex = weekIndex || this.weekIndexOptions[0];
            if (recurrence) {
                this.recurrence = recurrence;
            }
            if (event.by_day) {
                // @ts-ignore-next-line
                this.dayList = event.by_day.map(item => item.toString());
            } else {
                event.by_day = [];
            }
            if (event.dt_until) {
                this.endRecurrenceIsActive = true;
                this.endRecurrenceDate = new Date(event.dt_until);
            } else {
                this.endRecurrenceIsActive = false;
                this.endRecurrenceDate = null;
            }
        } else {
            this.setRecurrenceBaseValues();
        }

        if (this.allDayIsActive) {
            this.timeFrom = '';
            this.timeTo = '';
        } else {
            this.timeFrom = startAmpm;
            this.timeTo = endAmpm;
        }
    }

    setRecurrenceToBaseValue(): void {
        this.recurrence = this.recurrenceOptions[0];
    }

    setSelectOptions(): void {
        const recurrenceOptions = [
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.NO_RECURRENCE'),
                value: null,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_DAY'),
                value: DAILY,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_WEEK'),
                value: WEEKLY,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_MONTH'),
                value: MONTHLY,
            },
        ];
        if (this.isUnavailability) {
            recurrenceOptions.push(
                {
                    text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_YEAR'),
                    value: YEARLY,
                }
            );
        }
        this.recurrenceOptions = recurrenceOptions;
        this.weekIndexOptions = [
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.ANY'),
                value: null,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.1'),
                value: 1,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.2'),
                value: 2,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.3'),
                value: 3,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.4'),
                value: 4,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.LAST_WEEK'),
                value: -1,
            },
        ];
    }

    setTimeToBaseValues(): void {
        this.startDay = this.date;
        this.endDay = this.date;
        if (this.isReservedSots || this.isUnavailability) {
            this.timeOptions = FULL_TIME_EVERY_30.map(time => this.$i18n.t(time));
        } else {
            this.timeOptions = FULL_TIME.map(time => this.$i18n.t(time));
        }
        this.timeFrom = this.$i18n.t('CALENDAR.TIME.08.AM');
        this.timeTo = this.isReservedSots ? this.$i18n.t('CALENDAR.TIME.12.PM') : this.$i18n.t('CALENDAR.TIME.06.PM');
    }

    setBaseValues(): void {
        this.title = null;
        this.allDayIsActive = false;
        this.checkboxLocation = [];
        this.setTimeToBaseValues();
        this.setRecurrenceBaseValues();
    }

    setRecurrenceBaseValues() {
        this.endRecurrenceIsActive = false;
        this.dayList = [];
        this.recurrence = this.recurrenceOptions[0];
        this.weekIndex = this.weekIndexOptions[0];
        this.endRecurrenceDate = null;
        this.setRecurrenceToBaseValue();
    }

    isLocationSkillDisabled(item: CategorySkillNameType): boolean {
        // ON_SITE_ID can be choose only separate with another skills
        const isCategorySiteChoose = this.checkboxLocation.some(e => e.searchable_name === ON_SITE_ID);
        if (isCategorySiteChoose) {
            return item.searchable_name !== ON_SITE_ID;
        }
        if (this.isOnSiteExists && this.checkboxLocation.length) {
            return item.searchable_name === ON_SITE_ID;
        }
        return false;
    }

    updateDayEndAfterChangeRecurrence(value: boolean) {
        if (value) {
            this.endDay = this.startDay;
            this.disableEndDay = true;
        } else {
            this.dayList = [];
            this.disableEndDay = false;
        }
    }

    async created() {
        if (this.isReservedSots && !this.categoryLocationSkillNames.length) {
            this.recurrenceLoading = true;
            await this.fetchCategoryLocationSkillNames();
            this.recurrenceLoading = false;
        }
        this.setSelectOptions();
        this.setTimeToBaseValues();
        this.setRecurrenceBaseValues();
        if (this.instanceData) {
            this.setInstanceData(this.instanceData);
        } else if (this.categoryLocationSkillNamesFiltered.length === 1) {
            this.checkboxLocation = [...this.categoryLocationSkillNamesFiltered];
        }
    }

    @Emit('addSlot')
    addSlot() {
        return this.preparedSlotData;
    }

    @Emit('removeSlot')
    removeSlot() {
        if (this.isAvailabilitySlots) {
            return this.reservedSlot;
        }

        return this.instanceData;
    }

    @Emit('closePopup')
    close() {}

    @Watch('instanceData')
    instanceDataHandler(instance: any | null) {
        if (instance) {
            this.setInstanceData(instance);
        } else {
            this.setBaseValues();
        }
    }

    @Watch('isEveryDayStateActive')
    isEveryDayStateActiveHandler(value: boolean) {
        this.updateDayEndAfterChangeRecurrence(value);
    }

    @Watch('isEveryWeekStateActive')
    isEveryWeekStateActiveHandler(value: boolean) {
        this.updateDayEndAfterChangeRecurrence(value);
    }

    @Watch('isEveryMonthStateActive')
    isEveryMonthStateActiveHandler(value: boolean) {
        this.updateDayEndAfterChangeRecurrence(value);
    }

    @Watch('isEveryYearStateActive')
    isEveryYearStateActiveHandler(value: boolean) {
        this.endRecurrenceIsActive = true;
        this.disableEndRecurrenceIsActive = value;
        this.updateDayEndAfterChangeRecurrence(value);
    }

    @Watch('startDay')
    startDayHandler(startDay: Date) {
        if ((this.isReservedSots || this.isShift) && startDay) {
            this.endDay = startDay;
        } else if (startDay > this.endDay) {
            this.endDay = startDay;
        }
        this.calendarPanelKey += 1;
    }

    @Watch('endDay')
    endDayHandler(endDay: Date) {
        if (endDay < this.startDay) {
            this.startDay = endDay;
        }
        this.calendarPanelKey += 1;
    }

    @Watch('date')
    dateHandler(value: Date) {
        if (value) {
            if (!this.isPseudoSlot) {
                this.startDay = value;
                this.endDay = value;
            }
        }
    }

    @Watch('recurrence')
    recurrenceHandler(value: boolean) {
        if (value) {
            if (this.isEveryMonthStateActive || this.isEveryYearStateActive) {
                if (this.dayList.length > 1) {
                    this.dayList = [];
                }
            }
        } else {
            this.disableEndDay = this.isReservedSots || this.isShift;
        }
    }

    @Watch('allDayIsActive')
    allDayIsActiveHandler(value: boolean) {
        if (value) {
            this.timeFrom = '';
            this.timeTo = '';
        } else {
            if (!this.timeFrom) {
                this.timeFrom = this.$i18n.t('CALENDAR.TIME.08.AM');
            }
            if (!this.timeTo) {
                this.timeTo = this.$i18n.t('CALENDAR.TIME.12.PM');
            }
        }
    }

    @Watch('dayList')
    dayListHandler(list: Array<string>) {
        this.disableStartDay = Boolean(list.length);
        if (list.length) {
            this.disableEndDay = true;
        } else {
            this.disableEndDay = this.isReservedSots || this.isShift || this.isEveryDayStateActive || this.isEveryWeekStateActive || this.isEveryMonthStateActive;
        }
    }
}
</script>

<style lang='sass'>
.b-reserved-slots_on-site__hint
    font-size: 13px
    text-align: left

.h-uppercase
    text-transform: uppercase

.b-add-reserved-slot-form
    text-align: center

    .mx-datepicker
        padding: 0 10px

    .multiselect__content-wrapper
        max-height: 200px!important

    &__loading
        width: 100%
        height: 100%
        position: absolute
        left: 0
        top: 0
        display: flex
        align-items: center
        justify-content: center
        z-index: 5

    .fw-label-card-wrapper
        background-color: #F0F2F5

    .multiselect__single, .multiselect__option
        font-weight: 500 !important

    .multiselect__single, .multiselect__option
        font-size: 14px !important

    .fw-select-base .multiselect:after
        display: none !important

    &__title
        font-size: 14px
        line-height: 17px
        color: $main-gray
        text-transform: uppercase
        font-weight: bold

    &__date
        font-size: 25px
        font-weight: bold
        color: $dark-blue
        margin: 15px 0 20px

    &__label
        display: flex
        align-items: center
        justify-content: space-between
        height: 100%
        .multiselect__option
            text-transform: uppercase!important

        &--disabled
            position: relative
            opacity: 0.5
            &:after
                content: ''
                background-color: rgba(0,0,0,0.1)
                border-radius: 10px
                width: 100%
                height: 100%
                position: absolute
                z-index: 1
                left: 0
                top: 0

    &__label__text
        font-size: 14px
        color: $main-gray
        font-weight: 500 !important

        &--active
            color: $dark-blue

    &__button
        z-index: 4
        position: relative

        &__inner
            margin-top: 20px
            margin-left: 10px
            margin-right: 10px
            flex: 1 1 50%

    &__to
        width: 40px
        margin: 0 10px
        color: $main-gray

    &__wrapper
        position: relative

        &--disable
            &:after
                position: absolute
                content: ''
                left: 0
                width: 100%
                top: 0
                height: 100%
                z-index: 10

            .multiselect__single,
            .b-add-reserved-slot-form__label__text--active,
            .mx-datepicker--form .mx-input,
            .b-add-reserved-slot-form__datepicker.mx-input
                color: #787e85 !important
            .fw-switcher__slider, .fw-check-box__wrapper.fw-is-selected .fw-check-box__container
                background-color: #787e85 !important
            .fw-check-box__container
                color: #fff

    .fw-check-box__container.form-light
        height: 37px
        padding: 5px
        flex: 0 0 37px
        font-weight: 500

    &__datepicker
        font-weight: 500 !important

        &::placeholder
            font-weight: 500 !important
            overflow: auto !important
            width: 100% !important

    &__close_popup
        position: absolute
        right: 40px
        top: 40px
        cursor: pointer
        transition: opacity .3s
        will-change: opacity

        &:hover
            opacity: 0.7

    .multiselect__placeholder
        font-size: 14px !important
        font-weight: 500 !important
        color: #BEC7D3 !important

.b-recurrence--not-active
    text-transform: uppercase
    .multiselect__single, .multiselect__option
        color: $main-gray !important

.b-add-reserved-slot-form__day_datepicker
    .mx-datepicker-popup
        min-width: 290px
        left: -85px !important
        margin-top: 50px

    .mx-input
        font-weight: 500 !important
        &::placeholder
            opacity: 0 !important

.b-calendar-select__time
    .multiselect__tags
        display: flex
        align-items: center
        justify-content: center

    .multiselect__tags
        background: transparent !important

    .multiselect__option
        text-align: center

.h-width-115
    min-width: 115px
    width: 115px

.b-reserved-slots__category-locations
    background-color: #F0F2F5
    border-radius: 10px
    padding: 10px
    margin-bottom: 10px

    &__title
        font-size: 14px !important
        color: #BEC7D3 !important
        font-weight: 500 !important
        text-transform: uppercase
        text-align: left

    .fw-check-box__container
        height: 35px !important
        padding: 8px 8px !important
        font-size: 11px !important
        border-radius: 16px !important

    &__inner
        display: flex
        align-items: flex-start
        flex-wrap: wrap

    &--disabled
        position: relative
        opacity: 0.5
        &:after
            content: ''
            background-color: rgba(0,0,0,0.1)
            border-radius: 10px
            width: 100%
            height: 100%
            position: absolute
            z-index: 1
            left: 0
            top: 0
</style>
