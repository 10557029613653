<template>
<div class='b-consult-appts-main h-flex h-flex-dir-column'>
    <div class='b-consult-appt-wrapper'>
        <div class='b-consult-card__inner'>
            <GoBackButton
                :isRelativePosition='isMobile()'
                class='h-mt-30'>
            </GoBackButton>
        </div>
        <div class='b-consult-appts__left h-flex h-flex-dir-column h-align-items-center'>
            <h1 class='b-consult-appointments__title'>
                {{ $t(isAdvisorTypePage ? 'TITLE.ADVISOR.ADVANCED.SEARCH' : 'TITLE.MANAGER.ADVANCED.SEARCH') }}
            </h1>
            <div class='h-flex h-flex-wrap h-flex-justify-center h-width-100p'>
                <form class='b-consult-appointments__form'
                      @keyup.enter='onSubmit(currentPage, perPage)'>
                    <div class='b-consult-appointments__inner'>
                        <FwFormInput
                            class='h-flex-1-0 h-m-10'
                            labelType='label-medium'
                            :title='$t(`LABEL.FIRST.NAME`)'>
                            <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                <FwSelect
                                    v-model='first_name'
                                    :propsPlaceholder='$t(`LABEL.TYPE.FIRST.NAME`)'
                                    type='white'
                                    :options='worker_first_name_array'
                                    :searchable='true'
                                    noElementsFoundText=''
                                    :loading='is_loading_first_name'
                                    :show-no-results='false'
                                    :clear-on-select='false'
                                    :hide-selected='true'
                                    openDirection='bottom'
                                    showRightIcon
                                    @search-change='searchWorker($event, `first_name`)'>
                                    <template #noOptions>
                                        {{ $t('LABEL.NO.RESULT') }}
                                    </template>
                                    <template #noResult>
                                        {{ $t('LABEL.NO.RESULT') }}
                                    </template>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                        <FwFormInput
                            class='h-flex-1-0 h-m-10'
                            labelType='label-medium'
                            :title='$t(`LABEL.LAST.NAME`)'>
                            <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                <FwSelect
                                    v-model='last_name'
                                    :propsPlaceholder='$t(`LABEL.TYPE.LAST.NAME`)'
                                    type='white'
                                    :options='worker_last_name_array'
                                    :searchable='true'
                                    noElementsFoundText=''
                                    :loading='is_loading_last_name'
                                    :show-no-results='false'
                                    :clear-on-select='false'
                                    :hide-selected='true'
                                    openDirection='bottom'
                                    showRightIcon
                                    @search-change='searchWorker($event, `last_name`)'>
                                    <template #noOptions>
                                        {{ $t('LABEL.NO.RESULT') }}
                                    </template>
                                    <template #noResult>
                                        {{ $t('LABEL.NO.RESULT') }}
                                    </template>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                        <FwFormInput
                            class='h-flex-1-0 h-m-10'
                            labelType='label-medium'
                            :title='$t(`LABEL.EMAIL`)'>
                            <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                <FwSelect
                                    v-model='email'
                                    :propsPlaceholder='$t(`LABEL.TYPE.EMAIL`)'
                                    type='white'
                                    :options='worker_email_array'
                                    :searchable='true'
                                    noElementsFoundText=''
                                    :loading='is_loading_email'
                                    :show-no-results='false'
                                    :clear-on-select='false'
                                    :hide-selected='true'
                                    openDirection='bottom'
                                    showRightIcon
                                    @search-change='searchWorker($event, `email`)'>
                                    <template #noOptions>
                                        {{ $t('LABEL.NO.RESULT') }}
                                    </template>
                                    <template #noResult>
                                        {{ $t('LABEL.NO.RESULT') }}
                                    </template>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                        <template v-if='isAdvisorTypePage'>
                            <FwFormInput
                                v-if='skills'
                                labelType='label-medium'
                                class='h-flex-1-0 h-m-10'
                                :title='`${$t(`WORKER.FORM.SKILLS.LABEL`)}`'>
                                <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                    <FwSelect
                                        v-model='skillChoosed'
                                        class='fw-select-with-icon--big'
                                        :propsPlaceholder='$t(`LABEL.SELECT.SKILL`)'
                                        type='white'
                                        :searchable='false'
                                        :label='labelKey'
                                        openDirection='bottom'
                                        :clearable='false'
                                        :multiple='true'
                                        :withCircles='true'
                                        :canNotDelete='false'
                                        :options='skills'>
                                    </FwSelect>
                                </div>
                            </FwFormInput>
                            <FwFormInput
                                v-if='categoryLocationSkills'
                                labelType='label-medium'
                                class='h-flex-1-0 h-m-10'
                                :title='`${$t(`LABEL.APPOINTMENT.METHODS`)}`'>
                                <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                    <FwSelect
                                        v-model='locationSkillChoosed'
                                        class='fw-select-with-icon--big'
                                        :propsPlaceholder='$t(`LABEL.SELECT.APPOINTMENT.METHOD`)'
                                        type='white'
                                        :searchable='false'
                                        :label='labelKey'
                                        openDirection='bottom'
                                        :clearable='false'
                                        :multiple='true'
                                        :withCircles='true'
                                        :canNotDelete='false'
                                        :options='categoryLocationSkills'>
                                    </FwSelect>
                                </div>
                            </FwFormInput>
                        </template>
                        <FwFormInput
                            v-else
                            labelType='label-medium'
                            class='h-flex-1-0 h-m-10'
                            :title='`${$t(`LABEL.ROLE`)}`'>
                            <div class='fw-select-base fw-select-white-modern h-width-100p'>
                                <FwSelect
                                    v-model='valueRole'
                                    class='fw-select-with-icon--big'
                                    :propsPlaceholder='$t(`LABEL.SELECT.ROLE`)'
                                    type='white'
                                    :searchable='false'
                                    openDirection='bottom'
                                    :clearable='false'
                                    :withCircles='true'
                                    :canNotDelete='false'
                                    :options='rolesNames'>
                                </FwSelect>
                            </div>
                        </FwFormInput>
                    </div>
                </form>
                <div class='b-nav__buttons_wrapper'>
                    <FwButton
                        borderRadiusType='small-border'
                        @click='onSubmit(currentPage, perPage)'>
                        {{ $t('BUTTON.SEARCH') }}
                    </FwButton>
                    <div class='b-add-edit__form__delete h-mt-15'
                         @click='clearInputs'>
                        {{ $t('BUTTON.CLEAR.ALL') }}
                    </div>
                </div>
            </div>
        </div>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-mt-40 loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <div class='b-consult-appts__right h-flex h-width-100p h-flex-dir-column h-align-items-center'>
            <div class='b-appointment-types__list__wrapper h-width-100p'>
                <div v-if='!workerResultListData.length' class='h-flex h-flex-justify-center'>
                    <div v-if='!loading' class='h-flex h-flex-dir-column h-align-items-center h-mt-40'>
                        <FwIcon
                            class='h-pointer'
                            icon='loupe'
                            size='45'
                            color='#BEC7D4'>
                        </FwIcon>
                        <h2 class='b-appt-result__title'>
                            {{ isNoResult ? $t('LABEL.NO.RESULT') : $t('LABEL.RESULT.HERE.INFO') }}
                        </h2>
                    </div>
                </div>
                <div v-if='!loading && workerResultListData.length' class='h-width-100p h-flex h-flex-dir-column h-flex-center'>
                    <CalendarTable
                        :titles='workerTitleListKeys'
                        :titleHeader='`${$tc(`SETTINGS.TEAM.PEOPLE.RESULTS.PLURAL`, totalPage)} (${totalPage})`'
                        :data='workerResultListData'
                        :bigIndexes='bigIndexes'
                        class='b-appointment__table__wrapper'>
                        <component
                            :is='componentType'
                            v-for='(item, i) in workerResultListData'
                            :key='i'
                            v-bind='getAttrs(item.id)'
                            class='b-calendar__table'
                            @click='isAdvisorTypePage ? null : openManagerPopup(item)'>
                            <div class='b-appointment__table__outer h-pointer'>
                                <div v-for='(key, j) in mapping'
                                     :key='j'
                                     class='b-appointment__table__item'
                                     :class='{ "b-calendar__table__item--big": bigIndexes.includes(j) }'>
                                    <template v-if='item[key] && item[key].length'>
                                        <div class='b-appointment__table__item-text'>
                                            {{ getItemText(item, key) }}
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </component>
                    </CalendarTable>
                    <FwPagination
                        v-if='totalPage > perPage'
                        style='position: sticky; bottom: 0;'
                        :totalPage='totalPage'
                        :currentPage='currentPage'
                        :perPage='perPage'
                        :value='perPage'
                        @getData='onSubmit'>
                    </FwPagination>
                </div>
            </div>
        </div>
    </div>
    <AddManagerPopup
        v-if='isManagerPopupOpen'
        :manager='currentManager'
        @close='closeManagerPopup'>
    </AddManagerPopup>
</div>
</template>

<script lang='ts'>
import { isEmpty, clone, is } from 'ramda';
import { RawLocation } from 'vue-router';
import { Component, Mixins } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';
import { AppointmentConsultSearchType, CategoryLocationSkillNameType } from '@/types/Appointment';
import { AdvisorSkillType, User, UserManagerProfileRole } from '@/types/User';
import { AddManagerPopup } from '@/components/popups/AddManagerPopup';
import { CalendarTable } from '@/components/common/CalendarTable';
import { GoBackButton } from '@/components/simple/GoBackButton';

import {
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
} from '@/helpers/worker';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import TranslateMixin from '@/mixins/TranslateMixin';
import { capitalizeAll } from '@/helpers/string';

import { SettingsManagerListType, SettingsRoleSimpleType } from '@/types/Settings';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { PreparedAdvancedSearchParams } from '@/types/Consult';

const ConsultStore = namespace('ConsultStore');
const SettingsStore = namespace('SettingsStore');

const KEYS_FOR_CAPITALIZE_ADVISORS = [`first_name`, `last_name`, `email`, `skills`, `location_skills`];
const KEYS_FOR_CAPITALIZE_MANAGERS = [`first_name`, `last_name`, `email`, `role`];

interface roleSelectedType {
    name: string,
    id: string,
}

@Component({
    components: {
        CalendarTable,
        GoBackButton,
        AddManagerPopup,
    },
})
export default class AdvancedWorkerSearch extends Mixins(TranslateMixin) {
    @ConsultStore.Mutation('setAdvancedSearch') setAdvancedSearch!: (data: PreparedAdvancedSearchParams) => void;
    @SettingsStore.Mutation('setRoles') setRoles!: (roles: Array<SettingsRoleSimpleType>) => void;
    @State(state => state.SettingsStore.roles) roles!: Array<UserManagerProfileRole>;
    @State(state => state.ConsultStore.advancedSearchData) advancedSearchData!: null | PreparedAdvancedSearchParams;

    isPageToUpdate: boolean = false;
    isManagerPopupOpen: boolean = false;
    managerLoading: boolean = false;
    [EMAIL]: string = '';
    [FIRST_NAME]: string = '';
    [LAST_NAME]: string = '';
    EMAIL: string = EMAIL;
    FIRST_NAME: string = FIRST_NAME;
    LAST_NAME: string = LAST_NAME;
    skills: Array<CategoryLocationSkillNameType> = [];
    skillChoosed: Array<CategoryLocationSkillNameType> = [];
    valueRole: roleSelectedType | null = null;
    categoryLocationSkills: Array<CategoryLocationSkillNameType> = [];
    locationSkillChoosed: Array<CategoryLocationSkillNameType> = [];
    worker_first_name_array: Array<string> = [];
    worker_last_name_array: Array<string> = [];
    worker_email_array: Array<string> = [];
    isNoResult: boolean = false;
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;
    text: string = '';
    param: string = '';
    paramAutocomplete: string = '';
    timeout: number | NodeJS.Timeout = 0;
    loading: boolean = false;
    bigIndexes: Array<number> = [];
    is_loading_first_name: boolean = false;
    is_loading_last_name: boolean = false;
    is_loading_email: boolean = false;

    workerTitleListKeys: Array<string> = [];
    mapping: Array<string> = [];

    workerResultListData: Array<AppointmentConsultSearchType> = [];

    currentManager: User | null = null;

    $refs!: {
        leftBar: HTMLElement
    };

    get componentType(): 'router-link' | `div` {
        return this.isAdvisorTypePage ? `router-link` : `div`;
    }

    get firstName() {
        return this.first_name ? { first_name: this.first_name } : {};
    }

    get lastName() {
        return this.last_name ? { last_name: this.last_name } : {};
    }

    get emailName() {
        return this.email ? { email: this.email } : {};
    }

    get roleName() {
        return this.valueRole ? { role: this.valueRole } : {};
    }

    get isAdvisorTypePage() {
        return this.$route.path.includes('advisor');
    }

    get pageType() {
        return this.isAdvisorTypePage ? 'advisors' : 'managers';
    }

    get pageTypeAutocomplete() {
        return this.isAdvisorTypePage ? '' : 'manager_';
    }

    get rolesNames() {
        return this.roles ? this.roles.map(role => role.name) : [];
    }

    get preparedSearchParams(): PreparedAdvancedSearchParams {
        return {
            worker_first_name_array: this.worker_first_name_array,
            first_name: this.first_name,
            worker_last_name_array: this.worker_last_name_array,
            last_name: this.last_name,
            worker_email_array: this.worker_email_array,
            email: this.email,
            skillChoosed: this.skillChoosed,
            skills: this.skills,
            categoryLocationSkills: this.categoryLocationSkills,
            locationSkillChoosed: this.locationSkillChoosed,
            rolesNames: this.rolesNames,
            valueRole: this.valueRole,
            currentPage: this.currentPage,
        };
    }

    getAttrs(id: string): { to: RawLocation } | null {
        if (this.isAdvisorTypePage) {
            return {
                to: { path: `/consult/advisor/${id}` },
            };
        }
        return null;
    }

    clearInputs() {
        this[FIRST_NAME] = '';
        this[LAST_NAME] = '';
        this[EMAIL] = '';
        this.skillChoosed = [];
        this.locationSkillChoosed = [];
        this.valueRole = null;
        this.workerResultListData = [];
    }

    async openManagerPopup(manager: SettingsManagerListType) {
        this.managerLoading = true;
        this.currentManager = await SettingsWebApi.getManager(manager.id);
        this.managerLoading = false;
        this.isManagerPopupOpen = true;
    }

    async onSubmit(el: number, val: number) {
        this.isNoResult = false;
        const url = { ...this.firstName, ...this.lastName, ...this.emailName, ...this.roleName };
        const skills = this.locationSkillChoosed.length || this.skillChoosed.length ?
            [...this.locationSkillChoosed, ...this.skillChoosed].map(skill => skill.searchable_name) :
            null;
        let urlArrayString = '';

        if (skills) {
            const parts = skills.map(param => {
                return (
                    `skill[]=${encodeURIComponent(param)}`
                );
            });
            urlArrayString = `&${parts.join('&')}`;
        }

        try {
            if (!isEmpty(url) || skills) {
                this.loading = true;
                const payload: any = {
                    current_page: el,
                    per_page: val,
                };
                if (this.isPageToUpdate) {
                    payload.current_page = 1;
                }

                const { data } = await WorkersWebApi.getWorkerDataByAdvancedSearch(payload, url, this.pageType, urlArrayString);
                if (data.users && !data.users.length && !data.meta) {
                    this.workerResultListData = [];
                    this.totalPage = 0;
                    this.currentPage = 1;
                    this.isNoResult = true;
                } else {
                    this.workerResultListData = data.users.map((user: any) => {
                        const clonedSkills = clone(user.skills);

                        user.skills = this.skills.filter(item => {
                            return user.skills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                        });

                        user.location_skills = this.categoryLocationSkills.filter(item => {
                            return clonedSkills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                        });

                        return user;
                    });
                    this.loading = false;
                    this.totalPage = data.meta.total_count;
                    this.currentPage = data.meta.current_page;
                    if (!this.workerResultListData.length) {
                        this.isNoResult = true;
                    }
                }
                if (!this.isNoResult) {
                    this.setAdvancedSearch(this.preparedSearchParams);
                }
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
            this.isPageToUpdate = false;
        }
    }

    async getWorkersBySearch(searchText: string, param: string) {
        this[`is_loading_${param}`] = true;

        this.paramAutocomplete = param;
        const keyData = this.isAdvisorTypePage ? this.pageTypeAutocomplete : '';

        const payload: any = {
            text: searchText,
        };
        const { data } = await WorkersWebApi.getWorkerAutocompleteSearch(payload, param, this.pageTypeAutocomplete);
        if (data[`${keyData}${param}_autocomplete`] && data[`${keyData}${param}_autocomplete`].length) {
            const workerArray = data[`${keyData}${param}_autocomplete`];
            this[`worker_${param}_array`] = [...this[`worker_${param}_array`], ...workerArray];
        }

        this[`is_loading_${param}`] = false;
    }

    searchWorker(text: string, param: string) {
        this.text = text;
        this.param = param;
        if (text.length >= 3) {
            this[`worker_${param}_array`] = [];
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                this.getWorkersBySearch(text, param);
            }, 500);
        }
    }

    getItemText(value: { [key: string]: string }, key: string) {
        if (!value[key]) {
            return ``;
        }

        if (Array.isArray(value[key])) {
            // @ts-ignore-next-line
            value[key] = (value[key] as Array<AdvisorSkillType>).map((val: AdvisorSkillType) => val[this.labelKey]).join(', ');
        }

        if (this.isAdvisorTypePage) {
            return KEYS_FOR_CAPITALIZE_ADVISORS.includes(key) ? capitalizeAll(value[key]) : value[key];
        }

        return KEYS_FOR_CAPITALIZE_MANAGERS.includes(key) ? capitalizeAll(value[key]) : value[key];
    }

    closeManagerPopup(manager: User) {
        if (is(Object, manager)) {
            this.workerResultListData = this.workerResultListData.map(item => {
                if (item.id === manager.id) {
                    return { ...item, ...manager };
                }

                return item;
            });
        } else if (is(String, manager)) {
            this.workerResultListData = this.workerResultListData.filter(item => item.id !== (manager as any));
            this.totalPage = this.workerResultListData.length;
        }

        this.isManagerPopupOpen = false;
    }

    async getRoles() {
        const roles = await SettingsWebApi.getSettingsRoleNames();
        this.setRoles(roles);
    }

    async created() {
        if (this.isAdvisorTypePage) {
            const { data } = await WorkersWebApi.getAdvisorHelpersData();
            if (!this.skills.length) {
                this.skills = data.skill_names;
            }
            this.categoryLocationSkills = data.category_location_skill_names;

            this.mapping = [
                `first_name`,
                `last_name`,
                `email`,
                `skills`,
                `location_skills`,
            ];

            this.workerTitleListKeys = [
                'LABEL.FIRST.NAME',
                'LABEL.LAST.NAME',
                'LABEL.EMAIL',
                'WORKER.FORM.SKILLS.LABEL',
                'LABEL.APPOINTMENT.METHODS',
            ];
        } else {
            await this.getRoles();

            this.mapping = [
                `first_name`,
                `last_name`,
                `email`,
                `role`,
            ];

            this.workerTitleListKeys = [
                'LABEL.FIRST.NAME',
                'LABEL.LAST.NAME',
                'LABEL.EMAIL',
                'LABEL.ROLE',
            ];
        }

        if (this.advancedSearchData) {
            const keys = Object.keys(this.advancedSearchData);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                // @ts-ignore
                this[key] = this.advancedSearchData[key];
            }
            this.onSubmit(this.currentPage, this.perPage);
        }
    }
}
</script>

<style lang='sass'>
.b-consult-appts-main
    height: 100%
    display: flex
    flex-flow: column nowrap
    justify-content: flex-start
    align-items: center

    &__popup
        .fw-popup
            min-height: 620px
            justify-content: start !important

    .multiselect__single, .multiselect__tags input
        font-weight: 500

    .b-consult-appt-wrapper
        max-width: 1300px
        width: 100%

    .b-appt-result__title
        color: rgba(33,63,107,0.5)
        font-weight: 500

    .b-appt-button__clear
        text-transform: uppercase
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

.b-consult-appointments__form
    width: 100%
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center

    &__row
        display: flex
        width: 100%
        margin-bottom: 20px

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-consult-appointments__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px

        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7

    .mx-datepicker
        background: #ffffff
        min-height: 60px
        border-radius: 10px
        font-weight: 500 !important
        box-shadow: $big-box-shadow
        line-height: unset

        .mx-datepicker-popup
            width: 350px

        .b-add-reserved-slot-form__datepicker
            &::placeholder
                font-size: 16px
                color: rgba(33,63,107,0.5)
                font-weight: 500

    .multiselect--active
        .fw-select__caret
            opacity: 0!important

    .multiselect__placeholder div
        color: rgba(33, 63, 107, 0.5)!important
        font-size: 16px!important

.b-appointment-types
    &__search
        width: 350px

    &__sort
        width: 350px

        &__wrapper
            margin-top: 10px
            display: flex
            justify-content: space-between
            align-items: baseline

    &__list
        &__wrapper
            width: 100%
            height: 100%

        &__select
            width: 100%

        &__flex
            display: flex
            margin-bottom: 10px

            &--disabled
                opacity: 0.5
                position: relative
                &:after
                    content: ''
                    position: absolute
                    left: 0
                    top: 0
                    cursor: not-allowed
                    width: 100%
                    height: 100%

            &--edit
                .b-settings-reminder-types__list__type
                    padding: 0
                .fw-select
                    box-shadow: none !important

            &--head
                .b-settings-reminder-types__list__type
                    padding: 0 0 0 0
                    color: #213F6B
                    font-size: 14px
                    font-weight: 500

                .b-settings-reminder-types__list__icon
                    background-color: transparent

        &__icon
            flex: 0 0 53px
            margin-left: 10px
            display: flex
            align-items: center
            justify-content: center
            background-color: #fff
            border-radius: 5px

            &__wrapper
                height: 100%
                width: 100%
                display: flex
                align-items: center
                justify-content: center
                flex-direction: column
                cursor: pointer
                &:hover
                    opacity: 0.9

            &__success
                background-color: #27DBBD
                color: #fff
                flex-direction: column

            &__cancel
                background-color: #EB2767
                color: #fff
                flex-direction: column

            &__text
                font-size: 9px
                text-transform: uppercase
                color: #fff
                margin-top: 7px
                font-weight: 500

        &__type
            flex: 1 0
            color: #203f6a
            display: flex
            font-weight: 500
            padding: 16px 10px 16px 20px

            &--big
                flex: 2 0

        &__white-bg
            background-color: #fff
            border-radius: 5px
            display: flex
            width: 100%

.b-appointment__table__outer
    background-color: #ffffff
    display: flex
    flex: 1
    border-radius: 5px
    height: 60px

.b-appointment__table__item
    flex: 1 0
    width: auto
    display: flex
    align-items: center
    justify-content: space-between
    margin: 10px
    font-weight: 500
    overflow: hidden
    text-overflow: ellipsis
    text-align: left

    @include media('<=phone')
        margin: 7px 2px
        font-size: 12px

    &-text
        max-height: 100%
        overflow: hidden
        text-align: left
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2

.b-appointment__table__wrapper
    width: 100%
    max-width: 100%
    height: 100%

    .b-calendar__table__title
        padding: 0 10px

    .b-calendar__table__header__item
        text-transform: none
        flex: 1 0
        padding: 10px
        min-width: auto
        max-width: none

.b-consult-appointments
    &__inner
        display: flex
        width: 100%

        @include media('<=phone')
            flex-direction: column
            padding: 0 10px

    &__title
        width: 100%
        padding: 0 20px
        text-align: center

        @include media('<=phone')
            text-align: left
</style>
