<template>
<div class='h-inline-flex h-pointer'
     :class='{
         "b-back-button": !isRelativePosition,
         "b-back-button__relative": isRelativePosition
     }'
     @click.stop='goBack'>
    <FwIcon
        class='h-flex-center h-pointer'
        icon='arrow-left'
        size='14'
        color='rgba(33,63,107,.3)'>
    </FwIcon>
    <p class='h-bold h-mv-0 h-ml-10 h-font-14'>
        {{ $t('BUTTON.BACK') }}
    </p>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GoBackButton extends Vue {
    @Prop({ type: Boolean, default: false }) readonly isRelativePosition!: boolean;
    @Prop({ type: String, default: null }) readonly route!: string;
    @Prop({ type: Function, default: null }) readonly method!: Function | null;

    goBack() {
        if (this.method) {
            this.method();
        } else if (this.route) {
            this.$router.push(this.route);
        } else {
            this.$router.go(-1);
        }
    }
}
</script>

<style lang='sass'>
.b-back-button, .b-back-button__relative
    color: rgba(33,63,107,.3)
    &:hover
        transition: .3s color
        color: $dark-blue
        svg
            path
                transition: .3s fill
                fill: $dark-blue !important
.b-back-button
    position: absolute

.b-back-button__relative
    position: relative

.b-back-button, .b-back-button__relative
    @include media('<=phone')
        padding: 5px
</style>
