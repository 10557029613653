<template>
<div class='b-blue-background b-blue-background__opacity b-cancel-popup__select b-blue-background--fixed'>
    <FwPopup
        v-if='reasonPopup'
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='800px'
        :title='$t(`CANCEL.REASON.HEADER`)'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        submitText='BUTTON.ADD'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <CancelReasonSelect
                v-if='currentOptions'
                :isRequestSending='isRequestSending'
                iconsWrapperClass='h-flex-dir-column h-flex'
                :optionsReasonOrigin='currentOptions'
                :disabledForm='false'
                submitText=''
                @onSubmit='sendDataToServer'>
            </CancelReasonSelect>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CancelReasonSelect } from '@/components/common/CancelReasonSelect';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { AppointmentOriginSendDataType } from '@/types/Appointment';
import AccountMixin from '@/mixins/account';
import DynamicFormMixin from '@/mixins/dynamicFormMixin';
import i18n from '@/locale';

const CommonStore = namespace('CommonStore');

interface ReasonValueType {
    title: string,
    value: string,
}

@Component({
    components: {
        CancelReasonSelect,
    },
})
export default class CancelReasonPopup extends Mixins(AccountMixin, DynamicFormMixin) {
    @Prop({ type: String, required: true }) readonly appointmentId!: string;

    reasonPopup: boolean = true;
    isRequestSending: boolean = false;

    optionsAvailable: Array<ReasonValueType> = [
        {
            title: i18n.tc('CANCEL.ORIGIN.COMPANY.INTERNAL'),
            value: 'internal',
        },
        {
            title: i18n.tc('CANCEL.ORIGIN.COMPANY.EXTERNAL'),
            value: 'client_or_prospect',
        },
        {
            title: i18n.tc('CANCEL.ORIGIN.PARTNER.CUSTOMER'),
            value: 'client',
        },
        {
            title: i18n.tc('CANCEL.ORIGIN.PARTNER.BUSINESS'),
            value: 'partner_company',
        },
        {
            title: i18n.tc('CANCEL.ORIGIN.NISSAN.INTERNAL'),
            value: 'internal_ima',
        },
        {
            title: i18n.tc('CANCEL.ORIGIN.NISSAN.EXTERNAL'),
            value: 'external_nissan',
        },
    ];

    get currentOptions(): Array<ReasonValueType> {
        const origins = this.accountData.cancellation_origins;
        if (origins && origins.length) {
            const originsMapArray = origins.map(item => {
                return {
                    title: item[this.currentLangKey],
                    value: item.searchable_name,
                };
            });

            return originsMapArray.filter(item => this.cancellationReasonsKeys.includes(item.value));
        }
        return this.optionsAvailable.filter(item => this.cancellationReasonsKeys.includes(item.value));
    }

    get cancellationReasonsKeys(): Array<string> {
        return [...new Set(this.accountData.cancellation_reasons.map(item => item.cancellation_origin_keys).flat())];
    }

    async sendDataToServer(data: AppointmentOriginSendDataType) {
        try {
            this.isRequestSending = true;
            await AppointmentWebApi.cancelAppointment(data, this.appointmentId);
            this.isRequestSending = false;
            this.reasonPopup = false;
            this.$emit('close', this.appointmentId);
            this.$emit('cancel', this.appointmentId);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.CANCELED', true);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }
}
</script>

<style lang='sass'>
.b-blue-background
    display: flex
    justify-content: center
    align-items: center
    .fw-popup__title
        font-size: 22px
        @include media('<=phone')
            font-size: 18px

    .fw-popup__close_popup
        @include media('<=phone')
            right: 22px
            top: 22px
</style>
