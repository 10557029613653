import { EventFormDataType } from '@/types/Events';
import {
    COMPANY_NAME,
    CLIENT_SURNAME,
    CLIENT_NAME,
    CONTRACT_NUMBER,
    LOCATION_VALUE,
    COMPANY,
} from '@/helpers/events';
import { informationDataType } from '@/types/Consult';
import {
    COMPANY_INFORMATION_NAME,
    COMPANY_INFORMATION_ADDRESS,
    COMPANY_INFORMATION_SIREN,
    COMPANY_INFORMATION_SIRET,
    COMPANY_INFORMATION_PHONE,
    COMPANY_INFORMATION_EMAIL,
    COMPANY_INFORMATION_ZIP_CODE,
    COMPANY_INFORMATION_INSEE_CODE,
    COMPANY_INFORMATION_CITY,
} from '@/helpers/company';
import { CompanyCardType } from '@/types/Companies';
import { workersData } from '@/mocks/userData';

const conpanyData: Array<informationDataType> = [
    {
        title: 'LABEL.ADDRESS',
        aside: `130 rue Saint-Maur 75011 Paris, FRANCE`,
    },
    {
        title: 'LABEL.START.POINT',
        aside: '75011',
    },
    {
        title: 'LABEL.EMAIL',
        aside: 'addresse@mail.com',
    },
    {
        title: 'LABEL.PHONE',
        aside: '09 27 38 47 18',
    },
];

const company: EventFormDataType = {
    [COMPANY_NAME]: 'Autodistribution',
    [CLIENT_SURNAME]: 'Durant',
    [CLIENT_NAME]: 'Camille',
    [CONTRACT_NUMBER]: '0273810182736',
    [LOCATION_VALUE]: '240 avenue des Barattes 74000 ANNECY',
    [COMPANY]: 'Kheops Securité 74000',
};

const companyFileData = [
    {
        [COMPANY_INFORMATION_NAME]: 'ABS Protection 75',
        [COMPANY_INFORMATION_ADDRESS]: '130 rue Saint-Maur',
        [COMPANY_INFORMATION_ZIP_CODE]: '75011',
        [COMPANY_INFORMATION_INSEE_CODE]: '20982',
        [COMPANY_INFORMATION_CITY]: 'Paris',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'hello@abs.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'KEOPS Securité 13',
        [COMPANY_INFORMATION_ADDRESS]: '69 rue du Paradis',
        [COMPANY_INFORMATION_ZIP_CODE]: '13000',
        [COMPANY_INFORMATION_INSEE_CODE]: '1092384',
        [COMPANY_INFORMATION_CITY]: 'Marseille',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'hello@keopssecurity.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'Franche comte communications 91',
        [COMPANY_INFORMATION_ADDRESS]: '1 rue de Narvik',
        [COMPANY_INFORMATION_ZIP_CODE]: '74000',
        [COMPANY_INFORMATION_INSEE_CODE]: '347267',
        [COMPANY_INFORMATION_CITY]: 'Annecy',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'email@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'Protectora System 69',
        [COMPANY_INFORMATION_ADDRESS]: '76 rue des Cascades',
        [COMPANY_INFORMATION_ZIP_CODE]: '69000',
        [COMPANY_INFORMATION_INSEE_CODE]: '89765',
        [COMPANY_INFORMATION_CITY]: 'Lyon',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'protectora_system@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'SAS Michel Boissinot ',
        [COMPANY_INFORMATION_ADDRESS]: '1 rue Paul Cabaud',
        [COMPANY_INFORMATION_ZIP_CODE]: '74000',
        [COMPANY_INFORMATION_INSEE_CODE]: '376521',
        [COMPANY_INFORMATION_CITY]: 'Annecy',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'michel_boissinot@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'Protectora System 69',
        [COMPANY_INFORMATION_ADDRESS]: '76 rue des Cascades',
        [COMPANY_INFORMATION_ZIP_CODE]: '69000',
        [COMPANY_INFORMATION_INSEE_CODE]: '89765',
        [COMPANY_INFORMATION_CITY]: 'Lyon',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'protectora_system@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'SAS Michel Boissinot ',
        [COMPANY_INFORMATION_ADDRESS]: '1 rue Paul Cabaud',
        [COMPANY_INFORMATION_ZIP_CODE]: '74000',
        [COMPANY_INFORMATION_INSEE_CODE]: '376521',
        [COMPANY_INFORMATION_CITY]: 'Annecy',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'michel_boissinot@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'Protectora System 69',
        [COMPANY_INFORMATION_ADDRESS]: '76 rue des Cascades',
        [COMPANY_INFORMATION_ZIP_CODE]: '69000',
        [COMPANY_INFORMATION_INSEE_CODE]: '89765',
        [COMPANY_INFORMATION_CITY]: 'Lyon',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'protectora_system@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'SAS Michel Boissinot ',
        [COMPANY_INFORMATION_ADDRESS]: '1 rue Paul Cabaud',
        [COMPANY_INFORMATION_ZIP_CODE]: '74000',
        [COMPANY_INFORMATION_INSEE_CODE]: '376521',
        [COMPANY_INFORMATION_CITY]: 'Annecy',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'michel_boissinot@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'Protectora System 69',
        [COMPANY_INFORMATION_ADDRESS]: '76 rue des Cascades',
        [COMPANY_INFORMATION_ZIP_CODE]: '69000',
        [COMPANY_INFORMATION_INSEE_CODE]: '89765',
        [COMPANY_INFORMATION_CITY]: 'Lyon',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'protectora_system@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'SAS Michel Boissinot ',
        [COMPANY_INFORMATION_ADDRESS]: '1 rue Paul Cabaud',
        [COMPANY_INFORMATION_ZIP_CODE]: '74000',
        [COMPANY_INFORMATION_INSEE_CODE]: '376521',
        [COMPANY_INFORMATION_CITY]: 'Annecy',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'michel_boissinot@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'Protectora System 69',
        [COMPANY_INFORMATION_ADDRESS]: '76 rue des Cascades',
        [COMPANY_INFORMATION_ZIP_CODE]: '69000',
        [COMPANY_INFORMATION_INSEE_CODE]: '89765',
        [COMPANY_INFORMATION_CITY]: 'Lyon',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'protectora_system@gmail.com',
    },
    {
        [COMPANY_INFORMATION_NAME]: 'SAS Michel Boissinot ',
        [COMPANY_INFORMATION_ADDRESS]: '1 rue Paul Cabaud',
        [COMPANY_INFORMATION_ZIP_CODE]: '74000',
        [COMPANY_INFORMATION_INSEE_CODE]: '376521',
        [COMPANY_INFORMATION_CITY]: 'Annecy',
        [COMPANY_INFORMATION_SIREN]: '123456789',
        [COMPANY_INFORMATION_SIRET]: '12345678912345',
        [COMPANY_INFORMATION_PHONE]: '0920387165',
        [COMPANY_INFORMATION_EMAIL]: 'michel_boissinot@gmail.com',
    },
];

const companiesCardsData: Array<CompanyCardType> = [
    {
        title: 'ABS Protection',
        id: 1,
        workers: workersData,
    },
    {
        title: 'Conectia',
        id: 2,
        workers: workersData.sort(() => Math.random() - 0.5).slice(2),
    },
    {
        title: 'Franche comte communications',
        id: 3,
        workers: workersData.sort(() => Math.random() - 0.5).slice(2, 6),
    },
    {
        title: 'Group Secouricon',
        id: 4,
        workers: workersData.sort(() => Math.random() - 0.5).slice(1, 6),
    },
];

export {
    conpanyData,
    company,
    companyFileData,
    companiesCardsData,
};
