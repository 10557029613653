<template>
<div class='b-consult-branches b-page-container b-page-container--big'>
    <GoBackButton
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <div class='h-flex-dir-column h-mh-10'>
        <div class='b-common-title-wrapper'>
            <h1>
                {{ $t('BREADCRUMBS.HUBS') }}
                <template v-if='!(loading && !totalPage)'>
                    ({{ totalPage }})
                </template>
            </h1>
            <div class='b-common-title-wrapper__search'>
                <FwInput
                    class='h-flex-1 h-ml-15'
                    :value='searchText'
                    :placeholder='$t(`HUB.PLACEHOLDER.SEARCH`)'
                    iconRight='loupe'
                    iconSize='15'
                    inputStyle='white-bigger-search'
                    type='search'
                    @update:value='value => searchText = value'>
                </FwInput>
            </div>
        </div>
        <div class='b-common-button-wrapper'>
            <div class='b-consult-button-size h-flex h-flex-justify-end h-mr-0'>
                <FwButton
                    v-if='canCreate'
                    class='h-ph-40 qa-create-hub-button'
                    size='small'
                    borderRadiusType='small-border'
                    fontSize='12px'
                    @click.native='addHubMethod'>
                    {{ $t('BUTTON.ADD') }}
                </FwButton>
            </div>
            <div class='fw-select-base fw-select-white-modern h-width-250' style='box-shadow: none!important;'>
                <FwSelect
                    v-model='sortByValue'
                    :propsPlaceholder='`${$t(`BUTTON.SORT_BY`)}:`'
                    type='sort-simple'
                    :withCircles='true'
                    canNotDelete
                    :options='sortOptions'>
                </FwSelect>
            </div>
        </div>
    </div>
    <div class='b-consult-cards__wrapper'>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <HubsCard
            v-for='(hub, index) in hubsCards'
            v-else
            :key='hub.id'
            :hub='hub'
            class='h-mh-10 h-mb-20'
            :class='[`qa-hub-card-${index}`, `qa-${hub.id}`]'>
        </HubsCard>
    </div>
    <AddOrEditHubsByForm
        v-if='isAddEditAdvisorPopup'
        @closePopup='closePopup'>
    </AddOrEditHubsByForm>
    <FwPagination
        v-if='totalPage > perPage'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import throttle from 'lodash.throttle';
import { TranslateResult } from 'vue-i18n';
import PermissionsMixin from '@/mixins/permissions';
import { CompanyData } from '@/types/Companies';
import { companyDataProps } from '@/helpers/company';
import { HubsCard } from '@/components/simple/HubsCard';
import { SitesApi } from '@/api/sites/SitesApi';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { AddOrEditHubsByForm } from '@/views/consult/add-hubs/AddOrEditHubsByForm';
import { updateUrlPageAndQuery } from '@/helpers/url';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        HubsCard,
        GoBackButton,
        AddOrEditHubsByForm,
    },
})

export default class ConsultHubsPage extends Mixins(PermissionsMixin) {
    totalPage: number | null = 0;
    perPage: number = 10;
    currentPage: number = 1;
    isSortByCount: boolean = false;
    searchText: string = ``;
    loading: boolean = true;
    isAddEditAdvisorPopup: boolean = false;
    sortOptions: Array<string | TranslateResult> = ['AZ', this.$i18n.t('BRANCH.ADVISORS.NUMBER')];
    hubsCards: Array<CompanyData> = [companyDataProps];
    sortByValue: str = this.isSortByCount ? this.sortOptions[1] : this.sortOptions[0];
    throttleSearch = throttle(this.search, 1500, { leading: false, trailing: true });
    unwatch = () => { };
    unwatchSort = () => { };

    updateState(page: number = this.currentPage) {
        this.$router.push(updateUrlPageAndQuery(`/consult/choose/hubs`, page, { query: this.searchText, sort_by_count: this.isSortByCount }));
    }

    search(): void {
        this.currentPage = 1;
        this.updateState();
    }

    addHubMethod(): void {
        this.isAddEditAdvisorPopup = true;
    }

    closePopup(): void {
        this.isAddEditAdvisorPopup = false;
    }

    async getHubs(current_page: number, per_page: number): Promise<void> {
        this.loading = true;
        const payload: { current_page: number, per_page: number, sort_by_count: boolean } = {
            current_page,
            per_page,
            sort_by_count: this.isSortByCount,
        };
        const data = await SitesApi.getSitesHubs(payload);
        this.totalPage = data.meta.total_count;
        this.loading = false;
        this.hubsCards = data.hubs;
    }

    async getBranchesBySearch(current_page: number, per_page: number, searchText: string): Promise<void> {
        if (this.searchText.length > 1) {
            this.loading = true;
            const payload: { current_page: number, per_page: number } = {
                current_page,
                per_page,
            };
            const data = await SitesApi.getBranchBySearch(payload, searchText);
            this.totalPage = data.meta.total_count;
            this.hubsCards = data.hubs;
            this.loading = false;
        }
    }

    takeParamsFromUrl(): void {
        this.unwatch();
        this.unwatchSort();
        this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
        this.isSortByCount = this.$route.query.sort_by_count ? !!this.$route.query.sort_by_count : false;
        this.searchText = (this.$route.query.query as string) || ``;
        this.unwatch = this.$watch('searchText', this.throttleSearch);
        this.unwatchSort = this.$watch('sortByValue', this.sortList);
    }

    doRequest(): void {
        if (this.searchText.length > 1) {
            this.getBranchesBySearch(this.currentPage, this.perPage, this.searchText);
        } else if (this.searchText.length === 0) {
            this.getHubs(this.currentPage, this.perPage);
        }
    }

    created(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }

    sortList(): void {
        this.currentPage = 1;
        this.isSortByCount = this.sortByValue !== 'AZ';
        this.updateState();
    }

    @Watch(`$route`)
    onRouterChange(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }
}
</script>

<style lang='sass'>
.b-consult-branches.b-page-container--big
    max-width: 830px

    .b-consult-button-size
        height: 44px
        display: flex
        align-items: center
</style>
