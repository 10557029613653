<template>
<div class='b-blue-background
            b-blue-background__opacity
            b-cancel-popup__select
            b-blue-background--fixed
            b-reserved-slots__wrapper'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='600px'
        width='540px'
        :title='$t("WORKER.POPUP.RESERVED.SLOTS.TITLE")'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :padding='isMobile() ? "30px 20px" : "40px 50px 25px"'
        :disableClosingOnClickOutside='true'
        :hideSubPopup='!showSubPopup'
        @close='$emit("close")'>
        <template #body>
            <div style='width: 360px'
                 class='h-margin-0-auto'>
                <ReservedSlotsList
                    :startActiveId='startActiveId'
                    :skills='skills'
                    @update='updateSlotType'>
                </ReservedSlotsList>
                <FwDatepicker
                    v-model='localDate'
                    class='h-mv-20'
                    :class='{ "qa-advisor-reserved-slots-datepicker-slot": currentAvailabilitySlot }'
                    :propsRange='false'
                    :selectMode='false'
                    :propsLang='`${$i18n.locale}`'
                    pickerStyle='static'
                    :bigStyle='true'
                    :hideIcon='true'
                    :showWeeksNumbers='true'
                    :reservedSlots='reservedSlots'
                    :hideInput='true'
                    :defaultValue='lastEventDate'
                    :disabledDate='disabledDateByObject'
                    @change-calendar-month='changeCalendarMonth'
                    @selectDate='selectDate'>
                </FwDatepicker>
            </div>
        </template>

        <template #subPopup>
            <template v-if='localDate && showSubPopup && !forceTempHidePopup'>
                <ChooseReservedSlotForm
                    v-if='showChooseReservedSlot'
                    :date='localDate'
                    :currentSlots='currentSlots'
                    :currentInstancesData='currentInstancesData'
                    :isReservedSots='isReservedSotsPopup'
                    @closePopup='closePopup'
                    @chooseSlot='setSlot'
                    @addNewSlot='setShowAddReservedSlot'>
                </ChooseReservedSlotForm>
                <AddReservedSlotForm
                    v-else
                    :date='localDate'
                    :isRequestSendingStatus='isRequestSending'
                    :reservedSlot='currentSlot'
                    :instanceData='currentInstanceData'
                    type='appointment'
                    :skills='skills'
                    :isReservedSots='isReservedSotsPopup'
                    @closePopup='closeAddReservedSlotPopup'
                    @addSlot='createEvent'
                    @removeSlot='openDeletePopup'>
                </AddReservedSlotForm>
            </template>
        </template>
    </FwPopup>

    <DeleteReservedSlotPopup
        v-if='showReservedSlotPopup'
        :instanceData='instanceData'
        :isRequestSending='isRequestSending'
        type='reserved-slots'
        @click.native.stop
        @removeSlot='removeEvent'
        @close='closeReservedSlotPopup'>
    </DeleteReservedSlotPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { AddReservedSlotForm } from '@/components/forms/add/AddReservedSlotForm';
import { ChooseReservedSlotForm } from '@/components/forms/add/ChooseReservedSlotForm';
import { DeleteReservedSlotPopup } from '@/components/popups/DeleteReservedSlotPopup';
import { ReservedSlotsList } from '@/components/simple/ReservedSlotsList';
import ReservedSlotsMixin from '@/mixins/ReservedSlotsMixin';
import { CategorySkillNameType } from '@/types/Appointment';
import { ALL_ID } from '@/helpers/appointment';

@Component({
    components: {
        AddReservedSlotForm,
        ChooseReservedSlotForm,
        DeleteReservedSlotPopup,
        ReservedSlotsList,
    },
})
export default class ReservedSlotsPopup extends Mixins(ReservedSlotsMixin) {
    @Prop({ type: Array, required: true }) readonly skills!: Array<CategorySkillNameType>;

    isReservedSotsPopup: boolean = true;
    isUnavailability: boolean = false;
    currentDate = new Date();
    startActiveId: string = this.skills.length > 1 ? ALL_ID : this.skills[0].searchable_name;

    updateSlotType(type: string) {
        this.startActiveId = type;
        if (!this.changedCalendarDate) return;
        this.getMonthData({ date: this.changedCalendarDate, isFullMonthRequest: true });
    }

    get disabledDateByObject() {
        return this.reservedSlots && {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate()) : new Date(),
            excludedSlots: this.reservedSlots ? this.reservedSlots.map(item => item.date) : [],
        };
    }
}
</script>

<style lang='sass'>
.b-reserved-slots-marked-date
    width: 30px
    height: 30px
    background-color: rgba(255, 0, 0, 0.04)
    font-weight: 400

.b-reserved-slots
    &__wrapper
        .mx-datepicker
            box-shadow: none !important

        .fw-popup__sub_popup
            width: 380px !important
            overflow: auto
            max-height: calc(100vh - 70px)
            transform: translate(80%, 0) !important
            overflow: visible !important

            @include media('<=phone')
                overflow: auto !important
                padding: 30px 30px 50px

            @include media('<=phone')
                transform: none !important
</style>
