import { colorType } from '@/types/Colors';

function LightenDarkenColor(col: string, amt: number) {
    let usePound = false;
    let localColor: string = col;
    if (col[0] === '#') {
        localColor = col.slice(1);
        usePound = true;
    }
    const num = parseInt(localColor, 16);
    let r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    let b = ((num >> 8) & 0x00FF) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    let g = (num & 0x0000FF) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

function hexToRgbA(hex: string, opacity: number) {
    let c: any;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity || 1})`;
    }
    return hex;
}

function separateColor(str: string) {
    return str.split(' - ');
}

const COLOR_BROWN = '#846355';
const COLOR_PINK = '#eb217f';
const COLOR_RED = '#e72e17';
const COLOR_ORANGE = '#fc8e1b';
const COLOR_DARK_YELLOW = '#ffc200';
const COLOR_YELLOW = '#f9eb21';
const COLOR_LIGHT_YELLOW = '#d4e12c';
const COLOR_LIGHT_GREEN = '#90c748';
const COLOR_GREEN = '#53b658';
const COLOR_DARK_GREEN = '#53b658';
const COLOR_LIGHT_BLUE = '#00c6da';
const COLOR_BLUE = '#01a8ee';
const COLOR_DARK_BLUE = '#283ec4';
const COLOR_DARK_VIOLET = '#7640c0';
const COLOR_VIOLET = '#a420b6';
const COLOR_GRAY = '#6e8b98';
const COLOR_LIGHT_GRAY = '#afaeae';
const COLOR_DARK_GRAY = '#3d474d';

const AVAILABLE_COLORS: Array<string> = [
    COLOR_BROWN,
    COLOR_PINK,
    COLOR_RED,
    COLOR_ORANGE,
    COLOR_DARK_YELLOW,
    COLOR_YELLOW,
    COLOR_LIGHT_YELLOW,
    COLOR_LIGHT_GREEN,
    COLOR_GREEN,
    COLOR_DARK_GREEN,
    COLOR_LIGHT_BLUE,
    COLOR_BLUE,
    COLOR_DARK_BLUE,
    COLOR_DARK_VIOLET,
    COLOR_VIOLET,
    COLOR_GRAY,
    COLOR_LIGHT_GRAY,
    COLOR_DARK_GRAY,
];

const AVAILABLE_COLORS_DATA: { [key: string]: colorType } = {};

for (let i = 0; i < AVAILABLE_COLORS.length; i++) {
    const color = AVAILABLE_COLORS[i];
    AVAILABLE_COLORS_DATA[color] = {
        gradient: `linear-gradient(225deg, ${LightenDarkenColor(color, 70)} 0%, ${color} 100%)`,
    };
}

export {
    LightenDarkenColor,
    hexToRgbA,
    separateColor,
    AVAILABLE_COLORS,
    AVAILABLE_COLORS_DATA,

    COLOR_BROWN,
    COLOR_PINK,
    COLOR_RED,
    COLOR_ORANGE,
    COLOR_DARK_YELLOW,
    COLOR_YELLOW,
    COLOR_LIGHT_YELLOW,
    COLOR_LIGHT_GREEN,
    COLOR_GREEN,
    COLOR_DARK_GREEN,
    COLOR_LIGHT_BLUE,
    COLOR_BLUE,
    COLOR_DARK_BLUE,
    COLOR_DARK_VIOLET,
    COLOR_VIOLET,
    COLOR_GRAY,
    COLOR_LIGHT_GRAY,
    COLOR_DARK_GRAY,
};
