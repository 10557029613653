import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { isUserAuth, user } from '@/helpers/auth';
import { getUserRole } from '@/helpers/roles';
import { AccountType } from '@/types/Account';
import { EventBus } from '@/utils/EventBus';
import { User, UserManagerProfileRole } from '@/types/User';
import { RoleFullPayloadType } from '@/types/Roles';

@Component
export default class CalizyGlobalMixin extends Vue {
    @State(state => state.GlobalStore.account) account!: AccountType;

    analyticsEvents(text: string) {
        return this.$analytics ? this.$analytics.logEvent(text) : null;
    }
    // @ts-ignore-next-line
    requireImage(path: string): any {
        let image = null;
        try {
            image = require(`@/static/images/${path}`);
        } catch (e) {
            console.log(e);
        }
        return image;
    }

    isUserAuth() {
        return isUserAuth(this.$store);
    }

    user(): User {
        return user(this.$store);
    }

    isAdvisor(): boolean {
        const { kind } = this.user();
        if (kind) {
            return kind.toLowerCase() === `advisor`;
        }
        return false;
    }

    get mainRouteGlobal() {
        return this.$router.currentRoute.matched[0].path;
    }

    get isRecapRoute() {
        return this.$route.meta && this.$route.meta.kind === 'recap';
    }

    get currentUserRole(): RoleFullPayloadType | null {
        return getUserRole(this.user());
    }

    sentNotif(responseOrId: any, isSuccess?: boolean) {
        if (responseOrId && responseOrId.status === 404) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.404'));
        } else if (responseOrId && responseOrId.status === 401) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.401'));
        } else if (responseOrId && responseOrId.status === 403) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.403'));
        } else if (responseOrId && responseOrId.status === 500) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.500'));
        } else if (!isSuccess && responseOrId && responseOrId.data && responseOrId.data.errors) {
            const error = Array.isArray(responseOrId.data.errors) ? responseOrId.data.errors[0] : responseOrId.data.errors;
            EventBus.$emit(`sent-notification`, this.$i18n.t(error));
        } else if (responseOrId) {
            EventBus.$emit(`sent-notification`, this.$i18n.t(responseOrId), isSuccess);
        }
    }
};
