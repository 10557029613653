<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='400px'
        width='auto'
        :title='title'
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        :centered='true'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <div class='h-text-center h-mb-20'>
                    {{ asideText }}
                </div>
                <template v-if='isRecurrenceOptionsActive || isMultipleEvents'>
                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove-this-alone'
                        borderRadiusType='small-border'
                        :disabled='isRequestSending'
                        @click='removeSlotAlone'>
                        {{ deleteTextThis }}
                    </FwButton>

                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove-future'
                        :paddingHorizontal='true'
                        :disabled='isRequestSending'
                        borderRadiusType='small-border'
                        @click='removeSlotFuture'>
                        {{ deleteTextFuture }}
                    </FwButton>

                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove'
                        borderRadiusType='small-border'
                        :paddingHorizontal='true'
                        :disabled='isRequestSending'
                        @click='removeSlot'>
                        {{ deleteTextAll }}
                    </FwButton>
                </template>
                <template v-else>
                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove-alone'
                        borderRadiusType='small-border'
                        :disabled='isRequestSending'
                        @click='removeSlotBase'>
                        {{ deleteTextAlone }}
                    </FwButton>
                </template>
            </div>
        </template>
        <template #footer>
            <div class='b-company-edit__delete'
                 @click='$emit("close")'>
                {{ $t('ADD.RESERVED.SLOT.POPUP.DELETE.CANCEL') }}
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { TranslateResult } from 'vue-i18n';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';

@Component
export default class DeleteShiftsPopup extends Vue {
    @Prop({ type: Object, required: true }) readonly instanceData!: UnavailabilityEventInstancesType;
    @Prop({ type: Boolean, default: false }) readonly isMultipleEvents!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    get title(): TranslateResult {
        return this.$i18n.t(`SERVICES_POINT.SHIFTS.DELETE.TITLE`);
    }

    get deleteTextFuture(): TranslateResult {
        return this.$i18n.t(`SERVICES_POINT.SHIFTS.DELETE.ALL.FUTURE`);
    }

    get deleteTextThis(): TranslateResult {
        return this.$i18n.t(`SERVICES_POINT.SHIFTS.DELETE.ONLY_THIS`);
    }

    get deleteTextAll(): TranslateResult {
        return this.$i18n.t(`SERVICES_POINT.SHIFTS.DELETE.ALL`);
    }

    get deleteTextAlone(): TranslateResult {
        return this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.BASE`);
    }

    get asideText(): TranslateResult {
        return this.isRecurrenceOptionsActive ?
            this.$i18n.t(`SERVICES_POINT.SHIFTS.DELETE.ASIDE_RECURRENCE`) :
            this.$i18n.t(`SERVICES_POINT.SHIFTS.DELETE.ASIDE`);
    }

    get isRecurrenceOptionsActive(): boolean {
        return Boolean(this.instanceData && (this.instanceData.is_recurrent || this.instanceData.parent_id || this.instanceData.recurrence_id));
    }

    removeSlotBase(): void {
        if (this.isRecurrenceOptionsActive) {
            this.removeSlotAlone();
        } else {
            this.removeSlot();
        }
    }

    @Emit('removeSlot')
    removeSlotAlone() {
        return { instance: this.instanceData, onlyInstance: true };
    }

    @Emit('removeSlot')
    removeSlotFuture() {
        return { instance: this.instanceData, future: true };
    }

    @Emit('removeSlot')
    removeSlot() {
        return { instance: this.instanceData };
    }
}
</script>

<style lang='sass'>
.h-width-250
    width: 250px
</style>
