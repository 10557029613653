<template>
<div class='b-forbidden__wrapper'>
    <h1 class='h-font-22'>
        {{ $t('FORBIDDEN.NOT.MANAGER') }}
    </h1>
</div>
</template>

<script lang='ts'>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ForbiddenNotManager extends Vue {}
</script>

<style lang='sass'>
.b-forbidden__wrapper
    max-width: 900px
    margin: 0 auto
    padding-top: 40px
</style>

