const BRANCH_KEY = `BRANCH_KEY`;
const BRANCH_ADDRESS_KEY = `BRANCH_ADDRESS_KEY`;
const HUB_KEY = `HUB_KEY`;
const USER_LS_KEY: string = 'STORAGE_USER_DATA';
const ACCOUNT_LS_KEY: string = 'ACCOUNT_USER_DATA';
const USER_HEADERS_KEY: string = 'STORAGE_HEADERS_DATA';
const LOCALE_LS_KEY: string = 'LOCALE_KEY';
const SASS_FLOW_NAME: string = 'SASS_FLOW_NAME';

const APPOINTMENT_CALENDAR_PAGE_NAME = `AppointmentCalendarPage`;
const CONSULT_CALENDAR_PAGE_NAME = `ConsultCalendarPage`;
const MONTH_CALENDAR_PAGE_NAME = `MonthCalendarPage`;

const SHIFTS_CALENDAR_PARAM = 'shifts';

export {
    BRANCH_ADDRESS_KEY,
    BRANCH_KEY,
    HUB_KEY,
    USER_LS_KEY,
    ACCOUNT_LS_KEY,
    USER_HEADERS_KEY,
    LOCALE_LS_KEY,
    SASS_FLOW_NAME,
    APPOINTMENT_CALENDAR_PAGE_NAME,
    CONSULT_CALENDAR_PAGE_NAME,
    MONTH_CALENDAR_PAGE_NAME,
    SHIFTS_CALENDAR_PARAM,
};
