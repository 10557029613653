<template>
<div class='b-consult-service-points b-page-container b-page-container--big'>
    <GoBackButton
        class='h-mt-30'
        isRelativePosition>
    </GoBackButton>
    <div class='h-flex-dir-column h-mh-10'>
        <div class='b-common-title-wrapper'>
            <h1>
                {{ $t('SELECT.SERVICES_POINT.PLURAL.TITLE') }}
                <template v-if='!(loading && !totalPage)'>
                    ({{ totalPage }})
                </template>
            </h1>
            <div class='b-common-title-wrapper__search'>
                <FwInput
                    class='h-flex-1 h-ml-15'
                    :value='searchText'
                    :placeholder='$t(`SERVICES_POINT.PLACEHOLDER.SEARCH`)'
                    iconRight='loupe'
                    iconSize='15'
                    inputStyle='white-bigger-search'
                    type='search'
                    @update:value='value => searchText = value'>
                </FwInput>
            </div>
        </div>
        <div class='b-common-button-wrapper'>
            <div class='b-consult-button-size h-flex h-flex-justify-end h-mr-0'>
                <FwButton
                    v-if='canCreate'
                    class='h-ph-40 qa-service-point-add-button b-add-item-button'
                    size='little'
                    borderRadiusType='small-border'
                    fontSize='12px'
                    @click.native='addBranchMethod'>
                    {{ $t('BUTTON.ADD') }}
                </FwButton>
            </div>
            <div class='fw-select-base fw-select-white-modern h-width-250'
                 style='box-shadow: none!important;'>
                <FwSelect
                    v-model='sortByValue'
                    :propsPlaceholder='`${$t(`BUTTON.SORT_BY`)}:`'
                    type='sort-simple'
                    :withCircles='true'
                    canNotDelete
                    :options='sortOptions'>
                </FwSelect>
            </div>
        </div>
    </div>
    <div class='b-consult-cards__wrapper'>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <ServicePointCard
            v-for='servicePoint in servicePoints'
            v-else
            :key='servicePoint.id'
            :servicePoint='servicePoint'
            class='h-mh-10 h-mb-20 qa-service-point-card'>
        </ServicePointCard>
    </div>
    <FwPagination
        v-if='totalPage > perPage'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>
    <AddOrEditServicePoint
        v-if='isServicePointAddShow'
        @updateState='doRequest'
        @closePopup='closeBranchPopup'>
    </AddOrEditServicePoint>
</div>
</template>

<script lang='ts'>
import throttle from 'lodash.throttle';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { CompanyData } from '@/types/Companies';
import { ServicePointCard } from '@/components/simple/ServicePointCard';
import { SitesApi } from '@/api/sites/SitesApi';
import { GoBackButton } from '@/components/simple/GoBackButton';
import PermissionsMixin from '@/mixins/permissions';
import { AddOrEditServicePoint } from '@/views/consult/add-service-point/AddOrEditServicePoint';
import { updateUrlPageAndQuery } from '@/helpers/url';
import { ServicePointListItemType } from '@/types/ServicePoint';
import { pagePaginationData } from '@/types/Appointment';

@Component({
    components: {
        AddOrEditServicePoint,
        GoBackButton,
        ServicePointCard,
    },
})
export default class ConsultServicePointsPage extends Mixins(PermissionsMixin) {
    totalPage: number | null = 0;
    perPage: number = 10;
    currentPage: number = 1;
    searchText: string = '';
    isSortByCount: boolean = false;
    loading: boolean = true;

    isServicePointAddShow: boolean = false;
    sortOptions: Array<string | TranslateResult> = [
        'AZ',
        this.$i18n.t('SERVICES_POINT.ADVISOR.NUMBER'),
    ];
    servicePoints: Array<ServicePointListItemType> = [];
    sortByValue: str = this.isSortByCount ? this.sortOptions[1] : this.sortOptions[0];
    throttleSearch = throttle(this.search, 1500, { leading: false, trailing: true });

    unwatch = () => { };
    unwatchSort = () => { };

    updateState(page: number = this.currentPage): void {
        this.$router.push(updateUrlPageAndQuery(`/consult/choose/service-points`, page, { query: this.searchText, sort_by_count: this.isSortByCount }));
    }

    search(): void {
        this.currentPage = 1;
        this.updateState();
    }

    addBranchMethod(): void {
        this.isServicePointAddShow = true;
    }

    closeBranchPopup(): void {
        this.isServicePointAddShow = false;
    }

    async getServicePoints(current_page: number, per_page: number): Promise<void> {
        this.loading = true;
        const payload: pagePaginationData = {
            current_page,
            per_page,
            sort_by_count: this.isSortByCount,
        };
        const data = await SitesApi.getServicePointsBySearch(payload, '');
        this.totalPage = data.meta.total_count;
        this.servicePoints = data.service_points;
        this.loading = false;
    }

    async getServicePointsBySearch(current_page: number, per_page: number, searchText: string): Promise<void> {
        if (this.searchText.length > 1) {
            this.loading = true;
            const payload: pagePaginationData = {
                current_page,
                per_page,
                sort_by_count: this.isSortByCount,
            };
            const data = await SitesApi.getServicePointsBySearch(payload, searchText);
            if (data.meta) {
                this.totalPage = data.meta.total_count;
            }
            this.servicePoints = data.service_points;
            this.loading = false;
        }
    }

    takeParamsFromUrl(): void {
        this.unwatch();
        this.unwatchSort();
        this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
        this.isSortByCount = this.$route.query.sort_by_count ? !!this.$route.query.sort_by_count : false;
        this.searchText = (this.$route.query.query as string) || ``;
        this.unwatch = this.$watch('searchText', this.throttleSearch);
        this.unwatchSort = this.$watch('sortByValue', this.sortList);
    }

    doRequest(): void {
        if (this.searchText.length > 1) {
            this.getServicePointsBySearch(this.currentPage, this.perPage, this.searchText.toLowerCase());
        } else if (this.searchText.length === 0) {
            this.getServicePoints(this.currentPage, this.perPage);
        }
    }

    created(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }

    sortList(): void {
        this.currentPage = 1;
        this.isSortByCount = this.sortByValue !== 'AZ';
        this.updateState();
    }

    @Watch(`$route`)
    onRouterChange(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }
}
</script>

<style lang='sass'>
.b-consult-service-points
    &.b-page-container--big
        max-width: 830px

    @include media('<=phone')
        padding: 0 15px

    .b-consult-button-size
        height: 44px
        display: flex
        align-items: center

.b-add-item-button
    height: 34px !important
    padding-top: 12px !important
</style>
