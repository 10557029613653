<template>
<div class='b-page-container b-page-container--big'>
    <div class='h-text-center h-pt-30'>
        <h1>
            {{ $t('CHOOSE_MANAGE.COMMON.TITLE') }}
        </h1>
    </div>
    <div class='b-consult-choose-cards__wrapper'>
        <component
            :is='checkPermissions(card) ? `router-link` : `div`'
            v-for='card in consultItems'
            :key='card.route'
            :class='card.class'
            class='b-consult-choose-cards__item'
            :to='checkPermissions(card) ? `/consult/choose/${card.route}` : null'>
            <FwSimpleCard
                height='130px'
                :isDisabled='!checkPermissions(card)'
                :isActive='checkPermissions(card)'>
                <div class='h-flex-center h-flex-space-between'>
                    <div class='b-consult-choose-cards__wrapper__image h-flex-center'>
                        <FwImage
                            :src='requireImage(card.image)'
                            :height='`${card.height || "64px"}`'
                            :width='`${card.width || "96px"}`'>
                        </FwImage>
                    </div>
                    <div class='h-font-22 h-bold h-ml-20'>
                        {{ $t(card.title) }}
                    </div>
                </div>
            </FwSimpleCard>
        </component>
    </div>
</div>
</template>

<script lang='ts'>
import { namespace, State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { ConsultChooseDataType, ConsultItemChooseDataType } from '@/types/Consult';
import { RIGHT_LIST } from '@/helpers/rights';
import { AppointmentSearchType } from '@/types/Appointment';
import { AccountTitlesType } from '@/types/Account';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const AppointmentStore = namespace('AppointmentStore');

const WORKERS_BLOCK: ConsultItemChooseDataType = {
    image: 'workers.svg',
    title: 'BREADCRUMBS.ADVISORS',
    route: 'advisors',
    right: RIGHT_LIST,
    rightBlock: 'advisor_rights',
    class: 'qa-consult-choose-workers-block',
    width: '96px',
    height: '67px',
};

const TECHNICIANS_BLOCK: ConsultItemChooseDataType = {
    image: 'workers.svg',
    title: 'SETTINGS.ROLE.WORKERS.TITLE',
    route: 'technicians',
    right: RIGHT_LIST,
    rightBlock: 'technician_rights',
    class: 'qa-consult-choose-technicians-block',
    width: '96px',
    height: '67px',
};

const CALENDARS_BLOCK: ConsultItemChooseDataType = {
    image: 'consult-calendar.svg',
    title: 'SELECT.APPOINTMENT.TITLE',
    route: 'appointments',
    right: RIGHT_LIST,
    rightBlock: 'appointment_rights',
    class: 'qa-consult-choose-appointments-block',
    width: '94px',
    height: '62px',
};

const SERVICE_POINT_BLOCK: ConsultItemChooseDataType = {
    image: 'consult-service-point.svg',
    title: 'SELECT.SERVICES_POINT.PLURAL.TITLE',
    route: 'service-points',
    right: RIGHT_LIST,
    rightBlock: 'service_point_rights',
    class: 'qa-consult-choose-services-point-block',
    width: '101px',
    height: '51px',
};

const HUBS_BLOCK: ConsultItemChooseDataType = {
    image: 'consult-hubs.svg',
    title: 'BREADCRUMBS.HUBS',
    route: 'hubs',
    right: RIGHT_LIST,
    rightBlock: 'hub_rights',
    class: 'qa-consult-choose-hubs-block',
    width: '96px',
    height: '67px',
};

const BRANCH_BLOCK: ConsultItemChooseDataType = {
    image: 'consult-branches.svg',
    title: 'ONBOARDING.OPTION.BRANCHES',
    route: 'branch',
    right: RIGHT_LIST,
    rightBlock: 'branch_rights',
    class: 'qa-consult-choose-branch-block',
};

const COMPANIES_BLOCK: ConsultItemChooseDataType = {
    image: 'partners-company.svg',
    title: 'PLAN.APPOINTMENT.BUSINESS.PARTNER.TITLE',
    route: 'companies',
    right: RIGHT_LIST,
    rightBlock: `company_rights`,
    class: 'qa-consult-choose-companies-block',
};

@Component
export default class ConsultChoosePage extends Vue {
    @BreadCrumbsStore.State('flowName') flowName!: string;
    @State(state => state.CommonStore.accountData) accountData!: AccountTitlesType;
    @AppointmentStore.Mutation('setAppointmentDataToUpdate') setAppointmentDataToUpdate!: (type: AppointmentSearchType | null) => void;

    value: string = '';
    loading: boolean = false;

    get consultChooseData(): ConsultChooseDataType {
        const servicePointItem = this.accountData?.account.service_point_support ? [SERVICE_POINT_BLOCK] : [];
        return {
            partners: [
                COMPANIES_BLOCK,
                TECHNICIANS_BLOCK,
                CALENDARS_BLOCK,
            ],
            company_hubs: [
                HUBS_BLOCK,
                WORKERS_BLOCK,
                CALENDARS_BLOCK,
            ],
            company_branches: [
                BRANCH_BLOCK,
                WORKERS_BLOCK,
                CALENDARS_BLOCK,
            ],
            company_both: [
                BRANCH_BLOCK,
                HUBS_BLOCK,
                WORKERS_BLOCK,
                CALENDARS_BLOCK,
            ],
            service_points_and_hubs: [
                HUBS_BLOCK,
                WORKERS_BLOCK,
                CALENDARS_BLOCK,
                ...(servicePointItem),
            ],
        };
    }

    get consultItems() {
        let result;
        if (this.user().account_kind === 'partner') {
            result = this.consultChooseData.partners;
        } else if (this.user().account_organization_type === 'with_hubs') {
            result = this.consultChooseData.company_hubs;
        } else if (this.user().account_organization_type === 'with_branches') {
            result = this.consultChooseData.company_branches;
        } else if (this.user().account_organization_type === 'with_service_points_and_hubs') {
            result = this.consultChooseData.service_points_and_hubs;
        } else {
            result = this.consultChooseData.company_both;
        }
        return result;
    }

    checkPermissions(card: ConsultItemChooseDataType) {
        return this.currentUserRole && this.currentUserRole[card.rightBlock] && this.currentUserRole[card.rightBlock][card.right];
    }

    mounted(): void {
        this.setAppointmentDataToUpdate(null);
    }
}
</script>

<style lang='sass'>
.b-society-selection

    .multiselect__option--highlight
        background: transparent!important

    .multiselect__option--highlight span
        color: rgb(47, 89, 151)!important

.b-consult-choose-cards__wrapper
    &__image
        img
            height: inherit !important
            width: inherit

        @include media('<=phone')
            width: 120px

    .fw-simple-card-wrapper
        justify-content: flex-start
</style>
