import { RouteType } from '@/types/router';
import { AppointmentBookedTypes } from '@/views/consult/branch-statistics/AppointmentBookedTypes';
import { AppointmentBookedMethods } from '@/views/consult/branch-statistics/AppointmentBookedMethods';
import { AppointmentBookedOrigin } from '@/views/consult/branch-statistics/AppointmentBookedOrigin';
import { AppointmentBookedByTimeSlot } from '@/views/consult/branch-statistics/AppointmentBookedByTimeSlot';
import { AppointmentBookedByTimeBetweenMaking } from '@/views/consult/branch-statistics/AppointmentBookedByTimeBetweenMaking';
import { AppointmentBookedByTimeBetweenAppointment } from '@/views/consult/branch-statistics/AppointmentBookedByTimeBetweenAppointment';
import { AppointmentBookedRecipients } from '@/views/consult/branch-statistics/AppointmentBookedRecipients';
import { AppointmentBookedTypesTaken } from '@/views/consult/branch-statistics/AppointmentBookedTypesTaken';
import { RIGHT_READ, CONSULT } from '@/helpers/rights';
import { BOOKED_GROUP, CANCELLED_GROUP, BRANCH_TYPE } from './consts';

const BASE_META = {
    showAppHeader: true,
    showAppHeaderProgress: true,
    rightBlock: 'kpis_rights',
    right: RIGHT_READ,
    pageType: CONSULT,
    type: BRANCH_TYPE,
};

const childrenBranchStatisticRoutes: Array<RouteType> = [
    {
        path: `/consult/branch/statistic/:id/appointment-booked-types/:type`,
        name: 'AppointmentBookedTypes',
        component: AppointmentBookedTypes,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.TYPES',
            titleFor: 'BRANCH.STATISTICS.BOOKED.TYPE',
            titleBy: 'BRANCH.STATISTICS.BOOKED.BY.TYPE',
            linkGroupName: BOOKED_GROUP,
            requestType: 'types',
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-booked-methods/:type',
        name: AppointmentBookedMethods.name,
        component: AppointmentBookedMethods,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.METHODS',
            titleFor: 'BRANCH.STATISTICS.BOOKED.TYPE',
            titleBy: 'BRANCH.STATISTICS.BOOKED.BY.TYPE',
            linkGroupName: BOOKED_GROUP,
            requestType: 'methods',
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-booked-starting-time/:type',
        name: AppointmentBookedByTimeSlot.name,
        component: AppointmentBookedByTimeSlot,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.BY_TIME_SLOT',
            titleFor: 'BRANCH.STATISTICS.BOOKED.TYPE',
            titleBy: 'BRANCH.STATISTICS.BOOKED.BY.TYPE',
            linkGroupName: BOOKED_GROUP,
            requestType: 'starting_times',
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-booked-time-between-booking/:type',
        name: AppointmentBookedByTimeBetweenMaking.name,
        component: AppointmentBookedByTimeBetweenMaking,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.TIME_BETWEEN',
            titleFor: 'BRANCH.STATISTICS.BOOKED.TYPE',
            titleBy: 'BRANCH.STATISTICS.BOOKED.BY.TYPE',
            linkGroupName: BOOKED_GROUP,
            requestType: 'times_between',
            sortByKeyLength: true,
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-booked-source/:type',
        name: AppointmentBookedOrigin.name,
        component: AppointmentBookedOrigin,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.SOURCE',
            titleFor: 'BRANCH.STATISTICS.BOOKED.TYPE',
            titleBy: 'BRANCH.STATISTICS.BOOKED.BY.TYPE',
            hideTabs: true,
            linkGroupName: BOOKED_GROUP,
            requestType: 'sources',
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-booked-recipients/:type',
        name: 'AppointmentBookedByRecipients',
        component: AppointmentBookedRecipients,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.RECIPIENTS',
            titleFor: 'BRANCH.STATISTICS.BOOKED.BY.TYPE',
            titleBy: 'BRANCH.STATISTICS.BOOKED.BY.TYPE',
            hideTabs: true,
            linkGroupName: BOOKED_GROUP,
            requestType: 'recipients',
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-types-cancelled-branch/:type',
        name: 'AppointmentTypeCancelledForBranch',
        component: AppointmentBookedTypesTaken,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.CANCELLATION.ORIGIN',
            titleFor: 'BRANCH.STATISTICS.CANCELLED.TYPE',
            titleBy: 'BRANCH.STATISTICS.CANCELLED.BY.TYPE',
            linkGroupName: CANCELLED_GROUP,
            title: 'BRANCH.STATISTICS.CANCELLED.TYPE',
            requestType: 'origins',
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-types-cancelled-time-between/:type',
        name: AppointmentBookedByTimeBetweenAppointment.name,
        component: AppointmentBookedByTimeBetweenAppointment,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.CANCELLATION.TIME.BETWEEN',
            titleFor: 'BRANCH.STATISTICS.CANCELLED.TYPE',
            titleBy: 'BRANCH.STATISTICS.CANCELLED.BY.TYPE',
            linkGroupName: CANCELLED_GROUP,
            requestType: 'times_between',
            sortByKeyLength: true,
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-cancelled-source/:type',
        name: 'AppointmentBookedForOrigin',
        component: AppointmentBookedOrigin,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.CANCELLED.SOURCE',
            titleFor: 'BRANCH.STATISTICS.CANCELLED.TYPE',
            titleBy: 'BRANCH.STATISTICS.CANCELLED.BY.TYPE',
            hideTabs: true,
            linkGroupName: CANCELLED_GROUP,
            requestType: 'sources',
        },
    },
    {
        path: '/consult/branch/statistic/:id/appointment-cancelled-recipients/:type',
        name: 'AppointmentBookedForRecipients',
        component: AppointmentBookedRecipients,
        meta: {
            ...BASE_META,
            text: 'BRANCH.STATISTICS.CANCELLED.RECIPIENTS',
            titleFor: 'BRANCH.STATISTICS.CANCELLED.BY.TYPE',
            titleBy: 'BRANCH.STATISTICS.CANCELLED.BY.TYPE',
            hideTabs: true,
            linkGroupName: CANCELLED_GROUP,
            requestType: 'recipients',
        },
    },
];

export default childrenBranchStatisticRoutes;
