<template>
<div class='b-settings-appointment-type'>
    <div class='b-settings__title'>
        <span>
            {{ $t('SETTINGS.MENU.APPT.TYPES') }}
            <template v-if='!(loading && !totalPage)'>
                ({{ totalPage }})
            </template>
        </span>
    </div>
    <div class='b-settings-reminder-types__sort__wrapper'>
        <div class='b-settings-reminder-types__tabs'>
            <router-link
                class='b-settings-reminder-types__tabs__item'
                to='/settings/appointment-types'
                :class='{ "active": !isArchiveTab }'>
                {{ $t('SETTINGS.MENU.ACTIVE') }}
            </router-link>
            <router-link
                class='b-settings-reminder-types__tabs__item'
                to='/settings/appointment-types?archived=1'
                :class='{ "active": isArchiveTab }'>
                {{ $t('SETTINGS.MENU.ARCHIVED') }}
            </router-link>
        </div>

        <FwButton
            v-if='canCreate'
            class='h-mt-20'
            size='little'
            :disabled='disableElements'
            borderRadiusType='small-border'
            @click='addAppointment'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else-if='appointmentCategories && appointmentCategories.length'
         class='b-settings-reminder-types__list__wrapper'>
        <AppointmentCategoryData
            :appointmentCategories='appointmentCategories'
            :appointmentsCategoriesTitleArray='appointmentsCategoriesTitleArray'
            :isArchive='isArchiveTab'
            withArchiveLogic
            @archive='archiveAppointment'
            @edit='editAppointment'
            @delete='deleteAppointment'>
        </AppointmentCategoryData>
    </div>
    <div v-else
         class='b-settings-reminder-types__list__empty'>
        {{ isArchiveTab ? $t(`SETTINGS.APPOINTMENT.TYPE.ARCHIVED.EMPTY`) : $t(`SETTINGS.APPOINTMENT.TYPE.ACTIVE.EMPTY`) }}
    </div>

    <FwPagination
        v-if='appointmentCategories && appointmentCategories.length && totalPage > perPage'
        :totalPage='totalPage'
        :currentPage='currentPage'
        :perPage='perPage'
        :value='perPage'
        @getData='updateState'>
    </FwPagination>

    <div v-if='appointmentLoading'
         class='b-fw-spin-loader-wrapper'>
        <FwSpinLoader
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='appointmentLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </div>

    <template v-if='isEditAppointment'>
        <EditAppointmentTypePopup
            v-if='!user().account_sc_supported'
            :startAppointmentCategoryData='startAppointmentCategoryData'
            @updateAppointments='fetchAppointmentTypes'
            @close='isEditAppointment = false'>
        </EditAppointmentTypePopup>
        <AddEditAppointmentTypePopup
            v-else
            :startAppointmentCategoryData='startAppointmentCategoryData'
            @updateAppointments='fetchAppointmentTypes'
            @close='isEditAppointment = false'>
        </AddEditAppointmentTypePopup>
    </template>

    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`DELETE.APPOINTMENT.TYPE.TITLE`)'
        :subMessage='$t(`DELETE.APPOINTMENT.TYPE.TEXT`)'
        @deleteItem='deleteItem'
        @close='confirmPopup = false'>
    </DeletePopup>

    <ArchivePopup
        v-if='isArchivePopupShow'
        :isArchive='isArchiveTab'
        @submit='doArchive'
        @close='isArchivePopupShow = false'>
    </ArchivePopup>
</div>
</template>

<script lang='ts'>
import { State, namespace } from 'vuex-class';
import { Component, Mixins, Watch } from 'vue-property-decorator';

import { isNil } from 'ramda';

import { RawLocation } from 'vue-router';
import {
    AppointmentReminderTypeListRouteType,
    StartAppointmentCategoryDataType,
    CategorySkillNameType,
    AppointmentCategoryType,
    AppointmentDataType,
    CategoryLocationSkillNameType,
} from '@/types/Appointment';

import i18n from '@/locale';

import { SettingsWebApi } from '@/api/settings/SettingsApi';

import { duration } from '@/helpers/duration';
import { calculateNextPage } from '@/helpers/pagination';

import { DeletePopup } from '@/components/popups/DeletePopup';
import { ArchivePopup } from '@/components/popups/ArchivePopup';
import { AppointmentCategoryData } from '@/components/common/AppointmentCategoryData';
import { EditAppointmentTypePopup } from '@/components/popups/EditAppointmentTypePopup';
import { AddEditAppointmentTypePopup } from '@/components/popups/AddEditAppointmentTypePopup';

import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';

const OnboardingStore = namespace('OnboardingStore');

@Component({
    components: {
        DeletePopup,
        ArchivePopup,
        AppointmentCategoryData,
        EditAppointmentTypePopup,
        AddEditAppointmentTypePopup,
    },
})
export default class SettingsAppointmentTypesPage extends Mixins(PermissionsMixin, AccountMixin) {
    @State(state => state.OnboardingStore.categoryLocationSkillNames) categoryLocationSkillNames!: Array<CategoryLocationSkillNameType>;
    @State(state => state.OnboardingStore.skills) skills!: Array<CategorySkillNameType>;
    @OnboardingStore.Action('fetchCategoryLocationSkillNames') fetchCategoryLocationSkillNames!: () => Promise<void>;
    @OnboardingStore.Action('fetchSkills') fetchSkills!: () => Promise<void>;

    currentPage: number = 1;
    totalPage: number = 0;
    perPage: number = 5;
    duration: Array<{ duration: number, title: string }> = duration;
    appointmentsCategoriesTitleArray: Array<string> = [
        'LABEL.CATEGORY',
        'LABEL.TITLE',
        'LABEL.TIME.TITLE',
        'LABEL.SKILLS.NEEDED',
        'LABEL.LOCATION.APPT',
    ].map(item => i18n.t(item));
    appointmentCategories: Array<AppointmentCategoryType> = []
    currentAppointment: AppointmentDataType | null = null;
    confirmPopup: boolean = false;
    isEditAppointment: boolean = false;
    appointmentLoading: boolean = false;
    isArchivePopupShow: boolean = false;
    loading: boolean = false;
    startAppointmentCategoryData: null | StartAppointmentCategoryDataType = null;
    editableIndex: null | number = null;

    get disableElements(): boolean {
        return !isNil(this.editableIndex);
    }

    get isArchiveTab(): boolean {
        return !!this.$route.query.archived;
    }

    async doArchive(): Promise<void> {
        if (!this.currentAppointment) return;
        try {
            this.isArchivePopupShow = false;
            await SettingsWebApi.editAppointmentType({ appointment_category: { archived: !this.isArchiveTab }}, this.currentAppointment.id);
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
            if (this.currentPage === 1 && this.currentAppointment) {
                this.doRequest();
            } else {
                this.updateState(1);
            }
        } catch (response) {
            this.sentNotif(response);
        } finally {
            this.currentAppointment = null;
        }
    }

    isDisabled(index: number): boolean {
        return this.disableElements && index !== (this.editableIndex as number);
    }

    addAppointment(): void {
        this.startAppointmentCategoryData = null;
        this.isEditAppointment = true;
    }

    save(): void {
        this.editableIndex = null;
    }

    takeParamsFromUrl(): void {
        if (this.$route.query && this.$route.query.archived) {
            this.currentPage = Number(this.$route.query.archived);
        } else {
            this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
        }
    }

    updateState(page: number = this.currentPage): void {
        let route: AppointmentReminderTypeListRouteType = {
            path: `/settings/appointment-types/${page}`,
        };

        if (this.$route.query && this.$route.query.archived) {
            route = {
                path: '/settings/appointment-types/',
                query: {
                    archived: page,
                },
            };
        }

        this.$router.push(route as RawLocation);
    }

    async fetchAppointmentTypes(): Promise<void> {
        this.loading = true;
        const payload = { per_page: this.perPage, page: this.currentPage, isArchive: this.isArchiveTab };
        const data = await SettingsWebApi.getAppointmentTypes(payload);
        this.appointmentCategories = data.appointment_categories;
        this.totalPage = data.meta.total_count;
        this.loading = false;
    }

    async deleteItem(): Promise<void> {
        if (this.currentAppointment) {
            try {
                await SettingsWebApi.deleteAppointmentType(this.currentAppointment.id);
                this.currentPage = calculateNextPage({ page: this.currentPage, per_page: this.perPage, total_items: this.totalPage });
                if (this.currentPage === 1 && this.currentAppointment) {
                    this.doRequest();
                } else {
                    this.updateState(1);
                }
                this.confirmPopup = false;
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            } catch ({ response }) {
                this.sentNotif(response);
            }
        }
    }

    async getAppointmentData(appointment: AppointmentCategoryType): Promise<void> {
        this.appointmentLoading = true;
        if (!this.categoryLocationSkillNames.length) {
            await this.fetchCategoryLocationSkillNames();
        }
        if (appointment) {
            const startAppointmentCategoryData: StartAppointmentCategoryDataType = {
                appointmentCategory: appointment.kind,
                timeFrom: this.duration.find(item => appointment.duration === item.duration)!,
                title: appointment.title,
                skillNeededActive: false,
                skillChoosed: [],
                selectable: appointment.selectable ? 'visible_to_all' : 'visible_with_link',
                visibility: appointment.visibility as string,
                custom_order: appointment.custom_order as string,
                sc_title: appointment.sc_title as string,
                checkboxLocation: [],
                id: appointment.id,
                subAccountsChoosed: appointment.sub_accounts as any,
            };
            if (appointment.skills.length) {
                if (!this.skills.length) {
                    await this.fetchSkills();
                }
                startAppointmentCategoryData.skillNeededActive = true;
                startAppointmentCategoryData.skillChoosed = this.skills.filter(item => appointment.skills.map(skill => skill.id).includes(item.id));
            }
            if (appointment.location_skills.length) {
                const locationIds = appointment.location_skills.map(item => item.id);
                startAppointmentCategoryData.checkboxLocation = this.categoryLocationSkillNames.filter(item => locationIds.includes(item.id));
            }
            this.startAppointmentCategoryData = startAppointmentCategoryData;
            this.appointmentLoading = false;
        }
    }

    async editAppointment(appointment: AppointmentCategoryType): Promise<void> {
        await this.getAppointmentData(appointment);
        this.isEditAppointment = true;
    }

    archiveAppointment(appointment: AppointmentDataType): void {
        this.currentAppointment = appointment;
        this.isArchivePopupShow = true;
    }

    deleteAppointment(appointment: AppointmentDataType): void {
        this.currentAppointment = appointment;
        this.confirmPopup = true;
    }

    doRequest(): void {
        this.takeParamsFromUrl();
        this.fetchAppointmentTypes();
    }

    created(): void {
        this.doRequest();
    }

    @Watch('$route')
    watchHandler(): void {
        this.doRequest();
    }
};
</script>

<style lang='sass'>
.b-settings-reminder-types
    &__search
        width: 350px

    &__sort
        width: 350px

        &__wrapper
            margin-top: 10px
            height: 63px
            display: flex
            justify-content: space-between
            align-items: baseline

    &__tabs
        display: flex
        font-size:  18px
        color: #213F6B
        margin-left: 40px

        &__item
            position: relative
            font-weight: 500
            opacity: 0.5

            &.active
                opacity: 1
                &:after
                    width: 80%
                    position: absolute
                    left: 0
                    content: ''
                    right: 0
                    bottom: -7px
                    border-top: 2px solid #213F6B
                    height: 0
                    margin: auto

            &:not(.active)
                cursor: pointer

            &:not(:last-of-type)
                margin-right: 60px

    &__list
        &__empty
            font-size: 18px
            text-align: center
            padding-top: 30px
            width: 100%
            font-weight: 500

        &__wrapper
            margin-top: 20px

        &__select
            width: 100%

        &__flex
            display: flex
            margin-bottom: 10px

            &--disabled
                opacity: 0.5
                position: relative
                &:after
                    content: ''
                    position: absolute
                    left: 0
                    top: 0
                    cursor: not-allowed
                    width: 100%
                    height: 100%

            &--edit
                .b-settings-reminder-types__list__type
                    padding: 0
                .fw-select
                    box-shadow: none !important

            &--head
                .b-settings-reminder-types__list__type
                    opacity: 0.4
                    padding: 0 0 0 30px

                .b-settings-reminder-types__list__icon
                    background-color: transparent

        &__icon
            flex: 0 0 53px
            margin-left: 10px
            display: flex
            align-items: center
            justify-content: center
            background-color: #fff
            border-radius: 5px

            &__wrapper
                height: 100%
                width: 100%
                display: flex
                align-items: center
                justify-content: center
                flex-direction: column
                cursor: pointer
                &:hover
                    opacity: 0.9

            &__success
                background-color: #27DBBD
                color: #fff
                flex-direction: column

            &__cancel
                background-color: #EB2767
                color: #fff
                flex-direction: column

            &__text
                font-size: 9px
                text-transform: uppercase
                color: #fff
                margin-top: 7px
                font-weight: 500

        &__type
            flex: 1 0
            color: #203f6a
            display: flex
            font-weight: 500
            padding: 16px 10px 16px 30px

            &--big
                flex: 2 0

        &__white-bg
            background-color: #fff
            border-radius: 5px
            display: flex
            width: 100%

.b-settings-appointment-type
    .fw-horizontal-scroll__inner
        height: auto !important

    .b-calendar__table__inner
        max-width: none !important
</style>

