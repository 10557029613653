<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        max-width='750px'
        width='auto'
        :title='popupTitle'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <EditUserForm
                v-if='defaultFormValues'
                :isRequestSending='isRequestSending'
                iconsWrapperClass='h-flex-dir-column h-flex'
                :defaultFormValues='defaultFormValues'
                :disabledForm='false'
                submitText='BUTTON.SAVE'
                @onSubmit='sendDataToServer'>
            </EditUserForm>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { EditUserForm } from '@/components/forms/edit/EditUserForm';
import { User } from '@/types/User';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { AccountApi } from '@/api/account/AccountApi';

const GlobalStore = namespace('GlobalStore');

@Component({
    components: {
        EditUserForm,
    },
})
export default class EditPopup extends Vue {
    @GlobalStore.Mutation('setUserData') setUserData!: Function;
    @Prop({ type: String, default: 'Form title' }) readonly popupTitle!: string;
    @Prop({ type: Object, default: null }) readonly defaultFormValues!: User;
    isRequestSending: boolean = false;
    userData = this.user();
    sendDataToServer(data: any): void {
        this.isRequestSending = true;
        AuthWebApi.editUserAccount(
            {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                phone: data.phone,
            },
            this.userData.id
        ).then(response => {
            if (response.status === 200) {
                this.isRequestSending = false;
                this.$store.commit('GlobalStore/mutationLoginUser', response.data.user);
                if (data.logo !== this.user().avatar_url && !this.user().avatar_url) {
                    const formData = new FormData();
                    formData.append(`user[avatar]`, data.logo);
                    this.sendAvatarToServer(formData);
                } else {
                    this.$emit('close');
                }
            }
        }).catch(({ response }) => {
            this.isRequestSending = false;
            this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
        });
    }

    sendAvatarToServer(avatarImg: any): void {
        if (avatarImg) {
            AccountApi.sendAvatarUser(
                {
                    avatar: avatarImg,
                },
                this.userData.id,
            ).then(response => {
                if (response.status === 200) {
                    this.$store.commit('GlobalStore/mutationLoginUser', response.data.user);
                    this.$emit('close');
                }
            }).catch(({ response }) => {
                this.serverErrors = Object.assign(this.serverErrors, this.prepareServerFormErrors(response.data));
            });
        }
    }
}
</script>
