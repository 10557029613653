<template>
<div class='container'>
    <div class='b-base-title__wrapper'>
        <h2 class='b-appointment-left-bar__title'>
            {{ $t('ONBOARDING.CHOOSE.TITLE') }}
        </h2>
    </div>
    <div class='b-choose__wrapper'>
        <div class='b-choose'
             @click='sendDateToServer("individual")'>
            <FwImage
                :src='requireImage("my-company.svg")'>
            </FwImage>
            <h2 class='b-choose__title h-mt-25'>
                {{ $t('ONBOARDING.CHOOSE.MY.COMPANY.TEXT') }}
            </h2>
        </div>
        <div class='b-choose'
             @click='sendDateToServer("partner")'>
            <FwImage
                :src='requireImage("partners-company.svg")'>
            </FwImage>
            <h2 class='b-choose__title h-mt-25'>
                {{ $t('ONBOARDING.CHOOSE.PARTNER.COMPANY.TEXT') }}
            </h2>
        </div>
    </div>

    <portal v-if='showComingSoonPopup'
            to='popupWrapper'>
        <ComingSoonPopup @close='showComingSoonPopup = false'></ComingSoonPopup>
    </portal>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ComingSoonPopup } from '@/components/popups/ComingSoonPopup';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { AccountType } from '@/types/Account';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const GlobalStore = namespace('GlobalStore');

@Component({
    components: {
        ComingSoonPopup,
    },
})
export default class OnboardingChooseForWhoPage extends Vue {
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @BreadCrumbsStore.Mutation('setFlowName') setFlowName!: (key: string) => void;
    @GlobalStore.Mutation('setAccount') setAccount!: (account: AccountType) => void;
    showComingSoonPopup: boolean = false;
    isRequestSending: boolean = false;

    kindMapping: { [key: string]: string } = {
        individual: 'account-onboarding',
        partner: 'partners-onboarding',
    };

    setKind(name: string) {
        this.$router.push(`/${name}/categories/add-category`);
        if (name === 'onboarding-partners') {
            this.setFlowKey(name);
        }
        this.setFlowName(name);
    }

    async sendDateToServer(kind: string) {
        if (!this.isRequestSending) {
            this.isRequestSending = true;
            try {
                const account = await AppointmentWebApi.chooseAppointmentKind({ kind }, this.user().account_id);
                this.setKind(this.kindMapping[kind]);
                this.setAccount(account);
            } catch ({ response }) {
                if (this.user().account_kind) {
                    this.setKind(this.kindMapping[this.user().account_kind]);
                }
                this.sentNotif(response);
            } finally {
                this.isRequestSending = false;
            }
        }
    }
}
</script>
