import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { AttributeExtendedType } from '@/types/Attribute';
import { EN_LOCAL } from '@/helpers/locale';
import { AccountTitlesType } from '@/types/Account';
import { checkIsValid } from '@/components/forms/attributes/helpers';

const CommonStore = namespace('CommonStore');

@Component
export default class DynamicFormMixin extends Vue {
    @CommonStore.State('accountData') accountData!: AccountTitlesType;
    schema: Array<AttributeExtendedType> = [];

    get isErrorInForm(): boolean {
        return this.prepareAttributeSchema.some(item => !item.isValid);
    }

    get currentLangKey(): `en_name` | `localized_name` {
        return this.$i18n.locale === EN_LOCAL ? `en_name` : `localized_name`;
    }

    get prepareAttributeSchema(): Array<AttributeExtendedType> {
        if (!this.schema || this.schema.length === 0) {
            return [];
        }

        return this.schema.map(item => ({
            ...item,
            className: this.schemaClass || `b-booking-form__input-wrapper`,
            value: item.value || ``,
            title: item[this.currentLangKey] || ``,
            isValid: checkIsValid(item),
        }));
    }

    updateSchema(schema: Array<AttributeExtendedType>) {
        this.schema = schema;
    }
};
