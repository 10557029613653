<template>
<div :class='formClassWrapper' class='b-appointment-left-bar_form'>
    <div v-if='eventTime || dates'
         class='h-mb-40'
         :class='iconsWrapperClass'>
        <div v-if='eventTime'
             :class='iconsInnerClass'
             class='h-inline-flex h-mv-3'>
            <FwIcon class='h-mh-5 h-mr-15 h-pointer'
                    icon='calendar-confirmed'
                    size='16'
                    color='#213F6B'>
            </FwIcon>
            <span>
                {{ eventTime }}
            </span>
        </div>
        <div v-if='dates'
             :class='iconsInnerClass'
             class='h-inline-flex h-mv-3'>
            <div v-if='dates'
                 :class='iconsInnerClass'
                 class='h-inline-flex h-mv-3'>
                <FwIcon
                    class='h-mh-5 h-mr-15 h-pointer'
                    icon='calendar-time'
                    size='16'
                    color='#213F6B'>
                </FwIcon>
                <div class='h-flex h-flex-center'>
                    <div v-for='(time, index) in dates'
                         :key='index'
                         class='b-appointment-left-bar__time'>
                        <span>
                            {{ defaultFormValues.full_day ? "00:00" : time }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <form class='h-mt-15'
          :class='formClass'
          @keyup.enter='onSubmit'>
        <FwFormInput
            v-if='defaultFormValues.fetched_details_raw[ `is_professional ` ]'
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(REASON_COMPANY_NAME)'
            labelType='label-medium'
            :title='$t(`LABEL.COMPANY.REASON.NAME`)'>
            <FwInput
                :value='reason_company_name'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.COMPANY.REASON.NAME`)'
                :error='getFieldErrorText(REASON_COMPANY_NAME)'
                inputStyle='white'
                :disabled='disabledForm'
                @update:value='value => reason_company_name = value'
                @input='clearServerErrorsBase(REASON_COMPANY_NAME)'
                @blur='addCurrentInputToValidateArray(REASON_COMPANY_NAME)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(CLIENT_SURNAME)'
            labelType='label-medium'
            :title='$t(`LABEL.CLIENT.LAST.NAME`)'>
            <FwInput
                :value='client_surname'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.CLIENT.LAST.NAME`)'
                inputStyle='white'
                :disabled='disabledForm'
                :error='getFieldErrorText(CLIENT_SURNAME)'
                @update:value='value => client_surname = value'
                @input='clearServerErrorsBase(CLIENT_SURNAME)'
                @blur='addCurrentInputToValidateArray(CLIENT_SURNAME)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(CLIENT_NAME)'
            labelType='label-medium'
            :title='$t(`LABEL.CLIENT.NAME`)'>
            <FwInput
                :value='client_name'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.CLIENT.NAME`)'
                inputStyle='white'
                :disabled='disabledForm'
                :error='getFieldErrorText(CLIENT_NAME)'
                @update:value='value => client_name = value'
                @input='clearServerErrorsBase(CLIENT_NAME)'
                @blur='addCurrentInputToValidateArray(CLIENT_NAME)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(CONTRACT_NUMBER)'
            labelType='label-medium'
            :title='$t(`LABEL.CONTRACT.NUMBER`)'>
            <FwInput
                :value='contract_number'
                type='tel'
                name='text'
                :placeholder='$t(`LABEL.CONTRACT.NUMBER`)'
                inputStyle='white'
                :disabled='disabledForm'
                :error='getFieldErrorText(CONTRACT_NUMBER)'
                @update:value='value => contract_number = value'
                @input='clearServerErrorsBase(CONTRACT_NUMBER)'
                @blur='addCurrentInputToValidateArray(CONTRACT_NUMBER)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(LOCATION_VALUE)'
            labelType='label-medium'
            :title='$t(`LABEL.APPOINTMENT.LOCATION`)'>
            <FwInput
                componentType='textarea'
                :value='location_value'
                type='text'
                name='text'
                :placeholder='$t(`LABEL.APPOINTMENT.LOCATION`)'
                inputStyle='textarea'
                :disabled='disabledForm'
                :error='getFieldErrorText(LOCATION_VALUE)'
                @update:value='value => location_value = value'
                @input='clearServerErrorsBase(LOCATION_VALUE)'
                @blur='addCurrentInputToValidateArray(LOCATION_VALUE)'>
            </FwInput>
        </FwFormInput>
        <FwFormInput
            class='b-base-form-margin'
            :fieldHasError='fieldHasError(COMPANY_NAME)'
            labelType='label-medium'
            :title='$t(`CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME`)'>
            <FwInput
                :value='company_name'
                type='text'
                name='text'
                :placeholder='$t(`CONSULT.ADD.FILE.RESULT.FIELDS.COMPANY_NAME`)'
                :error='getFieldErrorText(COMPANY_NAME)'
                inputStyle='white'
                :disabled='disabledForm'
                @update:value='value => company_name = value'
                @input='clearServerErrorsBase(COMPANY_NAME)'
                @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
            </FwInput>
        </FwFormInput>
    </form>
    <div v-if='submitText'
         class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-20'
            :disabled='isRequestSending'
            borderRadiusType='tiny-border'
            @click='onSubmit'>
            {{ $t(submitText) }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import CalendarMixin from '@/mixins/calendar';
import {
    REASON_COMPANY_NAME,
    COMPANY_NAME,
    CLIENT_SURNAME,
    CLIENT_NAME,
    CONTRACT_NUMBER,
    LOCATION_VALUE,
} from '@/helpers/events';
import { CompanyData } from '@/types/Companies';
import { AppointmentDataType, BookAppointmentDataType } from '@/types/Appointment';

@Component({
    validators: {
        [REASON_COMPANY_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [COMPANY_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [CLIENT_SURNAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [CLIENT_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [CONTRACT_NUMBER](value: string) {
            return this.baseInputValidator({ value });
        },
        [LOCATION_VALUE](value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class EventForm extends Mixins(ValidationMixin, CalendarMixin) {
    @Prop({ type: String, default: 'BUTTON.SAVE' }) readonly submitText!: string;
    @Prop({ type: Object, default: null }) readonly defaultFormValues!: AppointmentDataType;
    @Prop({ type: Object, default: null }) readonly companyData!: CompanyData;
    @Prop({ type: String, default: null }) readonly formClassWrapper!: string;
    @Prop({ type: String, default: null }) readonly formClass!: string;
    @Prop({ type: String, default: null }) readonly iconsWrapperClass!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly iconsInnerClass!: string;
    @Prop({ type: String, default: null }) readonly eventTime!: string;
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;
    @Prop({ type: Array, default: null }) readonly dates!: BookAppointmentDataType;

    [REASON_COMPANY_NAME]: string | null = '';
    [COMPANY_NAME]: string | null = '';
    [CLIENT_SURNAME]: string | null = '';
    [CLIENT_NAME]: string | null = '';
    [CONTRACT_NUMBER]: number | string | null = '';
    [LOCATION_VALUE]: string | null = '';
    REASON_COMPANY_NAME: string = REASON_COMPANY_NAME;
    COMPANY_NAME: string = COMPANY_NAME;
    CLIENT_SURNAME: string = CLIENT_SURNAME;
    CLIENT_NAME: string = CLIENT_NAME;
    CONTRACT_NUMBER: string = CONTRACT_NUMBER;
    LOCATION_VALUE: string = LOCATION_VALUE;

    serverErrors: serverErrors = {
        [COMPANY_NAME]: null,
        [REASON_COMPANY_NAME]: null,
        [CLIENT_SURNAME]: null,
        [CLIENT_NAME]: null,
        [CONTRACT_NUMBER]: null,
        [LOCATION_VALUE]: null,
    };
    get companyName() {
        return this.defaultFormValues.company_title;
    }
    mounted() {
        if (this.defaultFormValues) {
            const keys = Object.keys(this.defaultFormValues);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                // @ts-ignore-next-line
                this[key] = this.defaultFormValues[key];
                this.addCurrentInputToValidateArray(key);
            }
            this[REASON_COMPANY_NAME] = this.defaultFormValues.fetched_details_raw!.company_name;
            this[COMPANY_NAME] = this.companyName;
            this[CLIENT_SURNAME] = this.defaultFormValues.fetched_details_raw!.last_name;
            this[CLIENT_NAME] = this.defaultFormValues.fetched_details_raw!.first_name;
            this[CONTRACT_NUMBER] = this.defaultFormValues.fetched_details_raw!.contract_number;
            this[LOCATION_VALUE] = this.defaultFormValues.location;
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return {
            [REASON_COMPANY_NAME]: this[REASON_COMPANY_NAME],
            [COMPANY_NAME]: this[COMPANY_NAME],
            [CLIENT_SURNAME]: this[CLIENT_SURNAME],
            [CLIENT_NAME]: this[CLIENT_NAME],
            [CONTRACT_NUMBER]: this[CONTRACT_NUMBER],
            [LOCATION_VALUE]: this[LOCATION_VALUE],
        };
    }
}
</script>

<style lang='sass'>
.b-appointment-left-bar_form
    .fw-input--white, .fw-input--textarea
        box-shadow: none
        &::placeholder
            color: #909FB5
    .fw-input__error-text
        margin: 4px 0 15px
        font-size: 12px
        color: #FF004D
.b-appointment-left-bar__time
    &:not(:first-of-type)
        &:before
            content: '-'
            margin: 0 3px

.b-base-form-margin
    margin-bottom: 35px
    @include media('<tablet')
        margin-bottom: 10px
</style>
