<template>
<div class='b-blue-background
            b-blue-background__opacity
            b-cancel-popup__select
            b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='700px'
        width='695px'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        :padding='isMobile() ? "30px 20px" : "40px 90px 25px"'
        :disableClosingOnClickOutside='true'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mt-20 h-mb-20 h-mb-0 qa-advisor-availability-title'>
                    {{ $t('ADD.RESERVED.SLOT.FORM.SET.AVAILABILITY.TITLE') }}
                </h2>
                <div class='h-mv-30 h-flex h-flex-dir-column'>
                    <p class='h-fw-500 h-mv-0'>
                        {{ $t(`LABEL.ADVISOR.BY.DEFAULT`) }}
                    </p>
                    <FwCheckbox
                        v-model='flexibleValue'
                        value='strict'
                        class='icon-white__background h-mt-10'
                        :class='{ "qa-advisor-availability-checkbox": !flexibleValue }'
                        :style='{ pointerEvents: flexibleValue === `strict` ? `none` : `auto` }'
                        type='white-with-text'
                        :text='$t("ADD.RESERVED.SLOT.FORM.TITLE.AVAILABLE")'
                        name='checkbox'
                        itemWidth='310px'
                        singleSelect
                        :itemCheckedRight='false'>
                    </FwCheckbox>
                    <FwCheckbox
                        v-model='flexibleValue'
                        value='flex'
                        class='icon-white__background h-mt-10'
                        :class='{ "qa-advisor-availability-checkbox": !flexibleValue }'
                        :style='{ pointerEvents: flexibleValue === `flex` ? `none` : `auto` }'
                        type='white-with-text'
                        :text='$t("ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE")'
                        name='checkbox'
                        itemWidth='310px'
                        singleSelect
                        :itemCheckedRight='false'>
                    </FwCheckbox>
                </div>

                <p class='h-fw-500 h-mt-0'>
                    {{ $t('ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TEXT') }}
                </p>

                <p class='h-fw-500 h-mt-0'>
                    {{ $t('ADD.RESERVED.SLOT.FORM.AVAILABILITY.TEXT') }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center h-flex-dir-column'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-10 qa-advisor-availability-submit'
                    borderRadiusType='small-border'
                    :disabled='isDisabled'
                    @click='changeAvailabilityType'>
                    {{ $t('BUTTON.CONFIRM2') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { workerDataType } from '@/types/User';

const WorkerPageStore = namespace('WorkerPageStore');

@Component
export default class SetAvailabilitiesPopup extends Vue {
    @WorkerPageStore.Mutation('WORKER_DATA') setWorkerData!: Function;
    @WorkerPageStore.State('workerData') workerData!: workerDataType;

    flexibleValue: string = 'strict';

    get isDisabled() {
        // @ts-ignore-next-line
        return this.workerData.technician_profile.availability_type && this.workerData.technician_profile.availability_type === this.flexibleValue;
    }

    async changeAvailabilityType() {
        try {
            const { data } = await WorkersWebApi.updateWorkerAvailabilityType({
                availability_type: this.flexibleValue,
                // @ts-ignore-next-line
            }, this.workerData.id);

            this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);

            this.setWorkerData(data.user);
            this.$emit('close');
            this.$emit('getWorker');
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    // @ts-ignore-next-line
    created() {
        // @ts-ignore-next-line
        if (this.workerData && this.workerData.technician_profile.availability_type) {
            // @ts-ignore-next-line
            this.flexibleValue = this.workerData.technician_profile.availability_type;
        }
    }
}
</script>

<style lang='sass'>
.b-unavailabilities
    &__wrapper
        .mx-datepicker
            box-shadow: none !important

        .fw-popup__sub_popup
            width: 380px !important
            max-height: calc(100vh - 70px)
            transform: translate(80%, 0) !important

            @include media('<=phone')
                transform: none !important

            @media (max-height: 700px)
                .b-add-reserved-slot-form__date
                    display: none !important

                .b-add-reserved-slot-form__title
                    position: relative
                    top: -10px

                .b-add-reserved-slot-form__close_popup
                    top: 20px

            @media (max-height: 900px)
                padding: 30px 30px 50px !important
</style>
