const FIRST_NAME = 'first_name';
const LAST_NAME = 'last_name';
const SKILLS = 'skills';
const COMPANY = 'company';
const PHONE = 'phone';
const EMAIL = 'email';
const PROFESSIONAL_TITLE = 'professional_title';
const ADMIN = 'admin';
const GEOGRAPHIC_ZONE = 'geographic_zone';
const HUBS = 'hubs';
const BRANCHES = 'branches';
const REMOTE_ID = 'remote_id';

export {
    FIRST_NAME,
    LAST_NAME,
    SKILLS,
    COMPANY,
    PHONE,
    EMAIL,
    PROFESSIONAL_TITLE,
    ADMIN,
    GEOGRAPHIC_ZONE,
    HUBS,
    BRANCHES,
    REMOTE_ID,
};
