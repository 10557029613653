import { AppointmentSelectType, AppointmentSelectResponseType } from '@/types/Appointment';

const eventsServer = {
    'events': [
        {
            'id': '770a482e-3d9a-4dec-8222-3845c65d5526',
            'created_at': '2019-12-10T14:15:20.742+01:00',
            'updated_at': '2019-12-10T14:15:20.742+01:00',
            'user_id': '0be10406-465d-4e8d-b74b-fd5fcb83b6a8',
            'appointment_id': null,
            'title': 'event recurrent',
            'description': 'recurrent',
            'origin': 'from_user',
            'location': null,
            'latitude': null,
            'longitude': null,
            'time_zone': 'Europe/Paris',
            'dt_start': '2019-12-11T01:00:00.000+01:00',
            'dt_end': '2019-12-12T00:59:00.000+01:00',
            'dt_until': '2020-01-10T11:24:14.000+01:00',
            'freq': 'weekly',
            'interval': 1,
            'by_day': [],
            'by_month_day': null,
            'by_year_day': null,
            'by_month': null,
            'occurrences_count': null,
            'full_day': true,
            'first_reminder_minutes': null,
            'second_reminder_minutes': null,
            'calendar_instances': [
                {
                    'event_id': '770a482e-3d9a-4dec-8222-3845c65d5526',
                    'dt_start': '2019-12-11T01:00:00.000+01:00',
                    'dt_end': '2019-12-12T00:59:00.000+01:00',
                    'duration_in_minutes': 1439,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
                {
                    'event_id': '770a482e-3d9a-4dec-8222-3845c65d5526',
                    'dt_start': '2019-12-18T01:00:00.000+01:00',
                    'dt_end': '2019-12-19T00:59:00.000+01:00',
                    'duration_in_minutes': 1439,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
                {
                    'event_id': '770a482e-3d9a-4dec-8222-3845c65d5526',
                    'dt_start': '2019-12-25T01:00:00.000+01:00',
                    'dt_end': '2019-12-26T00:59:00.000+01:00',
                    'duration_in_minutes': 1439,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
            ],
        },
        {
            'id': '4df15013-3c1d-49f7-8aad-eac267458dd5',
            'created_at': '2019-12-10T11:09:47.760+01:00',
            'updated_at': '2019-12-10T11:09:47.760+01:00',
            'user_id': '0be10406-465d-4e8d-b74b-fd5fcb83b6a8',
            'appointment_id': null,
            'title': 'unavailability',
            'description': 'unavailability reason',
            'origin': 'from_unavailability',
            'location': null,
            'latitude': null,
            'longitude': null,
            'time_zone': 'Europe/Paris',
            'dt_start': '2019-12-11T10:59:00.000+01:00',
            'dt_end': '2019-12-11T12:59:00.000+01:00',
            'dt_until': null,
            'freq': null,
            'interval': 1,
            'by_day': [],
            'by_month_day': null,
            'by_year_day': null,
            'by_month': null,
            'occurrences_count': null,
            'full_day': false,
            'first_reminder_minutes': null,
            'second_reminder_minutes': null,
            'calendar_instances': [
                {
                    'event_id': '4df15013-3c1d-49f7-8aad-eac267458dd5',
                    'dt_start': '2019-12-11T10:59:00.000+01:00',
                    'dt_end': '2019-12-11T12:59:00.000+01:00',
                    'duration_in_minutes': 120,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
            ],
        },
        {
            'id': 'c45db9c6-71ee-4be9-93b9-6979154c819a',
            'created_at': '2019-12-10T14:42:45.716+01:00',
            'updated_at': '2019-12-10T14:44:00.318+01:00',
            'user_id': '0be10406-465d-4e8d-b74b-fd5fcb83b6a8',
            'appointment_id': null,
            'title': 'event recurrent',
            'description': 'recurrent',
            'origin': 'from_unavailability',
            'location': null,
            'latitude': null,
            'longitude': null,
            'time_zone': 'Europe/Paris',
            'dt_start': '2019-12-11T10:59:00.000+01:00',
            'dt_end': '2019-12-11T12:59:00.000+01:00',
            'dt_until': '2020-01-10T11:24:14.000+01:00',
            'freq': 'weekly',
            'interval': 1,
            'by_day': [],
            'by_month_day': null,
            'by_year_day': null,
            'by_month': null,
            'occurrences_count': null,
            'full_day': false,
            'first_reminder_minutes': null,
            'second_reminder_minutes': null,
            'calendar_instances': [
                {
                    'event_id': 'c45db9c6-71ee-4be9-93b9-6979154c819a',
                    'dt_start': '2019-12-11T10:59:00.000+01:00',
                    'dt_end': '2019-12-11T12:59:00.000+01:00',
                    'duration_in_minutes': 120,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
                {
                    'event_id': 'c45db9c6-71ee-4be9-93b9-6979154c819a',
                    'dt_start': '2019-12-18T10:59:00.000+01:00',
                    'dt_end': '2019-12-18T12:59:00.000+01:00',
                    'duration_in_minutes': 120,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
                {
                    'event_id': 'c45db9c6-71ee-4be9-93b9-6979154c819a',
                    'dt_start': '2019-12-25T10:59:00.000+01:00',
                    'dt_end': '2019-12-25T12:59:00.000+01:00',
                    'duration_in_minutes': 120,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
            ],
        },
        {
            'id': '5557edc2-55a5-41e3-9690-a9117445d57b',
            'created_at': '2019-12-10T11:00:42.234+01:00',
            'updated_at': '2019-12-10T16:21:32.171+01:00',
            'user_id': '0be10406-465d-4e8d-b74b-fd5fcb83b6a8',
            'appointment_id': null,
            'title': 'event',
            'description': 'event description',
            'origin': 'from_user',
            'location': '4 Rue Théodore de Banville, 75017 Paris',
            'latitude': 48.8814207,
            'longitude': 2.2999308,
            'time_zone': 'Europe/Paris',
            'dt_start': '2019-12-11T12:59:00.000+01:00',
            'dt_end': '2019-12-11T18:59:00.000+01:00',
            'dt_until': null,
            'freq': null,
            'interval': 1,
            'by_day': [],
            'by_month_day': null,
            'by_year_day': null,
            'by_month': null,
            'occurrences_count': null,
            'full_day': false,
            'first_reminder_minutes': null,
            'second_reminder_minutes': null,
            'calendar_instances': [
                {
                    'event_id': '5557edc2-55a5-41e3-9690-a9117445d57b',
                    'dt_start': '2019-12-11T12:59:00.000+01:00',
                    'dt_end': '2019-12-11T18:59:00.000+01:00',
                    'duration_in_minutes': 360,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
            ],
        },
        {
            'id': '481e3463-a2f4-4bc4-83d5-df9c482dbab8',
            'created_at': '2019-12-10T18:01:31.503+01:00',
            'updated_at': '2019-12-10T18:01:31.503+01:00',
            'user_id': '2e87aa4e-7b06-49f0-8a39-107e225c825d',
            'appointment_id': 'e10758bd-06a7-446e-af46-84b8b67fcfed',
            'title': 'INS',
            'description': null,
            'origin': 'from_appointment',
            'location': '5 Avenue Van Dyck, 75008 Paris',
            'latitude': 48.8784571,
            'longitude': 2.3059357,
            'time_zone': 'Europe/Paris',
            'dt_start': '2019-12-12T13:56:00.000+01:00',
            'dt_end': '2019-12-12T14:56:00.000+01:00',
            'dt_until': null,
            'freq': null,
            'interval': 1,
            'by_day': [],
            'by_month_day': null,
            'by_year_day': null,
            'by_month': null,
            'occurrences_count': null,
            'full_day': false,
            'first_reminder_minutes': null,
            'second_reminder_minutes': null,
            'calendar_instances': [
                {
                    'event_id': '481e3463-a2f4-4bc4-83d5-df9c482dbab8',
                    'dt_start': '2019-12-12T13:56:00.000+01:00',
                    'dt_end': '2019-12-12T14:56:00.000+01:00',
                    'duration_in_minutes': 60,
                    'first_reminder_at': null,
                    'second_reminder_at': null,
                },
            ],
        },
    ],
    'meta': {
        'current_page': 1,
        'next_page': null,
        'prev_page': null,
        'total_count': 5,
        'total_pages': 1,
    },
    'code': 0,
    'errors': [],
};
const appointmentData: Array<AppointmentSelectType> = [
    {
        title: 'SAV appointment',
        id: 1,
    },
    {
        title: 'Law consultation',
        id: 2,
    },
    {
        title: 'Loan consultation',
        id: 3,
    },
];
const appointmentLocationData: Array<AppointmentSelectType> = [
    {
        title: 'On site',
        id: 1,
    },
    {
        title: 'By phone',
        id: 2,
    },
    {
        title: 'In branch - face to face public',
        id: 3,
    },
    {
        title: 'In branch - face to face private',
        id: 4,
    },
    {
        title: 'In branch - videoconference appointment',
        id: 5,
    },
];

const appointmentLocationResponseData: AppointmentSelectResponseType = {
    location_category_on_site: {
        en_name: 'On site',
        fr_name: 'En clientèle',
        icon: 'slots-on-site',
        title: 'location_category_on_site',
    },
    location_category_by_phone: {
        en_name: 'By phone',
        fr_name: 'Au téléphone',
        icon: 'slots-on-phone',
        title: 'location_category_by_phone',
    },
    location_category_on_site_in_branch: {
        en_name: 'On Site - In Branch',
        fr_name: 'Dans mon agence',
        icon: 'slots-on-site',
        title: 'location_category_on_site_in_branch',
    },
    location_category_in_branch_public: {
        en_name: 'In branch - Face to Face - Public',
        fr_name: 'En agence',
        icon: 'slots-face-to-face',
        title: 'location_category_in_branch_public',
    },
    location_category_in_branch_client_video_private: {
        en_name: 'In branch - Videoconference',
        fr_name: 'En agence- exterieur avec vidéoconference',
        icon: 'slots-branch-private',
        title: 'location_category_in_branch_video',
    },
    location_category_in_branch_private: {
        en_name: 'In branch - Face to Face - Private',
        fr_name: 'En agence avec salle',
        icon: 'slots-branch-private',
        title: 'location_category_in_branch_private',
    },
    location_category_in_branch_by_phone_public: {
        en_name: 'In Branch - By Phone - Public',
        fr_name: 'Au téléphone (agence)',
        icon: 'slots-phone-public',
        title: 'location_category_in_branch_by_phone_public',

    },
    location_category_in_branch_by_phone_private: {
        en_name: 'In Branch - By Phone - Private',
        fr_name: 'Au téléphone avec salle (agence)',
        icon: 'slots-phone-private',
        title: 'location_category_in_branch_by_phone_private',
    },
    location_category_in_branch_by_video_public: {
        en_name: 'In Branch - By Video - Public',
        fr_name: 'En visioconférence (agence)',
    },
    location_category_in_branch_by_video_private: {
        en_name: 'In Branch - By Video - Private',
        fr_name: 'En visioconférence avec salle (agence)',
    },
    location_category_by_online_video: {
        en_name: 'By online video',
        fr_name: 'En visioconférence',
        icon: 'slots-by-video',
        title: 'location_category_by_online_video',
    },
};

export {
    eventsServer,
    appointmentData,
    appointmentLocationData,
    appointmentLocationResponseData,
};
