<template>
<div class='b-room-list qa-user-list'>
    <div class='b-room-list__inner h-pv-10'>
        <div v-for='room in rooms'
             class='b-room-list__item'
             :class='{
                 "b-room-list__item--active": currentRoomId === room.id,
             }'
             @click='$emit("click", room.id)'>
            <div class='b-room-list__item__text'>
                {{ $t(room.name) }}
            </div>
            <div class='b-room-list__item__text'>
                {{ $t(room.address) }}
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BranchAddressVideoRoomType } from '@/types/Appointment';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class RoomList extends Vue {
    @Prop({ type: Array, required: true }) readonly rooms!: Array<BranchAddressVideoRoomType>;
    @Prop({ type: String, required: true }) readonly currentRoomId!: string;
}
</script>

<style lang='sass'>
.b-room-list
    padding-right: 50px
    margin-left: 0 !important
    padding-left: 85px

    &__inner
        margin: 0
        display: flex
        cursor: pointer
        overflow: auto

    &__item__text
        width: 100%
        font-size: 11px
        text-align: center
        color: $dark-blue
        font-weight: 500
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__item__avatar
        width: 20px
        height: 20px
        border-radius: 10px
        margin-bottom: 6px
        opacity: 0.7
        display: flex
        align-items: center
        justify-content: center

    &__item
        width: 100px
        height: 60px
        flex: 0 0 100px
        border: 1px solid rgba(33,63,107,0.1)
        background-color: #fff
        border-radius: 10px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        margin: 0 5px
        padding: 5px
        transition: background-color 0.3s
        position: relative

        &--active
            background-color: lighten($main-green, 40)
            border-color: transparent !important

            &:after
                content: ''
                position: absolute
                left: 0
                right: 0
                width: 20px
                height: 2px
                background-color: $main-green
                bottom: 0
                margin: auto

            .b-room-list__item__text
                color: $main-green

        &--disabled
            background-color: lighten($main-gray, 17) !important
            border-color: transparent !important
            cursor: default !important

            &:after
                content: ''
                position: absolute
                left: 0
                right: 0
                width: 20px
                height: 2px
                background-color: $main-gray
                bottom: 0
                margin: auto

        &:not(.b-room-list__item--disabled):hover
            border-color: transparent
            background-color: lighten($main-green, 45)
</style>
