<template>
<div>
    <div class='h-flex h-flex-space-between h-flex-center h-mt-30'>
        <div class='h-flex h-flex-center'>
            <FwIcon
                icon='reccurance-icon'
                size='20'
                color='#BEC7D4'>
            </FwIcon>
            <span class='h-ml-10 h-fw-500 h-font-14'>
                {{ $t('UPDATE.TITLE.POPUP.SCHEDULED.UPDATE') }}
            </span>
        </div>
        <FwIcon
            v-if='canEdit'
            class='h-pointer'
            icon='bucket-edit'
            size='20'
            color='#BEC7D4'
            @click.native='confirmPopup = true'>
        </FwIcon>
    </div>
    <p v-if='isAddress' class='h-mt-5 h-font-12'>
        {{ $t('UPDATE.ADDRESS.DESCRIPTION') }} "{{ isDeparture ?
            updateData.new_data.departure_point :
            updateData.new_data.address }}" {{ $t('UPDATE.ADDRESS.ON') }} {{ updateDate }}
    </p>
    <p v-else class='h-mt-5 h-font-12'>
        {{ $t('UPDATE.WILL.BE.UPDATE') }} {{ updateDate }}
    </p>
    <DeletePopup
        v-if='confirmPopup'
        :headerText='$t(`UPDATE.TITLE.POPUP.CANCEL`)'
        :subMessage='$t(`UPDATE.TITLE.POPUP.CANCEL.QUESTION`)'
        :buttonText='`UPDATE.TITLE.POPUP.CANCEL.YES`'
        :cancelButtonText='`UPDATE.TITLE.POPUP.CANCEL.BACK`'
        @deleteItem='cancelUpdate'
        @close='confirmPopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { getPrettyStringDate } from '@/helpers/dates';
import PermissionsMixin from '@/mixins/permissions';

const WorkerPageStore = namespace('WorkerPageStore');

@Component({
    components: {
        DeletePopup,
    },
    refs: [`infoBox`],
})
export default class WorkingHoursUpdateInfo extends Mixins(PermissionsMixin) {
    @WorkerPageStore.Mutation('WORKER_UPDATE_DATA') setWorkerUpdateData!: Function;
    @WorkerPageStore.Mutation('ADDRESS_UPDATE_DATA') setAddressUpdateData!: Function;
    @WorkerPageStore.State('workerUpdateData') workerUpdateData!: any;
    @WorkerPageStore.State('addressUpdateData') addressUpdateData!: any;
    @Prop({ type: Boolean, default: false }) readonly isAddress!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isDeparture!: boolean;

    confirmPopup: boolean = false;
    get updateData() {
        return this.isAddress ? this.addressUpdateData : this.workerUpdateData;
    }
    get setUpdateData() {
        return this.isAddress ? this.setAddressUpdateData : this.setWorkerUpdateData;
    }
    get updateId() {
        return this.updateData.id;
    }
    get updateDate() {
        if (this.updateData) {
            return getPrettyStringDate(new Date(this.updateData.dt_execute_at), this.$i18n, true);
        }

        return null;
    }
    async cancelUpdate() {
        try {
            await WorkersWebApi.deleteTechnicianUpdateData(this.updateId);
            this.confirmPopup = false;
            if (this.isAddress) {
                this.setAddressUpdateData(null);
            } else {
                this.setWorkerUpdateData(null);
            }
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.CANCELED', true);
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }
}
</script>

<style lang='sass'>
.b-worker-time-card
    position: relative
    width: 100%
    display: flex
    padding: 5px 5px 5px 10px
    border-radius: 5px
    background-color: #fff
    overflow: hidden
    box-shadow: $base-box-shadow
    min-width: 100px

    &__inner
        display: flex
        justify-content: flex-start
        flex-flow: column nowrap
        width: 100%

    &__time
        padding: 3px 0
        width: 100%
        display: flex
        font-weight: 500
        font-size: 12px
        align-items: center
        justify-content: center

    &__overflow-text
        width: 140px
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__title
        font-size: 12px
        padding: 3px 0
        width: 100%
        font-weight: 400
        display: flex
        justify-content: flex-start
        align-items: flex-start
        line-height: 1.5em
</style>
