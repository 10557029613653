<template>
<div class='b-intervention-code_main h-flex-center h-flex-dir-column'
     :class='{ "b-intervention-code_main--popup": popupStyle }'>
    <div class='h-flex h-flex-dir-column h-flex-justify-start h-flex-center' style='overflow-y: auto'>
        <div class='h-flex'>
            <div class='b-calendar-select__week fw-select-base h-mt-15'>
                <FwDatepicker
                    v-model='dateFrom'
                    name='datepicker'
                    :placeholder='$t(`LABEL.CHOOSE_DAY`)'
                    :propsRange='false'
                    :propsLang='`${$i18n.locale}`'
                    :selectMode='false'
                    pickerStyle='static'
                    propsType='date'
                    propsFormat='MMM DD, YYYY'
                    :openOnTop='false'
                    :disabledDate='disabledDateByObject'
                    propsInputClass='b-add-reserved-slot-form__datepicker'>
                    <template #icon>
                        <FwIcon
                            class='h-flex'
                            icon='calendar'
                            size='16'
                            color='#203f6a'>
                        </FwIcon>
                    </template>
                </FwDatepicker>
            </div>
            <div style='min-width: 150px; position: relative;'
                 class='b-calendar-select__week
                                fw-select-base
                                fw-select-base__time
                                h-flex h-flex-center
                                h-flex-space-between'>
                <h6 class='h-mh-10 h-fw-400'>
                    {{ $t('SETTINGS.CALENDAR.FROM') }}
                </h6>
                <div style='min-width: 120px'>
                    <FwSelect
                        v-model='timeFrom'
                        :canNotDelete='true'
                        :propsPlaceholder='value'
                        :searchable='false'
                        :options='timeFromArray'>
                    </FwSelect>
                </div>
            </div>
            <div style='min-width: 150px; position: relative;'
                 class='b-calendar-select__week
                        fw-select-base
                        fw-select-base__time
                        h-flex h-flex-center
                        h-flex-space-between'>
                <h6 class='h-mh-10 h-fw-400'>
                    {{ $t('SETTINGS.CALENDAR.TO') }}
                </h6>
                <div style='min-width: 120px'>
                    <FwSelect
                        v-model='timeTo'
                        :canNotDelete='true'
                        :propsPlaceholder='value'
                        :searchable='false'
                        :options='timeToArray'>
                    </FwSelect>
                </div>
            </div>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-add-company__form__input h-flex-1'
                labelType='label-medium'
                :fieldHasError='fieldHasError(TITLE)'
                :title='`${$t("LABEL.TITLE")}*`'>
                <FwInput
                    class='h-width-100p'
                    :value='title'
                    placeholder='ex: Alarm installation'
                    :error='getFieldErrorText(TITLE)'
                    type='text'
                    inputStyle='white-bigger-shadow'
                    @update:value='value => title = value'
                    @input='clearServerErrorsBase(TITLE)'
                    @blur='addCurrentInputToValidateArray(TITLE)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-add-company__form__input'
                labelType='label-medium'
                :fieldHasError='fieldHasError(FIRST_NAME)'
                :title='`${$t("LABEL.FIRST.NAME")}*`'>
                <FwInput
                    class='h-width-100p'
                    :value='first_name'
                    placeholder='ex: John'
                    :error='getFieldErrorText(FIRST_NAME)'
                    type='text'
                    inputStyle='white-bigger-shadow'
                    @update:value='value => first_name = value'
                    @input='clearServerErrorsBase(FIRST_NAME)'
                    @blur='addCurrentInputToValidateArray(FIRST_NAME)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-add-company__form__input'
                labelType='label-medium'
                :fieldHasError='fieldHasError(LAST_NAME)'
                :title='`${$t("LABEL.LAST.NAME")}*`'>
                <FwInput
                    class='h-width-100p'
                    :value='last_name'
                    placeholder='ex: Doe'
                    :error='getFieldErrorText(LAST_NAME)'
                    type='text'
                    inputStyle='white-bigger-shadow'
                    @update:value='value => last_name = value'
                    @input='clearServerErrorsBase(LAST_NAME)'
                    @blur='addCurrentInputToValidateArray(LAST_NAME)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-add-company__form__input'
                labelType='label-medium'
                :title='`${$t("LABEL.PHONE.NUMBER")}`'>
                <FwInput
                    class='h-width-100p'
                    :value='phone'
                    placeholder='ex: 0612345678'
                    type='text'
                    inputStyle='white-bigger-shadow'
                    @update:value='value => phone = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-add-company__form__input'
                :fieldHasError='fieldHasError(CONTRACT_NUMBER)'
                labelType='label-medium'
                :title='`${$t(`LABEL.CONTRACT.NUMBER`)}*`'>
                <FwInput
                    :value='contract_number'
                    type='tel'
                    name='text'
                    placeholder='20200301568'
                    inputStyle='white-bigger-shadow'
                    :disabled='disabledForm'
                    :error='getFieldErrorText(CONTRACT_NUMBER)'
                    @update:value='value => contract_number = value'
                    @input='clearServerErrorsBase(CONTRACT_NUMBER)'
                    @blur='addCurrentInputToValidateArray(CONTRACT_NUMBER)'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-add-company__form__input'
                labelType='label-medium'
                :title='`${$t("LABEL.EMAIL")}`'>
                <FwInput
                    class='h-width-100p'
                    :value='email'
                    placeholder='ex: address@email.com'
                    type='text'
                    inputStyle='white-bigger-shadow'
                    @update:value='value => email = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-add-company__form__input'
                labelType='label-medium'
                :title='`${$t("LABEL.COMPANY")}`'>
                <FwInput
                    class='h-width-100p'
                    :value='company_name'
                    placeholder='ex: Calizy Inc.'
                    type='text'
                    inputStyle='white-bigger-shadow'
                    @update:value='value => company_name = value'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-width-100p h-mb-30'>
            <FwFormInput
                class='b-add-company__form__input h-flex-1-0'
                :title='`${$t(`LABEL.ADDRESS`)}*`'
                labelType='label-medium'>
                <SelectAddress
                    :startAddress='address'
                    :isDisabled='isAddressDisabled'
                    @changePostCode='value => post_code = value'
                    @input='input'>
                </SelectAddress>
            </FwFormInput>
        </div>
        <div class='b-add-company__form__row'>
            <FwFormInput
                class='b-add-company__form__input h-flex-1-0'
                :fieldHasError='fieldHasError(NOTES)'
                labelType='label-medium'
                :title='$t(`Notes`)'>
                <FwInput
                    componentType='textarea'
                    :value='notes'
                    type='text'
                    name='text'
                    :placeholder='$t(`APPT.NOTES.PLACEHOLDER`)'
                    inputStyle='textarea'
                    :error='getFieldErrorText(NOTES)'
                    @update:value='value => notes = value'
                    @input='clearServerErrorsBase(NOTES)'
                    @blur='addCurrentInputToValidateArray(NOTES)'>
                </FwInput>
            </FwFormInput>
        </div>
        <p class='b-header-required h-fw-500 h-mb-0 h-pl-15 h-fw-14 h-width-100p'>
            *{{ $t('SETTINGS.ROLE.REQUIRED.FIELD') }}
        </p>
    </div>
    <div v-if='!isTypeManually'>
        <FwButton
            class='h-mh-10'
            size='medium'
            :disabled='isRequestSending'
            borderRadiusType='tiny-border'
            @click='sendCodeHandler'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </div>
    <div v-else>
        <div class='h-flex h-flex-center'>
            <FwButton
                class='h-mt-20'
                borderRadiusType='small-border'
                :disabled='!title || !first_name || !last_name || !address || isRequestSending || !post_code'
                @click='sendDataToServer'>
                {{ $t('LABEL.BOOK') }}
            </FwButton>
        </div>
    </div>
    <div v-if='$route.meta.isManualTabShown' class='h-flex h-flex-center'>
        <FwButton
            class='h-mt-20'
            :disabled='isRequestSending'
            :style='{
                color: `rgba(32, 63, 106, 0.3)`,
                textTransform: `uppercase`
            }'
            color='transparent'
            fontSize='14px'
            @click='$emit(`close`)'>
            {{ $t('BUTTON.CANCEL') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Prop, Emit, Watch } from 'vue-property-decorator';
import { clone } from 'ramda';
import ValidationMixin from '@/mixins/validation';
import CalendarMixin from '@/mixins/calendar';
import { SelectAddress } from '@/components/common/SelectAddress';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { AppointmentDataType } from '@/types/Appointment';
import {
    FULL_TIME_EVERY_30,
    getHoursAndMinutesFromAMPM,
    dateToString,
} from '@/helpers/dates';
import { addPostCodeToAddress } from '@/helpers/address';
import {
    COMPANY_NAME,
    TITLE,
    FIRST_NAME,
    LAST_NAME,
    PHONE,
    CONTACT_EMAIL,
    NOTES,
    CONTRACT_NUMBER,
} from '@/helpers/events';
import i18n from '@/locale';
import { ParsedAddressData } from '@/types/GoogleMap';
import DateMixin from '@/mixins/dateMixin';
import { InstanceAppointmentsWithSlotsType } from '@/types/Events';

const IWS_TYPE: string = 'IWS';
const COHERIS_TYPE: string = 'COHERIS';
const MANUALLY: string = 'ADD_COMPANIES.COMMON.MANUAL.TITLE';
const INTERVENTION_TYPES: Array<string> = [IWS_TYPE, COHERIS_TYPE];

const CODE_NAME = 'code';

@Component({
    validators: {
        [CODE_NAME](value: string) {
            return this.baseInputValidator({ value, maxLength: 10 });
        },
        [TITLE](value: string) {
            return this.baseInputValidator({ value });
        },
        [FIRST_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [LAST_NAME](value: string) {
            return this.baseInputValidator({ value });
        },
        [CONTRACT_NUMBER](value: string) {
            return this.baseInputValidator({ value });
        },
    },
    components: {
        SelectAddress,
    },
})
export default class AddAppointmentForm extends Mixins(DateMixin, ValidationMixin, CalendarMixin) {
    @Prop({ type: Boolean, default: false }) readonly popupStyle!: string;
    @Prop({ type: Boolean, required: true }) readonly isRequestSending!: boolean;
    @Prop({ type: Boolean, required: true }) readonly isTechCalendar!: boolean;
    @Prop({ type: Object, required: true }) readonly serverErrors!: { [CODE_NAME]: string | null };
    @Prop({ type: Date, default: null }) readonly appointmentStartDate!: Date;

    activeType: string = MANUALLY;
    CODE_NAME = CODE_NAME;
    dateFrom: Date = this.appointmentStartDate;
    timeFrom: string = '';
    timeTo: string = '';
    loading: boolean = false;
    [TITLE]: string = '';
    [FIRST_NAME]: string = '';
    [LAST_NAME]: string = '';
    [PHONE]: string = '';
    [CONTACT_EMAIL]: string = '';
    [COMPANY_NAME]: string = '';
    [NOTES]: string = '';
    [CONTRACT_NUMBER]: string = '';
    TITLE: string = TITLE;
    FIRST_NAME: string = FIRST_NAME;
    LAST_NAME: string = LAST_NAME;
    PHONE: string = PHONE;
    CONTACT_EMAIL: string = CONTACT_EMAIL;
    COMPANY_NAME: string = COMPANY_NAME;
    CONTRACT_NUMBER: string = CONTRACT_NUMBER;
    address: string = '';
    NOTES: string = NOTES;
    isAddressDisabled: boolean = false;
    post_code: string = '';
    country: string = '';
    calizyAddress: string = '';
    get timeFromArray(): Array<string> {
        return FULL_TIME_EVERY_30.map(time => i18n.t(time));
    }
    get timeToArray() {
        const updatedArray = clone(this.timeFromArray);
        const index = updatedArray.findIndex(i => i === this.timeFrom);
        const result = updatedArray.splice(index + 2, updatedArray.length - 1);
        this.timeTo = result[0];
        return result;
    }

    [CODE_NAME]: string | number = '';

    input(parsedAddressData: ParsedAddressData) {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
        this.country = parsedAddressData.country;
        this.calizyAddress = parsedAddressData.calizyAddress;
    }

    checkCode(code: string) {
        const result = code.replace(/\D/g, '');
        this[CODE_NAME] = result;
        return result;
    }

    prepareAppointmentAsEvent(appointment: AppointmentDataType): InstanceAppointmentsWithSlotsType {
        return {
            id: `front_id_${appointment.id}`,
            appointment_id: appointment.id,
            dt_start: appointment.dt_start,
            dt_end: appointment.dt_end,
            title: appointment.title,
            full_day: false,
            origin: 'from_appointment',
        };
    }

    async sendDataToServer() {
        this.loading = true;
        try {
            const appointment: AppointmentDataType = await AppointmentWebApi.bookAppointmentManually(
                {
                    user_id: this.$route.params.id,
                    dt_start: dateToString(this.startDate),
                    dt_end: dateToString(this.endDate),
                    title: this.title,
                    first_name: this.first_name,
                    last_name: this.last_name,
                    contract_number: this.contract_number,
                    phone: this.phone,
                    email: this.email,
                    company_name: this.company_name,
                    location: addPostCodeToAddress(this.calizyAddress, this.post_code),
                    notes: this.notes,
                });
            this.loading = false;
            this.sentNotif('APPOINTMENT.SUCCESS.POPUP.TXT', true);
            this.$store.commit('CACHE_APPOINTMENT', appointment);
            this.$store.commit('CALENDAR_RESERVED_SLOTS', [{ date: new Date(appointment.dt_start), id: +appointment.dt_start }]);
            this.$store.commit('CALENDAR_COMMIT_EVENTS_CONCAT', [this.prepareAppointmentAsEvent(appointment)]);
            this.$emit('close');
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }
    get disabledDateByObject() {
        return {
            disablePrevDays: this.currentDate ? this.currentDate.setDate(this.currentDate.getDate() + 1) : new Date(),
        };
    }
    get isTypeManually() {
        return this.activeType === MANUALLY;
    }
    get interventionTypesArray() {
        return this.$route.meta && this.$route.meta.isManualTabShown ? [...INTERVENTION_TYPES, MANUALLY] : INTERVENTION_TYPES;
    }
    get startDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeFrom as string));
        return new Date(this.dateFrom.setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    get endDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeTo as string));
        return new Date(this.dateFrom.setHours(timeData.hours, timeData.minutes, 0, 0));
    }

    @Emit('sendCode')
    sendCodeHandler() {
        this.addCurrentInputToValidateArray(this.CODE_NAME);
        return {
            code: `${this[CODE_NAME]}`,
            app_type: this.activeType,
        };
    }
    @Watch('$route')
    routeChange() {
        this.clearServerErrorsBase(this.CODE_NAME);
    }
    created() {
        if (this.isWorkersCalendar) {
            this.timeFrom = this.formatAMPM(this.dateFrom, false);
        }
        if (this.$route.query) {
            if (this.$route.query.app) {
                // @ts-ignore-next-line
                const activeTypeUpperCase = this.$route.query.app.toUpperCase();
                if (activeTypeUpperCase === IWS_TYPE || activeTypeUpperCase === COHERIS_TYPE) {
                    this.activeType = activeTypeUpperCase;
                }
            }
            if (this.$route.query.reference) {
                // @ts-ignore-next-line
                this.code = this.$route.query.reference;
            }
        }
    }
}
</script>

<style lang='sass'>
.b-intervention-code_main
    max-height: 75vh
    .fw-input__inner
        width: 100%
    .b-intervention-code__button
        text-transform: uppercase
    .mx-datepicker
        padding: 0
        .mx-input-wrapper input
            color: $dark-blue
    .mx-input-wrapper, .fw-select-base .multiselect__tags, .fw-select-address .multiselect__tags, .fw-select-tooltip .multiselect__tags
        background: #eff0f3
        border-radius: 10px
        font-size: 14px
    .mx-datepicker--static .mx-calendar, .mx-datepicker--static .mx-datepicker-popup
        background: #eff0f3!important
        right: auto!important
        padding: 10px
    .fw-select-base .multiselect__element, .fw-select-address .multiselect__element, .fw-select-tooltip .multiselect__element
        font-size: 14px
    .fw-select-base .multiselect__content-wrapper, .fw-select-address .multiselect__content-wrapper, .fw-select-tooltip .multiselect__content-wrapper
        max-height: 240px!important
    .fw-input--white-bigger-shadow, .fw-input--border-bigger-shadow, .fw-select-white .multiselect__tags
        min-height: 50px!important
        font-weight: 400
    .fw-input--white-bigger-shadow::placeholder, .fw-input--border-bigger-shadow::placeholder
        font-weight: 400!important

    .fw-select-white .multiselect__tags
        padding-left: 20px
        box-shadow: $big-box-shadow

</style>
