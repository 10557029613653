import { PartnersWrapper } from '@/views/appointment/partners/PartnersWrapper';
import { BusinessPartnerAdd } from '@/views/appointment/partners/BusinessPartnerAdd';
import { AppointmentAddWorkers } from '@/views/appointment/AppointmentAddWorkers';
import { AppointmentAddPartner } from '@/views/appointment/partners/AppointmentAddPartner';
import { AppointmentAddWorker } from '@/views/appointment/AppointmentAddWorker';
import { AppointmentChooseCategoryPage } from '@/views/appointment/AppointmentChooseCategoryPage';
import i18n from '@/locale';
import { AppointmentAdd } from '@/views/appointment/AppointmentAdd';
import { AppointmentCategories } from '@/views/appointment/AppointmentCategories';
import { AppointmentPartnerCategories } from '@/views/appointment/partners/AppointmentPartnerCategories';
import { AppointmentWorkersList } from '@/views/appointment/AppointmentWorkersList';
import { RecapChoosePage } from '@/views/recap/RecapChoosePage';
import { FinalStepsConfirmInfo } from '@/views/final/FinalStepsConfirmInfo';
import { FinalStepsWorkingHours } from '@/views/final/FinalStepsWorkingHours';
import { OnboardingWorkingHoursChoosePage } from '@/views/onboarding/OnboardingWorkingHoursChoosePage';
import { OnboardingWorkingHoursPage } from '@/views/onboarding/OnboardingWorkingHoursPage';
import { OnboardingWorkersListWorkingHours } from '@/views/onboarding/OnboardingWorkersListWorkingHours';
import { OnboardingCalendarSyncPage } from '@/views/onboarding/OnboardingCalendarSyncPage';
import { OnboardingWorkersListCalendarSync } from '@/views/onboarding/OnboardingWorkersListCalendarSync';

import { RouteType } from '@/types/router';
import { ONBOARDING, PARTNER } from '@/helpers/rights';

const partnersRoutes: Array<RouteType> = [
    {
        path: `/partners-onboarding`,
        component: PartnersWrapper,
        name: `PartnersWrapper`,
        children: [
            {
                path: '/partners-onboarding/categories/choose-category',
                name: AppointmentChooseCategoryPage.name,
                component: AppointmentChooseCategoryPage,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    // breadCrumbsText: `ONBOARDING.OPTION.1`),
                    breadCrumbsText: `BREADCRUMBS.APPOINTMENTS.TYPES`,
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.CATEGORY.TITLE'),
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/categories/add-category',
                component: AppointmentAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.APPOINTMENTS.TYPES`,
                    breadCrumbsFlowKey: `partners`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/categories/add-category/:id',
                component: AppointmentAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.APPOINTMENTS.TYPES`,
                    breadCrumbsFlowKey: `partners`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/appointment/categories',
                component: AppointmentCategories,
                meta: {
                    showAppHeader: true,
                    breadCrumbsText: `BREADCRUMBS.APPOINTMENTS.TYPES`,
                    breadCrumbsFlowKey: `partners`,
                    next: '/partners-onboarding/partners/add',
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.TYPES.HEADER'),
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            // {
            //     path: '/partners-onboarding/partners/choose',
            //     name: AppointmentAddPartner.name,
            //     component: AppointmentAddPartner,
            //     meta: {
            //         showAppHeader: true,
            //         backButton: true,
            //         breadCrumbsText: `BREADCRUMBS.BUSINESS.PARTNER.TITLE`),
            //         pageTitle: i18n.tc('ONBOARDING.PARTNERS.CHOOSE.CATEGORIES.HEADER'),
            //     },
            // },
            {
                path: '/partners-onboarding/partners/add',
                component: BusinessPartnerAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.BUSINESS.PARTNER.TITLE`,
                    breadCrumbsFlowKey: `partners`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/partners/edit/:id',
                component: BusinessPartnerAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    breadCrumbsText: `BREADCRUMBS.BUSINESS.PARTNER.TITLE`,
                    breadCrumbsFlowKey: `partners`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/partners',
                component: AppointmentPartnerCategories,
                meta: {
                    showAppHeader: true,
                    next: '/partners-onboarding/technicians/add',
                    breadCrumbsText: `BREADCRUMBS.BUSINESS.PARTNER.TITLE`,
                    breadCrumbsFlowKey: `partners`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                    // breadCrumbsText: `PLAN.APPOINTMENT.BUSINESS.PARTNER.TITLE`),
                },
            },
            // {
            //     path: '/partners-onboarding/technicians/choose',
            //     name: AppointmentAddWorkers.name,
            //     component: AppointmentAddWorkers,
            //     meta: {
            //         showAppHeader: true,
            //         backButton: true,
            //         // breadCrumbsText: `WORKERS.FORM.TITLE`),
            //         nextUrl: '/appointment/add-worker',
            //         breadCrumbsText: `BREADCRUMBS.ADVISORS`),
            //     },
            // },
            {
                path: '/partners-onboarding/technicians/add-more',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    next: '/partners-onboarding/technicians',
                    breadCrumbsText: `BREADCRUMBS.TECH`,
                    breadCrumbsFlowKey: `partners`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/technicians/add',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    next: '/partners-onboarding/technicians',
                    breadCrumbsText: `BREADCRUMBS.TECH`,
                    breadCrumbsFlowKey: `partners`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/appointment/edit-technician/:id',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    next: '/partners-onboarding/technicians',
                    breadCrumbsFlowKey: `partners`,
                    breadCrumbsText: `BREADCRUMBS.TECH`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/technicians',
                component: AppointmentWorkersList,
                meta: {
                    showAppHeader: true,
                    next: '/partners-onboarding/recap',
                    breadCrumbsFlowKey: `partners`,
                    breadCrumbsText: `BREADCRUMBS.TECH`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/recap',
                component: RecapChoosePage,
                meta: {
                    showAppHeader: true,
                    next: '/partners-onboarding/final-steps',
                    backButton: true,
                    breadCrumbsFlowKey: 'partners',
                    breadCrumbsText: `BREADCRUMBS.RECAP`,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/final-steps',
                component: FinalStepsConfirmInfo,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/working-hours/choose',
                component: OnboardingWorkingHoursChoosePage,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/final-steps/working-hours',
                component: FinalStepsWorkingHours,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/working-hours/all',
                component: OnboardingWorkingHoursPage,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/working-hours/list',
                component: OnboardingWorkersListWorkingHours,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/invitations/choose',
                component: OnboardingCalendarSyncPage,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/invitations/choose-technicians',
                component: OnboardingWorkersListCalendarSync,
                meta: {
                    backButton: false,
                    showAppHeader: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/recap/partners',
                component: AppointmentPartnerCategories,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    hide: true,
                    next: '/partners-onboarding/recap',
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                    // breadCrumbsText: `PLAN.APPOINTMENT.BUSINESS.PARTNER.TITLE`),
                },
            },
            {
                path: '/partners-onboarding/recap/partners/edit/:id',
                component: BusinessPartnerAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    kind: 'recap',
                    hide: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/recap/technicians',
                component: AppointmentWorkersList,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    hide: true,
                    next: '/partners-onboarding/recap',
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/recap/appointment/edit-technician/:id',
                component: AppointmentAddWorker,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    kind: 'recap',
                    hide: true,
                    next: '/partners-onboarding/recap/technicians',
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/recap/appointment/categories',
                component: AppointmentCategories,
                meta: {
                    showAppHeader: true,
                    kind: 'recap',
                    hide: true,
                    next: '/recap',
                    pageTitle: i18n.tc('ONBOARDING.CHOOSE.CATEGORIES.HEADER'),
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
            {
                path: '/partners-onboarding/recap/categories/add-category/:id',
                component: AppointmentAdd,
                meta: {
                    showAppHeader: true,
                    backButton: true,
                    kind: 'recap',
                    hide: true,
                    pageType: ONBOARDING,
                    pageKind: PARTNER,
                },
            },
        ],
    },
];

export default partnersRoutes;
