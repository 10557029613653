import { GeograficTypes } from '@/types/Appointment';

function sortGeograficPlaces(cities: Array<GeograficTypes>): Array<GeograficTypes> {
    return cities.filter(item => !!item).sort((a, b) => {
        const postCodeA = parseInt(a.post_code, 10);
        const postCodeB = parseInt(b.post_code, 10);
        if (postCodeA !== postCodeB) {
            return parseInt(a.post_code, 10) - parseInt(b.post_code, 10);
        }
        return a.name.localeCompare(b.name);
    });
}

export {
    sortGeograficPlaces,
};
