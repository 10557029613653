import { Route } from 'vue-router';
import { CONSULT_PREFIX } from '@/routes/config';
import { CONSULT_CALENDAR_MAPPING, CONSULT_MONTH_VIEW_CALENDARS } from '@/helpers/calendar';
import { CalendarPage } from '@/views/consult/common/CalendarPage';
import { MonthCalendarPage } from '@/views/consult/common/MonthCalendarPage';
import { RIGHT_READ } from '@/helpers/rights';
import authRoutes from './auth';
import planAppointmentRoutes from './planAppointment';
import settingsRoutes from './settings';
import profileRoutes from './profile';
import partnersRoutes from './partners';
import companyRoutes from './company';
import OnboardingRoutes from './onbording';
import ForbiddenRoutes from './forbidden';
import E2eRoutes from './e2e';
import { insertIf } from '@/helpers/global';
import { CONSULT_CALENDAR_PAGE_NAME, MONTH_CALENDAR_PAGE_NAME } from '@/helpers/consts';

export default [
    ...authRoutes,
    ...planAppointmentRoutes,
    ...profileRoutes,
    ...partnersRoutes,
    ...companyRoutes,
    ...OnboardingRoutes,
    ...settingsRoutes,
    ...ForbiddenRoutes,
    {
        path: `${CONSULT_PREFIX}/calendar/:type(${Object.keys(CONSULT_CALENDAR_MAPPING).join(`|`)})/:id/:param?`,
        component: CalendarPage,
        name: CONSULT_CALENDAR_PAGE_NAME,
        meta: {
            showAppHeader: false,
            showAppHeaderProgress: false,
            getName: (route: Route) => CONSULT_CALENDAR_MAPPING[route.params.type],
            rightBlock: 'calendar_profile_rights',
            right: RIGHT_READ,
        },
    },
    {
        path: `${CONSULT_PREFIX}/calendar-month/:type(${CONSULT_MONTH_VIEW_CALENDARS.join(`|`)})/:id`,
        name: MONTH_CALENDAR_PAGE_NAME,
        component: MonthCalendarPage,
        meta: {
            showAppHeaderProgress: false,
            getName: (route: Route) => CONSULT_CALENDAR_MAPPING[route.params.type],
            rightBlock: 'calendar_profile_rights',
            right: RIGHT_READ,
            hideHeader: true,
            authRequired: true,
            showAppHeader: false,
        },
    },
    ...insertIf(process.env.NODE_ENV === `development`, ...E2eRoutes),
];
