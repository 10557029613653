<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <FwImage class='h-ph-50'
                         :height='`166px`'
                         :src='requireImage("progress.svg")'>
                </FwImage>
                <h2 class='h-font-22 h-mt-20 h-mb-0'>
                    {{ title }}
                </h2>
                <p v-if='subMessage'
                   class='h-mt-10 h-mb-20'>
                    {{ subMessage }}
                </p>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class InProgressPopup extends Vue {
    @Prop({ type: String, default: 'Success!' }) readonly title!: string;
    @Prop({ type: String, default: null }) readonly subMessage!: string;
}
</script>
