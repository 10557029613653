import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { AccountTitlesType } from '@/types/Account';

@Component
export default class PermissionsMixin extends Vue {
    @State(state => state.CommonStore.accountData) accountData!: AccountTitlesType;

    get canEdit(): boolean {
        if (!this.$route.meta || !this.$route.meta.rightBlock) {
            return true;
        }
        return !!(this.currentUserRole && this.currentUserRole[this.$route.meta.rightBlock] && this.currentUserRole[this.$route.meta.rightBlock].update);
    }

    get canDelete(): boolean {
        if (!this.$route.meta || !this.$route.meta.rightBlock) {
            return true;
        }
        return !!(this.currentUserRole && this.currentUserRole[this.$route.meta.rightBlock] && this.currentUserRole[this.$route.meta.rightBlock].destroy);
    }

    get canRead(): boolean {
        if (!this.$route.meta || !this.$route.meta.rightBlock) {
            return true;
        }
        return !!(this.currentUserRole && this.currentUserRole[this.$route.meta.rightBlock] && this.currentUserRole[this.$route.meta.rightBlock].read);
    }

    get canCreate(): boolean {
        if (!this.$route.meta || !this.$route.meta.rightBlock) {
            return true;
        }
        return !!(this.currentUserRole && this.currentUserRole[this.$route.meta.rightBlock] && this.currentUserRole[this.$route.meta.rightBlock].create);
    }

    get canShowList(): boolean {
        if (!this.$route.meta || !this.$route.meta.rightBlock) {
            return true;
        }
        return !!(this.currentUserRole && this.currentUserRole[this.$route.meta.rightBlock] && this.currentUserRole[this.$route.meta.rightBlock].list);
    }

    get accessLockMonthPage() {
        if (!this.accountData) {
            return false;
        }

        return this.accountData.account.licence === 'advanced' || this.accountData.account.licence === 'essential';
    }

    get accessLockStatisticPage() {
        if (!this.accountData) {
            return false;
        }

        return this.accountData.account.licence === 'essential';
    }

    get emailCount() {
        if (!this.accountData) {
            return false;
        }

        return this.accountData.account.current_month_email_count;
    }

    get smsCount() {
        if (!this.accountData) {
            return false;
        }

        return this.accountData.account.current_month_sms_count;
    }

    get clientApiSupport() {
        if (!this.accountData) {
            return false;
        }

        return this.accountData.account.client_api_support;
    }

    get geoScopeNational() {
        if (!this.accountData) {
            return false;
        }

        return this.accountData.account.geo_scope === 'national';
    }

    get geoCountryIsoCode() {
        if (!this.accountData) {
            return false;
        }

        return this.accountData.account.country_iso_code;
    }

    get isBranchesFlow() {
        return this.user().account_organization_type === `with_all` || this.user().account_organization_type === `with_branches`;
    }
};
