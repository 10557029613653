import DynamicFieldsE2e from '@/views/e2e/DynamicFieldsE2e.vue';
import { RouteType } from '@/types/router';

const E2eRoutes: Array<RouteType> = [
    {
        path: `/test/dynamic-fields/:id`,
        name: DynamicFieldsE2e.name,
        component: DynamicFieldsE2e,
        meta: {
            showAppHeader: false,
        },
    },
];

export default E2eRoutes;
