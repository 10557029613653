<template>
<div class='h-width-100p b-form-tabs-main b-form-tabs__appointment'>
    <FormTabsHeader :appointmentData='appointmentData' :data='data'></FormTabsHeader>
    <div class='b-form-tabs b-form-tabs--hm50'>
        <div
            v-for='(tab, index) in currentTabs'
            ref='activeTabRef'
            :key='tab.name'
            :class='{ "b-form-tabs__active-tab": tab.isActive }'
            class='b-form-tabs__tab h-fw-500 h-text-center'
            @click='selectTab(tab, index)'>
            {{ tab.name }}
        </div>
        <template v-if='isDesktop()'>
            <div class='h-pos-abs b-form-tabs__line-active' :style='{ left: tabLeft + "px", width: tabWidth + "px" }'>
                <div class='b-form-tabs__line-active-box'></div>
            </div>
            <div class='b-form-tabs__line'></div>
        </template>
    </div>
    <transition name='component-fade' mode='out-in'>
        <component
            :is='currentComponent'
            :isPartnerFlow='isPartnerFlow'
            :appointmentData='appointmentData'>
        </component>
    </transition>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { InternalAppointmentRecapForm } from '@/components/forms/readable/internal/default/InternalAppointmentRecapForm';
import { PartnerAppointmentClientForm } from '@/components/forms/readable/partner/PartnerAppointmentClientForm';
import { PartnerAppointmentRecallForm } from '@/components/forms/readable/partner/PartnerAppointmentRecallForm';
import { InternalAppointmentClientForm } from '@/components/forms/readable/internal/default/InternalAppointmentClientForm';
import { InternalAppointmentCommentsForm } from '@/components/forms/readable/internal/default/InternalAppointmentCommentsForm';
import { InternalAppointmentForm } from '@/components/forms/readable/internal/default/InternalAppointmentForm';
import { AppointmentSearchType, AppointmentTabType } from '@/types/Appointment';
import i18n from '@/locale';
import { FormTabsHeader } from '@/components/forms/tabs/nodes/FormTabsHeader';
import { ConsultEventType } from '@/types/Availabilities';

@Component({
    components: {
        PartnerAppointmentRecallForm,
        PartnerAppointmentClientForm,
        InternalAppointmentRecapForm,
        InternalAppointmentForm,
        InternalAppointmentClientForm,
        InternalAppointmentCommentsForm,
        FormTabsHeader,
    },
})
export default class FormTabs extends Vue {
    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: Object, required: true }) readonly data!: ConsultEventType;

    currentComponent: string = 'InternalAppointmentRecapForm';
    tabsInternal: Array<AppointmentTabType> = [
        {
            name: i18n.tc('RECAP.TITLE'),
            component: 'InternalAppointmentRecapForm',
            isActive: true,
        }, {
            name: i18n.tc('PAST.APPOINTMENT.DETAILS.TITLE'),
            component: 'InternalAppointmentForm',
            isActive: false,
        }, {
            name: i18n.tc('CONSULT.APPOINTMENT.CLIENT.DETAILS'),
            component: 'InternalAppointmentClientForm',
            isActive: false,
        }, {
            name: i18n.tc('CONSULT.APPOINTMENT.COMMENTS'),
            component: 'InternalAppointmentCommentsForm',
            isActive: false,
        },
    ];

    tabsPartners: Array<AppointmentTabType> = [
        {
            name: 'Recap',
            component: 'PartnerAppointmentRecallForm',
            isActive: true,
        }, {
            name: 'Appointment details',
            component: 'InternalAppointmentForm',
            isActive: false,
        }, {
            name: 'Client details',
            component: 'PartnerAppointmentClientForm',
            isActive: false,
        }, {
            name: 'Comments',
            component: 'PartnerAppointmentCommentsForm',
            isActive: false,
        },
    ];

    tabWidth: number = 100;
    tabLeft: number = 100;
    activeTabIndex: number = 0;

    $refs!: {
        activeTabRef: Array<HTMLElement>
    };

    get isPartnerFlow(): boolean {
        return this.user().account_kind === 'partner';
    }

    get currentTabs(): Array<AppointmentTabType> {
        return this.isPartnerFlow ? this.tabsPartners : this.tabsInternal;
    }

    selectTab(selectedTab: AppointmentTabType, index: number): void {
        this.activeTabIndex = index;
        this.updateTabWidth();
        this.currentTabs.forEach(tab => {
            if (tab.name === selectedTab.name) {
                tab.isActive = true;
                this.currentComponent = tab.component;
            } else {
                tab.isActive = false;
            }
        });
        this.$emit('updateCurrentTab', selectedTab);
    }

    created(): void {
        this.selectTab(this.currentTabs[0], 0);
    }

    mounted(): void {
        this.updateTabWidth();
    }

    updateTabWidth(): void {
        if (this.$refs && this.$refs.activeTabRef) {
            this.tabWidth = this.$refs.activeTabRef[this.activeTabIndex].offsetWidth;
            this.tabLeft = this.$refs.activeTabRef[this.activeTabIndex].offsetLeft;
        }
    }
}
</script>

<style lang='sass'>
.b-form-tabs
    display: flex
    justify-content: center
    align-items: center
    color: $dark-blue
    position: relative

    &--hm50
        margin-bottom: 50px
        @include media('<=phone')
            margin-bottom: 10px

    &__appointment
        @include media('<=phone')
            .b-add-branch__form
                margin: 0

            .b-form-base-line
                padding-bottom: 0

    @include media('<=phone')
        width: 100%
        justify-content: flex-start !important
        overflow: auto
        .b-form-tabs__tab
            margin: 0 15px
            flex: auto
            white-space: nowrap

    &__line
        position: absolute
        bottom: -14px
        width: 100%
        height: 1px
        background: $dark-blue

        @include media('<=phone')
            display: none !important

        &-active
            display: flex
            justify-content: center
            position: absolute
            top: 30px
            transition: left 0.5s

            &-box
                height: 2px
                width: 50px
                background:  $aqua-marine-light

    &__active-tab
        position: relative
        display: flex
        justify-content: center
        align-items: center
        flex-flow: column nowrap
        color: $aqua-marine-light

    &__tab
        cursor: pointer
        margin: 0 30px

        @include media('<=phone')
            margin: 0
            padding: 0 10px
            flex: 0 0 50%

.b-form-tabs-main
    .component-fade-enter-active, .component-fade-leave-active
        transition: opacity 0.2s

    .component-fade-enter, .component-fade-leave-to
        opacity: 0

.b-form-tabs__appointment
    @include media('>phone')
        .b-form-base-line
            padding-bottom: 0px
</style>
