<template>
<component
    :is='canRead ? `router-link` : `div`'
    v-bind='getAttrs(servicePoint.id)'
    class='b-service-point__wrapper'>
    <FwSimpleCard
        width='100%'
        height='auto'
        padding='10px 30px'
        :isActive='canRead'
        :isDisabled='!canRead'>
        <div class='h-flex h-flex-dir-column h-width-100p'>
            <div class='b-service-point__title h-font-14 qa-service-point-title'>
                {{ servicePoint.name | capitalizeAll }}
            </div>
            <div class='b-service-point__workers-wrapper'>
                <div v-if='servicePoint.advisors_count' class='b-service-point__workers-title h-font-12'>
                    <span>
                        {{ servicePoint.advisor_count }}
                    </span>
                    <span v-if='servicePoint.advisors_count !== 0' class='h-ml-5'>
                        {{ $tc('CHOOSE_MANAGE.COMMON.ADVISOR.PLURAL', servicePoint.advisors_count) }}
                    </span>
                    <span v-else class='h-ml-5'>
                        {{ $tc('CHOOSE_MANAGE.COMMON.NO.ADVISOR.PLURAL', servicePoint.advisors_count.length) }}
                    </span>
                </div>
            </div>
        </div>
        <div v-if='mockedUsersList && mockedUsersList.length'
             class='h-flex'>
            <div v-for='worker in mockedUsersList'
                 class='b-service-point__worker-inner'>
                <WorkerAvatar
                    :worker='worker'
                    class='b-service-point__worker'
                    :avatarSize='30'
                    :hideInfo='true'
                    align='right'>
                </WorkerAvatar>
            </div>
        </div>
    </FwSimpleCard>
</component>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BranchHubAvatarsResponseType } from '@/types/Appointment';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import PermissionsMixin from '@/mixins/permissions';

const MAX_WORKERS_QUANTITY = 5;

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class ServicePointCard extends Mixins(PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly servicePoint!: any;

    getAttrs(id: string): null | { to: string } {
        return this.canRead ? { to: `/consult/service-point/${this.servicePoint.id}` } : null;
    }

    get mockedUsersList(): any {
        const mockedColors: Array<string> = [
            '#00A193 - #049588',
            '#7A43C6 - #713DB8',
            '#414B51 - #323B40',
            '#FFF020 - #F0E220',
            '#59BD5D - #4BAB4E',
            '#FFBE00 - #FFC500',
            '#F22383 - #E7217D',
            '#414B51 - #323B40',
            '#718F9D - #69838F',
            '#AA22BC - #991DA9',
            '#FFF020 - #F0E220',
        ];
        return mockedColors.sort(() => Math.random() - 0.5).map(color => ({
            avatar_url: null,
            color,
            id: `id-${color}`,
        })).slice(0, this.servicePoint.advisor_count > MAX_WORKERS_QUANTITY ? MAX_WORKERS_QUANTITY : this.servicePoint.advisor_count || 0);
    }
}
</script>

<style lang='sass'>
$worker-size: 32px
$card-min-height: 70px

.b-service-point
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    &__wrapper
        display: flex
        max-width: 395px
        flex: 0 0 50%
        min-height: $card-min-height
        align-items: center

        @include media('<=phone')
            width: 100%

        .fw-simple-card-wrapper
            min-height: $card-min-height

    &__title
        font-weight: 600
        color: $alt-blue

    &__workers-wrapper
        display: flex
        align-items: center
        margin-top: 3px

    &__worker
        width: $worker-size
        height: $worker-size
        border-radius: 50%
        border: 1px solid #FFFFFF
        position: relative
        background-size: cover
        background-position: center
        background-color: lighten($main-green, 30)

        .b-worker__avatar__icon
            margin-top: 4px

    &__worker-inner
        width: calc($worker-size / 1.4)

    &__workers-title
        display: flex
        align-items: center
        font-weight: 300
        color: $main-gray
        text-transform: uppercase

@include media('<tablet')
    .b-service-point
        width: 100%

        &__wrapper
            margin: 5px 0
</style>
