export default {
    'POPUPS.SETTINGS.TITLE': 'Paramètres d\'affichage',
    'POPUP.COMING.SOON.TITLE': 'Bientôt disponible',
    'POPUP.COMING.SOON.ASIDE': 'Si vous souhaitez que l\'import soit effectué par nos équipes, veuillez nous contacter sur l\'adresse',
    'POPUP.COMING.SOON.BUTTON.TEXT': 'Fermer',

    'POPUPS.SETTINGS.STATISTICS': 'STATISTIQUES',
    'POPUPS.SETTINGS.ACCESSIBILITY_MODE': 'MODE ACCESSIBILITÉ',

    'POPUPS.HOW_TO_USE.TITLE': 'Guide d’utilisation',
    'POPUPS.HOW_TO_USE.SEE_THE_ACTIVITY': 'Consulter de façon globale toute l’activité des conseillers',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_APPOINTMENTS': 'Mission uniquement',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_RESERVED_SLOTS': 'la journée ne contient que des créneaux réservés',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_SAAS': 'le jour possède uniquement des rendez-vous Calizy SaaS',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_APPT': 'la journée ne contient que des rendez-vous',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_SC': 'le jour possède uniquement des rendez-vous Calizy Selfcare',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_UNAVAILABILITIES': 'la journée ne contient que des indisponibilités',
    'POPUPS.HOW_TO_USE.THE_DAY_CONTAINS_DIFFERENT_ELEMENTS': 'la journée contient plusieurs éléments',
    'POPUPS.HOW_TO_USE.NOTHING_IS_HAPPENING': 'Disponibilité uniquement',
    'POPUPS.HOW_TO_USE.CLICKING_ON_SQUARE': 'Cliquer sur un carré affichera une répartition plus détaillée de la journée',
    'POPUPS.HOW_TO_USE.NO_DATA_FOR_THIS_DAY': 'Noting is happening in the day',

    'POPUPS.BOOK.TITLE': 'Planifier une mission',
    'POPUPS.BOOK.APPOINTMENT_TYPE': 'Type de mission',
    'POPUPS.BOOK.MEANS_OF_TRANSPORT': 'Moyen de transport',
    'POPUPS.BOOK.DESTINATION': 'Destination',
    'POPUPS.BOOK.TEAM': 'Équipe',
    'POPUPS.BOOK.BOOK': 'Planifier',
};
