import axios from 'axios';
import { BranchRoomResponseType } from '@/types/Appointment';
import {
    BranchKpiAppointmentCategoriesResponseType,
    BranchKpiAppointmentMethodsResponseType,
    BranchKpiAppointmentOriginResponseType,
    BranchKpiAppointmentTimeSlotsResponseType,
    BranchKpiAppointmentTimeBetweenResponseType,
    BranchKpiAppointmentCancelledOriginResponseType,
    BranchKpiAppointmentCancelledTimeResponseType,
    BranchKpiAppointmentTakenTypesResponseType,
    BranchKpiAppointmentRecipientsTakenTypesResponseType,
    BaseBranchKpiResponseType,
    BranchKpiParamsType,
    BranchKpiResponseType,
} from '@/types/Branch';
import { updateUrlGetParams } from '@/helpers/url';
import WebApi from '../WebApi';

class BranchApi extends WebApi {
    async createRoom(site: BranchRoomResponseType) {
        const { data } = await axios.post(
            `${this.urlPrefix}/sites`,
            { site }
        );
        return data;
    }
    async updateRoom(site: BranchRoomResponseType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/sites/${id}`,
            { site }
        );
        return data;
    }

    async getBranchKpi(branch_id: string): Promise<BaseBranchKpiResponseType> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/components/kpis`, { branch_id }));
        return data;
    }

    async getBranchKpiData(params: BranchKpiParamsType): Promise<BranchKpiResponseType> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/kpis_branch_aggregators`, params));
        return data;
    }

    async getBranchKpiAppointmentAdvisorByCategory(
        category_id: string, branch_id: string, from: string, to: string
    ): Promise<{ [key: string]: number }> {
        const { data } = await axios.get(updateUrlGetParams(`${this.urlPrefix}/kpis_aggregators/appointment_advisors_by_category`, { category_id, branch_id, from, to }));
        return data.advisors_counts;
    }
}

const branchApi = new BranchApi();

export {
    branchApi as BranchApi,
};
