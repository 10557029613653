<template>
<div class='b-add-worker__wrapper container'>
    <AddEditGeograficPopup
        v-if='isGeograficPopup'
        @closePopup='closePopup'
        @close='isGeograficPopup = false'>
    </AddEditGeograficPopup>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <div class='b-base-title__wrapper'>
            <h2 class='b-appointment-left-bar__title'>
                {{ $route.params.id ? $t('WORKERS.FORM.TITLE.EDIT') : $t('WORKERS.FORM.TITLE') }}
            </h2>
        </div>
        <form @keydown.stop.prevent.enter='submit'>
            <div class='h-flex h-flex-space-between h-pb-30'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    :fieldHasError='fieldHasError(FIRST_NAME)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.FIRST.NAME.LABEL`)}*`'>
                    <FwInput
                        :value='first_name'
                        type='text'
                        name='text'
                        :placeholder='$t(`WORKER.FORM.FIRST.NAME.PLACEHOLDER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(FIRST_NAME)'
                        @update:value='value => first_name = value'
                        @input='clearServerErrorsBase(FIRST_NAME)'
                        @blur='addCurrentInputToValidateArray(FIRST_NAME)'>
                    </FwInput>
                </FwFormInput>

                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    :fieldHasError='fieldHasError(LAST_NAME)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.LAST.NAME.LABEL`)}*`'>
                    <FwInput
                        :value='last_name'
                        type='text'
                        name='text'
                        :placeholder='$t(`WORKER.FORM.LAST.NAME.PLACEHOLDER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(LAST_NAME)'
                        @update:value='value => last_name = value'
                        @input='clearServerErrorsBase(LAST_NAME)'
                        @blur='addCurrentInputToValidateArray(LAST_NAME)'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    labelType='label-medium'
                    class='h-flex-1-0'
                    :title='`${$t(`WORKER.FORM.COMPANY.LABEL`)}*`'>
                    <div >
                        <div class='fw-select-base'>
                            <FwSelect
                                v-model='company'
                                class='fw-select-with-icon--big'
                                type='white'
                                :propsPlaceholder='`${$t(`WORKER.FORM.COMPANY.PLACEHOLDER`)}`'
                                label='title'
                                :canNotDelete='true'
                                :disabled='$route.params.id'
                                :searchable='false'
                                :multiply='false'
                                :withCircles='true'
                                :clearable='false'
                                :options='companies'>
                            </FwSelect>
                        </div>
                    </div>
                </FwFormInput>
            </div>
            <div class='h-flex h-flex-space-between h-pb-30'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.PHONE.LABEL`)}`'>
                    <FwInput
                        :value='phone'
                        type='number'
                        name='text'
                        :placeholder='$t(`WORKER.FORM.PHONE.PLACEHOLDER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        @update:value='value => phone = value'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    v-if='categoryLocationSkillNames'
                    labelType='label-medium'
                    class='h-flex-1-0 h-mr-30'
                    :title='`${$t(`LABEL.APPOINTMENT.METHODS`)}*`'>
                    <div class='fw-select-base'>
                        <FwSelect
                            v-model='locationSkillChoosed'
                            class='fw-select-with-icon--big'
                            :propsPlaceholder='$t(`WORKER.FORM.SKILLS.PLACEHOLDER`)'
                            type='white'
                            :searchable='false'
                            :label='labelKey'
                            openDirection='bottom'
                            :multiple='true'
                            :clearable='false'
                            :withCircles='true'
                            :canNotDelete='false'
                            :options='categoryLocationSkillNames'>
                        </FwSelect>
                    </div>
                </FwFormInput>
                <FwFormInput
                    labelType='label-medium'
                    class='h-flex-1-0'
                    :title='`${$t(`WORKER.FORM.SKILLS.LABEL`)}*`'>
                    <div >
                        <div class='fw-select-base'>
                            <FwSelect
                                v-model='skillChoosed'
                                class='fw-select-with-icon--big'
                                type='white'
                                :propsPlaceholder='`${$t(`WORKER.FORM.SKILLS.PLACEHOLDER`)}`'
                                :searchable='false'
                                :multiple='true'
                                :label='labelKey'
                                :clearable='false'
                                :withCircles='true'
                                :canNotDelete='false'
                                :options='skills'>
                            </FwSelect>
                        </div>
                    </div>
                </FwFormInput>
            </div>
            <div style='width: 310px' class='h-flex h-flex-space-between h-pb-30'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    :fieldHasError='fieldHasError(EMAIL)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.EMAIL.LABEL`)}*`'>
                    <FwInput
                        :value='email'
                        type='text'
                        name='text'
                        :placeholder='$t(`WORKER.FORM.EMAIL.PLACEHOLDER`)'
                        inputStyle='white-bigger'
                        :disabled='disabledForm'
                        :error='getFieldErrorText(EMAIL)'
                        @update:value='value => email = value'
                        @input='clearServerErrorsBase(EMAIL)'
                        @blur='addCurrentInputToValidateArray(EMAIL)'>
                    </FwInput>
                </FwFormInput>
            </div>

            <template>
                <div v-if='geograficIds.length' class='h-pos-rel'>
                    <FwFormInput
                        labelType='label-medium'
                        :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                        <div class='h-flex h-flex-wrap h-pb-30' style='margin: 0 -15px;'>
                            <div v-for='item in geograficIds'
                                 :key='item'
                                 style='width: 435px; height: 60px;'
                                 class='b-team-list h-mh-15'>
                                <div class='b-team-list__body'>
                                    <div class='h-fw-500'>
                                        {{ item.post_code }} - {{ item.name }}
                                    </div>
                                </div>
                                <div class='b-team-list__delete'
                                     @click='deleteItem(item)'>
                                    <FwIcon
                                        class='h-flex-center h-pointer'
                                        icon='bucket-edit'
                                        size='20'
                                        color='rgba(33,63,107,.3)'
                                        @click.native='confirmPopup = true'>
                                    </FwIcon>
                                </div>
                            </div>
                        </div>
                    </FwFormInput>
                    <div class='h-pos-abs h-fw-500 h-font-14 h-pointer'
                         style='top: 0; left: 150px;color: #27dbbd;'
                         @click='openPopup'>
                        {{ $t('LABEL.GEO.ZONE') }}
                    </div>
                </div>
                <div v-else style='width: 310px' class='h-flex h-flex-space-between h-pb-30'>
                    <FwFormInput
                        labelType='label-medium'
                        class='h-flex-1-0 h-mr-30'
                        :title='`${$t(`WORKER.FORM.ZONE.LABEL`)}*`'>
                        <div class='fw-select-base'>
                            <div class='b-team-list__body h-pointer'
                                 @click='openPopup'>
                                <div class='h-fw-500' style='color: rgba(33,63,107,.5);'>
                                    {{ $t('WORKER.FORM.GEOGRAFIC.SELECT.PLACEHOLDER') }}
                                </div>
                                <FwIcon
                                    class='h-flex-center h-pointer'
                                    icon='edit-settings'
                                    size='20'
                                    color='#203f6a'>
                                </FwIcon>
                            </div>
                        </div>
                    </FwFormInput>
                </div>
            </template>

            <div style='width: 310px'>
                <FwFormInput
                    class='h-flex-1-0 h-mr-30'
                    :fieldHasError='fieldHasError(ADMIN)'
                    labelType='label-medium'
                    :title='`${$t(`WORKER.FORM.ADMIN.LABEL`)}`'>
                    <FwLabelCard
                        class='h-flex-1-0 b-label-card'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'>
                                <span class='b-add-worker__is_admin_active'>
                                    {{ isActiveAdmin ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                </span>
                            </div>
                            <FwSwitcher
                                :isActive='isActiveAdmin'
                                :isRequestSending='isActiveAdminRequestSending'
                                @toggleSwitch='isActiveAdmin = !isActiveAdmin'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>
            </div>
            <template v-if='isTypeSite'>
                <h2 class='h-font-22'>
                    {{ $t('LABEL.START.POINT') }}
                </h2>
                <div style='width: 310px' class='h-pb-30'>
                    <FwFormInput
                        class='h-flex-1-0 h-mr-30'
                        :fieldHasError='fieldHasError(ADMIN)'
                        labelType='label-medium'
                        :title='`${$t(`TECH.SAME.COMPANY.ADDRESS`)}*`'>
                        <FwLabelCard
                            :disabled='isInitiaStartPoinDisabled'
                            class='h-flex-1-0 b-label-card'>
                            <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                                <div class='b-add-edit__form__option h-flex h-flex-center'>
                                    <span class='b-add-worker__is_admin_active'>
                                        {{ isCompanyAddress ? $t('WORKER.FORM.ADMIN.YES') : $t('WORKER.FORM.ADMIN.NO') }}
                                    </span>
                                </div>
                                <FwSwitcher
                                    :isActive='isCompanyAddress'
                                    :isRequestSending='isActiveAdminRequestSending'
                                    @toggleSwitch='isCompanyAddress = !isCompanyAddress'>
                                </FwSwitcher>
                            </div>
                        </FwLabelCard>
                    </FwFormInput>
                </div>
                <div v-if='!isCompanyAddress'>
                    <div class='h-flex h-flex-space-between h-mb-30'>
                        <FwFormInput
                            class='h-flex-1-0'
                            :fieldHasError='fieldHasError()'
                            :title='`${$t(`LABEL.ADDRESS`)}*`'
                            labelType='label-medium'>
                            <SelectAddress
                                :startAddress='address'
                                :isDisabled='isInitiaStartPoinDisabled'
                                @changePostCode='value => post_code = value'
                                @input='input'>
                            </SelectAddress>
                        </FwFormInput>
                    </div>
                </div>
            </template>

            <div class='h-fw-500 h-font-14 h-mv-30'>
                *{{ $t('WORKER.FORM.REQUIRED.FIELDS') }}
            </div>
        </form>
    </template>
    <portal to='appointment-bar-buttons'>
        <div class='h-flex'>
            <FwButton
                v-if='$route.path.includes(`add-more`) && !isRecapRoute'
                class='h-mr-10'
                size='little'
                color='skip'
                borderRadiusType='small-border'
                @click='$router.push({ path: `${mainRouteGlobal}/technicians` })'>
                {{ $t('BUTTON.SKIP') }}
            </FwButton>
            <FwButton
                size='little'
                :disabled='isDisabled'
                borderRadiusType='small-border'
                @click='submit'>
                {{ $route.params.id ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
            </FwButton>
        </div>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import {
    FIRST_NAME,
    LAST_NAME,
    SKILLS,
    COMPANY,
    EMAIL,
} from '@/helpers/worker';
import {
    CategoryLocationSkillNameType,
    CategorySkillNameType,
    GeograficCitiesType,
} from '@/types/Appointment';
import TranslateMixin from '@/mixins/TranslateMixin';
import { AddEditGeograficPopup } from '@/components/popups/AddEditGeograficPopup';
import { TechniciansApi } from '@/api/technicians/TechniciansApi';
import { CompanyType } from '@/types/Companies';
import { SelectAddress } from '@/components/common/SelectAddress';
import { ParsedAddressData } from '@/types/GoogleMap';
import { WorkersWebApi } from '@/api/workers/WorkersApi';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    components: {
        SelectAddress,
        AddEditGeograficPopup,
    },
    validators: {
        [FIRST_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [LAST_NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [EMAIL](value: string) {
            return this.requiredValidator({ value });
        },
        // [GEOGRAPHIC_ZONE](value: string) {
        //     return this.requiredValidator({ value });
        // },
    },
})
export default class AppointmentAddPartnerWorker extends Mixins(ValidationMixin, TranslateMixin) {
    @AppointmentStore.Mutation('setGeograficCitiesData') setGeograficCitiesData!: Function;
    @AppointmentStore.State('geograficCitiesData') geograficIds!: Array<GeograficCitiesType>;
    zoneValue: string | null = null;
    [FIRST_NAME]: string = '';
    [LAST_NAME]: string = '';
    company: null | CompanyType = null;
    companies: Array<CompanyType> = [];
    skills: Array<CategorySkillNameType> = [];
    categoryLocationSkillNames: Array<CategoryLocationSkillNameType> = [];
    [EMAIL]: string = '';
    FIRST_NAME: string = FIRST_NAME;
    LAST_NAME: string = LAST_NAME;
    EMAIL: string = EMAIL;
    phone: string = '';
    post_code: string | null = '';
    address: string | null = '';
    geographic_zone: string = '';
    isGeograficPopup: boolean = false;
    isAddressDisabled: boolean = false;
    isActiveAdminRequestSending: boolean = false;
    isActiveAdmin: boolean = false;
    isInitiaStartPoinDisabled: boolean = false;
    isCompanyAddress: boolean = false;
    loading: boolean = false;
    sendRequest: boolean = false;
    locationSkillChoosed: Array<CategoryLocationSkillNameType> = [];
    skillChoosed: Array<CategorySkillNameType | CategoryLocationSkillNameType> = [];
    errorFromServer: string = '';
    serverErrors: serverErrors = {
        [FIRST_NAME]: null,
        [LAST_NAME]: null,
        [EMAIL]: null,
    };
    zones: Array<string> = [`Zone 1`, `Zone 2`];

    get isDisabled(): boolean {
        return !this.isFormValid || !this.isAdminFormValid || this.sendRequest ||
            (this.isTypeSite && (!this.address && !this.isCompanyAddress)) ||
            this.geograficIds.length === 0;
    }

    get isAdminFormValid(): boolean {
        return Boolean(this.skillChoosed.length !== 0 && this.locationSkillChoosed.length !== 0 && this.company);
    }

    get isTypeSite() {
        return this.locationSkillChoosed.some(item => item.searchable_name === 'location_category_on_site' || item.searchable_name === 'location_category_on_site_in_branch');
    }
    input(parsedAddressData: ParsedAddressData) {
        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.calizyAddress;
    }
    openPopup() {
        this.isGeograficPopup = true;
    }

    closePopup() {
        this.isGeograficPopup = false;
    }
    deleteItem(item: GeograficCitiesType) {
        if (item) {
            const arrayItems = this.geograficIds.filter(filterItem => filterItem.id !== item.id);
            this.setGeograficCitiesData(arrayItems);
        }
    }
    async created() {
        this.loading = true;
        const helpers = await WorkersWebApi.getTechnicianHelpersData();
        if (!this.skills.length) {
            this.skills = helpers.data.skill_names;
        }
        this.companies = helpers.data.company_titles;
        this.categoryLocationSkillNames = helpers.data.category_location_skill_names;
        this.setGeograficCitiesData([]);
        if (this.$route.params.id) {
            this.isAddressDisabled = true;
            const { data } = await TechniciansApi.getTechnicianData({ id: this.$route.params.id });
            const { technician } = data;
            if ((technician as WorkerType)) {
                this.company = this.companies.find(item => item.id === technician.company_id) || null;
                this.zoneValue = technician.time_zone; // wrong
                this[FIRST_NAME] = technician.first_name;
                this[LAST_NAME] = technician.last_name;
                this[EMAIL] = technician.email;
                this.isCompanyAddress = technician.is_company_address_used;
                this.setGeograficCitiesData(technician.geo_sites);
                if (technician.technician_profile && technician.technician_profile.departure_point) {
                    this.address = technician.technician_profile.departure_point;
                    this.isInitiaStartPoinDisabled = true;
                }
                this.phone = technician.phone || technician.technician_profile.phone;
                // this.geographic_zone = technician.time_zone; // wrong
                this.isActiveAdmin = technician.technician_profile.company_admin;
                this.skillChoosed = this.skills.filter(item => {
                    return technician.skills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                });
                this.locationSkillChoosed = this.categoryLocationSkillNames.filter(item => {
                    return technician.skills.find((skill: CategoryLocationSkillNameType) => skill.id === item.id);
                });
            }
        }
        this.loading = false;
    }

    async submit() {
        if (this.isDisabled) return;
        this.isRequestSending = true;
        try {
            await this.sendDataToServer();
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    async sendDataToServer() {
        this.sendRequest = true;
        const skill_ids = [...this.skillChoosed!.map(item => item.id), ...this.locationSkillChoosed!.map(item => item.id)];
        const geo_site_ids = [...this.geograficIds!.map(item => item.id)];
        const departure_point = !this.isCompanyAddress && this.address ? this.address : null;
        const set_company_as_starting_point = !!this.isCompanyAddress;
        try {
            const payload = {
                first_name: this[FIRST_NAME],
                last_name: this[LAST_NAME],
                label: '',
                kind: 'technician',
                company_id: this.company ? this.company.id : '',
                email: this[EMAIL],
                phone: this.phone,
                company_admin: this.isActiveAdmin,
                geo_site_ids,
                skill_ids,
                departure_point,
                set_company_as_starting_point,
            };
            if (this.$route.params.id) {
                try {
                    await TechniciansApi.editTechnician(payload, this.$route.params.id);
                    this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            } else {
                try {
                    await TechniciansApi.createTechnician(payload);
                    this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
                } catch ({ response }) {
                    this.sentNotif(response);
                }
            }
            if (this.isRecapRoute) {
                this.$router.push({ path: `${this.mainRouteGlobal}/recap/technicians` });
            } else {
                this.$router.push({ path: `${this.mainRouteGlobal}/technicians` });
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.sendRequest = false;
        }
    }
}
</script>

<style lang='sass'>
.b-add-worker
    &__wrapper
        .b-label-card
            background-color: #fff
            height: 60px !important

    &__required-fields
        display: flex
        align-items: center
        justify-content: flex-end
        font-weight: 500
        color: #213F6B
        height: 60px
        margin-top: 26px

    &__is_admin_active
        font-weight: 500

.h-flex-1-0
    min-width: 0
</style>
