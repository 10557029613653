<template>
<div :class='`${mainClass}__wrapper container`'>
    <div v-if='!isPopupForm' class='b-base-title__wrapper'>
        <h2 class='b-appointment-left-bar__title'>
            {{ $t('You need to choose a branch') }}
        </h2>
    </div>
    <div class='h-flex h-flex-dir-column h-flex-center'>
        <h3>
            If you want to add a room, you need to choose a branch before
        </h3>
        <div class='fw-select-base h-mt-30' style='width: 320px;'>
            <FwSelect
                v-model='choosenBranch'
                type='white'
                :propsPlaceholder='`${$t(`WORKER.FORM.BRANCH.PLACEHOLDER`)}`'
                label='name'
                :canNotDelete='true'
                :searchable='false'
                :multiply='false'
                :options='branches'
                @select='updateBranchAddStore'>
            </FwSelect>
        </div>
    </div>
    <div v-if='isPopupForm' class='h-flex h-flex-center h-mt-40'>
        <FwButton
            class='h-mt-20'
            size='little'
            borderRadiusType='small-border'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>
    <portal to='appointment-bar-buttons'>
        <FwButton
            :to='`${mainRouteGlobal}${$route.meta.nextUrl}`'
            size='little'
            borderRadiusType='small-border'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { isNil } from 'ramda';
import ValidationMixin from '@/mixins/validation';
import { ConsultCard } from '@/components/simple/ConsultCard';
import {
    NAME,
    EMAIL,
} from '@/helpers/branch';
import { OnboardingApi } from '@/api/onboarding/OnboardingApi';
import { BranchRoomResponseType } from '@/types/Appointment';
import { AddEditRoomPopup } from '@/components/popups/AddEditRoomPopup';
import { DeletePopup } from '@/components/popups/DeletePopup';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { CalendarTable } from '@/components/common/CalendarTable';
import { CalendarTableButtons } from '@/components/common/CalendarTableButtons';
import { SitesBranchDataType } from '@/types/Site';
import { BranchTitles } from '@/types/Branch';

const OnboardingStore = namespace('OnboardingStore');

@Component({
    validators: {
        [NAME](value: string) {
            return this.requiredValidator({ value });
        },
        [EMAIL](value: string) {
            return this.requiredValidator({ value });
        },
    },
    components: {
        ConsultCard,
        AddEditRoomPopup,
        DeletePopup,
        FinalePopup,
        CalendarTable,
        CalendarTableButtons,
    },
})
export default class AppointmentBranchChooseBranch extends Mixins(ValidationMixin) {
    @OnboardingStore.Mutation('mutationBranchData') mutationBranchData!: Function;
    @OnboardingStore.Getter('branchAddData') branchAddData!: BranchRoomResponseType;
    @Prop({ type: Boolean, default: false }) readonly isPopupForm!: boolean;
    rooms: Array<SitesBranchDataType> = [];
    name: string | null = '';
    email: string | null = '';
    choosenBranch: BranchTitles | null = null;
    editableIndex: null | number = 0;
    branches: Array<BranchTitles> = [];
    loading: boolean = false;
    currentRoomData: BranchTitles | null = null;

    serverErrors: serverErrors = {
        name: null,
        email: null,
    };

    get mainClass() {
        return !this.isPopupForm ? `b-add-branch` : `b-popup-branch`;
    }

    updateBranchAddStore(branch: BranchTitles) {
        this.mutationBranchData(branch);
        this.$router.push({ path: `/account-onboarding/branches/add-rooms/${branch.id}` });
    }

    async getData() {
        // @ts-ignore-next-line
        this.branches = await OnboardingApi.getBranchesTitles();
    }

    async created() {
        if (!this.branchAddData) {
            await this.getData();
        }
    }
}
</script>

<style lang='sass'>
.b-popup-branch
    &__card
        max-width: 400px
        padding: 20px 50px
        border-radius: 10px
        background-color: rgba(32,63,106,0.05)
    &__wrapper
        width: 100%
        @include media('<=phone')
            width: 100%

        .b-required-field
            font-weight: 500
            font-size: 14px
            margin-bottom: 0

    &__rooms
        &__title
            font-size: 25px
            font-weight: 500
            margin-bottom: 0

        &__form
            display: flex
            justify-content: space-between
            align-items: center

        &__aside
            font-size: 16px
            font-weight: 500

        &__select
            width: 100px
</style>
