export default {
    'WORKER.FORM.FIRST.NAME.LABEL': 'First name',
    'WORKER.FORM.FIRST.NAME.PLACEHOLDER': 'ex: John',

    'WORKER.FORM.LAST.NAME.LABEL': 'Last name',
    'WORKER.FORM.LAST.NAME.PLACEHOLDER': 'ex: Doe',

    'WORKER.FORM.SKILLS.LABEL': 'Skills',
    'WORKER.FORM.SKILLS.PLACEHOLDER': 'Select methods',

    'WORKER.FORM.COMPANY.LABEL': 'Company',
    'WORKER.FORM.COMPANY.PLACEHOLDER': 'Select a company',

    'WORKER.FORM.PHONE.LABEL': 'Phone',
    'WORKER.FORM.PHONE.PLACEHOLDER': 'ex: 0620394762',

    'WORKER.FORM.EMAIL.LABEL': 'Email',
    'WORKER.FORM.EMAIL.PLACEHOLDER': 'ex: adress@email.com',

    'WORKER.FORM.ADMIN.LABEL': 'Admin of this company',
    'WORKER.FORM.HUB.ADMIN.LABEL': 'Hub admin',
    'WORKER.FORM.ADMIN.NO': 'NO',
    'WORKER.FORM.ADMIN.YES': 'YES',

    'WORKER.FORM.ZONE.LABEL': 'Geographic zone',
    'WORKER.FORM.ZONE.PLACEHOLDER': 'Select a zone',

    'WORKER.FORM.HUB.LABEL': 'Hub',
    'WORKER.FORM.HUB.PLACEHOLDER': 'Select a hub',
    'WORKER.FORM.HUB.MEMBERSHIP': 'Hub membership',

    'WORKER.FORM.BRANCH.LABEL': 'Branch',
    'WORKER.FORM.BRANCH.PLACEHOLDER': 'Select a branch',
    'WORKER.FORM.BRANCH.MEMBERSHIP': 'Branch membership',

    'WORKER.FORM.REQUIRED.FIELDS': 'Required fields',
    'WORKER.FORM.REQUIRED.ONE.OF.TWO.FIELDS': 'Please complete at least one of these fields',

    'WORKERS.LIST.HEADER': 'Ressources',
    'WORKER.LIST.NEXT.STEP': 'You can add, edit or delete ',
    'WORKER.LIST.NEXT.STEP2': ' or go to the next step.',

    'WORKER.HOURS.SET': 'Set here working hours for the worker',

    'WORKER.FORM.GEOGRAFIC.TITLE': 'Entire country',
    'WORKER.FORM.GEOGRAFIC.SELECT.PLACEHOLDER': 'Select a district/city',
    'WORKER.FORM.GEOGRAFIC.SELECT.DISTRICT': 'Please select a district',
    'WORKER.FORM.GEOGRAFIC.SEARCH.PLACEHOLDER': 'Search by a post code',
    'WORKER.FORM.GEOGRAFIC.SELECT.ENTIRE.DISTRICT': 'Select the entire district',
};
