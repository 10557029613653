<template>
<div class='h-mt-30'>
    <form class='b-add-manager_new__form'
          @keydown.stop.prevent.enter='onSubmit'>
        <div class='h-flex h-flex-center h-width-100p h-mv-0'>
            <FwFormInput
                style='flex: 1 0 50%;'
                class='qa-add-manager-popup__name'
                :fieldHasError='fieldHasError("first_name")'
                labelType='label-medium'
                :title='`${$t(`LABEL.FIRST.NAME`)}*`'>
                <FwInput
                    :value='first_name'
                    :placeholder='$t(`LABEL.FIRST.NAME`)'
                    type='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger'
                    @update:value='value => first_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                style='flex: 1 0 50%;'
                class='qa-add-manager-popup__last-name'
                :fieldHasError='fieldHasError("last_name")'
                labelType='label-medium'
                :title='`${$t(`LABEL.LAST.NAME`)}*`'>
                <FwInput
                    :value='last_name'
                    :placeholder='$t(`LABEL.LAST.NAME`)'
                    type='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger'
                    @update:value='value => last_name = value'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-flex-center h-width-100p h-mv-0'>
            <FwFormInput
                style='flex: 0 1 50%;'
                :fieldHasError='fieldHasError("email")'
                labelType='label-medium'
                class='qa-add-manager-popup__email'
                :title='`${$t(`LABEL.EMAIL`)}*`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EMAIL`)'
                    inputStyle='white-bigger'
                    :disabled='disabledForm'
                    :error='getFieldErrorText("email")'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase("email")'
                    @blur='addCurrentInputToValidateArray("email")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-add-company__form__input b-add-company__form__input--big qa-add-manager-popup__phone'
                :fieldHasError='fieldHasError("phone")'
                labelType='label-medium'
                :title='$t(`LABEL.PHONE`)'>
                <FwInput
                    :value='phone'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger'
                    placeholder='ex: 06 20 45 30 79'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    @update:value='value => phone = value'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='h-flex h-flex-center  h-mv-0'
             :class='isMatmutAccount ? "h-width-100p" : "h-width-50p"'>
            <FwFormInput
                labelType='label-medium'
                :title='`${$t(`LABEL.ROLE`)}*`'>
                <div style='background: #fff; border-radius: 10px;' class='h-p-0'>
                    <div class='fw-select-base qa-add-manager-popup__role'>
                        <FwSelect
                            v-model='valueRole'
                            type='white'
                            :disabled='disabledForm'
                            :propsPlaceholder='$t(`LABEL.ROLE.PLACEHOLDER`)'
                            label='name'
                            canNotDelete
                            :searchable='false'
                            :multiply='false'
                            withCircles
                            :options='optionsRole'>
                        </FwSelect>
                    </div>
                </div>
            </FwFormInput>
            <FwFormInput
                v-if='isMatmutAccount'
                class='b-base-form-margin b-add-company__form__input b-add-company__form__input--big'
                :fieldHasError='fieldHasError("code_site")'
                labelType='label-medium'
                title='Code site'>
                <FwInput
                    :value='code_site'
                    type='text'
                    name='text'
                    :disabled='disabledForm'
                    inputStyle='white-bigger'
                    placeholder='ex: 123 456'
                    @update:value='value => code_site = value'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>
    <div class='b-form-hint__wrapper'>
        <div class='b-header-required h-fw-500 h-mb-0 h-font-14'>
            * {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
        </div>
    </div>
    <div class='h-flex h-flex-center'>
        <FwButton
            v-if='!disabledForm'
            class='h-mt-20 qa-add-manager-popup__save'
            :disabled='disabled'
            borderRadiusType='tiny-border'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>
    </div>
    <div v-if='manager && canDelete'
         class='b-add-manager_new__form__delete qa-manager-card__delete'
         @click='removeManager'>
        {{ $t('MANAGERS.DELETE.FORM.TITLE') }}
    </div>
</div>
</template>

<script lang='ts'>
import { clone } from 'ramda';
import { Component, Prop, Mixins, Watch, Emit } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import { SettingsManagerFormDataType } from '@/types/Settings';
import { UserManagerProfileRole, User } from '@/types/User';
import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';
import { RoleSelectedType } from '@/types/Roles';

import { isEqual } from '@/helpers/base';
import { SettingsWebApi } from '@/api/settings/SettingsApi';

@Component({
    validators: {
        email(value: string) {
            return this.emailValidator({ value });
        },
        last_name(value: string) {
            return this.requiredValidator({ value });
        },
        first_name(value: string) {
            return this.requiredValidator({ value });
        },
    },
})
export default class AddManagerNewForm extends Mixins(ValidationMixin, PermissionsMixin, AccountMixin) {
    @Prop({ type: Object, required: true }) readonly manager!: User;
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;
    @Prop({ type: String, default: null }) readonly errorFromServer!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: Array, required: true }) readonly roles!: Array<UserManagerProfileRole>;

    phoneCode: string = '+33';
    phone: string = '';

    valueRole: RoleSelectedType | null = null;

    optionsRole: Array<RoleSelectedType> = this.roles.map(item => ({ id: item.id, name: item.name }));
    startFormData: SettingsManagerFormDataType | null = null;

    role: string = '';
    role_id: string = '';
    email: string = '';
    first_name: string = '';
    last_name: string = '';
    code_site: string = '';

    serverErrors: serverErrors = {
        first_name: '',
        last_name: '',
        email: '',
    };

    get isEqual(): boolean {
        if (this.manager) {
            return isEqual(this.startFormData, this.currentForm);
        }
        return false;
    }

    get currentFormValid(): boolean {
        return Boolean(this.isFormValid && this.selectedRole);
    }

    get disabled(): boolean {
        return (
            !this.currentFormValid ||
            this.isRequestSending ||
            (this.manager && this.isEqual)
        );
    }

    get rolesArray(): any {
        return this.$store.state.SettingsPageStore.rolesState;
    }

    get currentForm() {
        return {
            email: this.email,
            role: this.selectedRole ? this.selectedRole.name : '',
            role_id: this.selectedRole ? this.selectedRole.id : '',
            phone: this.phone,
            code_site: this.code_site,
            first_name: this.first_name,
            last_name: this.last_name,
        };
    }

    get selectedRole(): RoleSelectedType | null {
        if (this.valueRole) {
            return {
                name: this.valueRole.name,
                id: this.valueRole.id,
            };
        }
        return null;
    };

    removeManager(): void {
        this.$emit('remove', this.manager);
    }

    async created() {
        if (this.manager) {
            this.email = this.manager.email || '';
            this.phone = this.manager!.phone || this.manager.manager_profile!.phone || '';
            this.first_name = this.manager.first_name || '';
            this.last_name = this.manager.last_name || '';
            this.code_site = this.manager.code_site || '';
            const managerRole: RoleSelectedType = this.optionsRole.find(item => item.name === this.manager.manager_profile!.role.name)!;
            this.valueRole = managerRole;
            this.startFormData = clone(this.currentForm);
        }
    }

    async mounted() {
        if (this.manager) {
            this.addCurrentInputToValidateArray('email');
            this.addCurrentInputToValidateArray('first_name');
            this.addCurrentInputToValidateArray('last_name');
        }
    }

    @Watch('errorFromServer')
    checkEmailError() {
        if (this.errorFromServer.includes('Email')) {
            this.serverErrors = {
                // @ts-ignore-next-line
                email: this.$i18n.t('WORKER.EMAIL.TAKEN'),
            };
        }
    }

    @Emit('onSubmit')
    onSubmit(): SettingsManagerFormDataType {
        return this.currentForm;
    }
}
</script>

<style lang='sass'>
.b-add-manager_new__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px
    .multiselect:after
        bottom: 10px!important
    .multiselect__tags
        padding-left: 20px!important
    .multiselect__placeholder
        color: #bec7d3!important
    .fw-select-white
        box-shadow: none!important
    .b-base-form-small
        width: 210px!important
    .b-base-form-large
        width: 100%!important

    @include media('<=phone')
        flex-direction: column

    .fw-form-input__wrapper
        flex: 1 0 30%
        padding: 0 15px
        margin: 10px 0

    &__select
        box-shadow: $big-box-shadow

    &__delete
        font-size: 12px
        cursor: pointer
        color: #EB2767
        margin-top: 15px
        font-weight: 500
        text-align: center

    .fw-select-with-icon .fw-select-white,
    .fw-select-with-icon .multiselect__tags
        height: 60px

.h-width-50p
    width: 50%
</style>
