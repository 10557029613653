<template>
<component
    :is='canRead ? `router-link` : `div`'
    :to='canRead ? { path: `/consult/company/${company.id}` } : false'
    class='b-company-card__wrapper'>
    <div class='b-company-card'>
        <div class='h-flex h-flex-dir-column'>
            <div class='b-company-card__title h-font-14'>
                {{ company.title }}
            </div>
            <div class='b-branch-card__workers-wrapper'>
                <div v-if='company.technicians.length' class='b-branch-card__workers-title h-font-12'>
                    <div>
                        {{ company.technicians.length }}
                    </div>
                    <span v-if='company.technicians.length !== 0' class='h-ml-5'>
                        {{ $tc('CHOOSE_MANAGE.COMMON.WORKER.PLURAL', company.technicians.length) }}
                    </span>
                    <span v-else class='h-ml-5'>
                        {{ $tc('CHOOSE_MANAGE.COMMON.NO.WORKER.PLURAL', company.technicians.length) }}
                    </span>
                </div>
            </div>
        </div>
        <div class='b-company-card__workers-wrapper'>
            <div v-for='worker in workersPart'
                 class='b-company-card__worker-inner'>
                <WorkerAvatar
                    :worker='worker'
                    class='b-company-card__worker'
                    :avatarSize='23'
                    :hideInfo='true'
                    align='right'>
                </WorkerAvatar>
            </div>
        </div>
    </div>
</component>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { CompanyCardWorkerType, CompanyData } from '@/types/Companies';
import { ConsultCompanyPage } from '@/views/consult/companies/ConsultCompanyPage';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import PermissionsMixin from '@/mixins/permissions';

const MAX_WORKERS_QUANTITY = 5;
@Component({
    components: {
        WorkerAvatar,
    },
})
export default class CompanyCard extends Mixins(PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly company!: CompanyData;

    get workersPart(): CompanyData {
        let val = null;
        if (this.company && this.company.technicians) {
            val = (this.company.technicians as any).slice(0, MAX_WORKERS_QUANTITY);
        }
        return val;
    }
}
</script>

<style lang='sass'>
$worker-size: 25px

.b-company-card
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    &__wrapper
        display: flex
        max-width: 395px
        background-color: #fff
        flex: 0 0 50%
        padding: 10px 30px
        border-radius: 20px
        box-shadow: $big-box-shadow
        min-height: 70px
        align-items: center

        @include media('<=phone')
            width: 100%
            max-width: none

    &__title
        font-weight: 600
        color: $alt-blue

    &__workers-wrapper
        display: flex
        align-items: center

    &__worker
        width: $worker-size
        height: $worker-size
        border-radius: 50%
        border: 1px solid #FFFFFF
        position: relative
        background-size: cover
        background-position: center
        background-color: lighten($main-green, 30)

    &__worker-inner
        width: calc($worker-size / 1.4)

    &__workers-title
        display: flex
        align-items: center
        font-weight: 300
        color: $main-gray
        text-transform: uppercase

@include media('<tablet')
    .b-company-card
        width: 100%
        &__wrapper
            margin: 5px 0

</style>
