export default {
    'CALENDAR.BUTTON.TODAY': 'Today',
    'CALENDAR.VIEW.AGENDA': 'View agenda of',
    'CALENDAR.APPOINTMENT.LABEL': 'Appointment',
    'CALENDAR.DURATION.LABEL': 'Duration',
    'CALENDAR.METHOD.LABEL': 'Method',
    'CALENDAR.NAME.LABEL': 'Name',
    'CALENDAR.COMPANY.NAME.LABEL': 'Company name',
    'CALENDAR.LOCATION.LABEL': 'Location',
    'CALENDAR.LOCATION.BRANCH.LABEL': 'Branch Address',
    'CALENDAR.LOCATION.CHECKBOX.LABEL': 'Location',
    'CALENDAR.MONTH.JANUARY': 'January',
    'CALENDAR.MONTH.FEBRUARY': 'February',
    'CALENDAR.MONTH.MARCH': 'March',
    'CALENDAR.MONTH.APRIL': 'April',
    'CALENDAR.MONTH.MAY': 'May',
    'CALENDAR.MONTH.JUNE': 'June',
    'CALENDAR.MONTH.JULY': 'July',
    'CALENDAR.MONTH.AUGUST': 'August',
    'CALENDAR.MONTH.SEPTEMBER': 'September',
    'CALENDAR.MONTH.OCTOBER': 'October',
    'CALENDAR.MONTH.NOVEMBER': 'November',
    'CALENDAR.MONTH.DECEMBER': 'December',
    'CALENDAR.WEEK.MONDAY': 'Monday',
    'CALENDAR.WEEK.TUESDAY': 'Tuesday',
    'CALENDAR.WEEK.WEDNESDAY': 'Wednesday',
    'CALENDAR.WEEK.THURSDAY': 'Thursday',
    'CALENDAR.WEEK.FRIDAY': 'Friday',
    'CALENDAR.WEEK.SATURDAY': 'Saturday',
    'CALENDAR.WEEK.SUNDAY': 'Sunday',

    'CALENDAR.FULL.DAY': 'Full day',

    'CALENDAR.TIME.12.AM': '12:00 am',
    'CALENDAR.TIME.12.15.AM': '12:15 am',
    'CALENDAR.TIME.12.30.AM': '12:30 am',
    'CALENDAR.TIME.12.45.AM': '12:45 am',
    'CALENDAR.TIME.01.AM': '01:00 am',
    'CALENDAR.TIME.01.15.AM': '01:15 am',
    'CALENDAR.TIME.01.30.AM': '01:30 am',
    'CALENDAR.TIME.01.45.AM': '01:45 am',
    'CALENDAR.TIME.02.AM': '02:00 am',
    'CALENDAR.TIME.02.15.AM': '02:15 am',
    'CALENDAR.TIME.02.30.AM': '02:30 am',
    'CALENDAR.TIME.02.45.AM': '02:45 am',
    'CALENDAR.TIME.03.AM': '03:00 am',
    'CALENDAR.TIME.03.15.AM': '03:15 am',
    'CALENDAR.TIME.03.30.AM': '03:30 am',
    'CALENDAR.TIME.03.45.AM': '03:45 am',
    'CALENDAR.TIME.04.AM': '04:00 am',
    'CALENDAR.TIME.04.15.AM': '04:15 am',
    'CALENDAR.TIME.04.30.AM': '04:30 am',
    'CALENDAR.TIME.04.45.AM': '04:45 am',
    'CALENDAR.TIME.05.AM': '05:00 am',
    'CALENDAR.TIME.05.15.AM': '05:15 am',
    'CALENDAR.TIME.05.30.AM': '05:30 am',
    'CALENDAR.TIME.05.45.AM': '05:45 am',
    'CALENDAR.TIME.06.AM': '06:00 am',
    'CALENDAR.TIME.06.15.AM': '06:15 am',
    'CALENDAR.TIME.06.30.AM': '06:30 am',
    'CALENDAR.TIME.06.45.AM': '06:45 am',
    'CALENDAR.TIME.07.AM': '07:00 am',
    'CALENDAR.TIME.07.15.AM': '07:15 am',
    'CALENDAR.TIME.07.30.AM': '07:30 am',
    'CALENDAR.TIME.07.45.AM': '07:45 am',
    'CALENDAR.TIME.08.AM': '08:00 am',
    'CALENDAR.TIME.08.15.AM': '08:15 am',
    'CALENDAR.TIME.08.30.AM': '08:30 am',
    'CALENDAR.TIME.08.45.AM': '08:45 am',
    'CALENDAR.TIME.09.AM': '09:00 am',
    'CALENDAR.TIME.09.15.AM': '09:15 am',
    'CALENDAR.TIME.09.30.AM': '09:30 am',
    'CALENDAR.TIME.09.45.AM': '09:45 am',
    'CALENDAR.TIME.10.AM': '10:00 am',
    'CALENDAR.TIME.10.15.AM': '10:15 am',
    'CALENDAR.TIME.10.30.AM': '10:30 am',
    'CALENDAR.TIME.10.45.AM': '10:45 am',
    'CALENDAR.TIME.11.AM': '11:00 am',
    'CALENDAR.TIME.11.15.AM': '11:15 am',
    'CALENDAR.TIME.11.30.AM': '11:30 am',
    'CALENDAR.TIME.11.45.AM': '11:45 am',
    'CALENDAR.TIME.12.PM': '12:00 pm',
    'CALENDAR.TIME.12.15.PM': '12:15 pm',
    'CALENDAR.TIME.12.30.PM': '12:30 pm',
    'CALENDAR.TIME.12.45.PM': '12:45 pm',
    'CALENDAR.TIME.01.PM': '01:00 pm',
    'CALENDAR.TIME.01.15.PM': '01:15 pm',
    'CALENDAR.TIME.01.30.PM': '01:30 pm',
    'CALENDAR.TIME.01.45.PM': '01:45 pm',
    'CALENDAR.TIME.02.PM': '02:00 pm',
    'CALENDAR.TIME.02.15.PM': '02:15 pm',
    'CALENDAR.TIME.02.30.PM': '02:30 pm',
    'CALENDAR.TIME.02.45.PM': '02:45 pm',
    'CALENDAR.TIME.03.PM': '03:00 pm',
    'CALENDAR.TIME.03.15.PM': '03:15 pm',
    'CALENDAR.TIME.03.30.PM': '03:30 pm',
    'CALENDAR.TIME.03.45.PM': '03:45 pm',
    'CALENDAR.TIME.04.PM': '04:00 pm',
    'CALENDAR.TIME.04.15.PM': '04:15 pm',
    'CALENDAR.TIME.04.30.PM': '04:30 pm',
    'CALENDAR.TIME.04.45.PM': '04:45 pm',
    'CALENDAR.TIME.05.PM': '05:00 pm',
    'CALENDAR.TIME.05.15.PM': '05:15 pm',
    'CALENDAR.TIME.05.30.PM': '05:30 pm',
    'CALENDAR.TIME.05.45.PM': '05:45 pm',
    'CALENDAR.TIME.06.PM': '06:00 pm',
    'CALENDAR.TIME.06.15.PM': '06:15 pm',
    'CALENDAR.TIME.06.30.PM': '06:30 pm',
    'CALENDAR.TIME.06.45.PM': '06:45 pm',
    'CALENDAR.TIME.07.PM': '07:00 pm',
    'CALENDAR.TIME.07.15.PM': '07:15 pm',
    'CALENDAR.TIME.07.30.PM': '07:30 pm',
    'CALENDAR.TIME.07.45.PM': '07:45 pm',
    'CALENDAR.TIME.08.PM': '08:00 pm',
    'CALENDAR.TIME.08.15.PM': '08:15 pm',
    'CALENDAR.TIME.08.30.PM': '08:30 pm',
    'CALENDAR.TIME.08.45.PM': '08:45 pm',
    'CALENDAR.TIME.09.PM': '09:00 pm',
    'CALENDAR.TIME.09.15.PM': '09:15 pm',
    'CALENDAR.TIME.09.30.PM': '09:30 pm',
    'CALENDAR.TIME.09.45.PM': '09:45 pm',
    'CALENDAR.TIME.10.PM': '10:00 pm',
    'CALENDAR.TIME.10.15.PM': '10:15 pm',
    'CALENDAR.TIME.10.30.PM': '10:30 pm',
    'CALENDAR.TIME.10.45.PM': '10:45 pm',
    'CALENDAR.TIME.11.PM': '11:00 pm',
    'CALENDAR.TIME.11.15.PM': '11:15 pm',
    'CALENDAR.TIME.11.30.PM': '11:30 pm',
    'CALENDAR.TIME.11.45.PM': '11:45 pm',

    'CALENDAR.DAY.MONDAY.SYMBOL': 'M',
    'CALENDAR.DAY.TUESDAY.SYMBOL': 'T',
    'CALENDAR.DAY.WEDNESDAY.SYMBOL': 'W',
    'CALENDAR.DAY.THURSDAY.SYMBOL': 'T',
    'CALENDAR.DAY.FRIDAY.SYMBOL': 'F',
    'CALENDAR.DAY.SATURDAY.SYMBOL': 'S',
    'CALENDAR.DAY.SUNDAY.SYMBOL': 'S',

    'CALENDAR.ALL.DATES': `ALL DATES`,
    'CALENDAR.DAY': `DAY`,
    'CALENDAR.WEEK': `WEEK`,
    'CALENDAR.MONTH': `MONTH`,

    'CALENDAR.POPUP.ADD.APPOINTMENT.TITLE': 'Add an appointment',

    'CALENDAR.POPUP.SYNC.TITLE': 'Calendar synchronization',
    'CALENDAR.POPUP.SYNC.GOOGLE.CALENDAR': 'Google Calendar',
    'CALENDAR.POPUP.SYNC.OUTLOOK.CALENDAR': 'Outlook Calendar',
    'CALENDAR.POPUP.SYNC.AUTHORIZATION': 'Authorization',
    'CALENDAR.POPUP.SYNC.TOKEN.VALIDITY': 'Token validity',
    'CALENDAR.POPUP.SYNC.CALENDAR.SYNCED': 'Calendar synced',
    'CALENDAR.POPUP.SYNC.USER.ID.RETRIEVED': 'User ID retrieved',
    'CALENDAR.POPUP.SYNC.CALENDAR.YES': 'YES',
    'CALENDAR.POPUP.SYNC.CALENDAR.NO': 'NO',

    'CALENDAR.EVENT.BOOKED.BY': 'Booked by {account}',
    'CALENDAR.EVENT.BOOKED.BY.THE.CLIENT': 'the client',

    'CALENDAR.FILTER.TITLE': 'Filter/sort users',
    'CALENDAR.CLEAR_FILTER.TITLE': 'Clear filters',
    'CALENDAR.FILTER.BY': 'Filter by',
    'CALENDAR.FILTER.SKILLS': 'Skills',
    'CALENDAR.FILTER.SORT_BY': 'Sort by',
    'CALENDAR.FILTER.SORTING_VARIABLE': 'Sorting variable',
    'CALENDAR.FILTER.SORTING_ORDER': 'Sorting order',
    'CALENDAR.FILTER.APPLY': 'Apply',

    'CALENDAR.CURRENT_MONTH': 'Current month',

    'CALENDAR.NO_DATA': 'No data for selected period',
};
