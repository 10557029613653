export default {
    'BRANCH.STATISTICS': 'Statistics',

    'BRANCH.STATISTICS.TYPES': 'Types',
    'BRANCH.STATISTICS.TOTAL': 'Total',
    'BRANCH.STATISTICS.METHODS': 'Methods',
    'BRANCH.STATISTICS.HONORED': 'Honored',
    'BRANCH.STATISTICS.SOURCE': 'Sources',
    'BRANCH.STATISTICS.RECIPIENTS': 'Recipients',
    'BRANCH.STATISTICS.CANCELLED.SOURCE': 'Sources',
    'BRANCH.STATISTICS.CANCELLED.RECIPIENTS': 'Recipients',
    'BRANCH.STATISTICS.NOT.HONORED': 'Not honored',
    'BRANCH.STATISTICS.ORIGIN': 'Origin',
    'BRANCH.STATISTICS.CANCELLATION.ORIGIN': 'Cancellation origins',
    'BRANCH.STATISTICS.CANCELLATION.TIME.BETWEEN': 'Time between cancellation and appointment',

    'BRANCH.STATISTICS.BY_TIME_SLOT': 'Starting time',
    'BRANCH.STATISTICS.TIME_BETWEEN': 'Time between booking and appointment',

    'BRANCH.STATISTICS.CANCELLATION': 'Cancellation origin',
    'BRANCH.STATISTICS.TIME_BETWEEN_2': 'Time between appointment cancellation and appointment',

    'BRANCH.STATISTICS.BOOKED.TYPE': 'Appointments booked for the branch',
    'BRANCH.STATISTICS.BOOKED.BY.TYPE': 'Appointments booked by the branch',
    'BRANCH.STATISTICS.CANCELLED.TYPE': 'Appointments cancelled for the branch',
    'BRANCH.STATISTICS.CANCELLED.BY.TYPE': 'Appointments cancelled by the branch',

    'HUB.STATISTICS.BOOKED.TYPE': 'Appointments booked for the hub',
    'HUB.STATISTICS.BOOKED.BY.TYPE': 'Appointments booked by the hub',
    'HUB.STATISTICS.CANCELLED.TYPE': 'Appointments cancelled for the hub',
    'HUB.STATISTICS.CANCELLED.BY.TYPE': 'Appointments cancelled by the hub',

    'BRANCH.STATISTICS.BOOKED': 'Appointments booked',
    'BRANCH.STATISTICS.CANCELLED': 'Appointments cancelled',

    'BRANCH.STATISTICS.APPOINTMENT.SOURCE': 'Appointment sources',
    'BRANCH.STATISTICS.APPOINTMENT.HONORED': 'Appointments honored',
    'BRANCH.STATISTICS.APPOINTMENT.RECIPIENTS': 'Appointment recipients',
    'BRANCH.STATISTICS.APPOINTMENT.CANCEL.RECIPIENTS': 'Cancellation recipients',
    'BRANCH.STATISTICS.APPOINTMENT.CANCEL.SOURCE': 'Cancellation sources',
    'BRANCH.STATISTICS.APPOINTMENT.CANCELED': 'Appointment cancelled',
    'BRANCH.STATISTICS.DISTRIBUTION.APPOINTMENT': 'Distribution of appointments by starting time',
    'BRANCH.STATISTICS.TIME.BTW.APPOINTMENT.CANCELLATION.APPOINTMENT': 'Time between cancellation and the appointment',
    'BRANCH.STATISTICS.TIME.BTW.MAKING.APPOINTMENT.APPOINTMENT': 'Time between booking and appointment',

    'BRANCH.STATISTICS.LOAN.APPOINTMENT': 'Loan appointment',
    'BRANCH.STATISTICS.HEALTH.APPOINTMENT': 'Health appointment',
    'BRANCH.STATISTICS.AUTO.APPOINTMENT': 'Auto appointment',
    'BRANCH.STATISTICS.LOAN.CONSULTATION': 'Loan consultation',
    'BRANCH.STATISTICS.LEGAL.CONSULTATION': 'Legal consultation',
    'BRANCH.STATISTICS.PERSONAL.CONSULTATION': 'Personal consultation',
    'BRANCH.STATISTICS.PROFESSIONAL.INSURANCE': 'Professional insurance',
    'BRANCH.STATISTICS.PROFESSIONAL.CONSULTATION': 'Professional consultation',
    'BRANCH.STATISTICS.PET.INSURANCE': 'Pet insurance',

    'BRANCH.STATISTICS.FOR_BRANCH_ADVISORS': 'For branch advisors',
    'BRANCH.STATISTICS.FROM_BRANCH_ADVISORS': 'From branch advisors',
    'BRANCH.STATISTICS.NOT.FROM_BRANCH_ADVISORS': 'NOT from branch advisors',
    'BRANCH.STATISTICS.NOT.FOR_BRANCH_ADVISORS': 'NOT for BRANCH advisors',

    'BRANCH.STATISTICS.BY_PHONE': 'By phone',
    'BRANCH.STATISTICS.ON_SITE': 'On site',
    'BRANCH.STATISTICS.BRANCH_FACE_TO_FACE': 'At branch face to face',
    'BRANCH.STATISTICS.BRANCH_VIDEOCONFERENCE': 'At the branch video conference',

    'BRANCH.STATISTICS.BEFORE': 'Before',
    'BRANCH.STATISTICS.AFTER': 'After',

    'BRANCH.STATISTICS.LESS.THAN.24h': 'Less than 24h',
    'BRANCH.STATISTICS.BETWEEN.24h.48h': 'Between 24h and 48h',
    'BRANCH.STATISTICS.BETWEEN.48h.72h': 'Between 48h and 72h',
    'BRANCH.STATISTICS.BETWEEN.3d.7d': 'Between 3 and 7 days',
    'BRANCH.STATISTICS.MORE.THAN.7d': 'More than 7 days',

    'BRANCH.STATISTICS.INTERNAL.CANCELLATION': 'Internal cancellation',
    'BRANCH.STATISTICS.EXTERNAL.CANCELLATION': 'External cancellation',
};
