<template>
<div class='b-consult-appointment b-blue-background b-blue-background__opacity b-blue-background--fixed'
     :class='{
         "b-add-or-edit-appointment--as-full-page": asFullPage,
     }'>
    <CancelReasonPopup
        v-if='cancelPopupValue'
        :appointmentId='appointmentData.id'
        @close='cancelPopupValue = false'
        @cancel='removeAppointment'>
    </CancelReasonPopup>
    <UpdateAppointmentPopup
        v-else-if='isUpdatePopup'
        @goToCalendar='goToCalendar'
        @startBookingProcess='startBookingProcess'
        @close='isUpdatePopup = false'>
    </UpdateAppointmentPopup>
    <FwSpinLoader
        v-else-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <FwPopup
        v-else
        :centered='!isMobile()'
        :isFixed='isDesktop()'
        iconColor='#BEC7D4'
        max-width='1200px'
        width='100%'
        :title='appointmentData? appointmentData.title : ""'
        :allowBackgroundScrolling='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        class='h-pos-rel'
        :padding='isMobile() ? "30px 20px" : "50px"'
        style='max-height: 90%;overflow-y: auto; justify-content: flex-start'
        :showClosePopup='!asFullPage'
        :propsPopupStyles='{ paddingBottom: isMobile() ? "60px" : null }'
        @close='$emit("close")'>
        <template #body>
            <div v-if='appointmentData && (appointmentData.booking_platform === "saas" || appointmentData.booking_platform === "sc")'
                 class='h-text-center'>
                <div v-if='appointmentData.sub_account_name' class='b-add-or-edit-appointment__account__title b-add-or-edit-appointment__sub-account__title'>
                    {{ $t('CALENDAR.EVENT.BOOKED.BY', { account: accountBookedType }) }}
                </div>
                <div v-else
                     class='b-add-or-edit-appointment__account__title'
                     :class='`b-add-or-edit-appointment__account__title--${appointmentData.booking_platform}`'>
                    {{ $t('CALENDAR.EVENT.BOOKED.BY', { account: accountBookedType }) }}
                </div>
            </div>
            <FormTabs
                :appointmentData='appointmentData'
                :data='data'
                @onSubmit='sendDataToServer'
                @updateCurrentTab='updateCurrentTab'>
            </FormTabs>
            <div v-if='currentTab !== "InternalAppointmentCommentsForm"' class='h-flex h-flex-center h-flex-dir-column h-pos-rel'>
                <div v-if='isDayLessThenCurrent'
                     class='b-cancel-appointment__button'
                     style='pointer-events: none'>
                    <span >
                        {{ $t('PAST.APPOINTMENTS.CANT.BE.CANCELED') }}
                    </span>
                </div>
                <template v-else>
                    <div
                        class='b-add-or-edit-appointment__buttons'>
                        <div
                            class='h-flex h-flex-center h-pointer'
                            style='line-height: 1'
                            @click='isUpdatePopup = true'>
                            <FwIcon
                                icon='edit'
                                size='16'
                                color='#17479E'>
                            </FwIcon>
                            <span class='h-ml-10 h-fw-700 h-font-12' style='color: #17479E'>
                                {{ $t('UPDATE.APPOINTMENT.TITLE') }}
                            </span>
                        </div>
                        <div
                            class='b-add-edit__form__delete h-flex h-flex-center h-ml-50 qa-consult-recap__appointment-cancel'
                            @click='cancelPopupValue = true'>
                            <FwIcon
                                icon='close'
                                size='14'
                                color='#FF004D'>
                            </FwIcon>
                            <span class='h-ml-10'>
                                {{ $t('CANCEL.BUTTON.APPOINTMENT') }}
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { namespace } from 'vuex-class';
import {
    AppointmentCategoryType,
    AppointmentOnSiteDataType,
    AppointmentSearchType,
    AppointmentTabType,
    BookAppointmentManuallyType,
    BranchAddressType,
} from '@/types/Appointment';
import { ConsultEventType } from '@/types/Availabilities';

import { SitesApi } from '@/api/sites/SitesApi';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

import { CancelReasonPopup } from '@/components/popups/CancelReasonPopup';
import { UpdateAppointmentPopup } from '@/components/popups/UpdateAppointmentPopup';
import { FormTabs } from '@/components/forms/tabs/FormTabs';

import AppointmentMixin from '@/mixins/appointment';
import AccountMixin from '@/mixins/account';

import { parseAddress } from '@/helpers/address';
import { dateToString } from '@/helpers/dates';
import { APPOINTMENT_CALENDAR_PAGE_NAME } from '@/helpers/consts';
import { RIGHT_CREATE, RIGHT_UPDATE } from '@/helpers/rights';

const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

@Component({
    components: {
        FormTabs,
        CancelReasonPopup,
        UpdateAppointmentPopup,
    },
})
export default class AddOrEditAppointmentByForm extends Mixins(AccountMixin, AppointmentMixin) {
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (type: AppointmentCategoryType) => void;
    @AppointmentStore.Mutation('setAppointmentDataToUpdate') setAppointmentDataToUpdate!: (type: AppointmentSearchType | null) => void;
    @AppointmentStore.Mutation('setAppointmentType') setAppointmentType!: (type: string) => void;
    @AppointmentStore.Mutation('setBranchAddress') setBranchAddress!: (branchAddress: BranchAddressType) => void;
    @AppointmentStore.Mutation('setAppointmentOnSiteData') setAppointmentOnSiteData!: (appointment: AppointmentOnSiteDataType | AppointmentSearchType) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;

    @Prop({ type: Object, default: null }) readonly appointmentData!: AppointmentSearchType;
    @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
    @Prop({ type: Boolean, default: false }) readonly asFullPage!: boolean;
    @Prop({ type: Object, required: true }) readonly data!: ConsultEventType;

    errorFromServer: string | null | TranslateResult = null;
    isRequestSending: boolean = false;
    isUpdatePopup: boolean = false;
    currentTab: string = '';
    cancelPopupValue: boolean = false;
    appointmentChangeDateData: BookAppointmentManuallyType | null = null;

    get isDayLessThenCurrent() {
        return this.appointmentData ? dateToString(new Date(this.appointmentData.dt_start as string), false, true) < dateToString(new Date()) : false;
    }

    get accountBookedType(): TranslateResult | string {
        if (!this.appointmentData || !this.appointmentData.booking_platform) {
            return ``;
        }
        if (this.appointmentData.booking_platform === 'sc') {
            return this.$t('CALENDAR.EVENT.BOOKED.BY.THE.CLIENT');
        }

        return this.user().account_name ? capitalizeFirstLetter(this.user().account_name) : '';
    }

    goToInterventionPage() {
        const currentRoute = this.$router.getRoutes().find(item => item.name === APPOINTMENT_CALENDAR_PAGE_NAME);
        if (currentRoute) {
            currentRoute.meta.right = RIGHT_UPDATE;
        }
        if (this.isOnPhoneFlow) {
            this.$router.push({ path: `/appointment/calendar/on-phone` });
        } else if (this.isOnSiteFlow) {
            this.setAppointmentOnSiteData(this.appointmentData);
            this.$router.push({ path: `/appointment/calendar/on-site` });
        } else if (this.isOnSiteInBranchFlow) {
            this.setAppointmentOnSiteData(this.appointmentData);
            this.$router.push({ path: `/appointment/calendar/in-branch-on-site` });
        } else if (this.isOnVideoFlow) {
            this.$router.push({ path: `/appointment/calendar/by-online-video` });
        } else if (this.isFaceToFaceFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-face-to-face` });
        } else if (this.isVideoconferenceFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-videoconference` });
        } else if (this.isBranchPrivateVideoFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-face-to-face-private` });
        } else if (this.isBranchByPhonePublicFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-phone-public` });
        } else if (this.isBranchByPhonePrivateFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-phone-private` });
        } else if (this.isBranchByVideoPublicFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-video-public` });
        } else if (this.isBranchByVideoPrivateFlow) {
            this.$router.push({ path: `/appointment/calendar/in-branch-by-video-private` });
        }
        setTimeout(() => {
            if (currentRoute) {
                currentRoute.meta.right = RIGHT_CREATE;
            }
        }, 3000);
    }

    updateAppointmentDate(payload: BookAppointmentManuallyType) {
        this.appointmentChangeDateData = payload;
    }

    async changeAppointmentDate() {
        if (!this.appointmentChangeDateData) {
            return;
        }

        try {
            this.isRequestSending = true;
            const appointment = await AppointmentWebApi.updateAppointmentData({
                dt_start: this.appointmentChangeDateData.dt_start,
            }, this.appointmentData.id as string);

            if (appointment) {
                this.$emit('updateAppointmentData', appointment);
                this.removeAppointment(appointment.id);
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', 'success');
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isRequestSending = false;
        }
    }

    async goToCalendar() {
        if (this.appointmentData) {
            this.setAppointmentDataToUpdate(this.appointmentData);
            this.setAppointmentCategory({
                id: this.appointmentData.appointment_category_id,
                title: this.appointmentData.title,
                duration: this.appointmentData.duration,
            } as any);
            this.setAppointmentType(this.appointmentData.category);

            let clientPostCode = null;
            let branchPostCode = null;
            let locationPostCode = null;
            let branch_video_rooms = {};

            if (this.appointmentData) {
                if (this.appointmentData.client_address) {
                    const { zip_code } = parseAddress(this.appointmentData.client_address);
                    clientPostCode = { post_code: zip_code };
                }

                if (this.appointmentData.client_post_code) {
                    clientPostCode = { post_code: this.appointmentData.client_post_code };
                }

                if (this.appointmentData.branch_address) {
                    const { zip_code } = parseAddress(this.appointmentData.branch_address);
                    branchPostCode = { post_code: zip_code };
                }

                if (this.appointmentData.location) {
                    const { zip_code } = parseAddress(this.appointmentData.location);
                    locationPostCode = { post_code: zip_code };
                }

                if (this.appointmentData.branch_id && this.appointmentData.room_id) {
                    const { site } = await SitesApi.getSite(this.appointmentData.branch_id);
                    branch_video_rooms = { branch_video_rooms: site.branch_rooms };
                }

                this.setBranchAddress({
                    address: this.appointmentData.branch_address,
                    id: this.appointmentData.branch_id || this.appointmentData.advisor_branch_id as string,
                    name: this.appointmentData.branch_name,
                    ...branchPostCode,
                    ...branch_video_rooms,
                });

                if (this.isOnSiteFlow && locationPostCode && locationPostCode.post_code) {
                    this.setPostCode(locationPostCode.post_code);
                } else if (clientPostCode && clientPostCode.post_code) {
                    this.setPostCode(clientPostCode.post_code);
                }

                this.goToInterventionPage();
            }
        }
    }

    startBookingProcess() {
        this.setAppointmentDataToUpdate({ ...this.appointmentData, ...{ is_update_from_start: true }});
        this.$router.push({ path: '/appointment/select-type' });
    }

    updateCurrentTab(currentTab: AppointmentTabType) {
        this.currentTab = currentTab.component;
    }

    removeAppointment(id: string) {
        this.$emit('cancel', id);
    }
}
</script>

<style lang="sass">
.b-consult-appointment
    .fw-input
        background: #EDF0F3!important
        box-shadow: none!important
    .b-cancel-appointment__button
        cursor: pointer
        font-weight: 700
        font-size: 15px
        padding-top: 30px
        text-align: right
        width: 100%
        span
            opacity: 0.3
        &--text:hover
            transition: opacity 0.2s ease-in-out
            color: #FF004D
            opacity: 1

.b-add-or-edit-appointment--as-full-page
    background-color: #F8F9FA !important
    overflow-y: auto
    position: static
    .fw-popup
        background-color: #F8F9FA !important
        position: relative !important
        align-items: flex-start !important
        transform: none !important
        border-radius: 0 !important
        justify-content: flex-start
        box-shadow: none !important
        left: 0 !important
        right: 0 !important
        top: 0 !important
        bottom: 0 !important

    .fw-popup__title
        margin-bottom: 40px !important

    .b-cancel-appointment__button
        bottom: auto !important
        margin-top: 30px
        right: 0

    .fw-input
        background-color: #fff !important

.b-add-or-edit-appointment__account__title
    margin: 0 35px 15px
    text-align: center
    padding: 7px 15px
    font-size: 12px
    line-height: 13px
    font-weight: bold
    border-radius: 5px
    display: inline-block
    z-index: 10
    &--saas
        background: rgba(255,129,18,0.1)
        color: #FF8112
    &--sc
        background: rgba(55,180,251,0.1)
        color: #37B4FB

.b-add-or-edit-appointment__sub-account__title
    background-color: rgba(139,24,207,0.1)
    color: #8B18CF

.b-add-or-edit-appointment__buttons
    display: flex
    align-items: center
    margin-top: 20px
    @include media('<=phone')
        flex-direction: column
        .b-add-edit__form__delete
            margin: 20px 0
</style>
