<template>
<component
    :is='link.disabled ? `div` : `router-link`'
    v-bind='getAttrs'
    :class='{ "b-settings-nav__link--disabled": link.disabled }'
    class='b-settings-nav__link'
    replace>
    <FwIcon
        v-if='link.icon'
        class='h-mr-10'
        :icon='link.icon'
        size='21'
        :color='!link.disabled ? "#213F6B" : "#c1bdbd"'>
    </FwIcon>
    <div class='b-settings-nav__text'>
        {{ $t(link.text) }}
    </div>
</component>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SettingsNavLinkType } from '@/types/Settings';

@Component
export default class SettingsNavLink extends Vue {
    @Prop({ type: Object, required: true }) readonly link!: SettingsNavLinkType;

    get getAttrs(): null | { to: any } {
        if (this.link.disabled) return null;
        return {
            to: {
                name: this.link.name,
                params: {
                    id: this.$route.params.id,
                    ...(this.link.path.endsWith(':type') && { type: this.$route.params.type || `for` }),
                },
            },
        };
    }
}
</script>

<style lang='sass'>
.b-settings-nav
    &__link
        display: flex
        margin: 20px 0
        transition: opacity .3s
        position: relative

        &--disabled
            color: #c1bdbd !important

        &:not(.router-link-active)
            opacity: 0.5
            &:hover
                opacity: 0.7

        &.router-link-active
            &:after
                content: ''
                position: absolute
                right: 0
                top: 0
                height: 100%
                width: 2px
                background-color: #27DBBD

            .b-settings-nav__text
                border-bottom: 1px dashed

    &__text
        font-size: 16px
        line-height: 23px
        font-weight: bold
        padding-right: 10px
</style>
