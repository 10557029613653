import { AttributeExtendedType } from '@/types/Attribute';

const DYNAMIC_FIELDS_SCHEME: Array<AttributeExtendedType> = [
    {
        'searchable_name': 'cf__company_name',
        'localized_name': 'Nom de l\'entreprise',
        'en_name': 'Company name',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
            min_length: 3,
            max_length: 6,
        },
    },
    {
        'searchable_name': 'cf__siren',
        'localized_name': 'SIREN',
        'en_name': 'SIREN',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
            min_length: 1,
            max_length: 40,
        },
    },
    {
        'searchable_name': 'cf__number_of_employees',
        'localized_name': 'Nombre d\'employés',
        'en_name': 'Number of employees',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
        },
    },
    {
        'searchable_name': 'cf__some_another_field',
        'localized_name': 'Qa field #1',
        'en_name': 'Qa field #1',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: false,
        },
    },
    {
        'searchable_name': 'cf__some_another_field_2',
        'localized_name': 'Qa field #2',
        'en_name': 'Qa field #2',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
        },
    },
    {
        'searchable_name': 'cf__some_another_field_3',
        'localized_name': 'Qa field #3',
        'en_name': 'Qa field #3',
        'order': null,
        'type': 'string',
        'validator': {},
    },
    {
        'searchable_name': 'cf__some_another_field_4',
        'localized_name': 'Qa field #4',
        'en_name': 'Qa field #4',
        'order': null,
        'type': 'string',
        'validator': {},
    },
    {
        'searchable_name': 'cf__some_another_field_5',
        'localized_name': 'Qa field #5',
        'en_name': 'Qa field #5',
        'order': null,
        'type': 'string',
        'validator': {
            min_length: 2,
            max_length: 4,
        },
    },
    {
        'searchable_name': 'cf__some_another_field_6',
        'localized_name': 'Qa field #6',
        'en_name': 'Qa field #6',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: false,
            min_length: 10,
            max_length: 14,
        },
    },
];

const DYNAMIC_FIELDS_SCHEME_2: Array<AttributeExtendedType> = [
    {
        'searchable_name': 'cf__company_name',
        'localized_name': 'Nom de l\'entreprise',
        'en_name': 'Company name',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
            min_length: 3,
        },
    },
    {
        'searchable_name': 'cf__siren',
        'localized_name': 'SIREN',
        'en_name': 'SIREN',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
            max_length: 5,
        },
    },
    {
        'searchable_name': 'cf__number_of_employees',
        'localized_name': 'Nombre d\'employés',
        'en_name': 'Number of employees',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
            length: 3,
        },
    },
];

const DYNAMIC_FIELDS_SCHEME_3: Array<AttributeExtendedType> = [
    {
        'searchable_name': 'cf__company_name',
        'localized_name': 'Nom de l\'entreprise',
        'en_name': 'Company name',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
            max_length: 3,
        },
    },
    {
        'searchable_name': 'cf__siren',
        'localized_name': 'SIREN',
        'en_name': 'SIREN',
        'order': null,
        'type': 'string',
        'validator': {
            max_length: 5,
            number_range: [10, 12],
        },
    },
    {
        'searchable_name': 'cf__number_of_employees',
        'localized_name': 'Nombre d\'employés',
        'en_name': 'Number of employees',
        'order': null,
        'type': 'string',
        'validator': {},
    },
    {
        'searchable_name': 'cf__some_another_field',
        'localized_name': 'Qa field #1',
        'en_name': 'Qa field #1',
        'order': null,
        'type': 'string',
        'validator': {
            number_range: [1, 4],
        },
    },
];

const DYNAMIC_FIELDS_SCHEME_4: Array<AttributeExtendedType> = [
    {
        'searchable_name': 'cf__company_name',
        'localized_name': 'Nom de l\'entreprise',
        'en_name': 'Company name',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
            min_length: 3,
            max_length: 6,
        },
    },
    {
        'searchable_name': 'cf__siren',
        'localized_name': 'SIREN',
        'en_name': 'SIREN',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
            min_length: 1,
            max_length: 40,
        },
    },
    {
        'searchable_name': 'cf__number_of_employees',
        'localized_name': 'Nombre d\'employés',
        'en_name': 'Number of employees',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
        },
    },
    {
        'searchable_name': 'cf__some_another_field',
        'localized_name': 'Qa field #1',
        'en_name': 'Qa field #1',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: false,
        },
    },
    {
        'searchable_name': 'cf__some_another_field_2',
        'localized_name': 'Qa field #2',
        'en_name': 'Qa field #2',
        'order': null,
        'type': 'string',
        'validator': {
            input_required: true,
        },
    },
];

export {
    DYNAMIC_FIELDS_SCHEME,
    DYNAMIC_FIELDS_SCHEME_2,
    DYNAMIC_FIELDS_SCHEME_3,
    DYNAMIC_FIELDS_SCHEME_4,
};
