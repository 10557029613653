<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mb-0'>
                    {{ $t('WORKER.TIME.POPUP.TITLE') }} ?
                </h2>
                <p class='h-mt-10 h-mb-20 h-text-center'>
                    {{ $t('WORKER.TIME.POPUP.TXT') }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-10 h-mr-10'
                    :style='{
                        fontSize: `16px`
                    }'
                    color='cancel'
                    borderRadiusType='tiny-border'
                    @click='$emit("close")'>
                    {{ $t('WORKER.TIME.POPUP.BUTTON.CANCEL') }}
                </FwButton>
                <FwButton
                    class='h-mt-10'
                    :style='{
                        padding: `0 20px`
                    }'
                    borderRadiusType='tiny-border'
                    @click='$emit("sendDataToServer")'>
                    {{ $t('WORKER.TIME.POPUP.BUTTON.SAVE') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FinalePopup extends Vue {
    @Prop({ type: String, default: null }) readonly subMessage!: string;
}
</script>
